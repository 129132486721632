import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import glassesIllustration from '../../assets/images/glasses-illustration.svg';
import CardHeader from '../../components/card/card-header';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <CardHeader>
      <Box sx={{ ml: 4, mt: 6, mb: 7 }}>
        <Typography variant="h4" sx={{ color: theme.palette.common.white }}>
          {t('email-verification.title')}
        </Typography>
        <Typography
          variant="h5"
          sx={{ mt: 1, color: theme.palette.common.white }}
        >
          {t('email-verification.subtitle')}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '200px',
          mt: 1,
          background: `url(${glassesIllustration}) no-repeat center`,
        }}
      />
    </CardHeader>
  );
};

export default Header;
