import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PictogramsDtoDetailedSustainabilityEnum } from '../../../api';
import PictogramItem from '../../../components/pictogram/pictogram-item';
import TooltipTypography from '../../../components/typography/tooltip-typography';
import { DetailedSustainabilityPictograms } from '../../../constants/pictograms';
import { EditLabelSplitFormProps } from '../../../models/label';
import {
  DetailedSustainabilityCategoryEnum,
  Pictogram,
  PictogramId,
} from '../../../models/pictogram';

interface DetailedSustainabilityCategoryProps extends EditLabelSplitFormProps {
  category: DetailedSustainabilityCategoryEnum;
}
const DetailedSustainabilityCategory: React.FC<
  DetailedSustainabilityCategoryProps
> = ({ category, control, getValues, setValue }) => {
  const { t } = useTranslation();
  const pictogramsInCategory = DetailedSustainabilityPictograms.filter(
    (it) => it.details?.category === category
  );
  const pictogramIdsInCategory = pictogramsInCategory.map((it) => it.id);
  const selectedDetailedSustainabilityPictogramIds =
    getValues && getValues('pictograms.detailedSustainability')
      ? (getValues(
          'pictograms.detailedSustainability'
        ) as PictogramsDtoDetailedSustainabilityEnum[])
      : [];
  const isPictogramSelectedFromCategory = pictogramIdsInCategory.some(
    (it) =>
      typeof it !== 'undefined' &&
      selectedDetailedSustainabilityPictogramIds.includes(
        it as PictogramsDtoDetailedSustainabilityEnum
      )
  );
  const [checked, setChecked] = useState(isPictogramSelectedFromCategory);

  useEffect(() => {
    setChecked(isPictogramSelectedFromCategory);
  }, [isPictogramSelectedFromCategory]);

  const toggleCategory = () => setChecked(!checked);
  const unSelectAll = () => {
    if (setValue && getValues)
      setValue(
        'pictograms.detailedSustainability',
        (
          getValues(
            'pictograms.detailedSustainability'
          ) as PictogramsDtoDetailedSustainabilityEnum[]
        ).filter((it) => !pictogramIdsInCategory.includes(it)) as never,
        { shouldDirty: true }
      );
  };
  const selectFirst = () => {
    if (setValue && getValues)
      setValue(
        'pictograms.detailedSustainability',
        [
          ...(getValues(
            'pictograms.detailedSustainability'
          ) as PictogramsDtoDetailedSustainabilityEnum[]),
          pictogramIdsInCategory[0],
        ] as never,
        { shouldDirty: true }
      );
  };
  const handleToggle = () => {
    if (checked) {
      unSelectAll();
    } else {
      selectFirst();
    }

    toggleCategory();
  };
  const handleSelectionChange = (
    fieldValue: PictogramId[],
    changedValue: PictogramId,
    callback: (value: PictogramId[]) => void
  ) => {
    callback([
      ...fieldValue.filter((it) => !pictogramIdsInCategory.includes(it)),
      changedValue,
    ]);
  };
  const getTooltipTitle = (pictogram: Pictogram) => (
    <Box>
      <TooltipTypography>{`${t('edit-label.resin-id')}: ${
        pictogram.details?.resinCode
      }`}</TooltipTypography>
      <TooltipTypography>{`${t('edit-label.waste-destination')}: ${t(
        `detailed-sustainability.waste.${pictogram.details?.waste}`
      )}`}</TooltipTypography>
    </Box>
  );

  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleToggle} />}
        label={t(`detailed-sustainability.category.${category}`)}
        sx={{ ml: 0.5 }}
      />
      <Controller
        control={control}
        name="pictograms.detailedSustainability"
        render={({
          field: { value, onChange },
        }: {
          field: {
            value: PictogramId[];
            onChange: (value: PictogramId[]) => void;
          };
        }) => (
          <Grid
            container
            spacing={2}
            sx={{
              height: checked ? 'auto' : 0,
              overflow: 'hidden',
            }}
          >
            {pictogramsInCategory.map((pictogram) => (
              <PictogramItem
                key={pictogram.id}
                checked={!!value?.includes(pictogram.id)}
                value={pictogram.id}
                onChange={(newValue) =>
                  handleSelectionChange(value, newValue, onChange)
                }
                imageSrc={pictogram.src}
                label={t(pictogram.name)}
                tooltipProps={{
                  title: getTooltipTitle(pictogram),
                  arrow: true,
                }}
              />
            ))}
          </Grid>
        )}
      />
    </>
  );
};

export default DetailedSustainabilityCategory;
