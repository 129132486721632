export default [
  {
    value: 'AF',
    label: 'An Afganastáin',
  },
  {
    value: 'AL',
    label: 'An Albáin',
  },
  {
    value: 'DZ',
    label: 'An Ailgéir',
  },
  {
    value: 'AS',
    label: 'Samó Meiriceánach',
  },
  {
    value: 'AD',
    label: 'Andóra',
  },
  {
    value: 'AO',
    label: 'Angóla',
  },
  {
    value: 'AI',
    label: 'Angise',
  },
  {
    value: 'AG',
    label: 'Antigua agus Barbúda',
  },
  {
    value: 'AR',
    label: 'An Airgintín',
  },
  {
    value: 'AM',
    label: 'An Airméin',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'An Astráil',
  },
  {
    value: 'AT',
    label: 'An Ostair',
  },
  {
    value: 'AZ',
    label: 'An Asarbaiseáin',
  },
  {
    value: 'BS',
    label: 'Baháfaas',
  },
  {
    value: 'BH',
    label: 'Bairéin',
  },
  {
    value: 'BD',
    label: 'An Bhanglaidéis',
  },
  {
    value: 'BB',
    label: 'Barbadós',
  },
  {
    value: 'BY',
    label: 'An Bhealarúis',
  },
  {
    value: 'BE',
    label: 'An Bheilg',
  },
  {
    value: 'BZ',
    label: 'An Bheilís',
  },
  {
    value: 'BJ',
    label: 'Beinin',
  },
  {
    value: 'BM',
    label: 'Beirmiúda',
  },
  {
    value: 'BT',
    label: 'An Bhútáin',
  },
  {
    value: 'BO',
    label: 'An Bholaiv',
  },
  {
    value: 'BA',
    label: 'An Bhoisnia agus Heirseagaivéin',
  },
  {
    value: 'BW',
    label: 'An Bhotsuáin',
  },
  {
    value: 'BR',
    label: 'An Bhrasaíl',
  },
  {
    value: 'IO',
    label: 'Oileáin Mhaighdean na Breataine',
  },
  {
    value: 'BN',
    label: 'Brunei darussalam',
  },
  {
    value: 'BG',
    label: 'An Bhulgáir',
  },
  {
    value: 'BF',
    label: 'Buircíne Fasó',
  },
  {
    value: 'BI',
    label: 'An Bhurúin',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'An Chambóid',
  },
  {
    value: 'CM',
    label: 'Camarún',
  },
  {
    value: 'CA',
    label: 'Ceanada',
  },
  {
    value: 'KY',
    label: 'Oileáin Cayman',
  },
  {
    value: 'CF',
    label: 'Poblacht na hAfraice Láir',
  },
  {
    value: 'TD',
    label: 'Sead',
  },
  {
    value: 'CL',
    label: 'An tSile',
  },
  {
    value: 'CN',
    label: 'An tSín',
  },
  {
    value: 'CO',
    label: 'An Cholóim',
  },
  {
    value: 'KM',
    label: 'Comoros',
  },
  {
    value: 'CG',
    label: 'An Congó',
  },
  {
    value: 'CK',
    label: 'Oileáin Cook',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
  },
  {
    value: 'CI',
    label: 'An Cósta Eavoire',
  },
  {
    value: 'HR',
    label: 'An Chróit',
  },
  {
    value: 'CU',
    label: 'Cúba',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'An Chipir',
  },
  {
    value: 'CZ',
    label: 'Poblacht na Seice',
  },
  {
    value: 'CZ',
    label: 'Poblacht na ndaoine daonlathacha sa Chóiré',
  },
  {
    value: 'CD',
    label: 'Poblacht Dhaonlathach an Chongó',
  },
  {
    value: 'DK',
    label: 'An Danmhairg',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Djibouti',
  },
  {
    value: 'DM',
    label: 'Doiminice',
  },
  {
    value: 'DO',
    label: 'An Phoblacht Dhoiminiceach',
  },
  {
    value: 'EC',
    label: 'Eacuadór',
  },
  {
    value: 'EG',
    label: 'An Éigipt',
  },
  {
    value: 'SV',
    label: 'An tSalvadóir',
  },
  {
    value: 'GQ',
    label: 'Guine batchiorrach',
  },
  {
    value: 'ER',
    label: 'An Eiritré',
  },
  {
    value: 'EE',
    label: 'An Eastóin',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'An Aetóip',
  },
  {
    value: 'FK',
    label: 'Oileáin Fháclainne',
  },
  {
    value: 'FO',
    label: 'Oileáin Fharó',
  },
  {
    value: 'FJ',
    label: 'Fidsí',
  },
  {
    value: 'FI',
    label: 'An Fhionlainn',
  },
  {
    value: 'FR',
    label: 'An Fhrainc',
  },
  {
    value: 'FR',
    label: 'Ranna agus críocha na Fraince san Aigéan Indiach',
  },
  {
    value: 'GF',
    label: 'Guáin na Fraince',
  },
  {
    value: 'PF',
    label: 'Polainéis na Fraince',
  },
  {
    value: 'GA',
    label: 'An Ghabúin',
  },
  {
    value: 'GM',
    label: 'An Ghaimbia',
  },
  {
    value: 'GE',
    label: 'An tSeoirsia',
  },
  {
    value: 'DE',
    label: 'An Ghearmáin',
  },
  {
    value: 'GH',
    label: 'Gána',
  },
  {
    value: 'GI',
    label: '(De chuid) na nGiobrálta',
  },
  {
    value: 'GR',
    label: 'An Ghréig',
  },
  {
    value: 'GL',
    label: 'An Ghraonlainn',
  },
  {
    value: 'GD',
    label: 'Greanáda',
  },
  {
    value: 'GP',
    label: 'Guahadalúip',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatamala',
  },
  {
    value: 'GG',
    label: 'An Ghuine',
  },
  {
    value: 'GN',
    label: 'Guine Bissau',
  },
  {
    value: 'GY',
    label: 'An Ghuáin',
  },
  {
    value: 'HT',
    label: 'Háítí',
  },
  {
    value: 'HN',
    label: 'Hondúras',
  },
  {
    value: 'HK',
    label: 'Hong Cong',
  },
  {
    value: 'HU',
    label: 'An Ungáir',
  },
  {
    value: 'IS',
    label: 'An Íoslainn',
  },
  {
    value: 'IN',
    label: 'An India',
  },
  {
    value: 'ID',
    label: 'An Indinéis',
  },
  {
    value: 'IR',
    label: 'An Iaráin',
  },
  {
    value: 'IQ',
    label: 'An Iaráic',
  },
  {
    value: 'IE',
    label: 'Éireann',
  },
  {
    value: 'IL',
    label: 'Iosrael',
  },
  {
    value: 'IT',
    label: 'An Iodáil',
  },
  {
    value: 'JM',
    label: 'Iamáice',
  },
  {
    value: 'JP',
    label: 'An tSeapáin',
  },
  {
    value: 'JO',
    label: 'An Iordáin',
  },
  {
    value: 'KZ',
    label: 'An Chasacstáin',
  },
  {
    value: 'KE',
    label: 'An Chéinia',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'An Chóiré',
  },
  {
    value: 'XK',
    label: 'An Chosaiv',
  },
  {
    value: 'KW',
    label: 'Cuáit',
  },
  {
    value: 'KG',
    label: 'An Chirgeastáin',
  },
  {
    value: 'LA',
    label: 'Poblacht Dhaonlathach Lao People',
  },
  {
    value: 'LV',
    label: 'An Laitvia',
  },
  {
    value: 'LB',
    label: 'An Liobáin',
  },
  {
    value: 'LS',
    label: 'Leosóta',
  },
  {
    value: 'LR',
    label: 'An Libéir',
  },
  {
    value: 'LY',
    label: 'An Libia',
  },
  {
    value: 'LI',
    label: 'Lichtinstéin',
  },
  {
    value: 'LT',
    label: 'An Liotuáin',
  },
  {
    value: 'LU',
    label: 'Lucsamburg',
  },
  {
    value: 'MO',
    label: 'Macao',
  },
  {
    value: 'MG',
    label: 'Madagascar',
  },
  {
    value: 'MW',
    label: 'An Mhaláiv',
  },
  {
    value: 'MY',
    label: 'An Mhalaeisia',
  },
  {
    value: 'MV',
    label: 'Oileáin Mhaildíve',
  },
  {
    value: 'ML',
    label: 'Mailí',
  },
  {
    value: 'MT',
    label: 'Málta',
  },
  {
    value: 'MH',
    label: 'Oileáin Marshall',
  },
  {
    value: 'MQ',
    label: 'Martinique',
  },
  {
    value: 'MR',
    label: 'An Mháratáin',
  },
  {
    value: 'MU',
    label: 'Oileán Mhuirís',
  },
  {
    value: 'MX',
    label: 'Meicsiceo',
  },
  {
    value: 'FM',
    label: 'An Mhicrinéis',
  },
  {
    value: 'MD',
    label: 'An Mholdóiv',
  },
  {
    value: 'MC',
    label: 'Monacó',
  },
  {
    value: 'MN',
    label: 'An Mhongóil',
  },
  {
    value: 'ME',
    label: 'Montainéagró',
  },
  {
    value: 'MS',
    label: 'Monterrat',
  },
  {
    value: 'MA',
    label: 'Maracó',
  },
  {
    value: 'MZ',
    label: 'Mósaimbíc',
  },
  {
    value: 'MM',
    label: 'Maenmar',
  },
  {
    value: 'NA',
    label: 'An Namaib',
  },
  {
    value: 'NR',
    label: 'Nárús',
  },
  {
    value: 'NP',
    label: 'Neipeal',
  },
  {
    value: 'NL',
    label: 'Ísiltír',
  },
  {
    value: 'NC',
    label: 'An Nua -Chaladóin',
  },
  {
    value: 'NZ',
    label: 'An Nua-Shéalainn',
  },
  {
    value: 'NI',
    label: 'Nicearagua',
  },
  {
    value: 'NE',
    label: 'An Nígir',
  },
  {
    value: 'NG',
    label: 'An Nigéir',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Oileán Norfolk',
  },
  {
    value: 'NF',
    label: 'An Macadóine Thuaidh',
  },
  {
    value: 'MP',
    label: 'Oileáin Mariana Thuaidh',
  },
  {
    value: 'NO',
    label: 'An Iorua',
  },
  {
    value: 'OM',
    label: 'Óman',
  },
  {
    value: 'PK',
    label: 'An Phacastáin',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Nua -Ghuine Phapua',
  },
  {
    value: 'PY',
    label: 'Paragua',
  },
  {
    value: 'PE',
    label: 'Peiriú',
  },
  {
    value: 'PH',
    label: 'Na hOileáin Dhuige',
  },
  {
    value: 'PL',
    label: 'An Pholainn',
  },
  {
    value: 'PT',
    label: 'An Phortaingéil',
  },
  {
    value: 'PR',
    label: 'Pórtó Ríce',
  },
  {
    value: 'QA',
    label: 'Catar',
  },
  {
    value: 'RO',
    label: 'An Rómáin',
  },
  {
    value: 'RU',
    label: 'Cónaidhm na Rúise',
  },
  {
    value: 'RW',
    label: 'Ruanda',
  },
  {
    value: 'SH',
    label: 'Naomh Helena, Deascairt agus Tristan da Cunha',
  },
  {
    value: 'KN',
    label: 'Naomh Kitts agus Nevis',
  },
  {
    value: 'LC',
    label: 'Naomh Lucia',
  },
  {
    value: 'PM',
    label: 'Naomh Pierre agus Miquelon',
  },
  {
    value: 'VC',
    label: 'Naomh Vincent agus na grenadines',
  },
  {
    value: 'WS',
    label: 'Samó',
  },
  {
    value: 'SM',
    label: 'San Mairíne',
  },
  {
    value: 'ST',
    label: 'Sao Tome agus Principe',
  },
  {
    value: 'SA',
    label: 'An Araib Shádach',
  },
  {
    value: 'SN',
    label: 'An tSeineagáil',
  },
  {
    value: 'RS',
    label: 'An tSeirbia',
  },
  {
    value: 'SC',
    label: 'Séiséil',
  },
  {
    value: 'SL',
    label: 'Siarra Leon',
  },
  {
    value: 'SG',
    label: 'Singeapór',
  },
  {
    value: 'SG',
    label: 'Sint maarten',
  },
  {
    value: 'SK',
    label: 'An tSlóvaic',
  },
  {
    value: 'SI',
    label: 'An tSlóivéin',
  },
  {
    value: 'SB',
    label: 'Oileáin Sholomón',
  },
  {
    value: 'SO',
    label: 'An tSomáil',
  },
  {
    value: 'ZA',
    label: 'an Afraic Theas',
  },
  {
    value: 'SS',
    label: 'An tSúdáin Theas',
  },
  {
    value: 'ES',
    label: 'an Spáinn',
  },
  {
    value: 'LK',
    label: 'Srí Lanca',
  },
  {
    value: 'SD',
    label: 'An tSúdáin',
  },
  {
    value: 'SR',
    label: 'Suranam',
  },
  {
    value: 'SE',
    label: 'An tSualainn',
  },
  {
    value: 'CH',
    label: 'An Eilvéis',
  },
  {
    value: 'SY',
    label: 'Poblacht Arabach na Siria',
  },
  {
    value: 'TW',
    label: 'An Téaváin',
  },
  {
    value: 'TJ',
    label: 'An Táidsíceastáin',
  },
  {
    value: 'TZ',
    label: 'An Tansáin',
  },
  {
    value: 'TH',
    label: 'An Téalainn',
  },
  {
    value: 'TL',
    label: 'Tíomór-Leste',
  },
  {
    value: 'TG',
    label: 'Poblacht Tóga',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Oileán na Tríonóide agus Tobága',
  },
  {
    value: 'TN',
    label: 'An Túinéis',
  },
  {
    value: 'TR',
    label: 'Poblacht na Tuirce',
  },
  {
    value: 'TM',
    label: 'An Tuircméanastáin',
  },
  {
    value: 'TC',
    label: 'Oileáin na dTurcach agus Caicos',
  },
  {
    value: 'TV',
    label: 'Túvalú',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'An Úcráin',
  },
  {
    value: 'AE',
    label: 'Aontas na nÉimíríochtaí Arabacha',
  },
  {
    value: 'GB',
    label: 'an Ríocht Aontaithe',
  },
  {
    value: 'US',
    label: 'Stáit Aontaithe',
  },
  {
    value: 'US',
    label: 'Oileáin Mhaighdean na Stát Aontaithe',
  },
  {
    value: 'UY',
    label: 'Uragua',
  },
  {
    value: 'UZ',
    label: 'An Úisbéiceastáin',
  },
  {
    value: 'VU',
    label: 'Vanuatú',
  },
  {
    value: 'VE',
    label: 'Veiniséala',
  },
  {
    value: 'VN',
    label: 'Viet Nam',
  },
  {
    value: 'WF',
    label: 'Wallis agus futuna',
  },
  {
    value: 'YE',
    label: 'Éimin',
  },
  {
    value: 'ZM',
    label: 'An tSaimbia',
  },
  {
    value: 'ZM',
    label: 'An tSiombáib',
  },
];
