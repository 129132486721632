import { BoxProps, Divider, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { PriceProductTierEnum, Product, ProductCurrencyEnum } from '../../api';
import PaletteIcon from '../../assets/icons/palette-icon';
import CardFooter from '../../components/card/card-footer';
import FlexBoxColumn from '../../components/flexbox/flex-box-column';
import {
  default as FlexBox,
  default as Flexbox,
} from '../../components/flexbox/flexbox';
import Switch from '../../components/switch/switch';
import { primary, textDisabled } from '../../constants/colors';
import { getCurrencySign } from './licences-utils';

interface LicenceCardFooterProps extends BoxProps {
  product?: Product;
  checked?: boolean;
  onChange?: () => void;
  isDense?: boolean;
}

const LicenceCardFooter: React.FC<LicenceCardFooterProps> = ({
  product,
  checked,
  onChange,
  isDense,
  ...props
}) => {
  const { palette } = useTheme();
  const isUsdPrice = product?.currency === ProductCurrencyEnum.Usd;
  const premiumPrice = isUsdPrice
    ? Array.from(product?.prices || []).find(
        (it) => it.productTier === PriceProductTierEnum._2
      )?.amount
    : product?.priceDifference;
  const currencySign = getCurrencySign(product?.currency);

  return (
    <CardFooter
      sx={{
        bgcolor: checked ? primary[800] : palette.grey[200],
        py: 0.5,
        px: isDense ? 0.5 : 3,
        height: '66px',
      }}
      {...props}
    >
      <FlexBox
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Flexbox gap={0.5} mx={isDense ? 1 : 1.5}>
          <PaletteIcon
            fill={checked ? '#FFF' : undefined}
            fillOpacity={checked ? 1 : undefined}
          />
          <FlexBoxColumn>
            <Typography
              variant="button"
              sx={{
                fontSize: '0.9375rem',
                lineHeight: 1.73,
                letterSpacing: 0.46,
                ...(checked ? { color: '#FFF' } : {}),
              }}
            >
              {t('licences.personalization')}
            </Typography>
            <FlexBox justifyContent="space-between">
              <Typography
                sx={{
                  color: textDisabled,
                  fontSize: '0.875rem',
                  lineHeight: 1,
                  ...(checked ? { color: palette.grey[300] } : {}),
                }}
              >
                {t('common.optional')}
              </Typography>
              {!checked && (
                <Typography
                  sx={{
                    color: palette.primary.light,
                    fontSize: '0.875rem',
                    fontWeight: 700,
                    lineHeight: 1,
                  }}
                >
                  {isUsdPrice
                    ? `${currencySign}${premiumPrice}`
                    : `+${premiumPrice}${currencySign}`}
                </Typography>
              )}
            </FlexBox>
          </FlexBoxColumn>
        </Flexbox>
        <Divider orientation="vertical" flexItem />
        <Switch
          checked={checked}
          onChange={onChange}
          checkedcolor={palette.warning.light}
          isCustom
          sx={{ mx: isDense ? 1 : 1.5 }}
        />
      </FlexBox>
    </CardFooter>
  );
};

export default LicenceCardFooter;
