export default [
  {
    value: 'AF',
    label: 'Afghanistan',
  },
  {
    value: 'AL',
    label: 'Albanië',
  },
  {
    value: 'DZ',
    label: 'Algerije',
  },
  {
    value: 'AS',
    label: 'Amerikaans Samoa',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguilla',
  },
  {
    value: 'AG',
    label: 'Antigua en Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentinië',
  },
  {
    value: 'AM',
    label: 'Armenië',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Australië',
  },
  {
    value: 'AT',
    label: 'Oostenrijk',
  },
  {
    value: 'AZ',
    label: 'Azerbeidzjan',
  },
  {
    value: 'BS',
    label: 'Bahamas',
  },
  {
    value: 'BH',
    label: 'Bahrein',
  },
  {
    value: 'BD',
    label: 'Bangladesh',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Wit -Rusland',
  },
  {
    value: 'BE',
    label: 'België',
  },
  {
    value: 'BZ',
    label: 'Belize',
  },
  {
    value: 'BJ',
    label: 'Benin',
  },
  {
    value: 'BM',
    label: 'Bermuda',
  },
  {
    value: 'BT',
    label: 'Bhutan',
  },
  {
    value: 'BO',
    label: 'Bolivia',
  },
  {
    value: 'BA',
    label: 'Bosnië-Herzegovina',
  },
  {
    value: 'BW',
    label: 'Botswana',
  },
  {
    value: 'BR',
    label: 'Brazilië',
  },
  {
    value: 'IO',
    label: 'Britse Maagdeneilanden',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bulgarije',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Cambodja',
  },
  {
    value: 'CM',
    label: 'Kameroen',
  },
  {
    value: 'CA',
    label: 'Canada',
  },
  {
    value: 'KY',
    label: 'Kaaiman Eilanden',
  },
  {
    value: 'CF',
    label: 'Centraal Afrikaanse Republiek',
  },
  {
    value: 'TD',
    label: 'Tsjaad',
  },
  {
    value: 'CL',
    label: 'Chili',
  },
  {
    value: 'CN',
    label: 'China',
  },
  {
    value: 'CO',
    label: 'Colombia',
  },
  {
    value: 'KM',
    label: 'Comoros',
  },
  {
    value: 'CG',
    label: 'Congo',
  },
  {
    value: 'CK',
    label: 'Kook eilanden',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
  },
  {
    value: 'CI',
    label: 'Ivoorkust',
  },
  {
    value: 'HR',
    label: 'Kroatië',
  },
  {
    value: 'CU',
    label: 'Cuba',
  },
  {
    value: 'CU',
    label: 'Curacao',
  },
  {
    value: 'CY',
    label: 'Cyprus',
  },
  {
    value: 'CZ',
    label: 'Tsjechische Republiek',
  },
  {
    value: 'CZ',
    label: 'Democratische Volksrepubliek Korea',
  },
  {
    value: 'CD',
    label: 'Democratische Republiek Congo',
  },
  {
    value: 'DK',
    label: 'Denemarken',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Djibouti',
  },
  {
    value: 'DM',
    label: 'Dominica',
  },
  {
    value: 'DO',
    label: 'Dominicaanse Republiek',
  },
  {
    value: 'EC',
    label: 'Ecuador',
  },
  {
    value: 'EG',
    label: 'Egypte',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'GQ',
    label: 'Equatoriaal-Guinea',
  },
  {
    value: 'ER',
    label: 'Eritrea',
  },
  {
    value: 'EE',
    label: 'Estland',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Ethiopië',
  },
  {
    value: 'FK',
    label: 'Falkland Eilanden',
  },
  {
    value: 'FO',
    label: 'Faerappeilanden',
  },
  {
    value: 'FJ',
    label: 'Fiji',
  },
  {
    value: 'FI',
    label: 'Finland',
  },
  {
    value: 'FR',
    label: 'Frankrijk',
  },
  {
    value: 'FR',
    label: 'Franse afdelingen en gebieden in de Indische Oceaan',
  },
  {
    value: 'GF',
    label: 'Frans Guyana',
  },
  {
    value: 'PF',
    label: 'Frans-Polynesië',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambia',
  },
  {
    value: 'GE',
    label: 'Georgië',
  },
  {
    value: 'DE',
    label: 'Duitsland',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
  },
  {
    value: 'GR',
    label: 'Griekenland',
  },
  {
    value: 'GL',
    label: 'Groenland',
  },
  {
    value: 'GD',
    label: 'Grenada',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  {
    value: 'GG',
    label: 'Guinea',
  },
  {
    value: 'GN',
    label: 'Guinee-Bissau',
  },
  {
    value: 'GY',
    label: 'Guyana',
  },
  {
    value: 'HT',
    label: 'Haïti',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hong Kong',
  },
  {
    value: 'HU',
    label: 'Hongarije',
  },
  {
    value: 'IS',
    label: 'IJsland',
  },
  {
    value: 'IN',
    label: 'India',
  },
  {
    value: 'ID',
    label: 'Indonesië',
  },
  {
    value: 'IR',
    label: 'Iran',
  },
  {
    value: 'IQ',
    label: 'Irak',
  },
  {
    value: 'IE',
    label: 'Ierland',
  },
  {
    value: 'IL',
    label: 'Israël',
  },
  {
    value: 'IT',
    label: 'Italië',
  },
  {
    value: 'JM',
    label: 'Jamaica',
  },
  {
    value: 'JP',
    label: 'Japan',
  },
  {
    value: 'JO',
    label: 'Jordanië',
  },
  {
    value: 'KZ',
    label: 'Kazachstan',
  },
  {
    value: 'KE',
    label: 'Kenia',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Korea',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Koeweit',
  },
  {
    value: 'KG',
    label: 'Kirgizisch',
  },
  {
    value: 'LA',
    label: 'Lao Democratische Volksrepubliek',
  },
  {
    value: 'LV',
    label: 'Letland',
  },
  {
    value: 'LB',
    label: 'Libanon',
  },
  {
    value: 'LS',
    label: 'Lesotho',
  },
  {
    value: 'LR',
    label: 'Liberia',
  },
  {
    value: 'LY',
    label: 'Libië',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
  },
  {
    value: 'LT',
    label: 'Litouwen',
  },
  {
    value: 'LU',
    label: 'Luxemburg',
  },
  {
    value: 'MO',
    label: 'Macao',
  },
  {
    value: 'MG',
    label: 'Madagascar',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Maleisië',
  },
  {
    value: 'MV',
    label: 'Maldiven',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Marshall eilanden',
  },
  {
    value: 'MQ',
    label: 'Martinique',
  },
  {
    value: 'MR',
    label: 'Mauritanië',
  },
  {
    value: 'MU',
    label: 'Mauritius',
  },
  {
    value: 'MX',
    label: 'Mexico',
  },
  {
    value: 'FM',
    label: 'Micronesië',
  },
  {
    value: 'MD',
    label: 'Moldavië',
  },
  {
    value: 'MC',
    label: 'Monaco',
  },
  {
    value: 'MN',
    label: 'Mongolië',
  },
  {
    value: 'ME',
    label: 'Montenegro',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Marokko',
  },
  {
    value: 'MZ',
    label: 'Mozambique',
  },
  {
    value: 'MM',
    label: 'Myanmar',
  },
  {
    value: 'NA',
    label: 'Namibië',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepal',
  },
  {
    value: 'NL',
    label: 'Nederland',
  },
  {
    value: 'NC',
    label: 'Nieuw-Caledonië',
  },
  {
    value: 'NZ',
    label: 'Nieuw-Zeeland',
  },
  {
    value: 'NI',
    label: 'Nicaragua',
  },
  {
    value: 'NE',
    label: 'Niger',
  },
  {
    value: 'NG',
    label: 'Nigeria',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Norfolk Island',
  },
  {
    value: 'NF',
    label: 'Noord -Macedonië',
  },
  {
    value: 'MP',
    label: 'noordelijke Mariana eilanden',
  },
  {
    value: 'NO',
    label: 'Noorwegen',
  },
  {
    value: 'OM',
    label: 'Oman',
  },
  {
    value: 'PK',
    label: 'Pakistan',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papoea-Nieuw-Guinea',
  },
  {
    value: 'PY',
    label: 'Paraguay',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'PH',
    label: 'Filippijnen',
  },
  {
    value: 'PL',
    label: 'Polen',
  },
  {
    value: 'PT',
    label: 'Portugal',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'QA',
    label: 'Qatar',
  },
  {
    value: 'RO',
    label: 'Roemenië',
  },
  {
    value: 'RU',
    label: 'Russische Federatie',
  },
  {
    value: 'RW',
    label: 'Rwanda',
  },
  {
    value: 'SH',
    label: 'Saint Helena, Ascension en Tristan Da Cunha',
  },
  {
    value: 'KN',
    label: 'Saint Kitts en Nevis',
  },
  {
    value: 'LC',
    label: 'Sint Lucia',
  },
  {
    value: 'PM',
    label: 'Saint Pierre en Miquelon',
  },
  {
    value: 'VC',
    label: 'Saint Vincent en de Grenadines',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao Tome en Principe',
  },
  {
    value: 'SA',
    label: 'Saoedi-Arabië',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Servië',
  },
  {
    value: 'SC',
    label: 'Seychellen',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Singapore',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Slowakije',
  },
  {
    value: 'SI',
    label: 'Slovenië',
  },
  {
    value: 'SB',
    label: 'Solomon eilanden',
  },
  {
    value: 'SO',
    label: 'Somalië',
  },
  {
    value: 'ZA',
    label: 'Zuid-Afrika',
  },
  {
    value: 'SS',
    label: 'Zuid Soedan',
  },
  {
    value: 'ES',
    label: 'Spanje',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
  },
  {
    value: 'SD',
    label: 'Soedan',
  },
  {
    value: 'SR',
    label: 'Aanraden',
  },
  {
    value: 'SE',
    label: 'Zweden',
  },
  {
    value: 'CH',
    label: 'Zwitserland',
  },
  {
    value: 'SY',
    label: 'Syrische Arabische Republiek',
  },
  {
    value: 'TW',
    label: 'Taiwan',
  },
  {
    value: 'TJ',
    label: 'Tadzjikistan',
  },
  {
    value: 'TZ',
    label: 'Tanzania',
  },
  {
    value: 'TH',
    label: 'Thailand',
  },
  {
    value: 'TL',
    label: 'Timor-Leste',
  },
  {
    value: 'TG',
    label: 'Togo Republiek',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinidad en Tobago',
  },
  {
    value: 'TN',
    label: 'Tunesië',
  },
  {
    value: 'TR',
    label: 'republiek van Turkije',
  },
  {
    value: 'TM',
    label: 'Turkmenistan',
  },
  {
    value: 'TC',
    label: 'Turks-en Caicoseilanden',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Oeganda',
  },
  {
    value: 'UA',
    label: 'Oekraïne',
  },
  {
    value: 'AE',
    label: 'Verenigde Arabische Emiraten',
  },
  {
    value: 'GB',
    label: 'Verenigd Koninkrijk',
  },
  {
    value: 'US',
    label: 'Verenigde Staten',
  },
  {
    value: 'US',
    label: 'Verenigde Staten Maagdeneilanden',
  },
  {
    value: 'UY',
    label: 'Uruguay',
  },
  {
    value: 'UZ',
    label: 'Oezbekistan',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Vietnam',
  },
  {
    value: 'WF',
    label: 'Wallis en Futuna',
  },
  {
    value: 'YE',
    label: 'Jemen',
  },
  {
    value: 'ZM',
    label: 'Zambia',
  },
  {
    value: 'ZM',
    label: 'Zimbabwe',
  },
];
