export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortBy {
  'CREATED' = 'CREATED',
  'HARVEST_YEAR' = 'HARVEST_YEAR',
  'PRODUCT_NAME' = 'PRODUCT_NAME',
}

export interface TableOptions {
  pageNumber: number;
  sortBy: SortBy;
  sortDirection: SortDirection;
  size: number;
}
