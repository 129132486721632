import { Button, DialogProps, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import errorIllustration from '../../assets/images/error-illustration.svg';
import warningIllustration from '../../assets/images/warning-illustration.svg';
import FlexBoxColumn from '../flexbox/flex-box-column';
import FlexBox from '../flexbox/flexbox';
import Modal from './modal';

interface ConfirmationModalProps extends DialogProps {
  variant?: 'error' | 'warning';
  onConfirm: () => void;
  onCancel?: () => void;
  hideOnChoice?: boolean;
  labels?: {
    message?: string;
    cancel?: string;
    confirm?: string;
  };
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  const { t } = useTranslation();
  const {
    onConfirm,
    onCancel,
    labels,
    variant = 'error',
    hideOnChoice = true,
    ...modalProps
  } = props;

  const handleCancel = () => {
    if (props.onClose && hideOnChoice) props.onClose({}, 'backdropClick');
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    if (props.onClose && hideOnChoice) props.onClose({}, 'backdropClick');
    onConfirm();
  };

  return (
    <Modal {...modalProps}>
      <FlexBox mx={4} my={6}>
        <img
          src={variant === 'warning' ? warningIllustration : errorIllustration}
        />
        <FlexBoxColumn ml={4} gap={2} justifyContent="space-around">
          <Typography variant="h6" textAlign="center">
            {labels?.message}
          </Typography>
          <FlexBox>
            <Button
              fullWidth
              sx={{ mr: 0.5 }}
              variant="outlined"
              onClick={handleCancel}
            >
              {labels?.cancel || t('common.cancel')}
            </Button>
            <Button
              fullWidth
              sx={{ ml: 0.5 }}
              variant="contained"
              color={variant}
              onClick={handleConfirm}
            >
              {labels?.confirm || t('common.yes')}
            </Button>
          </FlexBox>
        </FlexBoxColumn>
      </FlexBox>
    </Modal>
  );
};

export default ConfirmationModal;
