import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PriceProductTierEnum,
  Product,
  ProductCurrencyEnum,
  ProductPeriodEnum,
} from '../../api';
import CardHeader from '../../components/card/card-header';
import Flexbox from '../../components/flexbox/flexbox';
import CardHeaderContentWrapper from './card-header-content-wrapper';
import { getCurrencySign } from './licences-utils';
import { LicenceCardVersion, StarTagType } from '../../models/licence';
import StarTag from './star-tag';

interface LicenceCardHeaderProps {
  product?: Product;
  isCustom?: boolean;
  withPersonalization?: boolean;
  version?: LicenceCardVersion;
}

const LicenceCardHeader: React.FC<LicenceCardHeaderProps> = ({
  product,
  isCustom,
  withPersonalization,
  version,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const isVersion2 = version === LicenceCardVersion.V2;
  const prices = Array.from(product?.prices || []);
  const normalPrice = prices.find(
    (it) => it.productTier === PriceProductTierEnum._1
  )?.amount;
  const premiumPrice = prices.find(
    (it) => it.productTier === PriceProductTierEnum._2
  )?.amount;
  const price = withPersonalization ? premiumPrice : normalPrice;
  const notCustomStarTagType = withPersonalization
    ? StarTagType.PERSONALIZED
    : StarTagType.BASIC;
  const starTagType = isCustom ? StarTagType.CUSTOM : notCustomStarTagType;
  const currencySign = getCurrencySign(product?.currency);
  const currencySignAhead = product?.currency === ProductCurrencyEnum.Usd;
  const isMonthlyPrice = product?.period === ProductPeriodEnum.Month;

  return (
    <CardHeader
      isWarning={withPersonalization}
      sx={
        isVersion2
          ? {
              background: `linear-gradient(102.83deg,\
                 ${palette.primaryVariations[800]} 10.46%,\
                 ${palette.primaryVariations[600]} 91.92%)`,
              position: 'relative',
            }
          : {}
      }
    >
      {isVersion2 && <StarTag type={starTagType} />}
      <CardHeaderContentWrapper>
        <Flexbox
          alignItems="flex-end"
          flexDirection={currencySignAhead ? 'row-reverse' : 'row'}
        >
          <Typography variant="h3">
            {isCustom ? t('licences.custom') : price}
          </Typography>
          {!isCustom && (
            <Typography variant="h4" sx={{ mb: 0.25 }}>
              {currencySign}
            </Typography>
          )}
        </Flexbox>
        {!isCustom && (
          <>
            <Typography variant="h5" sx={{ color: palette.common.white }}>
              {`+ ${t('licences.tax')} | ${t(
                `licences.per-${product?.period?.toLowerCase()}`
              )}`}
            </Typography>
            {isMonthlyPrice && (
              <Typography variant="body2">
                {t('licences.committed-and-billed-annually')}
              </Typography>
            )}
          </>
        )}
      </CardHeaderContentWrapper>
    </CardHeader>
  );
};

export default LicenceCardHeader;
