import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

export type TextFieldWithControllerProps<T extends FieldValues> = {
  control: Control<T>;
  name: FieldPath<T>;
  undefinedEmpty?: boolean;
  textFieldProps: TextFieldProps;
};

const TextFieldWithController = <ObjectType extends FieldValues>({
  control,
  name,
  textFieldProps,
  undefinedEmpty,
  ...otherProps
}: TextFieldWithControllerProps<ObjectType>) => {
  return (
    <Controller
      render={({ field: { ...fieldProps } }) => (
        <TextField
          {...textFieldProps}
          {...fieldProps}
          {...otherProps}
          value={fieldProps.value || ''}
          onChange={(event) =>
            fieldProps.onChange(
              !event.target.value && undefinedEmpty
                ? undefined
                : event.target.value
            )
          }
        />
      )}
      name={name}
      control={control}
    />
  );
};

export default TextFieldWithController;
