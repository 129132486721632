export enum LicenceCardVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export enum StarTagType {
  BASIC = 'BASIC',
  PERSONALIZED = 'PERSONALIZED',
  CUSTOM = 'CUSTOM',
}
