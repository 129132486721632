import { Box, BoxProps } from '@mui/material';
import React from 'react';

interface TextColorPreviewerProps extends BoxProps {
  isSelected?: boolean;
}

const TextColorPreviewer: React.FC<TextColorPreviewerProps> = ({
  isSelected,
  sx,
  ...rest
}) => {
  const size = isSelected ? 32 : 24;

  return (
    <Box
      sx={{
        minWidth: size,
        maxWidth: size,
        height: size,
        borderRadius: 0.5,
        boxShadow: isSelected ? 28 : 29,
        cursor: 'pointer',
        ...sx,
      }}
      {...rest}
    />
  );
};

export default TextColorPreviewer;
