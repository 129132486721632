export default [
  {
    value: 'AF',
    label: 'Afghanistan',
  },
  {
    value: 'AL',
    label: 'Albanie',
  },
  {
    value: 'DZ',
    label: 'Algérie',
  },
  {
    value: 'AS',
    label: 'Samoa américaine',
  },
  {
    value: 'AD',
    label: 'Andorre',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguille',
  },
  {
    value: 'AG',
    label: 'Antigua-et-Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentine',
  },
  {
    value: 'AM',
    label: 'Arménie',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Australie',
  },
  {
    value: 'AT',
    label: "L'Autriche",
  },
  {
    value: 'AZ',
    label: 'Azerbaïdjan',
  },
  {
    value: 'BS',
    label: 'Bahamas',
  },
  {
    value: 'BH',
    label: 'Bahreïn',
  },
  {
    value: 'BD',
    label: 'Bangladesh',
  },
  {
    value: 'BB',
    label: 'Barbade',
  },
  {
    value: 'BY',
    label: 'Biélorussie',
  },
  {
    value: 'BE',
    label: 'Belgique',
  },
  {
    value: 'BZ',
    label: 'Belize',
  },
  {
    value: 'BJ',
    label: 'Bénin',
  },
  {
    value: 'BM',
    label: 'Bermudes',
  },
  {
    value: 'BT',
    label: 'Bhoutan',
  },
  {
    value: 'BO',
    label: 'Bolivie',
  },
  {
    value: 'BA',
    label: 'Bosnie Herzégovine',
  },
  {
    value: 'BW',
    label: 'Botwana',
  },
  {
    value: 'BR',
    label: 'Brésil',
  },
  {
    value: 'IO',
    label: 'Îles Vierges britanniques',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bulgarie',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Cambodge',
  },
  {
    value: 'CM',
    label: 'Cameroun',
  },
  {
    value: 'CA',
    label: 'Canada',
  },
  {
    value: 'KY',
    label: 'Îles Caïmans',
  },
  {
    value: 'CF',
    label: 'République centrafricaine',
  },
  {
    value: 'TD',
    label: 'Tchad',
  },
  {
    value: 'CL',
    label: 'Chili',
  },
  {
    value: 'CN',
    label: 'Chine',
  },
  {
    value: 'CO',
    label: 'Colombie',
  },
  {
    value: 'KM',
    label: 'Comores',
  },
  {
    value: 'CG',
    label: 'Congo',
  },
  {
    value: 'CK',
    label: 'les Îles Cook',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
  },
  {
    value: 'CI',
    label: "Côte d'Ivoire",
  },
  {
    value: 'HR',
    label: 'Croatie',
  },
  {
    value: 'CU',
    label: 'Cuba',
  },
  {
    value: 'CU',
    label: 'Curacao',
  },
  {
    value: 'CY',
    label: 'Chypre',
  },
  {
    value: 'CZ',
    label: 'République tchèque',
  },
  {
    value: 'CZ',
    label: 'République Populaire Démocratique de Corée',
  },
  {
    value: 'CD',
    label: 'République Démocratique du Congo',
  },
  {
    value: 'DK',
    label: 'Danemark',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Djibouti',
  },
  {
    value: 'DM',
    label: 'Dominique',
  },
  {
    value: 'DO',
    label: 'République dominicaine',
  },
  {
    value: 'EC',
    label: 'Equateur',
  },
  {
    value: 'EG',
    label: 'Egypte',
  },
  {
    value: 'SV',
    label: 'Le Salvador',
  },
  {
    value: 'GQ',
    label: 'Guinée Équatoriale',
  },
  {
    value: 'ER',
    label: 'Érythrée',
  },
  {
    value: 'EE',
    label: 'Estonie',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Ethiopie',
  },
  {
    value: 'FK',
    label: 'les îles Falkland',
  },
  {
    value: 'FO',
    label: 'Îles Féroé',
  },
  {
    value: 'FJ',
    label: 'Fidji',
  },
  {
    value: 'FI',
    label: 'Finlande',
  },
  {
    value: 'FR',
    label: 'France',
  },
  {
    value: 'FR',
    label: "Départements français et territoires dans l'océan Indien",
  },
  {
    value: 'GF',
    label: 'Guyane Française',
  },
  {
    value: 'PF',
    label: 'Polynésie française',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambie',
  },
  {
    value: 'GE',
    label: 'Géorgie',
  },
  {
    value: 'DE',
    label: 'Allemagne',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
  },
  {
    value: 'GR',
    label: 'Grèce',
  },
  {
    value: 'GL',
    label: 'Groenland',
  },
  {
    value: 'GD',
    label: 'Grenade',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  {
    value: 'GG',
    label: 'Guinée',
  },
  {
    value: 'GN',
    label: 'Guinée-Bissau',
  },
  {
    value: 'GY',
    label: 'Guyane',
  },
  {
    value: 'HT',
    label: 'Haïti',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hong Kong',
  },
  {
    value: 'HU',
    label: 'Hongrie',
  },
  {
    value: 'IS',
    label: 'Islande',
  },
  {
    value: 'IN',
    label: 'Inde',
  },
  {
    value: 'ID',
    label: 'Indonésie',
  },
  {
    value: 'IR',
    label: "L'Iran",
  },
  {
    value: 'IQ',
    label: 'Irak',
  },
  {
    value: 'IE',
    label: 'Irlande',
  },
  {
    value: 'IL',
    label: 'Israël',
  },
  {
    value: 'IT',
    label: 'Italie',
  },
  {
    value: 'JM',
    label: 'Jamaïque',
  },
  {
    value: 'JP',
    label: 'Japon',
  },
  {
    value: 'JO',
    label: 'Jordan',
  },
  {
    value: 'KZ',
    label: 'Kazakhstan',
  },
  {
    value: 'KE',
    label: 'Kenya',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Corée',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Koweit',
  },
  {
    value: 'KG',
    label: 'Kirghizistan',
  },
  {
    value: 'LA',
    label: 'République démocratique populaire lao',
  },
  {
    value: 'LV',
    label: 'Lettonie',
  },
  {
    value: 'LB',
    label: 'Liban',
  },
  {
    value: 'LS',
    label: 'Lesotho',
  },
  {
    value: 'LR',
    label: 'Libéria',
  },
  {
    value: 'LY',
    label: 'Libye',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
  },
  {
    value: 'LT',
    label: 'Lituanie',
  },
  {
    value: 'LU',
    label: 'Luxembourg',
  },
  {
    value: 'MO',
    label: 'Macao',
  },
  {
    value: 'MG',
    label: 'Madagascar',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Malaisie',
  },
  {
    value: 'MV',
    label: 'Maldives',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Malte',
  },
  {
    value: 'MH',
    label: 'Iles Marshall',
  },
  {
    value: 'MQ',
    label: 'Martinique',
  },
  {
    value: 'MR',
    label: 'Mauritanie',
  },
  {
    value: 'MU',
    label: 'Maurice',
  },
  {
    value: 'MX',
    label: 'Mexique',
  },
  {
    value: 'FM',
    label: 'Micronésie',
  },
  {
    value: 'MD',
    label: 'Moldavie',
  },
  {
    value: 'MC',
    label: 'Monaco',
  },
  {
    value: 'MN',
    label: 'Mongolie',
  },
  {
    value: 'ME',
    label: 'Monténégro',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Maroc',
  },
  {
    value: 'MZ',
    label: 'Mozambique',
  },
  {
    value: 'MM',
    label: 'Myanmar',
  },
  {
    value: 'NA',
    label: 'Namibie',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Népal',
  },
  {
    value: 'NL',
    label: 'Pays-Bas',
  },
  {
    value: 'NC',
    label: 'Nouvelle Calédonie',
  },
  {
    value: 'NZ',
    label: 'Nouvelle-Zélande',
  },
  {
    value: 'NI',
    label: 'Nicaragua',
  },
  {
    value: 'NE',
    label: 'Niger',
  },
  {
    value: 'NG',
    label: 'Nigeria',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: "l'ile de Norfolk",
  },
  {
    value: 'NF',
    label: 'Macédoine du Nord',
  },
  {
    value: 'MP',
    label: 'Îles Mariannes du Nord',
  },
  {
    value: 'NO',
    label: 'Norvège',
  },
  {
    value: 'OM',
    label: 'Oman',
  },
  {
    value: 'PK',
    label: 'Pakistan',
  },
  {
    value: 'PW',
    label: 'Palaos',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papouasie Nouvelle Guinée',
  },
  {
    value: 'PY',
    label: 'Paraguay',
  },
  {
    value: 'PE',
    label: 'Pérou',
  },
  {
    value: 'PH',
    label: 'Philippines',
  },
  {
    value: 'PL',
    label: 'Pologne',
  },
  {
    value: 'PT',
    label: 'le Portugal',
  },
  {
    value: 'PR',
    label: 'Porto Rico',
  },
  {
    value: 'QA',
    label: 'Qatar',
  },
  {
    value: 'RO',
    label: 'Roumanie',
  },
  {
    value: 'RU',
    label: 'Fédération Russe',
  },
  {
    value: 'RW',
    label: 'Rwanda',
  },
  {
    value: 'SH',
    label: 'Saint Helena, Ascension et Tristan da Cunha',
  },
  {
    value: 'KN',
    label: 'Saint-Christophe-et-Niévès',
  },
  {
    value: 'LC',
    label: 'Sainte-Lucie',
  },
  {
    value: 'PM',
    label: 'Saint-Pierre-et-Miquelon',
  },
  {
    value: 'VC',
    label: 'Saint-Vincent-et-les-Grenadines',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'Saint Marin',
  },
  {
    value: 'ST',
    label: 'Sao tome et principe',
  },
  {
    value: 'SA',
    label: 'Arabie Saoudite',
  },
  {
    value: 'SN',
    label: 'Sénégal',
  },
  {
    value: 'RS',
    label: 'Serbie',
  },
  {
    value: 'SC',
    label: 'les Seychelles',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Singapour',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Slovaquie',
  },
  {
    value: 'SI',
    label: 'Slovènie',
  },
  {
    value: 'SB',
    label: 'Les îles Salomon',
  },
  {
    value: 'SO',
    label: 'Somalie',
  },
  {
    value: 'ZA',
    label: 'Afrique du Sud',
  },
  {
    value: 'SS',
    label: 'Soudan du sud',
  },
  {
    value: 'ES',
    label: 'Espagne',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
  },
  {
    value: 'SD',
    label: 'Soudan',
  },
  {
    value: 'SR',
    label: 'Suriname',
  },
  {
    value: 'SE',
    label: 'Suède',
  },
  {
    value: 'CH',
    label: 'Suisse',
  },
  {
    value: 'SY',
    label: 'République arabe syrienne',
  },
  {
    value: 'TW',
    label: 'Taïwan',
  },
  {
    value: 'TJ',
    label: 'Tadjikistan',
  },
  {
    value: 'TZ',
    label: 'Tanzanie',
  },
  {
    value: 'TH',
    label: 'Thaïlande',
  },
  {
    value: 'TL',
    label: 'Timor-Leste',
  },
  {
    value: 'TG',
    label: 'République du Togo',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinité-et-Tobago',
  },
  {
    value: 'TN',
    label: 'Tunisie',
  },
  {
    value: 'TR',
    label: 'République de Turquie',
  },
  {
    value: 'TM',
    label: 'Turkménistan',
  },
  {
    value: 'TC',
    label: 'Îles Turques et Caïques',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Ouganda',
  },
  {
    value: 'UA',
    label: 'Ukraine',
  },
  {
    value: 'AE',
    label: 'Emirats Arabes Unis',
  },
  {
    value: 'GB',
    label: 'Royaume-Uni',
  },
  {
    value: 'US',
    label: 'États-Unis',
  },
  {
    value: 'US',
    label: 'Îles Vierges américaines',
  },
  {
    value: 'UY',
    label: 'Uruguay',
  },
  {
    value: 'UZ',
    label: 'Ouzbékistan',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Viet Nam',
  },
  {
    value: 'WF',
    label: 'Wallis et Futuna',
  },
  {
    value: 'YE',
    label: 'Yémen',
  },
  {
    value: 'ZM',
    label: 'Zambie',
  },
  {
    value: 'ZM',
    label: 'Zimbabwe',
  },
];
