import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';
import RequireAuth from './components/require-auth/require-auth';
import { ROUTES } from './constants/routes';
import CustomerWinefoPointDataWrapper from './screens/customer-winefo-point/customer-winefo-point-data-wrapper';
import EditLabelDataWrapper from './screens/edit-label/edit-label-data-wrapper';
import ForgotPassword from './screens/forgot-password/forgot-password';
import Labels from './screens/labels/labels';
import Licences from './screens/licences/licences';
import Login from './screens/login/login';
import ResetPassword from './screens/reset-password/reset-password';
import PremiumFeatures from './screens/settings/premium-features/premium-features';
import ProfileSettingsPage from './screens/settings/profile-settings/profile-settings-page';
import Settings from './screens/settings/settings';
import Signup from './screens/signup/signup';
import Stuck from './screens/stuck/stuck';
import VerifyEmail from './screens/verify-email/verify-email';
import { IngredientProvider } from './services/ingredient-context';
import { PictogramProvider } from './services/pictogram-context';
import WineryScreensWrapper from './components/winery-screens-wrapper/winery-screens-wrapper';

const Router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={ROUTES.COSTUMER_WINEFO_POINT}
        element={<CustomerWinefoPointDataWrapper />}
      />
      <Route path="/" element={<WineryScreensWrapper />}>
        <Route index element={<Navigate to={ROUTES.LABELS} />} />
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.SIGNUP} element={<Signup />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route
          path={ROUTES.VERIFY_EMAIL}
          element={
            <RequireAuth
              element={<VerifyEmail />}
              verifiedEmailRequired={false}
            />
          }
        />
        <Route
          path={ROUTES.LICENCES}
          element={<RequireAuth element={<Licences />} />}
        />
        <Route
          path={ROUTES.SETTINGS}
          element={<RequireAuth element={<Settings />} />}
        />
        <Route
          path={ROUTES.PROFILE_SETTINGS}
          element={<RequireAuth element={<ProfileSettingsPage />} />}
        />
        <Route
          path={ROUTES.PREMIUM_FEATURES}
          element={<RequireAuth element={<PremiumFeatures />} />}
        />
        <Route
          path={ROUTES.STUCK}
          element={<RequireAuth element={<Stuck />} />}
        />
        <Route
          path={ROUTES.LABELS}
          element={<RequireAuth element={<Labels />} />}
        />
        <Route
          path={ROUTES.LABEL}
          element={
            <RequireAuth
              element={
                <PictogramProvider>
                  <IngredientProvider>
                    <EditLabelDataWrapper />
                  </IngredientProvider>
                </PictogramProvider>
              }
            />
          }
        />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </>
  )
);

export default Router;
