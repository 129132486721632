import { useTranslation } from 'react-i18next';
import CountriesByCodeBg from '../../assets/countries/countries-by-code-bg';
import CountriesByCodeCs from '../../assets/countries/countries-by-code-cs';
import CountriesByCodeDa from '../../assets/countries/countries-by-code-da';
import CountriesByCodeDe from '../../assets/countries/countries-by-code-de';
import CountriesByCodeEl from '../../assets/countries/countries-by-code-el';
import CountriesByCodeEn from '../../assets/countries/countries-by-code-en';
import CountriesByCodeEs from '../../assets/countries/countries-by-code-es';
import CountriesByCodeEt from '../../assets/countries/countries-by-code-et';
import CountriesByCodeFi from '../../assets/countries/countries-by-code-fi';
import CountriesByCodeFr from '../../assets/countries/countries-by-code-fr';
import CountriesByCodeGa from '../../assets/countries/countries-by-code-ga';
import CountriesByCodeHr from '../../assets/countries/countries-by-code-hr';
import CountriesByCodeHu from '../../assets/countries/countries-by-code-hu';
import CountriesByCodeIt from '../../assets/countries/countries-by-code-it';
import CountriesByCodeLt from '../../assets/countries/countries-by-code-lt';
import CountriesByCodeLv from '../../assets/countries/countries-by-code-lv';
import CountriesByCodeMt from '../../assets/countries/countries-by-code-mt';
import CountriesByCodeNl from '../../assets/countries/countries-by-code-nl';
import CountriesByCodePl from '../../assets/countries/countries-by-code-pl';
import CountriesByCodePt from '../../assets/countries/countries-by-code-pt';
import CountriesByCodeRo from '../../assets/countries/countries-by-code-ro';
import CountriesByCodeSk from '../../assets/countries/countries-by-code-sk';
import CountriesByCodeSl from '../../assets/countries/countries-by-code-sl';
import CountriesByCodeSv from '../../assets/countries/countries-by-code-sv';
import { LANGUAGES } from '../../constants/languages';

const useCountriesByCode = (): Array<{ label: string; value: string }> => {
  const { i18n } = useTranslation();

  switch (i18n.language) {
    case LANGUAGES.bg:
      return CountriesByCodeBg;
    case LANGUAGES.cs:
      return CountriesByCodeCs;
    case LANGUAGES.da:
      return CountriesByCodeDa;
    case LANGUAGES.de:
      return CountriesByCodeDe;
    case LANGUAGES.el:
      return CountriesByCodeEl;
    case LANGUAGES.en:
      return CountriesByCodeEn;
    case LANGUAGES.es:
      return CountriesByCodeEs;
    case LANGUAGES.et:
      return CountriesByCodeEt;
    case LANGUAGES.fi:
      return CountriesByCodeFi;
    case LANGUAGES.fr:
      return CountriesByCodeFr;
    case LANGUAGES.hr:
      return CountriesByCodeHr;
    case LANGUAGES.hu:
      return CountriesByCodeHu;
    case LANGUAGES.ga:
      return CountriesByCodeGa;
    case LANGUAGES.it:
      return CountriesByCodeIt;
    case LANGUAGES.lt:
      return CountriesByCodeLt;
    case LANGUAGES.lv:
      return CountriesByCodeLv;
    case LANGUAGES.nl:
      return CountriesByCodeNl;
    case LANGUAGES.mt:
      return CountriesByCodeMt;
    case LANGUAGES.pl:
      return CountriesByCodePl;
    case LANGUAGES.pt:
      return CountriesByCodePt;
    case LANGUAGES.ro:
      return CountriesByCodeRo;
    case LANGUAGES.sk:
      return CountriesByCodeSk;
    case LANGUAGES.sl:
      return CountriesByCodeSl;
    case LANGUAGES.sv:
      return CountriesByCodeSv;
    default:
      return CountriesByCodeEn;
  }
};

export default useCountriesByCode;
