import { Button, ButtonProps } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';

const SettingsItem: React.FC<ButtonProps> = ({
  children,
  sx,
  ...otherProps
}) => (
  <Button
    variant="text"
    endIcon={<ChevronRightIcon />}
    size="small"
    sx={{
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '166%',
      letterSpacing: '0.4px',
      width: '100%',
      justifyContent: 'start',
      ...sx,
    }}
    {...otherProps}
  >
    {children}
  </Button>
);

export default SettingsItem;
