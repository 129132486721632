import { Link, LinkProps } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import bottle from '../../assets/images/bottle-complete-illustration.svg';
import logo from '../../assets/images/logo-horizontal.svg';
import { ROUTES } from '../../constants/routes';
import { WINEFO_URL } from '../../constants/urls';

interface LogoProps extends LinkProps {
  isProtected?: boolean;
  isInverse?: boolean;
}

const Logo: React.FC<LogoProps> = ({ isProtected, isInverse }) => {
  const navigate = useNavigate();

  return (
    <Link
      href={isProtected ? undefined : WINEFO_URL}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
      onClick={() => {
        if (isProtected) {
          navigate(ROUTES.LABELS);
        }
      }}
      sx={{ cursor: 'pointer' }}
    >
      <img
        src={isInverse ? bottle : logo}
        style={{ height: 65, width: '100%' }}
      />
    </Link>
  );
};

export default Logo;
