import {
  ALCOHOL_VOLUMEN_TO_KCAL,
  ORGANIC_ACID_GRAMS_PER_LITER_TO_KCAL,
  POLYOL_GRAMS_PER_LITER_TO_KCAL,
  SUGAR_GRAMS_PER_LITER_TO_KCAL,
} from '../../constants/unit-convertion';

export const nutritionsToKcal = (
  alcohol: number,
  sugar: number,
  acid: number,
  polyol: number
) => {
  return (
    alcohol * ALCOHOL_VOLUMEN_TO_KCAL +
    sugar * SUGAR_GRAMS_PER_LITER_TO_KCAL +
    acid * ORGANIC_ACID_GRAMS_PER_LITER_TO_KCAL +
    polyol * POLYOL_GRAMS_PER_LITER_TO_KCAL
  );
};
