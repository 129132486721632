import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { PaymentStarted, StartOnlinePaymentRequest } from '../../api';
import paymentApi from './payment-api';

interface StartSubscriptionPaymentParams {
  variables: StartOnlinePaymentRequest;
  onSuccess: (paymentLink?: string) => void;
}

const useStartSubscriptionPayment = (
  params: StartSubscriptionPaymentParams
): UseMutationResult<
  AxiosResponse<PaymentStarted>,
  unknown,
  unknown,
  unknown
> => {
  return useMutation({
    mutationFn: () => paymentApi.startSubscriptionPayment(params.variables),
    onSuccess: (response: AxiosResponse<PaymentStarted, unknown>) =>
      params.onSuccess(response.data.paymentLink),
  });
};

export default useStartSubscriptionPayment;
