import React from 'react';
import { Translation } from '../../../api';
import CustomIngredientTranslation from './custom-ingredient-translation';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import CustomIngredientTranslationsButton from './custom-ingredient-translations-button';
import { useSnackbar } from '../../../services/snackbar-context';
import { useTranslation } from 'react-i18next';

interface CustomIngredientTranslationsProps {
  translations: Translation[];
  onChange: (newTranslations: Translation[]) => void;
  errors: boolean[];
  submitted: boolean;
}

const CustomIngredientTranslations: React.FC<
  CustomIngredientTranslationsProps
> = ({ translations, onChange, errors, submitted }) => {
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const getHiddenLanguageCodes = (languageCode?: string) =>
    translations
      .map((it) => it.languageCode)
      .filter(
        (it) => it !== languageCode && typeof it === 'string'
      ) as string[];
  const handleTranslationChange = (
    newTranslation: Translation,
    translationIndex: number
  ) => {
    onChange(
      translations.map((it, index) =>
        translationIndex === index ? newTranslation : it
      )
    );
  };
  const addTranslation = () => {
    onChange([...translations, { name: '' }]);
  };
  const deleteTranslation = (translationIndex: number) => {
    onChange(translations.filter((_, index) => index !== translationIndex));
    setSnackbar({
      message: t(
        'edit-label.ingredients-section.translations-modal.delete-message'
      ),
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
    });
  };

  return (
    <FlexBoxColumn gap={2}>
      {translations.map((translation, index) => (
        <CustomIngredientTranslation
          key={(translation.languageCode || '') + index}
          translation={translation}
          onChange={(newTranslation) =>
            handleTranslationChange(newTranslation, index)
          }
          onDelete={() => deleteTranslation(index)}
          hiddenLanguageCodes={getHiddenLanguageCodes(translation.languageCode)}
          hideDeleteButton={index === 0}
          error={submitted && errors[index]}
        />
      ))}
      <CustomIngredientTranslationsButton
        version="more"
        onClick={addTranslation}
        sx={{ mr: 'auto' }}
      />
    </FlexBoxColumn>
  );
};

export default CustomIngredientTranslations;
