import {
  Autocomplete,
  AutocompleteOwnerState,
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  TextField,
  TextFieldProps,
} from '@mui/material';
import {
  AutocompleteHighlightChangeReason,
  createFilterOptions,
} from '@mui/material/useAutocomplete';

import React from 'react';
import { partialSearchFilterOptions } from '../../utils/select/select-utils';

export interface SearchableSelectOptions {
  key: string;
  value: string;
  disabled?: boolean;
}

export interface SearchableSelectProps
  extends Partial<
    AutocompleteProps<SearchableSelectOptions, false, false, false | true>
  > {
  optionMap: SearchableSelectOptions[];
  textFieldProps: TextFieldProps;
  freeSolo?: boolean;
  onSelectChange?: (selected?: string | null) => void;
  partialSearch?: boolean;
}

const SearchableSelect = React.forwardRef<
  HTMLInputElement,
  SearchableSelectProps
>((props: React.PropsWithChildren<SearchableSelectProps>, ref) => {
  const {
    optionMap: options,
    textFieldProps,
    freeSolo,
    onSelectChange,
    partialSearch,
    renderOption,
    renderTags,
    groupBy,
    onHighlightChange,
    ...autocompleteProps
  } = props;
  const calcFreeSolo = freeSolo ?? true;
  return (
    <Autocomplete
      {...autocompleteProps}
      freeSolo={calcFreeSolo}
      filterOptions={
        partialSearch ? partialSearchFilterOptions : createFilterOptions()
      }
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return (
            props.optionMap.find((it) => it.value === option)?.key || option
          );
        }
        return option.key;
      }}
      getOptionDisabled={(option) => {
        if (typeof option === 'string') {
          return !!props.optionMap.find((it) => it.value === option)?.disabled;
        }
        return !!option.disabled;
      }}
      renderOption={
        typeof renderOption === 'undefined'
          ? undefined
          : (
              renderOptionProps: React.HTMLAttributes<HTMLLIElement>,
              option: string | SearchableSelectOptions,
              state: AutocompleteRenderOptionState
            ) => {
              return renderOption(
                renderOptionProps,
                typeof option === 'string'
                  ? { key: option, value: option }
                  : option,
                state
              );
            }
      }
      renderTags={
        typeof renderTags === 'undefined'
          ? undefined
          : (
              value: (string | SearchableSelectOptions)[],
              getTagProps: AutocompleteRenderGetTagProps,
              ownerState: AutocompleteOwnerState<
                string | SearchableSelectOptions,
                false,
                false,
                boolean,
                'div'
              >
            ) => {
              return renderTags(
                value.map((it) =>
                  typeof it === 'string' ? { key: it, value: it } : it
                ),
                getTagProps,
                ownerState as AutocompleteOwnerState<
                  SearchableSelectOptions,
                  false,
                  false,
                  boolean,
                  'div'
                >
              );
            }
      }
      renderInput={(params: AutocompleteRenderInputParams) => {
        return <TextField {...params} {...textFieldProps} ref={ref} />;
      }}
      groupBy={
        typeof groupBy === 'undefined'
          ? undefined
          : (option: string | SearchableSelectOptions) =>
              groupBy(
                typeof option === 'string'
                  ? { key: option, value: option }
                  : option
              )
      }
      isOptionEqualToValue={(
        option: SearchableSelectOptions | string,
        currentValue: SearchableSelectOptions | string
      ) => {
        if (typeof currentValue === 'string') {
          if (typeof option === 'string') {
            return option === currentValue;
          }
          return option.value === currentValue;
        }
        if (typeof option === 'string') {
          return option === currentValue.value;
        }
        return option.value === currentValue.value;
      }}
      onChange={(e, data) => {
        if (onSelectChange) {
          if (!data) {
            onSelectChange(undefined);
          } else if (typeof data === 'string') {
            const newVal =
              props.optionMap.find((it) => it.key === data)?.value ?? data;
            onSelectChange(newVal);
          } else {
            onSelectChange(data.value);
          }
        }
      }}
      onHighlightChange={
        typeof onHighlightChange === 'undefined'
          ? undefined
          : (
              event: React.SyntheticEvent<Element, Event>,
              option: string | SearchableSelectOptions | null,
              reason: AutocompleteHighlightChangeReason
            ) => {
              onHighlightChange(
                event,
                typeof option === 'string'
                  ? { key: option, value: option }
                  : option,
                reason
              );
            }
      }
      value={autocompleteProps.value || null}
    />
  );
});

SearchableSelect.displayName = 'SearchableSelect';

export default SearchableSelect;
