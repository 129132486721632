import { BoxProps } from '@mui/material';
import React from 'react';
import { HEADER_HEIGHT } from '../../constants/dimensions';
import FlexBox from '../flexbox/flexbox';

export interface BackgroundProps extends BoxProps {
  imageSource: string;
  isCardCentered?: boolean;
}

const Background: React.FC<BackgroundProps> = ({
  imageSource,
  isCardCentered,
  children,
  sx,
  ...props
}) => {
  return (
    <FlexBox
      sx={{
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        backgroundImage: `url(${imageSource})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        alignItems: 'center',
        justifyContent: isCardCentered ? 'center' : 'flex-start',
        position: 'relative',
        ...sx,
      }}
      {...props}
    >
      {children}
    </FlexBox>
  );
};

export default Background;
