import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { JWT_TOKEN_KEY } from '../constants/storage-keys';
import useLogout from '../data/logout/use-logout';
import { AuthService } from '../models/authorization';
import { UserDetails } from '../models/user';
import isTokenSimple from '../utils/authorization/is-token-simple';
import { useQueryClient } from '@tanstack/react-query';

export const useAuthService = (): AuthService => {
  const queryClient = useQueryClient();
  const revokeTokens = useLogout({
    onSuccess: () => localStorage.removeItem(JWT_TOKEN_KEY),
  });

  const logout = async () => {
    const currentSession = await Auth.currentSession();
    queryClient.removeQueries();
    Auth.signOut({ global: true }).then(() => {
      revokeTokens.mutate({
        refreshToken: currentSession.getRefreshToken().getToken(),
      });
    });
  };

  const getToken = (): string | null => {
    return localStorage.getItem(JWT_TOKEN_KEY);
  };

  const setToken = (token: string | null): void => {
    if (token) {
      localStorage.setItem(JWT_TOKEN_KEY, token);
    } else {
      logout();
    }
  };

  const login = async (email: string, password: string): Promise<unknown> => {
    return Auth.signIn({ username: email, password }).then((response) => {
      setToken(response.signInUserSession.accessToken.jwtToken);
    });
  };

  const currentAuthenticatedUser = (): Promise<CognitoUser> => {
    return Auth.currentAuthenticatedUser();
  };

  const userInfo = (): Promise<UserDetails> => {
    return Auth.currentUserInfo().then((details) => {
      return {
        id: details.id,
        username: details.username,
        attributes: {
          email_verified: details.attributes.email_verified,
          email: details.attributes.email,
        },
      };
    });
  };

  const hasValidSimpleToken = (): boolean => {
    const token = getToken();
    if (token) {
      if (isTokenSimple(token)) {
        const payload: { iss?: string; exp?: number } = jwt_decode(token);
        const epoch = new Date().getTime() / 1000;
        if (
          payload.iss?.split('/')[1] ===
            process.env.REACT_APP_AWS_USER_POOL_ID &&
          (payload.exp || 0) > epoch
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const isUserVerified = async (): Promise<boolean | undefined> => {
    if (hasValidSimpleToken()) {
      return Promise.resolve(true);
    }
    return userInfo().then((user) => {
      return user.attributes.email_verified;
    });
  };

  const sendVerificationEmail = async (lang: string): Promise<void> => {
    return currentAuthenticatedUser().then((user) => {
      return Auth.verifyUserAttribute(user, 'email', {
        lang,
      });
    });
  };

  const forgotPassword = async (
    email: string,
    lang: string
  ): Promise<unknown> => {
    return Auth.forgotPassword(email, {
      env: process.env.REACT_APP_TARGET || '',
      lang,
    });
  };

  const forgotPasswordSubmit = async (
    userName: string,
    code: string,
    password: string
  ): Promise<unknown> => {
    return Auth.forgotPasswordSubmit(userName, code, password);
  };

  const changePassword = async (
    oldPassword: string,
    password: string
  ): Promise<'SUCCESS'> => {
    return Auth.currentAuthenticatedUser().then((user) => {
      return Auth.changePassword(user, oldPassword, password);
    });
  };

  const removeAccount = async (): Promise<void> => {
    const user = await currentAuthenticatedUser();
    return new Promise((resolve, reject) =>
      user.deleteUser((err?: Error) => (err ? reject() : resolve()))
    );
  };

  const createAccount = async (
    email: string,
    password: string
  ): Promise<unknown> => {
    const username = uuidv4();
    await Auth.signUp({
      username,
      password,
      attributes: {
        email,
      },
    });
    const user = await Auth.signIn({ username, password });
    localStorage.setItem(
      JWT_TOKEN_KEY,
      user.signInUserSession.idToken.jwtToken
    );
    return Auth.currentAuthenticatedUser().catch(async (e) => {
      await removeAccount();
      throw e;
    });
  };

  const refresh = async (): Promise<void> => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    return new Promise<void>((resolve) => {
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (err: Error, session: CognitoUserSession) => {
          setToken(session.getIdToken().getJwtToken());
          resolve();
        }
      );
    });
  };

  const verify = (code: string): Promise<boolean> => {
    return Auth.verifyCurrentUserAttributeSubmit('email', code)
      .then(() => refresh())
      .then(() => true);
  };

  const currentSession = (): Promise<CognitoUserSession> => {
    return Auth.currentSession();
  };

  return {
    currentAuthenticatedUser,
    removeAccount,
    createAccount,
    sendVerificationEmail,
    verify,
    logout,
    getToken,
    login,
    isUserVerified,
    currentSession,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
  };
};
