import { Grid } from '@mui/material';
import React from 'react';
import {
  locationBlockFields,
  useSignupFormUtils,
} from '../../screens/signup/signup-form-utils';
import CountrySelectWithController from '../country-select/country-select-with-controller';
import TextFieldWithController from '../text-field/text-field-with-controller';
import { SignupSplitFormProps } from '../../models/signup';

const LocationForm: React.FC<SignupSplitFormProps> = ({
  control,
  formState,
}) => {
  const { getTextFieldProps } = useSignupFormUtils(formState);

  return (
    <Grid container spacing={2} sx={{ my: 0.5 }}>
      {locationBlockFields
        .filter((it) => it !== 'country')
        .map((field) => (
          <Grid item xs={12} key={field}>
            <TextFieldWithController
              control={control}
              name={field}
              textFieldProps={getTextFieldProps(field)}
            />
          </Grid>
        ))}
      <Grid item xs={12}>
        <CountrySelectWithController
          textFieldProps={getTextFieldProps('country')}
          control={control}
          name="country"
        />
      </Grid>
    </Grid>
  );
};

export default LocationForm;
