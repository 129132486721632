import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import copy from 'copy-to-clipboard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../services/snackbar-context';

interface CopyLinkButtonProps {
  link: string;
}

const CopyLinkButton: React.FC<CopyLinkButtonProps> = ({ link }) => {
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const copyLinkToClipboard = () => {
    copy(link);
    setSnackbar({
      message: t('labels.copied-snackbar'),
    });
  };

  return (
    <IconButton onClick={copyLinkToClipboard} sx={{ ml: 'auto', mr: 'auto' }}>
      <ContentCopyIcon />
    </IconButton>
  );
};

export default CopyLinkButton;
