import { Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import FlexBox from '../../../components/flexbox/flexbox';
import { ICON_MEDIUM2_SIZE } from '../../../constants/dimensions';

interface ProfileFormSectionHeaderProps {
  icon: ReactElement;
  title: string;
}

const ProfileFormSectionHeader: React.FC<ProfileFormSectionHeaderProps> = ({
  icon,
  title,
}) => {
  const theme = useTheme();

  return (
    <FlexBox
      color={theme.palette.primary.main}
      fontSize={ICON_MEDIUM2_SIZE}
      mr={1}
      gap="10px"
      alignItems="center"
      pt={4}
    >
      {icon}
      <Typography color="initial">{title}</Typography>
    </FlexBox>
  );
};

export default ProfileFormSectionHeader;
