import {
  WineLabelCustomPictogramViewPictogramGroupEnum as CustomPictogramGroup,
  PictogramsDtoDetailedSustainabilityEnum,
  PictogramsDtoResponsibleConsumptionEnum,
  PictogramsDtoSustainabilityEnum,
} from '../api';
import Alu41 from '../assets/pictograms/alu41.svg';
import Calu90 from '../assets/pictograms/calu90.svg';
import CpetCpetg90 from '../assets/pictograms/cpetCpetg90.svg';
import Cpvc90 from '../assets/pictograms/cpvc90.svg';
import DrinkingAndDriving from '../assets/pictograms/drinking-and-driving.svg';
import EggSulphiteLogo from '../assets/pictograms/egg-and-sulphite.svg';
import EighteenPlus from '../assets/pictograms/eighteen-plus.svg';
import Fe40 from '../assets/pictograms/fe40.svg';
import For50 from '../assets/pictograms/for50.svg';
import For51 from '../assets/pictograms/for51.svg';
import Gl70 from '../assets/pictograms/gl70.svg';
import Gl71 from '../assets/pictograms/gl71.svg';
import Gl72 from '../assets/pictograms/gl72.svg';
import Hdpe2 from '../assets/pictograms/hdpe2.svg';
import Ldpe4 from '../assets/pictograms/ldpe4.svg';
import MilkSulphiteLogo from '../assets/pictograms/milk-and-sulphite.svg';
import MilkEggSulphiteLogo from '../assets/pictograms/milk-egg-and-sulphite.svg';
import MobiusLoop from '../assets/pictograms/mobius-loop.svg';
import O07 from '../assets/pictograms/o07.svg';
import OrganicLogo from '../assets/pictograms/organic-logo.svg';
import Pap20 from '../assets/pictograms/pap20.svg';
import Pap22 from '../assets/pictograms/pap22.svg';
import Pp5 from '../assets/pictograms/pp5.svg';
import PregnantWoman from '../assets/pictograms/pregnant-woman.svg';
import SulphiteLogo from '../assets/pictograms/sulphite.svg';
import Tex62 from '../assets/pictograms/tex62.svg';
import GreenDot from '../assets/pictograms/the-green-dot.svg';
import TrimanExample from '../assets/pictograms/triman-example.svg';
import Triman from '../assets/pictograms/triman.svg';
import WineInModeration from '../assets/pictograms/wine-in-moderation.svg';
import {
  DetailedSustainabilityCategoryEnum,
  DetailedSustainabilityWaste,
  Pictogram,
} from '../models/pictogram';

export const SustainabilityPictograms: Pictogram[] = [
  {
    id: PictogramsDtoSustainabilityEnum.GreenDot,
    src: GreenDot,
    name: 'pictograms.green-dot',
  },
  {
    id: PictogramsDtoSustainabilityEnum.MobiusLoop,
    src: MobiusLoop,
    name: 'pictograms.mobius-loop',
  },
  {
    id: PictogramsDtoSustainabilityEnum.OrganicLogo,
    src: OrganicLogo,
    name: 'pictograms.organic-logo',
  },
  {
    id: PictogramsDtoSustainabilityEnum.TrimanExample,
    src: TrimanExample,
    name: 'pictograms.triman-example',
  },
  {
    id: PictogramsDtoSustainabilityEnum.Triman,
    src: Triman,
    name: 'pictograms.triman',
  },
];

export const ResponsibilityPictograms: Pictogram[] = [
  {
    id: PictogramsDtoResponsibleConsumptionEnum.Pregnancy,
    src: PregnantWoman,
    name: 'pictograms.during-pregnancy',
  },
  {
    id: PictogramsDtoResponsibleConsumptionEnum.DrinkingAndDriving,
    src: DrinkingAndDriving,
    name: 'pictograms.drinking-and-driving',
  },
  {
    id: PictogramsDtoResponsibleConsumptionEnum.EighteenPlus,
    src: EighteenPlus,
    name: 'pictograms.eighteen-plus',
  },
  {
    id: PictogramsDtoResponsibleConsumptionEnum.WineInModeration,
    src: WineInModeration,
    name: 'pictograms.wine-in-moderation',
  },
];

export const AllergenPictograms: Pictogram[] = [
  {
    id: 'SULPHITE',
    src: SulphiteLogo,
    name: 'pictograms.sulphite',
  },
  {
    id: 'MILK_SULPHITE',
    src: MilkSulphiteLogo,
    name: 'pictograms.milk-sulphite',
  },
  {
    id: 'EGG_SULPHITE',
    src: EggSulphiteLogo,
    name: 'pictograms.egg-sulphite',
  },
  {
    id: 'MILK_EGG_SULPHITE',
    src: MilkEggSulphiteLogo,
    name: 'pictograms.milk-egg-sulphite',
  },
];

export const DEFAULT_PICTOGRAMS = {
  [CustomPictogramGroup.ResponsibleCons]: ResponsibilityPictograms,
  [CustomPictogramGroup.Sustainability]: SustainabilityPictograms,
  [CustomPictogramGroup.Allergen]: AllergenPictograms,
  [CustomPictogramGroup.Origin]: [],
  [CustomPictogramGroup.DetailedSustainability]: [],
};

export const ALLOWED_EXTENSIONS = ['png', 'jpg', 'jpeg'];
export const MAX_FILE_SIZE = 5242880; // 5MB

export const DetailedSustainabilityPictograms: Pictogram[] = [
  {
    id: PictogramsDtoDetailedSustainabilityEnum.ClearGlass,
    src: Gl70,
    name: 'pictograms.clear-glass',
    details: {
      category: DetailedSustainabilityCategoryEnum.BOTTLE,
      resinCode: 'GL70',
      waste: DetailedSustainabilityWaste.GLASS,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.GreenBlueGlass,
    src: Gl71,
    name: 'pictograms.green-blue-glass',
    details: {
      category: DetailedSustainabilityCategoryEnum.BOTTLE,
      resinCode: 'GL71',
      waste: DetailedSustainabilityWaste.GLASS,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.BrownGlass,
    src: Gl72,
    name: 'pictograms.brown-glass',
    details: {
      category: DetailedSustainabilityCategoryEnum.BOTTLE,
      resinCode: 'GL72',
      waste: DetailedSustainabilityWaste.GLASS,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.Cork,
    src: For51,
    name: 'pictograms.cork',
    details: {
      category: DetailedSustainabilityCategoryEnum.CLOSURE,
      resinCode: 'FOR51',
      waste: DetailedSustainabilityWaste.SEPARATED,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.AluminumScrewCap,
    src: Calu90,
    name: 'pictograms.aluminum-screw-cap',
    details: {
      category: DetailedSustainabilityCategoryEnum.CLOSURE,
      resinCode: 'C/ALU90',
      waste: DetailedSustainabilityWaste.METAL,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.SyntheticClosure,
    src: Ldpe4,
    name: 'pictograms.synthetic-closure',
    details: {
      category: DetailedSustainabilityCategoryEnum.CLOSURE,
      resinCode: 'LDPE4',
      waste: DetailedSustainabilityWaste.PLASTIC,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.SyntheticMushroomCapInWhite,
    src: Ldpe4,
    name: 'pictograms.synthetic-mushroom-cap-in-white',
    details: {
      category: DetailedSustainabilityCategoryEnum.CLOSURE,
      resinCode: 'LDPE4',
      waste: DetailedSustainabilityWaste.PLASTIC,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.SyntheticMushroomCapInBrown,
    src: Ldpe4,
    name: 'pictograms.synthetic-mushroom-cap-in-brown',
    details: {
      category: DetailedSustainabilityCategoryEnum.CLOSURE,
      resinCode: 'LDPE4',
      waste: DetailedSustainabilityWaste.PLASTIC,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.LongScrewCap,
    src: Calu90,
    name: 'pictograms.long-screw-cap',
    details: {
      category: DetailedSustainabilityCategoryEnum.CLOSURE,
      resinCode: 'C/ALU90',
      waste: DetailedSustainabilityWaste.METAL,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.ShortScrewCap,
    src: Calu90,
    name: 'pictograms.short-screw-cap',
    details: {
      category: DetailedSustainabilityCategoryEnum.CLOSURE,
      resinCode: 'C/ALU90',
      waste: DetailedSustainabilityWaste.METAL,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.EasyToOpen,
    src: Hdpe2,
    name: 'pictograms.easy-to-open',
    details: {
      category: DetailedSustainabilityCategoryEnum.CLOSURE,
      resinCode: 'HDPE2',
      waste: DetailedSustainabilityWaste.PLASTIC,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.PvcShrinkCapsule,
    src: Cpvc90,
    name: 'pictograms.pvc-shrink-capsule',
    details: {
      category: DetailedSustainabilityCategoryEnum.CAPSULE,
      resinCode: 'C/PVC90',
      waste: DetailedSustainabilityWaste.PLASTIC,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.EtShrinkCapsule,
    src: CpetCpetg90,
    name: 'pictograms.et-shrink-capsule',
    details: {
      category: DetailedSustainabilityCategoryEnum.CAPSULE,
      resinCode: 'C/PETG 90 & C/PET90',
      waste: DetailedSustainabilityWaste.PLASTIC,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.TinCapsule,
    src: Fe40,
    name: 'pictograms.tin-capsule',
    details: {
      category: DetailedSustainabilityCategoryEnum.CAPSULE,
      resinCode: 'FE40',
      waste: DetailedSustainabilityWaste.METAL,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.PolylaminatedAluminiumCapsule,
    src: Calu90,
    name: 'pictograms.polylaminated-aluminium-capsule',
    details: {
      category: DetailedSustainabilityCategoryEnum.CAPSULE,
      resinCode: 'C/ALU90',
      waste: DetailedSustainabilityWaste.METAL,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.PolylaminatedChampagneCapsule,
    src: Calu90,
    name: 'pictograms.polylaminated-champagne-capsule',
    details: {
      category: DetailedSustainabilityCategoryEnum.CAPSULE,
      resinCode: 'C/ALU90',
      waste: DetailedSustainabilityWaste.METAL,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.AgraffeForChampagneCaps,
    src: Fe40,
    name: 'pictograms.agraffe-for-champagne-caps',
    details: {
      category: DetailedSustainabilityCategoryEnum.AGRAFFE,
      resinCode: 'FE40',
      waste: DetailedSustainabilityWaste.METAL,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.Cardboard,
    src: Pap20,
    name: 'pictograms.cardboard',
    details: {
      category: DetailedSustainabilityCategoryEnum.CARDBOARD_BOX,
      resinCode: 'PAP20',
      waste: DetailedSustainabilityWaste.PAPER,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.GridInserts,
    src: Pap20,
    name: 'pictograms.grid-inserts',
    details: {
      category: DetailedSustainabilityCategoryEnum.CARDBOARD_BOX,
      resinCode: 'PAP20',
      waste: DetailedSustainabilityWaste.PAPER,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.CardboardAndGridInserts,
    src: Pap20,
    name: 'pictograms.cardboard-and-grid-inserts',
    details: {
      category: DetailedSustainabilityCategoryEnum.CARDBOARD_BOX,
      resinCode: 'PAP20',
      waste: DetailedSustainabilityWaste.PAPER,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.WoodenBox,
    src: For50,
    name: 'pictograms.wooden-box',
    details: {
      category: DetailedSustainabilityCategoryEnum.WOODEN_BOX,
      resinCode: 'FOR50',
      waste: DetailedSustainabilityWaste.SEPARATED,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.ShrinkFilmWooden,
    src: Pp5,
    name: 'pictograms.shrink-film-wooden',
    details: {
      category: DetailedSustainabilityCategoryEnum.WOODEN_BOX,
      resinCode: 'PP5',
      waste: DetailedSustainabilityWaste.PLASTIC,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.Can,
    src: Alu41,
    name: 'pictograms.can',
    details: {
      category: DetailedSustainabilityCategoryEnum.CAN,
      resinCode: 'ALU41',
      waste: DetailedSustainabilityWaste.METAL,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.PaperTissue,
    src: Pap22,
    name: 'pictograms.paper-tissue',
    details: {
      category: DetailedSustainabilityCategoryEnum.TISSUE,
      resinCode: 'PAP22',
      waste: DetailedSustainabilityWaste.PAPER,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.PaperHangTag,
    src: Pap22,
    name: 'pictograms.paper-hang-tag',
    details: {
      category: DetailedSustainabilityCategoryEnum.HANG_TAG,
      resinCode: 'PAP22',
      waste: DetailedSustainabilityWaste.PAPER,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.PaperCardboardBox,
    src: Pap20,
    name: 'pictograms.paper-cardboard-box',
    details: {
      category: DetailedSustainabilityCategoryEnum.CARDBOARD_BOX_2_3_BOTTLES,
      resinCode: 'PAP20',
      waste: DetailedSustainabilityWaste.PAPER,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.ShrinkFilmThermal,
    src: Pp5,
    name: 'pictograms.shrink-film-thermal',
    details: {
      category: DetailedSustainabilityCategoryEnum.THERMAL,
      resinCode: 'PP05',
      waste: DetailedSustainabilityWaste.PLASTIC,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.Tray,
    src: Pap20,
    name: 'pictograms.tray',
    details: {
      category: DetailedSustainabilityCategoryEnum.THERMAL,
      resinCode: 'PAP20',
      waste: DetailedSustainabilityWaste.PAPER,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.BagInBoxPlexiGlass,
    src: O07,
    name: 'pictograms.bag-in-box-plexi-glass',
    details: {
      category: DetailedSustainabilityCategoryEnum.PLEXI_GLASS,
      resinCode: 'O07',
      waste: DetailedSustainabilityWaste.UNDIFFERENTIATED,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.Fabric,
    src: Tex62,
    name: 'pictograms.fabric',
    details: {
      category: DetailedSustainabilityCategoryEnum.YARN,
      resinCode: 'TEX62',
      waste: DetailedSustainabilityWaste.SEPARATED,
    },
  },
  {
    id: PictogramsDtoDetailedSustainabilityEnum.BagInBoxBag,
    src: O07,
    name: 'pictograms.bag-in-box-bag',
    details: {
      category: DetailedSustainabilityCategoryEnum.BAG,
      resinCode: 'O07',
      waste: DetailedSustainabilityWaste.UNDIFFERENTIATED,
    },
  },
];
