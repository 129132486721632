import { GridProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WineLabelCustomPictogramViewPictogramGroupEnum as CustomPictogramGroup } from '../../api';
import { TempPictogram } from '../../models/pictogram';
import { usePictogramContext } from '../../services/pictogram-context';
import { useSnackbar } from '../../services/snackbar-context';
import PictogramItem from './pictogram-item';

interface TempPictogramsProps {
  group: CustomPictogramGroup;
  onPictogramToggle?: () => void;
  gridProps?: GridProps;
}

const TempPictograms: React.FC<TempPictogramsProps> = ({
  group,
  onPictogramToggle,
  gridProps,
}) => {
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const { tempPictograms, removeTempPictogram, toggleTempChecked } =
    usePictogramContext();

  const handleToggle = (
    pictogram: TempPictogram,
    type: CustomPictogramGroup
  ) => {
    toggleTempChecked(pictogram, type);
    onPictogramToggle?.();
  };
  const handleDelete = (pictogram: TempPictogram) => {
    removeTempPictogram(pictogram, group);
    setSnackbar({ message: t('edit-label.pictogram-delete-snackbar') });
  };

  return (
    <>
      {tempPictograms[group].map((pictogram) => (
        <PictogramItem
          key={pictogram.tempId}
          checked={pictogram.checked}
          value={pictogram.tempId}
          onChange={() => handleToggle(pictogram, group)}
          imageSrc={pictogram.src}
          label={pictogram.name}
          onDelete={() => handleDelete(pictogram)}
          gridProps={gridProps}
        />
      ))}
    </>
  );
};

export default TempPictograms;
