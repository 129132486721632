import { FormControlLabel, Checkbox } from '@mui/material';
import React, { useState } from 'react';
import IngredientLabel from '../../../components/ingredient-label/ingredient-label';
import { Ingredient } from '../../../models/ingredient';
import useModal from '../../../utils/modal/use-modal';
import { useIngredientContext } from '../../../services/ingredient-context';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/modal/confirmation-modal';

interface IngredientControlsProps {
  ingredient: Ingredient;
}

const IngredientControls: React.FC<IngredientControlsProps> = ({
  ingredient,
}) => {
  const { t } = useTranslation();
  const { selectDefaultIngredient, unselectDefaultIngredient } =
    useIngredientContext();
  const { openModal, closeModal, isModalOpen } = useModal();
  const [ingredientToUnselect, setIngredientToUnselect] =
    useState<Ingredient>();
  const handleIngredientToggle = (value: Ingredient, checked: boolean) => {
    if (checked) {
      selectDefaultIngredient(value);
    } else {
      const hasSubIngredients = value.subIngredients?.ingredients.some(
        (it) => it.isSelected
      );
      if (hasSubIngredients) {
        setIngredientToUnselect(value);
        openModal();
      } else {
        unselectDefaultIngredient(value);
      }
    }
  };
  const handleIngredientUnSelectWithSubIngredients = () => {
    if (ingredientToUnselect) unselectDefaultIngredient(ingredientToUnselect);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!ingredient.isSelected}
            onChange={(event, checked) =>
              handleIngredientToggle(ingredient, checked)
            }
          />
        }
        label={<IngredientLabel ingredient={ingredient} />}
      />
      <ConfirmationModal
        open={isModalOpen}
        onConfirm={handleIngredientUnSelectWithSubIngredients}
        onClose={closeModal}
        labels={{
          message: t(
            'edit-label.ingredients-section.delete-with-sub-ingredients-message'
          ),
          confirm: t(
            'edit-label.ingredients-section.delete-with-sub-ingredients-confirm'
          ),
        }}
      />
    </>
  );
};

export default IngredientControls;
