import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import backgroundImage from '../../assets/images/forgot-password-background.svg';
import ResetPasswordCardContent from '../../components/reset-password/reset-password-card-content';
import { ResetPasswordFormValue } from '../../components/reset-password/reset-password-form';
import TemplateWithCard from '../../components/screen-template/template-with-card';
import { ROUTES } from '../../constants/routes';
import { useAuthContext } from '../../services/auth-context';

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const code = searchParams.get('code');
  const userName = searchParams.get('userName');
  const { forgotPasswordSubmit } = useAuthContext();
  const resetPassword = (data: ResetPasswordFormValue) => {
    if (code && userName)
      forgotPasswordSubmit(userName, code, data.password).then(() =>
        navigate(ROUTES.LOGIN)
      );
  };

  useEffect(() => {
    if (!code || !userName) navigate(ROUTES.LOGIN);
  }, []);

  return (
    <TemplateWithCard
      backgroundProps={{ imageSource: backgroundImage }}
      cardContent={<ResetPasswordCardContent onSubmitSuccess={resetPassword} />}
    />
  );
};

export default ResetPassword;
