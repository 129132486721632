import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import i18n from 'i18next';
import dayjs, { Dayjs } from 'dayjs';

export type DatePickerWithControllerProps<T extends FieldValues, S> = {
  control: Control<T>;
  name: FieldPath<T>;
  datePickerProps: DatePickerProps<S>;
};

const DatePickerWithController = <ObjectType extends FieldValues>({
  control,
  name,
  datePickerProps,
  ...otherProps
}: DatePickerWithControllerProps<ObjectType, Dayjs>) => {
  return (
    <Controller
      render={({ field: { ...fieldProps } }) => (
        <LocalizationProvider
          adapterLocale={i18n?.language}
          dateAdapter={AdapterDayjs}
        >
          <DatePicker
            {...datePickerProps}
            {...fieldProps}
            {...otherProps}
            value={
              (fieldProps.value && dayjs().set('year', fieldProps.value)) || ''
            }
            onYearChange={(value) => {
              fieldProps.onChange(value?.year());
            }}
            slotProps={{
              ...datePickerProps.slotProps,
              field: {
                onKeyDown: (e) => {
                  if (fieldProps.value >= 1000) {
                    e.preventDefault();
                  }
                },
              },
            }}
            onChange={(value) => fieldProps.onChange(value?.year())}
          />
        </LocalizationProvider>
      )}
      name={name}
      control={control}
    />
  );
};

export default DatePickerWithController;
