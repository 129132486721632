import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ProductSectionIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 28">
      <g id="product-section-icon">
        <path d="M22.125 7.78437V1.8125C22.125 1.56386 22.0262 1.3254 21.8504 1.14959C21.6746 0.973772 21.4361 0.875 21.1875 0.875H18.375V2.75H20.25V9.05C20.25 9.05 23.0625 9.52531 23.0625 13.0625V25.25H20.25V27.125H24C24.2486 27.125 24.4871 27.0262 24.6629 26.8504C24.8387 26.6746 24.9375 26.4361 24.9375 26.1875V13.0625C24.9375 9.82625 23.1253 8.3525 22.125 7.78437ZM15.5625 7.78437V1.8125C15.5625 1.56386 15.4637 1.3254 15.2879 1.14959C15.1121 0.973772 14.8736 0.875 14.625 0.875H11.8125V2.75H13.6875V9.05C13.6875 9.05 16.5 9.52531 16.5 13.0625V25.25H13.6875V27.125H17.4375C17.6861 27.125 17.9246 27.0262 18.1004 26.8504C18.2762 26.6746 18.375 26.4361 18.375 26.1875V13.0625C18.375 9.82625 16.5628 8.3525 15.5625 7.78437ZM9 7.78437V1.8125C9 1.56386 8.90123 1.3254 8.72541 1.14959C8.5496 0.973772 8.31114 0.875 8.0625 0.875H4.3125C4.06386 0.875 3.8254 0.973772 3.64959 1.14959C3.47377 1.3254 3.375 1.56386 3.375 1.8125V7.78437C2.37469 8.3525 0.5625 9.82625 0.5625 13.0625V26.1875C0.5625 26.4361 0.661272 26.6746 0.837087 26.8504C1.0129 27.0262 1.25136 27.125 1.5 27.125H10.875C11.1236 27.125 11.3621 27.0262 11.5379 26.8504C11.7137 26.6746 11.8125 26.4361 11.8125 26.1875V13.0625C11.8125 9.82625 10.0003 8.3525 9 7.78437ZM9.9375 25.25H2.4375V13.0625C2.4375 9.52625 5.25 9.05 5.25 9.05V2.75H7.125V9.05C7.125 9.05 9.9375 9.52625 9.9375 13.0625V25.25Z" />
      </g>
    </SvgIcon>
  );
};

export default ProductSectionIcon;
