import { styled } from '@mui/material';

export const hideArrows = {
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  'input[type=number]': {
    MozAppearance: 'textfield',
  },
};

const NumberInputWithoutArrows = styled('input')({
  '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
    display: 'none',
  },

  '&[type=number]': {
    MozAppearance: 'textfield',
  },
});

export default NumberInputWithoutArrows;
