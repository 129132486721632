/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Info } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { FieldPathValue } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { PremiumFeature, WineLabelView } from '../../api';
import HeaderIcon from '../../assets/icons/nutrition-filled-icon';
import CustomerContainer from '../../components/customer-container/customer-container';
import FlexBox from '../../components/flexbox/flexbox';
import NutritionTable from '../../components/nutrition-table/nutrition-table';
import { getColors } from './winefo-point-utils';

interface NutritionDeclarationProps {
  nutrition?: FieldPathValue<WineLabelView, 'nutritionDeclaration'>;
  premiumFeature?: PremiumFeature;
}

const NutritionDeclaration = ({
  nutrition,
  premiumFeature,
}: NutritionDeclarationProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const colors = getColors(premiumFeature);

  return nutrition ? (
    <CustomerContainer
      title={t('common.nutrition-declaration')}
      icon={<HeaderIcon />}
      colors={colors}
    >
      <Box p={2} maxWidth={500}>
        <NutritionTable value={nutrition} premiumFeature={premiumFeature} />
      </Box>
      <FlexBox gap={1.5} px={2} pb={2} color={palette.primaryVariations[700]}>
        <Info />
        <Typography>{t('winefo-point.average-values-message')}</Typography>
      </FlexBox>
    </CustomerContainer>
  ) : (
    <></>
  );
};

export default NutritionDeclaration;
