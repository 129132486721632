export default [
  {
    value: 'AF',
    label: 'Afghánistán',
  },
  {
    value: 'AL',
    label: 'Albánie',
  },
  {
    value: 'DZ',
    label: 'Alžírsko',
  },
  {
    value: 'AS',
    label: 'Americká Samoa',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguilla',
  },
  {
    value: 'AG',
    label: 'Antigua a Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentina',
  },
  {
    value: 'AM',
    label: 'Arménie',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Austrálie',
  },
  {
    value: 'AT',
    label: 'Rakousko',
  },
  {
    value: 'AZ',
    label: 'Ázerbajdžán',
  },
  {
    value: 'BS',
    label: 'Bahamy',
  },
  {
    value: 'BH',
    label: 'Bahrajn',
  },
  {
    value: 'BD',
    label: 'Bangladéš',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Bělorusko',
  },
  {
    value: 'BE',
    label: 'Belgie',
  },
  {
    value: 'BZ',
    label: 'Belize',
  },
  {
    value: 'BJ',
    label: 'Benin',
  },
  {
    value: 'BM',
    label: 'Bermudy',
  },
  {
    value: 'BT',
    label: 'Bhútán',
  },
  {
    value: 'BO',
    label: 'Bolívie',
  },
  {
    value: 'BA',
    label: 'Bosna a Hercegovina',
  },
  {
    value: 'BW',
    label: 'Botswana',
  },
  {
    value: 'BR',
    label: 'Brazílie',
  },
  {
    value: 'IO',
    label: 'Britské Panenské ostrovy',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bulharsko',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo verde',
  },
  {
    value: 'KH',
    label: 'Kambodža',
  },
  {
    value: 'CM',
    label: 'Kamerun',
  },
  {
    value: 'CA',
    label: 'Kanada',
  },
  {
    value: 'KY',
    label: 'Kajmanské ostrovy',
  },
  {
    value: 'CF',
    label: 'Středoafrická republika',
  },
  {
    value: 'TD',
    label: 'Čad',
  },
  {
    value: 'CL',
    label: 'Chile',
  },
  {
    value: 'CN',
    label: 'Čína',
  },
  {
    value: 'CO',
    label: 'Kolumbie',
  },
  {
    value: 'KM',
    label: 'Comoros',
  },
  {
    value: 'CG',
    label: 'Kongo',
  },
  {
    value: 'CK',
    label: 'Cookovy ostrovy',
  },
  {
    value: 'CR',
    label: 'Kostarika',
  },
  {
    value: 'CI',
    label: "Côte d'ivoire",
  },
  {
    value: 'HR',
    label: 'Chorvatsko',
  },
  {
    value: 'CU',
    label: 'Kuba',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Kypr',
  },
  {
    value: 'CZ',
    label: 'Česká republika',
  },
  {
    value: 'CZ',
    label: 'Korejská lidově demokratická republika',
  },
  {
    value: 'CD',
    label: 'Demokratická republika Kongo',
  },
  {
    value: 'DK',
    label: 'Dánsko',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Džibutské',
  },
  {
    value: 'DM',
    label: 'Dominika',
  },
  {
    value: 'DO',
    label: 'Dominikánská republika',
  },
  {
    value: 'EC',
    label: 'Ekvádor',
  },
  {
    value: 'EG',
    label: 'Egypt',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'GQ',
    label: 'Rovníková Guinea',
  },
  {
    value: 'ER',
    label: 'Eritrea',
  },
  {
    value: 'EE',
    label: 'Estonsko',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etiopie',
  },
  {
    value: 'FK',
    label: 'Falklandy',
  },
  {
    value: 'FO',
    label: 'Faerské ostrovy',
  },
  {
    value: 'FJ',
    label: 'Fidži',
  },
  {
    value: 'FI',
    label: 'Finsko',
  },
  {
    value: 'FR',
    label: 'Francie',
  },
  {
    value: 'FR',
    label: 'Francouzská oddělení a území v Indickém oceánu',
  },
  {
    value: 'GF',
    label: 'Francouzská Guiana',
  },
  {
    value: 'PF',
    label: 'Francouzská Polynésie',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambie',
  },
  {
    value: 'GE',
    label: 'Gruzie',
  },
  {
    value: 'DE',
    label: 'Německo',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
  },
  {
    value: 'GR',
    label: 'Řecko',
  },
  {
    value: 'GL',
    label: 'Grónsko',
  },
  {
    value: 'GD',
    label: 'Grenada',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  {
    value: 'GG',
    label: 'Guinea',
  },
  {
    value: 'GN',
    label: 'Guinea-Bissau',
  },
  {
    value: 'GY',
    label: 'Guyana',
  },
  {
    value: 'HT',
    label: 'Haiti',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hongkong',
  },
  {
    value: 'HU',
    label: 'Maďarsko',
  },
  {
    value: 'IS',
    label: 'Island',
  },
  {
    value: 'IN',
    label: 'Indie',
  },
  {
    value: 'ID',
    label: 'Indonésie',
  },
  {
    value: 'IR',
    label: 'Írán',
  },
  {
    value: 'IQ',
    label: 'Irák',
  },
  {
    value: 'IE',
    label: 'Irsko',
  },
  {
    value: 'IL',
    label: 'Izrael',
  },
  {
    value: 'IT',
    label: 'Itálie',
  },
  {
    value: 'JM',
    label: 'Jamaica',
  },
  {
    value: 'JP',
    label: 'Japonsko',
  },
  {
    value: 'JO',
    label: 'Jordán',
  },
  {
    value: 'KZ',
    label: 'Kazachstán',
  },
  {
    value: 'KE',
    label: 'Keňa',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Korea',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuvajt',
  },
  {
    value: 'KG',
    label: 'Kyrgyzstán',
  },
  {
    value: 'LA',
    label: 'Laoská lidově demokratická republika',
  },
  {
    value: 'LV',
    label: 'Lotyšsko',
  },
  {
    value: 'LB',
    label: 'Libanon',
  },
  {
    value: 'LS',
    label: 'Lesotho',
  },
  {
    value: 'LR',
    label: 'Libérie',
  },
  {
    value: 'LY',
    label: 'Libye',
  },
  {
    value: 'LI',
    label: 'Lichtenstein',
  },
  {
    value: 'LT',
    label: 'Litva',
  },
  {
    value: 'LU',
    label: 'Lucembursko',
  },
  {
    value: 'MO',
    label: 'Macao',
  },
  {
    value: 'MG',
    label: 'Madagaskar',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Malajsie',
  },
  {
    value: 'MV',
    label: 'Maledivy',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Marshallovy ostrovy',
  },
  {
    value: 'MQ',
    label: 'Martinika',
  },
  {
    value: 'MR',
    label: 'Mauritánie',
  },
  {
    value: 'MU',
    label: 'Mauricius',
  },
  {
    value: 'MX',
    label: 'Mexiko',
  },
  {
    value: 'FM',
    label: 'Mikronésie',
  },
  {
    value: 'MD',
    label: 'Moldavsko',
  },
  {
    value: 'MC',
    label: 'Monako',
  },
  {
    value: 'MN',
    label: 'Mongolsko',
  },
  {
    value: 'ME',
    label: 'Černá Hora',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Maroko',
  },
  {
    value: 'MZ',
    label: 'Mozambik',
  },
  {
    value: 'MM',
    label: 'Myanmar',
  },
  {
    value: 'NA',
    label: 'Namibie',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepál',
  },
  {
    value: 'NL',
    label: 'Holandsko',
  },
  {
    value: 'NC',
    label: 'Nová Kaledonie',
  },
  {
    value: 'NZ',
    label: 'Nový Zéland',
  },
  {
    value: 'NI',
    label: 'Nikaragua',
  },
  {
    value: 'NE',
    label: 'Niger',
  },
  {
    value: 'NG',
    label: 'Nigérie',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Norfolk Island',
  },
  {
    value: 'NF',
    label: 'Severní Makedonie',
  },
  {
    value: 'MP',
    label: 'Severní ostrovy Mariana',
  },
  {
    value: 'NO',
    label: 'Norsko',
  },
  {
    value: 'OM',
    label: 'Omán',
  },
  {
    value: 'PK',
    label: 'Pákistán',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papua-Nová Guinea',
  },
  {
    value: 'PY',
    label: 'Paraguay',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'PH',
    label: 'Filipíny',
  },
  {
    value: 'PL',
    label: 'Polsko',
  },
  {
    value: 'PT',
    label: 'Portugalsko',
  },
  {
    value: 'PR',
    label: 'Portoriko',
  },
  {
    value: 'QA',
    label: 'Katar',
  },
  {
    value: 'RO',
    label: 'Rumunsko',
  },
  {
    value: 'RU',
    label: 'Ruská Federace',
  },
  {
    value: 'RW',
    label: 'Rwanda',
  },
  {
    value: 'SH',
    label: 'Svatý Helena, Ascension a Tristan da Cunha',
  },
  {
    value: 'KN',
    label: 'Saint Kitts a Nevis',
  },
  {
    value: 'LC',
    label: 'Svatá Lucia',
  },
  {
    value: 'PM',
    label: 'Svatý Pierre a Miquelon',
  },
  {
    value: 'VC',
    label: 'Svatý Vincent a Grenadiny',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao Tome a Principe',
  },
  {
    value: 'SA',
    label: 'Saudská arábie',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Srbsko',
  },
  {
    value: 'SC',
    label: 'Seychely',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Singapur',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Slovensko',
  },
  {
    value: 'SI',
    label: 'Slovinsko',
  },
  {
    value: 'SB',
    label: 'Solomonovy ostrovy',
  },
  {
    value: 'SO',
    label: 'Somálsko',
  },
  {
    value: 'ZA',
    label: 'Jižní Afrika',
  },
  {
    value: 'SS',
    label: 'jižní Súdán',
  },
  {
    value: 'ES',
    label: 'Španělsko',
  },
  {
    value: 'LK',
    label: 'Srí Lanka',
  },
  {
    value: 'SD',
    label: 'Súdán',
  },
  {
    value: 'SR',
    label: 'Surinam',
  },
  {
    value: 'SE',
    label: 'Švédsko',
  },
  {
    value: 'CH',
    label: 'Švýcarsko',
  },
  {
    value: 'SY',
    label: 'Syrská Arabská republika',
  },
  {
    value: 'TW',
    label: 'Tchaj -wan',
  },
  {
    value: 'TJ',
    label: 'Tádžikistán',
  },
  {
    value: 'TZ',
    label: 'Tanzanie',
  },
  {
    value: 'TH',
    label: 'Thajsko',
  },
  {
    value: 'TL',
    label: 'Timor-Leste',
  },
  {
    value: 'TG',
    label: 'Togo Republic',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinidad a Tobago',
  },
  {
    value: 'TN',
    label: 'Tunisko',
  },
  {
    value: 'TR',
    label: 'Turecká republika',
  },
  {
    value: 'TM',
    label: 'Turkmenistán',
  },
  {
    value: 'TC',
    label: 'Turci a Caicos',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukrajina',
  },
  {
    value: 'AE',
    label: 'Spojené arabské emiráty',
  },
  {
    value: 'GB',
    label: 'Spojené království',
  },
  {
    value: 'US',
    label: 'Spojené státy',
  },
  {
    value: 'US',
    label: 'Panenské ostrovy Spojených států',
  },
  {
    value: 'UY',
    label: 'Uruguay',
  },
  {
    value: 'UZ',
    label: 'Uzbekistán',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Viet Nam',
  },
  {
    value: 'WF',
    label: 'Wallis a Futuna',
  },
  {
    value: 'YE',
    label: 'Jemen',
  },
  {
    value: 'ZM',
    label: 'Zambie',
  },
  {
    value: 'ZM',
    label: 'Zimbabwe',
  },
];
