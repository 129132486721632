import { Box } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CompanySectionIcon from '../../../assets/icons/company-section-icon';
import PictogramSectionIcon from '../../../assets/icons/pictogram-section-icon';
import ProductSectionIcon from '../../../assets/icons/product-section-icon';
import CollapsibleBlocks from '../../../components/collapsible-blocks/collapsible-blocks';
import {
  EditLabelSections,
  EditLabelSplitFormProps,
  LabelValue,
} from '../../../models/label';
import { usePictogramContext } from '../../../services/pictogram-context';
import SectionHeader from '../section-header';
import CompanySubsection from './company-subsection';
import PictogramSubSection from './pictogram-subsection';
import ProductSubsection from './product-subsection';

interface GeneralInfoSectionProps extends EditLabelSplitFormProps {
  expandedBlocks: EditLabelSections[];
  handleBlockToggle: (
    value: EditLabelSections
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
  getValues: UseFormGetValues<LabelValue>;
  setValue: UseFormSetValue<LabelValue>;
}

const GeneralInfoSection: React.FC<GeneralInfoSectionProps> = ({
  expandedBlocks,
  handleBlockToggle,
  ...rest
}) => {
  const { t } = useTranslation();
  const { tempPictograms, hasCustomPictogramChange } = usePictogramContext();
  const hasUnsavedPictogramChange =
    tempPictograms.RESPONSIBLE_CONS.length > 0 ||
    tempPictograms.SUSTAINABILITY.length > 0 ||
    hasCustomPictogramChange.RESPONSIBLE_CONS ||
    hasCustomPictogramChange.SUSTAINABILITY;
  const hasUnsavedChanges =
    (rest.formState.isDirty &&
      [
        EditLabelSections.COMPANY,
        EditLabelSections.PRODUCT,
        EditLabelSections.PICTOGRAM,
      ].includes(expandedBlocks[0])) ||
    hasUnsavedPictogramChange;

  return (
    <Box my={1}>
      <SectionHeader section="general" withSaveButton={hasUnsavedChanges} />
      <CollapsibleBlocks
        sx={{
          bgcolor: 'inherit',
          mb: 1,
          boxShadow: 0,
          position: 'inherit',
        }}
        blocks={[
          {
            title: t('edit-label.general-section.subsections.company'),
            icon: <CompanySectionIcon fontSize="inherit" />,
            details: <CompanySubsection {...rest} />,
            value: EditLabelSections.COMPANY,
          },
          {
            title: t('edit-label.general-section.subsections.product'),
            icon: <ProductSectionIcon fontSize="inherit" />,
            details: <ProductSubsection {...rest} />,
            value: EditLabelSections.PRODUCT,
          },
          {
            title: t('edit-label.general-section.subsections.pictograms'),
            icon: <PictogramSectionIcon fontSize="inherit" />,
            details: <PictogramSubSection {...rest} />,
            value: EditLabelSections.PICTOGRAM,
          },
        ]}
        expandedBlocks={expandedBlocks}
        handleBlockToggle={handleBlockToggle}
      />
    </Box>
  );
};

export default GeneralInfoSection;
