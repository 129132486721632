import { Card, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import successIllustration from '../../assets/images/success-illustration.svg';
import FlexBox from '../../components/flexbox/flexbox';

const FirstLabelCard: React.FC = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <FlexBox justifyContent="center">
      <Card
        sx={{
          width: '500px',
          py: '50px',
          backgroundColor: palette.grey[100],
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2" sx={{ color: palette.primaryVariations[700] }}>
          {t('labels.ready')}
        </Typography>
        <Typography
          variant="h5"
          sx={{ maxWidth: '230px', textAlign: 'center', my: 1 }}
        >
          {t('labels.first-label')}
        </Typography>
        <img src={successIllustration} style={{ width: '168px' }} />
      </Card>
    </FlexBox>
  );
};

export default FirstLabelCard;
