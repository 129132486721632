import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import equal from 'react-fast-compare';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import useUpdatePremiumFeatures from '../../../data/premium-features/use-update-premium-features';
import { usePrevious } from '../../../utils/common/use-previous';
import Colors from './colors';
import Fonts from './fonts';
import Logos from './logos';
import PremiumFeaturesFormHeader from './premium-features-form-header';
import premiumFeaturesFormSchema from './premium-features-form-schema';
import { getLogoIdsToDelete, toPremiumFeature } from './premium-features-utils';
import { PremiumFeaturesFormValue } from './premium-features.types';
import useDeleteLogos from './use-delete-logos';
import useUploadLogos from './use-upload-logos';
import NavigationGuard from '../../../components/navigation-guard/navigation-guard';
import { useSnackbar } from '../../../services/snackbar-context';

interface PremiumFeaturesFormProps {
  defaultValue?: PremiumFeaturesFormValue;
  onChange: (value: PremiumFeaturesFormValue) => void;
}

const PremiumFeaturesForm: React.FC<PremiumFeaturesFormProps> = ({
  defaultValue,
  onChange,
}) => {
  const { t } = useTranslation();
  const previousDefaultValue = usePrevious(defaultValue);
  const { setSnackbar } = useSnackbar();
  const { handleSubmit, formState, control, watch, reset } =
    useForm<PremiumFeaturesFormValue>({
      mode: 'all',
      defaultValues: defaultValue,
      resolver: yupResolver(premiumFeaturesFormSchema()),
    });
  const { deleteLogos } = useDeleteLogos();
  const { uploadLogos } = useUploadLogos();
  const updatePremiumFeatures = useUpdatePremiumFeatures();
  const handleFormSubmitSuccess = async (data: PremiumFeaturesFormValue) => {
    const logoIdsToDelete = getLogoIdsToDelete(data, defaultValue);
    await deleteLogos(logoIdsToDelete);
    const uploadedLogos = await uploadLogos(data.logos);
    const mutationData = toPremiumFeature(data, uploadedLogos);
    updatePremiumFeatures.mutate({
      data: mutationData,
      onSuccess: () => reset({}, { keepValues: true }),
    });
  };
  const handleFormSubmitError = () => {
    setSnackbar({
      message: t('common.submit-error'),
    });
  };
  const formValue = watch();
  const submitBeforeNavigation = (
    continueNavigation: () => void,
    closeNavigationGuardModal?: () => void
  ) => {
    handleSubmit(
      (data) => {
        handleFormSubmitSuccess(data).then(() => {
          continueNavigation();
        });
      },
      () => {
        closeNavigationGuardModal?.();
        handleFormSubmitError();
      }
    )();
  };

  useEffect(() => {
    if (!equal(previousDefaultValue, defaultValue)) reset(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    onChange(formValue);
  }, [formValue]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmitSuccess)}>
      <NavigationGuard
        shouldBlockNavigation={formState.isDirty}
        beforeNavigation={submitBeforeNavigation}
      />
      <Box>
        <PremiumFeaturesFormHeader formState={formState} />
        <Typography variant="h5" sx={{ mt: 4, mb: 2.5, mx: 5 }}>
          {t('premium-features.title')}
        </Typography>
        <FlexBoxColumn mx={6} gap={2}>
          <Logos control={control} />
          <Divider />
          <Colors control={control} formState={formState} />
          <Divider sx={{ my: 2 }} />
          <Fonts control={control} />
        </FlexBoxColumn>
      </Box>
    </form>
  );
};

export default PremiumFeaturesForm;
