import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/loading/loading';
import { ROUTES } from '../../constants/routes';
import useGetWineLabel from '../../data/wine-label/use-get-wine-label';
import { useIngredientContext } from '../../services/ingredient-context';
import { usePictogramContext } from '../../services/pictogram-context';
import EditLabel from './edit-label';
import { toLabelValue } from './edit-label-utils';

const EditLabelDataWrapper: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useGetWineLabel(Number(id), {
    onError: () => navigate(ROUTES.LABELS),
  });
  const { setInitialIngredientsSequencesFlexibles } = useIngredientContext();
  const { setCustomPictograms } = usePictogramContext();
  const labelValue = useMemo(
    () => (data ? toLabelValue(data) : undefined),
    [data]
  );

  useEffect(() => {
    setInitialIngredientsSequencesFlexibles(data?.ingredient);
    setCustomPictograms(Array.from(data?.customPictograms || []));
  }, [data]);

  return (
    <>{labelValue ? <EditLabel labelValue={labelValue} /> : <Loading />}</>
  );
};

export default EditLabelDataWrapper;
