import { SvgIcon, SvgIconProps } from '@mui/material';

const SustainabilityIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 24" fill="none">
      <path d="M17.5 8C8.5 10 6.4 16.17 4.32 21.34L6.21 22L7.16 19.7C7.64 19.87 8.14 20 8.5 20C19.5 20 22.5 3 22.5 3C21.5 5 14.5 5.25 9.5 6.25C4.5 7.25 2.5 11.5 2.5 13.5C2.5 15.5 4.25 17.25 4.25 17.25C7.5 8 17.5 8 17.5 8Z" />
    </SvgIcon>
  );
};

export default SustainabilityIcon;
