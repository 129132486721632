import { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../../services/auth-context';
import { usePrevious } from '../common/use-previous';

export enum UserStatus {
  LOADING,
  OK,
  NOT_VERIFIED,
  NOT_LOGGED_IN,
}

const useUserStatus = (verifiedEmailRequired: boolean): UserStatus => {
  const { isUserVerified } = useAuthContext();
  const [userStatus, setUserStatus] = useState<UserStatus>(UserStatus.LOADING);
  const [userStatusSet, setUserStatusSet] = useState(false);
  const mountedRef = useRef(true);
  const previousVerifiedEmailRequired = usePrevious(verifiedEmailRequired);
  const verifiedEmailRequirementChanged =
    previousVerifiedEmailRequired !== verifiedEmailRequired;

  useEffect(() => {
    isUserVerified()
      .then((verified) => {
        if (!mountedRef.current && !verifiedEmailRequirementChanged) return;
        if (!verifiedEmailRequired || verified) {
          setUserStatus(UserStatus.OK);
          return;
        }
        if (verified === undefined) {
          setUserStatus(UserStatus.NOT_LOGGED_IN);
          return;
        }
        if (!verified) {
          setUserStatus(UserStatus.NOT_VERIFIED);
        }
      })
      .catch(() => {
        if (!mountedRef.current) return;
        setUserStatus(UserStatus.NOT_LOGGED_IN);
      })
      .finally(() => {
        setUserStatusSet(true);
      });

    return () => {
      if (userStatusSet) mountedRef.current = false;
    };
  }, [isUserVerified, setUserStatus, verifiedEmailRequired]);

  return userStatus;
};

export default useUserStatus;
