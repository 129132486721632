import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Ingredient } from '../../models/ingredient';

interface IngredientLabelProps {
  ingredient?: Ingredient;
}

const IngredientLabel: React.FC<IngredientLabelProps> = ({ ingredient }) => {
  const { t } = useTranslation();

  return (
    <Typography
      component="span"
      fontWeight={ingredient?.allergen ? 'bold' : 'normal'}
      fontFamily="inherit"
    >
      {ingredient?.isCustom
        ? ingredient?.name
        : t(`ingredients.${ingredient?.name}`)}
    </Typography>
  );
};

export default IngredientLabel;
