import { yupResolver } from '@hookform/resolvers/yup';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PasswordFieldWithController from '../password-field/password-field-with-controller';
import resetPasswordFormSchema from './reset-password-form-schema';

export interface ResetPasswordFormValue {
  currentPassword?: string;
  password: string;
}
export interface ResetPasswordFormProps {
  onSubmitSuccess: (data: ResetPasswordFormValue) => void;
  withCurrentPassword?: boolean;
  isCurrentPasswordInvalid?: boolean;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  onSubmitSuccess,
  withCurrentPassword,
  isCurrentPasswordInvalid,
}) => {
  const { t } = useTranslation();
  const defaultValues = {
    currentPassword: '',
    password: '',
  };
  const { handleSubmit, control, formState } = useForm<ResetPasswordFormValue>({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(resetPasswordFormSchema(withCurrentPassword)),
  });
  const { errors } = formState;
  const isPasswordError = !!errors.password;
  const isCurrentPasswordError =
    !!errors.currentPassword || isCurrentPasswordInvalid;

  return (
    <form onSubmit={handleSubmit(onSubmitSuccess)}>
      {withCurrentPassword && (
        <PasswordFieldWithController
          control={control}
          name="currentPassword"
          textFieldProps={{
            label: t('reset-password.current-password'),
            error: isCurrentPasswordError,
            fullWidth: true,
            helperText:
              isCurrentPasswordInvalid &&
              t('reset-password.current-password-error-message'),
            sx: { mb: 2 },
          }}
        />
      )}
      <PasswordFieldWithController
        control={control}
        name="password"
        textFieldProps={{
          label: t('reset-password.new-password'),
          error: isPasswordError,
          fullWidth: true,
          helperText: isPasswordError
            ? t('reset-password.error-message')
            : t('auth.password-helper-text'),
        }}
      />
      <Button
        variant="contained"
        fullWidth
        endIcon={<ChevronRightIcon />}
        type="submit"
        sx={{ mt: 4 }}
      >
        {t('reset-password.set-password')}
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
