import { Card, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PlaceholderImage from '../../../assets/images/preview-placeholder-illustration.svg';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import FlexBox from '../../../components/flexbox/flexbox';
import { warning } from '../../../constants/colors';
import { AllergenEnum } from '../../../models/ingredient';
import { LabelValue } from '../../../models/label';
import { useIngredientContext } from '../../../services/ingredient-context';
import AllergenSelector from './allergen-selector';
import Preview from './preview';

interface PreviewSubsectionProps {
  control: Control<LabelValue>;
  setAllergen: (value?: AllergenEnum) => void;
}

const PreviewSubsection: React.FC<PreviewSubsectionProps> = ({
  control,
  setAllergen,
}) => {
  const { t } = useTranslation();
  const { sequencedIngredientsAndGroups } = useIngredientContext();

  return (
    <FlexBoxColumn>
      <Controller
        name="ingredient.ingredientGroupOrders"
        control={control}
        render={() =>
          sequencedIngredientsAndGroups.length ? (
            <>
              <Preview />
              <AllergenSelector control={control} setAllergen={setAllergen} />
            </>
          ) : (
            <Card
              elevation={5}
              sx={{
                width: '461px',
                p: 4,
                my: 1,
                mx: 'auto',
                borderRadius: '10px',
              }}
            >
              <FlexBox gap={2} alignItems="center" justifyContent="center">
                <img src={PlaceholderImage} />
                <Typography
                  variant="h6"
                  fontSize={16}
                  color={warning}
                  textAlign="center"
                >
                  {t('edit-label.preview-placeholder')}
                </Typography>
              </FlexBox>
            </Card>
          )
        }
      />
    </FlexBoxColumn>
  );
};

export default PreviewSubsection;
