export default [
  {
    value: 'AF',
    label: 'Afganistan',
  },
  {
    value: 'AL',
    label: 'Albania',
  },
  {
    value: 'DZ',
    label: 'Algieria',
  },
  {
    value: 'AS',
    label: 'Samoa Amerykańskie',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguilla',
  },
  {
    value: 'AG',
    label: 'Antigua i Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentyna',
  },
  {
    value: 'AM',
    label: 'Armenia',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Australia',
  },
  {
    value: 'AT',
    label: 'Austria',
  },
  {
    value: 'AZ',
    label: 'Azerbejdżan',
  },
  {
    value: 'BS',
    label: 'Bahamy',
  },
  {
    value: 'BH',
    label: 'Bahrajn',
  },
  {
    value: 'BD',
    label: 'Bangladesz',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Białoruś',
  },
  {
    value: 'BE',
    label: 'Belgia',
  },
  {
    value: 'BZ',
    label: 'Belize',
  },
  {
    value: 'BJ',
    label: 'Benin',
  },
  {
    value: 'BM',
    label: 'Bermudy',
  },
  {
    value: 'BT',
    label: 'Bhutan',
  },
  {
    value: 'BO',
    label: 'Boliwia',
  },
  {
    value: 'BA',
    label: 'Bośnia i Hercegowina',
  },
  {
    value: 'BW',
    label: 'Botswana',
  },
  {
    value: 'BR',
    label: 'Brazylia',
  },
  {
    value: 'IO',
    label: 'Brytyjskie Wyspy Dziewicze',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bułgaria',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Kambodża',
  },
  {
    value: 'CM',
    label: 'Kamerun',
  },
  {
    value: 'CA',
    label: 'Kanada',
  },
  {
    value: 'KY',
    label: 'Kajmany',
  },
  {
    value: 'CF',
    label: 'Republika Środkowoafrykańska',
  },
  {
    value: 'TD',
    label: 'Chad',
  },
  {
    value: 'CL',
    label: 'Chile',
  },
  {
    value: 'CN',
    label: 'Chiny',
  },
  {
    value: 'CO',
    label: 'Kolumbia',
  },
  {
    value: 'KM',
    label: 'Comoros',
  },
  {
    value: 'CG',
    label: 'Kongo',
  },
  {
    value: 'CK',
    label: 'Wyspy Cooka',
  },
  {
    value: 'CR',
    label: 'Kostaryka',
  },
  {
    value: 'CI',
    label: "Côte d'Ovoire",
  },
  {
    value: 'HR',
    label: 'Chorwacja',
  },
  {
    value: 'CU',
    label: 'Kuba',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Cypr',
  },
  {
    value: 'CZ',
    label: 'Republika Czeska',
  },
  {
    value: 'CZ',
    label: 'Koreańska Republika Ludowo-Demokratyczna',
  },
  {
    value: 'CD',
    label: 'Demokratyczna Republika Konga',
  },
  {
    value: 'DK',
    label: 'Dania',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Dżibuti',
  },
  {
    value: 'DM',
    label: 'Dominika',
  },
  {
    value: 'DO',
    label: 'Republika Dominikany',
  },
  {
    value: 'EC',
    label: 'Ekwador',
  },
  {
    value: 'EG',
    label: 'Egipt',
  },
  {
    value: 'SV',
    label: 'Salwador',
  },
  {
    value: 'GQ',
    label: 'Gwinea Równikowa',
  },
  {
    value: 'ER',
    label: 'Erytrea',
  },
  {
    value: 'EE',
    label: 'Estonia',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etiopia',
  },
  {
    value: 'FK',
    label: 'Falklandy',
  },
  {
    value: 'FO',
    label: 'Wyspy Owcze',
  },
  {
    value: 'FJ',
    label: 'Fidżi',
  },
  {
    value: 'FI',
    label: 'Finlandia',
  },
  {
    value: 'FR',
    label: 'Francja',
  },
  {
    value: 'FR',
    label: 'Francuskie wydziały i terytoria na Oceanie Indyjskim',
  },
  {
    value: 'GF',
    label: 'Gujana Francuska',
  },
  {
    value: 'PF',
    label: 'Polinezja Francuska',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambia',
  },
  {
    value: 'GE',
    label: 'Gruzja',
  },
  {
    value: 'DE',
    label: 'Niemcy',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
  },
  {
    value: 'GR',
    label: 'Grecja',
  },
  {
    value: 'GL',
    label: 'Grenlandia',
  },
  {
    value: 'GD',
    label: 'Grenada',
  },
  {
    value: 'GP',
    label: 'Gwadelupa',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Gwatemala',
  },
  {
    value: 'GG',
    label: 'Gwinea',
  },
  {
    value: 'GN',
    label: 'Gwinea-Bissau',
  },
  {
    value: 'GY',
    label: 'Gujana',
  },
  {
    value: 'HT',
    label: 'Haiti',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hongkong',
  },
  {
    value: 'HU',
    label: 'Węgry',
  },
  {
    value: 'IS',
    label: 'Islandia',
  },
  {
    value: 'IN',
    label: 'Indie',
  },
  {
    value: 'ID',
    label: 'Indonezja',
  },
  {
    value: 'IR',
    label: 'Iran',
  },
  {
    value: 'IQ',
    label: 'Irak',
  },
  {
    value: 'IE',
    label: 'Irlandia',
  },
  {
    value: 'IL',
    label: 'Izrael',
  },
  {
    value: 'IT',
    label: 'Włochy',
  },
  {
    value: 'JM',
    label: 'Jamajka',
  },
  {
    value: 'JP',
    label: 'Japonia',
  },
  {
    value: 'JO',
    label: 'Jordania',
  },
  {
    value: 'KZ',
    label: 'Kazachstan',
  },
  {
    value: 'KE',
    label: 'Kenia',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Korea',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuwejt',
  },
  {
    value: 'KG',
    label: 'Kirgistan',
  },
  {
    value: 'LA',
    label: 'Laotańska Republika Ludowo-Demokratyczna',
  },
  {
    value: 'LV',
    label: 'Łotwa',
  },
  {
    value: 'LB',
    label: 'Liban',
  },
  {
    value: 'LS',
    label: 'Lesotho',
  },
  {
    value: 'LR',
    label: 'Liberia',
  },
  {
    value: 'LY',
    label: 'Libia',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
  },
  {
    value: 'LT',
    label: 'Litwa',
  },
  {
    value: 'LU',
    label: 'Luksemburg',
  },
  {
    value: 'MO',
    label: 'Makao',
  },
  {
    value: 'MG',
    label: 'Madagaskar',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Malezja',
  },
  {
    value: 'MV',
    label: 'Malediwy',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Wyspy Marshalla',
  },
  {
    value: 'MQ',
    label: 'Martinique',
  },
  {
    value: 'MR',
    label: 'Mauritania',
  },
  {
    value: 'MU',
    label: 'Mauritius',
  },
  {
    value: 'MX',
    label: 'Meksyk',
  },
  {
    value: 'FM',
    label: 'Mikronezja',
  },
  {
    value: 'MD',
    label: 'Moldova',
  },
  {
    value: 'MC',
    label: 'Monako',
  },
  {
    value: 'MN',
    label: 'Mongolia',
  },
  {
    value: 'ME',
    label: 'Czarnogóra',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Maroko',
  },
  {
    value: 'MZ',
    label: 'Mozambik',
  },
  {
    value: 'MM',
    label: 'Myanmar',
  },
  {
    value: 'NA',
    label: 'Namibia',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepal',
  },
  {
    value: 'NL',
    label: 'Holandia',
  },
  {
    value: 'NC',
    label: 'Nowa Kaledonia',
  },
  {
    value: 'NZ',
    label: 'Nowa Zelandia',
  },
  {
    value: 'NI',
    label: 'Nikaragua',
  },
  {
    value: 'NE',
    label: 'Niger',
  },
  {
    value: 'NG',
    label: 'Nigeria',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Wyspa Norfolk',
  },
  {
    value: 'NF',
    label: 'Północna Macedonia',
  },
  {
    value: 'MP',
    label: 'Północne Wyspy Mariany',
  },
  {
    value: 'NO',
    label: 'Norwegia',
  },
  {
    value: 'OM',
    label: 'Oman',
  },
  {
    value: 'PK',
    label: 'Pakistan',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papua Nowa Gwinea',
  },
  {
    value: 'PY',
    label: 'Paragwaj',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'PH',
    label: 'Filipiny',
  },
  {
    value: 'PL',
    label: 'Polska',
  },
  {
    value: 'PT',
    label: 'Portugalia',
  },
  {
    value: 'PR',
    label: 'Portoryko',
  },
  {
    value: 'QA',
    label: 'Katar',
  },
  {
    value: 'RO',
    label: 'Rumunia',
  },
  {
    value: 'RU',
    label: 'Federacja Rosyjska',
  },
  {
    value: 'RW',
    label: 'Rwanda',
  },
  {
    value: 'SH',
    label: 'Saint Helena, Wniebowstąpienie i Tristan da Cunha',
  },
  {
    value: 'KN',
    label: 'Saint Kitts i Nevis',
  },
  {
    value: 'LC',
    label: 'święta Lucia',
  },
  {
    value: 'PM',
    label: 'Saint Pierre i Miquelon',
  },
  {
    value: 'VC',
    label: 'Saint Vincent i Grenadyny',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao Tome i Principe',
  },
  {
    value: 'SA',
    label: 'Arabia Saudyjska',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Serbia',
  },
  {
    value: 'SC',
    label: 'Seszele',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Singapur',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Słowacja',
  },
  {
    value: 'SI',
    label: 'Słowenia',
  },
  {
    value: 'SB',
    label: 'Wyspy Salomona',
  },
  {
    value: 'SO',
    label: 'Somali',
  },
  {
    value: 'ZA',
    label: 'Afryka Południowa',
  },
  {
    value: 'SS',
    label: 'Południowy Sudan',
  },
  {
    value: 'ES',
    label: 'Hiszpania',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
  },
  {
    value: 'SD',
    label: 'Sudan',
  },
  {
    value: 'SR',
    label: 'Surinam',
  },
  {
    value: 'SE',
    label: 'Szwecja',
  },
  {
    value: 'CH',
    label: 'Szwajcaria',
  },
  {
    value: 'SY',
    label: 'Republika Syryjsko-Arabska',
  },
  {
    value: 'TW',
    label: 'Tajwan',
  },
  {
    value: 'TJ',
    label: 'Tadżykistan',
  },
  {
    value: 'TZ',
    label: 'Tanzania',
  },
  {
    value: 'TH',
    label: 'Tajlandia',
  },
  {
    value: 'TL',
    label: 'TIMOR-WIELKA',
  },
  {
    value: 'TG',
    label: 'Togo Republic',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trynidad i Tobago',
  },
  {
    value: 'TN',
    label: 'Tunezja',
  },
  {
    value: 'TR',
    label: 'Republika Turcji',
  },
  {
    value: 'TM',
    label: 'Turkmenia',
  },
  {
    value: 'TC',
    label: 'Turks i Caicos',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukraina',
  },
  {
    value: 'AE',
    label: 'Zjednoczone Emiraty Arabskie',
  },
  {
    value: 'GB',
    label: 'Zjednoczone Królestwo',
  },
  {
    value: 'US',
    label: 'Stany Zjednoczone',
  },
  {
    value: 'US',
    label: 'Wyspy Dziewicze Stanów Zjednoczonych',
  },
  {
    value: 'UY',
    label: 'Urugwaj',
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Wenezuela',
  },
  {
    value: 'VN',
    label: 'Wietnam',
  },
  {
    value: 'WF',
    label: 'Wallis i Futuna',
  },
  {
    value: 'YE',
    label: 'Jemen',
  },
  {
    value: 'ZM',
    label: 'Zambia',
  },
  {
    value: 'ZM',
    label: 'Zimbabwe',
  },
];
