import * as yup from 'yup';
import { TFunction } from 'i18next';

const oneDecimalSchema = (
  t: TFunction,
  isPercentage?: boolean
): yup.NumberSchema => {
  return yup
    .number()
    .transform((_, value) => {
      if (!value.toString()) return undefined;
      if (value.toString().includes('.')) {
        return +value;
      }
      return +value.toString().replace(/,/, '.');
    })
    .when(() => {
      if (isPercentage)
        return yup
          .number()
          .max(99.9, t('edit-label.percentage-max-error'))
          .test({
            test: (val) => {
              if (val !== undefined) {
                return /^\d{0,2}(\.\d{0,1})?$/.test(val.toString());
              }
              return true;
            },
            message: t('common.errors.one-decimal-error'),
          });
      else
        return yup.number().test({
          test: (val) => {
            if (val !== undefined) {
              return /^\d*(\.\d{0,1})?$/.test(val.toString());
            }
            return true;
          },
          message: t('common.errors.one-decimal-error'),
        });
    })
    .positive();
};

export default oneDecimalSchema;
