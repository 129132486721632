import { KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../../assets/images/forgot-password-background.svg';
import TemplateWithCard from '../../components/screen-template/template-with-card';
import { ROUTES } from '../../constants/routes';
import ForgotPasswordForm from './forgot-password-form';
import Header from './header';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);
  const handleEmailSending = () => setEmailSent(true);
  const navigateToLogin = () => navigate(ROUTES.LOGIN);

  return (
    <TemplateWithCard
      backgroundProps={{ imageSource: backgroundImage }}
      cardContent={
        <>
          <Header />
          <Box sx={{ py: 4, px: 6 }}>
            {emailSent ? (
              <Typography>{t('forgot-password.email-sent-message')}</Typography>
            ) : (
              <ForgotPasswordForm onEmailSending={handleEmailSending} />
            )}
            <Button
              variant="outlined"
              startIcon={<KeyboardArrowLeft />}
              fullWidth
              onClick={navigateToLogin}
              sx={{ mt: 2 }}
            >
              {t('login.log-in')}
            </Button>
          </Box>
        </>
      }
    />
  );
};

export default ForgotPassword;
