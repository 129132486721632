export const primary = {
  100: '#BEE4FF',
  200: '#97D3FF',
  300: '#6AC1FF',
  400: '#37ABFF',
  500: '#0094FF',
  600: '#0071C2',
  700: '#004E86',
  800: '#002E50',
  900: '#00192C',
};

export const grey = {
  100: '#F8F9FA',
  200: '#E3E5E7',
  300: '#C7CDD1',
  400: '#9FA5AB',
  500: '#7A7F85',
  600: '#52585E',
  700: '#373E43',
  800: '#1F262B',
  900: '#0D151E',
};

export const wine = {
  ORANGE: '#FFA500',
  RED: '#B71C1C',
  ROSE: '#E57373',
  WHITE: '#FFECB3',
};

export const error = '#CC3214';
export const success = '#99CC14';
export const warning = { main: '#CC6A14', light: '#FFAB00', dark: '#FFB400' };
export const info = '#0037FF';
export const textDisabled = 'rgba(0, 0, 0, 0.38)';
