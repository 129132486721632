import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import {
  AutocompleteHighlightChangeReason,
  createFilterOptions,
} from '@mui/material/useAutocomplete';

import React from 'react';
import FlexBox from '../flexbox/flexbox';

export interface MerchantSelectOptions {
  key?: string;
  value?: {
    address?: string;
    name?: string;
  };
}

export interface MerchantSelectProps
  extends Partial<
    AutocompleteProps<MerchantSelectOptions, false, false, false | true>
  > {
  optionMap: MerchantSelectOptions[];
  textFieldProps: TextFieldProps;
  onSelectChange?: (selected?: MerchantSelectOptions | null) => void;
}

const MerchantSelect = React.forwardRef<HTMLInputElement, MerchantSelectProps>(
  (props: React.PropsWithChildren<MerchantSelectProps>, ref) => {
    const {
      optionMap: options,
      textFieldProps,
      onSelectChange,
      groupBy,
      onHighlightChange,
      ...autocompleteProps
    } = props;
    return (
      <Autocomplete
        {...autocompleteProps}
        filterOptions={createFilterOptions({
          ignoreCase: true,
          stringify: ({ value }) => `${value?.name} ${value?.address}`,
        })}
        freeSolo={false}
        defaultValue={undefined}
        value={
          typeof autocompleteProps.value === 'string'
            ? undefined
            : autocompleteProps.value
        }
        options={options}
        getOptionLabel={(option) => option.value?.name || ''}
        renderOption={(
          renderOptionProps: React.HTMLAttributes<HTMLLIElement>,
          option: MerchantSelectOptions
        ) => (
          <li {...renderOptionProps} key={option.key}>
            <FlexBox gap={1}>
              <Typography width={150}>{option.value?.name}</Typography>
              <Typography variant="caption" color="rgba(0,0,0,0.6)">
                {option.value?.address}
              </Typography>
            </FlexBox>
          </li>
        )}
        renderTags={undefined}
        renderInput={(params: AutocompleteRenderInputParams) => {
          return <TextField {...params} {...textFieldProps} ref={ref} />;
        }}
        groupBy={
          typeof groupBy === 'undefined'
            ? undefined
            : (option: MerchantSelectOptions) => groupBy(option)
        }
        isOptionEqualToValue={(
          option: MerchantSelectOptions,
          currentValue: MerchantSelectOptions
        ) => {
          return option.key === currentValue.key;
        }}
        onChange={(e, data) => {
          if (onSelectChange) {
            if (!data) {
              onSelectChange(undefined);
            } else {
              onSelectChange(data);
            }
          }
        }}
        onHighlightChange={
          typeof onHighlightChange === 'undefined'
            ? undefined
            : (
                event: React.SyntheticEvent<Element, Event>,
                option: MerchantSelectOptions | null,
                reason: AutocompleteHighlightChangeReason
              ) => {
                onHighlightChange(event, option, reason);
              }
        }
      />
    );
  }
);

MerchantSelect.displayName = 'SearchableSelect';

export default MerchantSelect;
