import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React, { MouseEvent, useState } from 'react';

const PasswordField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (props: React.PropsWithChildren<TextFieldProps>, ref) => {
    const { error: inputIsError, disabled } = props;

    const [passwordVisible, setPasswordVisible] = useState(false);

    const getIcon = () => {
      if (inputIsError) return null;
      return (
        <InputAdornment position="end">
          <IconButton
            disableRipple
            color="inherit"
            disabled={disabled}
            aria-label="toggle password visibility"
            onClick={() => setPasswordVisible(!passwordVisible)}
            onMouseDown={(e: MouseEvent) => e.preventDefault()}
            sx={{ p: 0 }}
          >
            {passwordVisible ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
          </IconButton>
        </InputAdornment>
      );
    };

    const getTargetInputType = () => {
      return passwordVisible ? 'text' : 'password';
    };

    return (
      <TextField
        {...props}
        value={props.value || ''}
        type={getTargetInputType()}
        ref={ref}
        InputProps={{
          endAdornment: getIcon(),
        }}
      />
    );
  }
);

PasswordField.displayName = 'PasswordField';

export default PasswordField;
