import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomColor from '../../../assets/images/custom-color.svg';
import FlexBox from '../../../components/flexbox/flexbox';
import ColorPickerPreviewer from './color-picker-previewer';
import { PremiumFeaturesFormValue } from './premium-features.types';
import TextColorPreviewer from './text-color-previewer';

interface TextColorEditorProps {
  control: Control<PremiumFeaturesFormValue>;
  name: 'colors.primaryText' | 'colors.secondaryText';
}

type DefaultColors = '#000' | '#FFF';

const TextColorEditor: React.FC<TextColorEditorProps> = ({ control, name }) => {
  const { t } = useTranslation();
  const { watchInternal } = control;
  const color = watchInternal(name);
  const defaultOptions: DefaultColors[] = ['#000', '#FFF'];
  const isDefault = defaultOptions.includes(color);
  const [selected, setSelected] = useState<DefaultColors | 'custom'>(
    isDefault ? color : 'custom'
  );
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [customColor, setCustomColor] = useState<string | undefined>(
    isDefault ? undefined : color
  );
  const handlePreviewClick = (
    value: DefaultColors,
    callback: (color: DefaultColors) => void
  ) => {
    setSelected(value);
    callback(value);
  };
  const handleCustomSelection = (callback: (color: string) => void) => {
    setSelected('custom');
    setIsPickerOpen(true);
    if (customColor) callback(customColor);
  };
  const handleCustomColorChange = (
    value: string,
    callback: (color: string) => void
  ) => {
    setCustomColor(value);
    callback(value);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FlexBox gap={2} alignItems="center">
          <Typography sx={{ mr: 4 }}>
            {t('premium-features.text-color')}
          </Typography>
          {defaultOptions.map((option) => (
            <TextColorPreviewer
              key={option}
              isSelected={selected === option}
              bgcolor={option}
              onClick={() => handlePreviewClick(option, field.onChange)}
            />
          ))}
          {isPickerOpen ? (
            <ColorPickerPreviewer
              color={field.value}
              onChange={(value) =>
                handleCustomColorChange(value, field.onChange)
              }
              onPickerClose={() => setIsPickerOpen(false)}
              size={32}
              withOpenPicker
              popoverProps={{
                anchorOrigin: { vertical: 40, horizontal: 'left' },
              }}
            />
          ) : (
            <TextColorPreviewer
              onClick={() => handleCustomSelection(field.onChange)}
              sx={{
                backgroundColor: customColor,
                background: customColor
                  ? ''
                  : `url(${CustomColor}) no-repeat center`,
              }}
              isSelected={selected === 'custom'}
            />
          )}
        </FlexBox>
      )}
    />
  );
};

export default TextColorEditor;
