import { Autocomplete, TextField } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { Controller } from 'react-hook-form';
import { LANGUAGES, languageCodes } from '../../../constants/languages';
import { EditLabelSplitFormProps } from '../../../models/label';

interface QrCodeLanguageSelectorProps extends EditLabelSplitFormProps {
  position: 'top' | 'bottom';
  disabled: boolean;
}

const QrCodeLanguageSelector: React.FC<QrCodeLanguageSelectorProps> = ({
  control,
  formState: { errors },
  position,
  disabled,
}) => {
  return (
    <Controller
      control={control}
      name={`qrCode.${position}LanguageCode`}
      render={({
        field: { value: valueInForm, onChange },
      }: {
        field: {
          value?: LANGUAGES | null;
          onChange: (value?: LANGUAGES | null) => void;
        };
      }) => (
        <Autocomplete
          disablePortal
          options={[
            { label: '', languageCode: undefined },
            ...languageCodes
              .map((code) => ({
                label: t(`languages.${code}`),
                languageCode: code,
              }))
              .filter((option) =>
                position === 'bottom'
                  ? option.languageCode !==
                    control.watchInternal('qrCode.topLanguageCode')
                  : option.languageCode !==
                    control.watchInternal('qrCode.bottomLanguageCode')
              ),
          ].sort((a, b) => a.label.localeCompare(b.label))}
          disableClearable={!valueInForm}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t(
                `edit-label.qr-code-editor.${position}-language-placeholder`
              )}
              error={!!errors.qrCode}
              helperText={
                !!errors.qrCode && t('edit-label.qr-code-editor.error-message')
              }
            />
          )}
          renderOption={(props, option) => (
            <React.Fragment key={option.label}>
              {option.label ? <li {...props}>{option.label}</li> : null}
            </React.Fragment>
          )}
          isOptionEqualToValue={(option, value) =>
            !value
              ? option.languageCode === null
              : option.languageCode === value.languageCode
          }
          value={{
            languageCode: valueInForm,
            label: !valueInForm ? '' : t(`languages.${valueInForm}`),
          }}
          onChange={(_, newValue) =>
            onChange(!newValue ? undefined : newValue.languageCode)
          }
        />
      )}
    />
  );
};

export default QrCodeLanguageSelector;
