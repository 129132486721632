import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import backgroundImage from '../../assets/images/blocked-background.svg';
import FlexBox from '../../components/flexbox/flexbox';
import TemplateWithCard from '../../components/screen-template/template-with-card';
import ContactButton from './contact-button';
import Header from './header';
import Note from './note';
import ReasonBox from './reason-box';

const Stuck: React.FC = () => {
  const { t } = useTranslation();

  return (
    <TemplateWithCard
      backgroundProps={{ imageSource: backgroundImage, isCardCentered: true }}
      cardProps={{ sx: { width: '875px' } }}
      cardContent={
        <>
          <Header />
          <Box p={3.5}>
            <FlexBox>
              <ReasonBox reasonIndex={1} hasMoreMessages />
              <Typography
                variant="h5"
                sx={{ mx: 3, mt: 4, textTransform: 'uppercase' }}
              >
                {t('common.or')}
              </Typography>
              <ReasonBox reasonIndex={2} />
            </FlexBox>
            <Note />
            <FlexBox>
              <ContactButton />
              <Button fullWidth variant="contained" sx={{ ml: 2 }}>
                {t('stuck.review-or-renew')}
              </Button>
            </FlexBox>
          </Box>
        </>
      }
    />
  );
};

export default Stuck;
