import { SxProps, Theme } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import CollapsibleBlock, { CollapsibleBlockProps } from './collapsible-block';

interface CollapsibleBlocksProps<T> {
  blocks: CollapsibleBlockProps<T>[];
  sx?: SxProps<Theme>;
  expandedBlocks: T[];
  handleBlockToggle: (
    value: T
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
}

const CollapsibleBlocks = <T,>({
  blocks,
  expandedBlocks,
  handleBlockToggle,
  sx,
}: CollapsibleBlocksProps<T>) => {
  return (
    <>
      {blocks.map((block) => (
        <CollapsibleBlock
          key={block.title}
          {...block}
          sx={sx}
          expanded={expandedBlocks.includes(block.value)}
          onChange={handleBlockToggle(block.value)}
          isValid={block.isValid}
        />
      ))}
    </>
  );
};

export default CollapsibleBlocks;
