import { Autocomplete, TextField, Typography } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_FONT, FONTS } from '../../../constants/fonts';
import { PremiumFeaturesFormValue } from './premium-features.types';

interface FontsProps {
  control: Control<PremiumFeaturesFormValue>;
}

const Fonts: React.FC<FontsProps> = ({ control }) => {
  const { t } = useTranslation();
  const fontsAsOptions = FONTS.map((font) => ({
    label:
      font === DEFAULT_FONT
        ? `${font} (${t('common.default').toLowerCase()})`
        : font,
    value: font,
  }));

  return (
    <>
      <Typography>{t('premium-features.fonts')}</Typography>
      <Controller
        control={control}
        name="fontFamily"
        render={({ field }) => (
          <Autocomplete
            disablePortal
            options={[{ label: '', value: '' }, ...fontsAsOptions]}
            disableClearable
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('premium-features.choose-a-font')}
              />
            )}
            renderOption={(props, option) => (
              <React.Fragment key={option.label}>
                {option.label ? <li {...props}>{option.label}</li> : null}
              </React.Fragment>
            )}
            isOptionEqualToValue={(option, value) =>
              !value ? option.value === null : option.value === value.value
            }
            value={{
              label: field.value || '',
              value: field.value || '',
            }}
            onChange={(_, newValue) =>
              field.onChange(!newValue ? undefined : newValue.value)
            }
            sx={{ mb: 4 }}
          />
        )}
      />
    </>
  );
};

export default Fonts;
