import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormHelperText, Grid, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CheckboxWithController from '../../components/checkbox/checkbox-with-controller';
import FlexBox from '../../components/flexbox/flexbox';
import PasswordFieldWithController from '../../components/password-field/password-field-with-controller';
import TextFieldWithController from '../../components/text-field/text-field-with-controller';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '../../constants/urls';
import { SignupSplitFormProps } from '../../models/signup';

const AccountForm: React.FC<SignupSplitFormProps> = ({
  control,
  formState,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { errors, isSubmitted } = formState;
  const isPasswordError = isSubmitted && !!errors.password;
  const isEmailError = isSubmitted && !!errors.email;
  const isTermsPrivacyError = isSubmitted && !!errors.termsPrivacyConsent;
  const isRegistrationCodeError = isSubmitted && !!errors.registrationCode;

  return (
    <Grid container spacing={2} sx={{ my: 0.5 }}>
      <Grid item xs={12}>
        <TextFieldWithController
          control={control}
          name="email"
          textFieldProps={{
            label: t('auth.email'),
            error: isEmailError,
            helperText: isEmailError && t('auth.email-error'),
            fullWidth: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <PasswordFieldWithController
          control={control}
          name="password"
          textFieldProps={{
            label: t('auth.password'),
            error: isPasswordError,
            helperText: t('auth.password-helper-text'),
            fullWidth: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CheckboxWithController
          control={control}
          name="termsPrivacyConsent"
          labelProps={{
            label: (
              <Trans i18nKey="signup.terms-privacy-consent">
                I accept the
                <Link target="_blank" to={TERMS_AND_CONDITIONS}>
                  terms & conditions
                </Link>
                and
                <Link target="_blank" to={PRIVACY_POLICY}>
                  privacy policy*
                </Link>
              </Trans>
            ),
          }}
        />
        <FormHelperText
          error={isTermsPrivacyError}
          sx={{ color: theme.palette.warning.main, ml: '33px' }}
        >
          {t('signup.terms-privacy-error')}
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <CheckboxWithController
          control={control}
          name="marketingConsent"
          labelProps={{ label: t('signup.marketing-consent') }}
        />
      </Grid>
      <Grid item xs={12}>
        <FlexBox alignItems="center">
          <TextFieldWithController
            control={control}
            name="registrationCode"
            textFieldProps={{
              label: t('signup.registration-code'),
              error: isRegistrationCodeError,
              helperText:
                isRegistrationCodeError && errors.registrationCode?.message,
              fullWidth: true,
            }}
          />
          <Tooltip
            placement="left"
            title={t('signup.registration-code-tooltip')}
            arrow
          >
            <InfoOutlinedIcon
              sx={{ ml: 1, mr: 1.5, mb: isRegistrationCodeError ? 2.5 : 0 }}
            />
          </Tooltip>
        </FlexBox>
      </Grid>
    </Grid>
  );
};

export default AccountForm;
