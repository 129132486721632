import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FlexBox from '../../../components/flexbox/flexbox';

interface ExampleFooterProps {
  logoSrc?: string;
}

const ExampleFooter: React.FC<ExampleFooterProps> = ({ logoSrc }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const isLogo = typeof logoSrc === 'string';

  return (
    <FlexBox
      bgcolor={palette.grey[800]}
      justifyContent="center"
      alignItems="center"
      p={3.5}
    >
      <FlexBox
        alignItems="center"
        justifyContent="center"
        height="68px"
        minWidth="238px"
        sx={{
          background: isLogo
            ? `center / contain no-repeat url(${logoSrc})`
            : palette.warning.dark,
        }}
      >
        {!isLogo && (
          <Typography>
            {t('premium-features.example.footer-logo-placeholder')}
          </Typography>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default ExampleFooter;
