import * as React from 'react';
import { SearchableSelectOptions } from '../../components/select/searchable-select';

const useSearchableOptions = (
  data?: Array<{ value: string; label: string }>
): SearchableSelectOptions[] => {
  return React.useMemo(() => {
    return data
      ? [
          ...data.map((it) => {
            return {
              key: it.label,
              value: it.value,
            };
          }),
          {
            key: '',
            value: '',
          },
        ]
      : [];
  }, [data]);
};

export default useSearchableOptions;
