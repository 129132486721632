import { Card, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoIllustration from '../../assets/images/info-illustration.svg';
import CardHeader from '../../components/card/card-header';
import CardHeaderContentWrapper from './card-header-content-wrapper';
import Features from './features';

interface PersonalizationProps {
  displayed: boolean;
}

export const Personalization: React.FC<PersonalizationProps> = ({
  displayed,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        width: displayed ? '380px' : 0,
        transition: 'width 0.3s ease-in-out',
        position: 'absolute',
        top: 0,
        right: 0,
        borderRadius: '0 0 0 40px',
        boxShadow: '-23px 9px 20px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <CardHeader isWarning sx={{ position: 'relative' }}>
        <CardHeaderContentWrapper>
          <Typography variant="h3">{t('licences.personalization')}</Typography>
          <Typography variant="h5">{t('licences.package')}</Typography>
        </CardHeaderContentWrapper>
        <img
          src={InfoIllustration}
          style={{
            position: 'absolute',
            bottom: '-40px',
            right: 0,
            width: displayed ? '114px' : 0,
            transition: 'width 0.3s ease-in-out',
          }}
        />
      </CardHeader>
      <Features type="personalization" />
    </Card>
  );
};
