import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { useSnackbar } from '../../services/snackbar-context';
import useModal from '../../utils/modal/use-modal';
import ConfirmationModal from '../modal/confirmation-modal';
import useDeleteRegistration from '../../data/registration/use-delete-registration';

const DeleteAccountButton: React.FC<ButtonProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const { openModal, isModalOpen, closeModal } = useModal();
  const { setSnackbar } = useSnackbar();
  const deleteRegistration = useDeleteRegistration();
  const navigate = useNavigate();

  const handleRegistrationDelete = () => {
    setSnackbar({
      message: t('common.delete-account-success-message'),
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      sx: { marginTop: '50px' },
    });
    navigate(ROUTES.SIGNUP);
  };
  const handleConfirmation = async () => {
    deleteRegistration.mutate({ onSuccess: handleRegistrationDelete });
  };

  return (
    <>
      <Button variant="outlined" color="error" onClick={openModal} {...props}>
        {t('common.delete-account')}
      </Button>
      <ConfirmationModal
        variant="error"
        open={isModalOpen}
        onClose={closeModal}
        onConfirm={handleConfirmation}
        labels={{
          message: t('common.delete-account-message'),
        }}
      />
    </>
  );
};

export default DeleteAccountButton;
