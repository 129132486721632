import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  OriginPictogramDto,
  OriginPictogramDtoLabelEnum,
  PremiumFeature,
} from '../../api';
import HeaderIcon from '../../assets/icons/origin-icon';
import PDO from '../../assets/pictograms/pdo.svg';
import PGI from '../../assets/pictograms/pgi.svg';
import CustomerContainer from '../../components/customer-container/customer-container';
import FlexBox from '../../components/flexbox/flexbox';
import Pictogram from './pictogram';
import { getColors } from './winefo-point-utils';

interface OriginProps {
  origin?: OriginPictogramDto;
  premiumFeature?: PremiumFeature;
}

const Origin = ({ origin, premiumFeature }: OriginProps) => {
  const { t } = useTranslation();
  const colors = getColors(premiumFeature);

  return origin?.label || origin?.name ? (
    <CustomerContainer
      title={t('pictograms.origin-title')}
      icon={<HeaderIcon />}
      colors={colors}
    >
      <FlexBox sx={{ p: 2, gap: 2 }}>
        {origin?.label === OriginPictogramDtoLabelEnum.Pgi && (
          <Pictogram src={PGI} />
        )}
        {origin?.label === OriginPictogramDtoLabelEnum.Pdo && (
          <Pictogram src={PDO} />
        )}
        <Typography
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {origin?.name}
        </Typography>
      </FlexBox>
    </CustomerContainer>
  ) : (
    <></>
  );
};

export default Origin;
