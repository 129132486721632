import { Tab as MuiTab, TabProps, useTheme } from '@mui/material';
import React from 'react';

const Tab: React.FC<TabProps> = ({ sx, ...rest }) => {
  const { palette } = useTheme();

  return (
    <MuiTab
      sx={{
        color: palette.grey[400],
        borderBottom: `4px solid ${palette.grey[400]}`,
        '&.Mui-selected': { color: '#FFF' },
        ...sx,
      }}
      {...rest}
    />
  );
};

export default Tab;
