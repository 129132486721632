import { Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FlexBox from '../../components/flexbox/flexbox';
import LanguageSelector from '../../components/language-selector/language-selector';

const SystemSettingsSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <FlexBox mx={2} flexDirection="column" gap={1}>
        <Typography sx={{ my: 1.5, ml: 0.5 }}>
          {t('settings.system-settings')}
        </Typography>
        <LanguageSelector variant="light" color="primary" />
      </FlexBox>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

export default SystemSettingsSection;
