import { Box, Button, Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../../assets/images/login-background.svg';
import TemplateWithCard from '../../components/screen-template/template-with-card';
import { ROUTES } from '../../constants/routes';
import Header from './header';
import LoginForm from './login-form';
import Promoter from './promoter';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToSignup = () => {
    navigate(ROUTES.SIGNUP);
  };
  const navigateToForgotPassword = () => {
    navigate(ROUTES.FORGOT_PASSWORD);
  };

  return (
    <TemplateWithCard
      backgroundProps={{ imageSource: backgroundImage }}
      cardContent={
        <>
          <Header />
          <Box sx={{ py: 4, px: 6 }}>
            <Typography variant="h5">{t('login.login')}</Typography>
            <Divider sx={{ mt: 1 }} />
            <LoginForm />
            <Button
              variant="text"
              fullWidth
              onClick={navigateToForgotPassword}
              sx={{ mt: 2 }}
            >
              {t('login.forgot-password')}
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={navigateToSignup}
              sx={{ mt: 2 }}
            >
              {t('login.sign-up')}
            </Button>
          </Box>
        </>
      }
      cardProps={{ sx: { zIndex: 1 } }}
      additionalContent={<Promoter />}
    />
  );
};

export default Login;
