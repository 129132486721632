import { Box, BoxProps, useTheme } from '@mui/material';
import React from 'react';
import bottleIllustration from '../../assets/images/bottle-illustration.svg';
import FlexBox from '../../components/flexbox/flexbox';
import { HEADER_HEIGHT } from '../../constants/dimensions';
import SignupProgress from './signup-progress';

interface TemplateProps extends BoxProps {
  progressValue?: number;
}

const Template: React.FC<TemplateProps> = ({ progressValue, children }) => {
  const theme = useTheme();
  const height = `calc(100vh - ${HEADER_HEIGHT}px)`;

  return (
    <FlexBox>
      <Box
        px={{ xs: 1, md: 12 }}
        py={{ xs: 1, md: 5.5 }}
        height={height}
        flexGrow={1}
        flexBasis="50%"
        overflow="auto"
        minWidth="min(550px, 100%)"
      >
        {children}
      </Box>
      <FlexBox
        sx={{
          display: { xs: 'none', md: 'flex' },
          height: height,
          flexBasis: '50%',
          flexGrow: 0,
          background: `linear-gradient(134.04deg, \
            ${theme.palette.primaryVariations[600]} 0%, \
            ${theme.palette.primaryVariations[900]} 100%)`,
          position: 'relative',
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={bottleIllustration}
          style={{ height: '100%', position: 'absolute', bottom: 0, right: 0 }}
        />
        <SignupProgress value={progressValue} />
      </FlexBox>
    </FlexBox>
  );
};

export default Template;
