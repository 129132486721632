import { Launch } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FlexBox from '../../components/flexbox/flexbox';
import {
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  WINEFO_BLOG,
} from '../../constants/urls';
import SettingsItem from './settings-item';

const OtherSettingsSection = () => {
  const { t } = useTranslation();

  return (
    <FlexBox mx={2} flexDirection="column" gap={1}>
      <Typography sx={{ my: 1.5, ml: 0.5 }}>
        {t('settings.other-settings')}
      </Typography>
      <Link to={TERMS_AND_CONDITIONS} target="_blank">
        <SettingsItem>{t('settings.terms-conditions')}</SettingsItem>
      </Link>
      <Link to={PRIVACY_POLICY} target="_blank">
        <SettingsItem>{t('common.privacy-policy')}</SettingsItem>
      </Link>
      <Link to={WINEFO_BLOG} target="_blank">
        <SettingsItem
          sx={{
            textDecoration: 'underline',
          }}
          startIcon={<Launch />}
          endIcon=""
        >
          {WINEFO_BLOG.replace('https://www.', '')}
        </SettingsItem>
      </Link>
    </FlexBox>
  );
};

export default OtherSettingsSection;
