import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { PremiumFeature } from '../../api';
import premiumFeaturesApi from './premium-features-api';
import { PREMIUM_FEATURES_QUERY_KEY } from './use-get-premium-features';

interface UpdatePremiumFeaturesVariables {
  data: PremiumFeature;
  onSuccess: () => void;
}

const useUpdatePremiumFeatures = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  UpdatePremiumFeaturesVariables,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: UpdatePremiumFeaturesVariables) =>
      premiumFeaturesApi.updatePremiumFeatures(variables.data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([PREMIUM_FEATURES_QUERY_KEY]);
      variables.onSuccess();
    },
  });
};

export default useUpdatePremiumFeatures;
