import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { WineLabelListResponse } from '../../api';
import { TableOptions } from '../../models/labels-table';
import wineLabelApi from './wine-label-api';

export const WINE_LABELS_KEY = 'wine-labels';

interface SearchLabelsParams extends TableOptions {
  searchText: string;
}

const useSearchLabels = (
  params: SearchLabelsParams,
  options?: UseQueryOptions<
    WineLabelListResponse,
    unknown,
    WineLabelListResponse,
    string[]
  >
): UseQueryResult<WineLabelListResponse, unknown> => {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [WINE_LABELS_KEY, params.searchText],
    queryFn: () =>
      wineLabelApi
        .searchLabels(
          params.searchText,
          params.pageNumber,
          params.size,
          params.sortBy,
          params.sortDirection
        )
        .then((result) => result.data),
    retry: 0,
    ...options,
  });
};

export default useSearchLabels;
