import { WarningAmber } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NotFoundIllustration from '../../assets/images/not-found-illustration.svg';
import CustomerContainer from '../../components/customer-container/customer-container';
import DeletedWinefoPointHeader from './deleted-winefo-point-header';
import WinefoPointFooter from './winefo-point-footer';

const DeletedWinefoPoint: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box minHeight="100vh" sx={{ display: 'flex', flexDirection: 'column' }}>
      <DeletedWinefoPointHeader />
      <CustomerContainer title={t('common.ups')} icon={<WarningAmber />} error>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            py: 2,
            px: '50px',
            gap: 2,
          }}
        >
          <Typography textAlign="center">
            {t('winefo-point.not-found-message')}
          </Typography>
          <img height={100} src={NotFoundIllustration} />
        </Box>
      </CustomerContainer>
      <WinefoPointFooter hideImage />
    </Box>
  );
};

export default DeletedWinefoPoint;
