import { Box } from '@mui/material';
import React from 'react';
import ResetPasswordCardHeader from './reset-password-card-header';
import ResetPasswordForm, {
  ResetPasswordFormProps,
} from './reset-password-form';

const ResetPasswordCardContent: React.FC<ResetPasswordFormProps> = ({
  ...props
}) => {
  return (
    <>
      <ResetPasswordCardHeader />
      <Box sx={{ py: 4, px: 6 }}>
        <ResetPasswordForm {...props} />
      </Box>
    </>
  );
};

export default ResetPasswordCardContent;
