import { SvgIcon, SvgIconProps } from '@mui/material';

const ResponsibilityIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.798 2.19463C12.2506 2.02497 12.7494 2.02497 13.202 2.19463L20.202 4.81963C20.5833 4.96256 20.9119 5.21849 21.1438 5.55319C21.3758 5.88789 21.5001 6.28542 21.5 6.69263V12.0556C21.5 13.7271 21.0346 15.3655 20.1559 16.7873C19.2772 18.2091 18.02 19.3581 16.525 20.1056L13.171 21.7826C12.9627 21.8868 12.7329 21.9411 12.5 21.9411C12.2671 21.9411 12.0373 21.8868 11.829 21.7826L8.475 20.1056C6.98004 19.3581 5.72277 18.2091 4.84407 16.7873C3.96537 15.3655 3.49996 13.7271 3.5 12.0556V6.69263C3.49995 6.28542 3.6242 5.88789 3.85615 5.55319C4.0881 5.21849 4.41669 4.96256 4.798 4.81963L11.798 2.19463ZM12.5 4.06763L5.5 6.69263V12.0556C5.50023 13.3555 5.86239 14.6296 6.54592 15.7353C7.22944 16.8409 8.20732 17.7344 9.37 18.3156L12.5 19.8826V4.06763Z"
      />
    </SvgIcon>
  );
};

export default ResponsibilityIcon;
