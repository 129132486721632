import * as yup from 'yup';
import { TFunction } from 'i18next';
import {
  NutritionDeclaration,
  WineLabelViewWineColourEnum,
} from '../../../api';
import { WineListFormValue } from './wine-list-modal';
import { WineCategoryType } from '../../../assets/wine/wine-category';
import oneDecimalSchema from '../../../utils/schemas/one-decimal-schema';

const wineListSchema = (t: TFunction): yup.ObjectSchema<WineListFormValue> => {
  return yup.object().shape({
    wineColour: yup
      .mixed<WineLabelViewWineColourEnum>()
      .oneOf([...Object.values(WineLabelViewWineColourEnum)])
      .required(t('common.errors.cant-be-empty')),
    wineCategory: yup
      .object<WineCategoryType>()
      .shape({
        id: yup.string().required(),
        colour: yup
          .mixed<WineLabelViewWineColourEnum>()
          .oneOf([...Object.values(WineLabelViewWineColourEnum)])
          .required(),
        nutritions: yup.object<NutritionDeclaration>().required(),
      })
      .required(t('common.errors.cant-be-empty')),
    alcoholByVolume: oneDecimalSchema(t, true).required(
      t('common.errors.cant-be-empty')
    ),
    sugar: yup.number().when('wineCategory', (values) => {
      if (values[0].id === 'general')
        return oneDecimalSchema(t).required(t('common.errors.cant-be-empty'));
      else return oneDecimalSchema(t).optional();
    }),
  });
};

export default wineListSchema;
