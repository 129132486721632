import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { WineLabelView } from '../../api';
import wineLabelApi from './wine-label-api';

export const WINE_LABEL_KEY = 'wine-label';

const useGetWineLabel = (
  id: number,
  options?: UseQueryOptions<
    WineLabelView,
    unknown,
    WineLabelView,
    (string | number | undefined)[]
  >
): UseQueryResult<WineLabelView, unknown> => {
  return useQuery({
    queryKey: [WINE_LABEL_KEY, id],
    queryFn: () =>
      wineLabelApi.getWineLabel(Number(id)).then((result) => result.data),
    enabled: !isNaN(id),
    refetchOnWindowFocus: false,
    ...options,
  });
};

export default useGetWineLabel;
