import {
  Box,
  Chip,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useState } from 'react';
import { IngredientGroupOrderIngredientGroupEnum } from '../../../api';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import IngredientLabel from '../../../components/ingredient-label/ingredient-label';
import {
  groupsWithCustomIngredients,
  ingredientGroupsWithAddContainsSwitch,
  ingredientsWithSubIngredients,
} from '../../../constants/ingredients';
import { Ingredient } from '../../../models/ingredient';
import {
  IngredientProvider,
  useIngredientContext,
} from '../../../services/ingredient-context';
import CustomIngredientControls from './custom-ingredient-controls';
import CustomIngredientInput from './custom-ingredient-input';
import IngredientGroupAccordion from './ingredient-group-accordion';
import { useTranslation } from 'react-i18next';
import FlexBox from '../../../components/flexbox/flexbox';
import SubIngredients from './sub-ingredients';
import IngredientControls from './ingredient-controls';

interface IngredientGroupProps {
  group: IngredientGroupOrderIngredientGroupEnum;
  title: string;
  isInSubIngredient?: boolean;
}

const IngredientGroup: React.FC<IngredientGroupProps> = ({
  group,
  title,
  isInSubIngredient,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { ingredients, flexibleGroups, updateFlexibleGroups } =
    useIngredientContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const withAddContainsSwitch =
    ingredientGroupsWithAddContainsSwitch.includes(group);
  const ingredientsInGroup = ingredients.filter(
    (ingredient) => ingredient.ingredientGroup === group
  );
  const selectedIngredientsInGroup = ingredientsInGroup.filter(
    (ingredient) => ingredient.isSelected
  );
  const isFlexibleGroup = flexibleGroups.includes(group);
  const isWithSubIngredients = (ingredient: Ingredient) =>
    ingredientsWithSubIngredients.includes(ingredient.name) &&
    !ingredient.isCustom &&
    !isInSubIngredient;
  const handleAddContainsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    updateFlexibleGroups(group, checked);
  };

  return (
    <>
      <IngredientGroupAccordion
        title={title}
        onToggle={(expanded) => setIsExpanded(expanded)}
        details={
          <FlexBoxColumn>
            {withAddContainsSwitch && (
              <FormControlLabel
                control={
                  <Switch
                    checked={isFlexibleGroup}
                    onChange={handleAddContainsChange}
                  />
                }
                label={
                  <Tooltip
                    title={t(
                      'edit-label.ingredients-section.add-contains-description'
                    )}
                    arrow
                  >
                    <Typography>
                      {t('edit-label.ingredients-section.add-contains')}
                    </Typography>
                  </Tooltip>
                }
                sx={{ mt: 1, justifyContent: 'flex-end' }}
              />
            )}
            <>
              {ingredientsInGroup.map((ingredient, index) => (
                <Box
                  key={ingredient.name?.concat(String(index))}
                  color={theme.palette.grey[700]}
                  ml={4}
                >
                  {ingredient.isCustom ? (
                    <CustomIngredientControls ingredient={ingredient} />
                  ) : (
                    <FlexBox alignItems="center">
                      <IngredientControls ingredient={ingredient} />
                      {isWithSubIngredients(ingredient) && (
                        <IngredientProvider isSubIngredientProvider>
                          <SubIngredients mainIngredient={ingredient} />
                        </IngredientProvider>
                      )}
                    </FlexBox>
                  )}
                </Box>
              ))}
            </>
            {groupsWithCustomIngredients.includes(group) && (
              <CustomIngredientInput group={group} />
            )}
          </FlexBoxColumn>
        }
      />

      <Box>
        {!isExpanded &&
          selectedIngredientsInGroup.map((ingredient) => (
            <Chip
              key={ingredient.name}
              label={<IngredientLabel ingredient={ingredient} />}
              sx={{ m: '5px', bgcolor: grey[100] }}
            />
          ))}
      </Box>
    </>
  );
};

export default IngredientGroup;
