import { useEffect, useRef } from 'react';

export const useEventListener = (
  eventName: keyof WindowEventMap,
  handler: (event: Event) => void,
  element = window
): void => {
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return undefined;

    const eventListener = (event: Event) => {
      if (savedHandler.current) savedHandler.current(event);
    };
    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
