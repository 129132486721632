import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TextField, Tooltip } from '@mui/material';
import React from 'react';
import { Control, Controller, FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FlexBox from '../../../components/flexbox/flexbox';
import { Colors } from '../../../models/premium-features';
import ColorPickerPreviewer from './color-picker-previewer';
import { PremiumFeaturesFormValue } from './premium-features.types';

interface ColorEditorProps {
  control: Control<PremiumFeaturesFormValue>;
  formState: FormState<PremiumFeaturesFormValue>;
  name: 'colors.primary' | 'colors.secondary';
}

const ColorEditor: React.FC<ColorEditorProps> = ({
  control,
  formState,
  name,
}) => {
  const { t } = useTranslation();
  const scheme = name.split('.')[1];
  const helperTextHeight = '23px';
  const isError = !!(
    formState.isSubmitted && formState.errors.colors?.[scheme as keyof Colors]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FlexBox gap={2} alignItems="center">
          <ColorPickerPreviewer
            color={field.value}
            onChange={field.onChange}
            previewerProps={{ mb: helperTextHeight }}
          />
          <TextField
            label={t(`premium-features.${scheme}-color`)}
            value={field.value}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onChange={(e) => field.onChange(e.target.value)}
            fullWidth
            helperText={isError && t('premium-features.color-error-message')}
            error={isError}
            sx={{
              mb: isError ? 0 : helperTextHeight,
            }}
          />
          <Tooltip
            title={t(`premium-features.${scheme}-color-tooltip`)}
            arrow
            sx={{ mb: helperTextHeight }}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </FlexBox>
      )}
    />
  );
};

export default ColorEditor;
