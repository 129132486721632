import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const PictogramSectionIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 30">
      <g id="pictogram-section-icon">
        <path d="M24.322 3.84512L13.2426 0.0694473C13.1089 0.0238001 12.9296 0.000976562 12.7502 0.000976562C12.5709 0.000976562 12.3916 0.0238001 12.2579 0.0694473L1.17844 3.84512C0.907816 3.93642 0.686096 4.24943 0.686096 4.53635V20.2651C0.686096 20.552 0.871949 20.9302 1.09693 21.1096L12.3361 29.8673C12.4503 29.9553 12.597 30.001 12.747 30.001C12.897 30.001 13.0469 29.9553 13.1578 29.8673L24.397 21.1096C24.622 20.9335 24.8078 20.5553 24.8078 20.2651V4.53635C24.8144 4.24943 24.5926 3.93968 24.322 3.84512ZM22.4667 19.6391L12.7502 27.21L3.03371 19.6391V5.6971L12.7502 2.38441L22.4667 5.6971V19.6391ZM9.23533 13.0854C9.13751 12.9517 8.981 12.8702 8.81145 12.8702H7.01161C6.79968 12.8702 6.67577 13.1115 6.79968 13.2843L10.921 18.9576C10.9698 19.0243 11.0336 19.0786 11.1072 19.116C11.1809 19.1534 11.2623 19.1729 11.3449 19.1729C11.4275 19.1729 11.509 19.1534 11.5826 19.116C11.6563 19.0786 11.7201 19.0243 11.7688 18.9576L18.7008 9.41408C18.8247 9.24128 18.7008 9 18.4888 9H16.689C16.5227 9 16.3629 9.08151 16.2651 9.21519L11.3449 15.9905L9.23533 13.0854Z" />
      </g>
    </SvgIcon>
  );
};

export default PictogramSectionIcon;
