import {
  GridPaginationModel,
  GridRowId,
  GridRowsProp,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import fileDownload from 'js-file-download';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { GenerateQrCode200Response, WineLabelListItem } from '../../api';
import { ROUTES } from '../../constants/routes';
import useGenerateQrCode from '../../data/qr-code/use-generate-qr-code';
import { SortBy, SortDirection, TableOptions } from '../../models/labels-table';
import ExportSection from './export-section';
import NoResults from './no-results';
import StyledDataGrid from './styled-data-grid';
import useColumns from './use-columns';

interface LabelsTableProps {
  labels: WineLabelListItem[];
  totalElements?: number;
  isLoading: boolean;
  tableOptions: TableOptions;
  onTableOptionsChange: (options: TableOptions) => void;
}

const LabelsTable: React.FC<LabelsTableProps> = ({
  labels,
  totalElements,
  isLoading,
  tableOptions,
  onTableOptionsChange,
}) => {
  const { t } = useTranslation();
  const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>([]);
  const generateQrCode = useGenerateQrCode();
  const columns = useColumns();
  const rows: GridRowsProp = useMemo(
    () =>
      labels.map((label) => ({
        id: label.id,
        PRODUCT_NAME: label,
        HARVEST_YEAR: label.harvestYear || '-',
        COPY:
          location.protocol +
          '//' +
          location.host +
          generatePath(ROUTES.COSTUMER_WINEFO_POINT, { id: label.url }),
        PREVIEW:
          location.protocol +
          '//' +
          location.host +
          generatePath(ROUTES.COSTUMER_WINEFO_POINT, { id: label.url }),
      })),
    [labels]
  );

  const handleSelectionChange = (rowSelectionModel: GridRowId[]) =>
    setSelectedLabelIds(rowSelectionModel as number[]);

  const exportQrCodes = async () => {
    const label = labels.find((item) => item.id === selectedLabelIds[0]);
    if (label?.url) {
      generateQrCode.mutate({
        url: label.url,
        onSuccess: (response: GenerateQrCode200Response) => {
          fileDownload(
            new Blob([response.data as ArrayBuffer], { type: 'image/png' }),
            `${label.productName}.png`
          );
        },
      });
    }
  };

  const handleSortChange = (model: GridSortModel) => {
    if (
      model[0] &&
      (model[0].field === SortBy.PRODUCT_NAME ||
        model[0].field === SortBy.HARVEST_YEAR) &&
      model[0].sort
    ) {
      onTableOptionsChange({
        pageNumber: 0,
        sortBy: model[0].field,
        sortDirection: model[0].sort.toString().toUpperCase() as SortDirection,
        size: tableOptions.size,
      });
    } else {
      onTableOptionsChange({
        pageNumber: 0,
        sortBy: SortBy.CREATED,
        sortDirection: SortDirection.DESC,
        size: tableOptions.size,
      });
    }
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    onTableOptionsChange({
      pageNumber: model.page,
      sortBy: tableOptions.sortBy,
      sortDirection: tableOptions.sortDirection,
      size: model.pageSize,
    });
  };

  return (
    <>
      <ExportSection
        onExport={exportQrCodes}
        disabled={selectedLabelIds.length !== 1}
      />
      <StyledDataGrid
        loading={isLoading}
        pagination={true}
        initialState={{
          pagination: {
            paginationModel: {
              page: tableOptions.pageNumber,
              pageSize: tableOptions.size,
            },
          },
          sorting: {
            sortModel:
              tableOptions.sortBy === SortBy.CREATED
                ? []
                : [
                    {
                      field: tableOptions.sortBy,
                      // eslint-disable-next-line max-len
                      sort: tableOptions.sortDirection.toLowerCase() as GridSortDirection,
                    },
                  ],
          },
        }}
        paginationMode="server"
        sortingMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        onSortModelChange={handleSortChange}
        rowCount={totalElements}
        rows={rows}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={handleSelectionChange}
        localeText={{
          footerRowSelected: (count) => {
            return count !== 1
              ? t('labels.footer-row-selected_plural', { count })
              : t('labels.footer-row-selected', { count });
          },
        }}
        slots={{
          noRowsOverlay: () => <NoResults />,
        }}
        slotProps={{
          pagination: {
            count: totalElements,
            labelRowsPerPage: t('labels.pagination-rows-per-page'),
          },
        }}
        pageSizeOptions={[10, 25, 50]}
      />
    </>
  );
};

export default LabelsTable;
