import { InternalAxiosRequestConfig } from 'axios';
import React from 'react';
import axios from '../../data/axios';
import { useAuthContext } from '../../services/auth-context';
import isTokenSimple from '../authorization/is-token-simple';

const getHeader = (token: string, config: InternalAxiosRequestConfig) => {
  return {
    Authorization: `Bearer ${token}`,
    ...config.headers,
  };
};

const useAxiosAuthorizationInterceptor = (): void => {
  const { getToken, currentSession } = useAuthContext();

  const interceptor = React.useCallback(
    async (config: InternalAxiosRequestConfig) => {
      const configuration = config;
      const token = getToken();
      if (token) {
        if (isTokenSimple(token)) {
          configuration.headers.set(getHeader(token, config));
        } else {
          try {
            const session = await currentSession();
            const jwtToken = session.getIdToken().getJwtToken();
            configuration.headers.set(getHeader(jwtToken, config));
          } catch (e) {
            configuration.headers.set(getHeader(token, config));
          }
        }
      }
      return configuration;
    },
    [getToken, currentSession]
  );

  React.useEffect(() => {
    const addedInterceptor = axios.interceptors.request.use(interceptor);
    return () => {
      axios.interceptors.request.eject(addedInterceptor);
    };
  }, [interceptor]);
};

export default useAxiosAuthorizationInterceptor;
