import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import WinefoLogo from '../../assets/images/logo-horizontal-black.svg';
import { WINEFO_URL } from '../../constants/urls';

const DeletedWinefoPointHeader = () => {
  return (
    <Box
      height={80}
      bgcolor="#e0e0e0"
      justifyContent="center"
      color="white"
      p={2}
      position="sticky"
      top={0}
      zIndex={1}
      boxShadow={25}
    >
      <Link
        href={WINEFO_URL}
        rel="noopener noreferrer"
        underline="none"
        sx={{ cursor: 'pointer' }}
      >
        <img style={{ height: '100%' }} src={WinefoLogo} />
      </Link>
    </Box>
  );
};

export default DeletedWinefoPointHeader;
