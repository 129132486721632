import { useMediaQuery } from '@mui/material';
import muiTheme from '../../assets/theme/mui-theme';

const useBreakpoints = () => {
  const xsScreen = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const smScreen = useMediaQuery(muiTheme.breakpoints.between('sm', 'md'));
  const mdScreen = useMediaQuery(muiTheme.breakpoints.between('md', 'lg'));
  const lgScreen = useMediaQuery(muiTheme.breakpoints.between('lg', 'xl'));
  const xlScreen = useMediaQuery(muiTheme.breakpoints.up('xl'));

  return {
    xsScreen,
    smScreen,
    mdScreen,
    lgScreen,
    xlScreen,
  };
};

export default useBreakpoints;
