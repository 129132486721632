import React, { useState } from 'react';
import CardHeader from '../../../components/card/card-header';
import {
  Box,
  Button,
  DialogContent,
  DialogProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FlexBox from '../../../components/flexbox/flexbox';
import ModalWithConfirmation from '../../../components/modal/modal-with-confirmation';
import CustomIngredientTranslations from './custom-ingredient-translations';
import { Translation } from '../../../api';
import { Ingredient } from '../../../models/ingredient';
import { useIngredientContext } from '../../../services/ingredient-context';

interface CustomIngredientsTranslationsModalProps extends DialogProps {
  ingredient: Ingredient;
}

const CustomIngredientsTranslationsModal: React.FC<
  CustomIngredientsTranslationsModalProps
> = ({ ingredient, ...rest }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { setCustomIngredient } = useIngredientContext();
  const initialTranslations = [
    { languageCode: ingredient.languageCode, name: ingredient.name },
    ...(ingredient.translations || []),
  ];
  const initialErrors = initialTranslations.map(() => false);
  const [translations, setTranslations] =
    useState<Translation[]>(initialTranslations);
  const [errors, setErrors] = useState<boolean[]>(initialErrors);
  const [submitted, setSubmitted] = useState(false);
  const closeModal = () => {
    if (rest.onClose) rest.onClose({}, 'escapeKeyDown');
  };
  const hasChange =
    ingredient.languageCode !== translations[0].languageCode ||
    translations.slice(1).length !== (ingredient.translations || []).length ||
    translations
      .slice(1)
      .some(
        (it, index) =>
          it.languageCode !==
            (ingredient.translations || [])[index].languageCode ||
          it.name !== (ingredient.translations || [])[index].name
      );
  const hasErrors = errors.some((it) => it);
  const handleTranslationsChange = (newTranslations: Translation[]) => {
    setTranslations(newTranslations);
    setErrors(newTranslations.map((it) => !it.languageCode));
  };
  const handleSubmit = () => {
    setSubmitted(true);
    if (!hasErrors) {
      setCustomIngredient({
        ...ingredient,
        languageCode: translations[0].languageCode,
        translations: translations.slice(1),
      });
      setSubmitted(false);
      closeModal();
    }
  };
  const handleCancel = () => {
    setTranslations(initialTranslations);
    setSubmitted(false);
    closeModal();
  };

  return (
    <ModalWithConfirmation
      requiresConfirmation={hasChange}
      onConfirmation={handleSubmit}
      onCancel={handleCancel}
      {...rest}
    >
      <CardHeader sx={{ color: palette.common.white }}>
        <Box sx={{ my: 4, ml: 6 }}>
          <Typography variant="h4">
            {t('edit-label.ingredients-section.translations-modal.title')}
          </Typography>
        </Box>
      </CardHeader>
      <DialogContent sx={{ p: 6, pt: 4, pr: 2 }}>
        <CustomIngredientTranslations
          translations={translations}
          onChange={handleTranslationsChange}
          errors={errors}
          submitted={submitted}
        />
        <FlexBox pt={4} pr={4} gap={2.5}>
          <Button variant="contained" fullWidth onClick={handleCancel}>
            {t('common.cancel')}
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            disabled={!hasChange}
          >
            {t('common.save')}
          </Button>
        </FlexBox>
      </DialogContent>
    </ModalWithConfirmation>
  );
};

export default CustomIngredientsTranslationsModal;
