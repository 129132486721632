import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

const SignupProgress: React.FC<CircularProgressProps> = ({ ...props }) => {
  const theme = useTheme();
  const color =
    typeof props.value === 'number' && props.value < 100
      ? theme.palette.primaryVariations[100]
      : theme.palette.success.main;

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        size={254}
        sx={{
          color,
        }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography sx={{ color, fontSize: 60 }}>{`${Math.round(
          props.value || 0
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export default SignupProgress;
