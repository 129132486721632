export default [
  {
    value: 'AF',
    label: 'Afganisztán',
  },
  {
    value: 'AL',
    label: 'Albánia',
  },
  {
    value: 'DZ',
    label: 'Algéria',
  },
  {
    value: 'AS',
    label: 'Amerikai Szamoa',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguilla',
  },
  {
    value: 'AG',
    label: 'Antigua és Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentína',
  },
  {
    value: 'AM',
    label: 'Örményország',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Ausztrália',
  },
  {
    value: 'AT',
    label: 'Ausztria',
  },
  {
    value: 'AZ',
    label: 'Azerbajdzsán',
  },
  {
    value: 'BS',
    label: 'Bahama -szigetek',
  },
  {
    value: 'BH',
    label: 'Bahrein',
  },
  {
    value: 'BD',
    label: 'Banglades',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Fehéroroszország',
  },
  {
    value: 'BE',
    label: 'Belgium',
  },
  {
    value: 'BZ',
    label: 'Belize',
  },
  {
    value: 'BJ',
    label: 'Benin',
  },
  {
    value: 'BM',
    label: 'Bermuda',
  },
  {
    value: 'BT',
    label: 'Bhután',
  },
  {
    value: 'BO',
    label: 'Bolívia',
  },
  {
    value: 'BA',
    label: 'Bosznia és Hercegovina',
  },
  {
    value: 'BW',
    label: 'Botswana',
  },
  {
    value: 'BR',
    label: 'Brazília',
  },
  {
    value: 'IO',
    label: 'Brit Virgin szigetek',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bulgária',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Kambodzsa',
  },
  {
    value: 'CM',
    label: 'Kamerun',
  },
  {
    value: 'CA',
    label: 'Kanada',
  },
  {
    value: 'KY',
    label: 'Kajmán-szigetek',
  },
  {
    value: 'CF',
    label: 'Közép-Afrikai Köztársaság',
  },
  {
    value: 'TD',
    label: 'Csád',
  },
  {
    value: 'CL',
    label: 'Chile',
  },
  {
    value: 'CN',
    label: 'Kína',
  },
  {
    value: 'CO',
    label: 'Colombia',
  },
  {
    value: 'KM',
    label: 'Komorók',
  },
  {
    value: 'CG',
    label: 'Kongó',
  },
  {
    value: 'CK',
    label: 'Cook -szigetek',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
  },
  {
    value: 'CI',
    label: 'Elefántcsontparti',
  },
  {
    value: 'HR',
    label: 'Horvátország',
  },
  {
    value: 'CU',
    label: 'Kuba',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Ciprus',
  },
  {
    value: 'CZ',
    label: 'Cseh Köztársaság',
  },
  {
    value: 'CZ',
    label: 'Koreai Demokratikus Népköztársaság',
  },
  {
    value: 'CD',
    label: 'Kongói Demokratikus Köztársaság',
  },
  {
    value: 'DK',
    label: 'Dánia',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Dzsibuti',
  },
  {
    value: 'DM',
    label: 'Dominika',
  },
  {
    value: 'DO',
    label: 'Dominikai Köztársaság',
  },
  {
    value: 'EC',
    label: 'Ecuador',
  },
  {
    value: 'EG',
    label: 'Egyiptom',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'GQ',
    label: 'Egyenlítői-Guinea',
  },
  {
    value: 'ER',
    label: 'Eritrea',
  },
  {
    value: 'EE',
    label: 'Észtország',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etiópia',
  },
  {
    value: 'FK',
    label: 'Falkland-szigetek',
  },
  {
    value: 'FO',
    label: 'Faroe Szigetek',
  },
  {
    value: 'FJ',
    label: 'Fidzsi -szigetek',
  },
  {
    value: 'FI',
    label: 'Finnország',
  },
  {
    value: 'FR',
    label: 'Franciaország',
  },
  {
    value: 'FR',
    label: 'Francia osztályok és területek az Indiai -óceánon',
  },
  {
    value: 'GF',
    label: 'Francia Guyana',
  },
  {
    value: 'PF',
    label: 'Francia Polinézia',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambia',
  },
  {
    value: 'GE',
    label: 'Grúzia',
  },
  {
    value: 'DE',
    label: 'Németország',
  },
  {
    value: 'GH',
    label: 'Ghána',
  },
  {
    value: 'GI',
    label: 'Gibraltár',
  },
  {
    value: 'GR',
    label: 'Görögország',
  },
  {
    value: 'GL',
    label: 'Grönland',
  },
  {
    value: 'GD',
    label: 'Gránada',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  {
    value: 'GG',
    label: 'Guinea',
  },
  {
    value: 'GN',
    label: 'Bissau-Guinea',
  },
  {
    value: 'GY',
    label: 'Guyana',
  },
  {
    value: 'HT',
    label: 'Haiti',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hong Kong',
  },
  {
    value: 'HU',
    label: 'Magyarország',
  },
  {
    value: 'IS',
    label: 'Izland',
  },
  {
    value: 'IN',
    label: 'India',
  },
  {
    value: 'ID',
    label: 'Indonézia',
  },
  {
    value: 'IR',
    label: 'Irán',
  },
  {
    value: 'IQ',
    label: 'Irak',
  },
  {
    value: 'IE',
    label: 'Írország',
  },
  {
    value: 'IL',
    label: 'Izrael',
  },
  {
    value: 'IT',
    label: 'Olaszország',
  },
  {
    value: 'JM',
    label: 'Jamaica',
  },
  {
    value: 'JP',
    label: 'Japán',
  },
  {
    value: 'JO',
    label: 'Jordánia',
  },
  {
    value: 'KZ',
    label: 'Kazahsztán',
  },
  {
    value: 'KE',
    label: 'Kenya',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Korea',
  },
  {
    value: 'XK',
    label: 'Koszovó',
  },
  {
    value: 'KW',
    label: 'Kuvait',
  },
  {
    value: 'KG',
    label: 'Kirgizisztán',
  },
  {
    value: 'LA',
    label: 'Laoszi Népi Demokratikus Köztársaság',
  },
  {
    value: 'LV',
    label: 'Lettország',
  },
  {
    value: 'LB',
    label: 'Libanon',
  },
  {
    value: 'LS',
    label: 'Lesotho',
  },
  {
    value: 'LR',
    label: 'Libéria',
  },
  {
    value: 'LY',
    label: 'Líbia',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
  },
  {
    value: 'LT',
    label: 'Litvánia',
  },
  {
    value: 'LU',
    label: 'Luxemburg',
  },
  {
    value: 'MO',
    label: 'Makaó',
  },
  {
    value: 'MG',
    label: 'Madagaszkár',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Malaysia',
  },
  {
    value: 'MV',
    label: 'Maldív -szigetek',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Málta',
  },
  {
    value: 'MH',
    label: 'Marshall-szigetek',
  },
  {
    value: 'MQ',
    label: 'Martinique',
  },
  {
    value: 'MR',
    label: 'Mauritánia',
  },
  {
    value: 'MU',
    label: 'Mauritius',
  },
  {
    value: 'MX',
    label: 'Mexikó',
  },
  {
    value: 'FM',
    label: 'Mikronézia',
  },
  {
    value: 'MD',
    label: 'Moldova',
  },
  {
    value: 'MC',
    label: 'Monacói',
  },
  {
    value: 'MN',
    label: 'Mongólia',
  },
  {
    value: 'ME',
    label: 'Montenegró',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Marokkó',
  },
  {
    value: 'MZ',
    label: 'Mozambik',
  },
  {
    value: 'MM',
    label: 'Mianmar',
  },
  {
    value: 'NA',
    label: 'Namíbia',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepál',
  },
  {
    value: 'NL',
    label: 'Hollandia',
  },
  {
    value: 'NC',
    label: 'Új-Kaledónia',
  },
  {
    value: 'NZ',
    label: 'Új Zéland',
  },
  {
    value: 'NI',
    label: 'Nicaragua',
  },
  {
    value: 'NE',
    label: 'Niger',
  },
  {
    value: 'NG',
    label: 'Nigéria',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Norfolk -sziget',
  },
  {
    value: 'NF',
    label: 'Észak -Macedónia',
  },
  {
    value: 'MP',
    label: 'Észak Mariana szigetek',
  },
  {
    value: 'NO',
    label: 'Norvégia',
  },
  {
    value: 'OM',
    label: 'Omán',
  },
  {
    value: 'PK',
    label: 'Pakisztán',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Pápua Új-Guinea',
  },
  {
    value: 'PY',
    label: 'Paraguay',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'PH',
    label: 'Fülöp -szigeteki',
  },
  {
    value: 'PL',
    label: 'Lengyelország',
  },
  {
    value: 'PT',
    label: 'Portugália',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'QA',
    label: 'Katar',
  },
  {
    value: 'RO',
    label: 'Románia',
  },
  {
    value: 'RU',
    label: 'Orosz Föderáció',
  },
  {
    value: 'RW',
    label: 'Ruanda',
  },
  {
    value: 'SH',
    label: 'Szent Helena, Ascension és Tristan Da Cunha',
  },
  {
    value: 'KN',
    label: 'Saint Kitts és Nevis',
  },
  {
    value: 'LC',
    label: 'Szent Lucia',
  },
  {
    value: 'PM',
    label: 'Szent Pierre és Miquelon',
  },
  {
    value: 'VC',
    label: 'Szent Vincent és a Grenadines',
  },
  {
    value: 'WS',
    label: 'Szamoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao Tome és Principe',
  },
  {
    value: 'SA',
    label: 'Szaud-Arábia',
  },
  {
    value: 'SN',
    label: 'Szenegál',
  },
  {
    value: 'RS',
    label: 'Szerbia',
  },
  {
    value: 'SC',
    label: 'Seychelles',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Szingapúr',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Szlovákia',
  },
  {
    value: 'SI',
    label: 'Szlovénia',
  },
  {
    value: 'SB',
    label: 'Salamon-szigetek',
  },
  {
    value: 'SO',
    label: 'Szomália',
  },
  {
    value: 'ZA',
    label: 'Dél-Afrika',
  },
  {
    value: 'SS',
    label: 'Dél Szudán',
  },
  {
    value: 'ES',
    label: 'Spanyolország',
  },
  {
    value: 'LK',
    label: 'Srí Lanka',
  },
  {
    value: 'SD',
    label: 'Szudán',
  },
  {
    value: 'SR',
    label: 'Suriname',
  },
  {
    value: 'SE',
    label: 'Svédország',
  },
  {
    value: 'CH',
    label: 'Svájc',
  },
  {
    value: 'SY',
    label: 'Szíriai Arab Köztársaság',
  },
  {
    value: 'TW',
    label: 'Tajvan',
  },
  {
    value: 'TJ',
    label: 'Tádzsikisztán',
  },
  {
    value: 'TZ',
    label: 'Tanzánia',
  },
  {
    value: 'TH',
    label: 'Thaiföld',
  },
  {
    value: 'TL',
    label: 'Timor-Leste',
  },
  {
    value: 'TG',
    label: 'Togo Köztársaság',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinidad és Tobago',
  },
  {
    value: 'TN',
    label: 'Tunézia',
  },
  {
    value: 'TR',
    label: 'Törökország Köztársaság',
  },
  {
    value: 'TM',
    label: 'Türkmenisztán',
  },
  {
    value: 'TC',
    label: 'Törökök és Caicosok',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukrajna',
  },
  {
    value: 'AE',
    label: 'Egyesült Arab Emírségek',
  },
  {
    value: 'GB',
    label: 'Egyesült Királyság',
  },
  {
    value: 'US',
    label: 'Egyesült Államok',
  },
  {
    value: 'US',
    label: 'Egyesült Államok Virgin -szigetek',
  },
  {
    value: 'UY',
    label: 'Uruguay',
  },
  {
    value: 'UZ',
    label: 'Üzbegisztán',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Vietnam',
  },
  {
    value: 'WF',
    label: 'Wallis és Futuna',
  },
  {
    value: 'YE',
    label: 'Jemen',
  },
  {
    value: 'ZM',
    label: 'Zambia',
  },
  {
    value: 'ZM',
    label: 'Zimbabwé',
  },
];
