import { Box } from '@mui/material';
import { useMemo } from 'react';
import {
  WineLabelCustomPictogramViewPictogramGroupEnum as PictogramGroupEnum,
  PublicWineLabelView,
} from '../../api';
import {
  getFlexibleGroups,
  getSequencedIngredientsAndGroups,
} from '../../utils/ingredient/ingredient-utils';
import Ingredients from './ingredients';
import MainInformation from './main-information';
import NutritionDeclaration from './nutrition-declaration';
import Origin from './origin';
import Responsibility from './responsibility';
import Sustainability from './sustainability';
import WinefoPointFooter from './winefo-point-footer';
import WinefoPointHeader from './winefo-point-header';

interface CustomerWinefoPointProps {
  data: PublicWineLabelView;
}

const CustomerWinefoPoint: React.FC<CustomerWinefoPointProps> = ({ data }) => {
  const {
    customPictograms,
    ingredient,
    nutritionDeclaration,
    pictograms,
    premiumFeature,
  } = data;
  const showMainSection = useMemo(
    () =>
      data.grapeVariety ||
      data.sweetness ||
      data.wineColour ||
      data.netQuantity ||
      data.alcoholByVolume ||
      data.productionMethod ||
      data.company?.countryCode ||
      data.company?.brandName,
    [data]
  );

  const showNutritionSection = useMemo(
    () =>
      nutritionDeclaration &&
      !!Object.values(nutritionDeclaration).filter((value) => value !== null)
        .length,
    [nutritionDeclaration]
  );

  const ingredientsAndGroups = useMemo(
    () => getSequencedIngredientsAndGroups(ingredient),
    [ingredient]
  );

  const flexibleGroups = useMemo(
    () => getFlexibleGroups(ingredient),
    [ingredient]
  );

  const getCustomPictograms = (group: PictogramGroupEnum) =>
    Array.from(customPictograms || []).filter(
      (it) => it.pictogramGroup === group
    );

  return (
    <Box
      minHeight="100vh"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: premiumFeature?.font,
      }}
    >
      <WinefoPointHeader data={data} />
      <Box width="min(710px, 100%)">
        {showMainSection && <MainInformation data={data} />}
        <Origin origin={pictograms?.origin} premiumFeature={premiumFeature} />
        <Sustainability
          defaultPictogramIds={Array.from(pictograms?.sustainability || [])}
          customPictograms={getCustomPictograms(
            PictogramGroupEnum.Sustainability
          )}
          detailedPictogramIds={Array.from(
            pictograms?.detailedSustainability || []
          )}
          premiumFeature={premiumFeature}
        />
        <Responsibility
          defaultPictogramIds={Array.from(
            pictograms?.responsibleConsumption || []
          )}
          customPictograms={getCustomPictograms(
            PictogramGroupEnum.ResponsibleCons
          )}
          premiumFeature={premiumFeature}
        />
        <Ingredients
          ingredientsAndGroups={ingredientsAndGroups}
          flexibleGroups={flexibleGroups}
          defaultAllergen={pictograms?.allergen}
          customAllergenPictogram={
            getCustomPictograms(PictogramGroupEnum.Allergen)[0]
          }
          premiumFeature={premiumFeature}
        />
        {showNutritionSection && (
          <NutritionDeclaration
            nutrition={nutritionDeclaration}
            premiumFeature={premiumFeature}
          />
        )}
      </Box>
      <WinefoPointFooter premiumFeature={premiumFeature} />
    </Box>
  );
};

export default CustomerWinefoPoint;
