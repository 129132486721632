import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface TitledAccordionProps extends AccordionProps {
  title?: string;
}

const TitledAccordion: React.FC<TitledAccordionProps> = ({
  title,
  children,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Accordion
      elevation={0}
      {...rest}
      sx={{
        '&.Mui-expanded': {
          mt: 0,
        },
        '&::before': {
          content: 'unset',
        },
        ...rest.sx,
      }}
    >
      <AccordionSummary
        sx={{
          borderRadius: '5px',
          bgcolor: grey[200],
          '&.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 0,
          },
          '.MuiAccordionSummary-content.Mui-expanded': {
            my: 1.5,
          },
        }}
        expandIcon={<ExpandMore />}
      >
        {title ?? t('edit-label.advanced-settings')}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          py: '10px',
          px: 0,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default TitledAccordion;
