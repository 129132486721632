import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import pictogramsApi from './pictograms-api';

interface DeleteCustomPictogramValue {
  pictogramId: number;
  onSuccess?: () => void;
}

const useDeleteCustomPictogram = (): UseMutationResult<
  AxiosResponse,
  unknown,
  DeleteCustomPictogramValue,
  unknown
> => {
  return useMutation({
    mutationFn: (variables: DeleteCustomPictogramValue) =>
      pictogramsApi.deleteCustomPictogram(variables.pictogramId),
    onSuccess: (_, variables) => {
      if (variables.onSuccess) variables.onSuccess();
    },
  });
};

export default useDeleteCustomPictogram;
