import { Box, Tooltip, TooltipProps } from '@mui/material';
import HelperIcon from '../../assets/icons/helper-icon';

interface HelperTooltipProps extends Omit<TooltipProps, 'children'> {
  children?: TooltipProps['children'];
  tooltipWidth?: number;
}

const HelperTooltip: React.FC<HelperTooltipProps> = ({
  children,
  tooltipWidth = 200,
  ...props
}) => (
  <Tooltip
    {...props}
    slotProps={{
      popper: { style: { maxWidth: tooltipWidth } },
      tooltip: { style: { fontSize: 13 } },
    }}
    placement={children ? 'top' : 'left'}
    arrow
  >
    {children || (
      <Box display="grid" fontSize={48}>
        <HelperIcon fontSize="inherit" />
      </Box>
    )}
  </Tooltip>
);
export default HelperTooltip;
