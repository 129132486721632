import { Typography } from '@mui/material';
import React from 'react';

interface LicenceCardPreHeaderProps {
  text?: React.ReactNode;
}

const LicenceCardPreHeader: React.FC<LicenceCardPreHeaderProps> = ({
  text,
}) => {
  return (
    <Typography textAlign="center" sx={{ py: 1, boxShadow: 26 }}>
      {text}
    </Typography>
  );
};

export default LicenceCardPreHeader;
