import * as React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import useSearchableOptions from '../../utils/select/use-searchable-options';
import SearchableSelect, { SearchableSelectProps } from './searchable-select';

interface SearchableSelectOptions
  extends Omit<SearchableSelectProps, 'optionMap'> {
  onSelectChange: (selected?: string | null) => void;
  data: { label: string; value: string }[];
  disableSorting?: boolean;
}

type SearchableSelectWithControllerProps<T extends FieldValues> = Omit<
  SearchableSelectOptions,
  'onSelectChange' | 'ref'
> & {
  control: Control<T>;
  name: FieldPath<T>;
};

const SearchableSelectWithController = <ObjectType extends FieldValues>({
  textFieldProps,
  control,
  name,
  data,
  disableSorting,
  ...otherProps
}: SearchableSelectWithControllerProps<ObjectType>) => {
  const sortedData = React.useMemo(() => {
    return disableSorting
      ? data
      : data.sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);
  const options = useSearchableOptions(sortedData);

  return (
    <Controller
      render={({ field: { onChange, ...fieldProps } }) => {
        return (
          <SearchableSelect
            optionMap={options}
            freeSolo={false}
            onSelectChange={onChange}
            textFieldProps={textFieldProps}
            {...fieldProps}
            {...otherProps}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
};

export default SearchableSelectWithController;
