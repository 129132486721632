import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Loading: React.FC = () => {
  const { t } = useTranslation();

  return <Box>{t('common.loading')}</Box>;
};

export default Loading;
