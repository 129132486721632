import { TFunction } from 'i18next';
import * as yup from 'yup';
import emailSchema from '../../utils/schemas/email-schema';
import passwordSchema from '../../utils/schemas/password-schema';
import profileFormSchema from '../settings/profile-settings/profile-form-schema';
import { SignupFormValue } from '../../models/signup';

const signupFormSchema = (t: TFunction): yup.ObjectSchema<SignupFormValue> => {
  return profileFormSchema(t)
    .concat(passwordSchema())
    .concat(emailSchema())
    .shape({
      termsPrivacyConsent: yup.boolean().oneOf([true]).required(),
    });
};

export default signupFormSchema;
