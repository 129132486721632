import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { CompanyRoleEnum } from '../../api';
import NumberInputWithoutArrows from '../../components/text-field/number-input-without-arrows';
import { CODE_DIGIT_NUMBER } from '../../constants/email-verifier';
import { ROUTES } from '../../constants/routes';
import useGetProfile from '../../data/profile/use-get-profile';
import { useAuthContext } from '../../services/auth-context';
import { useSnackbar } from '../../services/snackbar-context';
import InputWrapper from './input-wrapper';

const CodeVerifier: React.FC = () => {
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { verify } = useAuthContext();
  const { refetch: refetchProfile } = useGetProfile();
  const [code, setCode] = useState('');
  const handleVerifySuccess = () => {
    refetchProfile().then((profile) => {
      const isFederationMember =
        profile.data?.role === CompanyRoleEnum.FederationMember;
      navigate(isFederationMember ? ROUTES.LABELS : ROUTES.LICENCES);
    });
  };
  const getSnackbarMessage = (errorCode: string): string => {
    switch (errorCode) {
      case 'ExpiredCodeException':
        return 'email-verification.expired-code';
      case 'LimitExceededException':
        return 'email-verification.code-limit-exceeded';
      default:
        return 'email-verification.invalid-code';
    }
  };
  const handleVerifyError = useCallback(
    (error: { code: string }) => {
      setCode('');
      setSnackbar({
        message: t(getSnackbarMessage(error.code)),
      });
    },
    [setSnackbar, t]
  );

  useEffect(() => {
    if (code.length >= CODE_DIGIT_NUMBER) {
      verify(code)
        .then(handleVerifySuccess)
        .catch((error) => {
          handleVerifyError(error);
        });
    }
  }, [code]);

  return (
    <OtpInput
      value={code}
      onChange={(value: string) => setCode(value)}
      numInputs={CODE_DIGIT_NUMBER}
      shouldAutoFocus
      renderInput={(props) => (
        <InputWrapper>
          <NumberInputWithoutArrows {...props} type="number" />
        </InputWrapper>
      )}
      renderSeparator={() => <Box sx={{ width: '20px' }} />}
      containerStyle={{ padding: '10px 0 20px', justifyContent: 'center' }}
    />
  );
};

export default CodeVerifier;
