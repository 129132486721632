export default [
  {
    value: 'AF',
    label: 'Афганистан',
  },
  {
    value: 'AL',
    label: 'Албания',
  },
  {
    value: 'DZ',
    label: 'Алжир',
  },
  {
    value: 'AS',
    label: 'Американска Самоа',
  },
  {
    value: 'AD',
    label: 'Андора',
  },
  {
    value: 'AO',
    label: 'Ангола',
  },
  {
    value: 'AI',
    label: 'Ангила',
  },
  {
    value: 'AG',
    label: 'Антигуа и Барбуда',
  },
  {
    value: 'AR',
    label: 'Аржентина',
  },
  {
    value: 'AM',
    label: 'Армения',
  },
  {
    value: 'AW',
    label: 'Аруба',
  },
  {
    value: 'AU',
    label: 'Австралия',
  },
  {
    value: 'AT',
    label: 'Австрия',
  },
  {
    value: 'AZ',
    label: 'Азербайджан',
  },
  {
    value: 'BS',
    label: 'Бахами',
  },
  {
    value: 'BH',
    label: 'Бахрейн',
  },
  {
    value: 'BD',
    label: 'Бангладеш',
  },
  {
    value: 'BB',
    label: 'Барбадос',
  },
  {
    value: 'BY',
    label: 'Беларус',
  },
  {
    value: 'BE',
    label: 'Белгия',
  },
  {
    value: 'BZ',
    label: 'Белиз',
  },
  {
    value: 'BJ',
    label: 'Бенин',
  },
  {
    value: 'BM',
    label: 'Бермуда',
  },
  {
    value: 'BT',
    label: 'Бутан',
  },
  {
    value: 'BO',
    label: 'Боливия',
  },
  {
    value: 'BA',
    label: 'Босна и Херцеговина',
  },
  {
    value: 'BW',
    label: 'Ботсвана',
  },
  {
    value: 'BR',
    label: 'Бразилия',
  },
  {
    value: 'IO',
    label: 'Британски Вирджински острови',
  },
  {
    value: 'BN',
    label: 'Бруней Дарусалам',
  },
  {
    value: 'BG',
    label: 'България',
  },
  {
    value: 'BF',
    label: 'Буркина Фасо',
  },
  {
    value: 'BI',
    label: 'Бурунди',
  },
  {
    value: 'CV',
    label: 'Кабо Верде',
  },
  {
    value: 'KH',
    label: 'Камбоджа',
  },
  {
    value: 'CM',
    label: 'Камерун',
  },
  {
    value: 'CA',
    label: 'Канада',
  },
  {
    value: 'KY',
    label: 'Кайманови острови',
  },
  {
    value: 'CF',
    label: 'Централноафриканска република',
  },
  {
    value: 'TD',
    label: 'Чад',
  },
  {
    value: 'CL',
    label: 'Чили',
  },
  {
    value: 'CN',
    label: 'Китай',
  },
  {
    value: 'CO',
    label: 'Колумбия',
  },
  {
    value: 'KM',
    label: 'Коморос',
  },
  {
    value: 'CG',
    label: 'Конго',
  },
  {
    value: 'CK',
    label: 'Кук острови',
  },
  {
    value: 'CR',
    label: 'Коста Рика',
  },
  {
    value: 'CI',
    label: "Кот д'Ивоар",
  },
  {
    value: 'HR',
    label: 'Хърватия',
  },
  {
    value: 'CU',
    label: 'Куба',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Кипър',
  },
  {
    value: 'CZ',
    label: 'Чехия',
  },
  {
    value: 'CZ',
    label: 'Демократична народна република Корея',
  },
  {
    value: 'CD',
    label: 'Демократична република Конго',
  },
  {
    value: 'DK',
    label: 'Дания',
  },
  {
    value: 'DK',
    label: 'Диего Гарсия',
  },
  {
    value: 'DJ',
    label: 'Джибути',
  },
  {
    value: 'DM',
    label: 'Доминика',
  },
  {
    value: 'DO',
    label: 'Доминиканска република',
  },
  {
    value: 'EC',
    label: 'Еквадор',
  },
  {
    value: 'EG',
    label: 'Египет',
  },
  {
    value: 'SV',
    label: 'Ел Салвадор',
  },
  {
    value: 'GQ',
    label: 'Екваториална Гвинея',
  },
  {
    value: 'ER',
    label: 'Еритрея',
  },
  {
    value: 'EE',
    label: 'Естония',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Етиопия',
  },
  {
    value: 'FK',
    label: 'Фолклендските острови',
  },
  {
    value: 'FO',
    label: 'Фарьорските острови',
  },
  {
    value: 'FJ',
    label: 'Фиджи',
  },
  {
    value: 'FI',
    label: 'Финландия',
  },
  {
    value: 'FR',
    label: 'Франция',
  },
  {
    value: 'FR',
    label: 'Френски отдели и територии в Индийския океан',
  },
  {
    value: 'GF',
    label: 'Френска Гвиана',
  },
  {
    value: 'PF',
    label: 'Френска полинезия',
  },
  {
    value: 'GA',
    label: 'Габон',
  },
  {
    value: 'GM',
    label: 'Гамбия',
  },
  {
    value: 'GE',
    label: 'Джорджия',
  },
  {
    value: 'DE',
    label: 'Германия',
  },
  {
    value: 'GH',
    label: 'Гана',
  },
  {
    value: 'GI',
    label: 'Гибралтар',
  },
  {
    value: 'GR',
    label: 'Гърция',
  },
  {
    value: 'GL',
    label: 'Гренландия',
  },
  {
    value: 'GD',
    label: 'Гренада',
  },
  {
    value: 'GP',
    label: 'Гваделупа',
  },
  {
    value: 'GU',
    label: 'Гуам',
  },
  {
    value: 'GT',
    label: 'Гватемала',
  },
  {
    value: 'GG',
    label: 'Гвинея',
  },
  {
    value: 'GN',
    label: 'Гвинея-Бисау',
  },
  {
    value: 'GY',
    label: 'Гаяна',
  },
  {
    value: 'HT',
    label: 'Хаити',
  },
  {
    value: 'HN',
    label: 'Хондурас',
  },
  {
    value: 'HK',
    label: 'Хонг Конг',
  },
  {
    value: 'HU',
    label: 'Унгария',
  },
  {
    value: 'IS',
    label: 'Исландия',
  },
  {
    value: 'IN',
    label: 'Индия',
  },
  {
    value: 'ID',
    label: 'Индонезия',
  },
  {
    value: 'IR',
    label: 'Иран',
  },
  {
    value: 'IQ',
    label: 'Ирак',
  },
  {
    value: 'IE',
    label: 'Ирландия',
  },
  {
    value: 'IL',
    label: 'Израел',
  },
  {
    value: 'IT',
    label: 'Италия',
  },
  {
    value: 'JM',
    label: 'Ямайка',
  },
  {
    value: 'JP',
    label: 'Япония',
  },
  {
    value: 'JO',
    label: 'Йордания',
  },
  {
    value: 'KZ',
    label: 'Казахстан',
  },
  {
    value: 'KE',
    label: 'Кения',
  },
  {
    value: 'KI',
    label: 'Кирибати',
  },
  {
    value: 'KR',
    label: 'Корея',
  },
  {
    value: 'XK',
    label: 'Косово',
  },
  {
    value: 'KW',
    label: 'Кувейт',
  },
  {
    value: 'KG',
    label: 'Киргизстан',
  },
  {
    value: 'LA',
    label: 'Демократичната република на Лаото',
  },
  {
    value: 'LV',
    label: 'Латвия',
  },
  {
    value: 'LB',
    label: 'Ливан',
  },
  {
    value: 'LS',
    label: 'Лесото',
  },
  {
    value: 'LR',
    label: 'Либерия',
  },
  {
    value: 'LY',
    label: 'Либия',
  },
  {
    value: 'LI',
    label: 'Лихтенщайн',
  },
  {
    value: 'LT',
    label: 'Литва',
  },
  {
    value: 'LU',
    label: 'Люксембург',
  },
  {
    value: 'MO',
    label: 'Макао',
  },
  {
    value: 'MG',
    label: 'Мадагаскар',
  },
  {
    value: 'MW',
    label: 'Малави',
  },
  {
    value: 'MY',
    label: 'Малайзия',
  },
  {
    value: 'MV',
    label: 'Малдиви',
  },
  {
    value: 'ML',
    label: 'Мали',
  },
  {
    value: 'MT',
    label: 'Малта',
  },
  {
    value: 'MH',
    label: 'Маршаловите острови',
  },
  {
    value: 'MQ',
    label: 'Мартиника',
  },
  {
    value: 'MR',
    label: 'Мавритания',
  },
  {
    value: 'MU',
    label: 'Мавриций',
  },
  {
    value: 'MX',
    label: 'Мексико',
  },
  {
    value: 'FM',
    label: 'Микронезия',
  },
  {
    value: 'MD',
    label: 'Молдова',
  },
  {
    value: 'MC',
    label: 'Монако',
  },
  {
    value: 'MN',
    label: 'Монголия',
  },
  {
    value: 'ME',
    label: 'Черна гора',
  },
  {
    value: 'MS',
    label: 'Монсерат',
  },
  {
    value: 'MA',
    label: 'Мароко',
  },
  {
    value: 'MZ',
    label: 'Мозамбик',
  },
  {
    value: 'MM',
    label: 'Мианмар',
  },
  {
    value: 'NA',
    label: 'Намибия',
  },
  {
    value: 'NR',
    label: 'Науру',
  },
  {
    value: 'NP',
    label: 'Непал',
  },
  {
    value: 'NL',
    label: 'Холандия',
  },
  {
    value: 'NC',
    label: 'Нова Каледония',
  },
  {
    value: 'NZ',
    label: 'Нова Зеландия',
  },
  {
    value: 'NI',
    label: 'Никарагуа',
  },
  {
    value: 'NE',
    label: 'Нигер',
  },
  {
    value: 'NG',
    label: 'Нигерия',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Остров Норфолк',
  },
  {
    value: 'NF',
    label: 'Северна Македония',
  },
  {
    value: 'MP',
    label: 'Северни острови Мариана',
  },
  {
    value: 'NO',
    label: 'Норвегия',
  },
  {
    value: 'OM',
    label: 'Оман',
  },
  {
    value: 'PK',
    label: 'Пакистан',
  },
  {
    value: 'PW',
    label: 'Палау',
  },
  {
    value: 'PA',
    label: 'Панама',
  },
  {
    value: 'PG',
    label: 'Папуа-Нова Гвинея',
  },
  {
    value: 'PY',
    label: 'Парагвай',
  },
  {
    value: 'PE',
    label: 'Перу',
  },
  {
    value: 'PH',
    label: 'Филипини',
  },
  {
    value: 'PL',
    label: 'Полша',
  },
  {
    value: 'PT',
    label: 'Португалия',
  },
  {
    value: 'PR',
    label: 'Пуерто Рико',
  },
  {
    value: 'QA',
    label: 'Катар',
  },
  {
    value: 'RO',
    label: 'Румъния',
  },
  {
    value: 'RU',
    label: 'Руска федерация',
  },
  {
    value: 'RW',
    label: 'Руанда',
  },
  {
    value: 'SH',
    label: 'Света Елена, Възнесение и Тристан да Куня',
  },
  {
    value: 'KN',
    label: 'Сейнт Китс и Невис',
  },
  {
    value: 'LC',
    label: 'Свети Лусия',
  },
  {
    value: 'PM',
    label: 'Свети Пиер и Микелон',
  },
  {
    value: 'VC',
    label: 'Сейнт Винсент и гренадините',
  },
  {
    value: 'WS',
    label: 'Самоа',
  },
  {
    value: 'SM',
    label: 'Сан Марино',
  },
  {
    value: 'ST',
    label: 'Сао Томе и Принсипи',
  },
  {
    value: 'SA',
    label: 'Саудитска Арабия',
  },
  {
    value: 'SN',
    label: 'Сенегал',
  },
  {
    value: 'RS',
    label: 'Сърбия',
  },
  {
    value: 'SC',
    label: 'Сейшели',
  },
  {
    value: 'SL',
    label: 'Сиера Леоне',
  },
  {
    value: 'SG',
    label: 'Сингапур',
  },
  {
    value: 'SG',
    label: 'Синт Маартен',
  },
  {
    value: 'SK',
    label: 'Словакия',
  },
  {
    value: 'SI',
    label: 'Словения',
  },
  {
    value: 'SB',
    label: 'Соломонски острови',
  },
  {
    value: 'SO',
    label: 'Сомалия',
  },
  {
    value: 'ZA',
    label: 'Южна Африка',
  },
  {
    value: 'SS',
    label: 'Южен Судан',
  },
  {
    value: 'ES',
    label: 'Испания',
  },
  {
    value: 'LK',
    label: 'Шри Ланка',
  },
  {
    value: 'SD',
    label: 'Судан',
  },
  {
    value: 'SR',
    label: 'Суринам',
  },
  {
    value: 'SE',
    label: 'Швеция',
  },
  {
    value: 'CH',
    label: 'Швейцария',
  },
  {
    value: 'SY',
    label: 'Сирийска Арабска Република',
  },
  {
    value: 'TW',
    label: 'Тайван',
  },
  {
    value: 'TJ',
    label: 'Таджикистан',
  },
  {
    value: 'TZ',
    label: 'Танзания',
  },
  {
    value: 'TH',
    label: 'Тайланд',
  },
  {
    value: 'TL',
    label: 'Тимор-Лесте',
  },
  {
    value: 'TG',
    label: 'Того Република',
  },
  {
    value: 'TK',
    label: 'Токелау',
  },
  {
    value: 'TO',
    label: 'Тонга',
  },
  {
    value: 'TT',
    label: 'Тринидад и Тобаго',
  },
  {
    value: 'TN',
    label: 'Тунис',
  },
  {
    value: 'TR',
    label: 'Република Турция',
  },
  {
    value: 'TM',
    label: 'Туркменистан',
  },
  {
    value: 'TC',
    label: 'Турци и Кайкос',
  },
  {
    value: 'TV',
    label: 'Тувалу',
  },
  {
    value: 'UG',
    label: 'Уганда',
  },
  {
    value: 'UA',
    label: 'Украйна',
  },
  {
    value: 'AE',
    label: 'Обединени арабски емирства',
  },
  {
    value: 'GB',
    label: 'Великобритания',
  },
  {
    value: 'US',
    label: 'Съединени щати',
  },
  {
    value: 'US',
    label: 'Вирджинските острови на САЩ',
  },
  {
    value: 'UY',
    label: 'Уругвай',
  },
  {
    value: 'UZ',
    label: 'Узбекистан',
  },
  {
    value: 'VU',
    label: 'Вануату',
  },
  {
    value: 'VE',
    label: 'Венецуела',
  },
  {
    value: 'VN',
    label: 'Виетнам',
  },
  {
    value: 'WF',
    label: 'Уолис и Футуна',
  },
  {
    value: 'YE',
    label: 'Йемен',
  },
  {
    value: 'ZM',
    label: 'Замбия',
  },
  {
    value: 'ZM',
    label: 'Зимбабве',
  },
];
