import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { GenerateQrCode200Response } from '../../api';
import qrCodeApi from './qr-code-api';

interface GenerateQrCodeVariables {
  url: string;
  onSuccess: (response: GenerateQrCode200Response) => void;
}

const useGenerateQrCode = (): UseMutationResult<
  GenerateQrCode200Response,
  unknown,
  GenerateQrCodeVariables,
  unknown
> => {
  return useMutation({
    mutationFn: (variables: GenerateQrCodeVariables) =>
      qrCodeApi.generateQrCode(variables.url, {
        responseType: 'arraybuffer',
      }),
    onSuccess: (response, variables) => {
      variables.onSuccess(response);
    },
  });
};

export default useGenerateQrCode;
