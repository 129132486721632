import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { GridProps } from '@mui/material/Grid/Grid';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import useModal from '../../utils/modal/use-modal';
import FlexBoxColumn from '../flexbox/flex-box-column';
import ConfirmationModal from '../modal/confirmation-modal';

interface PictogramItemProps<T> {
  imageSrc: string;
  label: string;
  value: T;
  onChange: (value: T) => void;
  checked: boolean;
  tooltipProps?: Omit<TooltipProps, 'children'>;
  gridProps?: GridProps;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
  onDelete?: () => void;
}

const PictogramItem = <T,>({
  imageSrc,
  label,
  checked,
  onChange,
  value,
  tooltipProps,
  gridProps,
  imgProps,
  onDelete,
}: PictogramItemProps<T>) => {
  const { t } = useTranslation();
  const { openModal, isModalOpen, closeModal } = useModal();
  const deletable = typeof onDelete !== 'undefined';
  const openDeleteConfirmation = (
    event: React.MouseEvent<Element, MouseEvent>
  ) => {
    openModal();
    event.stopPropagation();
  };
  const deletePictogram = () => {
    if (onDelete) onDelete();
    closeModal();
  };

  return (
    <>
      <Tooltip title={tooltipProps?.title} {...tooltipProps}>
        <Grid item xs={4} {...gridProps}>
          <FlexBoxColumn alignItems="center">
            <Button onClick={() => onChange(value)}>
              {checked ? (
                <CheckCircleIcon
                  color="success"
                  sx={{ position: 'absolute', top: 0, right: 0 }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  sx={{ position: 'absolute', top: 0, right: 0 }}
                />
              )}
              {deletable && (
                <DeleteIcon
                  onClick={openDeleteConfirmation}
                  color="error"
                  sx={{ position: 'absolute', top: 0, left: 0 }}
                />
              )}
              <img
                height={80}
                width={80}
                style={{ margin: 10, objectFit: 'contain' }}
                src={imageSrc}
                {...imgProps}
              />
            </Button>
            <Typography
              fontSize={12}
              color="rgba(0,0,0,0.6)"
              textAlign="center"
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {label}
            </Typography>
          </FlexBoxColumn>
        </Grid>
      </Tooltip>
      <ConfirmationModal
        open={isModalOpen}
        onClose={closeModal}
        onConfirm={deletePictogram}
        labels={{
          message: t('edit-label.pictogram-delete-confirmation'),
        }}
      />
    </>
  );
};

export default PictogramItem;
