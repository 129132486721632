import { MailOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../../assets/images/settings-background.svg';
import BackButton from '../../components/button/back-button';
import FlexBox from '../../components/flexbox/flexbox';
import Background from '../../components/screen-template/background';
import { HEADER_HEIGHT } from '../../constants/dimensions';
import { ROUTES } from '../../constants/routes';
import ContactButton from '../stuck/contact-button';
import AccountSettingsSection from './account-settings-section';
import OtherSettingsSection from './other-settings-section';
import PremiumFeaturesSection from './premium-features-section';
import ProfileSettingsSection from './profile-settings-section';
import SystemSettingsSection from './system-settings-section';

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToLabels = () => navigate(ROUTES.LABELS);
  const height = `calc(100vh - ${HEADER_HEIGHT}px)`;

  return (
    <Background
      imageSource={BackgroundImage}
      height={height}
      overflow="auto"
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        placeItems: 'start',
        backgroundPosition: '100% 50px',
        backgroundSize: { xs: 0, md: '50% 100%' },
        backgroundAttachment: 'fixed',
      }}
    >
      <Box width="100%" maxWidth={800} justifySelf="center" overflow="auto">
        <FlexBox justifyContent="space-between" mt={3} ml={2}>
          <BackButton onClick={navigateToLabels}>
            {t('settings.winefo-list')}
          </BackButton>
          <ContactButton
            sx={{ width: 'initial' }}
            startIcon={<MailOutline />}
          />
        </FlexBox>
        <Box mt={3} mb={9} mx={4}>
          <Typography variant="h5">{t('settings.title')}</Typography>
          <PremiumFeaturesSection />
          <ProfileSettingsSection />
          <AccountSettingsSection />
          <SystemSettingsSection />
          <OtherSettingsSection />
        </Box>
      </Box>
    </Background>
  );
};

export default Settings;
