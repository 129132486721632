import { yupResolver } from '@hookform/resolvers/yup';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Grid } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import useCreateLabel from '../../data/wine-label/use-create-label';
import DatePickerWithController from '../date-picker/date-picker-with-controller';
import TextFieldWithController from '../text-field/text-field-with-controller';
import createLabelFormSchema from './create-label-form-schema';

export interface CreateLabelFormValue {
  labelName: string;
  harvestYear?: string;
}

interface CreateLabelFormProps {
  onSubmit: () => void;
}

const CreateLabelForm: React.FC<CreateLabelFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const createLabelMutation = useCreateLabel();
  const defaultValues = {
    labelName: '',
    harvestYear: '',
  };
  const { handleSubmit, control, formState, watch } =
    useForm<CreateLabelFormValue>({
      mode: 'onSubmit',
      defaultValues,
      resolver: yupResolver(createLabelFormSchema()),
    });

  const navigate = useNavigate();
  const { errors } = formState;
  const labelName = watch('labelName');
  const isSubmitDisabled = !labelName;

  const createLabel = (
    data: CreateLabelFormValue,
    withNavigation?: boolean
  ) => {
    const harvestYear =
      data.harvestYear === '' ? undefined : Number(data.harvestYear);
    createLabelMutation.mutate({
      value: {
        productName: data.labelName,
        harvestYear,
      },
      onSuccess: (id: number) => {
        if (withNavigation) navigate(generatePath(ROUTES.LABEL, { id }));
        onSubmit();
      },
    });
  };
  const onCreateLabelClick = (data: CreateLabelFormValue) => {
    createLabel(data);
  };
  const onAddMoreInfoClick = (data: CreateLabelFormValue) => {
    createLabel(data, true);
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldWithController
            control={control}
            name="labelName"
            textFieldProps={{
              label: t('create-label.label-name'),
              helperText:
                errors.labelName && t('create-label.label-name-error'),
              error: !!errors.labelName,
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePickerWithController
            control={control}
            name="harvestYear"
            datePickerProps={{
              views: ['year'],
              format: 'YYYY',
              label: t('create-label.harvest-year'),
              slotProps: {
                textField: {
                  error: !!errors.harvestYear,
                  fullWidth: true,
                  helperText:
                    errors.harvestYear && t('create-label.harvest-year-error'),
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            type="submit"
            disabled={isSubmitDisabled}
            onClick={handleSubmit(onCreateLabelClick)}
          >
            {t('header.create-label')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            fullWidth
            endIcon={<ChevronRightIcon />}
            disabled={isSubmitDisabled}
            onClick={handleSubmit(onAddMoreInfoClick)}
          >
            {t('create-label.add-more-info-button')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateLabelForm;
