import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const IngredientsSectionIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 34 34">
      <g id="ingredients-section-icon">
        <path
          d="M13.719 23.75L18.219 25.25C18.219 25.25 29.469 23 30.969 23C32.469 23 32.469 24.5 30.969 26C29.469 27.5 24.219 32 19.719 32C15.219 32 12.219 29.75 9.21899 29.75H1.71899"
          fill="none"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.71899 20.75C3.21899 19.25 6.21899 17 9.21899 17C12.219 17 19.344 20 20.469 21.5C21.594 23 18.219 25.25 18.219 25.25"
          fill="none"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.2189 17.75C25.4616 17.75 26.4689 16.7426 26.4689 15.5C26.4689 14.2574 25.4616 13.25 24.2189 13.25C22.9763 13.25 21.9689 14.2574 21.9689 15.5C21.9689 16.7426 22.9763 17.75 24.2189 17.75Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="3"
        />
        <path
          d="M15.219 12.5C16.4616 12.5 17.469 11.4926 17.469 10.25C17.469 9.00736 16.4616 8 15.219 8C13.9764 8 12.969 9.00736 12.969 10.25C12.969 11.4926 13.9764 12.5 15.219 12.5Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="3"
        />
        <path
          d="M24.2189 6.5C25.4616 6.5 26.4689 5.49264 26.4689 4.25C26.4689 3.00736 25.4616 2 24.2189 2C22.9763 2 21.9689 3.00736 21.9689 4.25C21.9689 5.49264 22.9763 6.5 24.2189 6.5Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="3"
        />
      </g>
    </SvgIcon>
  );
};

export default IngredientsSectionIcon;
