export const ROUTES = {
  LABEL: `/label/:id`,
  LABELS: '/labels',
  LICENCES: '/licences',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SETTINGS: '/settings',
  PREMIUM_FEATURES: '/settings/premium-features',
  PROFILE_SETTINGS: '/settings/profile',
  SIGNUP: '/signup',
  STUCK: '/stuck',
  VERIFY_EMAIL: '/verify-email',
  COSTUMER_WINEFO_POINT: '/w/:id',
};
