import * as React from 'react';
import useSearchableOptions from '../../utils/select/use-searchable-options';
import SearchableSelect, {
  SearchableSelectProps,
} from '../select/searchable-select';
import usePrefixes from './use-prefixes';

export interface PrefixSelectOptions
  extends Omit<SearchableSelectProps, 'optionMap'> {
  onSelectChange: (selected?: string | null) => void;
}

const PrefixSelect = React.forwardRef<HTMLInputElement, PrefixSelectOptions>(
  (props: PrefixSelectOptions, ref) => {
    const data = usePrefixes();
    const options = useSearchableOptions(data);

    return (
      <SearchableSelect
        optionMap={options}
        freeSolo={false}
        {...props}
        ref={ref}
      />
    );
  }
);

PrefixSelect.displayName = 'PrefixSelect';

export default PrefixSelect;
