import React from 'react';
import Loading from '../../../components/loading/loading';
import useGetProfile from '../../../data/profile/use-get-profile';
import ProfileSettingsForm from './profile-settings-form';
import { toProfileFormValue } from './profile-utils';

const ProfileSettings: React.FC = () => {
  const { data } = useGetProfile();

  return data ? (
    <ProfileSettingsForm formData={toProfileFormValue(data)} />
  ) : (
    <Loading />
  );
};

export default ProfileSettings;
