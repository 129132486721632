import { Colors } from '../../../models/premium-features';

export enum LogoPositions {
  FOOTER = 'footer',
  HEADER = 'header',
}

export interface Logo {
  file?: File;
  src?: string;
  logoPosition?: LogoPositions;
  id?: number;
  originalFileName?: string;
}

export interface Logos {
  footer?: Logo;
  header?: Logo;
  useHeaderLogoTwice?: boolean;
}

export interface PremiumFeaturesFormValue {
  logos?: Logos;
  colors: Colors;
  fontFamily: string;
}
