import { Info, Warning } from '@mui/icons-material';
import React from 'react';
import InfoBox from '../../../components/info-box/info-box';
import { warning } from '../../../constants/colors';
import PreviewList from './preview-list';
import { t } from 'i18next';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';

interface PreviewProps {
  hasMainIngredient?: boolean;
}

const Preview: React.FC<PreviewProps> = ({ hasMainIngredient }) => {
  return (
    <FlexBoxColumn gap={1.25} mb={1.25}>
      <InfoBox
        label={t('edit-label.preview-info')}
        icon={<Info color="inherit" />}
      />
      <InfoBox
        label={t('edit-label.preview-warning')}
        color={warning.main}
        icon={<Warning color="inherit" />}
      />
      <PreviewList hasMainIngredient={hasMainIngredient} />
    </FlexBoxColumn>
  );
};

export default Preview;
