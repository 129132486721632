import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Fab, { FabProps } from '../../../components/button/fab';

interface SubIngredientsButtonProps extends FabProps {
  version: 'add' | 'edit';
}

const SubIngredientsButton: React.FC<SubIngredientsButtonProps> = ({
  version,
  ...rest
}) => {
  const { t } = useTranslation();
  const isEditVersion = version === 'edit';

  return (
    <Fab
      icon={isEditVersion ? <EditIcon /> : <AddIcon />}
      label={t(`edit-label.ingredients-section.${version}-ingredients`)}
      {...rest}
    />
  );
};

export default SubIngredientsButton;
