import { ProductCurrencyEnum } from '../../api';

export const getCurrencySign = (currency?: ProductCurrencyEnum) => {
  switch (currency) {
    case ProductCurrencyEnum.Eur:
      return '€';
    case ProductCurrencyEnum.Usd:
      return '$';
    default:
      return currency;
  }
};
