import { Info } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  WineLabelCustomPictogramViewPictogramGroupEnum as CustomPictogramGroup,
  PictogramsDtoDetailedSustainabilityEnum,
  PictogramsDtoResponsibleConsumptionEnum,
} from '../../../api';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import InfoBox from '../../../components/info-box/info-box';
import { EditLabelSplitFormProps } from '../../../models/label';
import DetailedSustainability from './detailed-sustainability';
import PictogramGroup from './pictogram-group';
import QrCodeTitleEditor from './qr-code-title-editor';

export const defaultPictogramSubsectionValues = {
  pictograms: {
    allergen: undefined,
    origin: { name: undefined, label: undefined },
    responsibleConsumption: [PictogramsDtoResponsibleConsumptionEnum.Pregnancy],
    sustainability: [],
    detailedSustainability: [] as PictogramsDtoDetailedSustainabilityEnum[],
  },
};

const PictogramSubSection: React.FC<EditLabelSplitFormProps> = ({
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <FlexBoxColumn gap={3}>
      <InfoBox
        icon={<Info color="inherit" />}
        label={t('edit-label.pictogram-info')}
      />
      <PictogramGroup {...props} group={CustomPictogramGroup.ResponsibleCons} />
      <PictogramGroup {...props} group={CustomPictogramGroup.Sustainability} />
      <DetailedSustainability {...props} />
      <QrCodeTitleEditor {...props} />
    </FlexBoxColumn>
  );
};

export default PictogramSubSection;
