import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { LogoutRequest } from '../../api';
import logoutApi from './logout-api';

interface LogoutParams {
  onSuccess: () => void;
}

const useLogout = (
  params: LogoutParams
): UseMutationResult<AxiosResponse<void>, unknown, LogoutRequest, unknown> => {
  return useMutation({
    mutationFn: (variables: LogoutRequest) => logoutApi.logout(variables),
    onSuccess: () => params.onSuccess(),
  });
};

export default useLogout;
