import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FlexBox from '../../../components/flexbox/flexbox';
import { Colors } from '../../../models/premium-features';

interface ExampleHeaderProps {
  colors?: Colors;
  logoSrc?: string;
}

const ExampleHeader: React.FC<ExampleHeaderProps> = ({ colors, logoSrc }) => {
  const { primary, primaryText } = colors || {};
  const { t } = useTranslation();
  const { palette } = useTheme();
  const isLogo = typeof logoSrc === 'string';

  return (
    <FlexBox
      bgcolor={primary}
      alignItems="center"
      px={2}
      py={0.25}
      gap={1}
      boxShadow={25}
    >
      <FlexBox
        alignItems="center"
        justifyContent="center"
        borderRadius="50%"
        height="34px"
        minWidth="34px"
        sx={{
          background: isLogo
            ? `center / contain no-repeat url(${logoSrc})`
            : palette.warning.dark,
        }}
      >
        {!isLogo && <Typography>1</Typography>}
      </FlexBox>
      <Box>
        <Typography
          variant="h6"
          color="white"
          sx={{
            fontSize: '15px',
            lineHeight: '22px',
            letterSpacing: 0.13,
            fontFamily: 'inherit',
            color: primaryText,
          }}
        >
          {t('premium-features.example.name-of-product-label')}
        </Typography>
        <Typography
          color="white"
          sx={{ fontFamily: 'inherit', color: primaryText }}
        >
          {2023}
        </Typography>
      </Box>
    </FlexBox>
  );
};

export default ExampleHeader;
