import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { Control, FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ColorEditor from './color-editor';
import { PremiumFeaturesFormValue } from './premium-features.types';
import TextColorEditor from './text-color-editor';

interface ColorProps {
  control: Control<PremiumFeaturesFormValue>;
  formState: FormState<PremiumFeaturesFormValue>;
}

const Colors: React.FC<ColorProps> = ({ control, formState }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography sx={{ my: 1.5 }}>{t('premium-features.colors')}</Typography>
      <ColorEditor
        control={control}
        formState={formState}
        name="colors.primary"
      />
      <TextColorEditor control={control} name="colors.primaryText" />
      <Divider sx={{ my: 4 }} />
      <ColorEditor
        control={control}
        formState={formState}
        name="colors.secondary"
      />
      <TextColorEditor control={control} name="colors.secondaryText" />
    </Box>
  );
};

export default Colors;
