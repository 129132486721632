import { Box, Card, DialogProps, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CardHeader from '../card/card-header';
import Modal from '../modal/modal';
import CreateLabelForm from './create-label-form';

const CreateLabelModal: React.FC<DialogProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { onClose } = props;
  const handleSubmit = () => {
    if (onClose) onClose({}, 'backdropClick');
  };

  return (
    <Modal
      closeButtonProps={{ sx: { color: palette.common.white } }}
      {...props}
    >
      <Card>
        <CardHeader sx={{ color: palette.common.white }}>
          <Box sx={{ mt: 6, ml: 6, mb: 2 }}>
            <Typography variant="h4">{t('create-label.title')}</Typography>
            <Typography variant="body2">
              {t('create-label.subtitle')}
            </Typography>
          </Box>
        </CardHeader>
        <Box m={6}>
          <CreateLabelForm onSubmit={handleSubmit} />
        </Box>
      </Card>
    </Modal>
  );
};

export default CreateLabelModal;
