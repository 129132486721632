import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

interface EditButtonProps {
  id: number;
}

const EditButton: React.FC<EditButtonProps> = ({ id }) => {
  const navigate = useNavigate();
  const navigateToEdit = () => {
    navigate(generatePath(ROUTES.LABEL, { id }));
  };

  return (
    <IconButton onClick={navigateToEdit} sx={{ ml: 'auto', mr: 'auto' }}>
      <EditIcon />
    </IconButton>
  );
};

export default EditButton;
