import { Card, CardHeader, Typography } from '@mui/material';
import { error as errorBackgroundColor, primary } from '../../constants/colors';
import { Colors } from '../../models/premium-features';
import FlexBox from '../flexbox/flexbox';

interface CustomerContainerProps {
  icon: React.ReactNode;
  children: React.ReactNode;
  title: string;
  error?: boolean;
  colors?: Partial<Colors>;
}

const CustomerContainer = ({
  icon,
  title,
  children,
  error,
  colors,
}: CustomerContainerProps) => {
  const backgroundColor = colors?.secondary || primary[100];
  const color = colors?.secondaryText || '#000';

  return (
    <Card sx={{ my: 3, mx: 2, borderRadius: '10px' }}>
      <CardHeader
        sx={{
          backgroundColor: error ? errorBackgroundColor : backgroundColor,
          color: error ? 'white' : color,
          '& .MuiCardHeader-title': {
            fontFamily: 'inherit',
          },
        }}
        title={
          <FlexBox gap={1} alignItems="center">
            {icon}
            <Typography fontFamily="inherit">{title}</Typography>
          </FlexBox>
        }
      />
      {children}
    </Card>
  );
};

export default CustomerContainer;
