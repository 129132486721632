import { useTranslation } from 'react-i18next';
import { PremiumFeature, WineLabelCustomPictogramView } from '../../api';
import HeaderIcon from '../../assets/icons/sustainability-icon';
import CustomerContainer from '../../components/customer-container/customer-container';
import FlexBox from '../../components/flexbox/flexbox';
import { SustainabilityPictograms } from '../../constants/pictograms';
import { PictogramId } from '../../models/pictogram';
import DetailedSustainability from './detailed-sustainability';
import Pictogram from './pictogram';
import { getColors } from './winefo-point-utils';

interface SustainabilityProps {
  defaultPictogramIds?: PictogramId[];
  customPictograms?: WineLabelCustomPictogramView[];
  detailedPictogramIds: PictogramId[];
  premiumFeature?: PremiumFeature;
}

const Sustainability = ({
  defaultPictogramIds,
  customPictograms,
  detailedPictogramIds,
  premiumFeature,
}: SustainabilityProps) => {
  const { t } = useTranslation();
  const defaultPictograms = SustainabilityPictograms.filter((it) =>
    defaultPictogramIds?.includes(it.id)
  );
  const hasDefaultPictogram = defaultPictograms.length > 0;
  const hasCustomPictogram = customPictograms && customPictograms.length > 0;
  const hasDetailedPictogram = detailedPictogramIds.length > 0;
  const hasPictograms =
    hasDefaultPictogram || hasCustomPictogram || hasDetailedPictogram;
  const colors = getColors(premiumFeature);

  return hasPictograms ? (
    <CustomerContainer
      title={t('pictograms.sustainability-title')}
      icon={<HeaderIcon />}
      colors={colors}
    >
      <FlexBox sx={{ p: 2, gap: 2, flexWrap: 'wrap' }}>
        {defaultPictograms.map((pictogram) => (
          <Pictogram key={pictogram.id} src={pictogram.src} />
        ))}
        {customPictograms?.map((pictogram) => (
          <Pictogram key={pictogram.id} src={pictogram.downloadUrl} />
        ))}
      </FlexBox>
      <DetailedSustainability pictogramIds={detailedPictogramIds} />
    </CustomerContainer>
  ) : (
    <></>
  );
};

export default Sustainability;
