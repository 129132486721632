import { useReducer } from 'react';
import { IngredientGroupOrderIngredientGroupEnum as IngredientGroupEnum } from '../api';
import {
  Ingredient,
  IngredientState,
  IngredientsSequencesFlexibles,
  SequencedIngredientOrGroup,
} from '../models/ingredient';
import { defaultIngredientList } from '../constants/ingredients';

export enum IngredientActionTypes {
  SET_MAIN_INGREDIENT = 'SET_MAIN_INGREDIENT',
  SET_INGREDIENTS = 'SET_INGREDIENTS',
  SET_SEQUENCED_INGREDIENTS_AND_GROUPS = 'SET_SEQUENCED_INGREDIENTS_AND_GROUPS',
  SET_FLEXIBLE_GROUPS = 'SET_FLEXIBLE_GROUPS',
  SET_HAS_CHANGE = 'SET_HAS_CHANGE',
  SET_INGREDIENTS_SEQUENCES_FLEXIBLES = 'SET_INGREDIENTS_SEQUENCES_FLEXIBLES',
  SET_SHOULD_UPDATE_UPPER_CONTEXT = 'SET_SHOULD_UPDATE_UPPER_CONTEXT',
}

interface SetMainIngredientAction {
  type: IngredientActionTypes.SET_MAIN_INGREDIENT;
  value?: Ingredient;
}

interface SetIngredientsAction {
  type: IngredientActionTypes.SET_INGREDIENTS;
  value: Ingredient[];
}

interface SetSequencedIngredientsAndGroupsAction {
  type: IngredientActionTypes.SET_SEQUENCED_INGREDIENTS_AND_GROUPS;
  value: SequencedIngredientOrGroup[];
}

interface SetFlexibleGroupsAction {
  type: IngredientActionTypes.SET_FLEXIBLE_GROUPS;
  value: IngredientGroupEnum[];
}

interface SetHasChangeAction {
  type: IngredientActionTypes.SET_HAS_CHANGE;
  value: boolean;
}

interface SetIngredientsSequencesFlexiblesAction {
  type: IngredientActionTypes.SET_INGREDIENTS_SEQUENCES_FLEXIBLES;
  value: IngredientsSequencesFlexibles;
}

interface SetShouldUpdateUpperContextAction {
  type: IngredientActionTypes.SET_SHOULD_UPDATE_UPPER_CONTEXT;
  value: boolean;
}

export type IngredientActions =
  | SetMainIngredientAction
  | SetIngredientsAction
  | SetSequencedIngredientsAndGroupsAction
  | SetFlexibleGroupsAction
  | SetHasChangeAction
  | SetIngredientsSequencesFlexiblesAction
  | SetShouldUpdateUpperContextAction;

export const ingredientReducer = (
  state: IngredientState,
  action: IngredientActions
): IngredientState => {
  switch (action.type) {
    case IngredientActionTypes.SET_MAIN_INGREDIENT:
      return { ...state, mainIngredient: action.value };
    case IngredientActionTypes.SET_INGREDIENTS:
      return { ...state, ingredients: action.value };
    case IngredientActionTypes.SET_SEQUENCED_INGREDIENTS_AND_GROUPS:
      return { ...state, sequencedIngredientsAndGroups: action.value };
    case IngredientActionTypes.SET_FLEXIBLE_GROUPS:
      return { ...state, flexibleGroups: action.value };
    case IngredientActionTypes.SET_HAS_CHANGE:
      return { ...state, hasChange: action.value };
    case IngredientActionTypes.SET_INGREDIENTS_SEQUENCES_FLEXIBLES:
      return { ...state, ...action.value };
    case IngredientActionTypes.SET_SHOULD_UPDATE_UPPER_CONTEXT:
      return { ...state, shouldUpdateUpperContext: action.value };
    default:
      return state;
  }
};

export const useIngredientReducer = () => {
  const [state, dispatch] = useReducer(ingredientReducer, {
    ingredients: defaultIngredientList,
    sequencedIngredientsAndGroups: [],
    flexibleGroups: [],
    hasChange: false,
    shouldUpdateUpperContext: false,
  });
  const setMainIngredient = (value?: Ingredient) => {
    dispatch({ type: IngredientActionTypes.SET_MAIN_INGREDIENT, value });
  };
  const setIngredients = (value: Ingredient[]) => {
    dispatch({ type: IngredientActionTypes.SET_INGREDIENTS, value });
  };
  const setSequencedIngredientsAndGroups = (
    value: SequencedIngredientOrGroup[]
  ) => {
    dispatch({
      type: IngredientActionTypes.SET_SEQUENCED_INGREDIENTS_AND_GROUPS,
      value,
    });
  };
  const setFlexibleGroups = (value: IngredientGroupEnum[]) => {
    dispatch({ type: IngredientActionTypes.SET_FLEXIBLE_GROUPS, value });
  };
  const setHasChange = (value: boolean) => {
    dispatch({ type: IngredientActionTypes.SET_HAS_CHANGE, value });
  };
  const setIngredientsSequencesFlexibles = (
    value: IngredientsSequencesFlexibles
  ) => {
    dispatch({
      type: IngredientActionTypes.SET_INGREDIENTS_SEQUENCES_FLEXIBLES,
      value,
    });
  };
  const setShouldUpdateUpperContext = (value: boolean) => {
    dispatch({
      type: IngredientActionTypes.SET_SHOULD_UPDATE_UPPER_CONTEXT,
      value,
    });
  };

  return {
    state,
    setMainIngredient,
    setIngredients,
    setSequencedIngredientsAndGroups,
    setFlexibleGroups,
    setHasChange,
    setIngredientsSequencesFlexibles,
    setShouldUpdateUpperContext,
  };
};
