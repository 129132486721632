import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Merchant } from '../../api';
import FlexBox from '../../components/flexbox/flexbox';

interface MerchantLabelProps {
  merchant: Merchant;
  title: string;
}

const MerchantLabel = ({ merchant, title }: MerchantLabelProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography fontWeight="bold" my={1} fontFamily="inherit">
        {title}:
      </Typography>
      <FlexBox gap={2}>
        <Typography mb={1} width={64} fontFamily="inherit">
          {t('common.name')}:
        </Typography>
        <Typography mb={1} fontFamily="inherit">
          {merchant.name}
        </Typography>
      </FlexBox>
      <FlexBox gap={2}>
        <Typography mb={1} width={64} fontFamily="inherit">
          {t('common.address')}:
        </Typography>
        <Typography mb={1} fontFamily="inherit">
          {merchant.address}
        </Typography>
      </FlexBox>
    </>
  );
};

export default MerchantLabel;
