import {
  Box,
  Button,
  Card,
  DialogProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardHeader from '../../../components/card/card-header';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import Modal from '../../../components/modal/modal';
import { LITER_TO_100ML } from '../../../constants/unit-convertion';
import { nutritionsToKcal } from '../../../utils/unit-convertion/nutritions-to-kcal';

interface EnergyCalculatorModalProps
  extends Omit<DialogProps, 'onClose' | 'onSubmit'> {
  sugar?: number;
  onClose: () => void;
  onSubmit: (energy: number, sugar?: number) => void;
}

interface Compounds {
  acid?: number;
  sugar?: number;
  alcohol?: number;
  polyol?: number;
}

const EnergyCalculatorModal: React.FC<EnergyCalculatorModalProps> = ({
  onSubmit,
  ...props
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { onClose } = props;

  const [compounds, setCompounds] = useState<Compounds>({
    alcohol: undefined,
    sugar: undefined,
    acid: 6,
    polyol: 7,
  });
  const { alcohol, sugar, acid, polyol } = compounds;

  useEffect(() => {
    if (props.sugar) {
      setCompounds({ ...compounds, sugar: props.sugar * LITER_TO_100ML });
    }
  }, [props.sugar]);

  const getFieldValue = (value: string) => {
    const sanitizedValue = value.replace(',', '.');
    const extendedValue = sanitizedValue === '.' ? '0.' : sanitizedValue;
    const normalizedValue =
      extendedValue !== '' &&
      Number(extendedValue) === 0 &&
      !extendedValue.includes('.')
        ? 0
        : extendedValue;

    return normalizedValue === '' ? undefined : normalizedValue;
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const fieldValue = (e.target as HTMLInputElement).value;
    const numbers = /[0-9]/;
    const decimalMarkers = /[.,]/;
    if (
      !(
        numbers.test(e.key) ||
        e.key === 'Backspace' ||
        e.key === 'Delete' ||
        e.key === 'Tab' ||
        e.key === 'ArrowLeft' ||
        e.key === 'ArrowRight' ||
        (decimalMarkers.test(e.key) && !fieldValue.includes('.'))
      )
    )
      e.preventDefault();
  };
  const handleFieldChange = (value: string, compound: keyof Compounds) => {
    setCompounds({
      ...compounds,
      [compound]: getFieldValue(value),
    });
  };
  const handleBlur = (fieldValue: string, compound: keyof Compounds) => {
    if (fieldValue[fieldValue.length - 1] === '.') {
      const trimmedValue = fieldValue.slice(0, -1);
      setCompounds({
        ...compounds,
        [compound]: trimmedValue.length
          ? getFieldValue(trimmedValue)
          : undefined,
      });
    }
  };
  const handleSubmit = () => {
    onSubmit(
      nutritionsToKcal(alcohol || 0, sugar || 0, acid || 0, polyol || 0),
      sugar ? parseFloat((sugar / LITER_TO_100ML).toFixed(3)) : undefined
    );
    onClose();
  };

  return (
    <Modal
      closeButtonProps={{ sx: { color: palette.common.white } }}
      {...props}
    >
      <Card>
        <CardHeader sx={{ color: palette.common.white }}>
          <Box sx={{ mt: 6, ml: 6, mb: 2 }}>
            <Typography variant="h4">{t('energy-calculator.title')}</Typography>
            <Typography variant="body2">
              {t('energy-calculator.subtitle')}
            </Typography>
          </Box>
        </CardHeader>
        <FlexBoxColumn gap="15px" p={6}>
          {(Object.keys(compounds) as Array<keyof Compounds>).map((it) => (
            <TextField
              key={it}
              label={t(`energy-calculator.${it}`)}
              fullWidth
              value={typeof compounds[it] === 'undefined' ? '' : compounds[it]}
              onKeyDown={handleKeyDown}
              onChange={(e) => handleFieldChange(e.target.value, it)}
              onBlur={(e) => handleBlur(e.target.value, it)}
            />
          ))}
          <Button variant="contained" onClick={handleSubmit}>
            {t('energy-calculator.submit')}
          </Button>
        </FlexBoxColumn>
      </Card>
    </Modal>
  );
};

export default EnergyCalculatorModal;
