import { Card, CardProps, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ReasonBoxProps extends CardProps {
  reasonIndex: number;
  hasMoreMessages?: boolean;
}

const ReasonBox: React.FC<ReasonBoxProps> = ({
  reasonIndex,
  hasMoreMessages,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Card
      sx={{
        py: 4,
        px: 2,
        backgroundColor: theme.palette.grey[100],
        boxShadow: 0,
        flex: 1,
      }}
    >
      <Typography
        variant="h5"
        color={theme.palette.warning.main}
        textAlign="center"
        sx={{ mb: 2.5 }}
      >
        {t(`stuck.reason-${reasonIndex}.title`)}
      </Typography>
      <Typography textAlign="center">
        {t(`stuck.reason-${reasonIndex}.message-1`)}
      </Typography>
      {hasMoreMessages && (
        <Typography textAlign="center">
          {t(`stuck.reason-${reasonIndex}.message-2`)}
        </Typography>
      )}
    </Card>
  );
};

export default ReasonBox;
