import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { Control, Controller, ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Logo from './logo';
import {
  LogoPositions,
  PremiumFeaturesFormValue,
} from './premium-features.types';

interface LogosProps {
  control: Control<PremiumFeaturesFormValue>;
}
const Logos: React.FC<LogosProps> = ({ control }) => {
  const { t } = useTranslation();
  const logoPositions = [LogoPositions.HEADER, LogoPositions.FOOTER];
  const handleFileSelect = (
    field: ControllerRenderProps<PremiumFeaturesFormValue, 'logos'>,
    file: File,
    position: LogoPositions
  ) => {
    const logo = {
      file,
      src: URL.createObjectURL(file),
      originalFileName: file.name,
      logoPosition: position,
    };
    field.onChange({ ...field.value, [position]: logo });
  };
  const handleDelete = (
    field: ControllerRenderProps<PremiumFeaturesFormValue, 'logos'>,
    position: LogoPositions
  ) => {
    const { value, onChange } = field;
    if (value?.useHeaderLogoTwice) {
      if (position === LogoPositions.FOOTER) {
        onChange({
          ...value,
          footer: undefined,
          useHeaderLogoTwice: false,
        });
      } else {
        onChange({ useHeaderLogoTwice: false });
      }
    } else {
      onChange({
        ...value,
        [position]: undefined,
      });
    }
  };
  const handleUseHeaderLogoTwiceClick = (
    field: ControllerRenderProps<PremiumFeaturesFormValue, 'logos'>
  ) => {
    const { value, onChange } = field;
    if (!value?.useHeaderLogoTwice) {
      onChange({
        ...value,
        footer: {
          ...value?.header,
          logoPosition: LogoPositions.FOOTER,
        },
        useHeaderLogoTwice: true,
      });
    } else {
      onChange({
        header: value?.header,
        useHeaderLogoTwice: false,
      });
    }
  };

  return (
    <Controller
      name="logos"
      control={control}
      render={({ field }) => (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          {logoPositions.map((position) => (
            <Box key={position}>
              <Logo
                position={position}
                field={field}
                onFileSelect={handleFileSelect}
                onDelete={handleDelete}
              />
              {position === LogoPositions.FOOTER && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!field.value?.useHeaderLogoTwice}
                      onChange={() => handleUseHeaderLogoTwiceClick(field)}
                    />
                  }
                  label={t('premium-features.use-first-twice')}
                  disabled={!field.value?.header}
                  sx={{ mt: 1 }}
                />
              )}
            </Box>
          ))}
        </Box>
      )}
    />
  );
};

export default Logos;
