import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { SeparatedMerchants } from '../../api';
import merchantsApi from './merchants-api';

export const MERCHANTS_QUERY_KEY = 'merchants';

const useRetrieveMerchants = (
  options?: UseQueryOptions<
    SeparatedMerchants,
    unknown,
    SeparatedMerchants,
    string[]
  >
): UseQueryResult<SeparatedMerchants, unknown> => {
  return useQuery({
    queryKey: [MERCHANTS_QUERY_KEY],
    queryFn: () =>
      merchantsApi.retrieveMerchants().then((result) => result.data),
    ...options,
  });
};

export default useRetrieveMerchants;
