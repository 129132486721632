import Prefixes from '../../assets/countries/prefixes';
import useCountriesByCode from '../../utils/countries/use-countries-by-code';

const usePrefixes = (): Array<{ label: string; value: string }> => {
  const data = useCountriesByCode();

  return data.map((country) => ({
    ...country,
    label: `${
      Prefixes.find((it) => it.countryCode === country.value)?.prefix
    } ${country.label}`,
  }));
};

export default usePrefixes;
