import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Button, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoOutlined } from '@mui/icons-material';
import FlexBox from '../../components/flexbox/flexbox';
import HelperTooltip from '../../components/helper/helper-tooltip';

interface ExportSectionProps {
  disabled?: boolean;
  onExport: () => void;
}

const ExportSection: React.FC<ExportSectionProps> = ({
  disabled,
  onExport,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <FlexBox
      sx={{
        p: 1,
        backgroundColor: palette.primaryVariations[100],
        borderRadius: '10px 10px 0 0',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Button
        variant="outlined"
        startIcon={<SaveAltIcon />}
        sx={{ borderColor: 'rgba(33, 150, 243, 0.50)' }}
        onClick={onExport}
        disabled={disabled}
      >
        {t('labels.export-qr-codes')}
      </Button>
      <HelperTooltip title={t('labels.qr-helper')}>
        <InfoOutlined style={{ color: 'rgba(33, 150, 243, 0.50)' }} />
      </HelperTooltip>
      <Box display="grid" flex={1} justifyContent="end" pr="15px">
        <HelperTooltip title={t('labels.labels-helper')} tooltipWidth={300} />
      </Box>
    </FlexBox>
  );
};

export default ExportSection;
