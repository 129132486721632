import { MenuItem, TextFieldProps, useTheme } from '@mui/material';
import { blue } from '@mui/material/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES, languagesTranslation } from '../../constants/languages';
import Select from './select';

interface LanguageSelectorProps {
  variant?: 'dark' | 'light';
  color?: 'primary' | 'white';
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  variant,
  color,
}) => {
  const { palette } = useTheme();
  const { i18n } = useTranslation();
  const changeLanguage = (data: React.ChangeEvent<HTMLInputElement>) => {
    i18n.changeLanguage(data.target.value);
  };

  let options: TextFieldProps = {};

  if (variant === 'light') {
    options = {
      sx: {
        width: 'fit-content',
        '& > .MuiInputBase-root': {
          color:
            color === 'primary' ? palette.primary.main : palette.common.white,
          pl: 1,
          fontFamily: 'inherit',
          // eslint-disable-next-line max-len
          '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
            borderColor: blue[100],
            borderWidth: 1,
          },
          '& > .MuiInputBase-input': {
            py: 1,
            pl: 0,
            pr: 3,
          },
          '& .MuiSvgIcon-root': {
            fontSize: 16,
          },
        },
      },
    };
  }

  return (
    <Select {...options} value={i18n.language} onChange={changeLanguage}>
      {Object.keys(LANGUAGES).map((it) => {
        return (
          <MenuItem key={it} value={it}>
            {languagesTranslation[it as LANGUAGES]}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default LanguageSelector;
