import { BoxProps, useTheme } from '@mui/material';
import React from 'react';
import FlexBoxColumn from '../flexbox/flex-box-column';

const CardFooter: React.FC<BoxProps> = ({ children, sx, ...rest }) => {
  const theme = useTheme();

  return (
    <FlexBoxColumn
      sx={{
        alignItems: 'center',
        backgroundColor: theme.palette.grey[100],
        ...sx,
      }}
      {...rest}
    >
      {children}
    </FlexBoxColumn>
  );
};

export default CardFooter;
