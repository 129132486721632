import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignupForm from './signup-form';
import Template from './template';

const Signup: React.FC = () => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(1);
  const progressUnit = 25;
  const progressValue = Math.min(progress * progressUnit, 100);
  const handleProgress = () => {
    setProgress(progress + 1);
  };

  return (
    <Template progressValue={progressValue}>
      <Typography variant="h5">{t('signup.title')}</Typography>
      <Typography variant="body2" sx={{ mb: 4 }}>
        {t('signup.subtitle')}
      </Typography>
      <SignupForm onProgress={handleProgress} />
    </Template>
  );
};

export default Signup;
