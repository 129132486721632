import { useTranslation } from 'react-i18next';
import { PremiumFeature, WineLabelCustomPictogramView } from '../../api';
import HeaderIcon from '../../assets/icons/responsibility-icon';
import CustomerContainer from '../../components/customer-container/customer-container';
import FlexBox from '../../components/flexbox/flexbox';
import { ResponsibilityPictograms } from '../../constants/pictograms';
import { PictogramId } from '../../models/pictogram';
import Pictogram from './pictogram';
import { getColors } from './winefo-point-utils';

interface ResponsibilityProps {
  defaultPictogramIds?: PictogramId[];
  customPictograms?: WineLabelCustomPictogramView[];
  premiumFeature?: PremiumFeature;
}

const Responsibility = ({
  defaultPictogramIds,
  customPictograms,
  premiumFeature,
}: ResponsibilityProps) => {
  const { t } = useTranslation();
  const defaultPictograms = ResponsibilityPictograms.filter((it) =>
    defaultPictogramIds?.includes(it.id)
  );
  const hasDefaultPictogram = defaultPictograms.length > 0;
  const hasCustomPictogram = customPictograms && customPictograms.length > 0;
  const hasPictograms = hasDefaultPictogram || hasCustomPictogram;
  const colors = getColors(premiumFeature);

  return hasPictograms ? (
    <CustomerContainer
      title={t('pictograms.responsibility-title')}
      icon={<HeaderIcon />}
      colors={colors}
    >
      <FlexBox sx={{ p: 2, gap: 2, flexWrap: 'wrap' }}>
        {defaultPictograms.map((pictogram) => (
          <Pictogram key={pictogram.id} src={pictogram.src} />
        ))}
        {customPictograms?.map((pictogram) => (
          <Pictogram key={pictogram.id} src={pictogram.downloadUrl} />
        ))}
      </FlexBox>
    </CustomerContainer>
  ) : (
    <></>
  );
};

export default Responsibility;
