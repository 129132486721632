import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchContext } from '../../services/search-context';
import { useOutsideClickListener } from '../../utils/common/use-outside-click-listener';

const SearchBar: React.FC<TextFieldProps> = ({ sx, ...rest }) => {
  const { t } = useTranslation();
  const { setSearchText } = useSearchContext();
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { ref } = useOutsideClickListener(() => setIsFocused(false));
  const hasValue = value !== '';

  const handleSearch = () => {
    setSearchText(value);
  };
  const handleEnterKeyDown = () => {
    setIsFocused(false);
    inputRef.current?.blur();
    handleSearch();
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleEnterKeyDown();
  };
  const handleEndAdornmentClick = () => {
    setSearchText('');
    setIsFocused(false);
    if (value !== '') {
      setValue('');
    }
  };

  return (
    <TextField
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={handleKeyDown}
      onFocus={() => setIsFocused(true)}
      variant="standard"
      placeholder={t('header.search-bar.placeholder')}
      helperText={isFocused ? t('header.search-bar.helper-text') : ''}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            disableRipple
            onClick={handleEndAdornmentClick}
            sx={{
              p: 0,
              visibility: isFocused || hasValue ? 'visible' : 'hidden',
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
      }}
      sx={{ maxWidth: '220px', mb: isFocused ? 0 : '23px', ...sx }}
      inputRef={inputRef}
      ref={ref}
      {...rest}
    />
  );
};

export default SearchBar;
