export enum LANGUAGES {
  bg = 'bg',
  cs = 'cs',
  da = 'da',
  de = 'de',
  el = 'el',
  en = 'en',
  es = 'es',
  et = 'et',
  fi = 'fi',
  fr = 'fr',
  hr = 'hr',
  hu = 'hu',
  ga = 'ga',
  it = 'it',
  lt = 'lt',
  lv = 'lv',
  nl = 'nl',
  mt = 'mt',
  pl = 'pl',
  pt = 'pt',
  ro = 'ro',
  sk = 'sk',
  sl = 'sl',
  sv = 'sv',
}

type LanguagesMap = { [key in LANGUAGES]: string };

export const defaultLanguage = LANGUAGES.en;

export const languagesTranslation: LanguagesMap = {
  [LANGUAGES.bg]: 'България',
  [LANGUAGES.cs]: 'Čeština',
  [LANGUAGES.da]: 'Dansk',
  [LANGUAGES.de]: 'Deutsch',
  [LANGUAGES.el]: 'Ελληνικά',
  [LANGUAGES.en]: 'English',
  [LANGUAGES.es]: 'Español',
  [LANGUAGES.et]: 'Eesti',
  [LANGUAGES.fi]: 'Suomi',
  [LANGUAGES.fr]: 'Français',
  [LANGUAGES.hr]: 'Hrvatski',
  [LANGUAGES.hu]: 'Magyar',
  [LANGUAGES.ga]: 'Gaeilge',
  [LANGUAGES.it]: 'Italiano',
  [LANGUAGES.lv]: 'Latviešu',
  [LANGUAGES.lt]: 'Lietuvių',
  [LANGUAGES.nl]: 'Nederlands',
  [LANGUAGES.mt]: 'Malti',
  [LANGUAGES.pl]: 'Polski',
  [LANGUAGES.pt]: 'Português',
  [LANGUAGES.ro]: 'Română',
  [LANGUAGES.sk]: 'Slovenčina',
  [LANGUAGES.sl]: 'Slovenščina',
  [LANGUAGES.sv]: 'Svenska',
};

export const languageCodes = Object.values(LANGUAGES);

export const euCountryLanguageMapper = {
  AT: LANGUAGES.de,
  BE: LANGUAGES.de,
  BG: LANGUAGES.bg,
  HR: LANGUAGES.hr,
  CY: LANGUAGES.el,
  CZ: LANGUAGES.cs,
  DK: LANGUAGES.da,
  EE: LANGUAGES.et,
  FI: LANGUAGES.fi,
  FR: LANGUAGES.fr,
  DE: LANGUAGES.de,
  GR: LANGUAGES.el,
  HU: LANGUAGES.hu,
  IE: LANGUAGES.ga,
  IT: LANGUAGES.it,
  LV: LANGUAGES.lv,
  LT: LANGUAGES.lt,
  LU: LANGUAGES.de,
  MT: LANGUAGES.mt,
  NL: LANGUAGES.nl,
  PL: LANGUAGES.pl,
  PT: LANGUAGES.pt,
  RO: LANGUAGES.ro,
  SK: LANGUAGES.sk,
  SI: LANGUAGES.sl,
  ES: LANGUAGES.es,
  SE: LANGUAGES.sv,
};
