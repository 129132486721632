import { TFunction } from 'i18next';
import * as yup from 'yup';
import {
  Ingredient,
  NutritionDeclaration,
  QrCode,
  WineLabelCustomPictogramView,
  WineLabelViewProductionMethodEnum,
  WineLabelViewSweetnessEnum,
  WineLabelViewWineColourEnum,
} from '../../api';
import { LabelValue, PictogramsValue } from '../../models/label';
import { isLowerOrEqual } from '../../utils/label/nutrition';
import oneDecimalSchema from '../../utils/schemas/one-decimal-schema';

const editLabelSchema = (t: TFunction): yup.ObjectSchema<LabelValue> => {
  return yup.object().shape({
    company: yup
      .object()
      .shape({
        brandName: yup.string().optional(),
        countryCode: yup.string().optional(),
      })
      .optional(),
    productName: yup.string().required(),
    harvestYear: yup
      .string()
      .matches(/^(?:\d{4}|)$/, 'Must be a 4-digit year or empty.')
      .optional(),
    productionMethod: yup
      .mixed<WineLabelViewProductionMethodEnum>()
      .oneOf(Object.values(WineLabelViewProductionMethodEnum))
      .optional(),
    grapeVariety: yup.string().optional(),
    wineColour: yup
      .mixed<WineLabelViewWineColourEnum>()
      .oneOf([...Object.values(WineLabelViewWineColourEnum), undefined])
      .optional(),
    sweetness: yup
      .mixed<WineLabelViewSweetnessEnum>()
      .oneOf(Object.values(WineLabelViewSweetnessEnum))
      .optional(),
    netQuantity: yup.number().optional(),
    ingredient: yup.object<Ingredient>().optional(),
    preview: yup.array().of(yup.string().required()),
    pictograms: yup
      .object<PictogramsValue>()
      .shape({
        sustainability: yup.array(),
        responsibleConsumption: yup.array(),
        detailedSustainability: yup.array(),
      })
      .optional(),
    customPictograms: yup.mixed<Set<WineLabelCustomPictogramView>>().optional(),
    nutritionDeclaration: yup
      .object<NutritionDeclaration>()
      .shape({
        sugar: yup
          .string()
          .test('max', (value, context) =>
            isLowerOrEqual(value, context.parent.carbohydrate)
          )
          .optional(),
        saturatedFat: yup
          .string()
          .test('max', (value, context) =>
            isLowerOrEqual(value, context.parent.fat)
          )
          .optional(),
      })
      .optional(),
    alcoholByVolume: oneDecimalSchema(t, true),
    productReference: yup
      .string()
      .matches(/^.{0,200}$/)
      .optional(),
    gtinNumber: yup
      .string()
      .matches(/^(\d{8}|\d{12,14})$/)
      .optional(),
    qrCode: yup
      .object<QrCode>()
      .shape({
        withNutrition: yup.boolean().optional(),
        withRecycling: yup.boolean().optional(),
        topLanguageCode: yup.string(),
        bottomLanguageCode: yup.string(),
      })
      .test(
        'has at least one language when has at least one title part',
        ({
          withNutrition,
          withRecycling,
          topLanguageCode,
          bottomLanguageCode,
        }) =>
          (!withNutrition && !withRecycling) ||
          !!topLanguageCode ||
          !!bottomLanguageCode
      ),
    autoKcalConversion: yup.boolean().optional(),
  });
};

export default editLabelSchema;
