import UploadFileIcon from '@mui/icons-material/UploadFile';
import { BoxProps, Button, ButtonProps, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../services/snackbar-context';
import splitFileName from '../../utils/file/split-file-name';
import FlexBoxColumn from '../flexbox/flex-box-column';

interface FileSelectorProps {
  label: string;
  onSelect: (file: File) => void;
  wrapperProps?: BoxProps;
  buttonProps?: ButtonProps;
  allowedExtensions?: string[];
  maxFileSize?: number;
}

const FileSelector: React.FC<FileSelectorProps> = ({
  label,
  onSelect,
  wrapperProps,
  buttonProps,
  allowedExtensions,
  maxFileSize,
}) => {
  const {
    sx: buttonSx,
    children: buttonChildren,
    ...buttonRest
  } = buttonProps || {};
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const fileInput = useRef<HTMLInputElement>(null);
  const selectFile = () => {
    if (fileInput.current) fileInput.current.click();
  };
  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const { extension } = splitFileName(file.name);
      const isExtensionCorrect =
        !!extension &&
        (allowedExtensions
          ? allowedExtensions.includes(extension.toLowerCase())
          : true);
      const isSizeCorrect = maxFileSize ? file.size <= maxFileSize : true;
      if (isExtensionCorrect && isSizeCorrect) {
        onSelect(file);
      } else {
        setSnackbar({
          message: t('common.errors.image'),
        });
      }
      event.target.value = '';
    }
  };

  return (
    <FlexBoxColumn alignItems="center" {...wrapperProps}>
      <Button
        onClick={selectFile}
        sx={{ color: 'rgba(0,0,0,0.56)', ...buttonSx }}
        {...buttonRest}
      >
        {buttonChildren}
        <UploadFileIcon sx={{ fontSize: 80, m: 1.25 }} />
      </Button>
      <Typography fontSize={12} color="rgba(0,0,0,0.6)" textAlign="center">
        {label}
      </Typography>
      <input
        type="file"
        name="file"
        ref={fileInput}
        onChange={handleFileSelection}
        style={{ display: 'none' }}
      />
    </FlexBoxColumn>
  );
};

export default FileSelector;
