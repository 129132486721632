import useAxiosAuthorizationInterceptor from '../../utils/network/use-axios-authorization-interceptor';
import useAxiosErrorInterceptor from '../../utils/network/use-axios-error-interceptor';

interface AxiosInterceptorProps {
  children: React.ReactNode;
}

const AxiosInterceptor = ({ children }: AxiosInterceptorProps) => {
  useAxiosAuthorizationInterceptor();
  useAxiosErrorInterceptor();

  return <>{children}</>;
};

export default AxiosInterceptor;
