import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleBlocks from '../../components/collapsible-blocks/collapsible-blocks';
import CompanyForm from '../../components/company-form/company-form';
import LocationForm from '../../components/location-form/location-form';
import { SignupSplitFormProps } from '../../models/signup';
import AccountForm from './account-form';

export type SignupBlockType = 'company' | 'location' | 'account';

interface SignupBlocksProps extends SignupSplitFormProps {
  expandedBlocks: SignupBlockType[];
  handleBlockToggle: (
    block: SignupBlockType
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
  blocksValidationStatus: Record<SignupBlockType, boolean>;
  blocksDirtyStatus: Record<SignupBlockType, boolean>;
}

const SignupBlocks: React.FC<SignupBlocksProps> = ({
  expandedBlocks,
  handleBlockToggle,
  blocksValidationStatus,
  blocksDirtyStatus,
  ...splitFormProps
}) => {
  const { t } = useTranslation();

  return (
    <CollapsibleBlocks
      expandedBlocks={expandedBlocks}
      handleBlockToggle={handleBlockToggle}
      blocks={[
        {
          icon: <WorkIcon fontSize="inherit" />,
          title: t('signup.company-information'),
          details: <CompanyForm {...splitFormProps} />,
          value: 'company',
          isValid: blocksValidationStatus.company,
        },
        {
          icon: <LocationOnIcon fontSize="inherit" />,
          title: t('signup.location'),
          details: <LocationForm {...splitFormProps} />,
          value: 'location',
          isValid: blocksValidationStatus.location,
          disabled: !blocksDirtyStatus.company,
        },
        {
          icon: <AccountCircleIcon fontSize="inherit" />,
          title: t('signup.account-settings'),
          details: <AccountForm {...splitFormProps} />,
          value: 'account',
          isValid: blocksValidationStatus.account,
          disabled: !blocksDirtyStatus.location,
        },
      ]}
    />
  );
};

export default SignupBlocks;
