import { BoxProps, useTheme } from '@mui/material';
import React from 'react';
import FlexBoxColumn from '../../components/flexbox/flex-box-column';

const CardHeaderContentWrapper: React.FC<BoxProps> = ({ ...props }) => {
  const { palette } = useTheme();

  return (
    <FlexBoxColumn
      width="100%"
      alignItems="center"
      justifyContent="center"
      height="180px"
      sx={{
        color: palette.common.white,
      }}
      {...props}
    />
  );
};

export default CardHeaderContentWrapper;
