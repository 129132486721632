import { Delete, Info, Warning } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import InfoBox from '../../../components/info-box/info-box';
import { warning } from '../../../constants/colors';
import { ingredientGroups } from '../../../constants/ingredients';
import { useIngredientContext } from '../../../services/ingredient-context';
import { transformGroupName } from '../../../utils/ingredient/ingredient-utils';
import IngredientGroup from './ingredient-group';

interface IngredientSubsectionProps {
  isSubIngredient?: boolean;
}

const IngredientSubsection: React.FC<IngredientSubsectionProps> = ({
  isSubIngredient,
}) => {
  const { t } = useTranslation();
  const { resetIngredients } = useIngredientContext();

  return (
    <FlexBoxColumn gap="10px">
      <InfoBox
        label={t('edit-label.ingredient-info')}
        icon={<Info color="inherit" />}
      />
      <InfoBox
        label={t('edit-label.ingredient-warning')}
        color={warning.main}
        icon={<Warning color="inherit" />}
      />
      <Button
        color="error"
        sx={{ width: 'fit-content', ml: 'auto' }}
        startIcon={<Delete />}
        onClick={() => {
          resetIngredients();
        }}
      >
        {t('common.clear-selections')}
      </Button>
      {ingredientGroups.map((group) => {
        return (
          <IngredientGroup
            key={group}
            group={group}
            title={t(
              `edit-label.ingredient-category.${transformGroupName(group)}`
            )}
            isInSubIngredient={isSubIngredient}
          />
        );
      })}
    </FlexBoxColumn>
  );
};

export default IngredientSubsection;
