import { useTheme } from '@mui/material';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import React from 'react';

const StyledDataGrid: React.FC<DataGridProps> = ({ ...props }) => {
  const { palette } = useTheme();

  return (
    <DataGrid
      sx={{
        tableLayout: 'fixed',
        borderStyle: 'none',
        borderRadius: 'none',
        '.MuiDataGrid-columnHeader': {
          backgroundColor: palette.grey[100],
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        },
        '.MuiDataGrid-row.Mui-selected': {
          backgroundColor: '#2196F30A',
        },
        '.MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        // eslint-disable-next-line max-len
        '.MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
          {
            display: 'none',
          },
        '& .MuiDataGrid-overlayWrapper': {
          height: '144px',
        },
      }}
      {...props}
    />
  );
};

export default StyledDataGrid;
