import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FlexBox from '../../components/flexbox/flexbox';
import TooltipTypography from '../../components/typography/tooltip-typography';

interface ProductNameProps {
  data?: {
    productName?: string;
    productReference?: string;
    gtinNumber?: string;
  };
}

const ProductName: React.FC<ProductNameProps> = ({ data }) => {
  const { t } = useTranslation();
  const { productName, productReference, gtinNumber } = data || {};
  const { palette } = useTheme();
  const productReferenceRef = useRef<HTMLDivElement>(null);
  const [isProductReferenceEllipsised, setIsProductReferenceEllipsised] =
    useState(false);
  const hasGtinNumber = typeof gtinNumber === 'string';
  const hasProductReference = typeof productReference === 'string';
  const hasSecondLine = hasGtinNumber || hasProductReference;

  useEffect(() => {
    const typographyElement = productReferenceRef.current;
    if (typographyElement) {
      setIsProductReferenceEllipsised(
        typographyElement.offsetWidth < typographyElement.scrollWidth
      );
    }
  }, [productReference]);

  return (
    <Box py={hasSecondLine ? 0.5 : 0} maxWidth="100%">
      <Typography>{productName}</Typography>
      <FlexBox gap={0.5} color={palette.grey[500]}>
        {hasProductReference && (
          <Tooltip
            title={
              <>
                <TooltipTypography>
                  {t('labels.intr-tooltip')}
                </TooltipTypography>
                {isProductReferenceEllipsised && (
                  <TooltipTypography>{productReference}</TooltipTypography>
                )}
              </>
            }
          >
            <Typography
              ref={productReferenceRef}
              variant="body2"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {productReference}
            </Typography>
          </Tooltip>
        )}
        {hasProductReference && hasGtinNumber && (
          <Typography variant="body2">{`|`}</Typography>
        )}

        {hasGtinNumber && (
          <Tooltip title={t('labels.gtin-tooltip')}>
            <Typography variant="body2">{gtinNumber}</Typography>
          </Tooltip>
        )}
      </FlexBox>
    </Box>
  );
};

export default ProductName;
