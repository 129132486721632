import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { BoxProps, IconButton, useTheme } from '@mui/material';
import React from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ICON_MEDIUM2_SIZE } from '../../constants/dimensions';
import { ROUTES } from '../../constants/routes';
import useProfileCountry from '../../data/profile/use-profile-country';
import { useAuthContext } from '../../services/auth-context';
import CreateLabelButton from '../create-label/create-label-button';
import DeleteAccountButton from '../delete-account/delete-account-button';
import FlexBox from '../flexbox/flexbox';
import LanguageSelector from '../language-selector/language-selector';
import SearchBar from '../search-bar/search-bar';
import Container from './container';
import DlgLogo from './dlg-logo';
import Logo from './logo';

const Header: React.FC<BoxProps> = () => {
  const { palette } = useTheme();
  const { logout } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const protectedHeaderRoutes = [
    ROUTES.LABEL,
    ROUTES.LABELS,
    ROUTES.LABEL,
    ROUTES.LICENCES,
    ROUTES.SETTINGS,
    ROUTES.PREMIUM_FEATURES,
    ROUTES.PROFILE_SETTINGS,
    ROUTES.STUCK,
  ];
  const inverseHeaderRoutes = [
    ROUTES.LABELS,
    ROUTES.LABEL,
    ROUTES.SETTINGS,
    ROUTES.PREMIUM_FEATURES,
    ROUTES.PROFILE_SETTINGS,
  ];
  const routesWithCreateLabel = [
    ROUTES.LABELS,
    ROUTES.LABEL,
    ROUTES.SETTINGS,
    ROUTES.PREMIUM_FEATURES,
    ROUTES.PROFILE_SETTINGS,
  ];

  const isProtected = !!protectedHeaderRoutes.filter((route) =>
    matchPath(route, location.pathname)
  ).length;

  const isInverse = !!inverseHeaderRoutes.filter((route) =>
    matchPath(route, location.pathname)
  ).length;

  const withCreateLabel = !!routesWithCreateLabel.filter((route) =>
    matchPath(route, location.pathname)
  ).length;

  const isLicencesRoute = location.pathname === ROUTES.LICENCES;
  const { isGermanAccount } = useProfileCountry({ enabled: isLicencesRoute });
  const withDlgLogo = isGermanAccount && isLicencesRoute;
  const withSearchBar = location.pathname === ROUTES.LABELS;

  const handleLogoutClick = () => {
    logout();
    navigate(ROUTES.LOGIN);
  };
  const navigateToSettings = () => navigate(ROUTES.SETTINGS);

  return (
    <Container isInverse={isInverse}>
      <FlexBox gap={withDlgLogo ? 1 : 3.75} alignItems="center">
        {withDlgLogo && <DlgLogo />}
        <Logo isProtected={isProtected} isInverse={isInverse} />
        {withSearchBar && <SearchBar sx={{ alignSelf: 'flex-end' }} />}
      </FlexBox>
      {isProtected ? (
        <FlexBox alignItems="center">
          {withCreateLabel && <CreateLabelButton />}
          {isLicencesRoute ? (
            <DeleteAccountButton startIcon={<DeleteIcon />} sx={{ mr: 4 }} />
          ) : (
            <IconButton
              onClick={navigateToSettings}
              sx={{
                mr: 2,
                fontSize: ICON_MEDIUM2_SIZE,
                ...(isInverse ? {} : { color: palette.common.white }),
              }}
            >
              <SettingsIcon color="inherit" fontSize="inherit" />
            </IconButton>
          )}
          <IconButton
            onClick={handleLogoutClick}
            sx={{
              fontSize: ICON_MEDIUM2_SIZE,
              ...(isInverse ? {} : { color: palette.common.white }),
            }}
          >
            <LogoutIcon color="inherit" fontSize="inherit" />
          </IconButton>
        </FlexBox>
      ) : (
        <LanguageSelector />
      )}
    </Container>
  );
};

export default Header;
