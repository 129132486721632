import { TFunction } from 'i18next';
import {
  CustomIngredient,
  Ingredient as IngredientDto,
  IngredientGroupOrderIngredientGroupEnum as IngredientGroupEnum,
  IngredientGroupOrderIngredientGroupEnum,
} from '../../api';
import {
  defaultIngredientList,
  defaultIngredientsDisplayedInGroup,
} from '../../constants/ingredients';
import {
  Ingredient,
  IngredientState,
  IngredientsAndGroupsListItem,
  IngredientsSequencesFlexibles,
  SequencedIngredientOrGroup,
} from '../../models/ingredient';

export const transformGroupName = (
  type?: IngredientGroupEnum
): keyof IngredientDto | '' => {
  switch (type) {
    case IngredientGroupEnum.Antioxidant:
      return 'antioxidants';
    case IngredientGroupEnum.Acid:
      return 'acids';
    case IngredientGroupEnum.Custom:
      return 'customIngredients';
    case IngredientGroupEnum.EnrichmentSubstance:
      return 'enrichmentSubstances';
    case IngredientGroupEnum.ProcessingAid:
      return 'processingAids';
    case IngredientGroupEnum.Preservative:
      return 'preservatives';
    case IngredientGroupEnum.RawMaterial:
      return 'rawMaterials';
    case IngredientGroupEnum.Stabilizer:
      return 'stabilizers';
    case IngredientGroupEnum.Gas:
      return 'gases';
    case IngredientGroupEnum.OtherPractice:
      return 'otherPractices';
    default:
      return '';
  }
};

export const toIngredientGroupName = (value: keyof IngredientDto) => {
  switch (value) {
    case 'antioxidants':
      return IngredientGroupEnum.Antioxidant;
    case 'acids':
      return IngredientGroupEnum.Acid;
    case 'customIngredients':
      return IngredientGroupEnum.Custom;
    case 'enrichmentSubstances':
      return IngredientGroupEnum.EnrichmentSubstance;
    case 'processingAids':
      return IngredientGroupEnum.ProcessingAid;
    case 'preservatives':
      return IngredientGroupEnum.Preservative;
    case 'rawMaterials':
      return IngredientGroupEnum.RawMaterial;
    case 'stabilizers':
      return IngredientGroupEnum.Stabilizer;
    case 'gases':
      return IngredientGroupEnum.Gas;
    case 'otherPractices':
      return IngredientGroupEnum.OtherPractice;
  }
};

export const getIngredientsInDefaultGroups = (data?: IngredientDto) =>
  (Object.keys(data || {}) as Array<keyof IngredientDto>)
    .filter(
      (key) => key !== 'ingredientGroupOrders' && key !== 'customIngredients'
    )
    .map((key) => ({
      type: key,
      value: [
        ...(data?.[key] ? Array.from(data?.[key] as Set<Ingredient>) : []).map(
          (it) => ({
            ...it,
            ingredientGroup: toIngredientGroupName(key),
            isCustom: false,
            allergen: defaultIngredientList.find(
              (ingredient) =>
                ingredient.name === it.name &&
                ingredient.ingredientGroup === toIngredientGroupName(key)
            )?.allergen,
          })
        ),
        ...Array.from(data?.customIngredients || new Set<CustomIngredient>())
          .filter((it) => it.ingredientGroup === toIngredientGroupName(key))
          .map((it) => ({ ...it, isCustom: true })),
      ],
    }));

export const getSequencedIngredientsAndGroups = (
  data?: IngredientDto
): SequencedIngredientOrGroup[] => {
  const ingredientsInDefaultGroups = getIngredientsInDefaultGroups(data);
  const ingredientGroupOrders = data?.ingredientGroupOrders
    ? Array.from(data.ingredientGroupOrders)
    : [];
  const sequencedIngredients: SequencedIngredientOrGroup[] = [
    ...ingredientsInDefaultGroups
      .flatMap((it) => it.value)
      .filter(
        (it) =>
          !it.isCustom &&
          typeof it.name !== 'undefined' &&
          !defaultIngredientsDisplayedInGroup.includes(it.name)
      )
      .map((it) => {
        const subIngredients = (it as Ingredient).subIngredient
          ? // eslint-disable-next-line @typescript-eslint/no-use-before-define
            toIngredientsSequencesFlexibles((it as Ingredient).subIngredient)
          : undefined;
        return {
          ingredient: {
            ...it,
            subIngredients,
            tempSubIngredients: subIngredients,
          },
          sequenceNumber: it.sequenceNumber,
        };
      }),
    ...Array.from(data?.customIngredients || new Set<CustomIngredient>())
      .filter((it) => it.ingredientGroup === IngredientGroupEnum.Custom)
      .map((it) => ({
        ingredient: {
          ...it,
          isCustom: true,
        },
        sequenceNumber: it.sequenceNumber,
      })),
  ];
  const sequencedGroups: SequencedIngredientOrGroup[] = ingredientGroupOrders
    .filter((it) => typeof it.ingredientGroup !== 'undefined')
    .map((it) => ({
      group: {
        name: it.ingredientGroup as IngredientGroupEnum,
        ingredients:
          ingredientsInDefaultGroups.find(
            (item) =>
              item.type ===
              transformGroupName(it.ingredientGroup as IngredientGroupEnum)
          )?.value || [],
      },
      sequenceNumber: it.sequenceNumber,
    }));

  return [...sequencedIngredients, ...sequencedGroups]
    .sort((a, b) => (a.sequenceNumber || 0) - (b.sequenceNumber || 0))
    .map((it, index) => ({
      ...it,
      sequenceNumber: index,
    }));
};

export const getFlexibleGroups = (
  data?: IngredientDto
): IngredientGroupEnum[] => {
  const flexibleGroups = data?.flexibleIngredientGroupList
    ? Array.from(data.flexibleIngredientGroupList).filter(
        (it) => it.contains && typeof it.ingredientGroup !== 'undefined'
      )
    : [];
  return flexibleGroups.map(
    (it) => it.ingredientGroup as IngredientGroupOrderIngredientGroupEnum
  );
};

export const sameIngredients = (
  ingredientA: Ingredient,
  ingredientB: Ingredient
) =>
  ingredientA.name === ingredientB.name &&
  ingredientA.ingredientGroup === ingredientB.ingredientGroup;

export const isIngredientDisplayedInGroup = (ingredient: Ingredient) => {
  const isDefaultDisplayedInGroup = defaultIngredientsDisplayedInGroup.includes(
    ingredient.name || ''
  );
  const isCustomDisplayedInGroup =
    ingredient.isCustom &&
    ingredient.ingredientGroup !== IngredientGroupEnum.Custom;

  return isDefaultDisplayedInGroup || isCustomDisplayedInGroup;
};

export const isFlexibleGroup = (
  flexibleGroups: IngredientGroupEnum[],
  groupName?: IngredientGroupEnum
) =>
  typeof groupName === 'undefined' ? false : flexibleGroups.includes(groupName);

const getCustomIngredientLocalizedName = (
  ingredient: Ingredient,
  language: string
) =>
  ingredient.translations?.find((it) => it.languageCode === language)?.name ||
  ingredient.name ||
  '';
export const getIngredientsAndGroupsListItems = (
  ingredientsAndGroups: SequencedIngredientOrGroup[],
  flexibleGroups: IngredientGroupEnum[],
  t: TFunction,
  language: string
): IngredientsAndGroupsListItem[][] =>
  ingredientsAndGroups.map<IngredientsAndGroupsListItem[]>(
    (ingredientOrGroup) => {
      const isIngredient = typeof ingredientOrGroup.ingredient !== 'undefined';
      const isGroup =
        ingredientOrGroup.group &&
        ingredientOrGroup.group.ingredients.length > 0;

      if (isIngredient) {
        const subIngredients = ingredientOrGroup.ingredient?.tempSubIngredients;
        const isWithSubIngredients =
          subIngredients?.ingredients &&
          subIngredients.ingredients.some((it) => it.isSelected);
        const subIngredientsText = getIngredientsAndGroupsListItems(
          subIngredients?.sequencedIngredientsAndGroups || [],
          subIngredients?.flexibleGroups || [],
          t,
          language
        );
        const subIngredientsTextWithParenthesis = [
          { text: ' (', isGrouped: true },
          ...subIngredientsText
            .flat()
            .map((it, index) =>
              index === 0 || it.isGrouped
                ? [{ ...it, isSub: true }]
                : [
                    { text: ', ', isSub: true },
                    { ...it, isSub: true },
                  ]
            )
            .flat(),
          { text: ')', isGrouped: true },
        ];

        return [
          {
            text: ingredientOrGroup.ingredient?.isCustom
              ? getCustomIngredientLocalizedName(
                  ingredientOrGroup.ingredient,
                  language
                )
              : t(`ingredients.${ingredientOrGroup.ingredient?.name}`),
            isBold: !!ingredientOrGroup.ingredient?.allergen,
          },
          ...(isWithSubIngredients ? subIngredientsTextWithParenthesis : []),
        ];
      }

      if (isGroup) {
        const isFlexible = isFlexibleGroup(
          flexibleGroups,
          ingredientOrGroup.group?.name
        );
        const groupName = {
          text: `${t(
            `edit-label.ingredient-category.${transformGroupName(
              ingredientOrGroup.group?.name
            )}`
          )} `,
        };
        const parenthesisStart = {
          text: `(${isFlexible ? `${t('common.contains')} ` : ''}`,
          isGrouped: true,
        };
        const groupItems =
          ingredientOrGroup.group?.ingredients.reduce<
            IngredientsAndGroupsListItem[]
          >((previous, current, currentIndex) => {
            const separator = {
              text: `${isFlexible ? ` ${t('common.and-or')}` : ','} `,
              isGrouped: true,
            };
            const name = {
              text: current.isCustom
                ? getCustomIngredientLocalizedName(current, language)
                : t(`ingredients.${current.name}`),
              isBold: !!current.allergen,
              isGrouped: true,
            };

            if (currentIndex !== 0) previous.push(separator);
            previous.push(name);

            return previous;
          }, []) || [];
        const parenthesisEnd = { text: ')', isGrouped: true };

        return [groupName, parenthesisStart, ...groupItems, parenthesisEnd];
      }

      return [{ text: '' }];
    }
  );

export const getIngredientsAndGroupsListTexts = (
  ingredientsAndGroups: SequencedIngredientOrGroup[],
  flexibleGroups: IngredientGroupEnum[],
  t: TFunction,
  language: string
): string[] => {
  const ingredientsAndGroupsListItems = getIngredientsAndGroupsListItems(
    ingredientsAndGroups,
    flexibleGroups,
    t,
    language
  );

  return ingredientsAndGroupsListItems.map((it) => {
    return it.map((groupItem) => groupItem.text).join('');
  });
};

export const toIngredientsSequencesFlexibles = (data?: IngredientDto) => {
  const selectedDefaultIngredients = getIngredientsInDefaultGroups(data)
    .flatMap((it) => it.value)
    .filter((it) => !it.isCustom)
    .map((it) => ({
      name: it.name,
      subIngredient: (it as Ingredient).subIngredient,
    }));
  const ingredients: Ingredient[] = [
    ...defaultIngredientList.map((it) => {
      const selectedDefaultIngredient = selectedDefaultIngredients.find(
        (ingredient) => ingredient.name === it.name
      );
      const subIngredients = selectedDefaultIngredient?.subIngredient
        ? toIngredientsSequencesFlexibles(
            selectedDefaultIngredient.subIngredient
          )
        : undefined;

      return {
        ...it,
        isCustom: false,
        isSelected: !!selectedDefaultIngredient,
        subIngredients,
        tempSubIngredients: subIngredients,
      };
    }),
    ...Array.from(data?.customIngredients || new Set<CustomIngredient>()).map(
      (it) => ({
        ...it,
        isCustom: true,
        isSelected: true,
      })
    ),
  ];
  const sequencedIngredientsAndGroups = getSequencedIngredientsAndGroups(data);
  const flexibleGroups = getFlexibleGroups(data);

  return {
    ingredients,
    sequencedIngredientsAndGroups,
    flexibleGroups,
  };
};

export const getUpdatedIngredientsSequencesFlexibles = (props: {
  mainIngredient: Ingredient;
  version: keyof Ingredient;
  value?: IngredientsSequencesFlexibles;
  ingredientState: IngredientState;
}) => {
  const { mainIngredient, version, value, ingredientState } = props;
  const { ingredients, sequencedIngredientsAndGroups, flexibleGroups } =
    ingredientState;
  const updatedIngredients: Ingredient[] = ingredients.map((it) => {
    if (sameIngredients(it, mainIngredient)) {
      return {
        ...it,
        [version]: value,
      };
    }
    return it;
  });
  const updatedSequencedIngredientsAndGroups =
    sequencedIngredientsAndGroups.map((it) => {
      if (it.ingredient && sameIngredients(it.ingredient, mainIngredient))
        return {
          ...it,
          ingredient: {
            ...it.ingredient,
            [version]: value,
          },
        };
      return it;
    });

  return {
    ingredients: updatedIngredients,
    sequencedIngredientsAndGroups: updatedSequencedIngredientsAndGroups,
    flexibleGroups,
  };
};
