import React from 'react';
import FlexBox from '../../../components/flexbox/flexbox';
import { IconButton, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Translation } from '../../../api';
import CustomIngredientLanguageSelector from './custom-ingredient-language-selector';

interface CustomIngredientTranslationProps {
  translation: Translation;
  onChange: (translation: Translation) => void;
  onDelete: () => void;
  hiddenLanguageCodes?: string[];
  hideDeleteButton?: boolean;
  error?: boolean;
}

const CustomIngredientTranslation: React.FC<
  CustomIngredientTranslationProps
> = ({
  translation,
  onChange,
  onDelete,
  hiddenLanguageCodes,
  hideDeleteButton,
  error,
}) => {
  const handleTranslationNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => onChange({ ...translation, name: event.target.value });
  const handleLanguageCodeChange = (languageCode?: string) =>
    onChange({ ...translation, languageCode });

  return (
    <FlexBox gap={1} pr={hideDeleteButton ? 6.5 : 0}>
      <TextField
        fullWidth
        value={translation.name}
        onChange={handleTranslationNameChange}
        disabled={hideDeleteButton}
      />
      <CustomIngredientLanguageSelector
        value={translation.languageCode}
        onChange={handleLanguageCodeChange}
        hiddenLanguageCodes={hiddenLanguageCodes}
        error={error}
      />
      {!hideDeleteButton && (
        <IconButton disableRipple sx={{ ml: 0.5 }} onClick={onDelete}>
          <Delete color="error" />
        </IconButton>
      )}
    </FlexBox>
  );
};

export default CustomIngredientTranslation;
