import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { IconButton, Link } from '@mui/material';
import React from 'react';

interface PreviewButtonProps {
  link: string;
}

const PreviewButton: React.FC<PreviewButtonProps> = ({ link }) => {
  return (
    <Link
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
      sx={{ ml: 'auto', mr: 'auto' }}
    >
      <IconButton>
        <RemoveRedEyeIcon />
      </IconButton>
    </Link>
  );
};

export default PreviewButton;
