import { TFunction } from 'i18next';
import * as yup from 'yup';
import { ProfileFormValue } from '../../../models/profile';
import { isValidPhoneNumber } from '../../../utils/phone/phone-utils';
import { evaluateTaxId } from '../../../utils/tax-id/tax-id-utils';

const profileFormSchema = (
  t: TFunction
): yup.ObjectSchema<ProfileFormValue> => {
  yup.setLocale({
    mixed: {
      required: () => t('signup.general-error'),
    },
  });

  return yup.object().shape({
    contactPerson: yup.string().required(),
    prefix: yup.string().required(),
    phoneNumber: yup
      .string()
      .ensure()
      .when('prefix', ([prefix]) => {
        return yup
          .string()
          .required()
          .test({
            name: 'phoneFormat',
            exclusive: false,
            test: (phoneNumber?: string) => {
              if (!prefix || !phoneNumber) return false;
              return isValidPhoneNumber(prefix, phoneNumber);
            },
            message: t('signup.phone-number-error'),
          });
      }),
    companyBrand: yup.string().required(),
    companyLegalName: yup.string().required(),
    companyUrl: yup
      .string()
      .optional()
      .matches(
        // eslint-disable-next-line max-len
        /^((https?:\/\/)?[a-zA-Z\d-]+(\.[a-zA-Z\d-]+){1,}([^\s]*))?$/,
        t('signup.url-error')
      ),
    companyIdCountry: yup.string().required(),
    companyId: yup
      .string()
      .required()
      .when('companyIdCountry', ([companyIdCountry]: string[]) => {
        return yup
          .string()
          .required()
          .test('taxIdValidation', t('signup.company-id-error'), (taxId) => {
            return evaluateTaxId(taxId, companyIdCountry)?.isValidFormat;
          });
      }),
    address: yup.string().required(),
    postalCode: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    country: yup.string().required(),
    registrationCode: yup.string().optional(),
    marketingConsent: yup.boolean().optional(),
  });
};

export default profileFormSchema;
