import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import Fab, { FabProps } from '../../../components/button/fab';
import { useTranslation } from 'react-i18next';

interface CustomIngredientsTranslationsButtonProps extends FabProps {
  version: 'add' | 'edit' | 'more';
}

const CustomIngredientsTranslationsButton: React.FC<
  CustomIngredientsTranslationsButtonProps
> = ({ version, ...rest }) => {
  const { t } = useTranslation();
  const isEditVersion = version === 'edit';

  return (
    <Fab
      icon={isEditVersion ? <EditIcon /> : <AddIcon />}
      label={t(`edit-label.ingredients-section.${version}-translation`)}
      {...rest}
    />
  );
};

export default CustomIngredientsTranslationsButton;
