import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Trash2Icon from '../../assets/icons/trash-2-icon';
import FlexBox from '../../components/flexbox/flexbox';
import { Pictogram } from '../../models/pictogram';

interface WasteCategoryProps {
  pictograms: Pictogram[];
}

const WasteCategory: React.FC<WasteCategoryProps> = ({ pictograms }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <FlexBox gap={1.25} alignItems="flex-start">
      <img src={pictograms[0].src} style={{ maxWidth: '64px' }} />
      <Box width="100%">
        <FlexBox gap={0.5} p={0.5} bgcolor={palette.grey[100]}>
          <Trash2Icon sx={{ fontSize: '20px' }} />
          <Typography fontFamily="inherit">
            {t(`detailed-sustainability.bin.${pictograms[0].details?.waste}`)}
          </Typography>
        </FlexBox>
        {pictograms.map((pictogram) => (
          <Box
            key={pictogram.id}
            py={0.5}
            px={1}
            border={`1px solid ${palette.grey[100]}`}
          >
            <Typography variant="subtitle2" fontFamily="inherit">
              {t(
                // eslint-disable-next-line max-len
                `detailed-sustainability.category.${pictogram.details?.category}`
              )}
            </Typography>
            <Typography variant="body2" fontFamily="inherit">
              {t(pictogram.name)}
            </Typography>
          </Box>
        ))}
      </Box>
    </FlexBox>
  );
};

export default WasteCategory;
