import { DragIndicator } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import StrictModeDroppable from '../../../components/droppable/strict-mode-droppable';
import FlexBox from '../../../components/flexbox/flexbox';
import HelperTooltip from '../../../components/helper/helper-tooltip';
import IngredientList from '../../../components/ingredient-list/ingredient-list';
import { grey } from '../../../constants/colors';
import { useIngredientContext } from '../../../services/ingredient-context';
import { getIngredientsAndGroupsListTexts } from '../../../utils/ingredient/ingredient-utils';

interface PreviewListProps {
  hasMainIngredient?: boolean;
}

const PreviewList: React.FC<PreviewListProps> = ({ hasMainIngredient }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    sequencedIngredientsAndGroups,
    reorderSequencedIngredientsAndGroups,
    flexibleGroups,
    sequencedIngredientsAndGroupsInUpperContext,
    flexibleGroupsInUpperContext,
  } = useIngredientContext();
  const reorderIngredients = (result: DropResult) => {
    const startIndex = result.source.index;
    const endIndex = result.destination?.index;

    if (endIndex === undefined) {
      return;
    }

    reorderSequencedIngredientsAndGroups(startIndex, endIndex);
  };

  const ingredientsAndGroupsListTexts = getIngredientsAndGroupsListTexts(
    sequencedIngredientsAndGroups,
    flexibleGroups,
    t,
    language
  );

  return (
    <DragDropContext onDragEnd={reorderIngredients}>
      <StrictModeDroppable droppableId="reorder-container">
        {(provided) => (
          <Box
            bgcolor={grey[100]}
            display="flex"
            gap="10px"
            flexDirection="column"
            p="20px"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {ingredientsAndGroupsListTexts.map(
              (ingredientOrGroupText, index) => {
                return (
                  <Draggable
                    key={ingredientOrGroupText}
                    draggableId={ingredientOrGroupText}
                    index={index}
                  >
                    {(draggableProvided) => (
                      <Box
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        display="flex"
                        gap="10px"
                      >
                        <DragIndicator />
                        <Typography>{ingredientOrGroupText}</Typography>
                      </Box>
                    )}
                  </Draggable>
                );
              }
            )}
            {provided.placeholder}
          </Box>
        )}
      </StrictModeDroppable>
      <Box bgcolor={grey[100]} p="20px">
        <FlexBox alignItems="center" justifyContent="space-between" pb={1}>
          <Typography>{t('labels.preview')}</Typography>
          <HelperTooltip title={t('edit-label.preview-helper')} />
        </FlexBox>
        <IngredientList
          ingredientsAndGroups={
            hasMainIngredient
              ? sequencedIngredientsAndGroupsInUpperContext
              : sequencedIngredientsAndGroups
          }
          flexibleGroups={
            hasMainIngredient ? flexibleGroupsInUpperContext : flexibleGroups
          }
          inPreview
          containerProps={{ p: 0 }}
        />
      </Box>
    </DragDropContext>
  );
};

export default PreviewList;
