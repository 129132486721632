import { Box } from '@mui/material';
import React, { useState } from 'react';
import equal from 'react-fast-compare';
import useGetPremiumFeatures from '../../../data/premium-features/use-get-premium-features';
import useGetProfile from '../../../data/profile/use-get-profile';
import Example from './example';
import PremiumFeaturesForm from './premium-features-form';
import { useToPremiumFeaturesFormValue } from './premium-features-utils';
import { PremiumFeaturesFormValue } from './premium-features.types';

const PremiumFeatures: React.FC = () => {
  const { data: profile } = useGetProfile();
  const { data } = useGetPremiumFeatures({
    enabled: !!profile?.premiumFeature,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const defaultValue = useToPremiumFeaturesFormValue(data);
  const [formValue, setFormValue] =
    useState<PremiumFeaturesFormValue>(defaultValue);
  const handleFormValueChange = (value: PremiumFeaturesFormValue) => {
    if (!equal(formValue, value)) setFormValue(value);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
      }}
    >
      <PremiumFeaturesForm
        defaultValue={defaultValue}
        onChange={handleFormValueChange}
      />
      <Example premiumFeatures={formValue} />
    </Box>
  );
};

export default PremiumFeatures;
