import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CardHeader from '../../components/card/card-header';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <CardHeader>
      <Box sx={{ pt: 4, pl: 6, pb: 1.5, color: theme.palette.common.white }}>
        <Typography variant="h4">{t('login.title')}</Typography>
        <Typography variant="body2">{t('login.subtitle')}</Typography>
      </Box>
    </CardHeader>
  );
};

export default Header;
