import * as yup from 'yup';
import { CreateLabelFormValue } from './create-label-form';

const createLabelFormSchema = (): yup.ObjectSchema<CreateLabelFormValue> => {
  return yup.object().shape({
    labelName: yup.string().required(),
    harvestYear: yup
      .string()
      .matches(/^(?:\d{4}|)$/, 'Must be a 4-digit year or empty.')
      .optional(),
  });
};
export default createLabelFormSchema;
