import { Shadows, createTheme } from '@mui/material/styles';
import {
  error,
  grey,
  info,
  primary,
  success,
  warning,
} from '../../constants/colors';

const theme = createTheme({
  palette: {
    primary: {
      light: primary[500],
      main: primary[800],
    },
    primaryVariations: primary,
    error: {
      main: error,
    },
    success: {
      main: success,
    },
    warning: warning,
    info: {
      main: info,
    },
    grey,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
  shadows: [
    ...createTheme({}).shadows,
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
    '0px 4px 8px 0px rgba(0, 0, 0, 0.25) inset',
    '0px 2.57px 2.57px 0px rgba(0, 0, 0, 0.25)',
    '0px 1.71px 1.71px 0px rgba(0, 0, 0, 0.25)',
  ] as unknown as Shadows,
});

export default theme;
