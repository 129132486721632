import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FileSelector from '../../../components/file-selector/file-selector';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import FlexBox from '../../../components/flexbox/flexbox';
import ConfirmationModal from '../../../components/modal/confirmation-modal';
import TooltipTypography from '../../../components/typography/tooltip-typography';
import {
  ALLOWED_EXTENSIONS,
  MAX_FILE_SIZE,
} from '../../../constants/pictograms';
import useModal from '../../../utils/modal/use-modal';
import {
  LogoPositions,
  PremiumFeaturesFormValue,
} from './premium-features.types';

interface LogoProps {
  field: ControllerRenderProps<PremiumFeaturesFormValue, 'logos'>;
  position: LogoPositions;
  onFileSelect: (
    field: ControllerRenderProps<PremiumFeaturesFormValue, 'logos'>,
    file: File,
    position: LogoPositions
  ) => void;
  onDelete: (
    field: ControllerRenderProps<PremiumFeaturesFormValue, 'logos'>,
    position: LogoPositions
  ) => void;
}

const Logo: React.FC<LogoProps> = ({
  field,
  position,
  onFileSelect,
  onDelete,
}) => {
  const { t } = useTranslation();
  const isFooterLogo = position === LogoPositions.FOOTER;
  const { openModal, closeModal, isModalOpen } = useModal();

  return (
    <FlexBoxColumn>
      <FlexBox gap={1.25} mb={1.5}>
        <Typography>{t(`premium-features.logo-${position}`)}</Typography>
        <Tooltip
          title={
            <>
              <TooltipTypography>
                {t(`premium-features.logo-${position}-tooltip`)}
              </TooltipTypography>
              <TooltipTypography>
                {t('premium-features.logo-upload-restriction-1')}
              </TooltipTypography>
              <TooltipTypography>
                {t('premium-features.logo-upload-restriction-2')}
              </TooltipTypography>
            </>
          }
          arrow
        >
          <InfoOutlinedIcon />
        </Tooltip>
      </FlexBox>
      {field.value && field.value[position] ? (
        <Box
          width="100px"
          height="112px"
          alignSelf="center"
          mb={4.5}
          position="relative"
          sx={{
            background: `center / contain no-repeat\
                  url(${field.value[position]?.src})`,
          }}
        >
          <IconButton
            onClick={openModal}
            sx={{ position: 'absolute', top: -8, right: -8 }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      ) : (
        <FileSelector
          label={t('premium-features.upload-logo')}
          onSelect={(file: File) => onFileSelect(field, file, position)}
          wrapperProps={{ width: '100px', alignSelf: 'center' }}
          buttonProps={{
            disabled: isFooterLogo && field.value?.useHeaderLogoTwice,
          }}
          allowedExtensions={ALLOWED_EXTENSIONS}
          maxFileSize={MAX_FILE_SIZE}
        />
      )}
      <ConfirmationModal
        open={isModalOpen}
        onClose={closeModal}
        onConfirm={() => onDelete(field, position)}
        labels={{ message: t('premium-features.logo-modal-message') }}
      />
    </FlexBoxColumn>
  );
};

export default Logo;
