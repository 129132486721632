import { Star } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IngredientGroupOrder,
  IngredientGroupOrderIngredientGroupEnum,
  OrderedPreservativeNameEnum,
  OrderedRawMaterialNameEnum,
  WineLabelView,
  WineLabelViewSweetnessEnum,
  WineLabelViewWineColourEnum,
} from '../../../api';
import IngredientsIcon from '../../../assets/icons/ingredients-section-icon';
import NutritionIcon from '../../../assets/icons/nutrition-filled-icon';
import CustomerContainer from '../../../components/customer-container/customer-container';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import FlexBox from '../../../components/flexbox/flexbox';
import IngredientList from '../../../components/ingredient-list/ingredient-list';
import NutritionTable from '../../../components/nutrition-table/nutrition-table';
import ProductMainDetails from '../../../components/product-main-details/product-main-details';
import { getSequencedIngredientsAndGroups } from '../../../utils/ingredient/ingredient-utils';
import ExampleFooter from './example-footer';
import ExampleHeader from './example-header';
import { PremiumFeaturesFormValue } from './premium-features.types';

interface ExampleProps {
  premiumFeatures?: PremiumFeaturesFormValue;
}

const Example: React.FC<ExampleProps> = ({ premiumFeatures }) => {
  const { logos, fontFamily, colors } = premiumFeatures || {};
  const { t } = useTranslation();
  const { palette } = useTheme();
  const example: WineLabelView = {
    grapeVariety: t('premium-features.example.grape-variety'),
    wineColour: WineLabelViewWineColourEnum.White,
    sweetness: WineLabelViewSweetnessEnum.Dry,
    netQuantity: 750,
    alcoholByVolume: 14.5,
    ingredient: {
      rawMaterials: new Set([
        { name: OrderedRawMaterialNameEnum.Grapes, sequenceNumber: 0 },
      ]),
      preservatives: new Set([
        { name: OrderedPreservativeNameEnum.EggLysozyme },
        { name: OrderedPreservativeNameEnum.SulphurDioxide },
        { name: OrderedPreservativeNameEnum.Sulphites },
        { name: OrderedPreservativeNameEnum.PotassiumSorbate },
      ]),
      ingredientGroupOrders: [
        {
          sequenceNumber: 1,
          ingredientGroup: IngredientGroupOrderIngredientGroupEnum.Preservative,
        },
      ] as unknown as Set<IngredientGroupOrder>,
    },
    nutritionDeclaration: {
      energyKcal: '13',
      energyKj: '54',
      fat: '10',
      saturatedFat: '0',
    },
  };
  const ingredientsAndGroups = getSequencedIngredientsAndGroups(
    example.ingredient
  );

  return (
    <FlexBox
      height="100%"
      bgcolor={palette.grey[100]}
      py={4}
      justifyContent="center"
      fontFamily={fontFamily}
    >
      <Box width="321px" bgcolor="#FFF">
        <ExampleHeader colors={colors} logoSrc={logos?.header?.src} />
        <CustomerContainer
          title={t('winefo-point.section.main')}
          icon={<Star />}
          colors={colors}
        >
          <FlexBoxColumn gap={2} p={2}>
            <ProductMainDetails product={example} />
          </FlexBoxColumn>
        </CustomerContainer>
        <CustomerContainer
          title={t('common.ingredients')}
          icon={<IngredientsIcon />}
          colors={colors}
        >
          <IngredientList
            ingredientsAndGroups={ingredientsAndGroups}
            flexibleGroups={[]}
          />
        </CustomerContainer>
        <CustomerContainer
          title={t('common.nutrition-declaration')}
          icon={<NutritionIcon />}
          colors={colors}
        >
          <NutritionTable
            value={example.nutritionDeclaration}
            premiumFeature={{ secondaryColour: colors?.secondary }}
            isTrimmed
          />
        </CustomerContainer>
        <ExampleFooter
          logoSrc={
            logos?.useHeaderLogoTwice ? logos?.header?.src : logos?.footer?.src
          }
        />
      </Box>
    </FlexBox>
  );
};

export default Example;
