import { SvgIcon, SvgIconProps } from '@mui/material';

const OriginIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.798 2.19518C12.1926 2.04753 12.6238 2.02828 13.03 2.14018L13.202 2.19518L20.202 4.82018C20.5569 4.95327 20.8667 5.18448 21.0953 5.48688C21.3239 5.78928 21.4618 6.1504 21.493 6.52818L21.5 6.69318V12.0562C21.5 13.6766 21.0624 15.267 20.2336 16.6594C19.4048 18.0518 18.2154 19.1946 16.791 19.9672L16.525 20.1062L13.171 21.7832C12.9863 21.8754 12.7846 21.9285 12.5785 21.9392C12.3723 21.9498 12.1662 21.9178 11.973 21.8452L11.829 21.7832L8.475 20.1062C7.02561 19.3815 5.79878 18.2789 4.924 16.9148C4.04923 15.5507 3.55898 13.9758 3.505 12.3562L3.5 12.0562V6.69318C3.50001 6.31431 3.60763 5.94323 3.81033 5.62315C4.01304 5.30307 4.3025 5.04715 4.645 4.88518L4.798 4.82018L11.798 2.19518ZM15.933 8.62918L11.335 13.2272L9.567 11.4592C9.37936 11.2717 9.12492 11.1664 8.85965 11.1665C8.59438 11.1666 8.34001 11.272 8.1525 11.4597C7.96499 11.6473 7.8597 11.9018 7.8598 12.167C7.85989 12.4323 7.96536 12.6867 8.153 12.8742L10.557 15.2782C10.6592 15.3804 10.7804 15.4614 10.9139 15.5168C11.0474 15.5721 11.1905 15.6005 11.335 15.6005C11.4795 15.6005 11.6226 15.5721 11.7561 15.5168C11.8896 15.4614 12.0108 15.3804 12.113 15.2782L17.347 10.0432C17.4425 9.95093 17.5187 9.84059 17.5711 9.71858C17.6235 9.59658 17.6511 9.46536 17.6523 9.33258C17.6534 9.1998 17.6281 9.06812 17.5778 8.94523C17.5275 8.82233 17.4533 8.71068 17.3594 8.61678C17.2655 8.52289 17.1539 8.44864 17.031 8.39836C16.9081 8.34808 16.7764 8.32277 16.6436 8.32393C16.5108 8.32508 16.3796 8.35267 16.2576 8.40508C16.1356 8.45749 16.0252 8.53367 15.933 8.62918Z"
      />
    </SvgIcon>
  );
};

export default OriginIcon;
