import { Cached } from '@mui/icons-material';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  alpha,
  styled,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { FieldPathValue } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { NutritionDeclaration, PremiumFeature, WineLabelView } from '../../api';
import { error, primary } from '../../constants/colors';
import { KCAL_TO_KJ } from '../../constants/unit-convertion';
import { defaultNutritionSubsectionValues } from '../../screens/edit-label/ingredients-section/nutrition-subsection';
import { hexColorRegex } from '../../utils/common/hex-color-regex';
import { isLower, splitNutritionValue } from '../../utils/label/nutrition';
import FlexBox from '../flexbox/flexbox';

interface NutritionTableProps {
  value: FieldPathValue<WineLabelView, 'nutritionDeclaration'>;
  onChange?: (
    value: FieldPathValue<WineLabelView, 'nutritionDeclaration'>
  ) => void;
  isTrimmed?: boolean;
  premiumFeature?: PremiumFeature;
  withEnergyCalculation?: boolean;
}

const StyledTableCell = styled(TableCell)({
  height: 32.8,
  padding: 0,
  fontFamily: 'inherit',
  '&:last-child': {
    textAlign: 'center',
  },
  '& input::-webkit-inner-spin-button': {
    appearance: 'none',
  },
});

const StyledInput = styled('input')(({ onChange, theme }) => ({
  width: '100%',
  height: 32,
  border: 'none',
  backgroundColor: onChange ? grey[100] : 'inherit',
  textAlign: 'center',
  boxShadow: onChange ? (theme.shadows as string[])[26] : 'none',
  '&:focus-visible': {
    outline: `${theme.palette.grey[500]} solid 1px`,
  },
}));

const NutritionTable = ({
  value,
  onChange,
  isTrimmed,
  premiumFeature,
  withEnergyCalculation,
}: NutritionTableProps) => {
  const { t } = useTranslation();
  const [fromKcalToKJ, setFromKcalToKJ] = useState(false);
  const prevWithEnergyCalculation = useRef(withEnergyCalculation);
  const rowLabels = Object.keys(
    defaultNutritionSubsectionValues.nutritionDeclaration
  ).slice(0, isTrimmed ? 3 : undefined);
  const rowBackgroundColor = alpha(
    (premiumFeature?.secondaryColour || '').match(hexColorRegex)
      ? premiumFeature?.secondaryColour || ''
      : primary[100],
    0.3
  );

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    withoutOperators?: boolean,
    withoutDecimals?: boolean
  ) => {
    const fieldValue = (e.target as HTMLInputElement).value;
    const numbers = /[0-9]/;
    const inequalityOperators = /[<>]/;
    const decimalMarkers = /[.,]/;
    if (
      !(
        numbers.test(e.key) ||
        e.key === 'Backspace' ||
        e.key === 'Delete' ||
        e.key === 'Tab' ||
        (decimalMarkers.test(e.key) &&
          !fieldValue.includes('.') &&
          !withoutDecimals) ||
        (inequalityOperators.test(e.key) &&
          fieldValue.length === 0 &&
          !withoutOperators)
      )
    )
      e.preventDefault();
  };
  const getEnergyInputValue = (energyUnit: 'energyKcal' | 'energyKj') => {
    return value?.[energyUnit] ||
      value?.[energyUnit] === '0' ||
      !withEnergyCalculation
      ? (fromKcalToKJ && energyUnit === 'energyKcal') ||
        (!fromKcalToKJ && energyUnit === 'energyKj')
        ? value?.energyKcal
        : value?.energyKj
      : '';
  };

  const getNutritionValue = (
    text: string,
    energyUnit?: 'energyKcal' | 'energyKj',
    withoutTrailingComma?: boolean
  ) => {
    const { numericPart, inequalityOperator } = splitNutritionValue(
      text.replace(',', '.')
    );
    const isDecimal = numericPart.includes('.');
    const decimalPart = isDecimal ? numericPart.split('.')[1].slice(0, 3) : '';
    const isDecimalPartZero = isDecimal ? Number(decimalPart) === 0 : false;

    if (numericPart === '') return inequalityOperator;
    const isEnergyValue = typeof energyUnit !== 'undefined';
    const energyModifier = fromKcalToKJ
      ? energyUnit === 'energyKcal'
        ? 1
        : KCAL_TO_KJ
      : energyUnit === 'energyKcal'
      ? 1 / KCAL_TO_KJ
      : 1;
    const nutritionValue =
      Number(numericPart === '.' ? '0.' : numericPart) *
      (isEnergyValue ? energyModifier : 1);
    const decimalFactor = isEnergyValue ? 1 : 1000;

    return `${inequalityOperator}${
      (isEnergyValue
        ? Math.round(nutritionValue * decimalFactor)
        : Math.floor(nutritionValue * decimalFactor)) / decimalFactor
    }${
      isDecimal && isDecimalPartZero
        ? `${
            withoutTrailingComma && decimalPart.length === 0 ? '' : '.'
          }${decimalPart}`
        : ''
    }`;
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement, Element>,
    label: keyof NutritionDeclaration
  ) => {
    const fieldValue = (e.target as HTMLInputElement).value;
    if (fieldValue[fieldValue.length - 1] === '.' && onChange) {
      const trimmedValue = fieldValue.slice(0, -1);
      onChange({
        ...value,
        [label]: trimmedValue.length
          ? getNutritionValue(trimmedValue)
          : undefined,
      });
    }
    if (['<', '>'].includes(fieldValue) && onChange) {
      onChange({ ...value, [label]: undefined });
    }
  };

  const handleEnergyCalculationSwitchOn = () => {
    if (onChange) {
      onChange({
        ...value,
        energyKcal: fromKcalToKJ
          ? value?.energyKcal
          : getNutritionValue(value?.energyKj || '', 'energyKcal'),
        energyKj: fromKcalToKJ
          ? getNutritionValue(value?.energyKcal || '', 'energyKj')
          : value?.energyKj,
      });
    }
  };

  const renderRow = useCallback(
    (label: keyof NutritionDeclaration) => {
      switch (label) {
        case 'energyKcal':
          return (
            <Fragment key={label}>
              <TableRow sx={{ backgroundColor: rowBackgroundColor, mx: 10 }}>
                <StyledTableCell sx={{ pl: 2 }} colSpan={3}>
                  {t('edit-label.nutrition-category.energy')}
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: 'center' }}>
                  {onChange ? (
                    <StyledInput
                      value={getEnergyInputValue('energyKcal')}
                      onKeyDown={(e) => handleKeyDown(e, true, true)}
                      onChange={(e) => {
                        onChange({
                          ...value,
                          energyKcal: withEnergyCalculation
                            ? getNutritionValue(e.target.value, 'energyKcal')
                            : fromKcalToKJ
                            ? getNutritionValue(e.target.value, 'energyKcal')
                            : value?.energyKcal,
                          energyKj: withEnergyCalculation
                            ? getNutritionValue(e.target.value, 'energyKj')
                            : fromKcalToKJ
                            ? value?.energyKj
                            : getNutritionValue(e.target.value, 'energyKj'),
                        });
                      }}
                    />
                  ) : (
                    getEnergyInputValue('energyKcal')
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <FlexBox
                    alignItems="center"
                    justifyContent="center"
                    gap="5px"
                  >
                    {fromKcalToKJ ? t('common.unit.kcal') : t('common.unit.kJ')}
                    {onChange && withEnergyCalculation && (
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => setFromKcalToKJ((prev) => !prev)}
                      >
                        <Cached />
                      </IconButton>
                    )}
                  </FlexBox>
                </StyledTableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: rowBackgroundColor }}>
                <StyledTableCell sx={{ pl: 2 }} colSpan={3}></StyledTableCell>
                <StyledTableCell sx={{ textAlign: 'center' }}>
                  {onChange ? (
                    <>
                      {withEnergyCalculation ? (
                        <StyledInput
                          readOnly
                          value={getEnergyInputValue('energyKj')}
                        />
                      ) : (
                        <StyledInput
                          value={getEnergyInputValue('energyKj')}
                          onKeyDown={(e) => handleKeyDown(e, true, true)}
                          onChange={(e) => {
                            onChange({
                              ...value,
                              energyKcal: fromKcalToKJ
                                ? value?.energyKcal
                                : getNutritionValue(e.target.value, 'energyKj'),
                              energyKj: fromKcalToKJ
                                ? getNutritionValue(
                                    e.target.value,
                                    'energyKcal'
                                  )
                                : value?.energyKj,
                            });
                          }}
                        />
                      )}
                    </>
                  ) : (
                    getEnergyInputValue('energyKj')
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {fromKcalToKJ ? t('common.unit.kJ') : t('common.unit.kcal')}
                </StyledTableCell>
              </TableRow>
            </Fragment>
          );
        case 'saturatedFat':
        case 'sugar':
          return (
            <TableRow key={label}>
              <StyledTableCell />
              <StyledTableCell sx={{ pl: 2 }} colSpan={2}>
                {t('edit-label.nutrition-category.' + label)}
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: 'center' }}>
                {onChange ? (
                  <Tooltip
                    open={
                      label === 'sugar'
                        ? isLower(value?.carbohydrate, value?.sugar)
                        : isLower(value?.fat, value?.saturatedFat)
                    }
                    placement="right"
                    title={
                      label === 'sugar'
                        ? t('edit-label.sugar-error')
                        : t('edit-label.fat-error')
                    }
                    arrow
                  >
                    <StyledInput
                      value={value?.[label] || ''}
                      formNoValidate
                      style={{
                        color:
                          label === 'sugar'
                            ? isLower(value?.carbohydrate, value?.sugar)
                              ? error
                              : 'inherit'
                            : isLower(value?.fat, value?.saturatedFat)
                            ? error
                            : 'inherit',
                      }}
                      onKeyDown={handleKeyDown}
                      onChange={(e) =>
                        onChange({
                          ...value,
                          [label]: e.target.value.length
                            ? getNutritionValue(e.target.value)
                            : undefined,
                        })
                      }
                      onBlur={(e) => handleBlur(e, label)}
                    />
                  </Tooltip>
                ) : (
                  value?.[label]
                )}
              </StyledTableCell>
              <StyledTableCell>{t('common.unit.gramm')}</StyledTableCell>
            </TableRow>
          );
        case 'fat':
        case 'carbohydrate':
        case 'protein':
        case 'salt':
          return (
            <TableRow key={label}>
              <StyledTableCell sx={{ pl: 2 }} colSpan={3}>
                {t('edit-label.nutrition-category.' + label)}
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: 'center' }}>
                {onChange ? (
                  <StyledInput
                    value={value?.[label] || ''}
                    onKeyDown={handleKeyDown}
                    onChange={(e) =>
                      onChange({
                        ...value,
                        [label]: e.target.value.length
                          ? getNutritionValue(e.target.value)
                          : undefined,
                      })
                    }
                    onBlur={(e) => handleBlur(e, label)}
                  />
                ) : (
                  value?.[label]?.toString()
                )}
              </StyledTableCell>
              <StyledTableCell>{t('common.unit.gramm')}</StyledTableCell>
            </TableRow>
          );
      }
    },
    [fromKcalToKJ, value, onChange, withEnergyCalculation]
  );

  useEffect(() => {
    if (prevWithEnergyCalculation.current === false && withEnergyCalculation) {
      handleEnergyCalculationSwitchOn();
    }

    prevWithEnergyCalculation.current = withEnergyCalculation;
  }, [withEnergyCalculation]);

  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow>
          <StyledTableCell sx={{ pl: 2 }} colSpan={3}></StyledTableCell>
          <StyledTableCell sx={{ textAlign: 'center' }}>
            {t('edit-label.per-100-ml')}
          </StyledTableCell>
          <StyledTableCell>{t('edit-label.unit')}</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rowLabels.map((label) => renderRow(label as keyof typeof value))}
        {isTrimmed && (
          <TableRow>
            <StyledTableCell sx={{ pl: 2 }} colSpan={3}>
              ...
            </StyledTableCell>
            <StyledTableCell sx={{ textAlign: 'center' }}>...</StyledTableCell>
            <StyledTableCell>...</StyledTableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default NutritionTable;
