export default [
  {
    value: 'AF',
    label: 'Afghanistan',
  },
  {
    value: 'AL',
    label: 'Albania',
  },
  {
    value: 'DZ',
    label: 'Algeria',
  },
  {
    value: 'AS',
    label: 'Samoa americane',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguilla',
  },
  {
    value: 'AG',
    label: 'Antigua e Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentina',
  },
  {
    value: 'AM',
    label: 'Armenia',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Australia',
  },
  {
    value: 'AT',
    label: 'Austria',
  },
  {
    value: 'AZ',
    label: 'Azerbaigian',
  },
  {
    value: 'BS',
    label: 'Bahamas',
  },
  {
    value: 'BH',
    label: 'Bahrein',
  },
  {
    value: 'BD',
    label: 'Bangladesh',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Bielorussia',
  },
  {
    value: 'BE',
    label: 'Belgio',
  },
  {
    value: 'BZ',
    label: 'Belize',
  },
  {
    value: 'BJ',
    label: 'Benin',
  },
  {
    value: 'BM',
    label: 'Bermuda',
  },
  {
    value: 'BT',
    label: 'Bhutan',
  },
  {
    value: 'BO',
    label: 'Bolivia',
  },
  {
    value: 'BA',
    label: 'Bosnia Erzegovina',
  },
  {
    value: 'BW',
    label: 'Botswana',
  },
  {
    value: 'BR',
    label: 'Brasile',
  },
  {
    value: 'IO',
    label: 'Isole Vergini Britanniche',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bulgaria',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Cambogia',
  },
  {
    value: 'CM',
    label: 'Camerun',
  },
  {
    value: 'CA',
    label: 'Canada',
  },
  {
    value: 'KY',
    label: 'Isole Cayman',
  },
  {
    value: 'CF',
    label: 'Repubblica centrale africana',
  },
  {
    value: 'TD',
    label: 'Chad',
  },
  {
    value: 'CL',
    label: 'Chile',
  },
  {
    value: 'CN',
    label: 'Cina',
  },
  {
    value: 'CO',
    label: 'Colombia',
  },
  {
    value: 'KM',
    label: 'COMOROS',
  },
  {
    value: 'CG',
    label: 'Congo',
  },
  {
    value: 'CK',
    label: 'Isole Cook',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
  },
  {
    value: 'CI',
    label: "Costa d'Ivoire",
  },
  {
    value: 'HR',
    label: 'Croazia',
  },
  {
    value: 'CU',
    label: 'Cuba',
  },
  {
    value: 'CU',
    label: 'Curacao',
  },
  {
    value: 'CY',
    label: 'Cipro',
  },
  {
    value: 'CZ',
    label: 'Repubblica Ceca',
  },
  {
    value: 'CZ',
    label: 'Repubblica Democratica Popolare di Corea',
  },
  {
    value: 'CD',
    label: 'Repubblica Democratica del Congo',
  },
  {
    value: 'DK',
    label: 'Danimarca',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Djibouti',
  },
  {
    value: 'DM',
    label: 'Dominica',
  },
  {
    value: 'DO',
    label: 'Repubblica Dominicana',
  },
  {
    value: 'EC',
    label: 'Ecuador',
  },
  {
    value: 'EG',
    label: 'Egitto',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'GQ',
    label: 'Guinea Equatoriale',
  },
  {
    value: 'ER',
    label: 'Eritrea',
  },
  {
    value: 'EE',
    label: 'Estonia',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etiopia',
  },
  {
    value: 'FK',
    label: 'Isole Falkland',
  },
  {
    value: 'FO',
    label: 'Isole Faroe',
  },
  {
    value: 'FJ',
    label: 'Figi',
  },
  {
    value: 'FI',
    label: 'Finlandia',
  },
  {
    value: 'FR',
    label: 'Francia',
  },
  {
    value: 'FR',
    label: "Dipartimenti e territori francesi nell'Oceano Indiano",
  },
  {
    value: 'GF',
    label: 'Guiana francese',
  },
  {
    value: 'PF',
    label: 'Polinesia francese',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambia',
  },
  {
    value: 'GE',
    label: 'Georgia',
  },
  {
    value: 'DE',
    label: 'Germania',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GI',
    label: 'Gibilterra',
  },
  {
    value: 'GR',
    label: 'Grecia',
  },
  {
    value: 'GL',
    label: 'Groenlandia',
  },
  {
    value: 'GD',
    label: 'Grenada',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  {
    value: 'GG',
    label: 'Guinea',
  },
  {
    value: 'GN',
    label: 'Guinea-Bissau',
  },
  {
    value: 'GY',
    label: 'Guyana',
  },
  {
    value: 'HT',
    label: 'Haiti',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hong Kong',
  },
  {
    value: 'HU',
    label: 'Ungheria',
  },
  {
    value: 'IS',
    label: 'Islanda',
  },
  {
    value: 'IN',
    label: 'India',
  },
  {
    value: 'ID',
    label: 'Indonesia',
  },
  {
    value: 'IR',
    label: 'Iran',
  },
  {
    value: 'IQ',
    label: 'Iraq',
  },
  {
    value: 'IE',
    label: 'Irlanda',
  },
  {
    value: 'IL',
    label: 'Israele',
  },
  {
    value: 'IT',
    label: 'Italia',
  },
  {
    value: 'JM',
    label: 'Giamaica',
  },
  {
    value: 'JP',
    label: 'Giappone',
  },
  {
    value: 'JO',
    label: 'Giordania',
  },
  {
    value: 'KZ',
    label: 'Kazakistan',
  },
  {
    value: 'KE',
    label: 'Kenya',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Corea',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuwait',
  },
  {
    value: 'KG',
    label: 'Kirghizistan',
  },
  {
    value: 'LA',
    label: 'Repubblica democratica del popolo di Lao',
  },
  {
    value: 'LV',
    label: 'Lettonia',
  },
  {
    value: 'LB',
    label: 'Libano',
  },
  {
    value: 'LS',
    label: 'Lesotho',
  },
  {
    value: 'LR',
    label: 'Liberia',
  },
  {
    value: 'LY',
    label: 'Libia',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
  },
  {
    value: 'LT',
    label: 'Lituania',
  },
  {
    value: 'LU',
    label: 'Lussemburgo',
  },
  {
    value: 'MO',
    label: 'Macao',
  },
  {
    value: 'MG',
    label: 'Madagascar',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Malaysia',
  },
  {
    value: 'MV',
    label: 'Maldive',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Isole Marshall',
  },
  {
    value: 'MQ',
    label: 'Martinica',
  },
  {
    value: 'MR',
    label: 'Mauritania',
  },
  {
    value: 'MU',
    label: 'Mauritius',
  },
  {
    value: 'MX',
    label: 'Messico',
  },
  {
    value: 'FM',
    label: 'Micronesia',
  },
  {
    value: 'MD',
    label: 'Moldavia',
  },
  {
    value: 'MC',
    label: 'Monaco',
  },
  {
    value: 'MN',
    label: 'Mongolia',
  },
  {
    value: 'ME',
    label: 'Montenegro',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Marocco',
  },
  {
    value: 'MZ',
    label: 'Mozambico',
  },
  {
    value: 'MM',
    label: 'Myanmar',
  },
  {
    value: 'NA',
    label: 'Namibia',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepal',
  },
  {
    value: 'NL',
    label: 'Olanda',
  },
  {
    value: 'NC',
    label: 'Nuova Caledonia',
  },
  {
    value: 'NZ',
    label: 'Nuova Zelanda',
  },
  {
    value: 'NI',
    label: 'Nicaragua',
  },
  {
    value: 'NE',
    label: 'Niger',
  },
  {
    value: 'NG',
    label: 'Nigeria',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Norfolk Island',
  },
  {
    value: 'NF',
    label: 'Macedonia settentrionale',
  },
  {
    value: 'MP',
    label: 'Isole Marianne settentrionali',
  },
  {
    value: 'NO',
    label: 'Norvegia',
  },
  {
    value: 'OM',
    label: 'Oman',
  },
  {
    value: 'PK',
    label: 'Pakistan',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papua Nuova Guinea',
  },
  {
    value: 'PY',
    label: 'Paraguay',
  },
  {
    value: 'PE',
    label: 'Perù',
  },
  {
    value: 'PH',
    label: 'Filippine',
  },
  {
    value: 'PL',
    label: 'Polonia',
  },
  {
    value: 'PT',
    label: 'Portogallo',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'QA',
    label: 'Qatar',
  },
  {
    value: 'RO',
    label: 'Romania',
  },
  {
    value: 'RU',
    label: 'Federazione Russa',
  },
  {
    value: 'RW',
    label: 'Ruanda',
  },
  {
    value: 'SH',
    label: 'Saint Helena, Ascension e Tristan Da Cunha',
  },
  {
    value: 'KN',
    label: 'Saint Kitts e Nevis',
  },
  {
    value: 'LC',
    label: 'Santa Lucia',
  },
  {
    value: 'PM',
    label: 'Saint Pierre e Miquelon',
  },
  {
    value: 'VC',
    label: 'Saint Vincent e Grenadines',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao Tome e Principe',
  },
  {
    value: 'SA',
    label: 'Arabia Saudita',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Serbia',
  },
  {
    value: 'SC',
    label: 'Seychelles',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Singapore',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Slovacchia',
  },
  {
    value: 'SI',
    label: 'Slovenia',
  },
  {
    value: 'SB',
    label: 'Isole Salomone',
  },
  {
    value: 'SO',
    label: 'Somalia',
  },
  {
    value: 'ZA',
    label: 'Sud Africa',
  },
  {
    value: 'SS',
    label: 'Sudan del Sud',
  },
  {
    value: 'ES',
    label: 'Spagna',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
  },
  {
    value: 'SD',
    label: 'Sudan',
  },
  {
    value: 'SR',
    label: 'Suriname',
  },
  {
    value: 'SE',
    label: 'Svezia',
  },
  {
    value: 'CH',
    label: 'Svizzera',
  },
  {
    value: 'SY',
    label: 'Repubblica Araba Siriana',
  },
  {
    value: 'TW',
    label: 'Taiwan',
  },
  {
    value: 'TJ',
    label: 'Tajikistan',
  },
  {
    value: 'TZ',
    label: 'Tanzania',
  },
  {
    value: 'TH',
    label: 'Tailandia',
  },
  {
    value: 'TL',
    label: 'Timor Est',
  },
  {
    value: 'TG',
    label: 'Togo Republic',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinidad e Tobago',
  },
  {
    value: 'TN',
    label: 'Tunisia',
  },
  {
    value: 'TR',
    label: 'Repubblica turca',
  },
  {
    value: 'TM',
    label: 'Turkmenistan',
  },
  {
    value: 'TC',
    label: 'Turchi e caicos',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ucraina',
  },
  {
    value: 'AE',
    label: 'Emirati Arabi Uniti',
  },
  {
    value: 'GB',
    label: 'Regno Unito',
  },
  {
    value: 'US',
    label: 'Stati Uniti',
  },
  {
    value: 'US',
    label: 'Isole vergini degli Stati Uniti',
  },
  {
    value: 'UY',
    label: 'Uruguay',
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Viet Nam',
  },
  {
    value: 'WF',
    label: 'Wallis e Futuna',
  },
  {
    value: 'YE',
    label: 'Yemen',
  },
  {
    value: 'ZM',
    label: 'Zambia',
  },
  {
    value: 'ZM',
    label: 'Zimbabwe',
  },
];
