import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CardHeader from '../../components/card/card-header';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <CardHeader isWarning>
      <Typography
        variant="h4"
        sx={{ color: theme.palette.common.white, mt: 3.5, mb: 2.5, ml: 6 }}
      >
        {t('stuck.title')}
      </Typography>
    </CardHeader>
  );
};

export default Header;
