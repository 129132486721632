import {
  IngredientGroupOrderIngredientGroupEnum as IngredientGroupEnum,
  OrderedAcidNameEnum,
  OrderedAntioxidantNameEnum,
  OrderedEnrichmentSubstanceNameEnum,
  OrderedGasNameEnum,
  OrderedOtherPracticeNameEnum,
  OrderedPreservativeNameEnum,
  OrderedProcessingAidNameEnum,
  OrderedRawMaterialNameEnum,
  OrderedStabilizerNameEnum,
} from '../api';
import { Ingredient } from '../models/ingredient';

export const defaultIngredientList: Ingredient[] = [
  {
    name: OrderedRawMaterialNameEnum.Grapes,
    ingredientGroup: IngredientGroupEnum.RawMaterial,
    allergen: false,
  },
  {
    name: OrderedRawMaterialNameEnum.Water,
    ingredientGroup: IngredientGroupEnum.RawMaterial,
    allergen: false,
  },
  {
    name: OrderedEnrichmentSubstanceNameEnum.GrapeMust,
    ingredientGroup: IngredientGroupEnum.EnrichmentSubstance,
    allergen: false,
  },
  {
    name: OrderedEnrichmentSubstanceNameEnum.Sucrose,
    ingredientGroup: IngredientGroupEnum.EnrichmentSubstance,
    allergen: false,
  },
  {
    name: OrderedEnrichmentSubstanceNameEnum.Wine,
    ingredientGroup: IngredientGroupEnum.EnrichmentSubstance,
    allergen: false,
  },
  {
    name: OrderedAcidNameEnum.LacticAcid,
    ingredientGroup: IngredientGroupEnum.Acid,
    allergen: false,
  },
  {
    name: OrderedAcidNameEnum.MalicAcid,
    ingredientGroup: IngredientGroupEnum.Acid,
    allergen: false,
  },
  {
    name: OrderedAcidNameEnum.TarticAcid,
    ingredientGroup: IngredientGroupEnum.Acid,
    allergen: false,
  },
  {
    name: OrderedAcidNameEnum.CitricAcid,
    ingredientGroup: IngredientGroupEnum.Acid,
    allergen: false,
  },
  {
    name: OrderedAcidNameEnum.CalciumCarbonate,
    ingredientGroup: IngredientGroupEnum.Acid,
    allergen: false,
  },
  {
    name: OrderedAcidNameEnum.PotassiumHydrogenCarbonate,
    ingredientGroup: IngredientGroupEnum.Acid,
    allergen: false,
  },
  {
    name: OrderedAcidNameEnum.CalciumSulphate,
    ingredientGroup: IngredientGroupEnum.Acid,
    allergen: false,
  },
  {
    name: OrderedAntioxidantNameEnum.AscorbicAcid,
    ingredientGroup: IngredientGroupEnum.Antioxidant,
    allergen: false,
  },
  {
    name: OrderedAntioxidantNameEnum.ErythorbicAcid,
    ingredientGroup: IngredientGroupEnum.Antioxidant,
    allergen: false,
  },
  {
    name: OrderedGasNameEnum.CarbonDioxide,
    ingredientGroup: IngredientGroupEnum.Gas,
    allergen: false,
  },
  {
    name: OrderedGasNameEnum.Argon,
    ingredientGroup: IngredientGroupEnum.Gas,
    allergen: false,
  },
  {
    name: OrderedGasNameEnum.Nitrogen,
    ingredientGroup: IngredientGroupEnum.Gas,
    allergen: false,
  },
  {
    name: OrderedGasNameEnum.ProtectiveBottling,
    ingredientGroup: IngredientGroupEnum.Gas,
    allergen: false,
  },
  {
    name: OrderedPreservativeNameEnum.SulphurDioxide,
    ingredientGroup: IngredientGroupEnum.Preservative,
    allergen: true,
  },
  {
    name: OrderedPreservativeNameEnum.PotassiumBisulphite,
    ingredientGroup: IngredientGroupEnum.Preservative,
    allergen: true,
  },
  {
    name: OrderedPreservativeNameEnum.PotassiumHydrogenSulphite,
    ingredientGroup: IngredientGroupEnum.Preservative,
    allergen: true,
  },
  {
    name: OrderedPreservativeNameEnum.PotassiumMetabisulphite,
    ingredientGroup: IngredientGroupEnum.Preservative,
    allergen: true,
  },
  {
    name: OrderedPreservativeNameEnum.PotassiumSorbate,
    ingredientGroup: IngredientGroupEnum.Preservative,
    allergen: false,
  },
  {
    name: OrderedPreservativeNameEnum.EggLysozyme,
    ingredientGroup: IngredientGroupEnum.Preservative,
    allergen: true,
  },
  {
    name: OrderedPreservativeNameEnum.Dimethyldicarbonate,
    ingredientGroup: IngredientGroupEnum.Preservative,
    allergen: false,
  },
  {
    name: OrderedPreservativeNameEnum.Sulphites,
    ingredientGroup: IngredientGroupEnum.Preservative,
    allergen: true,
  },
  {
    name: OrderedPreservativeNameEnum.SorbicAcid,
    ingredientGroup: IngredientGroupEnum.Preservative,
    allergen: false,
  },
  {
    name: OrderedStabilizerNameEnum.MetatartaricAcid,
    ingredientGroup: IngredientGroupEnum.Stabilizer,
    allergen: false,
  },
  {
    name: OrderedStabilizerNameEnum.GumArabic,
    ingredientGroup: IngredientGroupEnum.Stabilizer,
    allergen: false,
  },
  {
    name: OrderedStabilizerNameEnum.YeastMannoproteins,
    ingredientGroup: IngredientGroupEnum.Stabilizer,
    allergen: false,
  },
  {
    name: OrderedStabilizerNameEnum.Carboxymethilcellulose,
    ingredientGroup: IngredientGroupEnum.Stabilizer,
    allergen: false,
  },
  {
    name: OrderedStabilizerNameEnum.PotassiumPolyaspartate,
    ingredientGroup: IngredientGroupEnum.Stabilizer,
    allergen: false,
  },
  {
    name: OrderedStabilizerNameEnum.FumaricAcid,
    ingredientGroup: IngredientGroupEnum.Stabilizer,
    allergen: false,
  },
  {
    name: OrderedStabilizerNameEnum.CitricAcidStabilizer,
    ingredientGroup: IngredientGroupEnum.Stabilizer,
    allergen: false,
  },
  {
    name: OrderedProcessingAidNameEnum.EggAlbumin,
    ingredientGroup: IngredientGroupEnum.ProcessingAid,
    allergen: true,
  },
  {
    name: OrderedProcessingAidNameEnum.MilkCasein,
    ingredientGroup: IngredientGroupEnum.ProcessingAid,
    allergen: true,
  },
  {
    name: OrderedProcessingAidNameEnum.AmmaniumBisulphite,
    ingredientGroup: IngredientGroupEnum.ProcessingAid,
    allergen: true,
  },
  {
    name: OrderedProcessingAidNameEnum.WheatProtein,
    ingredientGroup: IngredientGroupEnum.ProcessingAid,
    allergen: true,
  },
  {
    name: OrderedOtherPracticeNameEnum.Caramel,
    ingredientGroup: IngredientGroupEnum.OtherPractice,
    allergen: false,
  },
  {
    name: OrderedOtherPracticeNameEnum.AleppoPineResin,
    ingredientGroup: IngredientGroupEnum.OtherPractice,
    allergen: false,
  },
];

export const allergens = {
  sulphite: [
    OrderedPreservativeNameEnum.Sulphites,
    OrderedPreservativeNameEnum.SulphurDioxide,
    OrderedPreservativeNameEnum.PotassiumHydrogenSulphite,
    OrderedPreservativeNameEnum.PotassiumMetabisulphite,
    OrderedPreservativeNameEnum.PotassiumBisulphite,
    OrderedProcessingAidNameEnum.AmmaniumBisulphite,
  ] as string[],
  egg: [
    OrderedProcessingAidNameEnum.EggAlbumin,
    OrderedPreservativeNameEnum.EggLysozyme,
  ] as string[],
  milk: [OrderedProcessingAidNameEnum.MilkCasein] as string[],
};

export const defaultIngredientsDisplayedInGroup: string[] = [
  ...Object.values(OrderedAcidNameEnum),
  ...Object.values(OrderedAntioxidantNameEnum),
  ...Object.values(OrderedPreservativeNameEnum),
  ...Object.values(OrderedStabilizerNameEnum),
  OrderedGasNameEnum.Argon,
  OrderedGasNameEnum.CarbonDioxide,
  OrderedGasNameEnum.Nitrogen,
];

export const defaultIngredientGroups = [
  IngredientGroupEnum.RawMaterial,
  IngredientGroupEnum.EnrichmentSubstance,
  IngredientGroupEnum.Acid,
  IngredientGroupEnum.Antioxidant,
  IngredientGroupEnum.Preservative,
  IngredientGroupEnum.Stabilizer,
  IngredientGroupEnum.Gas,
  IngredientGroupEnum.ProcessingAid,
  IngredientGroupEnum.OtherPractice,
];

export const ingredientGroups = [
  ...defaultIngredientGroups,
  IngredientGroupEnum.Custom,
];

export const groupsWithCustomIngredients: IngredientGroupEnum[] = [
  IngredientGroupEnum.Acid,
  IngredientGroupEnum.Antioxidant,
  IngredientGroupEnum.Preservative,
  IngredientGroupEnum.Stabilizer,
  IngredientGroupEnum.Gas,
  IngredientGroupEnum.Custom,
];

export const ingredientGroupsWithAddContainsSwitch: IngredientGroupEnum[] = [
  IngredientGroupEnum.Acid,
];

export const ingredientsWithSubIngredients: (string | undefined)[] = [
  OrderedEnrichmentSubstanceNameEnum.GrapeMust,
  OrderedEnrichmentSubstanceNameEnum.Wine,
];
