import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';
import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

export type CheckboxWithControllerProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  checkboxProps?: CheckboxProps;
  labelProps?: Omit<FormControlLabelProps, 'control'>;
};

const CheckboxWithController = <ObjectType extends FieldValues>({
  control,
  name,
  checkboxProps,
  labelProps,
  ...otherProps
}: CheckboxWithControllerProps<ObjectType>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ...fieldProps } }) => (
        <FormControlLabel
          control={
            <Checkbox
              {...checkboxProps}
              {...fieldProps}
              {...otherProps}
              checked={!!fieldProps.value}
            />
          }
          label={labelProps?.label}
          {...labelProps}
        />
      )}
    />
  );
};

export default CheckboxWithController;
