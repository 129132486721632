export default [
  {
    value: 'AF',
    label: 'Afganistan',
  },
  {
    value: 'AL',
    label: 'Albanija',
  },
  {
    value: 'DZ',
    label: 'Alġerija',
  },
  {
    value: 'AS',
    label: 'Samoa Amerikana',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguilla',
  },
  {
    value: 'AG',
    label: 'Antigua u Barbuda',
  },
  {
    value: 'AR',
    label: 'Arġentina',
  },
  {
    value: 'AM',
    label: 'Armenja',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'L-Awstralja',
  },
  {
    value: 'AT',
    label: 'L-Awstrija',
  },
  {
    value: 'AZ',
    label: 'Ażerbajġan',
  },
  {
    value: 'BS',
    label: 'Baħamas',
  },
  {
    value: 'BH',
    label: 'Baħrejn',
  },
  {
    value: 'BD',
    label: 'Bangladexx',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Belarussja',
  },
  {
    value: 'BE',
    label: 'Belġju',
  },
  {
    value: 'BZ',
    label: 'Beliże',
  },
  {
    value: 'BJ',
    label: 'Benin',
  },
  {
    value: 'BM',
    label: 'Bermuda',
  },
  {
    value: 'BT',
    label: 'Bhutan',
  },
  {
    value: 'BO',
    label: 'Bolivja',
  },
  {
    value: 'BA',
    label: 'Il-Bosnja u Ħerzegovina',
  },
  {
    value: 'BW',
    label: 'Botswana',
  },
  {
    value: 'BR',
    label: 'Brażil',
  },
  {
    value: 'IO',
    label: 'Gżejjer Verġni Brittaniċi',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bulgarija',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Kambodja',
  },
  {
    value: 'CM',
    label: 'Kamerun',
  },
  {
    value: 'CA',
    label: 'Kanada',
  },
  {
    value: 'KY',
    label: 'Gżejjer Kajman',
  },
  {
    value: 'CF',
    label: 'Repubblika tal-Afrika Ċentrali',
  },
  {
    value: 'TD',
    label: 'Ċad',
  },
  {
    value: 'CL',
    label: 'Ċili',
  },
  {
    value: 'CN',
    label: 'Iċ-Ċina',
  },
  {
    value: 'CO',
    label: 'Kolombja',
  },
  {
    value: 'KM',
    label: 'Comoros',
  },
  {
    value: 'CG',
    label: 'Kongo',
  },
  {
    value: 'CK',
    label: 'Cook Islands',
  },
  {
    value: 'CR',
    label: 'Il-Kosta Rika',
  },
  {
    value: 'CI',
    label: "Côte d'Ivoire",
  },
  {
    value: 'HR',
    label: 'Kroazja',
  },
  {
    value: 'CU',
    label: 'Kuba',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Ċipru',
  },
  {
    value: 'CZ',
    label: 'Repubblika Ċeka',
  },
  {
    value: 'CZ',
    label: 'Repubblika Demokratika tal-Poplu tal-Korea',
  },
  {
    value: 'CD',
    label: 'Repubblika Demokratika tal-Kongo',
  },
  {
    value: 'DK',
    label: 'Id-Danimarka',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'DJibouti',
  },
  {
    value: 'DM',
    label: 'Dominica',
  },
  {
    value: 'DO',
    label: 'Repubblika Dominikana',
  },
  {
    value: 'EC',
    label: 'Ekwador',
  },
  {
    value: 'EG',
    label: 'Eġittu',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'GQ',
    label: 'Guinea Ekwatorjali',
  },
  {
    value: 'ER',
    label: 'Eritrea',
  },
  {
    value: 'EE',
    label: 'Estonja',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etjopja',
  },
  {
    value: 'FK',
    label: 'Gżejjer Falkland',
  },
  {
    value: 'FO',
    label: 'Gżejjer Faroe',
  },
  {
    value: 'FJ',
    label: 'Fiji',
  },
  {
    value: 'FI',
    label: 'Il-Finlandja',
  },
  {
    value: 'FR',
    label: 'Franza',
  },
  {
    value: 'FR',
    label: 'Dipartimenti u territorji Franċiżi fl-Oċean Indjan',
  },
  {
    value: 'GF',
    label: 'Guiana Franċiża',
  },
  {
    value: 'PF',
    label: 'Polineżja Franċiża',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambja',
  },
  {
    value: 'GE',
    label: 'Ġeorġja',
  },
  {
    value: 'DE',
    label: 'Ġermanja',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GI',
    label: 'Ġibiltà',
  },
  {
    value: 'GR',
    label: 'Il-Greċja',
  },
  {
    value: 'GL',
    label: 'Greenland',
  },
  {
    value: 'GD',
    label: 'Grenada',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Gwatemala',
  },
  {
    value: 'GG',
    label: 'Guinea',
  },
  {
    value: 'GN',
    label: 'Guinea-Bissau',
  },
  {
    value: 'GY',
    label: 'Guyana',
  },
  {
    value: 'HT',
    label: 'Ħaiti',
  },
  {
    value: 'HN',
    label: 'Ħonduras',
  },
  {
    value: 'HK',
    label: 'Ħong Kong',
  },
  {
    value: 'HU',
    label: 'L-Ungerija',
  },
  {
    value: 'IS',
    label: 'L-Islanda',
  },
  {
    value: 'IN',
    label: 'L-Indja',
  },
  {
    value: 'ID',
    label: 'Indoneżja',
  },
  {
    value: 'IR',
    label: 'Iran',
  },
  {
    value: 'IQ',
    label: 'Iraq',
  },
  {
    value: 'IE',
    label: 'L-Irlanda',
  },
  {
    value: 'IL',
    label: 'Iżrael',
  },
  {
    value: 'IT',
    label: 'L-Italja',
  },
  {
    value: 'JM',
    label: 'Ġamajka',
  },
  {
    value: 'JP',
    label: 'Ġappun',
  },
  {
    value: 'JO',
    label: 'Ġordan',
  },
  {
    value: 'KZ',
    label: 'Każakstan',
  },
  {
    value: 'KE',
    label: 'Kenja',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Korea',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuwajt',
  },
  {
    value: 'KG',
    label: 'Kirgiżistan',
  },
  {
    value: 'LA',
    label: 'Ir-Repubblika Demokratika tal-Poplu Lao',
  },
  {
    value: 'LV',
    label: 'Latvja',
  },
  {
    value: 'LB',
    label: 'Libanu',
  },
  {
    value: 'LS',
    label: 'Lesoto',
  },
  {
    value: 'LR',
    label: 'Liberja',
  },
  {
    value: 'LY',
    label: 'Libja',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
  },
  {
    value: 'LT',
    label: 'Litwanja',
  },
  {
    value: 'LU',
    label: 'Lussemburgu',
  },
  {
    value: 'MO',
    label: 'Makaw',
  },
  {
    value: 'MG',
    label: 'Madagaskar',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Malasja',
  },
  {
    value: 'MV',
    label: 'Maldivi',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Gżejjer Marshall',
  },
  {
    value: 'MQ',
    label: 'Martinique',
  },
  {
    value: 'MR',
    label: 'Mawritanja',
  },
  {
    value: 'MU',
    label: 'Mawrizju',
  },
  {
    value: 'MX',
    label: 'Messiku',
  },
  {
    value: 'FM',
    label: 'Mikroneżja',
  },
  {
    value: 'MD',
    label: 'Moldova',
  },
  {
    value: 'MC',
    label: 'Monako',
  },
  {
    value: 'MN',
    label: 'Mongolja',
  },
  {
    value: 'ME',
    label: 'Montenegro',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Marokk',
  },
  {
    value: 'MZ',
    label: 'Możambik',
  },
  {
    value: 'MM',
    label: 'Mjanmar',
  },
  {
    value: 'NA',
    label: 'Namibja',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepal',
  },
  {
    value: 'NL',
    label: 'Olanda',
  },
  {
    value: 'NC',
    label: 'New Caledonia',
  },
  {
    value: 'NZ',
    label: 'New Zealand',
  },
  {
    value: 'NI',
    label: 'In-Nikaragwa',
  },
  {
    value: 'NE',
    label: 'Niġer',
  },
  {
    value: 'NG',
    label: 'In-Niġerja',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Gżira Norfolk',
  },
  {
    value: 'NF',
    label: 'Maċedonja tat-Tramuntana',
  },
  {
    value: 'MP',
    label: 'Gżejjer Mariana tat-Tramuntana',
  },
  {
    value: 'NO',
    label: 'In-Norveġja',
  },
  {
    value: 'OM',
    label: 'Oman',
  },
  {
    value: 'PK',
    label: 'Pakistan',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papua New Guinea',
  },
  {
    value: 'PY',
    label: 'Paragwaj',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'PH',
    label: 'Filippini',
  },
  {
    value: 'PL',
    label: 'Polonja',
  },
  {
    value: 'PT',
    label: 'Il-Portugall',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'QA',
    label: 'Qatar',
  },
  {
    value: 'RO',
    label: 'Ir-Rumanija',
  },
  {
    value: 'RU',
    label: 'Federazzjoni Russa',
  },
  {
    value: 'RW',
    label: 'Rwanda',
  },
  {
    value: 'SH',
    label: 'Santa Helena, Ascension u Tristan da Cunha',
  },
  {
    value: 'KN',
    label: 'San Kitts u Nevis',
  },
  {
    value: 'LC',
    label: 'Santa Luċija',
  },
  {
    value: 'PM',
    label: 'Saint Pierre u Miquelon',
  },
  {
    value: 'VC',
    label: 'Saint Vincent u l-Grenadini',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao Tome u Principe',
  },
  {
    value: 'SA',
    label: 'L-Arabja Sawdita',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Serbja',
  },
  {
    value: 'SC',
    label: 'Seychelles',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Singapor',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Is-Slovakkja',
  },
  {
    value: 'SI',
    label: 'Slovenja',
  },
  {
    value: 'SB',
    label: 'Gżejjer Solomon',
  },
  {
    value: 'SO',
    label: 'Somalja',
  },
  {
    value: 'ZA',
    label: "Afrika t'Isfel",
  },
  {
    value: 'SS',
    label: "Sudan t'Isfel",
  },
  {
    value: 'ES',
    label: 'Spanja',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
  },
  {
    value: 'SD',
    label: 'Sudan',
  },
  {
    value: 'SR',
    label: 'Surinam',
  },
  {
    value: 'SE',
    label: 'L-Iżvezja',
  },
  {
    value: 'CH',
    label: 'L-Isvizzera',
  },
  {
    value: 'SY',
    label: 'Repubblika Għarbija Sirjana',
  },
  {
    value: 'TW',
    label: 'Tajwan',
  },
  {
    value: 'TJ',
    label: 'Taġikistan',
  },
  {
    value: 'TZ',
    label: 'Tanzanija',
  },
  {
    value: 'TH',
    label: 'Tajlandja',
  },
  {
    value: 'TL',
    label: 'Timor-Leste',
  },
  {
    value: 'TG',
    label: 'Repubblika tat-Togo',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinidad u Tobago',
  },
  {
    value: 'TN',
    label: 'Tuneżija',
  },
  {
    value: 'TR',
    label: 'Repubblika tat-Turkija',
  },
  {
    value: 'TM',
    label: 'Turkmenistan',
  },
  {
    value: 'TC',
    label: 'Torok u Caicos',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukrajna',
  },
  {
    value: 'AE',
    label: 'Emirati Għarab Magħquda',
  },
  {
    value: 'GB',
    label: 'Ir-Renju Unit',
  },
  {
    value: 'US',
    label: 'Stati Uniti',
  },
  {
    value: 'US',
    label: 'Gżejjer Verġni tal-Istati Uniti',
  },
  {
    value: 'UY',
    label: 'Urugwaj',
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezwela',
  },
  {
    value: 'VN',
    label: 'Vjetnam',
  },
  {
    value: 'WF',
    label: 'Wallis u Futuna',
  },
  {
    value: 'YE',
    label: 'Jemen',
  },
  {
    value: 'ZM',
    label: 'Żambja',
  },
  {
    value: 'ZM',
    label: 'Żimbabwe',
  },
];
