import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  CustomImageViewImageGroupEnum,
  UploadCustomImageResponse,
} from '../../api';
import customImagesApi from './custom-images-api';

interface UploadCustomImageValue {
  imageGroup: CustomImageViewImageGroupEnum;
  image: File;
}

const useUploadCustomImage = (): UseMutationResult<
  AxiosResponse<UploadCustomImageResponse>,
  unknown,
  UploadCustomImageValue,
  unknown
> => {
  return useMutation({
    mutationFn: (data: UploadCustomImageValue) =>
      customImagesApi.uploadCustomImage(data.imageGroup, data.image),
  });
};

export default useUploadCustomImage;
