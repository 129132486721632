import { BoxProps, useTheme } from '@mui/material';
import React from 'react';
import FlexBox from '../flexbox/flexbox';

interface CardHeaderProps extends BoxProps {
  isWarning?: boolean;
}

const CardHeader: React.FC<CardHeaderProps> = ({
  isWarning,
  children,
  ...props
}) => {
  const theme = useTheme();

  return (
    <FlexBox
      sx={{
        background: isWarning
          ? `linear-gradient(47deg, ${theme.palette.warning.main} 0%, \
            ${theme.palette.warning.light} 100%)`
          : 'linear-gradient(47deg, rgba(0, 78, 134, 0.80) 26.52%,\
                 rgba(0, 148, 255, 0.80) 100%)',
        justifyContent: 'space-between',
        ...props.sx,
      }}
    >
      {children}
    </FlexBox>
  );
};

export default CardHeader;
