import CardFooter from '../card/card-footer';
import FlexBox from '../flexbox/flexbox';
import { primary } from '../../constants/colors';
import { BoxProps, Card, Typography } from '@mui/material';
import React from 'react';
import FlexBoxColumn from '../flexbox/flex-box-column';

interface InfoBoxProps extends BoxProps {
  title?: string;
  label: string;
  icon?: React.ReactNode;
}

const InfoBox = ({ title, label, icon, ...boxProps }: InfoBoxProps) => {
  return (
    <Card variant="elevation" elevation={3}>
      <CardFooter>
        <FlexBox
          color={primary[700]}
          width="100%"
          alignItems="center"
          p="10px 20px"
          gap="10px"
          {...boxProps}
        >
          {icon}
          <FlexBoxColumn>
            <Typography fontWeight={600}>{title}</Typography>
            <Typography>{label}</Typography>
          </FlexBoxColumn>
        </FlexBox>
      </CardFooter>
    </Card>
  );
};

export default InfoBox;
