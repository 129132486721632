export const splitNutritionValue = (text = '') => {
  const hasInequalityOperator = ['<', '>'].includes(text[0]);

  return {
    inequalityOperator: hasInequalityOperator ? text[0] : '',
    numericPart: hasInequalityOperator ? text.slice(1) : text,
  };
};

export const getNumericValue = (text = '') => {
  const { numericPart } = splitNutritionValue(text);
  return Number(numericPart);
};

export const isLower = (aValue?: string, bValue?: string) => {
  const { numericPart: aNumericPart } = splitNutritionValue(aValue);
  const { numericPart: bNumericPart } = splitNutritionValue(bValue);
  return Number(aNumericPart) < Number(bNumericPart);
};

export const isLowerOrEqual = (aValue?: string, bValue?: string) => {
  const { numericPart: aNumericPart } = splitNutritionValue(aValue);
  const { numericPart: bNumericPart } = splitNutritionValue(bValue);
  return Number(aNumericPart) <= Number(bNumericPart);
};
