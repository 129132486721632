import { Card, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NotFoundIllustration from '../../assets/images/not-found-illustration.svg';
import FlexBoxColumn from '../../components/flexbox/flex-box-column';
import FlexBox from '../../components/flexbox/flexbox';

const NoResults: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FlexBox justifyContent="center">
      <Card
        sx={{
          p: 3,
          my: 3,
          borderRadius: '10px',
          boxShadow: 25,
        }}
      >
        <FlexBox gap={2}>
          <img height={48} src={NotFoundIllustration} />
          <FlexBoxColumn justifyContent="center">
            <Typography>{t('labels.no-results.ups')}</Typography>
            <Typography>{t('labels.no-results.message')}</Typography>
          </FlexBoxColumn>
        </FlexBox>
      </Card>
    </FlexBox>
  );
};

export default NoResults;
