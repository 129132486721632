import { Button } from '@mui/material';
import React from 'react';
import { FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CompanyRoleEnum } from '../../../api';
import BackButton from '../../../components/button/back-button';
import FlexBox from '../../../components/flexbox/flexbox';
import HelperTooltip from '../../../components/helper/helper-tooltip';
import InformationModal from '../../../components/modal/information-modal';
import { ROUTES } from '../../../constants/routes';
import useGetProfile from '../../../data/profile/use-get-profile';
import useModal from '../../../utils/modal/use-modal';
import { PremiumFeaturesFormValue } from './premium-features.types';

interface PremiumFeaturesFormHeaderProps {
  formState: FormState<PremiumFeaturesFormValue>;
}

const PremiumFeaturesFormHeader: React.FC<PremiumFeaturesFormHeaderProps> = ({
  formState,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, refetch: refetchProfile } = useGetProfile();
  const { openModal, closeModal, isModalOpen } = useModal();
  const hasPremiumFeatures = data?.premiumFeature;
  const navigateToSubscriptionManagement = () => {
    window.open(process.env.REACT_APP_STRIPE_MANAGEMENT_LINK);
  };
  const handleUpgradeClick = () => {
    refetchProfile().then((profile) => {
      const isFederationMember =
        profile.data?.role === CompanyRoleEnum.FederationMember;

      if (isFederationMember) {
        openModal();
      } else {
        navigateToSubscriptionManagement();
      }
    });
  };

  return (
    <FlexBox justifyContent="space-between" alignItems="center" mt={3} mx={2}>
      <BackButton onClick={() => navigate(ROUTES.SETTINGS)}>
        {t('settings.title')}
      </BackButton>
      {hasPremiumFeatures ? (
        <Button disabled={!formState.isDirty} variant="contained" type="submit">
          {t('common.save')}
        </Button>
      ) : (
        <FlexBox gap={2} alignItems="center">
          <Button variant="contained" onClick={handleUpgradeClick}>
            {t('premium-features.upgrade')}
          </Button>
          <HelperTooltip title={t('premium-features.upgrade-tooltip')} />
        </FlexBox>
      )}
      <InformationModal
        open={isModalOpen}
        onClose={closeModal}
        labels={{ message: t('premium-features.upgrade-modal-message') }}
      />
    </FlexBox>
  );
};

export default PremiumFeaturesFormHeader;
