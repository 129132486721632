import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CustomIngredient,
  Ingredient as IngredientDto,
  IngredientGroupOrderIngredientGroupEnum as IngredientGroupEnum,
} from '../api';
import { defaultIngredientList } from '../constants/ingredients';
import {
  Ingredient,
  IngredientGroup,
  SequencedIngredientOrGroup,
  IngredientsSequencesFlexibles,
} from '../models/ingredient';
import {
  getUpdatedIngredientsSequencesFlexibles,
  isIngredientDisplayedInGroup,
  sameIngredients,
  toIngredientsSequencesFlexibles,
} from '../utils/ingredient/ingredient-utils';
import { useIngredientReducer } from './ingredient-reducer';

interface IngredientContextType {
  ingredients: Ingredient[];
  setInitialIngredientsSequencesFlexibles: (ingredient?: IngredientDto) => void;
  setIngredientsSequencesFlexibles: (
    props: IngredientsSequencesFlexibles
  ) => void;
  selectDefaultIngredient: (ingredient: Ingredient) => void;
  unselectDefaultIngredient: (ingredient: Ingredient) => void;
  reorderSequencedIngredientsAndGroups: (
    startIndex: number,
    endIndex: number
  ) => void;
  addCustomIngredient: (name: string, group: IngredientGroupEnum) => void;
  removeCustomIngredient: (ingredient: Ingredient) => void;
  setCustomIngredient: (ingredient: Ingredient) => void;
  resetIngredients: () => void;
  toggleCustomIngredientAllergenStatus: (ingredient: CustomIngredient) => void;
  sequencedIngredientsAndGroups: SequencedIngredientOrGroup[];
  hasChange: boolean;
  setHasChange: (value: boolean) => void;
  updateFlexibleGroups: (group: IngredientGroupEnum, checked: boolean) => void;
  flexibleGroups: IngredientGroupEnum[];
  setMainIngredient: (mainIngredient: Ingredient) => void;
  setSubIngredients: (props: {
    mainIngredient: Ingredient;
    subIngredients?: IngredientsSequencesFlexibles;
  }) => void;
  setTempSubIngredients: (props: {
    mainIngredient: Ingredient;
    tempSubIngredients?: IngredientsSequencesFlexibles;
  }) => void;
  sequencedIngredientsAndGroupsInUpperContext: SequencedIngredientOrGroup[];
  flexibleGroupsInUpperContext: IngredientGroupEnum[];
  setShouldUpdateUpperContext: (value: boolean) => void;
}

const throwMissingFunctionError = () => {
  throw Error('missing function implementation in ingredients context');
};

const initialValue: IngredientContextType = {
  ingredients: [],
  setInitialIngredientsSequencesFlexibles: throwMissingFunctionError,
  setIngredientsSequencesFlexibles: throwMissingFunctionError,
  selectDefaultIngredient: throwMissingFunctionError,
  unselectDefaultIngredient: throwMissingFunctionError,
  reorderSequencedIngredientsAndGroups: throwMissingFunctionError,
  addCustomIngredient: throwMissingFunctionError,
  removeCustomIngredient: throwMissingFunctionError,
  setCustomIngredient: throwMissingFunctionError,
  resetIngredients: throwMissingFunctionError,
  toggleCustomIngredientAllergenStatus: throwMissingFunctionError,
  sequencedIngredientsAndGroups: [],
  hasChange: false,
  setHasChange: throwMissingFunctionError,
  updateFlexibleGroups: throwMissingFunctionError,
  flexibleGroups: [],
  setMainIngredient: throwMissingFunctionError,
  setSubIngredients: throwMissingFunctionError,
  setTempSubIngredients: throwMissingFunctionError,
  sequencedIngredientsAndGroupsInUpperContext: [],
  flexibleGroupsInUpperContext: [],
  setShouldUpdateUpperContext: throwMissingFunctionError,
};

export const IngredientContext = createContext(initialValue);

export const IngredientProvider: React.FC<{
  children?: ReactNode;
  isSubIngredientProvider?: boolean;
}> = ({ children, isSubIngredientProvider }) => {
  const { i18n } = useTranslation();
  const {
    setSubIngredients: setSubIngredientsInUpperContext,
    setTempSubIngredients: setTempSubIngredientsInUpperContext,
    sequencedIngredientsAndGroups: sequencedIngredientsAndGroupsInUpperContext,
    flexibleGroups: flexibleGroupsInUpperContext,
  } =
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useIngredientContext();
  const {
    state,
    setMainIngredient,
    setIngredients,
    setSequencedIngredientsAndGroups,
    setFlexibleGroups,
    setHasChange,
    setIngredientsSequencesFlexibles,
    setShouldUpdateUpperContext,
  } = useIngredientReducer();
  const {
    mainIngredient,
    ingredients,
    sequencedIngredientsAndGroups,
    flexibleGroups,
    hasChange,
    shouldUpdateUpperContext,
  } = state;

  const setInitialIngredientsSequencesFlexibles = (data?: IngredientDto) => {
    const initialIngredientsSequencesFlexibles =
      toIngredientsSequencesFlexibles(data);

    setIngredientsSequencesFlexibles(initialIngredientsSequencesFlexibles);
  };

  const addSequencedIngredient = (ingredient: Ingredient) => {
    setSequencedIngredientsAndGroups([
      ...sequencedIngredientsAndGroups,
      { ingredient, sequenceNumber: sequencedIngredientsAndGroups.length },
    ]);
    setHasChange(true);
  };

  const addToSequencedGroup = (ingredient: Ingredient) => {
    if (ingredient.ingredientGroup) {
      const isGroupAlreadyAdded = !!sequencedIngredientsAndGroups.find(
        (it) => it.group?.name === ingredient.ingredientGroup
      );
      const newValue = isGroupAlreadyAdded
        ? sequencedIngredientsAndGroups.map((it) =>
            it.group?.name === ingredient.ingredientGroup
              ? {
                  ...it,
                  group: {
                    ...it.group,
                    ingredients: [
                      ...(it.group as IngredientGroup).ingredients,
                      ingredient,
                    ],
                  },
                }
              : it
          )
        : [
            ...sequencedIngredientsAndGroups,
            {
              group: {
                name: ingredient.ingredientGroup,
                ingredients: [ingredient],
              },
              sequenceNumber: sequencedIngredientsAndGroups.length,
            },
          ];
      setSequencedIngredientsAndGroups(newValue);
      setHasChange(true);
    }
  };

  const addToSequencedIngredientsAndGroups = (ingredient: Ingredient) => {
    const isDisplayedInGroup = isIngredientDisplayedInGroup(ingredient);

    if (isDisplayedInGroup) {
      addToSequencedGroup(ingredient);
    } else {
      addSequencedIngredient(ingredient);
    }
  };

  const selectDefaultIngredient = (ingredient: Ingredient) => {
    const updatedIngredients: Ingredient[] = ingredients.map((it) => {
      if (sameIngredients(it, ingredient)) {
        return { ...it, isSelected: true };
      }
      return it;
    });

    setIngredients(updatedIngredients);
    addToSequencedIngredientsAndGroups(ingredient);
  };

  const removeSequencedIngredient = (ingredient: Ingredient) => {
    setSequencedIngredientsAndGroups(
      sequencedIngredientsAndGroups
        .filter(
          (it) =>
            typeof it.ingredient === 'undefined' ||
            !sameIngredients(it.ingredient, ingredient)
        )
        .map((it, index) => ({ ...it, sequenceNumber: index }))
    );
    setHasChange(true);
  };

  const removeFromSequencedGroup = (ingredient: Ingredient) => {
    if (ingredient.ingredientGroup) {
      const isMoreSelectedFromThisGroup =
        ingredients.filter(
          (it) =>
            it.ingredientGroup === ingredient.ingredientGroup && it.isSelected
        ).length > 1;
      if (isMoreSelectedFromThisGroup) {
        setSequencedIngredientsAndGroups(
          sequencedIngredientsAndGroups.map((it) =>
            it.group?.name === ingredient.ingredientGroup
              ? {
                  ...it,
                  group: {
                    ...it.group,
                    ingredients: (it.group?.ingredients || []).filter(
                      (item) => item.name !== ingredient.name
                    ),
                  },
                }
              : it
          )
        );
      } else {
        setSequencedIngredientsAndGroups(
          sequencedIngredientsAndGroups
            .filter((it) => it.group?.name !== ingredient.ingredientGroup)
            .map((it, index) => ({ ...it, sequenceNumber: index }))
        );
      }
      setHasChange(true);
    }
  };

  const removeFromSequencedIngredientsAndGroups = (ingredient: Ingredient) => {
    const isDisplayedInGroup = isIngredientDisplayedInGroup(ingredient);
    if (isDisplayedInGroup) {
      removeFromSequencedGroup(ingredient);
    } else {
      removeSequencedIngredient(ingredient);
    }
  };

  const changeSequencedIngredient = (ingredient: Ingredient) => {
    setSequencedIngredientsAndGroups(
      sequencedIngredientsAndGroups.map((it) =>
        it.ingredient && sameIngredients(it.ingredient, ingredient)
          ? { ...it, ingredient }
          : it
      )
    );
    setHasChange(true);
  };

  const changeSequencedGroup = (ingredient: Ingredient) => {
    if (ingredient.ingredientGroup) {
      setSequencedIngredientsAndGroups(
        sequencedIngredientsAndGroups.map((it) =>
          it.group?.name === ingredient.ingredientGroup
            ? {
                ...it,
                group: {
                  ...it.group,
                  ingredients: (it.group?.ingredients || []).map((item) =>
                    item.name === ingredient.name ? ingredient : item
                  ),
                },
              }
            : it
        )
      );
      setHasChange(true);
    }
  };

  const changeSequencedIngredientsAndGroups = (ingredient: Ingredient) => {
    const isDisplayedInGroup = isIngredientDisplayedInGroup(ingredient);
    if (isDisplayedInGroup) {
      changeSequencedGroup(ingredient);
    } else {
      changeSequencedIngredient(ingredient);
    }
  };

  const unselectDefaultIngredient = (ingredient: Ingredient) => {
    const updatedIngredients: Ingredient[] = ingredients.map((it) => {
      if (sameIngredients(it, ingredient)) {
        return {
          ...it,
          isSelected: false,
          subIngredients: {
            ingredients: defaultIngredientList,
            sequencedIngredientsAndGroups: [],
            flexibleGroups: [],
          },
        };
      }
      return it;
    });

    setIngredients(updatedIngredients);
    removeFromSequencedIngredientsAndGroups(ingredient);
  };

  const reorderSequencedIngredientsAndGroups = (
    startIndex: number,
    endIndex: number
  ) => {
    const newOrder = [...sequencedIngredientsAndGroups];
    const [moved] = newOrder.splice(startIndex, 1);
    newOrder.splice(endIndex, 0, moved);
    const newSequencedIngredientsAndGroups = newOrder.map((it, index) => ({
      ...it,
      sequenceNumber: index,
    }));
    setSequencedIngredientsAndGroups(newSequencedIngredientsAndGroups);
    setHasChange(true);
  };

  const addCustomIngredient = (
    name: string,
    ingredientGroup: IngredientGroupEnum
  ) => {
    const customIngredient: Ingredient = {
      name,
      allergen: false,
      languageCode: i18n.language,
      ingredientGroup,
      isCustom: true,
      isSelected: true,
    };
    setIngredients([...ingredients, customIngredient]);
    addToSequencedIngredientsAndGroups(customIngredient);
  };

  const removeCustomIngredient = (ingredient: Ingredient) => {
    setIngredients(
      ingredients.filter((it) => !sameIngredients(it, ingredient))
    );
    removeFromSequencedIngredientsAndGroups(ingredient);
  };

  const setCustomIngredient = (ingredient: Ingredient) => {
    setIngredients(
      ingredients.map((it) =>
        sameIngredients(it, ingredient) ? ingredient : it
      )
    );
    changeSequencedIngredientsAndGroups(ingredient);
  };

  const resetIngredients = () => {
    setIngredients(
      ingredients
        .filter((it) => !it.isCustom)
        .map((it) => ({ ...it, isSelected: false }))
    );
    setSequencedIngredientsAndGroups([]);
    setHasChange(true);
  };

  const toggleCustomIngredientAllergenStatus = (ingredient: Ingredient) => {
    setIngredients(
      ingredients.map((it) => {
        if (sameIngredients(it, ingredient))
          return {
            ...it,
            allergen: !ingredient.allergen,
          };
        return it;
      })
    );
    setSequencedIngredientsAndGroups(
      sequencedIngredientsAndGroups.map((it) => {
        if (it.ingredient && sameIngredients(it.ingredient, ingredient))
          return {
            ...it,
            ingredient: { ...it.ingredient, allergen: !ingredient.allergen },
          };
        if (
          typeof ingredient.ingredientGroup !== 'undefined' &&
          it.group?.name === ingredient.ingredientGroup
        )
          return {
            ...it,
            group: {
              ...it.group,
              ingredients: it.group.ingredients.map((ingredientInGroup) => ({
                ...ingredientInGroup,
                allergen: sameIngredients(ingredient, ingredientInGroup)
                  ? !ingredientInGroup.allergen
                  : ingredientInGroup.allergen,
              })),
            },
          };
        return it;
      })
    );
    setHasChange(true);
  };

  const updateFlexibleGroups = (
    group: IngredientGroupEnum,
    checked: boolean
  ) => {
    setFlexibleGroups(
      checked
        ? [...flexibleGroups, group]
        : flexibleGroups.filter((it) => it !== group)
    );
    setHasChange(true);
  };

  const setSubIngredients = (props: {
    mainIngredient: Ingredient;
    subIngredients?: IngredientsSequencesFlexibles;
  }) => {
    const { mainIngredient: mainIngredientProp, subIngredients } = props;
    if (isSubIngredientProvider) {
      setHasChange(false);
      setSubIngredientsInUpperContext(props);
    } else {
      const updatedIngredientsSequencesFlexibles =
        getUpdatedIngredientsSequencesFlexibles({
          mainIngredient: mainIngredientProp,
          version: 'subIngredients',
          value: subIngredients,
          ingredientState: state,
        });

      setIngredientsSequencesFlexibles(updatedIngredientsSequencesFlexibles);

      setHasChange(true);
    }
  };

  const setTempSubIngredients = (props: {
    mainIngredient: Ingredient;
    tempSubIngredients?: IngredientsSequencesFlexibles;
  }) => {
    const { mainIngredient: mainIngredientProp, tempSubIngredients } = props;
    if (isSubIngredientProvider) {
      setTempSubIngredientsInUpperContext(props);
    } else {
      const updatedIngredientsSequencesFlexibles =
        getUpdatedIngredientsSequencesFlexibles({
          mainIngredient: mainIngredientProp,
          version: 'tempSubIngredients',
          value: tempSubIngredients,
          ingredientState: state,
        });

      setIngredientsSequencesFlexibles(updatedIngredientsSequencesFlexibles);
    }
  };

  useEffect(() => {
    if (mainIngredient && shouldUpdateUpperContext) {
      setTempSubIngredients({
        mainIngredient,
        tempSubIngredients: {
          ingredients,
          sequencedIngredientsAndGroups,
          flexibleGroups,
        },
      });
    }
  }, [ingredients, sequencedIngredientsAndGroups, flexibleGroups]);

  const contextValue = {
    ingredients,
    setInitialIngredientsSequencesFlexibles,
    setIngredientsSequencesFlexibles,
    selectDefaultIngredient,
    unselectDefaultIngredient,
    reorderSequencedIngredientsAndGroups,
    addCustomIngredient,
    removeCustomIngredient,
    setCustomIngredient,
    resetIngredients,
    toggleCustomIngredientAllergenStatus,
    sequencedIngredientsAndGroups,
    hasChange,
    setHasChange,
    flexibleGroups,
    updateFlexibleGroups,
    setMainIngredient,
    setSubIngredients,
    setTempSubIngredients,
    sequencedIngredientsAndGroupsInUpperContext,
    flexibleGroupsInUpperContext,
    setShouldUpdateUpperContext,
  };

  return (
    <IngredientContext.Provider value={contextValue}>
      {children}
    </IngredientContext.Provider>
  );
};

export const useIngredientContext = () => {
  return useContext(IngredientContext);
};
