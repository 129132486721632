import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const HelperIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 268 268">
      <rect
        width="268"
        height="268"
        rx="134"
        fill="#0094FF"
        fillOpacity="0.3"
      />
      <g filter="url(#filter0_d_1418_54633)">
        <path
          d="M99.6234 132.108C89.4336 146.008 84.0574 144.502 71.556 135.338C59.0546 126.173 44.1619 112.82 54.3518 98.9204C64.5416 85.0204 69.6748 113.1 82.1762 122.264C94.6777 131.429 109.813 118.208 99.6234 132.108Z"
          fill="url(#paint0_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter1_d_1418_54633)">
        <circle
          cx="184"
          cy="91.2031"
          r="25"
          fill="url(#paint1_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter2_d_1418_54633)">
        <circle
          cx="145"
          cy="218.203"
          r="25"
          fill="url(#paint2_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter3_d_1418_54633)">
        <circle
          cx="129"
          cy="201.203"
          r="25"
          fill="url(#paint3_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter4_d_1418_54633)">
        <circle
          cx="199"
          cy="119.203"
          r="25"
          fill="url(#paint4_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter5_d_1418_54633)">
        <circle
          cx="154"
          cy="81.2031"
          r="25"
          fill="url(#paint5_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter6_d_1418_54633)">
        <circle
          cx="103"
          cy="73.2031"
          r="25"
          fill="url(#paint6_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter7_d_1418_54633)">
        <circle
          cx="107"
          cy="97.2031"
          r="25"
          fill="url(#paint7_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter8_d_1418_54633)">
        <circle
          cx="113"
          cy="170.203"
          r="25"
          fill="url(#paint8_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter9_d_1418_54633)">
        <circle
          cx="166"
          cy="189.203"
          r="25"
          fill="url(#paint9_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter10_d_1418_54633)">
        <circle
          cx="148"
          cy="177.203"
          r="25"
          fill="url(#paint10_linear_1418_54633)"
        />
      </g>
      <path
        d="M146.417 63.0813C145.459 62.0837 145.081 60.67 145.399 59.3245C148.898 44.5339 155.932 38.7247 147.213 29.1233C138.084 19.0701 171.588 29.7983 165.432 33.2299C159.275 36.6614 149.844 51.8282 153.259 63.3508C155.903 72.272 149.46 66.2535 146.417 63.0813Z"
        fill="url(#paint11_linear_1418_54633)"
      />
      <g filter="url(#filter11_d_1418_54633)">
        <path
          d="M167.824 157.144C181.986 147.321 186.579 150.496 195.413 163.233C204.247 175.97 213.977 193.446 199.815 203.269C185.653 213.091 189.947 184.871 181.113 172.134C172.279 159.397 153.662 166.966 167.824 157.144Z"
          fill="url(#paint12_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter12_d_1418_54633)">
        <circle
          cx="180"
          cy="150.203"
          r="25"
          fill="url(#paint13_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter13_d_1418_54633)">
        <circle
          cx="163"
          cy="122.203"
          r="25"
          fill="url(#paint14_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter14_d_1418_54633)">
        <circle
          cx="159"
          cy="155.203"
          r="25"
          fill="url(#paint15_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter15_d_1418_54633)">
        <circle
          cx="103"
          cy="131.203"
          r="25"
          fill="url(#paint16_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter16_d_1418_54633)">
        <circle
          cx="130"
          cy="78.2031"
          r="25"
          fill="url(#paint17_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter17_d_1418_54633)">
        <circle
          cx="130"
          cy="152.203"
          r="25"
          fill="url(#paint18_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter18_d_1418_54633)">
        <circle
          cx="134"
          cy="123"
          r="25"
          fill="url(#paint19_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter19_d_1418_54633)">
        <circle
          cx="180"
          cy="105.203"
          r="25"
          fill="url(#paint20_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter20_d_1418_54633)">
        <circle
          cx="121.5"
          cy="98.7031"
          r="17.5"
          fill="url(#paint21_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter21_d_1418_54633)">
        <ellipse
          cx="116.679"
          cy="98.8834"
          rx="10.679"
          ry="10.6803"
          fill="url(#paint22_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter22_d_1418_54633)">
        <circle
          cx="156.5"
          cy="102.703"
          r="17.5"
          fill="url(#paint23_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter23_d_1418_54633)">
        <ellipse
          cx="151.679"
          cy="103.883"
          rx="10.679"
          ry="10.6803"
          fill="url(#paint24_linear_1418_54633)"
        />
      </g>
      <g filter="url(#filter24_i_1418_54633)">
        <path
          d="M163.81 77.6537C165.467 75.7469 170.543 77.4455 175.146 81.4475C179.75 85.4495 182.138 90.2394 180.48 92.1462C178.823 94.0529 178.382 90.8437 173.779 86.8418C169.175 82.8398 162.152 79.5604 163.81 77.6537Z"
          fill="#711E4A"
        />
      </g>
      <g filter="url(#filter25_i_1418_54633)">
        <path
          d="M102.758 77.452C102.339 74.9604 106.981 72.1041 113.124 71.0724C119.268 70.0406 124.588 71.2241 125.006 73.7157C125.425 76.2074 122.616 74.5164 116.472 75.5481C110.328 76.5799 103.176 79.9437 102.758 77.452Z"
          fill="#711E4A"
        />
      </g>
      <g filter="url(#filter26_i_1418_54633)">
        <path
          d="M145.537 130.864C144.001 136.597 136.269 139.506 128.267 137.362C120.265 135.218 115.024 128.832 116.56 123.099C118.096 117.366 119.997 123.063 127.999 125.207C136.001 127.351 147.074 125.131 145.537 130.864Z"
          fill="#711E4A"
        />
      </g>
      <path
        d="M145.296 130.799C144.554 133.567 142.311 135.677 139.239 136.833C136.168 137.988 132.288 138.18 128.332 137.12C124.376 136.06 121.112 133.954 119.03 131.418C116.948 128.881 116.059 125.932 116.801 123.164C116.991 122.455 117.182 121.94 117.383 121.577C117.583 121.214 117.781 121.024 117.974 120.935C118.163 120.849 118.386 120.841 118.677 120.922C118.972 121.005 119.316 121.174 119.729 121.415C120.053 121.605 120.413 121.835 120.812 122.091C122.427 123.127 124.692 124.579 127.935 125.448C129.957 125.99 132.167 126.255 134.31 126.438C135.096 126.505 135.871 126.561 136.624 126.616C137.935 126.711 139.183 126.802 140.315 126.938C142.104 127.152 143.539 127.476 144.432 128.071C144.87 128.363 145.167 128.715 145.317 129.145C145.468 129.577 145.479 130.116 145.296 130.799Z"
        stroke="black"
        strokeWidth="0.5"
      />
      <g filter="url(#filter27_d_1418_54633)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.782 113.984C124.981 115.115 132.545 109.385 133.676 101.186C134.807 92.9863 129.077 85.4224 120.878 84.2915C112.678 83.1605 105.114 88.8905 103.983 97.09C102.852 105.289 108.582 112.853 116.782 113.984ZM135.618 111.095C131.309 117.135 123.874 120.636 116.014 119.552C104.765 118 96.8962 107.643 98.4056 96.3978C95.1946 92.0478 89.9511 91.5939 86.1091 92.4464C84.594 92.7826 83.0932 91.8269 82.7571 90.3118C82.4209 88.7967 83.3766 87.2959 84.8917 86.9597C89.0111 86.0457 95.3337 86.1145 100.253 90.2061C104.026 82.3534 112.551 77.4697 121.645 78.7241C129.506 79.8083 135.716 85.1925 138.229 92.1748C142.539 86.1349 149.974 82.634 157.833 83.7181C166.818 84.9575 173.647 91.8148 175.262 100.255C175.666 100.103 176.074 99.9661 176.48 99.844C178.919 99.1114 181.862 98.7301 184.963 98.7835C191.124 98.8895 198.401 100.728 203.871 105.607C205.029 106.64 205.13 108.416 204.097 109.574C203.064 110.732 201.288 110.834 200.13 109.801C195.959 106.081 190.136 104.493 184.866 104.403C182.252 104.358 179.898 104.685 178.098 105.226C176.73 105.637 175.92 106.098 175.496 106.428C175.477 106.601 175.455 106.774 175.431 106.948C173.876 118.222 163.476 126.101 152.202 124.546C144.341 123.461 138.131 118.077 135.618 111.095ZM152.97 118.978C161.169 120.109 168.733 114.379 169.864 106.18C170.995 97.9803 165.265 90.4165 157.065 89.2855C148.866 88.1545 141.302 93.8846 140.171 102.084C139.04 110.283 144.77 117.847 152.97 118.978Z"
          fill="#0094FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1418_54633"
          x="46.9668"
          y="95.126"
          width="60.0078"
          height="55.2588"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1418_54633"
          x="155"
          y="66.2031"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1418_54633"
          x="116"
          y="193.203"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_1418_54633"
          x="100"
          y="176.203"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_1418_54633"
          x="170"
          y="94.2031"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_1418_54633"
          x="125"
          y="56.2031"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_1418_54633"
          x="74"
          y="48.2031"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_1418_54633"
          x="78"
          y="72.2031"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d_1418_54633"
          x="84"
          y="145.203"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_d_1418_54633"
          x="137"
          y="164.203"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter10_d_1418_54633"
          x="119"
          y="152.203"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter11_d_1418_54633"
          x="158.678"
          y="151.375"
          width="52.3008"
          height="61.9297"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter12_d_1418_54633"
          x="151"
          y="125.203"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter13_d_1418_54633"
          x="134"
          y="97.2031"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter14_d_1418_54633"
          x="130"
          y="130.203"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter15_d_1418_54633"
          x="74"
          y="106.203"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter16_d_1418_54633"
          x="101"
          y="53.2031"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter17_d_1418_54633"
          x="101"
          y="127.203"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter18_d_1418_54633"
          x="105"
          y="98"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter19_d_1418_54633"
          x="151"
          y="80.2031"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter20_d_1418_54633"
          x="100"
          y="81.2031"
          width="43"
          height="43"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter21_d_1418_54633"
          x="103.902"
          y="88.2031"
          width="25.5543"
          height="25.5573"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.09845" />
          <feGaussianBlur stdDeviation="1.04923" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter22_d_1418_54633"
          x="135"
          y="85.2031"
          width="43"
          height="43"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter23_d_1418_54633"
          x="138.902"
          y="93.2031"
          width="25.5543"
          height="25.5573"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.09845" />
          <feGaussianBlur stdDeviation="1.04923" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <filter
          id="filter24_i_1418_54633"
          x="163.562"
          y="76.8711"
          width="17.4434"
          height="19.8398"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1418_54633"
          />
        </filter>
        <filter
          id="filter25_i_1418_54633"
          x="102.732"
          y="70.6992"
          width="22.3145"
          height="11.6523"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1418_54633"
          />
        </filter>
        <filter
          id="filter26_i_1418_54633"
          x="116.293"
          y="120.615"
          width="29.3887"
          height="21.4521"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1418_54633"
          />
        </filter>
        <filter
          id="filter27_d_1418_54633"
          x="78.9427"
          y="78.5283"
          width="129.615"
          height="53.7064"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.74673" />
          <feGaussianBlur stdDeviation="1.87337" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1418_54633"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1418_54633"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1418_54633"
          x1="95.4368"
          y1="90.3504"
          x2="58.5364"
          y2="140.686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1418_54633"
          x1="184"
          y1="66.2031"
          x2="184"
          y2="116.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1418_54633"
          x1="145"
          y1="193.203"
          x2="145"
          y2="243.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1418_54633"
          x1="129"
          y1="176.203"
          x2="129"
          y2="226.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1418_54633"
          x1="199"
          y1="94.2031"
          x2="199"
          y2="144.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1418_54633"
          x1="154"
          y1="56.2031"
          x2="154"
          y2="106.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1418_54633"
          x1="103"
          y1="48.2031"
          x2="103"
          y2="98.2031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1418_54633"
          x1="107"
          y1="72.2031"
          x2="107"
          y2="122.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1418_54633"
          x1="113"
          y1="145.203"
          x2="113"
          y2="195.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1418_54633"
          x1="166"
          y1="164.203"
          x2="166"
          y2="214.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1418_54633"
          x1="148"
          y1="152.203"
          x2="148"
          y2="202.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1418_54633"
          x1="137.511"
          y1="43.5913"
          x2="160.093"
          y2="48.9729"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#689F38" />
          <stop offset="1" stopColor="#663C00" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1418_54633"
          x1="158.18"
          y1="197.987"
          x2="209.464"
          y2="162.417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1418_54633"
          x1="180"
          y1="125.203"
          x2="180"
          y2="175.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1418_54633"
          x1="163"
          y1="97.2031"
          x2="163"
          y2="147.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1418_54633"
          x1="159"
          y1="130.203"
          x2="159"
          y2="180.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1418_54633"
          x1="103"
          y1="106.203"
          x2="103"
          y2="156.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1418_54633"
          x1="130"
          y1="53.2031"
          x2="130"
          y2="103.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1418_54633"
          x1="130"
          y1="127.203"
          x2="130"
          y2="177.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1418_54633"
          x1="134"
          y1="98"
          x2="134"
          y2="148"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1418_54633"
          x1="180"
          y1="80.2031"
          x2="180"
          y2="130.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06292" />
          <stop offset="1" stopColor="#AD1457" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1418_54633"
          x1="109"
          y1="87.2031"
          x2="132"
          y2="139.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#DEDEDE" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1418_54633"
          x1="109.051"
          y1="91.8649"
          x2="123.089"
          y2="123.599"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1418_54633"
          x1="144"
          y1="91.2031"
          x2="167"
          y2="143.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#DEDEDE" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1418_54633"
          x1="144.051"
          y1="96.8649"
          x2="158.089"
          y2="128.599"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default HelperIcon;
