import Prefixes from '../../assets/countries/prefixes';

export const getPrefixForCountryCode = (countryCode?: string) => {
  return Prefixes.find((country) => country.countryCode === countryCode)
    ?.prefix;
};

export const getCountryCodeForPrefix = (prefix?: string) => {
  return Prefixes.find((country) => country.prefix === prefix)?.countryCode;
};
