import LanguageIcon from '@mui/icons-material/Language';
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import React from 'react';

const Select: React.FC<TextFieldProps> = ({
  children,
  value,
  onChange,
  sx,
}) => {
  const theme = useTheme();

  return (
    <TextField
      select
      value={value}
      focused
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LanguageIcon />
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      sx={{
        '& > .MuiOutlinedInput-root': {
          color: theme.palette.common.white,
          fontSize: 14,
          '& > svg, .MuiInputAdornment-root': { color: 'inherit' },
          '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primaryVariations[500],
          },
        },
        ...sx,
      }}
    >
      {children}
    </TextField>
  );
};

export default Select;
