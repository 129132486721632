import MailIcon from '@mui/icons-material/Mail';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button, ButtonProps, Link } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceProductTierEnum, Product } from '../../api';
import { EMAIL } from '../../constants/emails';
import useStartSubscriptionPayment from '../../data/payment/use-start-subscription-payment';
import useProfileCountry from '../../data/profile/use-profile-country';

interface LicenceActionProps {
  product?: Product;
  isCustom?: boolean;
  withPersonalization?: boolean;
}

const LicenceCardAction: React.FC<LicenceActionProps> = ({
  product,
  isCustom,
  withPersonalization,
}) => {
  const { isGermanAccount } = useProfileCountry();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const redirectToStripe = (paymentLink?: string) => {
    if (paymentLink) window.location.replace(paymentLink);
  };
  const productTier = withPersonalization
    ? PriceProductTierEnum._2
    : PriceProductTierEnum._1;
  const priceId = Array.from(product?.prices || []).find(
    (it) => it.productTier === productTier
  )?.priceId;
  const initiateStripeLinkCreation = useStartSubscriptionPayment({
    variables: {
      productId: product?.productId,
      priceId,
      currency: product?.currency,
    },
    onSuccess: redirectToStripe,
  });
  const handlePurchaseClick = () => {
    setIsLoading(true);
    initiateStripeLinkCreation.mutate({});
    setIsLoading(false);
  };
  const ActionButton = (props: ButtonProps) => (
    <Button
      id={`${product?.productFamily}-purchase-button`}
      variant="contained"
      fullWidth
      sx={{ borderRadius: 0 }}
      {...props}
    />
  );

  return (
    <>
      {isCustom ? (
        <Link
          href={`mailto:${
            isGermanAccount ? EMAIL.GERMAN_CUSTOMER : EMAIL.CUSTOMER
          }`}
          target="_blank"
          rel="noreferrer"
          underline="none"
        >
          <ActionButton startIcon={<MailIcon />}>
            {t('common.contact-us')}
          </ActionButton>
        </Link>
      ) : (
        <ActionButton
          startIcon={<ShoppingCartIcon />}
          disabled={isLoading}
          onClick={handlePurchaseClick}
        >
          {t('licences.purchase')}
        </ActionButton>
      )}
    </>
  );
};

export default LicenceCardAction;
