import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  DialogProps,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  NutritionDeclaration,
  WineLabelViewWineColourEnum,
} from '../../../api';
import WineCategory, {
  WineCategoryType,
} from '../../../assets/wine/wine-category';
import CardHeader from '../../../components/card/card-header';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import Modal from '../../../components/modal/modal';
import SearchableSelect from '../../../components/select/searchable-select';
import SearchableSelectWithController from '../../../components/select/searchable-select-with-controller';
import { hideArrows } from '../../../components/text-field/number-input-without-arrows';
import TextFieldWithController from '../../../components/text-field/text-field-with-controller';
import {
  ALCOHOL_VOLUMEN_TO_KCAL,
  LITER_TO_100ML,
  SUGAR_GRAMS_PER_LITER_TO_KCAL,
} from '../../../constants/unit-convertion';
import { useSnackbar } from '../../../services/snackbar-context';
import { getNumericValue } from '../../../utils/label/nutrition';
import wineListSchema from './wine-list-schema';

export interface WineListFormValue {
  wineColour: WineLabelViewWineColourEnum;
  wineCategory?: WineCategoryType;
  alcoholByVolume: number;
  sugar?: number;
}

interface WineListModalProps extends Omit<DialogProps, 'onClose' | 'onSubmit'> {
  sugar?: number;
  onClose: () => void;
  onSubmit: (energy: number, nutritions: NutritionDeclaration) => void;
}

const WineListModal: React.FC<WineListModalProps> = ({
  onSubmit,
  ...props
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { onClose } = props;
  const { setSnackbar } = useSnackbar();

  const onSubmitSuccess = (data: WineListFormValue) => {
    const nutritions = data.wineCategory?.nutritions;
    if (nutritions) {
      const energyKcal = getNumericValue(nutritions.energyKcal);
      const sugar =
        typeof nutritions.sugar === 'undefined' &&
        typeof data.sugar === 'undefined'
          ? undefined
          : (
              (data.sugar || getNumericValue(nutritions.sugar)) / LITER_TO_100ML
            ).toString();
      onSubmit(
        energyKcal +
          data.alcoholByVolume * ALCOHOL_VOLUMEN_TO_KCAL +
          (data.sugar || 0) * SUGAR_GRAMS_PER_LITER_TO_KCAL,
        {
          ...nutritions,
          sugar,
        }
      );
      setSnackbar({ message: t('wine-list.success') });
    }
    onClose();
  };

  const { handleSubmit, control, formState, getValues, watch, setValue } =
    useForm<WineListFormValue>({
      mode: 'onSubmit',
      resolver: yupResolver(wineListSchema(t)),
    });
  const { isSubmitted, errors } = formState;

  const categoryDisabled = useMemo(
    () => !getValues('wineColour'),
    [watch('wineColour')]
  );

  const showSugarSelector = useMemo(
    () =>
      !getValues('wineCategory') || getValues('wineCategory')?.id === 'general',
    [watch('wineCategory')]
  );
  const disableSubmit = useMemo(
    () =>
      getValues('wineCategory') === undefined ||
      getValues('alcoholByVolume') === undefined ||
      (showSugarSelector && getValues('sugar') === undefined),
    [watch(), showSugarSelector]
  );

  useEffect(() => {
    setValue(
      'wineCategory',
      WineCategory.map((value) => ({
        ...value,
        key: value.id,
        value: value.id,
      })).find(
        (category) =>
          category.colour === getValues('wineColour') &&
          category.id === 'general'
      )
    );
  }, [watch('wineColour')]);

  useEffect(() => {
    setValue('sugar', props.sugar ? props.sugar * LITER_TO_100ML : undefined);
  }, [props.sugar]);

  return (
    <Modal
      closeButtonProps={{ sx: { color: palette.common.white } }}
      {...props}
    >
      <Card>
        <CardHeader sx={{ color: palette.common.white }}>
          <Box sx={{ mt: 6, ml: 6, mb: 2 }}>
            <Typography variant="h4">{t('wine-list.title')}</Typography>
            <Typography variant="body2">{t('wine-list.subtitle')}</Typography>
          </Box>
        </CardHeader>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit(onSubmitSuccess)(event);
            event.stopPropagation();
          }}
        >
          <FlexBoxColumn gap="15px" p={6}>
            <SearchableSelectWithController
              control={control}
              name="wineColour"
              textFieldProps={{
                label: t('edit-label.wine-colour'),
                error: isSubmitted && !!errors.wineColour,
                fullWidth: true,
              }}
              data={Object.values(WineLabelViewWineColourEnum)
                .filter((it) => it !== WineLabelViewWineColourEnum.Orange)
                .map((colour) => ({
                  value: colour,
                  label: t(`wine-colour.${colour}`),
                }))}
            />
            <Controller
              control={control}
              name="wineCategory"
              render={({ field }) => (
                <SearchableSelect
                  disabled={categoryDisabled}
                  textFieldProps={{
                    label: t('wine-list.wine-category'),
                    error: isSubmitted && !!errors.wineCategory,
                    fullWidth: true,
                  }}
                  optionMap={WineCategory.filter(
                    (value) => value.colour === getValues('wineColour')
                  ).map((category) => ({
                    key: t(`wine-category.${category.id}`),
                    value: category.id,
                  }))}
                  freeSolo={false}
                  value={
                    (getValues('wineColour') &&
                      field.value && {
                        key: t(`wine-category.${field.value.id}`),
                        value: field.value.id,
                      }) ||
                    null
                  }
                  onSelectChange={(value) => {
                    return field.onChange(
                      WineCategory.find(
                        (category) =>
                          category.id === value &&
                          category.colour === getValues('wineColour')
                      )
                    );
                  }}
                />
              )}
            />
            <TextFieldWithController
              control={control}
              name="alcoholByVolume"
              undefinedEmpty
              textFieldProps={{
                type: 'number',
                sx: hideArrows,
                label: t('edit-label.alcoholic-volumen'),
                error: isSubmitted && !!errors.alcoholByVolume,
                helperText:
                  isSubmitted &&
                  !!errors.alcoholByVolume &&
                  errors.alcoholByVolume.message,
                fullWidth: true,
                InputProps: { endAdornment: '%' },
              }}
            />
            {showSugarSelector && (
              <TextFieldWithController
                control={control}
                name="sugar"
                undefinedEmpty
                textFieldProps={{
                  label: t('wine-list.sugar'),
                  error: isSubmitted && !!errors.sugar,
                  type: 'number',
                  sx: hideArrows,
                  helperText:
                    isSubmitted && !!errors.sugar && errors.sugar.message,
                  fullWidth: true,
                  InputProps: { endAdornment: t('common.unit.gramm') },
                }}
              />
            )}
            <Button variant="contained" type="submit" disabled={disableSubmit}>
              {t('energy-calculator.submit')}
            </Button>
          </FlexBoxColumn>
        </form>
      </Card>
    </Modal>
  );
};

export default WineListModal;
