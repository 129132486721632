import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FlexBoxColumn from '../../components/flexbox/flex-box-column';
import FlexBox from '../../components/flexbox/flexbox';
import useBreakpoints from '../../utils/responsive/use-breakpoints';

const Promoter: React.FC = () => {
  const { t } = useTranslation();
  const { xlScreen } = useBreakpoints();

  return (
    <FlexBox
      sx={{
        position: 'absolute',
        right: 0,
        height: '100%',
        width: 'calc((100vw - 50px) / 2)',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        pl: 10,
        pr: 1.25,
      }}
    >
      <FlexBoxColumn gap={xlScreen ? 3 : 2} pr={4}>
        {Array.from(Array(5).keys()).map((index) => (
          <FlexBox
            alignItems="center"
            key={index}
            gap={3}
            sx={{ fontSize: '48px' }}
          >
            <CheckIcon fontSize="inherit" color="success" />
            <Typography variant={xlScreen ? 'h4' : 'h5'}>
              {t(`login.promoting.message-${index + 1}`)}
            </Typography>
          </FlexBox>
        ))}
      </FlexBoxColumn>
    </FlexBox>
  );
};

export default Promoter;
