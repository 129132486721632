import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { WINE_LABELS_KEY } from './use-search-labels';
import wineLabelApi from './wine-label-api';

interface DeleteLabelsVariables {
  ids: number[];
  onSuccess: () => void;
}

const useDeleteLabels = (): UseMutationResult<
  AxiosResponse,
  unknown,
  DeleteLabelsVariables,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: DeleteLabelsVariables) =>
      wineLabelApi.deleteLabels({ ids: variables.ids }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([WINE_LABELS_KEY]);
      variables.onSuccess();
    },
  });
};

export default useDeleteLabels;
