import { Lens } from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WineLabelView } from '../../api';
import { wine } from '../../constants/colors';
import FlexBox from '../flexbox/flexbox';

interface ProductMainDetailsProps {
  product: WineLabelView;
}

const ProductMainDetails: React.FC<ProductMainDetailsProps> = ({ product }) => {
  const { t } = useTranslation();

  return (
    <>
      {product.grapeVariety && (
        <Typography fontFamily="inherit">{product.grapeVariety}</Typography>
      )}
      <FlexBox gap="10px">
        {product.wineColour && (
          <FlexBox color={wine[product.wineColour]} gap={1}>
            <Lens />
            <Typography color="black" fontFamily="inherit">
              {t(`wine-colour.${product.wineColour}`)}
            </Typography>
          </FlexBox>
        )}
        {product.wineColour && product.sweetness && (
          <Divider orientation="vertical" flexItem />
        )}
        {product.sweetness && (
          <Typography fontFamily="inherit">
            {t(`wine-sweetness.${product.sweetness}`)}
          </Typography>
        )}
      </FlexBox>
      <FlexBox gap="10px">
        {!!product.netQuantity && (
          <Typography fontFamily="inherit">{product.netQuantity} ml</Typography>
        )}
        {!!product.netQuantity && !!product.alcoholByVolume && (
          <Divider orientation="vertical" flexItem />
        )}
        {!!product.alcoholByVolume && (
          <Typography fontFamily="inherit">
            {product.alcoholByVolume}% {t('common.alcohol')}
          </Typography>
        )}
      </FlexBox>
    </>
  );
};

export default ProductMainDetails;
