import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Location,
  unstable_useBlocker as useBlocker,
  useNavigate,
} from 'react-router-dom';
import useModal from '../../utils/modal/use-modal';
import ConfirmationModal from '../modal/confirmation-modal';

interface NavigationGuardProps {
  shouldBlockNavigation: boolean;
  beforeNavigation: (
    continueNavigation: () => void,
    closeNavigationGuardModal?: () => void
  ) => void;
}

const NavigationGuard: React.FC<NavigationGuardProps> = ({
  shouldBlockNavigation,
  beforeNavigation,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openModal, closeModal, isModalOpen } = useModal();
  const [targetLocation, setTargetLocation] = useState<Location>();
  const continueNavigation = () => {
    if (targetLocation) navigate(targetLocation);
  };

  useBlocker(({ nextLocation }) => {
    if (!isModalOpen && shouldBlockNavigation) {
      setTargetLocation(nextLocation);
      openModal();
      return true;
    }
    return false;
  });

  return (
    <ConfirmationModal
      open={isModalOpen}
      onConfirm={() => {
        beforeNavigation(continueNavigation, closeModal);
      }}
      onCancel={continueNavigation}
      variant="warning"
      hideOnChoice={false}
      onClose={closeModal}
      labels={{
        message: t('common.save-message'),
        cancel: t('common.dont-save'),
        confirm: t('common.save'),
      }}
    />
  );
};

export default NavigationGuard;
