import {
  WineLabelCustomPictogramViewPictogramGroupEnum as CustomPictogramGroup,
  WineLabelCustomPictogramView,
} from '../../api';
import useUploadCustomPictogram from '../../data/pictograms/use-upload-custom-pictogram';
import {
  TempPictograms,
  usePictogramContext,
} from '../../services/pictogram-context';

const useUploadPictograms = () => {
  const { tempPictograms, emptyTempPictograms } = usePictogramContext();
  const uploadCustomPictogram = useUploadCustomPictogram();

  const uploadPictograms = async () => {
    let uploadedPictograms: WineLabelCustomPictogramView[] = [];
    await Promise.all(
      Object.values(CustomPictogramGroup)
        .map((group) => {
          return tempPictograms[group as keyof TempPictograms].map(
            async (pictogram) => {
              await uploadCustomPictogram
                .mutateAsync({
                  pictogramGroup: group,
                  image: pictogram.file,
                })
                .then(({ data }) => {
                  uploadedPictograms = [
                    ...uploadedPictograms,
                    {
                      originalFileName: pictogram.file.name,
                      id: data.pictogramId,
                      isSelected: pictogram.checked,
                      pictogramGroup: group,
                    },
                  ];
                });
            }
          );
        })
        .flat()
    ).then(emptyTempPictograms);

    return uploadedPictograms;
  };

  return { uploadPictograms };
};

export default useUploadPictograms;
