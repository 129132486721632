import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  WineLabelCustomPictogramViewPictogramGroupEnum as CustomPictogramGroup,
  PictogramsDtoResponsibleConsumptionEnum,
  PictogramsDtoSustainabilityEnum,
} from '../../../api';
import CustomPictograms from '../../../components/pictogram/custom-pictograms';
import PictogramItem from '../../../components/pictogram/pictogram-item';
import PictogramUploader from '../../../components/pictogram/pictogram-uploader';
import TempPictograms from '../../../components/pictogram/temp-pictograms';
import { DEFAULT_PICTOGRAMS } from '../../../constants/pictograms';
import { EditLabelSplitFormProps } from '../../../models/label';
import { Pictogram, PictogramId } from '../../../models/pictogram';

interface PictogramGroupProps extends EditLabelSplitFormProps {
  group: CustomPictogramGroup;
}

const PictogramGroup: React.FC<PictogramGroupProps> = ({ control, group }) => {
  const { t } = useTranslation();

  const getGridProps = (pictogram: Pictogram) => {
    switch (pictogram.id) {
      case PictogramsDtoSustainabilityEnum.TrimanExample:
        return { xs: 12 };
      case PictogramsDtoResponsibleConsumptionEnum.WineInModeration:
        return { xs: 8 };
      default:
        return undefined;
    }
  };
  const imgProps = (pictogram: Pictogram) =>
    (
      [
        PictogramsDtoSustainabilityEnum.TrimanExample,
        PictogramsDtoResponsibleConsumptionEnum.WineInModeration,
      ] as PictogramId[]
    ).includes(pictogram.id)
      ? { width: undefined }
      : undefined;
  const handleChange = (
    fieldValue: PictogramId[],
    changedValue: PictogramId,
    callback: (value: PictogramId[]) => void
  ) => {
    callback(
      fieldValue?.includes(changedValue)
        ? fieldValue.filter((id) => id !== changedValue)
        : [...(fieldValue || []), changedValue]
    );
  };

  return (
    <>
      <Typography py={2}>
        {t(
          `pictograms.${
            group === CustomPictogramGroup.ResponsibleCons
              ? 'responsible-consumption'
              : group.toLowerCase()
          }-title`
        )}
      </Typography>
      <Controller
        control={control}
        name={`pictograms.${
          group === CustomPictogramGroup.ResponsibleCons
            ? 'responsibleConsumption'
            : 'sustainability'
        }`}
        render={({
          field: { value, onChange },
        }: {
          field: {
            value: PictogramId[];
            onChange: (value: PictogramId[]) => void;
          };
        }) => (
          <Grid container spacing={2}>
            {DEFAULT_PICTOGRAMS[group].map((pictogram) => (
              <PictogramItem
                key={pictogram.id}
                checked={!!value?.includes(pictogram.id)}
                value={pictogram.id}
                onChange={(newValue) => handleChange(value, newValue, onChange)}
                imageSrc={pictogram.src}
                label={t(pictogram.name)}
                gridProps={getGridProps(pictogram)}
                imgProps={imgProps(pictogram)}
                tooltipProps={{
                  title:
                    pictogram.id ===
                    PictogramsDtoSustainabilityEnum.TrimanExample
                      ? t('pictograms.triman-tooltip')
                      : undefined,
                }}
              />
            ))}
            <CustomPictograms group={group} />
            <TempPictograms group={group} />
            <PictogramUploader group={group} />
          </Grid>
        )}
      />
    </>
  );
};

export default PictogramGroup;
