import { Card, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/modal/modal';
import ResetPasswordCardContent from '../../components/reset-password/reset-password-card-content';
import { ResetPasswordFormValue } from '../../components/reset-password/reset-password-form';
import { useAuthContext } from '../../services/auth-context';
import useModal from '../../utils/modal/use-modal';
import SettingsItem from './settings-item';

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { changePassword } = useAuthContext();
  const { openModal, isModalOpen, closeModal } = useModal();
  const [isCurrentPasswordInvalid, setIsCurrentPasswordInvalid] =
    useState(false);
  const resetPassword = (data: ResetPasswordFormValue) => {
    setIsCurrentPasswordInvalid(false);
    if (data.currentPassword)
      changePassword(data.currentPassword, data.password)
        .then(closeModal)
        .catch(() => {
          setIsCurrentPasswordInvalid(true);
        });
  };

  return (
    <>
      <SettingsItem onClick={openModal}>
        {t('settings.change-password')}
      </SettingsItem>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        closeButtonProps={{ sx: { color: palette.common.white } }}
      >
        <Card>
          <ResetPasswordCardContent
            onSubmitSuccess={resetPassword}
            withCurrentPassword
            isCurrentPasswordInvalid={isCurrentPasswordInvalid}
          />
        </Card>
      </Modal>
    </>
  );
};

export default ChangePassword;
