import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { SignupFormValue, SignupSplitFormProps } from '../../models/signup';
import {
  companyBlockFields,
  useSignupFormUtils,
} from '../../screens/signup/signup-form-utils';
import CountrySelectWithController from '../country-select/country-select-with-controller';
import PrefixSelectWithController from '../prefix-select/prefix-select-with-controller';
import TextFieldWithController from '../text-field/text-field-with-controller';

const CompanyForm: React.FC<SignupSplitFormProps> = ({
  control,
  formState,
  watch,
  trigger,
}) => {
  const { getTextFieldProps } = useSignupFormUtils(formState);
  const prefix = watch('prefix');
  const companyIdCountry = watch('companyIdCountry');

  useEffect(() => {
    if (formState.isSubmitted) trigger('companyId');
  }, [companyIdCountry]);

  useEffect(() => {
    if (formState.isSubmitted) trigger('phoneNumber');
  }, [prefix]);

  const getFieldWidth = (field: keyof SignupFormValue) => {
    const halfSizedFields = [
      'phoneNumber',
      'prefix',
      'companyIdCountry',
      'companyId',
    ];

    return halfSizedFields.includes(field) ? 6 : 12;
  };

  return (
    <Grid container spacing={2} sx={{ my: 0.5 }}>
      {companyBlockFields.map((field) => (
        <Grid item xs={getFieldWidth(field)} key={field}>
          {field === 'prefix' && (
            <PrefixSelectWithController
              control={control}
              name="prefix"
              textFieldProps={getTextFieldProps('prefix')}
            />
          )}
          {field === 'companyIdCountry' && (
            <CountrySelectWithController
              textFieldProps={getTextFieldProps('companyIdCountry')}
              control={control}
              name="companyIdCountry"
            />
          )}
          {field !== 'prefix' && field !== 'companyIdCountry' && (
            <TextFieldWithController
              control={control}
              name={field}
              textFieldProps={getTextFieldProps(field)}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default CompanyForm;
