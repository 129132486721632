import useDeleteCustomImage from '../../../data/custom-images/use-delete-custom-image';

const useDeleteLogos = () => {
  const deleteCustomImage = useDeleteCustomImage();
  const deleteLogos = async (ids: number[]) => {
    return await Promise.all(
      ids.map(async (id) => {
        await deleteCustomImage.mutateAsync(id);
      })
    );
  };
  return { deleteLogos };
};

export default useDeleteLogos;
