import { CustomImageView } from '../../../api';
import useUploadCustomImage from '../../../data/custom-images/use-upload-custom-image';
import { getImageGroup } from './premium-features-utils';
import { Logos } from './premium-features.types';

const useUploadLogos = () => {
  const uploadCustomImage = useUploadCustomImage();
  const uploadLogos = async (logos?: Logos) => {
    const newLogos = [logos?.header, logos?.footer].filter(
      (logo) =>
        typeof logo !== 'undefined' &&
        typeof logo?.id === 'undefined' &&
        typeof logo?.file !== 'undefined'
    );
    let uploadedLogos: CustomImageView[] = [];
    await Promise.all(
      newLogos.map(async (logo) => {
        const imageGroup = getImageGroup(logo);
        await uploadCustomImage
          .mutateAsync({
            imageGroup,
            image: logo?.file as File,
          })
          .then(({ data }) => {
            uploadedLogos = [
              ...uploadedLogos,
              {
                originalFileName: logo?.file?.name,
                id: data.customImageId,
                imageGroup,
              },
            ];
          });
      })
    );
    return uploadedLogos;
  };
  return { uploadLogos };
};

export default useUploadLogos;
