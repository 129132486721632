import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import useUserStatus, { UserStatus } from '../../utils/user/use-user-status';
import Loading from '../loading/loading';

interface RequireAuthProps {
  verifiedEmailRequired?: boolean;
  element: React.ReactElement;
}

const RequireAuth: React.FC<RequireAuthProps> = ({
  verifiedEmailRequired = true,
  element,
}) => {
  const userStatus = useUserStatus(verifiedEmailRequired);

  const resolve = (): ReactNode => {
    switch (userStatus) {
      case UserStatus.OK:
        return element;
      case UserStatus.NOT_VERIFIED:
        return <Navigate to={ROUTES.VERIFY_EMAIL} />;
      case UserStatus.NOT_LOGGED_IN:
        return <Navigate to={ROUTES.LOGIN} />;
      default:
        return <Loading />;
    }
  };

  return <>{resolve()}</>;
};

export default RequireAuth;
