import React from 'react';
import Modal from './modal';
import ConfirmationModal from './confirmation-modal';
import { useTranslation } from 'react-i18next';
import { DialogProps } from '@mui/material';
import useModal from '../../utils/modal/use-modal';

interface ModalWithConfirmationProps extends DialogProps {
  requiresConfirmation?: boolean;
  onConfirmation?: () => void;
  onCancel?: () => void;
}

const ModalWithConfirmation: React.FC<ModalWithConfirmationProps> = ({
  requiresConfirmation,
  onConfirmation,
  onCancel,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    openModal: openConfirmationModal,
    closeModal: closeConfirmationModal,
    isModalOpen: isConfirmationModalOpen,
  } = useModal();
  const closeModal = () => {
    if (props.onClose) props.onClose({}, 'escapeKeyDown');
  };
  const handleCloseAttempt = (
    event: object,
    reason: 'backdropClick' | 'escapeKeyDown',
    trigger?: 'closeButton' | 'doneButton'
  ) => {
    if (reason === 'backdropClick') return;
    if (trigger === 'closeButton' && requiresConfirmation) {
      openConfirmationModal();
    } else {
      closeModal();
    }
  };
  const handleConfirmation = (withConfirmation?: boolean) => {
    if (withConfirmation && onConfirmation) onConfirmation();
    if (!withConfirmation && onCancel) onCancel();
    closeConfirmationModal();
  };

  return (
    <Modal
      disableEscapeKeyDown
      closeButtonProps={{
        onClick: (event) =>
          handleCloseAttempt(event, 'escapeKeyDown', 'closeButton'),
        sx: { color: '#FFF' },
      }}
      scroll="paper"
      {...props}
      onClose={handleCloseAttempt}
    >
      {children}
      <ConfirmationModal
        open={isConfirmationModalOpen}
        onConfirm={() => handleConfirmation(true)}
        onCancel={handleConfirmation}
        variant="warning"
        hideOnChoice={false}
        onClose={closeConfirmationModal}
        labels={{
          message: t('common.save-message'),
          cancel: t('common.dont-save'),
          confirm: t('common.save'),
        }}
      />
    </Modal>
  );
};

export default ModalWithConfirmation;
