import CancelIcon from '@mui/icons-material/Cancel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FlexBox from '../../components/flexbox/flexbox';
import PasswordFieldWithController from '../../components/password-field/password-field-with-controller';
import TextFieldWithController from '../../components/text-field/text-field-with-controller';
import { ROUTES } from '../../constants/routes';
import { useAuthContext } from '../../services/auth-context';

export interface LoginFormValue {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const [isError, setIsError] = useState(false);
  const defaultValues = {
    email: '',
    password: '',
  };
  const { handleSubmit, control } = useForm<LoginFormValue>({
    mode: 'onSubmit',
    defaultValues,
  });
  const onSuccess = async (data: LoginFormValue) => {
    try {
      setIsLoginInProgress(true);
      await login(data.email, data.password);
      navigate(ROUTES.LABELS);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoginInProgress(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSuccess)}>
      <Grid container spacing={2} sx={{ my: 0.5 }}>
        <Grid item xs={12}>
          <TextFieldWithController
            control={control}
            name="email"
            textFieldProps={{
              label: t('auth.email'),
              error: isError,
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordFieldWithController
            control={control}
            name="password"
            textFieldProps={{
              label: t('auth.password'),
              error: isError,
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: isError ? 'flex' : 'none',
          }}
        >
          <FlexBox
            sx={{
              p: 1.5,
              alignItems: 'center',
              color: theme.palette.error.main,
              backgroundColor: theme.palette.grey[100],
            }}
          >
            <CancelIcon sx={{ mr: 1 }} />
            <Typography sx={{ fontSize: '12px' }}>
              {t('login.error')}
            </Typography>
          </FlexBox>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            endIcon={<ChevronRightIcon />}
            type="submit"
            disabled={isLoginInProgress}
          >
            {t('login.log-in')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
