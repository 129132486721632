import { TaxIdTypesByCountry } from '../../models/tax-id';

const whatever = /.*/;
const notFormatCheckedTaxIdTypes = [
  { countryCode: 'AD', name: 'ad_nrt' },
  { countryCode: 'AR', name: 'ar_cuit' },
  { countryCode: 'BO', name: 'bo_tin' },
  { countryCode: 'CN', name: 'cn_tin' },
  { countryCode: 'CO', name: 'co_nit' },
  { countryCode: 'CR', name: 'cr_tin' },
  { countryCode: 'DO', name: 'do_rcn' },
  { countryCode: 'EC', name: 'ec_ruc' },
  { countryCode: 'EG', name: 'eg_tin' },
  { countryCode: 'SV', name: 'sv_nit' },
  { countryCode: 'GE', name: 'ge_vat' },
  { countryCode: 'IS', name: 'is_vat' },
  { countryCode: 'KE', name: 'ke_pin' },
  { countryCode: 'PE', name: 'pe_ruc' },
  { countryCode: 'PH', name: 'ph_tin' },
  { countryCode: 'SG', name: 'sg_gst' },
  { countryCode: 'TR', name: 'tr_tin' },
  { countryCode: 'UA', name: 'ua_vat' },
  { countryCode: 'UY', name: 'uy_ruc' },
  { countryCode: 'VE', name: 've_rif' },
  { countryCode: 'VN', name: 'vn_tin' },
];

export const supportedTaxIdTypesByCountries: TaxIdTypesByCountry[] = [
  ...notFormatCheckedTaxIdTypes.map((it) => ({
    countryCode: it.countryCode,
    taxIdTypes: [
      {
        regex: whatever,
        name: it.name,
      },
    ],
  })),
  {
    countryCode: 'AU',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{11}$/),
        name: 'au_abn',
      },
      {
        regex: new RegExp(/^[0-9]{12}$/),
        name: 'au_arn',
      },
    ],
  },
  {
    countryCode: 'AT',
    taxIdTypes: [
      {
        regex: new RegExp(/^ATU[0-9]{8}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'BE',
    taxIdTypes: [
      {
        regex: new RegExp(/^(BE)(0\d{9})$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'BR',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2}$/),
        name: 'br_cnpj',
      },
      {
        regex: new RegExp(/^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/),
        name: 'br_cpf',
      },
    ],
  },
  {
    countryCode: 'BG',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{9,10}$/),
        name: 'bg_uic',
      },
      {
        regex: new RegExp(/^(BG)(\d{9,10})$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'CA',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{9}$/),
        name: 'ca_bn',
      },
      {
        regex: new RegExp(/^[0-9]{9}RT[0-9]{4}$/),
        name: 'ca_gst_hst',
      },
      {
        regex: new RegExp(/^PST-[0-9]{4}-[0-9]{4}$/),
        name: 'ca_pst_bc',
      },
      {
        regex: new RegExp(/^[0-9]{6}-[0-9]{1}$/),
        name: 'ca_pst_mb',
      },
      {
        regex: new RegExp(/^[0-9]{7}$/),
        name: 'ca_pst_sk',
      },
      {
        regex: new RegExp(/^[0-9]{10}TQ[0-9]{4}$/),
        name: 'ca_qst',
      },
    ],
  },
  {
    countryCode: 'CL',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{2}\.[0-9]{3}\.[0-9]{3}-K$/),
        name: 'cl_tin',
      },
    ],
  },
  {
    countryCode: 'HR',
    taxIdTypes: [
      {
        regex: new RegExp(/^HR[0-9]{11}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'CY',
    taxIdTypes: [
      {
        regex: new RegExp(/^(CY)(\d{8}[A-Z])$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'CZ',
    taxIdTypes: [
      {
        regex: new RegExp(/^CZ[0-9]{8,10}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'DK',
    taxIdTypes: [
      {
        regex: new RegExp(/^DK[0-9]{8}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'EE',
    taxIdTypes: [
      {
        regex: new RegExp(/^EE[0-9]{9}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'FI',
    taxIdTypes: [
      {
        regex: new RegExp(/^FI[0-9]{8}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'FR',
    taxIdTypes: [
      {
        regex: new RegExp(/^FR[A-Z0-9]{9,11}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'DE',
    taxIdTypes: [
      {
        regex: new RegExp(/^DE[0-9]{9}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'GR',
    taxIdTypes: [
      {
        regex: new RegExp(/^(EL)(\d{9})$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'HK',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{8}$/),
        name: 'hk_br',
      },
    ],
  },
  {
    countryCode: 'HU',
    taxIdTypes: [
      {
        regex: new RegExp(/^HU[0-9]{8,11}$/),
        name: 'eu_vat',
      },
      {
        regex: new RegExp(/^[0-9]{8}-[0-9]{1}-[0-9]{2}$/),
        name: 'hu_tin',
      },
    ],
  },
  {
    countryCode: 'IN',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z1-9]{4}$/),
        name: 'in_gst',
      },
    ],
  },
  {
    countryCode: 'ID',
    taxIdTypes: [
      {
        regex: new RegExp(
          /^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\.[0-9]{1}\\-[0-9]{3}\.[0-9]{3}$/
        ),
        name: 'id_npwp',
      },
    ],
  },
  {
    countryCode: 'IE',
    taxIdTypes: [
      {
        regex: new RegExp(/^IE[0-9]{7}[A-Z]{1,2}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'IL',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{9}$/),
        name: 'il_vat',
      },
    ],
  },
  {
    countryCode: 'IT',
    taxIdTypes: [
      {
        regex: new RegExp(/^IT[0-9]{11}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'JP',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{13}$/),
        name: 'jp_cn',
      },
      {
        regex: new RegExp(/^[0-9]{5}$/),
        name: 'jp_rn',
      },
      {
        regex: new RegExp(/^[A-Z]{1}[0-9]{13}$/),
        name: 'jp_trn',
      },
    ],
  },
  {
    countryCode: 'LV',
    taxIdTypes: [
      {
        regex: new RegExp(/^LV[0-9]{11}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'LI',
    taxIdTypes: [
      {
        regex: new RegExp(/^CHE[0-9]{9}$/),
        name: 'li_uid',
      },
    ],
  },
  {
    countryCode: 'LT',
    taxIdTypes: [
      {
        regex: new RegExp(/^LT[0-9]{9,12}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'LU',
    taxIdTypes: [
      {
        regex: new RegExp(/^LU[0-9]{8}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'MY',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{8}$/),
        name: 'my_frp',
      },
      {
        regex: new RegExp(/^C(\s)?[0-9]{10}$/),
        name: 'my_itn',
      },
      {
        regex: new RegExp(/^A[0-9]{2}-[0-9]{4}-[0-9]{8}$/),
        name: 'my_sst',
      },
    ],
  },
  {
    countryCode: 'MT',
    taxIdTypes: [
      {
        regex: new RegExp(/^MT[0-9]{8}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'MX',
    taxIdTypes: [
      {
        regex: new RegExp(/^[A-Z]{3}[0-9]{6}[A-Z]{2}[0-9]{1}$/),
        name: 'mx_rfc',
      },
    ],
  },
  {
    countryCode: 'NL',
    taxIdTypes: [
      {
        regex: new RegExp(/^NL[0-9]{9}B[0-9]{2}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'NZ',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{9}$/),
        name: 'nz_gst',
      },
    ],
  },
  {
    countryCode: 'NO',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{9}MVA$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'PL',
    taxIdTypes: [
      {
        regex: new RegExp(/^PL[0-9]{10}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'PT',
    taxIdTypes: [
      {
        regex: new RegExp(/^PT[0-9]{9}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'RO',
    taxIdTypes: [
      {
        regex: new RegExp(/^RO[0-9]+$/),
        name: 'eu_vat',
      },
      {
        regex: new RegExp(/^[0-9]{13}$/),
        name: 'ro_tin',
      },
    ],
  },
  {
    countryCode: 'RU',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{10,12}$/),
        name: 'ru_inn',
      },
      {
        regex: new RegExp(/^[0-9]{9,13}$/),
        name: 'ru_kpp',
      },
    ],
  },
  {
    countryCode: 'SA',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{15}$/),
        name: 'sa_vat',
      },
    ],
  },
  {
    countryCode: 'SK',
    taxIdTypes: [
      {
        regex: new RegExp(/^SK[0-9]{10}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'SI',
    taxIdTypes: [
      {
        regex: new RegExp(/^SI[0-9]{8}$/),
        name: 'eu_vat',
      },
      {
        regex: new RegExp(/^[0-9]{8}$/),
        name: 'si_tin',
      },
    ],
  },
  {
    countryCode: 'ZA',
    taxIdTypes: [
      {
        regex: new RegExp(/^4[0-9]{9}$/),
        name: 'za_vat',
      },
    ],
  },
  {
    countryCode: 'KR',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{3}-[0-9]{2}-[0-9]{5}$/),
        name: 'kr_brn',
      },
    ],
  },
  {
    countryCode: 'ES',
    taxIdTypes: [
      {
        regex: new RegExp(/^ES[A-Z0-9]{9}$/),
        name: 'eu_vat',
      },
      {
        regex: new RegExp(/^([A-Z][0-9]{8}|[A-Z][0-9]{7}[A-Z]|[0-9]{8}[A-Z])$/),
        name: 'es_cif',
      },
    ],
  },
  {
    countryCode: 'SE',
    taxIdTypes: [
      {
        regex: new RegExp(/^SE[0-9]{12}$/),
        name: 'eu_vat',
      },
    ],
  },
  {
    countryCode: 'CH',
    taxIdTypes: [
      {
        regex: new RegExp(/^CHE[0-9]{9}(\s)(MWST|TVA|IVA)$/),
        name: 'ch_vat',
      },
    ],
  },
  {
    countryCode: 'TW',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{8}$/),
        name: 'tw_vat',
      },
    ],
  },
  {
    countryCode: 'TH',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{13}$/),
        name: 'th_vat',
      },
    ],
  },
  {
    countryCode: 'AE',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{15}$/),
        name: 'ae_trn',
      },
    ],
  },
  {
    countryCode: 'GB',
    taxIdTypes: [
      {
        regex: new RegExp(/^XI[0-9]{9}$/),
        name: 'eu_vat',
      },
      {
        regex: new RegExp(/^GB[0-9]{9}$/),
        name: 'gb_vat',
      },
    ],
  },
  {
    countryCode: 'US',
    taxIdTypes: [
      {
        regex: new RegExp(/^[0-9]{2}-[0-9]{7}$/),
        name: 'us_ein',
      },
    ],
  },
];
