import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FlexBox from '../../components/flexbox/flexbox';

interface SectionHeaderProps {
  section: 'general' | 'ingredients';
  withSaveButton?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  section,
  withSaveButton,
}) => {
  const { t } = useTranslation();

  return (
    <FlexBox
      justifyContent="space-between"
      alignItems="center"
      bgcolor="white"
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 2,
        boxShadow: '8px 0px white, -8px 0px white',
      }}
    >
      <Typography variant="h5" py={3}>
        {t(`edit-label.${section}-section.title`)}
      </Typography>
      {withSaveButton && (
        <Button variant="contained" sx={{ borderRadius: 100 }} type="submit">
          {t('common.save')}
        </Button>
      )}
    </FlexBox>
  );
};

export default SectionHeader;
