import { hexColorRegex } from '../../../utils/common/hex-color-regex';
import { PremiumFeaturesFormValue } from './premium-features.types';

import * as yup from 'yup';

const premiumFeaturesFormSchema =
  (): yup.ObjectSchema<PremiumFeaturesFormValue> => {
    return yup.object().shape({
      logos: yup.object().optional(),
      colors: yup
        .object()
        .shape({
          primary: yup.string().matches(hexColorRegex).required(),
          secondary: yup.string().matches(hexColorRegex).required(),
          primaryText: yup.string().matches(hexColorRegex).required(),
          secondaryText: yup.string().matches(hexColorRegex).required(),
        })
        .required(),
      fontFamily: yup.string().required(),
    });
  };

export default premiumFeaturesFormSchema;
