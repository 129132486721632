import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import { Tabs, TabsProps, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FlexBox from '../../components/flexbox/flexbox';
import Tab from '../../components/tab/tab';

const LicencesTabs: React.FC<TabsProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant="fullWidth"
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.40)',
        backdropFilter: 'blur(2px)',
        '& .MuiTabs-indicator': {
          height: 4,
          backgroundColor: palette.success.main,
        },
      }}
    >
      <Tab
        label={
          <FlexBox>
            <PersonIcon />
            <Typography>{t('licences.winery')}</Typography>
          </FlexBox>
        }
      />
      <Tab
        label={
          <FlexBox>
            <PeopleIcon />
            <Typography>{t('licences.association')}</Typography>
          </FlexBox>
        }
      />
    </Tabs>
  );
};

export default LicencesTabs;
