import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid';
import { GridProps } from '@mui/material/Grid/Grid';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { WineLabelCustomPictogramViewPictogramGroupEnum as CustomPictogramGroup } from '../../api';
import { ALLOWED_EXTENSIONS, MAX_FILE_SIZE } from '../../constants/pictograms';
import { usePictogramContext } from '../../services/pictogram-context';
import splitFileName from '../../utils/file/split-file-name';
import FileSelector from '../file-selector/file-selector';
import TooltipTypography from '../typography/tooltip-typography';

interface PictogramUploaderProps {
  group: CustomPictogramGroup;
  gridProps?: GridProps;
}

const PictogramUploader: React.FC<PictogramUploaderProps> = ({
  group,
  gridProps,
}) => {
  const { t } = useTranslation();
  const { addTempPictogram } = usePictogramContext();
  const createPictogram = (file: File) => {
    const tempId = uuidv4();
    const src = URL.createObjectURL(file);
    const { baseName: name } = splitFileName(file.name);
    return { tempId, file, src, name, checked: false };
  };

  const handleSelect = (file: File) => {
    const pictogram = createPictogram(file);
    addTempPictogram(pictogram, group);
  };

  return (
    <Grid item xs={4} {...gridProps}>
      <FileSelector
        label={t('edit-label.pictogram-uploader-label')}
        onSelect={handleSelect}
        buttonProps={{
          children: (
            <Tooltip
              title={
                <>
                  <TooltipTypography>
                    {t('edit-label.pictogram-upload-tooltip')}
                  </TooltipTypography>
                  <TooltipTypography>
                    {t('edit-label.pictogram-upload-restriction-1')}
                  </TooltipTypography>
                  <TooltipTypography>
                    {t('edit-label.pictogram-upload-restriction-2')}
                  </TooltipTypography>
                </>
              }
            >
              <InfoOutlinedIcon
                sx={{ position: 'absolute', top: 0, right: 0 }}
              />
            </Tooltip>
          ),
        }}
        allowedExtensions={ALLOWED_EXTENSIONS}
        maxFileSize={MAX_FILE_SIZE}
      />
    </Grid>
  );
};

export default PictogramUploader;
