import React from 'react';
import { useTranslation } from 'react-i18next';
import CountrySelectWithController from '../../../components/country-select/country-select-with-controller';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import TextFieldWithController from '../../../components/text-field/text-field-with-controller';
import { EditLabelSplitFormProps } from '../../../models/label';
import CompanyAdvancedSettings from './company-advanced-settings';

export const defaultCompanySubsectionValues = {
  company: {
    brandName: '',
    countryCode: '',
  },
};

const CompanySubsection: React.FC<EditLabelSplitFormProps> = ({
  control,
  formState,
}) => {
  const { t } = useTranslation();
  const { isSubmitted, errors } = formState;

  return (
    <FlexBoxColumn gap="10px">
      <TextFieldWithController
        control={control}
        name="company.brandName"
        textFieldProps={{
          label: t('edit-label.brand-name'),
          error: isSubmitted && !!errors.company?.brandName,
          fullWidth: true,
        }}
      />
      <CountrySelectWithController
        textFieldProps={{
          label: t('signup.country'),
          error: isSubmitted && !!errors.company?.countryCode,
          fullWidth: true,
        }}
        control={control}
        name="company.countryCode"
      />
      <CompanyAdvancedSettings control={control} formState={formState} />
    </FlexBoxColumn>
  );
};

export default CompanySubsection;
