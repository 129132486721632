import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { PublicWineLabelView } from '../../api';
import winefoPublicApi from './winefo-public-api';

export const WINEFO_PUBLIC_KEY = 'winefo-public';

const useGetPublicWinefo = (
  id?: string,
  options?: UseQueryOptions<
    PublicWineLabelView,
    unknown,
    PublicWineLabelView,
    (string | undefined)[]
  >
): UseQueryResult<PublicWineLabelView, unknown> => {
  return useQuery({
    queryKey: [WINEFO_PUBLIC_KEY, id],
    queryFn: () =>
      winefoPublicApi
        .getPublicWinefo(id as string)
        .then((result) => result.data),
    enabled: typeof id === 'string',
    ...options,
  });
};

export default useGetPublicWinefo;
