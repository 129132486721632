import { createContext, ReactNode, useContext, useState } from 'react';
import {
  WineLabelCustomPictogramViewPictogramGroupEnum as CustomPictogramGroup,
  WineLabelCustomPictogramView,
} from '../api';
import { TempPictogram } from '../models/pictogram';

export type TempPictograms = {
  [key in CustomPictogramGroup]: TempPictogram[];
};

interface PictogramContextType {
  tempPictograms: TempPictograms;
  emptyTempPictograms: () => void;
  addTempPictogram: (
    pictogram: TempPictogram,
    group: CustomPictogramGroup
  ) => void;
  removeTempPictogram: (
    pictogram: TempPictogram,
    group: CustomPictogramGroup
  ) => void;
  toggleTempChecked: (
    pictogram: TempPictogram,
    group: CustomPictogramGroup
  ) => void;
  customPictograms: WineLabelCustomPictogramView[];
  setCustomPictograms: (value: WineLabelCustomPictogramView[]) => void;
  removeCustomPictogram: (id: number) => void;
  toggleCustomPictogram: (
    pictogram: WineLabelCustomPictogramView,
    isPictogramDeleted?: boolean
  ) => void;
  hasCustomPictogramChange: {
    [key in CustomPictogramGroup]: boolean;
  };
  unselectCustomAllergenPictograms: () => void;
}

const initialTempPictograms = {
  [CustomPictogramGroup.ResponsibleCons]: [],
  [CustomPictogramGroup.Sustainability]: [],
  [CustomPictogramGroup.Allergen]: [],
  [CustomPictogramGroup.Origin]: [],
  [CustomPictogramGroup.DetailedSustainability]: [],
};
const initialValue: PictogramContextType = {
  tempPictograms: initialTempPictograms,
  emptyTempPictograms: () => {
    throw Error('emptyTempPictograms function needs to be provided');
  },
  addTempPictogram: () => {
    throw Error('addTempPictogram function needs to be provided');
  },
  removeTempPictogram: () => {
    throw Error('removeTempPictogram function needs to be provided');
  },
  toggleTempChecked: () => {
    throw Error('toggleTempChecked function needs to be provided');
  },
  customPictograms: [],
  setCustomPictograms: () => {
    throw Error('setCustomPictograms function needs to be provided');
  },
  removeCustomPictogram: () => {
    throw Error('removeCustomPictogram function needs to be provided');
  },
  toggleCustomPictogram: () => {
    throw Error('toggleCustomPictogram function needs to be provided');
  },
  hasCustomPictogramChange: {
    [CustomPictogramGroup.Allergen]: false,
    [CustomPictogramGroup.ResponsibleCons]: false,
    [CustomPictogramGroup.Sustainability]: false,
    [CustomPictogramGroup.Origin]: false,
    [CustomPictogramGroup.DetailedSustainability]: false,
  },
  unselectCustomAllergenPictograms: () => {
    throw Error(
      'unselectCustomAllergenPictograms function needs to be provided'
    );
  },
};

export const PictogramContext = createContext(initialValue);

export const PictogramProvider: React.FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [tempPictograms, setTempPictograms] = useState<TempPictograms>(
    initialTempPictograms
  );
  const [customPictograms, setCustomPictograms] = useState<
    WineLabelCustomPictogramView[]
  >([]);
  const [hasCustomPictogramChange, setHasCustomPictogramChange] = useState<{
    [key in CustomPictogramGroup]: boolean;
  }>(initialValue.hasCustomPictogramChange);

  const emptyTempPictograms = () => {
    setTempPictograms(initialTempPictograms);
  };
  const addTempPictogram = (
    pictogram: TempPictogram,
    group: CustomPictogramGroup
  ) => {
    setTempPictograms((prev) => ({
      ...prev,
      [group]: [...prev[group], pictogram],
    }));
  };
  const removeTempPictogram = (
    pictogram: TempPictogram,
    group: CustomPictogramGroup
  ) => {
    setTempPictograms((prev) => ({
      ...prev,
      [group]: prev[group].filter((p) => p.tempId !== pictogram.tempId),
    }));
  };
  const toggleTempChecked = (
    pictogram: TempPictogram,
    group: CustomPictogramGroup
  ) => {
    const isAllergen = group === CustomPictogramGroup.Allergen;

    if (isAllergen) {
      setTempPictograms((prev) => ({
        ...prev,
        [group]: prev[group].map((p) =>
          p.tempId === pictogram.tempId
            ? { ...p, checked: !p.checked }
            : { ...p, checked: false }
        ),
      }));
      setCustomPictograms((prev) =>
        prev.map((it) =>
          it.pictogramGroup === CustomPictogramGroup.Allergen
            ? { ...it, isSelected: false }
            : it
        )
      );
    } else {
      setTempPictograms((prev) => ({
        ...prev,
        [group]: prev[group].map((p) =>
          p.tempId === pictogram.tempId ? { ...p, checked: !p.checked } : p
        ),
      }));
    }
  };
  const setCustomPictogramsResetChanges = (
    value: WineLabelCustomPictogramView[]
  ) => {
    setCustomPictograms(value);
    setHasCustomPictogramChange(initialValue.hasCustomPictogramChange);
  };
  const removeCustomPictogram = (id: number) => {
    setCustomPictograms((prev) => prev.filter((it) => it.id !== id));
  };
  const toggleCustomPictogram = (
    pictogram: WineLabelCustomPictogramView,
    isPictogramDeleted?: boolean
  ) => {
    const isAllergen =
      pictogram.pictogramGroup === CustomPictogramGroup.Allergen;

    if (isAllergen) {
      setCustomPictograms((prev) =>
        prev.map((it) => {
          if (it.id === pictogram.id) {
            return { ...it, isSelected: !it.isSelected };
          } else {
            return it.pictogramGroup === CustomPictogramGroup.Allergen
              ? { ...it, isSelected: false }
              : it;
          }
        })
      );
      setTempPictograms((prev) => ({
        ...prev,
        [CustomPictogramGroup.Allergen]: prev[
          CustomPictogramGroup.Allergen
        ].map((it) => ({ ...it, checked: false })),
      }));
    } else {
      setCustomPictograms((prev) =>
        prev.map((it) =>
          it.id === pictogram.id ? { ...it, isSelected: !it.isSelected } : it
        )
      );
    }
    if (!isPictogramDeleted && typeof pictogram.pictogramGroup !== 'undefined')
      setHasCustomPictogramChange({
        ...hasCustomPictogramChange,
        [pictogram.pictogramGroup]: true,
      });
  };
  const unselectCustomAllergenPictograms = () => {
    setCustomPictograms((prev) =>
      prev.map((it) =>
        it.pictogramGroup === CustomPictogramGroup.Allergen
          ? { ...it, isSelected: false }
          : it
      )
    );
    setTempPictograms((prev) => ({
      ...prev,
      [CustomPictogramGroup.Allergen]: prev[CustomPictogramGroup.Allergen].map(
        (it) => ({ ...it, checked: false })
      ),
    }));
  };

  const contextValue: PictogramContextType = {
    tempPictograms,
    emptyTempPictograms,
    addTempPictogram,
    toggleTempChecked,
    removeTempPictogram,
    customPictograms,
    setCustomPictograms: setCustomPictogramsResetChanges,
    toggleCustomPictogram,
    removeCustomPictogram,
    hasCustomPictogramChange,
    unselectCustomAllergenPictograms,
  };

  return (
    <PictogramContext.Provider value={contextValue}>
      {children}
    </PictogramContext.Provider>
  );
};

export const usePictogramContext = () => {
  return useContext(PictogramContext);
};
