import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogProps,
  IconButton,
  IconButtonProps,
} from '@mui/material';
import React from 'react';
import { ICON_MEDIUM2_SIZE } from '../../constants/dimensions';

interface ModalProps extends DialogProps {
  closeButtonProps?: IconButtonProps;
}

const Modal: React.FC<ModalProps> = ({
  children,
  closeButtonProps,
  ...props
}) => {
  const { onClose } = props;
  const handleCloseClick = () => {
    if (onClose) onClose({}, 'backdropClick');
  };

  return (
    <Dialog
      {...props}
      sx={{
        '& > .MuiDialog-container > .MuiPaper-root': {
          width: '550px',
          position: 'relative',
        },
        ...props.sx,
      }}
    >
      <IconButton
        onClick={handleCloseClick}
        {...closeButtonProps}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          fontSize: ICON_MEDIUM2_SIZE,
          ...closeButtonProps?.sx,
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
      {children}
    </Dialog>
  );
};

export default Modal;
