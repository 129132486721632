/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Info } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WineLabelCustomPictogramViewPictogramGroupEnum as CustomPictogramGroup } from '../../../api';
import InfoBox from '../../../components/info-box/info-box';
import CustomPictograms from '../../../components/pictogram/custom-pictograms';
import PictogramItem from '../../../components/pictogram/pictogram-item';
import PictogramUploader from '../../../components/pictogram/pictogram-uploader';
import TempPictograms from '../../../components/pictogram/temp-pictograms';
import { allergens } from '../../../constants/ingredients';
import { AllergenPictograms } from '../../../constants/pictograms';
import { AllergenEnum } from '../../../models/ingredient';
import { LabelValue } from '../../../models/label';
import { PictogramId } from '../../../models/pictogram';
import { useIngredientContext } from '../../../services/ingredient-context';
import { usePictogramContext } from '../../../services/pictogram-context';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';

interface AllergenSelectorProps {
  control: Control<LabelValue>;
  setAllergen: (value?: AllergenEnum) => void;
}

const AllergenSelector = ({ control, setAllergen }: AllergenSelectorProps) => {
  const { t } = useTranslation();
  const { unselectCustomAllergenPictograms } = usePictogramContext();
  const { ingredients } = useIngredientContext();
  const selectedIngredients = ingredients.filter(
    (ingredient) => ingredient.isSelected
  );
  const hasAllergen = (type: keyof typeof allergens) =>
    selectedIngredients.some(
      (ingredient) =>
        allergens[type].includes(ingredient.name || '') ||
        ingredient.subIngredients?.ingredients
          .filter((it) => it.isSelected)
          .some((subIngredient) =>
            allergens[type].includes(subIngredient.name || '')
          )
    );

  const hasSulphite = useMemo(
    () => hasAllergen('sulphite'),
    [selectedIngredients]
  );
  const hasEgg = useMemo(() => hasAllergen('egg'), [selectedIngredients]);
  const hasMilk = useMemo(() => hasAllergen('milk'), [selectedIngredients]);

  const recommendedType = useMemo(() => {
    if (hasSulphite && hasEgg && hasMilk) return AllergenEnum.MILK_EGG_SULPHITE;
    if (hasSulphite && hasEgg) return AllergenEnum.EGG_SULPHITE;
    if (hasSulphite && hasMilk) return AllergenEnum.MILK_SULPHITE;
    if (hasSulphite) return AllergenEnum.SULPHITE;
  }, [hasSulphite, hasEgg, hasMilk]);

  useEffect(() => {
    setAllergen(recommendedType);
  }, [recommendedType]);

  const findPictogram = (id: string) => {
    return (
      AllergenPictograms.find((pictogram) => pictogram.id === id) || {
        src: '',
        name: '',
      }
    );
  };
  const handleChange = (
    fieldValue: PictogramId,
    changedValue: PictogramId,
    callback: (value?: PictogramId) => void
  ) => {
    unselectCustomAllergenPictograms();
    callback(fieldValue === changedValue ? undefined : changedValue);
  };

  return (
    <FlexBoxColumn gap={1.25}>
      <Typography>{t('edit-label.allergen-title')}</Typography>
      <InfoBox
        icon={<Info />}
        label={
          recommendedType
            ? t('edit-label.allergen-recommendation')
            : t('edit-label.allergen-info')
        }
      />
      <Controller
        name="pictograms.allergen"
        control={control}
        render={({
          field: { value, onChange },
        }: {
          field: {
            value: PictogramId;
            onChange: (value?: PictogramId) => void;
          };
        }) => (
          <Grid container>
            {Object.keys(AllergenEnum).map((allergen) => (
              <PictogramItem
                key={allergen}
                imageSrc={findPictogram(allergen).src}
                label={t(findPictogram(allergen).name)}
                value={allergen}
                onChange={() => handleChange(value, allergen, onChange)}
                gridProps={{ xs: 3 }}
                checked={value === allergen}
              />
            ))}
            <CustomPictograms
              group={CustomPictogramGroup.Allergen}
              onPictogramToggle={() => setAllergen()}
              gridProps={{ xs: 3 }}
            />
            <TempPictograms
              group={CustomPictogramGroup.Allergen}
              onPictogramToggle={() => setAllergen()}
              gridProps={{ xs: 3 }}
            />
            <PictogramUploader
              group={CustomPictogramGroup.Allergen}
              gridProps={{ xs: 3 }}
            />
          </Grid>
        )}
      />
    </FlexBoxColumn>
  );
};

export default AllergenSelector;
