import { Box, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import QrCodeExample from '../../../assets/images/qr-code-example.svg';
import TitledAccordion from '../../../components/accordion/titled-accordion';
import CheckboxWithController from '../../../components/checkbox/checkbox-with-controller';
import FlexBox from '../../../components/flexbox/flexbox';
import { EditLabelSplitFormProps } from '../../../models/label';
import QrCodeLanguageSelector from './qr-code-language-selector';

const QrCodeTitleEditor: React.FC<EditLabelSplitFormProps> = ({
  control,
  formState,
  trigger,
}) => {
  const { t } = useTranslation();
  const withNutrition = useWatch({ control }).qrCode?.withNutrition;
  const withRecycling = useWatch({ control }).qrCode?.withRecycling;
  const topLanguageCode = useWatch({ control }).qrCode?.topLanguageCode;
  const bottomLanguageCode = useWatch({ control }).qrCode?.bottomLanguageCode;
  const isTextSelected = withNutrition || withRecycling;

  useEffect(() => {
    if (formState.isSubmitted && trigger) {
      trigger('qrCode');
    }
  }, [topLanguageCode, bottomLanguageCode, withNutrition, withRecycling]);

  return (
    <TitledAccordion title={t('edit-label.qr-code-editor.title')}>
      <FlexBox justifyContent="space-between" alignItems="center">
        <Box ml={1}>
          <CheckboxWithController
            control={control}
            name="qrCode.withNutrition"
            labelProps={{
              label: t(
                'edit-label.qr-code-editor.display-nutrition-ingredients'
              ),
              sx: { mb: 1 },
            }}
          />
          <CheckboxWithController
            control={control}
            name="qrCode.withRecycling"
            labelProps={{
              label: t('edit-label.qr-code-editor.display-recycling'),
              sx: { mt: 1 },
            }}
          />
        </Box>
        <Tooltip
          title={t('edit-label.qr-code-editor.example-tooltip')}
          placement="left"
          arrow
        >
          <img src={QrCodeExample} style={{ maxWidth: '94px' }} />
        </Tooltip>
      </FlexBox>
      <QrCodeLanguageSelector
        control={control}
        formState={formState}
        position="top"
        disabled={!isTextSelected}
      />
      <QrCodeLanguageSelector
        control={control}
        formState={formState}
        position="bottom"
        disabled={!isTextSelected}
      />
    </TitledAccordion>
  );
};

export default QrCodeTitleEditor;
