import { Snackbar, SnackbarProps } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';

interface SnackbarContextType {
  setSnackbar: (state: SnackbarProps) => void;
}

const initialValue = {
  snackbar: {},
  setSnackbar: (state: SnackbarProps) => {
    return state;
  },
};

export const SnackbarContext = createContext<SnackbarContextType>(initialValue);

export const SnackbarProvider: React.FC<SnackbarProps> = ({ children }) => {
  const [snackbar, setSnackbar] = useState<SnackbarProps>(
    initialValue.snackbar
  );
  const setSnackbarWithDefaults = (value: SnackbarProps) => {
    setSnackbar({
      open: true,
      ...value,
    });
  };

  const contextValue = React.useMemo(() => {
    return {
      setSnackbar: setSnackbarWithDefaults,
    };
  }, []);

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        {...snackbar}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): SnackbarContextType => {
  return useContext(SnackbarContext);
};
