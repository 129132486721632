export default [
  {
    value: 'AF',
    label: 'Afganistanas',
  },
  {
    value: 'AL',
    label: 'Albanija',
  },
  {
    value: 'DZ',
    label: 'Alžyras',
  },
  {
    value: 'AS',
    label: 'Amerikos Samoa',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Angilija',
  },
  {
    value: 'AG',
    label: 'Antigva ir Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentina',
  },
  {
    value: 'AM',
    label: 'Armėnija',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Australija',
  },
  {
    value: 'AT',
    label: 'Austrija',
  },
  {
    value: 'AZ',
    label: 'Azerbaidžanas',
  },
  {
    value: 'BS',
    label: 'Bahamos',
  },
  {
    value: 'BH',
    label: 'Bahreinas',
  },
  {
    value: 'BD',
    label: 'Bangladešas',
  },
  {
    value: 'BB',
    label: 'Barbadosas',
  },
  {
    value: 'BY',
    label: 'Baltarusija',
  },
  {
    value: 'BE',
    label: 'Belgija',
  },
  {
    value: 'BZ',
    label: 'Belizas',
  },
  {
    value: 'BJ',
    label: 'Beninas',
  },
  {
    value: 'BM',
    label: 'Bermuda',
  },
  {
    value: 'BT',
    label: 'Butanas',
  },
  {
    value: 'BO',
    label: 'Bolivija',
  },
  {
    value: 'BA',
    label: 'Bosnija ir Hercegovina',
  },
  {
    value: 'BW',
    label: 'Botsvana',
  },
  {
    value: 'BR',
    label: 'Brazilija',
  },
  {
    value: 'IO',
    label: 'Britanijos mergelių salos',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bulgarija',
  },
  {
    value: 'BF',
    label: 'Burkina Fasas',
  },
  {
    value: 'BI',
    label: 'Burundis',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Kambodža',
  },
  {
    value: 'CM',
    label: 'Kamerūnas',
  },
  {
    value: 'CA',
    label: 'Kanada',
  },
  {
    value: 'KY',
    label: 'Kaimanų salos',
  },
  {
    value: 'CF',
    label: 'Centrine Afrikos Respublika',
  },
  {
    value: 'TD',
    label: 'Čadas',
  },
  {
    value: 'CL',
    label: 'Čilė',
  },
  {
    value: 'CN',
    label: 'Kinija',
  },
  {
    value: 'CO',
    label: 'Kolumbija',
  },
  {
    value: 'KM',
    label: 'Komoros',
  },
  {
    value: 'CG',
    label: 'Kongo',
  },
  {
    value: 'CK',
    label: 'Kuko salos',
  },
  {
    value: 'CR',
    label: 'Kosta Rika',
  },
  {
    value: 'CI',
    label: "Côte d'Voire",
  },
  {
    value: 'HR',
    label: 'Kroatija',
  },
  {
    value: 'CU',
    label: 'Kuba',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Kipras',
  },
  {
    value: 'CZ',
    label: 'Čekijos Respublika',
  },
  {
    value: 'CZ',
    label: 'Korėjos demokratinės liaudies Respublika',
  },
  {
    value: 'CD',
    label: 'Kongo Demokratinė Respublika',
  },
  {
    value: 'DK',
    label: 'Danija',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Djibouti',
  },
  {
    value: 'DM',
    label: 'Dominika',
  },
  {
    value: 'DO',
    label: 'Dominikos Respublika',
  },
  {
    value: 'EC',
    label: 'Ekvadoras',
  },
  {
    value: 'EG',
    label: 'Egiptas',
  },
  {
    value: 'SV',
    label: 'Salvadoras',
  },
  {
    value: 'GQ',
    label: 'Pusiaujo Gvinėja',
  },
  {
    value: 'ER',
    label: 'Eritrėja',
  },
  {
    value: 'EE',
    label: 'Estija',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etiopija',
  },
  {
    value: 'FK',
    label: 'Folklando salos',
  },
  {
    value: 'FO',
    label: 'Farerų salos',
  },
  {
    value: 'FJ',
    label: 'Fiji',
  },
  {
    value: 'FI',
    label: 'Suomija',
  },
  {
    value: 'FR',
    label: 'Prancūzija',
  },
  {
    value: 'FR',
    label: 'Prancūzijos departamentai ir teritorijos Indijos vandenyne',
  },
  {
    value: 'GF',
    label: 'Prancūzijos Gviana',
  },
  {
    value: 'PF',
    label: 'Prancūzų Polinezija',
  },
  {
    value: 'GA',
    label: 'Gabonas',
  },
  {
    value: 'GM',
    label: 'Gambija',
  },
  {
    value: 'GE',
    label: 'Gruzija',
  },
  {
    value: 'DE',
    label: 'Vokietija',
  },
  {
    value: 'GH',
    label: 'Gana',
  },
  {
    value: 'GI',
    label: 'Gibraltaras',
  },
  {
    value: 'GR',
    label: 'Graikija',
  },
  {
    value: 'GL',
    label: 'Grenlandija',
  },
  {
    value: 'GD',
    label: 'Grenada',
  },
  {
    value: 'GP',
    label: 'Gvadelupė',
  },
  {
    value: 'GU',
    label: 'Guamas',
  },
  {
    value: 'GT',
    label: 'Gvatemala',
  },
  {
    value: 'GG',
    label: 'Gvinėja',
  },
  {
    value: 'GN',
    label: 'Gvinėjos-Bisau',
  },
  {
    value: 'GY',
    label: 'Gajana',
  },
  {
    value: 'HT',
    label: 'Haitis',
  },
  {
    value: 'HN',
    label: 'Hondūras',
  },
  {
    value: 'HK',
    label: 'Honkongas',
  },
  {
    value: 'HU',
    label: 'Vengrija',
  },
  {
    value: 'IS',
    label: 'Islandija',
  },
  {
    value: 'IN',
    label: 'Indija',
  },
  {
    value: 'ID',
    label: 'Indonezija',
  },
  {
    value: 'IR',
    label: 'Iranas',
  },
  {
    value: 'IQ',
    label: 'Irakas',
  },
  {
    value: 'IE',
    label: 'Airija',
  },
  {
    value: 'IL',
    label: 'Izraelis',
  },
  {
    value: 'IT',
    label: 'Italija',
  },
  {
    value: 'JM',
    label: 'Jamaika',
  },
  {
    value: 'JP',
    label: 'Japonija',
  },
  {
    value: 'JO',
    label: 'Jordanija',
  },
  {
    value: 'KZ',
    label: 'Kazachstanas',
  },
  {
    value: 'KE',
    label: 'Kenija',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Korėja',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuveitas',
  },
  {
    value: 'KG',
    label: 'Kirgizija',
  },
  {
    value: 'LA',
    label: 'Laoso liaudies demokratinė respublika',
  },
  {
    value: 'LV',
    label: 'Latvija',
  },
  {
    value: 'LB',
    label: 'Libanas',
  },
  {
    value: 'LS',
    label: 'Lesotas',
  },
  {
    value: 'LR',
    label: 'Liberija',
  },
  {
    value: 'LY',
    label: 'Libija',
  },
  {
    value: 'LI',
    label: 'Lichtenšteinas',
  },
  {
    value: 'LT',
    label: 'Lietuva',
  },
  {
    value: 'LU',
    label: 'Liuksemburgas',
  },
  {
    value: 'MO',
    label: 'Makao',
  },
  {
    value: 'MG',
    label: 'Madagaskaras',
  },
  {
    value: 'MW',
    label: 'Malavis',
  },
  {
    value: 'MY',
    label: 'Malaizija',
  },
  {
    value: 'MV',
    label: 'Maldyvai',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Maršalo salos',
  },
  {
    value: 'MQ',
    label: 'Martinika',
  },
  {
    value: 'MR',
    label: 'Mauritanija',
  },
  {
    value: 'MU',
    label: 'Mauricijus',
  },
  {
    value: 'MX',
    label: 'Meksika',
  },
  {
    value: 'FM',
    label: 'Mikronezija',
  },
  {
    value: 'MD',
    label: 'Moldova',
  },
  {
    value: 'MC',
    label: 'Monakas',
  },
  {
    value: 'MN',
    label: 'Mongolija',
  },
  {
    value: 'ME',
    label: 'Juodkalnija',
  },
  {
    value: 'MS',
    label: 'Montserratas',
  },
  {
    value: 'MA',
    label: 'Marokas',
  },
  {
    value: 'MZ',
    label: 'Mozambikas',
  },
  {
    value: 'MM',
    label: 'Mianmaras',
  },
  {
    value: 'NA',
    label: 'Namibija',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepalas',
  },
  {
    value: 'NL',
    label: 'Nyderlandai',
  },
  {
    value: 'NC',
    label: 'Naujoji Kaledonija',
  },
  {
    value: 'NZ',
    label: 'Naujoji Zelandija',
  },
  {
    value: 'NI',
    label: 'Nikaragva',
  },
  {
    value: 'NE',
    label: 'Nigeris',
  },
  {
    value: 'NG',
    label: 'Nigerija',
  },
  {
    value: 'NU',
    label: 'Niuue',
  },
  {
    value: 'NF',
    label: 'Norfolko sala',
  },
  {
    value: 'NF',
    label: 'Šiaurės Makedonija',
  },
  {
    value: 'MP',
    label: 'Šiaurės Marianos salos',
  },
  {
    value: 'NO',
    label: 'Norvegija',
  },
  {
    value: 'OM',
    label: 'Omanas',
  },
  {
    value: 'PK',
    label: 'Pakistanas',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papua Naujoji Gvinėja',
  },
  {
    value: 'PY',
    label: 'Paragvajus',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'PH',
    label: 'Filipinai',
  },
  {
    value: 'PL',
    label: 'Lenkija',
  },
  {
    value: 'PT',
    label: 'Portugalija',
  },
  {
    value: 'PR',
    label: 'Puerto Rikas',
  },
  {
    value: 'QA',
    label: 'Kataras',
  },
  {
    value: 'RO',
    label: 'Rumunija',
  },
  {
    value: 'RU',
    label: 'Rusijos Federacija',
  },
  {
    value: 'RW',
    label: 'Ruanda',
  },
  {
    value: 'SH',
    label: 'Saint Helena, Ascension ir Tristan da Cunha',
  },
  {
    value: 'KN',
    label: 'Saint Kitts ir Nevis',
  },
  {
    value: 'LC',
    label: 'Saint Lucia',
  },
  {
    value: 'PM',
    label: 'Saint Pierre ir Miquelon',
  },
  {
    value: 'VC',
    label: 'Šventasis Vincentas ir Grenadinai',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao Tome ir Principe',
  },
  {
    value: 'SA',
    label: 'Saudo Arabija',
  },
  {
    value: 'SN',
    label: 'Senegalas',
  },
  {
    value: 'RS',
    label: 'Serbija',
  },
  {
    value: 'SC',
    label: 'Seišelės',
  },
  {
    value: 'SL',
    label: 'Siera Leonė',
  },
  {
    value: 'SG',
    label: 'Singapūras',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Slovakija',
  },
  {
    value: 'SI',
    label: 'Slovėnija',
  },
  {
    value: 'SB',
    label: 'Saliamono salos',
  },
  {
    value: 'SO',
    label: 'Somalis',
  },
  {
    value: 'ZA',
    label: 'pietų Afrika',
  },
  {
    value: 'SS',
    label: 'Pietų Sudanas',
  },
  {
    value: 'ES',
    label: 'Ispanija',
  },
  {
    value: 'LK',
    label: 'Šri Lanka',
  },
  {
    value: 'SD',
    label: 'Sudanas',
  },
  {
    value: 'SR',
    label: 'Surinamas',
  },
  {
    value: 'SE',
    label: 'Švedija',
  },
  {
    value: 'CH',
    label: 'Šveicarija',
  },
  {
    value: 'SY',
    label: 'Sirijos Arabų Respublika',
  },
  {
    value: 'TW',
    label: 'Taivanas',
  },
  {
    value: 'TJ',
    label: 'Tadžikistanas',
  },
  {
    value: 'TZ',
    label: 'Tanzanija',
  },
  {
    value: 'TH',
    label: 'Tailandas',
  },
  {
    value: 'TL',
    label: 'Timor-Leste',
  },
  {
    value: 'TG',
    label: 'Togo Respublika',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinidadas ir Tobagas',
  },
  {
    value: 'TN',
    label: 'Tunisas',
  },
  {
    value: 'TR',
    label: 'Turkijos Respublika',
  },
  {
    value: 'TM',
    label: 'Turkmėnistanas',
  },
  {
    value: 'TC',
    label: 'Turkai ir Kaikosai',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukraina',
  },
  {
    value: 'AE',
    label: 'Jungtiniai Arabų Emyratai',
  },
  {
    value: 'GB',
    label: 'Jungtinė Karalystė',
  },
  {
    value: 'US',
    label: 'Jungtinės Valstijos',
  },
  {
    value: 'US',
    label: 'Jungtinių Valstijų Mergelių salos',
  },
  {
    value: 'UY',
    label: 'Urugvajus',
  },
  {
    value: 'UZ',
    label: 'Uzbekistanas',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venesuela',
  },
  {
    value: 'VN',
    label: 'Viet Nam',
  },
  {
    value: 'WF',
    label: 'Wallis ir Futuna',
  },
  {
    value: 'YE',
    label: 'Jemenas',
  },
  {
    value: 'ZM',
    label: 'Zambija',
  },
  {
    value: 'ZM',
    label: 'Zimbabvė',
  },
];
