import {
  PictogramsDtoResponsibleConsumptionEnum,
  PictogramsDtoSustainabilityEnum,
} from '../api';

export type PictogramId =
  | PictogramsDtoResponsibleConsumptionEnum
  | PictogramsDtoSustainabilityEnum
  | string
  | undefined;

export enum DetailedSustainabilityCategoryEnum {
  BOTTLE = 'bottle',
  CLOSURE = 'closure',
  CAPSULE = 'capsule',
  AGRAFFE = 'agraffe',
  CARDBOARD_BOX = 'cardboard-box',
  WOODEN_BOX = 'wooden-box',
  CAN = 'can',
  TISSUE = 'tissue',
  HANG_TAG = 'hang-tag',
  CARDBOARD_BOX_2_3_BOTTLES = 'cardboard-box-2-3-bottles',
  THERMAL = 'thermal',
  PLEXI_GLASS = 'plexi-glass',
  YARN = 'yarn',
  BAG = 'bag',
}

export enum DetailedSustainabilityWaste {
  GLASS = 'glass',
  PLASTIC = 'plastic',
  SEPARATED = 'separated',
  ALUMINUM = 'aluminum',
  PAPER = 'paper',
  METAL = 'metal',
  UNDIFFERENTIATED = 'undifferentiated',
}
interface DetailedSustainabilityDetails {
  category: DetailedSustainabilityCategoryEnum;
  resinCode: string;
  waste: DetailedSustainabilityWaste;
}
export type Pictogram = {
  id?: PictogramId;
  src: string;
  name: string;
  details?: DetailedSustainabilityDetails;
};

export type TempPictogram = Pictogram & {
  tempId: string;
  checked: boolean;
  file: File;
};
