import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { CreateWineLabelRequest } from '../../api';
import { WINE_LABELS_KEY } from './use-search-labels';
import wineLabelApi from './wine-label-api';

interface CreateLabelVariables {
  value: CreateWineLabelRequest;
  onSuccess: (id: number) => void;
}

const useCreateLabel = (): UseMutationResult<
  AxiosResponse,
  unknown,
  CreateLabelVariables,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: CreateLabelVariables) =>
      wineLabelApi.createLabel(variables.value),
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries([WINE_LABELS_KEY]);
      const { id } = response.data;
      if (id) variables.onSuccess(id);
    },
  });
};

export default useCreateLabel;
