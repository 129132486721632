import { useTheme } from '@mui/material';
import {
  CustomImageView,
  CustomImageViewImageGroupEnum,
  PremiumFeature,
} from '../../../api';
import { primary } from '../../../constants/colors';
import {
  Logo,
  LogoPositions,
  PremiumFeaturesFormValue,
} from './premium-features.types';

const getLogos = (
  customImages?: CustomImageView[],
  sameFooterLogo?: boolean
) => {
  const headerLogo = customImages?.find(
    (image) => image.imageGroup === CustomImageViewImageGroupEnum.BrandLogo
  );
  const footerLogo = customImages?.find(
    (image) => image.imageGroup === CustomImageViewImageGroupEnum.Footer
  );
  const footerLogoFormValue = footerLogo
    ? {
        src: footerLogo.downloadUrl,
        logoPosition: LogoPositions.FOOTER,
        id: footerLogo.id,
        originalFileName: footerLogo.originalFileName,
      }
    : undefined;

  return {
    header: headerLogo
      ? {
          src: headerLogo.downloadUrl,
          logoPosition: LogoPositions.HEADER,
          id: headerLogo.id,
          originalFileName: headerLogo.originalFileName,
        }
      : undefined,
    footer: sameFooterLogo
      ? {
          src: headerLogo?.downloadUrl,
          logoPosition: LogoPositions.FOOTER,
          id: headerLogo?.id,
          originalFileName: headerLogo?.originalFileName,
        }
      : footerLogoFormValue,
    useHeaderLogoTwice: sameFooterLogo,
  };
};

export const getLogoIdsToDelete = (
  value: PremiumFeaturesFormValue,
  defaultValue?: PremiumFeaturesFormValue
) =>
  (
    [defaultValue?.logos?.header?.id, defaultValue?.logos?.footer?.id].filter(
      (it) =>
        typeof it === 'number' &&
        ![value.logos?.header?.id, value.logos?.footer?.id]
          .filter((id) => typeof id === 'number')
          .includes(it)
    ) as number[]
  ).reduce(
    (previous, current) =>
      previous.includes(current) ? previous : [...previous, current],
    [] as number[]
  );

export const getImageGroup = (logo?: Logo) =>
  logo?.logoPosition === LogoPositions.HEADER
    ? CustomImageViewImageGroupEnum.BrandLogo
    : CustomImageViewImageGroupEnum.Footer;

export const useToPremiumFeaturesFormValue = (
  data?: PremiumFeature
): PremiumFeaturesFormValue => {
  const { palette } = useTheme();

  return {
    colors: {
      primary: data?.primaryColour || palette.primary.main,
      secondary:
        data?.secondaryColour || palette.primaryVariations[100] || primary[100],
      primaryText: data?.primaryTextColour || '#FFF',
      secondaryText: data?.secondaryTextColour || '#000',
    },
    fontFamily: data?.font || 'Roboto',
    logos: getLogos(data?.customImages, data?.sameFooterLogo),
  };
};

export const toPremiumFeature = (
  formValue: PremiumFeaturesFormValue,
  uploadedLogos: CustomImageView[]
): PremiumFeature => {
  const formerLogos = [formValue.logos?.header, formValue.logos?.footer].filter(
    (it) => typeof it?.id !== 'undefined'
  ) as Logo[];

  return {
    primaryColour: formValue.colors.primary,
    primaryTextColour: formValue.colors.primaryText,
    secondaryColour: formValue.colors.secondary,
    secondaryTextColour: formValue.colors.secondaryText,
    font: formValue.fontFamily,
    sameFooterLogo: formValue.logos?.useHeaderLogoTwice,
    customImages: [
      ...formerLogos.map((logo) => ({
        id: logo.id,
        imageGroup: getImageGroup(logo),
        originalFileName: logo.originalFileName,
      })),
      ...uploadedLogos,
    ],
  };
};
