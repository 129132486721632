import { KeyboardArrowDown, Star } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PublicWineLabelView } from '../../api';
import CustomerContainer from '../../components/customer-container/customer-container';
import FlexBoxColumn from '../../components/flexbox/flex-box-column';
import ProductMainDetails from '../../components/product-main-details/product-main-details';
import UseCountriesByCode from '../../utils/countries/use-countries-by-code';
import MerchantLabel from './merchant-label';
import { getColors } from './winefo-point-utils';

interface MainInformationProps {
  data: PublicWineLabelView;
}

const MainInformation = ({ data }: MainInformationProps) => {
  const { t } = useTranslation();
  const colors = getColors(data.premiumFeature);

  return (
    <CustomerContainer
      title={t('winefo-point.section.main')}
      icon={<Star />}
      colors={colors}
    >
      <FlexBoxColumn gap={2} p={2}>
        <ProductMainDetails product={data} />
        {data.productionMethod && (
          <Typography fontFamily="inherit">
            {t(
              // eslint-disable-next-line max-len
              `production_characteristics.${data.productionMethod}`
            )}
          </Typography>
        )}
      </FlexBoxColumn>
      <FlexBoxColumn gap={2} p={2} pt={1} bgcolor={grey[100]}>
        {data.company?.brandName && (
          <Typography fontFamily="inherit">{data.company.brandName}</Typography>
        )}
        {data.company?.countryCode && (
          <Typography fontFamily="inherit">
            {
              UseCountriesByCode().find(
                (option) => option.value === data.company?.countryCode
              )?.label
            }
          </Typography>
        )}
      </FlexBoxColumn>
      {(data.company?.producer || data.company?.importer) && (
        <Accordion
          elevation={0}
          sx={{
            bgcolor: grey[100],
            p: 2,
            pt: 1,
            '&.Mui-expanded': {
              bgcolor: 'white',
              m: 0,
            },
            '::before': {
              content: 'none',
            },
          }}
        >
          <AccordionSummary
            sx={{
              p: 0,
              width: 'fit-content',
              minHeight: 24,
              maxHeight: 24,
              '&.Mui-expanded': { minHeight: 24, maxHeight: 24 },
            }}
            expandIcon={<KeyboardArrowDown />}
          >
            {t('common.more')}
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {data.company?.producer && (
              <MerchantLabel
                merchant={data.company.producer}
                title={t('common.vendor')}
              />
            )}
            {data.company?.importer && (
              <MerchantLabel
                merchant={data.company.importer}
                title={t('common.importer')}
              />
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </CustomerContainer>
  );
};

export default MainInformation;
