import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Company } from '../../api';
import profileApi from './profile-api';
import { PROFILE_QUERY_KEY } from './use-get-profile';

interface UpdateProfileVariables {
  data: Company;
  onSuccess: () => void;
}

const useUpdateProfile = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  UpdateProfileVariables,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: UpdateProfileVariables) =>
      profileApi.updateProfile(variables.data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([PROFILE_QUERY_KEY]);
      variables.onSuccess();
    },
  });
};

export default useUpdateProfile;
