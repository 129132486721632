import { CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FlexBox from '../../components/flexbox/flexbox';
import useGetPublicWinefo from '../../data/winefo-public/use-get-public-winefo';
import { useSnackbar } from '../../services/snackbar-context';
import CustomerWinefoPoint from './customer-winefo-point';
import DeletedWinefoPoint from './deleted-winefo-point';

const CustomerWinefoPointDataWrapper: React.FC = () => {
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const { id } = useParams();
  const [notFound, setNotFound] = useState(false);
  const handleError = (error: unknown) => {
    if ((error as AxiosError)?.response?.status === 404) {
      setNotFound(true);
    } else {
      setSnackbar({
        message: t('error.general-network-error'),
      });
    }
  };
  const { data, isLoading } = useGetPublicWinefo(id, { onError: handleError });

  return (
    <>
      {isLoading ? (
        <FlexBox py={3} justifyContent="center">
          <CircularProgress />
        </FlexBox>
      ) : (
        <>
          {notFound || !data ? (
            <DeletedWinefoPoint />
          ) : (
            <CustomerWinefoPoint data={data} />
          )}
        </>
      )}
    </>
  );
};

export default CustomerWinefoPointDataWrapper;
