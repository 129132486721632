import { FormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SignupFormValue } from '../../models/signup';

export const companyBlockFields: (keyof SignupFormValue)[] = [
  'contactPerson',
  'prefix',
  'phoneNumber',
  'companyBrand',
  'companyLegalName',
  'companyIdCountry',
  'companyId',
  'companyUrl',
];
export const locationBlockFields: (keyof SignupFormValue)[] = [
  'address',
  'postalCode',
  'city',
  'state',
  'country',
];
export const accountBlockFields: (keyof SignupFormValue)[] = [
  'email',
  'password',
  'termsPrivacyConsent',
  'marketingConsent',
  'registrationCode',
];

export const useSignupFormUtils = (formState: FormState<SignupFormValue>) => {
  const { errors, isSubmitted } = formState;
  const { t } = useTranslation();
  const isError = (field: keyof SignupFormValue) =>
    isSubmitted && !!errors[field];
  const translationKeyMapper: Partial<Record<keyof SignupFormValue, string>> = {
    contactPerson: 'contact-person',
    prefix: 'prefix',
    phoneNumber: 'phone-number',
    companyBrand: 'company-brand',
    companyLegalName: 'company-legal-name',
    companyUrl: 'company-url',
    companyId: 'company-id',
    companyIdCountry: 'country',
    address: 'address',
    postalCode: 'postal-code',
    city: 'city',
    state: 'state',
    country: 'country',
  };
  const getTextFieldProps = (field: keyof SignupFormValue) => ({
    label: t(`signup.${translationKeyMapper[field]}`),
    fullWidth: true,
    error: isError(field),
    helperText: isError(field) && errors[field]?.message,
  });

  return { getTextFieldProps };
};
