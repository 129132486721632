export default [
  {
    value: 'AF',
    label: 'Afghanistan',
  },
  {
    value: 'AL',
    label: 'Albanien',
  },
  {
    value: 'DZ',
    label: 'Algeriet',
  },
  {
    value: 'AS',
    label: 'Amerikanska Samoa',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguilla',
  },
  {
    value: 'AG',
    label: 'Antigua och Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentina',
  },
  {
    value: 'AM',
    label: 'Armenien',
  },
  {
    value: 'AW',
    label: 'Araba',
  },
  {
    value: 'AU',
    label: 'Australien',
  },
  {
    value: 'AT',
    label: 'Österrike',
  },
  {
    value: 'AZ',
    label: 'Aserbajdsjan',
  },
  {
    value: 'BS',
    label: 'Bahamas',
  },
  {
    value: 'BH',
    label: 'Bahrain',
  },
  {
    value: 'BD',
    label: 'Bangladesh',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Belarus',
  },
  {
    value: 'BE',
    label: 'Belgien',
  },
  {
    value: 'BZ',
    label: 'Belisera',
  },
  {
    value: 'BJ',
    label: 'Benin',
  },
  {
    value: 'BM',
    label: 'Bermuda',
  },
  {
    value: 'BT',
    label: 'Bhutan',
  },
  {
    value: 'BO',
    label: 'Bolivia',
  },
  {
    value: 'BA',
    label: 'Bosnien och Hercegovina',
  },
  {
    value: 'BW',
    label: 'Botswana',
  },
  {
    value: 'BR',
    label: 'Brasilien',
  },
  {
    value: 'IO',
    label: 'Brittiska Jungfruöarna',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bulgarien',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Kambodja',
  },
  {
    value: 'CM',
    label: 'Kamerun',
  },
  {
    value: 'CA',
    label: 'Canada',
  },
  {
    value: 'KY',
    label: 'Caymanöarna',
  },
  {
    value: 'CF',
    label: 'Centralafrikanska republiken',
  },
  {
    value: 'TD',
    label: 'Tak',
  },
  {
    value: 'CL',
    label: 'Chile',
  },
  {
    value: 'CN',
    label: 'Kina',
  },
  {
    value: 'CO',
    label: 'Colombia',
  },
  {
    value: 'KM',
    label: 'Komor',
  },
  {
    value: 'CG',
    label: 'Kongo',
  },
  {
    value: 'CK',
    label: 'Cooköarna',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
  },
  {
    value: 'CI',
    label: "Côte d'Ivoire",
  },
  {
    value: 'HR',
    label: 'Kroatien',
  },
  {
    value: 'CU',
    label: 'Kuba',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Cypern',
  },
  {
    value: 'CZ',
    label: 'Tjeckien',
  },
  {
    value: 'CZ',
    label: 'Demokratiska folkrepubliken Korea',
  },
  {
    value: 'CD',
    label: 'Demokratiska republiken Kongo',
  },
  {
    value: 'DK',
    label: 'Danmark',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Djibouti',
  },
  {
    value: 'DM',
    label: 'Dominica',
  },
  {
    value: 'DO',
    label: 'Dominikanska republiken',
  },
  {
    value: 'EC',
    label: 'Ecuador',
  },
  {
    value: 'EG',
    label: 'Egypten',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'GQ',
    label: 'Ekvatorialguinea',
  },
  {
    value: 'ER',
    label: 'Eritrea',
  },
  {
    value: 'EE',
    label: 'Estland',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etiopien',
  },
  {
    value: 'FK',
    label: 'Falklandsöarna',
  },
  {
    value: 'FO',
    label: 'Färöarna',
  },
  {
    value: 'FJ',
    label: 'Fiji',
  },
  {
    value: 'FI',
    label: 'Finland',
  },
  {
    value: 'FR',
    label: 'Frankrike',
  },
  {
    value: 'FR',
    label: 'Franska avdelningar och territorier i Indiska oceanen',
  },
  {
    value: 'GF',
    label: 'Franska Guyana',
  },
  {
    value: 'PF',
    label: 'Franska polynesien',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambia',
  },
  {
    value: 'GE',
    label: 'Georgien',
  },
  {
    value: 'DE',
    label: 'Tyskland',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
  },
  {
    value: 'GR',
    label: 'Grekland',
  },
  {
    value: 'GL',
    label: 'Grönland',
  },
  {
    value: 'GD',
    label: 'Grenad',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  {
    value: 'GG',
    label: 'Guinea',
  },
  {
    value: 'GN',
    label: 'Guinea-bissau',
  },
  {
    value: 'GY',
    label: 'Guyana',
  },
  {
    value: 'HT',
    label: 'Haiti',
  },
  {
    value: 'HN',
    label: 'Honduror',
  },
  {
    value: 'HK',
    label: 'Hong Kong',
  },
  {
    value: 'HU',
    label: 'Ungern',
  },
  {
    value: 'IS',
    label: 'Island',
  },
  {
    value: 'IN',
    label: 'Indien',
  },
  {
    value: 'ID',
    label: 'Indonesien',
  },
  {
    value: 'IR',
    label: 'Iran',
  },
  {
    value: 'IQ',
    label: 'Irak',
  },
  {
    value: 'IE',
    label: 'Irland',
  },
  {
    value: 'IL',
    label: 'Israel',
  },
  {
    value: 'IT',
    label: 'Italien',
  },
  {
    value: 'JM',
    label: 'Jamaica',
  },
  {
    value: 'JP',
    label: 'Japan',
  },
  {
    value: 'JO',
    label: 'Jordan',
  },
  {
    value: 'KZ',
    label: 'Kazakstan',
  },
  {
    value: 'KE',
    label: 'Kenya',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Korea',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuwait',
  },
  {
    value: 'KG',
    label: 'Kirgisstan',
  },
  {
    value: 'LA',
    label: 'Demokratiska folkrepubliken Laos',
  },
  {
    value: 'LV',
    label: 'Lettland',
  },
  {
    value: 'LB',
    label: 'Libanon',
  },
  {
    value: 'LS',
    label: 'Lesotho',
  },
  {
    value: 'LR',
    label: 'Liberia',
  },
  {
    value: 'LY',
    label: 'Libyen',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
  },
  {
    value: 'LT',
    label: 'Lituen',
  },
  {
    value: 'LU',
    label: 'Luxemburg',
  },
  {
    value: 'MO',
    label: 'Makao',
  },
  {
    value: 'MG',
    label: 'Madagaskar',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Malaysia',
  },
  {
    value: 'MV',
    label: 'Maldiver',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Marshallöarna',
  },
  {
    value: 'MQ',
    label: 'Martinique',
  },
  {
    value: 'MR',
    label: 'Mauritanien',
  },
  {
    value: 'MU',
    label: 'Mauritius',
  },
  {
    value: 'MX',
    label: 'Mexiko',
  },
  {
    value: 'FM',
    label: 'Mikronesien',
  },
  {
    value: 'MD',
    label: 'Mögel',
  },
  {
    value: 'MC',
    label: 'Monaco',
  },
  {
    value: 'MN',
    label: 'mongoliet',
  },
  {
    value: 'ME',
    label: 'Montenegro',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Marocko',
  },
  {
    value: 'MZ',
    label: 'Mozambique',
  },
  {
    value: 'MM',
    label: 'Myanmar',
  },
  {
    value: 'NA',
    label: 'Namibia',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepal',
  },
  {
    value: 'NL',
    label: 'Nederländska',
  },
  {
    value: 'NC',
    label: 'Nya Kaledonien',
  },
  {
    value: 'NZ',
    label: 'Nya Zeeland',
  },
  {
    value: 'NI',
    label: 'Nicaragua',
  },
  {
    value: 'NE',
    label: 'Niger',
  },
  {
    value: 'NG',
    label: 'Nigeria',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Norfolk Island',
  },
  {
    value: 'NF',
    label: 'Norra makedonien',
  },
  {
    value: 'MP',
    label: 'Norra Marianaöarna',
  },
  {
    value: 'NO',
    label: 'Norge',
  },
  {
    value: 'OM',
    label: 'oman',
  },
  {
    value: 'PK',
    label: 'Pakistan',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papua Nya Guinea',
  },
  {
    value: 'PY',
    label: 'Paraguay',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'PH',
    label: 'Filippinerna',
  },
  {
    value: 'PL',
    label: 'Polen',
  },
  {
    value: 'PT',
    label: 'Portugal',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'QA',
    label: 'Qatar',
  },
  {
    value: 'RO',
    label: 'Rumänien',
  },
  {
    value: 'RU',
    label: 'Ryska Federationen',
  },
  {
    value: 'RW',
    label: 'Rwanda',
  },
  {
    value: 'SH',
    label: 'Saint Helena, Ascension och Tristan da Cunha',
  },
  {
    value: 'KN',
    label: 'Saint Kitts och Nevis',
  },
  {
    value: 'LC',
    label: 'Saint Lucia',
  },
  {
    value: 'PM',
    label: 'Saint Pierre och Miquelon',
  },
  {
    value: 'VC',
    label: 'Saint Vincent och Grenadinerna',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao Tome och Principe',
  },
  {
    value: 'SA',
    label: 'Saudiarabien',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Serbien',
  },
  {
    value: 'SC',
    label: 'Seycheller',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Singapore',
  },
  {
    value: 'SG',
    label: 'Sint maarten',
  },
  {
    value: 'SK',
    label: 'Slovakien',
  },
  {
    value: 'SI',
    label: 'Slovenien',
  },
  {
    value: 'SB',
    label: 'Salomonöarna',
  },
  {
    value: 'SO',
    label: 'Somalia',
  },
  {
    value: 'ZA',
    label: 'Sydafrika',
  },
  {
    value: 'SS',
    label: 'södra Sudan',
  },
  {
    value: 'ES',
    label: 'Spanien',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
  },
  {
    value: 'SD',
    label: 'Sudan',
  },
  {
    value: 'SR',
    label: 'Surinam',
  },
  {
    value: 'SE',
    label: 'Sverige',
  },
  {
    value: 'CH',
    label: 'Schweiz',
  },
  {
    value: 'SY',
    label: 'Syriska arabiska republiken',
  },
  {
    value: 'TW',
    label: 'Taiwan',
  },
  {
    value: 'TJ',
    label: 'Tånad',
  },
  {
    value: 'TZ',
    label: 'Tanzania',
  },
  {
    value: 'TH',
    label: 'Thailand',
  },
  {
    value: 'TL',
    label: 'Timor-lest',
  },
  {
    value: 'TG',
    label: 'Togo republik',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinidad och Tobago',
  },
  {
    value: 'TN',
    label: 'Tunisien',
  },
  {
    value: 'TR',
    label: 'republiken Turkiet',
  },
  {
    value: 'TM',
    label: 'Turkmenistan',
  },
  {
    value: 'TC',
    label: 'Turks och Caicos',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukraina',
  },
  {
    value: 'AE',
    label: 'Förenade arabemiraten',
  },
  {
    value: 'GB',
    label: 'Storbritannien',
  },
  {
    value: 'US',
    label: 'Förenta staterna',
  },
  {
    value: 'US',
    label: 'United States Jungfruöar',
  },
  {
    value: 'UY',
    label: 'Uruguay',
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Vietnam',
  },
  {
    value: 'WF',
    label: 'Wallis och Futuna',
  },
  {
    value: 'YE',
    label: 'Jemen',
  },
  {
    value: 'ZM',
    label: 'Zambia',
  },
  {
    value: 'ZM',
    label: 'Zimbabwe',
  },
];
