import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface SearchContextType {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
}

const initialValue: SearchContextType = {
  searchText: '',
  setSearchText: () => {
    throw Error('setSearchText function needs to be provided');
  },
};

export const SearchContext = createContext(initialValue);

export const SearchProvider: React.FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [searchText, setSearchText] = useState<string>('');

  const contextValue = {
    searchText,
    setSearchText,
  };

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  return useContext(SearchContext);
};
