import { FilterOptionsState } from '@mui/material/useAutocomplete';
import { SearchableSelectOptions } from '../../components/select/searchable-select';

export const partialSearchFilterOptions = (
  filterOptions: (string | SearchableSelectOptions)[],
  state: FilterOptionsState<string | SearchableSelectOptions>
): (string | SearchableSelectOptions)[] =>
  filterOptions.filter((option) => {
    const inputParts = state.inputValue.toLowerCase().split(' ');

    return inputParts.some((part) =>
      (typeof option === 'string' ? option : option.key)
        .toLowerCase()
        .includes(part)
    );
  });
