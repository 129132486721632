import { Info } from '@mui/icons-material';
import { t } from 'i18next';
import React from 'react';
import TitledAccordion from '../../../components/accordion/titled-accordion';
import InfoBox from '../../../components/info-box/info-box';
import { EditLabelSplitFormProps } from '../../../models/label';
import { DetailedSustainabilityCategoryEnum } from '../../../models/pictogram';
import DetailedSustainabilityCategory from './detailed-sustainability-category';

const DetailedSustainability: React.FC<EditLabelSplitFormProps> = ({
  ...props
}) => {
  return (
    <TitledAccordion
      title={t('edit-label.detailed-sustainability')}
      sx={{
        '& .MuiAccordionDetails-root': {
          gap: 0.5,
        },
      }}
    >
      <InfoBox
        icon={<Info color="inherit" />}
        label={t('edit-label.detailed-sustainability-info')}
      />
      {Object.values(DetailedSustainabilityCategoryEnum).map((category) => (
        <DetailedSustainabilityCategory
          key={category}
          category={category}
          {...props}
        />
      ))}
    </TitledAccordion>
  );
};

export default DetailedSustainability;
