import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CompanySectionIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 31 30">
      <g id="company-section-icon">
        <path
          stroke="currentColor"
          strokeWidth="2.14286"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.7501 1.07227L28.993 13.2866C29.211 13.4888 29.3846 13.7342 29.5027 14.0072C29.6208 14.2802 29.6807 14.5748 29.6787 14.8723V26.7866C29.6787 27.3549 29.4529 27.8999 29.051 28.3018C28.6492 28.7036 28.1041 28.9294 27.5358 28.9294H3.96438C3.39606 28.9294 2.85101 28.7036 2.44915 28.3018C2.04729 27.8999 1.82152 27.3549 1.82152 26.7866V14.8723C1.81951 14.5748 1.87944 14.2802 1.99749 14.0072C2.11555 13.7342 2.28915 13.4888 2.50724 13.2866L15.7501 1.07227ZM15.7501 1.07227V17.1437M1.82152 17.1437H29.6787M1.82152 23.0366H9.32152M22.1787 23.0366H29.6787M9.32152 28.9294V7.00798M22.1787 28.9294V7.00798"
        />
      </g>
    </SvgIcon>
  );
};

export default CompanySectionIcon;
