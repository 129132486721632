import React, { useEffect } from 'react';
import useModal from '../../../utils/modal/use-modal';
import SubIngredientsModal from './sub-ingredients-modal';
import { Ingredient } from '../../../models/ingredient';
import { useIngredientContext } from '../../../services/ingredient-context';
import SubIngredientsButton from './sub-ingredients-button';

interface SubIngredientsProps {
  mainIngredient: Ingredient;
}

const SubIngredients: React.FC<SubIngredientsProps> = ({ mainIngredient }) => {
  const {
    setMainIngredient,
    setIngredientsSequencesFlexibles,
    setHasChange,
    setShouldUpdateUpperContext,
  } = useIngredientContext();
  const hasSelectedSubIngredients =
    mainIngredient.subIngredients?.ingredients.some(
      (it) => it.isSelected === true
    );
  const version = hasSelectedSubIngredients ? 'edit' : 'add';
  const { openModal, closeModal, isModalOpen } = useModal();

  const handleOpenClick = () => {
    if (mainIngredient.subIngredients) {
      setIngredientsSequencesFlexibles(mainIngredient.subIngredients);
    }
    setShouldUpdateUpperContext(true);
    openModal();
  };
  const handleClose = () => {
    setShouldUpdateUpperContext(false);
    setHasChange(false);
    closeModal();
  };

  useEffect(() => {
    setMainIngredient(mainIngredient);
  }, []);

  return (
    <>
      {mainIngredient.isSelected && (
        <>
          <SubIngredientsButton onClick={handleOpenClick} version={version} />
          <SubIngredientsModal
            mainIngredient={mainIngredient}
            open={isModalOpen}
            onClose={handleClose}
          />
        </>
      )}
    </>
  );
};

export default SubIngredients;
