import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { Company } from '../../api';
import profileApi from './profile-api';

export const PROFILE_QUERY_KEY = 'profile';

const useGetProfile = (
  options?: UseQueryOptions<Company, unknown, Company, string[]>
): UseQueryResult<Company, unknown> => {
  return useQuery({
    queryKey: [PROFILE_QUERY_KEY],
    queryFn: () => profileApi.getProfile().then((result) => result.data),
    ...options,
  });
};

export default useGetProfile;
