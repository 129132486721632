import { Company } from '../../../api';
import { ProfileFormValue } from '../../../models/profile';
import {
  getCountryCodeForPrefix,
  getPrefixForCountryCode,
} from '../../../utils/countries/country-code-prefix';
import { getStripeTaxType } from '../../../utils/tax-id/tax-id-utils';

export const toProfileFormValue = (data: Company): ProfileFormValue => ({
  address: data.address,
  companyBrand: data.brand,
  city: data.city,
  country: data.country,
  companyLegalName: data.legalName,
  contactPerson: data.contact?.name,
  phoneNumber: data.contact?.phoneNumber,
  prefix: getCountryCodeForPrefix(data.contact?.phoneCountryCode),
  postalCode: data.postalCode,
  state: data.state,
  companyId: data.vatNumber,
  companyIdCountry: data.vatNumberCountry,
  companyUrl: data.website || '',
  marketingConsent: data.isMarketingMessageAllowed,
});

export const toCompany = (formValue: ProfileFormValue): Company => ({
  address: formValue.address,
  brand: formValue.companyBrand,
  city: formValue.city,
  contact: {
    name: formValue.contactPerson,
    phoneNumber: formValue.phoneNumber,
    phoneCountryCode: getPrefixForCountryCode(formValue.prefix),
  },
  country: formValue.country,
  legalName: formValue.companyLegalName,
  postalCode: formValue.postalCode,
  state: formValue.state,
  vatNumber: formValue.companyId,
  vatNumberCountry: formValue.companyIdCountry,
  stripeTaxType: getStripeTaxType(
    formValue.companyId,
    formValue.companyIdCountry
  ),
  website: formValue.companyUrl,
  isMarketingMessageAllowed: formValue.marketingConsent,
});
