import { Check } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { ICON_MEDIUM2_SIZE } from '../../constants/dimensions';
import FlexBox from '../flexbox/flexbox';

export interface CollapsibleBlockProps<T> extends Partial<AccordionProps> {
  icon?: ReactElement;
  title?: string;
  details?: ReactElement;
  isValid?: boolean;
  value: T;
}

const CollapsibleBlock = <T,>({
  icon,
  title,
  details,
  isValid,
  ...rest
}: CollapsibleBlockProps<T>) => {
  const { palette } = useTheme();
  const { expanded, disabled } = rest;
  const isTicked = isValid && !expanded;
  const iconColor = disabled
    ? palette.text.primary
    : palette.primaryVariations[700];

  return (
    <Accordion
      {...rest}
      sx={{
        boxShadow: 0,
        '&:before': { display: 'none' },
        '&.Mui-disabled': { backgroundColor: 'transparent' },
        '&.Mui-expanded': {
          my: 0,
        },
        ...rest.sx,
      }}
    >
      <AccordionSummary
        expandIcon={isTicked ? <Check /> : <ArrowDropDownIcon />}
        sx={{
          p: 0,
          '&.Mui-expanded, & > .Mui-expanded': { minHeight: 0, m: 0 },
          '& > .MuiAccordionSummary-content': {
            alignItems: 'center',
            '&.Mui-expanded': {
              my: 1.5,
            },
          },
          ' & > .MuiAccordionSummary-expandIconWrapper': {
            fontSize: ICON_MEDIUM2_SIZE,
            '& > svg': {
              fontSize: 'inherit',
              color: isTicked ? palette.success.main : iconColor,
              transform: 'rotate(0deg)',
            },
          },
        }}
      >
        <FlexBox color={iconColor} fontSize={ICON_MEDIUM2_SIZE} mr={1}>
          {icon}
        </FlexBox>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>{details}</AccordionDetails>
    </Accordion>
  );
};

export default CollapsibleBlock;
