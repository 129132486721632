import { Box } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import IngredientsSectionIcon from '../../../assets/icons/ingredients-section-icon';
import NutritionSectionIcon from '../../../assets/icons/nutrition-section-icon';
import PreviewSectionIcon from '../../../assets/icons/preview-section-icon';
import CollapsibleBlocks from '../../../components/collapsible-blocks/collapsible-blocks';
import {
  EditLabelSections,
  EditLabelSplitFormProps,
  LabelValue,
} from '../../../models/label';
import { useIngredientContext } from '../../../services/ingredient-context';
import { usePictogramContext } from '../../../services/pictogram-context';
import SectionHeader from '../section-header';
import IngredientSubsection from './ingredient-subsection';
import NutritionSubsection from './nutrition-subsection';
import PreviewSubsection from './preview-subsection';

interface IngredientsSectionProps extends EditLabelSplitFormProps {
  expandedBlocks: EditLabelSections[];
  handleBlockToggle: (
    value: EditLabelSections
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
  getValues: UseFormGetValues<LabelValue>;
  setValue: UseFormSetValue<LabelValue>;
}

const IngredientsSection: React.FC<IngredientsSectionProps> = ({
  expandedBlocks,
  handleBlockToggle,
  control,
  formState,
  setValue,
  watch,
}) => {
  const { t } = useTranslation();
  const { hasChange: hasIngredientsChange } = useIngredientContext();
  const { tempPictograms, hasCustomPictogramChange } = usePictogramContext();
  const hasUnsavedPictogramChange =
    tempPictograms.ALLERGEN.length > 0 || hasCustomPictogramChange.ALLERGEN;
  const hasUnsavedChanges =
    (formState.isDirty || hasIngredientsChange || hasUnsavedPictogramChange) &&
    [
      EditLabelSections.INGREDIENTS,
      EditLabelSections.PREVIEW,
      EditLabelSections.NUTRITION,
    ].includes(expandedBlocks[0]);

  return (
    <Box my={1}>
      <SectionHeader section="ingredients" withSaveButton={hasUnsavedChanges} />
      <CollapsibleBlocks
        sx={{
          bgcolor: 'inherit',
          mb: 1,
          boxShadow: 'none',
          position: 'inherit',
        }}
        blocks={[
          {
            title: t('edit-label.ingredients-section.subsections.ingredients'),
            icon: <IngredientsSectionIcon fontSize="inherit" />,
            details: <IngredientSubsection />,
            value: EditLabelSections.INGREDIENTS,
          },
          {
            title: t('edit-label.ingredients-section.subsections.preview'),
            icon: <PreviewSectionIcon fontSize="inherit" />,
            details: (
              <PreviewSubsection
                control={control}
                setAllergen={(value) =>
                  setValue('pictograms.allergen', value as never)
                }
              />
            ),
            value: EditLabelSections.PREVIEW,
          },
          {
            title: t('common.nutrition-declaration'),
            icon: <NutritionSectionIcon fontSize="inherit" />,
            details: (
              <NutritionSubsection
                control={control}
                formState={formState}
                watch={watch}
              />
            ),
            value: EditLabelSections.NUTRITION,
          },
        ]}
        expandedBlocks={expandedBlocks}
        handleBlockToggle={handleBlockToggle}
      />
    </Box>
  );
};

export default IngredientsSection;
