export default [
  {
    value: 'AF',
    label: 'Αφγανιστάν',
  },
  {
    value: 'AL',
    label: 'Αλβανία',
  },
  {
    value: 'DZ',
    label: 'Αλγερία',
  },
  {
    value: 'AS',
    label: 'Αμερικάνικη Σαμόα',
  },
  {
    value: 'AD',
    label: 'Ανδόρα',
  },
  {
    value: 'AO',
    label: 'Αγκόλα',
  },
  {
    value: 'AI',
    label: 'Αγγεία',
  },
  {
    value: 'AG',
    label: 'Αντίγκουα και Μπαρμπούντα',
  },
  {
    value: 'AR',
    label: 'Αργεντίνη',
  },
  {
    value: 'AM',
    label: 'Αρμενία',
  },
  {
    value: 'AW',
    label: 'Αρούμπα',
  },
  {
    value: 'AU',
    label: 'Αυστραλία',
  },
  {
    value: 'AT',
    label: 'Αυστρία',
  },
  {
    value: 'AZ',
    label: 'Αζερμπαϊτζάν',
  },
  {
    value: 'BS',
    label: 'Μπαχάμες',
  },
  {
    value: 'BH',
    label: 'Μπαχρέιν',
  },
  {
    value: 'BD',
    label: 'Μπαγκλαντές',
  },
  {
    value: 'BB',
    label: 'Μπαρμπάντος',
  },
  {
    value: 'BY',
    label: 'Λευκορωσία',
  },
  {
    value: 'BE',
    label: 'Βέλγιο',
  },
  {
    value: 'BZ',
    label: 'Μπελίζ',
  },
  {
    value: 'BJ',
    label: 'Μπενίν',
  },
  {
    value: 'BM',
    label: 'Βερμούδα',
  },
  {
    value: 'BT',
    label: 'Μπουτάν',
  },
  {
    value: 'BO',
    label: 'Βολιβία',
  },
  {
    value: 'BA',
    label: 'Βοσνία και Ερζεγοβίνη',
  },
  {
    value: 'BW',
    label: 'Γατάκια',
  },
  {
    value: 'BR',
    label: 'Βραζιλία',
  },
  {
    value: 'IO',
    label: 'Βρετανικές Παρθένοι Νήσοι',
  },
  {
    value: 'BN',
    label: 'Μπρουνάι Νταρουσαλάμ',
  },
  {
    value: 'BG',
    label: 'Βουλγαρία',
  },
  {
    value: 'BF',
    label: 'Μπουρκίνα Φάσο',
  },
  {
    value: 'BI',
    label: 'Μπουρούντι',
  },
  {
    value: 'CV',
    label: 'Κάμπο',
  },
  {
    value: 'KH',
    label: 'Καμπότζη',
  },
  {
    value: 'CM',
    label: 'Καμερούν',
  },
  {
    value: 'CA',
    label: 'Καναδάς',
  },
  {
    value: 'KY',
    label: 'Νησιά Κέιμαν',
  },
  {
    value: 'CF',
    label: 'Δημοκρατία Κεντρικής Αφρικής',
  },
  {
    value: 'TD',
    label: 'Τσαντ',
  },
  {
    value: 'CL',
    label: 'χιλή',
  },
  {
    value: 'CN',
    label: 'Κίνα',
  },
  {
    value: 'CO',
    label: 'Κολομβία',
  },
  {
    value: 'KM',
    label: 'Κομπόριο',
  },
  {
    value: 'CG',
    label: 'Κογκό',
  },
  {
    value: 'CK',
    label: 'Νησιά μαγειρικής',
  },
  {
    value: 'CR',
    label: 'Κόστα Ρίκα',
  },
  {
    value: 'CI',
    label: 'Ακτής της Ελεφαντοστού',
  },
  {
    value: 'HR',
    label: 'Κροατία',
  },
  {
    value: 'CU',
    label: 'Κούβα',
  },
  {
    value: 'CU',
    label: 'Κουράσω',
  },
  {
    value: 'CY',
    label: 'Κύπρος',
  },
  {
    value: 'CZ',
    label: 'Τσεχική Δημοκρατία',
  },
  {
    value: 'CZ',
    label: 'Λαϊκή Δημοκρατία της Κορέας',
  },
  {
    value: 'CD',
    label: 'Λαϊκή Δημοκρατία του Κονγκό',
  },
  {
    value: 'DK',
    label: 'Δανία',
  },
  {
    value: 'DK',
    label: 'Ντιέγκο Γκαρσία',
  },
  {
    value: 'DJ',
    label: 'Βιβλία',
  },
  {
    value: 'DM',
    label: 'Δομινίκα',
  },
  {
    value: 'DO',
    label: 'Δομινικανή Δημοκρατία',
  },
  {
    value: 'EC',
    label: 'Εκουαδόρ',
  },
  {
    value: 'EG',
    label: 'Αίγυπτος',
  },
  {
    value: 'SV',
    label: 'Ελ Σαλβαδόρ',
  },
  {
    value: 'GQ',
    label: 'Ισημερινή Γουινέα',
  },
  {
    value: 'ER',
    label: 'Ερυθραία',
  },
  {
    value: 'EE',
    label: 'Εσθονία',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Αιθιοπία',
  },
  {
    value: 'FK',
    label: 'Νησιά Φώκλαντ',
  },
  {
    value: 'FO',
    label: 'Νήσοι Φερόες',
  },
  {
    value: 'FJ',
    label: 'Φίτζι',
  },
  {
    value: 'FI',
    label: 'Φινλανδία',
  },
  {
    value: 'FR',
    label: 'Γαλλία',
  },
  {
    value: 'FR',
    label: 'Γαλλικά τμήματα και εδάφη στον Ινδικό Ωκεανό',
  },
  {
    value: 'GF',
    label: 'Γαλλική Γουιάνα',
  },
  {
    value: 'PF',
    label: 'Γαλλική Πολυνησία',
  },
  {
    value: 'GA',
    label: 'Γκαμπόν',
  },
  {
    value: 'GM',
    label: 'Γκάμπια',
  },
  {
    value: 'GE',
    label: 'Γεωργία',
  },
  {
    value: 'DE',
    label: 'Γερμανία',
  },
  {
    value: 'GH',
    label: 'Γκάνα',
  },
  {
    value: 'GI',
    label: 'Γιβραλτάρ',
  },
  {
    value: 'GR',
    label: 'Ελλάδα',
  },
  {
    value: 'GL',
    label: 'Γροιλανδία',
  },
  {
    value: 'GD',
    label: 'Γρενοδά',
  },
  {
    value: 'GP',
    label: 'Γουαδελούπη',
  },
  {
    value: 'GU',
    label: 'Γκουάμ',
  },
  {
    value: 'GT',
    label: 'Γουατεμάλα',
  },
  {
    value: 'GG',
    label: 'Γκινέα',
  },
  {
    value: 'GN',
    label: 'Γουινέα-Μπισσάου',
  },
  {
    value: 'GY',
    label: 'Γουιάνα',
  },
  {
    value: 'HT',
    label: 'Αΐτη',
  },
  {
    value: 'HN',
    label: 'Ονδούρα',
  },
  {
    value: 'HK',
    label: 'Χονγκ Κονγκ',
  },
  {
    value: 'HU',
    label: 'Ουγγαρία',
  },
  {
    value: 'IS',
    label: 'Ισλανδία',
  },
  {
    value: 'IN',
    label: 'Ινδία',
  },
  {
    value: 'ID',
    label: 'Ινδονησία',
  },
  {
    value: 'IR',
    label: 'Ιράν',
  },
  {
    value: 'IQ',
    label: 'Ιράκ',
  },
  {
    value: 'IE',
    label: 'Ιρλανδία',
  },
  {
    value: 'IL',
    label: 'Ισραήλ',
  },
  {
    value: 'IT',
    label: 'Ιταλία',
  },
  {
    value: 'JM',
    label: 'Ιαμαϊκή',
  },
  {
    value: 'JP',
    label: 'Ιαπωνία',
  },
  {
    value: 'JO',
    label: 'Ιορδανία',
  },
  {
    value: 'KZ',
    label: 'Καζακστάν',
  },
  {
    value: 'KE',
    label: 'Κενύα',
  },
  {
    value: 'KI',
    label: 'Κιριμπάτι',
  },
  {
    value: 'KR',
    label: 'Κορέα',
  },
  {
    value: 'XK',
    label: 'Κοσσυφοπέδιο',
  },
  {
    value: 'KW',
    label: 'Κουβέιτ',
  },
  {
    value: 'KG',
    label: 'Κιργιζία',
  },
  {
    value: 'LA',
    label: 'Λαϊκή Λαϊκή Δημοκρατία',
  },
  {
    value: 'LV',
    label: 'Λετονία',
  },
  {
    value: 'LB',
    label: 'Λίβανος',
  },
  {
    value: 'LS',
    label: 'Λεσόθο',
  },
  {
    value: 'LR',
    label: 'Λιβερία',
  },
  {
    value: 'LY',
    label: 'Λιβύη',
  },
  {
    value: 'LI',
    label: 'Λιχτενστάιν',
  },
  {
    value: 'LT',
    label: 'Λιθουανία',
  },
  {
    value: 'LU',
    label: 'Λουξεμβούργο',
  },
  {
    value: 'MO',
    label: 'Μακάο',
  },
  {
    value: 'MG',
    label: 'Μαδαγασκάρη',
  },
  {
    value: 'MW',
    label: 'Μαλάουι',
  },
  {
    value: 'MY',
    label: 'Μαλαισία',
  },
  {
    value: 'MV',
    label: 'Μαλδίβες',
  },
  {
    value: 'ML',
    label: 'Μάλι',
  },
  {
    value: 'MT',
    label: 'Μάλτα',
  },
  {
    value: 'MH',
    label: 'Νήσοι Μάρσαλ',
  },
  {
    value: 'MQ',
    label: 'Μαρτυρία',
  },
  {
    value: 'MR',
    label: 'Μαυριτανία',
  },
  {
    value: 'MU',
    label: 'Μαυρίκιος',
  },
  {
    value: 'MX',
    label: 'Μεξικό',
  },
  {
    value: 'FM',
    label: 'Μικρονία',
  },
  {
    value: 'MD',
    label: 'Μολδαβία',
  },
  {
    value: 'MC',
    label: 'Μονακό',
  },
  {
    value: 'MN',
    label: 'Μογγολία',
  },
  {
    value: 'ME',
    label: 'Μαυροβούνιο',
  },
  {
    value: 'MS',
    label: 'Μοντσέρρατ',
  },
  {
    value: 'MA',
    label: 'Μαρόκο',
  },
  {
    value: 'MZ',
    label: 'Μοζαμβίκη',
  },
  {
    value: 'MM',
    label: 'Μυανμάρ',
  },
  {
    value: 'NA',
    label: 'Ναμίμπια',
  },
  {
    value: 'NR',
    label: 'Ναυρό',
  },
  {
    value: 'NP',
    label: 'Νεπάλ',
  },
  {
    value: 'NL',
    label: 'Ολλανδία',
  },
  {
    value: 'NC',
    label: 'Νέα Καληδονία',
  },
  {
    value: 'NZ',
    label: 'Νέα Ζηλανδία',
  },
  {
    value: 'NI',
    label: 'Νικαράγουα',
  },
  {
    value: 'NE',
    label: 'Νίγηρας',
  },
  {
    value: 'NG',
    label: 'Νιγηρία',
  },
  {
    value: 'NU',
    label: 'Νιφάδα',
  },
  {
    value: 'NF',
    label: 'Νησί Νόρφολκ',
  },
  {
    value: 'NF',
    label: 'Βόρεια Μακεδονία',
  },
  {
    value: 'MP',
    label: 'Βορεια Νησια Μαριανα',
  },
  {
    value: 'NO',
    label: 'Νορβηγία',
  },
  {
    value: 'OM',
    label: 'Οθμάν',
  },
  {
    value: 'PK',
    label: 'Πακιστάν',
  },
  {
    value: 'PW',
    label: 'Παλάμης',
  },
  {
    value: 'PA',
    label: 'Παναμάς',
  },
  {
    value: 'PG',
    label: 'Παπούα Νέα Γουινέα',
  },
  {
    value: 'PY',
    label: 'Παραγουάη',
  },
  {
    value: 'PE',
    label: 'Περού',
  },
  {
    value: 'PH',
    label: 'Φιλιππίνες',
  },
  {
    value: 'PL',
    label: 'Πολωνία',
  },
  {
    value: 'PT',
    label: 'Πορτογαλία',
  },
  {
    value: 'PR',
    label: 'Πουέρτο Ρίκο',
  },
  {
    value: 'QA',
    label: 'Κατάρ',
  },
  {
    value: 'RO',
    label: 'Ρουμανία',
  },
  {
    value: 'RU',
    label: 'Ρωσική Ομοσπονδία',
  },
  {
    value: 'RW',
    label: 'Ρουάντα',
  },
  {
    value: 'SH',
    label: 'Η Αγία Ελένη, η Ανάληψη και ο Τριστάν Da Cunha',
  },
  {
    value: 'KN',
    label: 'Άγιος Kitts και Nevis',
  },
  {
    value: 'LC',
    label: 'Άγιος Λουκία',
  },
  {
    value: 'PM',
    label: 'Άγιος Pierre και Miquelon',
  },
  {
    value: 'VC',
    label: 'Άγιος Βικέντιος και Γρεναδίνες',
  },
  {
    value: 'WS',
    label: 'Σαμόα',
  },
  {
    value: 'SM',
    label: 'Σαν Μαρίνο',
  },
  {
    value: 'ST',
    label: 'Sao Tome και Principe',
  },
  {
    value: 'SA',
    label: 'Σαουδική Αραβία',
  },
  {
    value: 'SN',
    label: 'Σενεγάλη',
  },
  {
    value: 'RS',
    label: 'Σερβία',
  },
  {
    value: 'SC',
    label: 'Σεϋχέλλες',
  },
  {
    value: 'SL',
    label: 'Σιέρρα Λεόνε',
  },
  {
    value: 'SG',
    label: 'Σιγκαπούρη',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Σλοβακία',
  },
  {
    value: 'SI',
    label: 'Σλοβενία',
  },
  {
    value: 'SB',
    label: 'Νησιά του Σολομώντα',
  },
  {
    value: 'SO',
    label: 'Σομαλία',
  },
  {
    value: 'ZA',
    label: 'Νότια Αφρική',
  },
  {
    value: 'SS',
    label: 'Νότιο Σουδάν',
  },
  {
    value: 'ES',
    label: 'Ισπανία',
  },
  {
    value: 'LK',
    label: 'Σρι Λάνκα',
  },
  {
    value: 'SD',
    label: 'Σουδάν',
  },
  {
    value: 'SR',
    label: 'Σουρινό',
  },
  {
    value: 'SE',
    label: 'Σουηδία',
  },
  {
    value: 'CH',
    label: 'Ελβετία',
  },
  {
    value: 'SY',
    label: 'Συριακή Αραβική Δημοκρατία',
  },
  {
    value: 'TW',
    label: 'Ταϊβάν',
  },
  {
    value: 'TJ',
    label: 'Τατζικιστάν',
  },
  {
    value: 'TZ',
    label: 'Τανζανία',
  },
  {
    value: 'TH',
    label: 'Ταϊλάνδη',
  },
  {
    value: 'TL',
    label: 'Τιμόρ-Λέστερ',
  },
  {
    value: 'TG',
    label: 'Τόγκο Δημοκρατία',
  },
  {
    value: 'TK',
    label: 'Τακέλαου',
  },
  {
    value: 'TO',
    label: 'Τόνγκα',
  },
  {
    value: 'TT',
    label: 'Τρινιντάντ και Τομπάγκο',
  },
  {
    value: 'TN',
    label: 'Τυνησία',
  },
  {
    value: 'TR',
    label: 'δημοκρατία της Τουρκίας',
  },
  {
    value: 'TM',
    label: 'Τουρκμενιστάν',
  },
  {
    value: 'TC',
    label: 'Τούρκοι και καρύδες',
  },
  {
    value: 'TV',
    label: 'Τούβαλου',
  },
  {
    value: 'UG',
    label: 'Ουγκάντα',
  },
  {
    value: 'UA',
    label: 'Ουκρανία',
  },
  {
    value: 'AE',
    label: 'Ηνωμένα Αραβικά Εμιράτα',
  },
  {
    value: 'GB',
    label: 'Ηνωμένο Βασίλειο',
  },
  {
    value: 'US',
    label: 'Ηνωμένες Πολιτείες',
  },
  {
    value: 'US',
    label: 'Παρθένοι Νήσοι Ηνωμένων Πολιτειών',
  },
  {
    value: 'UY',
    label: 'Ουρουγουάη',
  },
  {
    value: 'UZ',
    label: 'Ουζμπεκιστάν',
  },
  {
    value: 'VU',
    label: 'Βανουάτου',
  },
  {
    value: 'VE',
    label: 'Βενεζουέλα',
  },
  {
    value: 'VN',
    label: 'Βιετνάμ',
  },
  {
    value: 'WF',
    label: 'Wallis και Futuna',
  },
  {
    value: 'YE',
    label: 'Γέμενη',
  },
  {
    value: 'ZM',
    label: 'Ζάμπια',
  },
  {
    value: 'ZM',
    label: 'Ζιμπάμπουε',
  },
];
