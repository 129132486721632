import { yupResolver } from '@hookform/resolvers/yup';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextFieldWithController from '../../components/text-field/text-field-with-controller';
import { useAuthContext } from '../../services/auth-context';
import emailSchema from '../../utils/schemas/email-schema';

export interface ForgotPasswordFormValue {
  email: string;
}

interface ForgotPasswordFormProps {
  onEmailSending: () => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  onEmailSending,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { forgotPassword } = useAuthContext();
  const [isUserNotFoundError, setIsUserNotFoundError] = useState(false);
  const defaultValues = {
    email: '',
  };
  const { handleSubmit, control, formState, watch } =
    useForm<ForgotPasswordFormValue>({
      mode: 'onSubmit',
      defaultValues,
      resolver: yupResolver(emailSchema()),
    });
  const password = watch('email');
  const { isSubmitted, errors } = formState;
  const isEmailError = isSubmitted && !!errors.email;
  const onSubmitSuccess = (data: ForgotPasswordFormValue) => {
    forgotPassword(data.email, language).then(onEmailSending, (err) => {
      if (err.code === 'UserNotFoundException') {
        setIsUserNotFoundError(true);
      }
    });
  };

  useEffect(() => {
    if (isUserNotFoundError) setIsUserNotFoundError(false);
  }, [password]);

  return (
    <form onSubmit={handleSubmit(onSubmitSuccess)}>
      <TextFieldWithController
        control={control}
        name="email"
        textFieldProps={{
          label: t('auth.email'),
          error: isEmailError || isUserNotFoundError,
          fullWidth: true,
          helperText:
            (isEmailError && t('auth.email-error')) ||
            (isUserNotFoundError && t('forgot-password.user-not-found-error')),
        }}
      />
      <Button
        variant="contained"
        fullWidth
        endIcon={<ChevronRightIcon />}
        type="submit"
        sx={{ mt: 4 }}
      >
        {t('forgot-password.send-email')}
      </Button>
    </form>
  );
};

export default ForgotPasswordForm;
