import { Delete } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ConfirmationModal from '../../../components/modal/confirmation-modal';
import useDeleteLabels from '../../../data/wine-label/use-delete-labels';
import { useSnackbar } from '../../../services/snackbar-context';
import useModal from '../../../utils/modal/use-modal';

interface DeleteLabelButtonProps {
  onDelete: () => void;
}

const DeleteLabelButton: React.FC<DeleteLabelButtonProps> = ({ onDelete }) => {
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const { id } = useParams();
  const { isModalOpen, openModal, closeModal } = useModal();
  const deleteLabels = useDeleteLabels();
  const deleteLabel = () => {
    if (id) {
      deleteLabels.mutate({
        ids: [+id],
        onSuccess: () => {
          setSnackbar({
            message: t('common.delete-label-success'),
          });
          onDelete();
        },
      });
    }
  };

  return (
    <>
      <Button
        endIcon={<Delete />}
        size="small"
        onClick={openModal}
        color="error"
        variant="text"
      >
        {t('edit-label.delete-label')}
      </Button>
      <ConfirmationModal
        open={isModalOpen}
        onConfirm={deleteLabel}
        onCancel={closeModal}
        hideOnChoice={false}
        onClose={closeModal}
        labels={{
          message: t('common.delete-label-message'),
        }}
      />
    </>
  );
};

export default DeleteLabelButton;
