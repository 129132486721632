import { KeyboardArrowLeft } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FlexBox from '../../../components/flexbox/flexbox';
import { ROUTES } from '../../../constants/routes';
import DeleteLabelButton from './delete-label-button';

interface EditLabelHeaderProps {
  onDeleteLabel: () => void;
}

const EditLabelHeader: React.FC<EditLabelHeaderProps> = ({ onDeleteLabel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToLabels = () => navigate(ROUTES.LABELS);

  return (
    <FlexBox justifyContent="space-between">
      <Button
        startIcon={<KeyboardArrowLeft />}
        size="small"
        onClick={navigateToLabels}
        color="primary"
        variant="outlined"
      >
        {t('edit-label.to-labels')}
      </Button>
      <DeleteLabelButton onDelete={onDeleteLabel} />
    </FlexBox>
  );
};

export default EditLabelHeader;
