import { NutritionDeclaration, WineLabelViewWineColourEnum } from '../../api';

export interface WineCategoryType {
  id: string;
  colour: WineLabelViewWineColourEnum;
  nutritions: NutritionDeclaration;
}

const WineCategory: WineCategoryType[] = [
  {
    id: 'general',
    colour: WineLabelViewWineColourEnum.Rose,
    nutritions: {
      energyKcal: '16',
      protein: '0.4',
      fat: '0',
      carbohydrate: '3.9',
      sugar: '3.9',
      salt: '0.005',
    },
  },
  {
    id: 'general',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '10.7',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.6',
      sugar: '0.6',
      salt: '0.004',
      saturatedFat: '0',
    },
  },
  {
    id: 'barbera',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '10.7',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.8',
    },
  },
  {
    id: 'burgundy',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '13.9',
      protein: '0.1',
      fat: '0',
      carbohydrate: '3.7',
    },
  },
  {
    id: 'cabernet_franc',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '8.7',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.4',
    },
  },
  {
    id: 'cabernet_sauvignon',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '9.4',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.6',
    },
  },
  {
    id: 'carignane',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '9.1',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.4',
    },
  },
  {
    id: 'claret',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '11.5',
      protein: '0.1',
      fat: '0',
      carbohydrate: '3',
    },
  },
  {
    id: 'gamay',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '9.3',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.4',
    },
  },
  {
    id: 'lemberger',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '8.5',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.4',
    },
  },
  {
    id: 'merlot',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '8.7',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.5',
      sugar: '0.6',
      salt: '0.004',
    },
  },
  {
    id: 'mouvedre',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '9.7',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.6',
    },
  },
  {
    id: 'petite_sirah',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '10.1',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.7',
    },
  },
  {
    id: 'pinot_noir',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '9.1',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.3',
    },
  },
  {
    id: 'sangiovese',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '9.8',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.6',
    },
  },
  {
    id: 'syrah',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '9.4',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.6',
    },
  },
  {
    id: 'zinfandel',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '10.4',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.8',
    },
  },
  {
    id: 'sangria',
    colour: WineLabelViewWineColourEnum.Red,
    nutritions: {
      energyKcal: '32.8',
      protein: '0',
      fat: '0',
      carbohydrate: '8.4',
      sugar: '7.3',
      salt: '0.01',
      saturatedFat: '0',
    },
  },
  {
    id: 'general',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '9.8',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.6',
      sugar: '1',
      salt: '0.005',
      saturatedFat: '0',
    },
  },
  {
    id: 'chardonnay',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '8.9',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.1',
      sugar: '1',
      salt: '0.005',
    },
  },
  {
    id: 'chenin_blanc',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '12.8',
      protein: '0.1',
      fat: '0',
      carbohydrate: '3.3',
    },
  },
  {
    id: 'fume_blanc',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '8.3',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.2',
    },
  },
  {
    id: 'gewurztraminer',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '10.3',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2.6',
    },
  },
  {
    id: 'late_harvest',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '54.6',
      protein: '0.1',
      fat: '0',
      carbohydrate: '14',
    },
  },
  {
    id: 'gewurztraminer_late_harvest',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '46.9',
      protein: '0.1',
      fat: '0',
      carbohydrate: '11.8',
    },
  },
  {
    id: 'muller_thurgau',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '12.8',
      protein: '0.1',
      fat: '0',
      carbohydrate: '3.5',
    },
  },
  {
    id: 'muscat',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '20.7',
      protein: '0.1',
      fat: '0',
      carbohydrate: '5.3',
    },
  },
  {
    id: 'pinot_blanc',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '6.5',
      protein: '0.1',
      fat: '0',
      carbohydrate: '1.9',
    },
  },
  {
    id: 'pinot_gris',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '7.8',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2',
    },
  },
  {
    id: 'riesling',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '13.6',
      protein: '0.1',
      fat: '0',
      carbohydrate: '3.8',
    },
  },
  {
    id: 'sauvignon_blanc',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '7.2',
      protein: '0.1',
      fat: '0',
      carbohydrate: '2',
    },
  },
  {
    id: 'semillon',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '11.3',
      protein: '0.1',
      fat: '0',
      carbohydrate: '3.1',
    },
  },
  {
    id: 'sangria',
    colour: WineLabelViewWineColourEnum.White,
    nutritions: {
      energyKcal: '33.2',
      protein: '0',
      fat: '0',
      carbohydrate: '8.4',
      sugar: '7.4',
      salt: '0.001',
      saturatedFat: '0',
    },
  },
];

export default WineCategory;
