import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import backgroundImage from '../../assets/images/verification-background.svg';
import FlexBoxColumn from '../../components/flexbox/flex-box-column';
import TemplateWithCard from '../../components/screen-template/template-with-card';
import { useAuthContext } from '../../services/auth-context';
import CodeVerifier from './code-verifier';
import Footer from './footer';
import Header from './header';

const VerifyEmail: React.FC = () => {
  const { t } = useTranslation();
  const { currentAuthenticatedUser } = useAuthContext();
  const [email, setEmail] = React.useState<string>();

  useEffect(() => {
    currentAuthenticatedUser().then((user) => {
      user.getUserAttributes((_, attributes) => {
        const attribute = attributes?.find((it) => it.getName() === 'email');
        if (attribute) {
          setEmail(attribute.getValue());
        }
      });
    });
  }, [currentAuthenticatedUser]);

  return (
    <TemplateWithCard
      backgroundProps={{ imageSource: backgroundImage }}
      cardContent={
        <>
          <Header />
          <FlexBoxColumn sx={{ my: 2.5, alignItems: 'center' }}>
            <Typography>{t('email-verification.message1')}</Typography>
            <Typography sx={{ fontWeight: 700 }}>{email}</Typography>
            <Typography>{t('email-verification.message2')}</Typography>
          </FlexBoxColumn>
          <CodeVerifier />
          <Footer />
        </>
      }
    />
  );
};

export default VerifyEmail;
