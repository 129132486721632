interface FileNameParts {
  baseName: string;
  extension: string;
}

const splitFileName = (filename: string): FileNameParts => {
  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex === -1) {
    // No extension found
    return {
      baseName: filename,
      extension: '',
    };
  }

  const baseName = filename.substring(0, lastDotIndex);
  const extension = filename.substring(lastDotIndex + 1);

  return {
    baseName,
    extension,
  };
};

export default splitFileName;
