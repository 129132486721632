import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  CustomIngredient,
  FlexibleIngredientGroup,
  Ingredient as IngredientDto,
  IngredientGroupOrder,
  IngredientGroupOrderIngredientGroupEnum,
  PictogramsDtoDetailedSustainabilityEnum,
  PictogramsDtoResponsibleConsumptionEnum,
  PictogramsDtoSustainabilityEnum,
  QrCode,
  WineLabelCustomPictogramView,
  WineLabelView,
} from '../../api';
import { defaultIngredientGroups } from '../../constants/ingredients';
import { LANGUAGES, euCountryLanguageMapper } from '../../constants/languages';
import { LabelValue } from '../../models/label';
import { useIngredientContext } from '../../services/ingredient-context';
import { transformGroupName } from '../../utils/ingredient/ingredient-utils';
import { defaultCompanySubsectionValues } from './general-info-section/company-subsection';
import { defaultPictogramSubsectionValues } from './general-info-section/pictogram-subsection';
import { defaultProductSubsectionValues } from './general-info-section/product-subsection';
import { defaultNutritionSubsectionValues } from './ingredients-section/nutrition-subsection';
import {
  Ingredient,
  SequencedIngredientOrGroup,
} from '../../models/ingredient';

export const toLabelValue = (data: WineLabelView): LabelValue => ({
  ...defaultProductSubsectionValues,
  ...defaultPictogramSubsectionValues,
  ...Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(data).filter(([_, v]) => v !== null)
  ),
  company: {
    ...defaultCompanySubsectionValues.company,
    ...Object.fromEntries(
      Object.entries(data.company || {}).filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, v]) => v !== null
      )
    ),
  },
  nutritionDeclaration: {
    ...defaultNutritionSubsectionValues.nutritionDeclaration,
    ...Object.fromEntries(
      Object.entries(data.nutritionDeclaration || {}).filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, v]) => v !== null
      )
    ),
  },
  pictograms: {
    allergen: data.pictograms?.allergen,
    origin:
      data.pictograms?.origin ||
      defaultPictogramSubsectionValues.pictograms.origin,
    sustainability: Array.from(data.pictograms?.sustainability || []).length
      ? Array.from(data.pictograms?.sustainability || [])
      : defaultPictogramSubsectionValues.pictograms.sustainability,
    responsibleConsumption: Array.from(
      data.pictograms?.responsibleConsumption || []
    ).length
      ? Array.from(data.pictograms?.responsibleConsumption || [])
      : defaultPictogramSubsectionValues.pictograms.responsibleConsumption,
    detailedSustainability: Array.from(
      data.pictograms?.detailedSustainability || []
    ).length
      ? Array.from(data.pictograms?.detailedSustainability || [])
      : defaultPictogramSubsectionValues.pictograms.detailedSustainability,
  },
  qrCode: {
    topLanguageCode:
      !data.qrCode?.topLanguageCode && !data.qrCode?.bottomLanguageCode
        ? data.company?.countryCode &&
          Object.keys(euCountryLanguageMapper).includes(
            data.company?.countryCode
          )
          ? euCountryLanguageMapper[
              data.company?.countryCode as keyof typeof euCountryLanguageMapper
            ]
          : LANGUAGES.en
        : undefined,
    ...(data.qrCode
      ? Object.fromEntries(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          Object.entries(data.qrCode).filter(([_, v]) => v != null)
        )
      : {}),
  },
});

export const useToWineLabelView = () => {
  const { t } = useTranslation();
  const {
    ingredients: ingredientsFromContext,
    sequencedIngredientsAndGroups: sequencedIngredientsAndGroupsFromContext,
    flexibleGroups: flexibleGroupsFromContext,
  } = useIngredientContext();

  const getTranslation = async (key: string, languageCode?: string) => {
    if (!languageCode) return undefined;

    await i18next.loadLanguages([languageCode]);

    return t(`qr-code-title.${key}`, { lng: languageCode });
  };

  const getNutritionIngredientsText = async (
    position: 'top' | 'bottom',
    qrCode?: QrCode
  ) => {
    const languageCode =
      position === 'top' ? qrCode?.topLanguageCode : qrCode?.bottomLanguageCode;
    const nutritionText = await getTranslation('nutrition', languageCode);
    const ingredientsText = await getTranslation('ingredients', languageCode);

    return languageCode
      ? `${nutritionText}${
          qrCode?.withRecycling ? ',' : ' &'
        } ${ingredientsText}`
      : undefined;
  };

  const getQrCodeTitle = (first?: string, second?: string) => {
    if (first) {
      return second ? `${first} & ${second}` : first;
    }
    return second ? second : undefined;
  };

  const getQrCodeTitles = async (qrCode?: QrCode) => {
    const topNutritionIngredients = qrCode?.withNutrition
      ? await getNutritionIngredientsText('top', qrCode)
      : undefined;
    const topRecycling = qrCode?.withRecycling
      ? await getTranslation('recycling', qrCode?.topLanguageCode)
      : undefined;
    const bottomNutritionIngredients = qrCode?.withNutrition
      ? await getNutritionIngredientsText('bottom', qrCode)
      : undefined;
    const bottomRecycling = qrCode?.withRecycling
      ? await getTranslation('recycling', qrCode?.bottomLanguageCode)
      : undefined;
    return {
      topText: getQrCodeTitle(topNutritionIngredients, topRecycling),
      bottomText: getQrCodeTitle(bottomNutritionIngredients, bottomRecycling),
    };
  };

  const getIngredientDto = (props: {
    ingredients: Ingredient[];
    sequencedIngredientsAndGroups: SequencedIngredientOrGroup[];
    flexibleGroups: IngredientGroupOrderIngredientGroupEnum[];
  }) => {
    const { ingredients, sequencedIngredientsAndGroups, flexibleGroups } =
      props;
    let ingredient: IngredientDto = {};
    const selectedIngredients = ingredients.filter((it) => it.isSelected);
    const sequencedIngredients = sequencedIngredientsAndGroups.filter(
      (it) => typeof it.ingredient !== 'undefined'
    );
    const sequencedIngredientGroups = sequencedIngredientsAndGroups.filter(
      (it) => typeof it.group !== 'undefined'
    );

    defaultIngredientGroups.forEach((group) => {
      ingredient = {
        ...ingredient,
        [transformGroupName(group)]: selectedIngredients
          .filter((it) => it.ingredientGroup === group && !it.isCustom)
          .map((it) => ({
            ...it,
            sequenceNumber: sequencedIngredients.find(
              (item) =>
                item.ingredient?.name === it.name &&
                item.ingredient?.ingredientGroup === group
            )?.sequenceNumber,
            ...(it.subIngredients
              ? {
                  subIngredient: getIngredientDto({
                    ingredients: it.subIngredients.ingredients,
                    sequencedIngredientsAndGroups:
                      it.subIngredients.sequencedIngredientsAndGroups,
                    flexibleGroups: it.subIngredients.flexibleGroups,
                  }),
                }
              : {}),
          })),
      };
    });

    const customIngredients = selectedIngredients
      .filter((it) => it.isCustom)
      .map((it) => ({
        ...it,
        sequenceNumber: sequencedIngredients.find(
          (item) =>
            item.ingredient?.name === it.name &&
            item.ingredient?.ingredientGroup === it.ingredientGroup
        )?.sequenceNumber,
      })) as unknown as Set<CustomIngredient>;

    ingredient = {
      ...ingredient,
      customIngredients,
      ingredientGroupOrders: sequencedIngredientGroups.map((it) => ({
        ingredientGroup: it.group?.name,
        sequenceNumber: it.sequenceNumber,
      })) as unknown as Set<IngredientGroupOrder>,
      flexibleIngredientGroupList: flexibleGroups.map((it) => ({
        contains: true,
        ingredientGroup: it,
      })) as unknown as Set<FlexibleIngredientGroup>,
    };

    return ingredient;
  };

  const toWineLabelView = async (
    data: LabelValue,
    customPictograms: WineLabelCustomPictogramView[],
    uploadedPictograms: WineLabelCustomPictogramView[]
  ): Promise<WineLabelView> => {
    const qrCodeTitles = await getQrCodeTitles(data.qrCode);

    return {
      ...data,
      harvestYear:
        data.harvestYear === '' ? undefined : Number(data.harvestYear),
      ingredient: getIngredientDto({
        ingredients: ingredientsFromContext,
        sequencedIngredientsAndGroups: sequencedIngredientsAndGroupsFromContext,
        flexibleGroups: flexibleGroupsFromContext,
      }),
      pictograms: {
        ...data.pictograms,
        responsibleConsumption: (data.pictograms?.responsibleConsumption ||
          []) as unknown as Set<PictogramsDtoResponsibleConsumptionEnum>,
        sustainability: (data.pictograms?.sustainability ||
          []) as unknown as Set<PictogramsDtoSustainabilityEnum>,
        detailedSustainability: (data.pictograms?.detailedSustainability ||
          []) as unknown as Set<PictogramsDtoDetailedSustainabilityEnum>,
      },
      nutritionDeclaration: {
        ...data.nutritionDeclaration,
        energyKcal:
          data.nutritionDeclaration?.energyKcal !== undefined
            ? data.nutritionDeclaration.energyKcal
            : undefined,
        energyKj:
          data.nutritionDeclaration?.energyKj !== undefined
            ? data.nutritionDeclaration.energyKj
            : undefined,
      },
      company: {
        ...data.company,
        producer:
          data.company?.producer?.name || data.company?.producer?.address
            ? data.company.producer
            : undefined,
        importer:
          data.company?.importer?.name || data.company?.importer?.address
            ? data.company.importer
            : undefined,
      },
      customPictograms: [
        ...customPictograms
          .filter((it) => it.isSelected)
          .map((it) => ({ id: it.id })),
        ...uploadedPictograms
          .filter((it) => it.isSelected)
          .map((it) => ({
            id: it.id,
          })),
      ] as unknown as Set<WineLabelCustomPictogramView>,
      qrCode: {
        ...data.qrCode,
        ...qrCodeTitles,
      },
    };
  };

  return { toWineLabelView };
};
