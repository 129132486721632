import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Merchant } from '../../../api';
import TitledAccordion from '../../../components/accordion/titled-accordion';
import MerchantSelect from '../../../components/select/merchant-select';
import useRetrieveMerchants from '../../../data/merchants/use-retrieve-merchants';
import { EditLabelSplitFormProps } from '../../../models/label';

const CompanyAdvancedSettings: React.FC<EditLabelSplitFormProps> = ({
  control,
  formState,
}) => {
  const { t } = useTranslation();
  const { isSubmitted, errors } = formState;
  const [previousVendor, setPreviousVendor] = useState(false);
  const [previousImporter, setPreviousImporter] = useState(false);
  const { data } = useRetrieveMerchants();
  const producers = Array.from(data?.producers || []);
  const hasProducers = !!producers.length;
  const importers = Array.from(data?.importers || []);
  const hasImporters = !!importers.length;

  return (
    <TitledAccordion>
      {hasProducers && (
        <FormControlLabel
          sx={{ my: 2 }}
          control={
            <Checkbox
              sx={{
                lineHeight: 0.7,
              }}
              checked={previousVendor}
              onChange={(event, checked) => setPreviousVendor(checked)}
            />
          }
          label={t('edit-label.use-previous-vendor')}
        />
      )}
      {previousVendor ? (
        <Controller
          control={control}
          name="company.producer"
          render={({ field }) => (
            <MerchantSelect
              textFieldProps={{
                label: t('common.vendor'),
                placeholder: t('common.select-one'),
              }}
              onSelectChange={(value) => {
                field.onChange(
                  producers.find(
                    (producer) => producer.id === Number(value?.key)
                  )
                );
              }}
              optionMap={producers.map((producer) => ({
                key: producer.id?.toString() || '',
                value: { name: producer.name, address: producer.address },
              }))}
            />
          )}
        />
      ) : (
        <>
          <Controller
            control={control}
            name="company.producer"
            render={({
              field,
            }: {
              field: {
                value?: Merchant;
                onChange: (value?: Merchant) => void;
              };
            }) => (
              <>
                <TextField
                  {...field}
                  label={t('edit-label.producer-name')}
                  error={isSubmitted && !!errors.company?.producer?.name}
                  fullWidth
                  value={field.value?.name || ''}
                  onChange={(event) =>
                    field.onChange({
                      ...field.value,
                      id: undefined,
                      name: event.target.value || undefined,
                    })
                  }
                />
                <TextField
                  {...field}
                  label={t('edit-label.producer-address')}
                  error={isSubmitted && !!errors.company?.producer?.address}
                  fullWidth
                  value={field.value?.address || ''}
                  onChange={(event) =>
                    field.onChange({
                      ...field.value,
                      id: undefined,
                      address: event.target.value || undefined,
                    })
                  }
                />
              </>
            )}
          />
        </>
      )}
      {hasImporters && (
        <FormControlLabel
          sx={{ my: 2 }}
          control={
            <Checkbox
              sx={{
                lineHeight: 0.7,
              }}
              checked={previousImporter}
              onChange={(event, checked) => setPreviousImporter(checked)}
            />
          }
          label={t('edit-label.use-previous-importer')}
        />
      )}
      {previousImporter ? (
        <Controller
          control={control}
          name="company.importer"
          render={({ field }) => (
            <MerchantSelect
              textFieldProps={{
                label: t('common.importer'),
                placeholder: t('common.select-one'),
              }}
              onSelectChange={(value) => {
                field.onChange(
                  importers.find(
                    (importer) => importer.id === Number(value?.key)
                  )
                );
              }}
              optionMap={importers.map((importer) => ({
                key: importer.id?.toString() || '',
                value: { name: importer.name, address: importer.address },
              }))}
            />
          )}
        />
      ) : (
        <>
          <Controller
            control={control}
            name="company.importer"
            render={({
              field,
            }: {
              field: {
                value?: Merchant;
                onChange: (value?: Merchant) => void;
              };
            }) => (
              <>
                <TextField
                  {...field}
                  label={t('edit-label.importer-name')}
                  error={isSubmitted && !!errors.company?.importer?.name}
                  fullWidth
                  value={field.value?.name || ''}
                  onChange={(event) =>
                    field.onChange({
                      ...field.value,
                      id: undefined,
                      name: event.target.value || undefined,
                    })
                  }
                />
                <TextField
                  {...field}
                  label={t('edit-label.importer-address')}
                  error={isSubmitted && !!errors.company?.importer?.address}
                  fullWidth
                  value={field.value?.address || ''}
                  onChange={(event) =>
                    field.onChange({
                      ...field.value,
                      id: undefined,
                      address: event.target.value || undefined,
                    })
                  }
                />
              </>
            )}
          />
        </>
      )}
    </TitledAccordion>
  );
};

export default CompanyAdvancedSettings;
