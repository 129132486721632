import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '../../api';
import PaletteIcon from '../../assets/icons/palette-icon';
import FlexBoxColumn from '../../components/flexbox/flex-box-column';
import FlexBox from '../../components/flexbox/flexbox';
import { LicenceCardVersion } from '../../models/licence';

interface FeaturesProps {
  type: 'licence' | 'personalization';
  product?: Product;
  isCustom?: boolean;
  withPersonalization?: boolean;
  version?: LicenceCardVersion;
}

const Features: React.FC<FeaturesProps> = ({
  type,
  product,
  isCustom,
  withPersonalization,
  version,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const isLicence = type === 'licence';
  const isImageUploadFeatureReady = false;
  const isVersion2 = version === LicenceCardVersion.V2;
  const personalizationFeatures = Array.from(Array(5).keys()).map(
    (i) => `licences.${isLicence ? '' : 'personalization-'}feature-${i + 1}`
  );
  const personalizationFeaturesV2 = Array.from(Array(3).keys()).map(
    (i) => `licences.personalization-feature-v2-${i + 1}`
  );
  const features = isCustom
    ? isVersion2
      ? [...personalizationFeaturesV2, ...personalizationFeatures]
      : ['licences.custom-feature']
    : [
        t('licences.account', {
          count: product?.accounts,
        }),
        ...personalizationFeatures,
      ].slice(isLicence ? 0 : 1);
  const highlightedFeatures = isCustom
    ? ['licences.custom-feature-2', 'licences.custom-feature']
    : [...personalizationFeaturesV2];

  if (!isCustom && isVersion2) features.splice(0, 1);
  if (!isLicence && !isImageUploadFeatureReady) features.splice(3, 1);

  return (
    <Box
      sx={{
        boxShadow: 26,
      }}
    >
      <FlexBoxColumn
        mx="auto"
        pt={isLicence ? 2 : 5.5}
        pb={isLicence ? (isVersion2 ? 4 : 2) : 5.5}
        px={isVersion2 ? 2 : 1}
        width="fit-content"
        minHeight={isCustom || isVersion2 ? 0 : '220px'}
        justifyContent={isCustom ? 'flex-start' : 'center'}
        gap={1}
      >
        {withPersonalization && !isVersion2 && (
          <FlexBox gap={1}>
            <PaletteIcon fillOpacity={1} sx={{ fontSize: '20px' }} />
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {t('licences.personalization-package')}
            </Typography>
          </FlexBox>
        )}
        {(withPersonalization || isCustom) &&
          isVersion2 &&
          highlightedFeatures.map((feature) => (
            <FlexBox gap={1} key={feature}>
              <AddIcon
                color="success"
                sx={{
                  fontSize: '20px',
                }}
              />
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {t(feature)}
              </Typography>
            </FlexBox>
          ))}
        {features.map((feature, index) => (
          <FlexBox gap={1} key={feature}>
            <CheckIcon
              color="success"
              sx={{
                fontSize: isLicence ? '20px' : '32px',
                ...(isVersion2
                  ? { color: palette.primaryVariations[700] }
                  : {}),
              }}
            />
            <Typography
              variant={isLicence ? 'body2' : 'h6'}
              sx={{
                ...(isLicence && !isCustom && !isVersion2 && index === 0
                  ? { fontWeight: 700 }
                  : {}),
                ...(isLicence ? {} : { whiteSpace: 'nowrap' }),
              }}
            >
              {t(feature)}
            </Typography>
          </FlexBox>
        ))}
      </FlexBoxColumn>
    </Box>
  );
};

export default Features;
