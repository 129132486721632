import React from 'react';
import { Star } from '@mui/icons-material';
import FlexBox from '../../components/flexbox/flexbox';
import { StarTagType } from '../../models/licence';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';

interface StarTagProps {
  type: StarTagType;
}

const StarTag: React.FC<StarTagProps> = ({ type }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const content = {
    [StarTagType.BASIC]: {
      numberOfStars: 1,
      text: t('licences.star-tag-basic'),
    },
    [StarTagType.PERSONALIZED]: {
      numberOfStars: 2,
      text: t('licences.star-tag-personalized'),
    },
    [StarTagType.CUSTOM]: {
      numberOfStars: 3,
      text: t('licences.star-tag-custom'),
    },
  };
  return (
    <FlexBox
      sx={{
        px: 2,
        py: 1,
        gap: 0.5,
        background: '#000',
        borderRadius: '0px 0px 16px 0px',
        position: 'absolute',
        boxShadow: 25,
        alignItems: 'center',
      }}
    >
      {Array.from(Array(content[type].numberOfStars).keys()).map((it) => (
        <Star
          key={it}
          sx={{
            fontSize: 16,
            color: palette.warning.light,
          }}
        />
      ))}
      <Typography variant="body2" sx={{ color: '#FFF', lineHeight: 1.25 }}>
        {content[type].text}
      </Typography>
    </FlexBox>
  );
};

export default StarTag;
