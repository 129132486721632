import { supportedTaxIdTypesByCountries } from '../../assets/countries/tax-id-types';

export const evaluateTaxId = (taxId: string, countryCode: string) => {
  const taxIdTypesByCountry = supportedTaxIdTypesByCountries.find(
    (it) => it.countryCode === countryCode
  );
  const isSupported = typeof taxIdTypesByCountry !== 'undefined';

  if (!isSupported) {
    return {
      isValidFormat: true,
      type: undefined,
    };
  }

  let result: { isValidFormat: boolean; type?: string } = {
    isValidFormat: false,
    type: undefined,
  };

  taxIdTypesByCountry.taxIdTypes.forEach((type) => {
    if (taxId.match(type.regex)) {
      result = {
        isValidFormat: true,
        type: type.name,
      };
    }
  });

  return result;
};

export const getStripeTaxType = (taxId?: string, countryCode?: string) =>
  typeof taxId === 'undefined' || typeof countryCode === 'undefined'
    ? undefined
    : evaluateTaxId(taxId, countryCode)?.type;
