/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'city'?: string;
    /**
     * 
     * @type {Contact}
     * @memberof Company
     */
    'contact'?: Contact;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'country'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'isMarketingMessageAllowed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'legalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'postalCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'premiumFeature'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'registrationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'role'?: CompanyRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'stripeTaxType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'vatNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'vatNumberCountry'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'website'?: string;
}

export const CompanyRoleEnum = {
    Winery: 'ROLE_WINERY',
    Federation: 'ROLE_FEDERATION',
    FederationMember: 'ROLE_FEDERATION_MEMBER'
} as const;

export type CompanyRoleEnum = typeof CompanyRoleEnum[keyof typeof CompanyRoleEnum];

/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'phoneCountryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface CreateWineLabelRequest
 */
export interface CreateWineLabelRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateWineLabelRequest
     */
    'harvestYear'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateWineLabelRequest
     */
    'productName'?: string;
}
/**
 * 
 * @export
 * @interface CreateWineLabelResponse
 */
export interface CreateWineLabelResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateWineLabelResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface CustomImageView
 */
export interface CustomImageView {
    /**
     * 
     * @type {string}
     * @memberof CustomImageView
     */
    'downloadUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomImageView
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomImageView
     */
    'imageGroup'?: CustomImageViewImageGroupEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomImageView
     */
    'originalFileName'?: string;
}

export const CustomImageViewImageGroupEnum = {
    BrandLogo: 'BRAND_LOGO',
    Footer: 'FOOTER'
} as const;

export type CustomImageViewImageGroupEnum = typeof CustomImageViewImageGroupEnum[keyof typeof CustomImageViewImageGroupEnum];

/**
 * 
 * @export
 * @interface CustomIngredient
 */
export interface CustomIngredient {
    /**
     * 
     * @type {boolean}
     * @memberof CustomIngredient
     */
    'allergen'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomIngredient
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomIngredient
     */
    'ingredientGroup'?: CustomIngredientIngredientGroupEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomIngredient
     */
    'languageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomIngredient
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomIngredient
     */
    'sequenceNumber'?: number;
    /**
     * 
     * @type {Array<Translation>}
     * @memberof CustomIngredient
     */
    'translations'?: Array<Translation>;
}

export const CustomIngredientIngredientGroupEnum = {
    Acid: 'ACID',
    EnrichmentSubstance: 'ENRICHMENT_SUBSTANCE',
    Preservative: 'PRESERVATIVE',
    Antioxidant: 'ANTIOXIDANT',
    ProcessingAid: 'PROCESSING_AID',
    RawMaterial: 'RAW_MATERIAL',
    Gas: 'GAS',
    Stabilizer: 'STABILIZER',
    OtherPractice: 'OTHER_PRACTICE',
    Custom: 'CUSTOM'
} as const;

export type CustomIngredientIngredientGroupEnum = typeof CustomIngredientIngredientGroupEnum[keyof typeof CustomIngredientIngredientGroupEnum];

/**
 * 
 * @export
 * @interface DeleteWineLabelsRequest
 */
export interface DeleteWineLabelsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteWineLabelsRequest
     */
    'ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface FlexibleIngredientGroup
 */
export interface FlexibleIngredientGroup {
    /**
     * 
     * @type {boolean}
     * @memberof FlexibleIngredientGroup
     */
    'contains'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FlexibleIngredientGroup
     */
    'ingredientGroup'?: FlexibleIngredientGroupIngredientGroupEnum;
}

export const FlexibleIngredientGroupIngredientGroupEnum = {
    Acid: 'ACID',
    EnrichmentSubstance: 'ENRICHMENT_SUBSTANCE',
    Preservative: 'PRESERVATIVE',
    Antioxidant: 'ANTIOXIDANT',
    ProcessingAid: 'PROCESSING_AID',
    RawMaterial: 'RAW_MATERIAL',
    Gas: 'GAS',
    Stabilizer: 'STABILIZER',
    OtherPractice: 'OTHER_PRACTICE',
    Custom: 'CUSTOM'
} as const;

export type FlexibleIngredientGroupIngredientGroupEnum = typeof FlexibleIngredientGroupIngredientGroupEnum[keyof typeof FlexibleIngredientGroupIngredientGroupEnum];

/**
 * 
 * @export
 * @interface GenerateQrCode200Response
 */
export interface GenerateQrCode200Response {
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'accelerationPriority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200Response
     */
    'alphaPremultiplied'?: boolean;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRaster}
     * @memberof GenerateQrCode200Response
     */
    'alphaRaster'?: GenerateQrCode200ResponseAlphaRaster;
    /**
     * 
     * @type {GenerateQrCode200ResponseColorModel}
     * @memberof GenerateQrCode200Response
     */
    'colorModel'?: GenerateQrCode200ResponseColorModel;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterParent}
     * @memberof GenerateQrCode200Response
     */
    'data'?: GenerateQrCode200ResponseAlphaRasterParent;
    /**
     * 
     * @type {GenerateQrCode200ResponseGraphics}
     * @memberof GenerateQrCode200Response
     */
    'graphics'?: GenerateQrCode200ResponseGraphics;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'minTileX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'minTileY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'minX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'minY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'numXTiles'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'numYTiles'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateQrCode200Response
     */
    'propertyNames'?: Array<string>;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRaster}
     * @memberof GenerateQrCode200Response
     */
    'raster'?: GenerateQrCode200ResponseAlphaRaster;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterParentSampleModel}
     * @memberof GenerateQrCode200Response
     */
    'sampleModel'?: GenerateQrCode200ResponseAlphaRasterParentSampleModel;
    /**
     * 
     * @type {object}
     * @memberof GenerateQrCode200Response
     */
    'source'?: object;
    /**
     * 
     * @type {Array<GenerateQrCode200ResponseSourcesInner>}
     * @memberof GenerateQrCode200Response
     */
    'sources'?: Array<GenerateQrCode200ResponseSourcesInner>;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'tileGridXOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'tileGridYOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'tileHeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'tileWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'transparency'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'type'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200Response
     */
    'width'?: number;
    /**
     * 
     * @type {Array<GenerateQrCode200ResponseAlphaRasterBoundsLocation>}
     * @memberof GenerateQrCode200Response
     */
    'writableTileIndices'?: Array<GenerateQrCode200ResponseAlphaRasterBoundsLocation>;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseAlphaRaster
 */
export interface GenerateQrCode200ResponseAlphaRaster {
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBounds}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'bounds'?: GenerateQrCode200ResponseAlphaRasterBounds;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterDataBuffer}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'dataBuffer'?: GenerateQrCode200ResponseAlphaRasterDataBuffer;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'minX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'minY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'numBands'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'numDataElements'?: number;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterParent}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'parent'?: GenerateQrCode200ResponseAlphaRasterParent;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterRect}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'rect'?: GenerateQrCode200ResponseAlphaRasterRect;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterParentSampleModel}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'sampleModel'?: GenerateQrCode200ResponseAlphaRasterParentSampleModel;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'sampleModelTranslateX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'sampleModelTranslateY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'transferType'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRaster
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseAlphaRasterBounds
 */
export interface GenerateQrCode200ResponseAlphaRasterBounds {
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBoundsBounds2D}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'bounds2D'?: GenerateQrCode200ResponseAlphaRasterBoundsBounds2D;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'centerX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'centerY'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'empty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'height'?: number;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBoundsLocation}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'location'?: GenerateQrCode200ResponseAlphaRasterBoundsLocation;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'maxX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'maxY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'minX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'minY'?: number;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBoundsRect}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'rect'?: GenerateQrCode200ResponseAlphaRasterBoundsRect;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBoundsSize}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'size'?: GenerateQrCode200ResponseAlphaRasterBoundsSize;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBounds
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
 */
export interface GenerateQrCode200ResponseAlphaRasterBoundsBounds2D {
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
     */
    'centerX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
     */
    'centerY'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
     */
    'empty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
     */
    'maxX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
     */
    'maxY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
     */
    'minX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
     */
    'minY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsBounds2D
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseAlphaRasterBoundsLocation
 */
export interface GenerateQrCode200ResponseAlphaRasterBoundsLocation {
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsLocation
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsLocation
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseAlphaRasterBoundsRect
 */
export interface GenerateQrCode200ResponseAlphaRasterBoundsRect {
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsRect
     */
    'centerX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsRect
     */
    'centerY'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsRect
     */
    'empty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsRect
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsRect
     */
    'maxX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsRect
     */
    'maxY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsRect
     */
    'minX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsRect
     */
    'minY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsRect
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsRect
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsRect
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseAlphaRasterBoundsSize
 */
export interface GenerateQrCode200ResponseAlphaRasterBoundsSize {
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsSize
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterBoundsSize
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseAlphaRasterDataBuffer
 */
export interface GenerateQrCode200ResponseAlphaRasterDataBuffer {
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterDataBuffer
     */
    'dataType'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterDataBuffer
     */
    'numBanks'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterDataBuffer
     */
    'offset'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GenerateQrCode200ResponseAlphaRasterDataBuffer
     */
    'offsets'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterDataBuffer
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseAlphaRasterParent
 */
export interface GenerateQrCode200ResponseAlphaRasterParent {
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBounds}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'bounds'?: GenerateQrCode200ResponseAlphaRasterBounds;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterDataBuffer}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'dataBuffer'?: GenerateQrCode200ResponseAlphaRasterDataBuffer;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'minX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'minY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'numBands'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'numDataElements'?: number;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterParentSampleModel}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'sampleModel'?: GenerateQrCode200ResponseAlphaRasterParentSampleModel;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'sampleModelTranslateX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'sampleModelTranslateY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'transferType'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParent
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseAlphaRasterParentSampleModel
 */
export interface GenerateQrCode200ResponseAlphaRasterParentSampleModel {
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParentSampleModel
     */
    'dataType'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParentSampleModel
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParentSampleModel
     */
    'numBands'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParentSampleModel
     */
    'numDataElements'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GenerateQrCode200ResponseAlphaRasterParentSampleModel
     */
    'sampleSize'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParentSampleModel
     */
    'transferType'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterParentSampleModel
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseAlphaRasterRect
 */
export interface GenerateQrCode200ResponseAlphaRasterRect {
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBounds}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'bounds'?: GenerateQrCode200ResponseAlphaRasterBounds;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterDataBuffer}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'dataBuffer'?: GenerateQrCode200ResponseAlphaRasterDataBuffer;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'minX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'minY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'numBands'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'numDataElements'?: number;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterParentSampleModel}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'sampleModel'?: GenerateQrCode200ResponseAlphaRasterParentSampleModel;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'sampleModelTranslateX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'sampleModelTranslateY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'transferType'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseAlphaRasterRect
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseColorModel
 */
export interface GenerateQrCode200ResponseColorModel {
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseColorModel
     */
    'alphaPremultiplied'?: boolean;
    /**
     * 
     * @type {GenerateQrCode200ResponseColorModelColorSpace}
     * @memberof GenerateQrCode200ResponseColorModel
     */
    'colorSpace'?: GenerateQrCode200ResponseColorModelColorSpace;
    /**
     * 
     * @type {Array<number>}
     * @memberof GenerateQrCode200ResponseColorModel
     */
    'componentSize'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseColorModel
     */
    'numColorComponents'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseColorModel
     */
    'numComponents'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseColorModel
     */
    'pixelSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseColorModel
     */
    'transferType'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseColorModel
     */
    'transparency'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseColorModelColorSpace
 */
export interface GenerateQrCode200ResponseColorModelColorSpace {
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseColorModelColorSpace
     */
    'cs_sRGB'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseColorModelColorSpace
     */
    'numComponents'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseColorModelColorSpace
     */
    'type'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseGraphics
 */
export interface GenerateQrCode200ResponseGraphics {
    /**
     * 
     * @type {GenerateQrCode200ResponseGraphicsClip}
     * @memberof GenerateQrCode200ResponseGraphics
     */
    'clip'?: GenerateQrCode200ResponseGraphicsClip;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBounds}
     * @memberof GenerateQrCode200ResponseGraphics
     */
    'clipBounds'?: GenerateQrCode200ResponseAlphaRasterBounds;
    /**
     * 
     * @type {GenerateQrCode200ResponseGraphicsClipRect}
     * @memberof GenerateQrCode200ResponseGraphics
     * @deprecated
     */
    'clipRect'?: GenerateQrCode200ResponseGraphicsClipRect;
    /**
     * 
     * @type {GenerateQrCode200ResponseGraphicsColor}
     * @memberof GenerateQrCode200ResponseGraphics
     */
    'color'?: GenerateQrCode200ResponseGraphicsColor;
    /**
     * 
     * @type {GenerateQrCode200ResponseGraphicsFont}
     * @memberof GenerateQrCode200ResponseGraphics
     */
    'font'?: GenerateQrCode200ResponseGraphicsFont;
    /**
     * 
     * @type {GenerateQrCode200ResponseGraphicsFontMetrics}
     * @memberof GenerateQrCode200ResponseGraphics
     */
    'fontMetrics'?: GenerateQrCode200ResponseGraphicsFontMetrics;
    /**
     * 
     * @type {GenerateQrCode200ResponseGraphicsXormode}
     * @memberof GenerateQrCode200ResponseGraphics
     */
    'xormode'?: GenerateQrCode200ResponseGraphicsXormode;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseGraphicsClip
 */
export interface GenerateQrCode200ResponseGraphicsClip {
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBounds}
     * @memberof GenerateQrCode200ResponseGraphicsClip
     */
    'bounds'?: GenerateQrCode200ResponseAlphaRasterBounds;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBoundsBounds2D}
     * @memberof GenerateQrCode200ResponseGraphicsClip
     */
    'bounds2D'?: GenerateQrCode200ResponseAlphaRasterBoundsBounds2D;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseGraphicsClipRect
 */
export interface GenerateQrCode200ResponseGraphicsClipRect {
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBoundsBounds2D}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'bounds2D'?: GenerateQrCode200ResponseAlphaRasterBoundsBounds2D;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'centerX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'centerY'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'empty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'height'?: number;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBoundsLocation}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'location'?: GenerateQrCode200ResponseAlphaRasterBoundsLocation;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'maxX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'maxY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'minX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'minY'?: number;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBoundsRect}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'rect'?: GenerateQrCode200ResponseAlphaRasterBoundsRect;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterBoundsSize}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'size'?: GenerateQrCode200ResponseAlphaRasterBoundsSize;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsClipRect
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseGraphicsColor
 */
export interface GenerateQrCode200ResponseGraphicsColor {
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsColor
     */
    'alpha'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsColor
     */
    'blue'?: number;
    /**
     * 
     * @type {GenerateQrCode200ResponseColorModelColorSpace}
     * @memberof GenerateQrCode200ResponseGraphicsColor
     */
    'colorSpace'?: GenerateQrCode200ResponseColorModelColorSpace;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsColor
     */
    'green'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsColor
     */
    'red'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsColor
     */
    'rgb'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsColor
     */
    'transparency'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseGraphicsFont
 */
export interface GenerateQrCode200ResponseGraphicsFont {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'attributes'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<object>}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'availableAttributes'?: Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'bold'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'family'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'fontName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'italic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'italicAngle'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'missingGlyphCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'numGlyphs'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'plain'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'psname'?: string;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'size2D'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'style'?: number;
    /**
     * 
     * @type {GenerateQrCode200ResponseGraphicsFontTransform}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'transform'?: GenerateQrCode200ResponseGraphicsFontTransform;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseGraphicsFont
     */
    'transformed'?: boolean;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseGraphicsFontMetrics
 */
export interface GenerateQrCode200ResponseGraphicsFontMetrics {
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetrics
     */
    'ascent'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetrics
     */
    'descent'?: number;
    /**
     * 
     * @type {GenerateQrCode200ResponseGraphicsFont}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetrics
     */
    'font'?: GenerateQrCode200ResponseGraphicsFont;
    /**
     * 
     * @type {GenerateQrCode200ResponseGraphicsFontMetricsFontRenderContext}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetrics
     */
    'fontRenderContext'?: GenerateQrCode200ResponseGraphicsFontMetricsFontRenderContext;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetrics
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetrics
     */
    'leading'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetrics
     */
    'maxAdvance'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetrics
     */
    'maxAscent'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetrics
     * @deprecated
     */
    'maxDecent'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetrics
     */
    'maxDescent'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetrics
     */
    'widths'?: Array<number>;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseGraphicsFontMetricsFontRenderContext
 */
export interface GenerateQrCode200ResponseGraphicsFontMetricsFontRenderContext {
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetricsFontRenderContext
     */
    'antiAliased'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetricsFontRenderContext
     */
    'antiAliasingHint'?: object;
    /**
     * 
     * @type {object}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetricsFontRenderContext
     */
    'fractionalMetricsHint'?: object;
    /**
     * 
     * @type {GenerateQrCode200ResponseGraphicsFontTransform}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetricsFontRenderContext
     */
    'transform'?: GenerateQrCode200ResponseGraphicsFontTransform;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetricsFontRenderContext
     */
    'transformType'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseGraphicsFontMetricsFontRenderContext
     */
    'transformed'?: boolean;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseGraphicsFontTransform
 */
export interface GenerateQrCode200ResponseGraphicsFontTransform {
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontTransform
     */
    'determinant'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateQrCode200ResponseGraphicsFontTransform
     */
    'identity'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontTransform
     */
    'scaleX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontTransform
     */
    'scaleY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontTransform
     */
    'shearX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontTransform
     */
    'shearY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontTransform
     */
    'toQuadrantRotation'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontTransform
     */
    'toRotation'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontTransform
     */
    'translateX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontTransform
     */
    'translateY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsFontTransform
     */
    'type'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseGraphicsXormode
 */
export interface GenerateQrCode200ResponseGraphicsXormode {
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsXormode
     */
    'alpha'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsXormode
     */
    'blue'?: number;
    /**
     * 
     * @type {GenerateQrCode200ResponseColorModelColorSpace}
     * @memberof GenerateQrCode200ResponseGraphicsXormode
     */
    'colorSpace'?: GenerateQrCode200ResponseColorModelColorSpace;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsXormode
     */
    'green'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsXormode
     */
    'red'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsXormode
     */
    'rgb'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseGraphicsXormode
     */
    'transparency'?: number;
}
/**
 * 
 * @export
 * @interface GenerateQrCode200ResponseSourcesInner
 */
export interface GenerateQrCode200ResponseSourcesInner {
    /**
     * 
     * @type {GenerateQrCode200ResponseColorModel}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'colorModel'?: GenerateQrCode200ResponseColorModel;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterParent}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'data'?: GenerateQrCode200ResponseAlphaRasterParent;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'minTileX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'minTileY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'minX'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'minY'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'numXTiles'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'numYTiles'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'propertyNames'?: Array<string>;
    /**
     * 
     * @type {GenerateQrCode200ResponseAlphaRasterParentSampleModel}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'sampleModel'?: GenerateQrCode200ResponseAlphaRasterParentSampleModel;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'tileGridXOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'tileGridYOffset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'tileHeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'tileWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenerateQrCode200ResponseSourcesInner
     */
    'width'?: number;
}
/**
 * 
 * @export
 * @interface Ingredient
 */
export interface Ingredient {
    /**
     * 
     * @type {Set<OrderedAcid>}
     * @memberof Ingredient
     */
    'acids'?: Set<OrderedAcid>;
    /**
     * 
     * @type {Set<OrderedAntioxidant>}
     * @memberof Ingredient
     */
    'antioxidants'?: Set<OrderedAntioxidant>;
    /**
     * 
     * @type {Set<CustomIngredient>}
     * @memberof Ingredient
     */
    'customIngredients'?: Set<CustomIngredient>;
    /**
     * 
     * @type {Set<OrderedEnrichmentSubstance>}
     * @memberof Ingredient
     */
    'enrichmentSubstances'?: Set<OrderedEnrichmentSubstance>;
    /**
     * 
     * @type {Set<FlexibleIngredientGroup>}
     * @memberof Ingredient
     */
    'flexibleIngredientGroupList'?: Set<FlexibleIngredientGroup>;
    /**
     * 
     * @type {Set<OrderedGas>}
     * @memberof Ingredient
     */
    'gases'?: Set<OrderedGas>;
    /**
     * 
     * @type {Set<IngredientGroupOrder>}
     * @memberof Ingredient
     */
    'ingredientGroupOrders'?: Set<IngredientGroupOrder>;
    /**
     * 
     * @type {Set<OrderedOtherPractice>}
     * @memberof Ingredient
     */
    'otherPractices'?: Set<OrderedOtherPractice>;
    /**
     * 
     * @type {Set<OrderedPreservative>}
     * @memberof Ingredient
     */
    'preservatives'?: Set<OrderedPreservative>;
    /**
     * 
     * @type {Set<OrderedProcessingAid>}
     * @memberof Ingredient
     */
    'processingAids'?: Set<OrderedProcessingAid>;
    /**
     * 
     * @type {Set<OrderedRawMaterial>}
     * @memberof Ingredient
     */
    'rawMaterials'?: Set<OrderedRawMaterial>;
    /**
     * 
     * @type {Set<OrderedStabilizer>}
     * @memberof Ingredient
     */
    'stabilizers'?: Set<OrderedStabilizer>;
}
/**
 * 
 * @export
 * @interface IngredientGroupOrder
 */
export interface IngredientGroupOrder {
    /**
     * 
     * @type {string}
     * @memberof IngredientGroupOrder
     */
    'ingredientGroup'?: IngredientGroupOrderIngredientGroupEnum;
    /**
     * 
     * @type {number}
     * @memberof IngredientGroupOrder
     */
    'sequenceNumber'?: number;
}

export const IngredientGroupOrderIngredientGroupEnum = {
    Acid: 'ACID',
    EnrichmentSubstance: 'ENRICHMENT_SUBSTANCE',
    Preservative: 'PRESERVATIVE',
    Antioxidant: 'ANTIOXIDANT',
    ProcessingAid: 'PROCESSING_AID',
    RawMaterial: 'RAW_MATERIAL',
    Gas: 'GAS',
    Stabilizer: 'STABILIZER',
    OtherPractice: 'OTHER_PRACTICE',
    Custom: 'CUSTOM'
} as const;

export type IngredientGroupOrderIngredientGroupEnum = typeof IngredientGroupOrderIngredientGroupEnum[keyof typeof IngredientGroupOrderIngredientGroupEnum];

/**
 * 
 * @export
 * @interface LabelCompany
 */
export interface LabelCompany {
    /**
     * 
     * @type {string}
     * @memberof LabelCompany
     */
    'brandName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabelCompany
     */
    'countryCode'?: string;
    /**
     * 
     * @type {Merchant}
     * @memberof LabelCompany
     */
    'importer'?: Merchant;
    /**
     * 
     * @type {Merchant}
     * @memberof LabelCompany
     */
    'producer'?: Merchant;
    /**
     * 
     * @type {string}
     * @memberof LabelCompany
     */
    'website'?: string;
}
/**
 * 
 * @export
 * @interface LogoutRequest
 */
export interface LogoutRequest {
    /**
     * 
     * @type {string}
     * @memberof LogoutRequest
     */
    'refreshToken'?: string;
}
/**
 * 
 * @export
 * @interface Merchant
 */
export interface Merchant {
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof Merchant
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface NutritionDeclaration
 */
export interface NutritionDeclaration {
    /**
     * 
     * @type {string}
     * @memberof NutritionDeclaration
     */
    'carbohydrate'?: string;
    /**
     * 
     * @type {string}
     * @memberof NutritionDeclaration
     */
    'energyKcal'?: string;
    /**
     * 
     * @type {string}
     * @memberof NutritionDeclaration
     */
    'energyKj'?: string;
    /**
     * 
     * @type {string}
     * @memberof NutritionDeclaration
     */
    'fat'?: string;
    /**
     * 
     * @type {string}
     * @memberof NutritionDeclaration
     */
    'protein'?: string;
    /**
     * 
     * @type {string}
     * @memberof NutritionDeclaration
     */
    'salt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NutritionDeclaration
     */
    'saturatedFat'?: string;
    /**
     * 
     * @type {string}
     * @memberof NutritionDeclaration
     */
    'sugar'?: string;
}
/**
 * 
 * @export
 * @interface OrderedAcid
 */
export interface OrderedAcid {
    /**
     * 
     * @type {string}
     * @memberof OrderedAcid
     */
    'name'?: OrderedAcidNameEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderedAcid
     */
    'sequenceNumber'?: number;
}

export const OrderedAcidNameEnum = {
    LacticAcid: 'LACTIC_ACID',
    MalicAcid: 'MALIC_ACID',
    TarticAcid: 'TARTIC_ACID',
    CalciumSulphate: 'CALCIUM_SULPHATE',
    PotassiumHydrogenCarbonate: 'POTASSIUM_HYDROGEN_CARBONATE',
    CalciumCarbonate: 'CALCIUM_CARBONATE',
    CitricAcid: 'CITRIC_ACID'
} as const;

export type OrderedAcidNameEnum = typeof OrderedAcidNameEnum[keyof typeof OrderedAcidNameEnum];

/**
 * 
 * @export
 * @interface OrderedAntioxidant
 */
export interface OrderedAntioxidant {
    /**
     * 
     * @type {string}
     * @memberof OrderedAntioxidant
     */
    'name'?: OrderedAntioxidantNameEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderedAntioxidant
     */
    'sequenceNumber'?: number;
}

export const OrderedAntioxidantNameEnum = {
    AscorbicAcid: 'ASCORBIC_ACID',
    ErythorbicAcid: 'ERYTHORBIC_ACID'
} as const;

export type OrderedAntioxidantNameEnum = typeof OrderedAntioxidantNameEnum[keyof typeof OrderedAntioxidantNameEnum];

/**
 * 
 * @export
 * @interface OrderedEnrichmentSubstance
 */
export interface OrderedEnrichmentSubstance {
    /**
     * 
     * @type {string}
     * @memberof OrderedEnrichmentSubstance
     */
    'name'?: OrderedEnrichmentSubstanceNameEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderedEnrichmentSubstance
     */
    'sequenceNumber'?: number;
    /**
     * 
     * @type {Ingredient}
     * @memberof OrderedEnrichmentSubstance
     */
    'subIngredient'?: Ingredient;
}

export const OrderedEnrichmentSubstanceNameEnum = {
    GrapeMust: 'GRAPE_MUST',
    Sucrose: 'SUCROSE',
    Wine: 'WINE'
} as const;

export type OrderedEnrichmentSubstanceNameEnum = typeof OrderedEnrichmentSubstanceNameEnum[keyof typeof OrderedEnrichmentSubstanceNameEnum];

/**
 * 
 * @export
 * @interface OrderedGas
 */
export interface OrderedGas {
    /**
     * 
     * @type {string}
     * @memberof OrderedGas
     */
    'name'?: OrderedGasNameEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderedGas
     */
    'sequenceNumber'?: number;
}

export const OrderedGasNameEnum = {
    CarbonDioxide: 'CARBON_DIOXIDE',
    Argon: 'ARGON',
    Nitrogen: 'NITROGEN',
    ProtectiveBottling: 'PROTECTIVE_BOTTLING'
} as const;

export type OrderedGasNameEnum = typeof OrderedGasNameEnum[keyof typeof OrderedGasNameEnum];

/**
 * 
 * @export
 * @interface OrderedOtherPractice
 */
export interface OrderedOtherPractice {
    /**
     * 
     * @type {string}
     * @memberof OrderedOtherPractice
     */
    'name'?: OrderedOtherPracticeNameEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderedOtherPractice
     */
    'sequenceNumber'?: number;
}

export const OrderedOtherPracticeNameEnum = {
    Caramel: 'CARAMEL',
    AleppoPineResin: 'ALEPPO_PINE_RESIN'
} as const;

export type OrderedOtherPracticeNameEnum = typeof OrderedOtherPracticeNameEnum[keyof typeof OrderedOtherPracticeNameEnum];

/**
 * 
 * @export
 * @interface OrderedPreservative
 */
export interface OrderedPreservative {
    /**
     * 
     * @type {string}
     * @memberof OrderedPreservative
     */
    'name'?: OrderedPreservativeNameEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderedPreservative
     */
    'sequenceNumber'?: number;
}

export const OrderedPreservativeNameEnum = {
    SulphurDioxide: 'SULPHUR_DIOXIDE',
    PotassiumHydrogenSulphite: 'POTASSIUM_HYDROGEN_SULPHITE',
    PotassiumMetabisulphite: 'POTASSIUM_METABISULPHITE',
    PotassiumSorbate: 'POTASSIUM_SORBATE',
    EggLysozyme: 'EGG_LYSOZYME',
    Dimethyldicarbonate: 'DIMETHYLDICARBONATE',
    Sulphites: 'SULPHITES',
    SorbicAcid: 'SORBIC_ACID',
    PotassiumBisulphite: 'POTASSIUM_BISULPHITE'
} as const;

export type OrderedPreservativeNameEnum = typeof OrderedPreservativeNameEnum[keyof typeof OrderedPreservativeNameEnum];

/**
 * 
 * @export
 * @interface OrderedProcessingAid
 */
export interface OrderedProcessingAid {
    /**
     * 
     * @type {string}
     * @memberof OrderedProcessingAid
     */
    'name'?: OrderedProcessingAidNameEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderedProcessingAid
     */
    'sequenceNumber'?: number;
}

export const OrderedProcessingAidNameEnum = {
    EggAlbumin: 'EGG_ALBUMIN',
    MilkCasein: 'MILK_CASEIN',
    AmmaniumBisulphite: 'AMMANIUM_BISULPHITE',
    WheatProtein: 'WHEAT_PROTEIN'
} as const;

export type OrderedProcessingAidNameEnum = typeof OrderedProcessingAidNameEnum[keyof typeof OrderedProcessingAidNameEnum];

/**
 * 
 * @export
 * @interface OrderedRawMaterial
 */
export interface OrderedRawMaterial {
    /**
     * 
     * @type {string}
     * @memberof OrderedRawMaterial
     */
    'name'?: OrderedRawMaterialNameEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderedRawMaterial
     */
    'sequenceNumber'?: number;
}

export const OrderedRawMaterialNameEnum = {
    Grapes: 'GRAPES',
    Water: 'WATER'
} as const;

export type OrderedRawMaterialNameEnum = typeof OrderedRawMaterialNameEnum[keyof typeof OrderedRawMaterialNameEnum];

/**
 * 
 * @export
 * @interface OrderedStabilizer
 */
export interface OrderedStabilizer {
    /**
     * 
     * @type {string}
     * @memberof OrderedStabilizer
     */
    'name'?: OrderedStabilizerNameEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderedStabilizer
     */
    'sequenceNumber'?: number;
}

export const OrderedStabilizerNameEnum = {
    MetatartaricAcid: 'METATARTARIC_ACID',
    GumArabic: 'GUM_ARABIC',
    YeastMannoproteins: 'YEAST_MANNOPROTEINS',
    Carboxymethilcellulose: 'CARBOXYMETHILCELLULOSE',
    PotassiumPolyaspartate: 'POTASSIUM_POLYASPARTATE',
    FumaricAcid: 'FUMARIC_ACID',
    CitricAcidStabilizer: 'CITRIC_ACID_STABILIZER'
} as const;

export type OrderedStabilizerNameEnum = typeof OrderedStabilizerNameEnum[keyof typeof OrderedStabilizerNameEnum];

/**
 * 
 * @export
 * @interface OriginPictogramDto
 */
export interface OriginPictogramDto {
    /**
     * 
     * @type {string}
     * @memberof OriginPictogramDto
     */
    'label'?: OriginPictogramDtoLabelEnum;
    /**
     * 
     * @type {string}
     * @memberof OriginPictogramDto
     */
    'name'?: string;
}

export const OriginPictogramDtoLabelEnum = {
    Pgi: 'PGI',
    Pdo: 'PDO',
    Pregnancy: 'PREGNANCY',
    DrinkingAndDriving: 'DRINKING_AND_DRIVING',
    EighteenPlus: 'EIGHTEEN_PLUS',
    WineInModeration: 'WINE_IN_MODERATION',
    GreenDot: 'GREEN_DOT',
    MobiusLoop: 'MOBIUS_LOOP',
    OrganicLogo: 'ORGANIC_LOGO',
    TrimanExample: 'TRIMAN_EXAMPLE',
    Triman: 'TRIMAN',
    Sulphite: 'SULPHITE',
    MilkSulphite: 'MILK_SULPHITE',
    EggSulphite: 'EGG_SULPHITE',
    MilkEggSulphite: 'MILK_EGG_SULPHITE',
    ClearGlass: 'CLEAR_GLASS',
    GreenBlueGlass: 'GREEN_BLUE_GLASS',
    BrownGlass: 'BROWN_GLASS',
    Cork: 'CORK',
    AluminumScrewCap: 'ALUMINUM_SCREW_CAP',
    SyntheticClosure: 'SYNTHETIC_CLOSURE',
    SyntheticMushroomCapInWhite: 'SYNTHETIC_MUSHROOM_CAP_IN_WHITE',
    SyntheticMushroomCapInBrown: 'SYNTHETIC_MUSHROOM_CAP_IN_BROWN',
    LongScrewCap: 'LONG_SCREW_CAP',
    ShortScrewCap: 'SHORT_SCREW_CAP',
    EasyToOpen: 'EASY_TO_OPEN',
    PvcShrinkCapsule: 'PVC_SHRINK_CAPSULE',
    EtShrinkCapsule: 'ET_SHRINK_CAPSULE',
    TinCapsule: 'TIN_CAPSULE',
    PolylaminatedAluminiumCapsule: 'POLYLAMINATED_ALUMINIUM_CAPSULE',
    PolylaminatedChampagneCapsule: 'POLYLAMINATED_CHAMPAGNE_CAPSULE',
    AgraffeForChampagneCaps: 'AGRAFFE_FOR_CHAMPAGNE_CAPS',
    Cardboard: 'CARDBOARD',
    GridInserts: 'GRID_INSERTS',
    CardboardAndGridInserts: 'CARDBOARD_AND_GRID_INSERTS',
    WoodenBox: 'WOODEN_BOX',
    ShrinkFilmWooden: 'SHRINK_FILM_WOODEN',
    Can: 'CAN',
    PaperTissue: 'PAPER_TISSUE',
    PaperHangTag: 'PAPER_HANG_TAG',
    PaperCardboardBox: 'PAPER_CARDBOARD_BOX',
    ShrinkFilmThermal: 'SHRINK_FILM_THERMAL',
    Tray: 'TRAY',
    BagInBoxPlexiGlass: 'BAG_IN_BOX_PLEXI_GLASS',
    Fabric: 'FABRIC',
    BagInBoxBag: 'BAG_IN_BOX_BAG'
} as const;

export type OriginPictogramDtoLabelEnum = typeof OriginPictogramDtoLabelEnum[keyof typeof OriginPictogramDtoLabelEnum];

/**
 * 
 * @export
 * @interface PageableWineLabelListItem
 */
export interface PageableWineLabelListItem {
    /**
     * 
     * @type {Array<WineLabelListItem>}
     * @memberof PageableWineLabelListItem
     */
    'content'?: Array<WineLabelListItem>;
    /**
     * 
     * @type {number}
     * @memberof PageableWineLabelListItem
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableWineLabelListItem
     */
    'totalSearchElements'?: number;
}
/**
 * 
 * @export
 * @interface PaymentStarted
 */
export interface PaymentStarted {
    /**
     * 
     * @type {string}
     * @memberof PaymentStarted
     */
    'paymentLink'?: string;
}
/**
 * 
 * @export
 * @interface PictogramsDto
 */
export interface PictogramsDto {
    /**
     * 
     * @type {string}
     * @memberof PictogramsDto
     */
    'allergen'?: PictogramsDtoAllergenEnum;
    /**
     * 
     * @type {Set<string>}
     * @memberof PictogramsDto
     */
    'detailedSustainability'?: Set<PictogramsDtoDetailedSustainabilityEnum>;
    /**
     * 
     * @type {OriginPictogramDto}
     * @memberof PictogramsDto
     */
    'origin'?: OriginPictogramDto;
    /**
     * 
     * @type {Set<string>}
     * @memberof PictogramsDto
     */
    'responsibleConsumption'?: Set<PictogramsDtoResponsibleConsumptionEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof PictogramsDto
     */
    'sustainability'?: Set<PictogramsDtoSustainabilityEnum>;
}

export const PictogramsDtoAllergenEnum = {
    Pgi: 'PGI',
    Pdo: 'PDO',
    Pregnancy: 'PREGNANCY',
    DrinkingAndDriving: 'DRINKING_AND_DRIVING',
    EighteenPlus: 'EIGHTEEN_PLUS',
    WineInModeration: 'WINE_IN_MODERATION',
    GreenDot: 'GREEN_DOT',
    MobiusLoop: 'MOBIUS_LOOP',
    OrganicLogo: 'ORGANIC_LOGO',
    TrimanExample: 'TRIMAN_EXAMPLE',
    Triman: 'TRIMAN',
    Sulphite: 'SULPHITE',
    MilkSulphite: 'MILK_SULPHITE',
    EggSulphite: 'EGG_SULPHITE',
    MilkEggSulphite: 'MILK_EGG_SULPHITE',
    ClearGlass: 'CLEAR_GLASS',
    GreenBlueGlass: 'GREEN_BLUE_GLASS',
    BrownGlass: 'BROWN_GLASS',
    Cork: 'CORK',
    AluminumScrewCap: 'ALUMINUM_SCREW_CAP',
    SyntheticClosure: 'SYNTHETIC_CLOSURE',
    SyntheticMushroomCapInWhite: 'SYNTHETIC_MUSHROOM_CAP_IN_WHITE',
    SyntheticMushroomCapInBrown: 'SYNTHETIC_MUSHROOM_CAP_IN_BROWN',
    LongScrewCap: 'LONG_SCREW_CAP',
    ShortScrewCap: 'SHORT_SCREW_CAP',
    EasyToOpen: 'EASY_TO_OPEN',
    PvcShrinkCapsule: 'PVC_SHRINK_CAPSULE',
    EtShrinkCapsule: 'ET_SHRINK_CAPSULE',
    TinCapsule: 'TIN_CAPSULE',
    PolylaminatedAluminiumCapsule: 'POLYLAMINATED_ALUMINIUM_CAPSULE',
    PolylaminatedChampagneCapsule: 'POLYLAMINATED_CHAMPAGNE_CAPSULE',
    AgraffeForChampagneCaps: 'AGRAFFE_FOR_CHAMPAGNE_CAPS',
    Cardboard: 'CARDBOARD',
    GridInserts: 'GRID_INSERTS',
    CardboardAndGridInserts: 'CARDBOARD_AND_GRID_INSERTS',
    WoodenBox: 'WOODEN_BOX',
    ShrinkFilmWooden: 'SHRINK_FILM_WOODEN',
    Can: 'CAN',
    PaperTissue: 'PAPER_TISSUE',
    PaperHangTag: 'PAPER_HANG_TAG',
    PaperCardboardBox: 'PAPER_CARDBOARD_BOX',
    ShrinkFilmThermal: 'SHRINK_FILM_THERMAL',
    Tray: 'TRAY',
    BagInBoxPlexiGlass: 'BAG_IN_BOX_PLEXI_GLASS',
    Fabric: 'FABRIC',
    BagInBoxBag: 'BAG_IN_BOX_BAG'
} as const;

export type PictogramsDtoAllergenEnum = typeof PictogramsDtoAllergenEnum[keyof typeof PictogramsDtoAllergenEnum];
export const PictogramsDtoDetailedSustainabilityEnum = {
    Pgi: 'PGI',
    Pdo: 'PDO',
    Pregnancy: 'PREGNANCY',
    DrinkingAndDriving: 'DRINKING_AND_DRIVING',
    EighteenPlus: 'EIGHTEEN_PLUS',
    WineInModeration: 'WINE_IN_MODERATION',
    GreenDot: 'GREEN_DOT',
    MobiusLoop: 'MOBIUS_LOOP',
    OrganicLogo: 'ORGANIC_LOGO',
    TrimanExample: 'TRIMAN_EXAMPLE',
    Triman: 'TRIMAN',
    Sulphite: 'SULPHITE',
    MilkSulphite: 'MILK_SULPHITE',
    EggSulphite: 'EGG_SULPHITE',
    MilkEggSulphite: 'MILK_EGG_SULPHITE',
    ClearGlass: 'CLEAR_GLASS',
    GreenBlueGlass: 'GREEN_BLUE_GLASS',
    BrownGlass: 'BROWN_GLASS',
    Cork: 'CORK',
    AluminumScrewCap: 'ALUMINUM_SCREW_CAP',
    SyntheticClosure: 'SYNTHETIC_CLOSURE',
    SyntheticMushroomCapInWhite: 'SYNTHETIC_MUSHROOM_CAP_IN_WHITE',
    SyntheticMushroomCapInBrown: 'SYNTHETIC_MUSHROOM_CAP_IN_BROWN',
    LongScrewCap: 'LONG_SCREW_CAP',
    ShortScrewCap: 'SHORT_SCREW_CAP',
    EasyToOpen: 'EASY_TO_OPEN',
    PvcShrinkCapsule: 'PVC_SHRINK_CAPSULE',
    EtShrinkCapsule: 'ET_SHRINK_CAPSULE',
    TinCapsule: 'TIN_CAPSULE',
    PolylaminatedAluminiumCapsule: 'POLYLAMINATED_ALUMINIUM_CAPSULE',
    PolylaminatedChampagneCapsule: 'POLYLAMINATED_CHAMPAGNE_CAPSULE',
    AgraffeForChampagneCaps: 'AGRAFFE_FOR_CHAMPAGNE_CAPS',
    Cardboard: 'CARDBOARD',
    GridInserts: 'GRID_INSERTS',
    CardboardAndGridInserts: 'CARDBOARD_AND_GRID_INSERTS',
    WoodenBox: 'WOODEN_BOX',
    ShrinkFilmWooden: 'SHRINK_FILM_WOODEN',
    Can: 'CAN',
    PaperTissue: 'PAPER_TISSUE',
    PaperHangTag: 'PAPER_HANG_TAG',
    PaperCardboardBox: 'PAPER_CARDBOARD_BOX',
    ShrinkFilmThermal: 'SHRINK_FILM_THERMAL',
    Tray: 'TRAY',
    BagInBoxPlexiGlass: 'BAG_IN_BOX_PLEXI_GLASS',
    Fabric: 'FABRIC',
    BagInBoxBag: 'BAG_IN_BOX_BAG'
} as const;

export type PictogramsDtoDetailedSustainabilityEnum = typeof PictogramsDtoDetailedSustainabilityEnum[keyof typeof PictogramsDtoDetailedSustainabilityEnum];
export const PictogramsDtoResponsibleConsumptionEnum = {
    Pgi: 'PGI',
    Pdo: 'PDO',
    Pregnancy: 'PREGNANCY',
    DrinkingAndDriving: 'DRINKING_AND_DRIVING',
    EighteenPlus: 'EIGHTEEN_PLUS',
    WineInModeration: 'WINE_IN_MODERATION',
    GreenDot: 'GREEN_DOT',
    MobiusLoop: 'MOBIUS_LOOP',
    OrganicLogo: 'ORGANIC_LOGO',
    TrimanExample: 'TRIMAN_EXAMPLE',
    Triman: 'TRIMAN',
    Sulphite: 'SULPHITE',
    MilkSulphite: 'MILK_SULPHITE',
    EggSulphite: 'EGG_SULPHITE',
    MilkEggSulphite: 'MILK_EGG_SULPHITE',
    ClearGlass: 'CLEAR_GLASS',
    GreenBlueGlass: 'GREEN_BLUE_GLASS',
    BrownGlass: 'BROWN_GLASS',
    Cork: 'CORK',
    AluminumScrewCap: 'ALUMINUM_SCREW_CAP',
    SyntheticClosure: 'SYNTHETIC_CLOSURE',
    SyntheticMushroomCapInWhite: 'SYNTHETIC_MUSHROOM_CAP_IN_WHITE',
    SyntheticMushroomCapInBrown: 'SYNTHETIC_MUSHROOM_CAP_IN_BROWN',
    LongScrewCap: 'LONG_SCREW_CAP',
    ShortScrewCap: 'SHORT_SCREW_CAP',
    EasyToOpen: 'EASY_TO_OPEN',
    PvcShrinkCapsule: 'PVC_SHRINK_CAPSULE',
    EtShrinkCapsule: 'ET_SHRINK_CAPSULE',
    TinCapsule: 'TIN_CAPSULE',
    PolylaminatedAluminiumCapsule: 'POLYLAMINATED_ALUMINIUM_CAPSULE',
    PolylaminatedChampagneCapsule: 'POLYLAMINATED_CHAMPAGNE_CAPSULE',
    AgraffeForChampagneCaps: 'AGRAFFE_FOR_CHAMPAGNE_CAPS',
    Cardboard: 'CARDBOARD',
    GridInserts: 'GRID_INSERTS',
    CardboardAndGridInserts: 'CARDBOARD_AND_GRID_INSERTS',
    WoodenBox: 'WOODEN_BOX',
    ShrinkFilmWooden: 'SHRINK_FILM_WOODEN',
    Can: 'CAN',
    PaperTissue: 'PAPER_TISSUE',
    PaperHangTag: 'PAPER_HANG_TAG',
    PaperCardboardBox: 'PAPER_CARDBOARD_BOX',
    ShrinkFilmThermal: 'SHRINK_FILM_THERMAL',
    Tray: 'TRAY',
    BagInBoxPlexiGlass: 'BAG_IN_BOX_PLEXI_GLASS',
    Fabric: 'FABRIC',
    BagInBoxBag: 'BAG_IN_BOX_BAG'
} as const;

export type PictogramsDtoResponsibleConsumptionEnum = typeof PictogramsDtoResponsibleConsumptionEnum[keyof typeof PictogramsDtoResponsibleConsumptionEnum];
export const PictogramsDtoSustainabilityEnum = {
    Pgi: 'PGI',
    Pdo: 'PDO',
    Pregnancy: 'PREGNANCY',
    DrinkingAndDriving: 'DRINKING_AND_DRIVING',
    EighteenPlus: 'EIGHTEEN_PLUS',
    WineInModeration: 'WINE_IN_MODERATION',
    GreenDot: 'GREEN_DOT',
    MobiusLoop: 'MOBIUS_LOOP',
    OrganicLogo: 'ORGANIC_LOGO',
    TrimanExample: 'TRIMAN_EXAMPLE',
    Triman: 'TRIMAN',
    Sulphite: 'SULPHITE',
    MilkSulphite: 'MILK_SULPHITE',
    EggSulphite: 'EGG_SULPHITE',
    MilkEggSulphite: 'MILK_EGG_SULPHITE',
    ClearGlass: 'CLEAR_GLASS',
    GreenBlueGlass: 'GREEN_BLUE_GLASS',
    BrownGlass: 'BROWN_GLASS',
    Cork: 'CORK',
    AluminumScrewCap: 'ALUMINUM_SCREW_CAP',
    SyntheticClosure: 'SYNTHETIC_CLOSURE',
    SyntheticMushroomCapInWhite: 'SYNTHETIC_MUSHROOM_CAP_IN_WHITE',
    SyntheticMushroomCapInBrown: 'SYNTHETIC_MUSHROOM_CAP_IN_BROWN',
    LongScrewCap: 'LONG_SCREW_CAP',
    ShortScrewCap: 'SHORT_SCREW_CAP',
    EasyToOpen: 'EASY_TO_OPEN',
    PvcShrinkCapsule: 'PVC_SHRINK_CAPSULE',
    EtShrinkCapsule: 'ET_SHRINK_CAPSULE',
    TinCapsule: 'TIN_CAPSULE',
    PolylaminatedAluminiumCapsule: 'POLYLAMINATED_ALUMINIUM_CAPSULE',
    PolylaminatedChampagneCapsule: 'POLYLAMINATED_CHAMPAGNE_CAPSULE',
    AgraffeForChampagneCaps: 'AGRAFFE_FOR_CHAMPAGNE_CAPS',
    Cardboard: 'CARDBOARD',
    GridInserts: 'GRID_INSERTS',
    CardboardAndGridInserts: 'CARDBOARD_AND_GRID_INSERTS',
    WoodenBox: 'WOODEN_BOX',
    ShrinkFilmWooden: 'SHRINK_FILM_WOODEN',
    Can: 'CAN',
    PaperTissue: 'PAPER_TISSUE',
    PaperHangTag: 'PAPER_HANG_TAG',
    PaperCardboardBox: 'PAPER_CARDBOARD_BOX',
    ShrinkFilmThermal: 'SHRINK_FILM_THERMAL',
    Tray: 'TRAY',
    BagInBoxPlexiGlass: 'BAG_IN_BOX_PLEXI_GLASS',
    Fabric: 'FABRIC',
    BagInBoxBag: 'BAG_IN_BOX_BAG'
} as const;

export type PictogramsDtoSustainabilityEnum = typeof PictogramsDtoSustainabilityEnum[keyof typeof PictogramsDtoSustainabilityEnum];

/**
 * 
 * @export
 * @interface PremiumFeature
 */
export interface PremiumFeature {
    /**
     * 
     * @type {Array<CustomImageView>}
     * @memberof PremiumFeature
     */
    'customImages'?: Array<CustomImageView>;
    /**
     * 
     * @type {string}
     * @memberof PremiumFeature
     */
    'font'?: string;
    /**
     * 
     * @type {string}
     * @memberof PremiumFeature
     */
    'primaryColour'?: string;
    /**
     * 
     * @type {string}
     * @memberof PremiumFeature
     */
    'primaryTextColour'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PremiumFeature
     */
    'sameFooterLogo'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PremiumFeature
     */
    'secondaryColour'?: string;
    /**
     * 
     * @type {string}
     * @memberof PremiumFeature
     */
    'secondaryTextColour'?: string;
}
/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    'priceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    'productTier'?: PriceProductTierEnum;
}

export const PriceProductTierEnum = {
    _1: 'TIER_1',
    _2: 'TIER_2'
} as const;

export type PriceProductTierEnum = typeof PriceProductTierEnum[keyof typeof PriceProductTierEnum];

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'accounts'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'currency'?: ProductCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'members'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'period'?: ProductPeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'priceDifference'?: number;
    /**
     * 
     * @type {Set<Price>}
     * @memberof Product
     */
    'prices'?: Set<Price>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'productFamily'?: ProductProductFamilyEnum;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'productId'?: string;
}

export const ProductCurrencyEnum = {
    Eur: 'EUR',
    Usd: 'USD'
} as const;

export type ProductCurrencyEnum = typeof ProductCurrencyEnum[keyof typeof ProductCurrencyEnum];
export const ProductPeriodEnum = {
    Year: 'YEAR',
    Month: 'MONTH'
} as const;

export type ProductPeriodEnum = typeof ProductPeriodEnum[keyof typeof ProductPeriodEnum];
export const ProductProductFamilyEnum = {
    Base: 'BASE',
    GermanBase: 'GERMAN_BASE',
    DopAlicanteBase: 'DOP_ALICANTE_BASE',
    German1: 'GERMAN_1',
    German2: 'GERMAN_2',
    German3: 'GERMAN_3'
} as const;

export type ProductProductFamilyEnum = typeof ProductProductFamilyEnum[keyof typeof ProductProductFamilyEnum];

/**
 * 
 * @export
 * @interface ProductResponse
 */
export interface ProductResponse {
    /**
     * 
     * @type {Set<Product>}
     * @memberof ProductResponse
     */
    'products'?: Set<Product>;
}
/**
 * 
 * @export
 * @interface PublicWineLabelView
 */
export interface PublicWineLabelView {
    /**
     * 
     * @type {number}
     * @memberof PublicWineLabelView
     */
    'alcoholByVolume'?: number;
    /**
     * 
     * @type {LabelCompany}
     * @memberof PublicWineLabelView
     */
    'company'?: LabelCompany;
    /**
     * 
     * @type {Set<WineLabelCustomPictogramView>}
     * @memberof PublicWineLabelView
     */
    'customPictograms'?: Set<WineLabelCustomPictogramView>;
    /**
     * 
     * @type {string}
     * @memberof PublicWineLabelView
     */
    'grapeVariety'?: string;
    /**
     * 
     * @type {number}
     * @memberof PublicWineLabelView
     */
    'harvestYear'?: number;
    /**
     * 
     * @type {Ingredient}
     * @memberof PublicWineLabelView
     */
    'ingredient'?: Ingredient;
    /**
     * 
     * @type {number}
     * @memberof PublicWineLabelView
     */
    'netQuantity'?: number;
    /**
     * 
     * @type {NutritionDeclaration}
     * @memberof PublicWineLabelView
     */
    'nutritionDeclaration'?: NutritionDeclaration;
    /**
     * 
     * @type {PictogramsDto}
     * @memberof PublicWineLabelView
     */
    'pictograms'?: PictogramsDto;
    /**
     * 
     * @type {PremiumFeature}
     * @memberof PublicWineLabelView
     */
    'premiumFeature'?: PremiumFeature;
    /**
     * 
     * @type {string}
     * @memberof PublicWineLabelView
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicWineLabelView
     */
    'productionMethod'?: PublicWineLabelViewProductionMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicWineLabelView
     */
    'sweetness'?: PublicWineLabelViewSweetnessEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicWineLabelView
     */
    'wineColour'?: PublicWineLabelViewWineColourEnum;
}

export const PublicWineLabelViewProductionMethodEnum = {
    BarrelAged: 'BARREL_AGED',
    BarrelFermented: 'BARREL_FERMENTED',
    BarrelMatured: 'BARREL_MATURED',
    BottleFermented: 'BOTTLE_FERMENTED',
    BottleFermentedTraditionally: 'BOTTLE_FERMENTED_TRADITIONALLY',
    CaskAged: 'CASK_AGED',
    CaskFermented: 'CASK_FERMENTED',
    CaskMatured: 'CASK_MATURED',
    CharmatMethod: 'CHARMAT_METHOD',
    ClassicalMethod: 'CLASSICAL_METHOD',
    ClassicalTraditionalMethod: 'CLASSICAL_TRADITIONAL_METHOD',
    Cremant: 'CREMANT',
    TraditionalMethod: 'TRADITIONAL_METHOD',
    FermentationInVat: 'FERMENTATION_IN_VAT',
    TankFermented: 'TANK_FERMENTED',
    CarboneDioxideInfused: 'CARBONE_DIOXIDE_INFUSED',
    Reductive: 'REDUCTIVE'
} as const;

export type PublicWineLabelViewProductionMethodEnum = typeof PublicWineLabelViewProductionMethodEnum[keyof typeof PublicWineLabelViewProductionMethodEnum];
export const PublicWineLabelViewSweetnessEnum = {
    Dry: 'DRY',
    MediumDry: 'MEDIUM_DRY',
    MediumSweet: 'MEDIUM_SWEET',
    Sweet: 'SWEET',
    BrutNature: 'BRUT_NATURE',
    ExtraBrut: 'EXTRA_BRUT',
    Brut: 'BRUT',
    ExtraDry: 'EXTRA_DRY',
    DryChampagne: 'DRY_CHAMPAGNE',
    HalfDry: 'HALF_DRY',
    SecSweet: 'SEC_SWEET',
    Doux: 'DOUX'
} as const;

export type PublicWineLabelViewSweetnessEnum = typeof PublicWineLabelViewSweetnessEnum[keyof typeof PublicWineLabelViewSweetnessEnum];
export const PublicWineLabelViewWineColourEnum = {
    Red: 'RED',
    Rose: 'ROSE',
    White: 'WHITE',
    Orange: 'ORANGE'
} as const;

export type PublicWineLabelViewWineColourEnum = typeof PublicWineLabelViewWineColourEnum[keyof typeof PublicWineLabelViewWineColourEnum];

/**
 * 
 * @export
 * @interface QrCode
 */
export interface QrCode {
    /**
     * 
     * @type {string}
     * @memberof QrCode
     */
    'bottomLanguageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof QrCode
     */
    'bottomText'?: string;
    /**
     * 
     * @type {string}
     * @memberof QrCode
     */
    'topLanguageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof QrCode
     */
    'topText'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QrCode
     */
    'withNutrition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QrCode
     */
    'withRecycling'?: boolean;
}
/**
 * 
 * @export
 * @interface RegistrationFinalizationResponse
 */
export interface RegistrationFinalizationResponse {
    /**
     * 
     * @type {number}
     * @memberof RegistrationFinalizationResponse
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface SeparatedMerchants
 */
export interface SeparatedMerchants {
    /**
     * 
     * @type {Set<Merchant>}
     * @memberof SeparatedMerchants
     */
    'importers'?: Set<Merchant>;
    /**
     * 
     * @type {Set<Merchant>}
     * @memberof SeparatedMerchants
     */
    'producers'?: Set<Merchant>;
}
/**
 * 
 * @export
 * @interface StartOnlinePaymentRequest
 */
export interface StartOnlinePaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof StartOnlinePaymentRequest
     */
    'currency'?: StartOnlinePaymentRequestCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof StartOnlinePaymentRequest
     */
    'priceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StartOnlinePaymentRequest
     */
    'productId'?: string;
}

export const StartOnlinePaymentRequestCurrencyEnum = {
    Eur: 'EUR',
    Usd: 'USD'
} as const;

export type StartOnlinePaymentRequestCurrencyEnum = typeof StartOnlinePaymentRequestCurrencyEnum[keyof typeof StartOnlinePaymentRequestCurrencyEnum];

/**
 * 
 * @export
 * @interface Translation
 */
export interface Translation {
    /**
     * 
     * @type {string}
     * @memberof Translation
     */
    'languageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Translation
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UploadCustomImageResponse
 */
export interface UploadCustomImageResponse {
    /**
     * 
     * @type {number}
     * @memberof UploadCustomImageResponse
     */
    'customImageId'?: number;
}
/**
 * 
 * @export
 * @interface UploadCustomPictogramResponse
 */
export interface UploadCustomPictogramResponse {
    /**
     * 
     * @type {number}
     * @memberof UploadCustomPictogramResponse
     */
    'pictogramId'?: number;
}
/**
 * 
 * @export
 * @interface ValidateRegistrationCodeRequest
 */
export interface ValidateRegistrationCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidateRegistrationCodeRequest
     */
    'registrationCode'?: string;
}
/**
 * 
 * @export
 * @interface WineLabelCustomPictogramView
 */
export interface WineLabelCustomPictogramView {
    /**
     * 
     * @type {string}
     * @memberof WineLabelCustomPictogramView
     */
    'downloadUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof WineLabelCustomPictogramView
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WineLabelCustomPictogramView
     */
    'isSelected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WineLabelCustomPictogramView
     */
    'originalFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WineLabelCustomPictogramView
     */
    'pictogramGroup'?: WineLabelCustomPictogramViewPictogramGroupEnum;
}

export const WineLabelCustomPictogramViewPictogramGroupEnum = {
    Origin: 'ORIGIN',
    ResponsibleCons: 'RESPONSIBLE_CONS',
    Sustainability: 'SUSTAINABILITY',
    Allergen: 'ALLERGEN',
    DetailedSustainability: 'DETAILED_SUSTAINABILITY'
} as const;

export type WineLabelCustomPictogramViewPictogramGroupEnum = typeof WineLabelCustomPictogramViewPictogramGroupEnum[keyof typeof WineLabelCustomPictogramViewPictogramGroupEnum];

/**
 * 
 * @export
 * @interface WineLabelListItem
 */
export interface WineLabelListItem {
    /**
     * 
     * @type {string}
     * @memberof WineLabelListItem
     */
    'gtinNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof WineLabelListItem
     */
    'harvestYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof WineLabelListItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WineLabelListItem
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WineLabelListItem
     */
    'productReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof WineLabelListItem
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface WineLabelListResponse
 */
export interface WineLabelListResponse {
    /**
     * 
     * @type {PageableWineLabelListItem}
     * @memberof WineLabelListResponse
     */
    'wineLabelPage'?: PageableWineLabelListItem;
}
/**
 * 
 * @export
 * @interface WineLabelView
 */
export interface WineLabelView {
    /**
     * 
     * @type {number}
     * @memberof WineLabelView
     */
    'alcoholByVolume'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WineLabelView
     */
    'autoKcalConversion'?: boolean;
    /**
     * 
     * @type {LabelCompany}
     * @memberof WineLabelView
     */
    'company'?: LabelCompany;
    /**
     * 
     * @type {Set<WineLabelCustomPictogramView>}
     * @memberof WineLabelView
     */
    'customPictograms'?: Set<WineLabelCustomPictogramView>;
    /**
     * 
     * @type {string}
     * @memberof WineLabelView
     */
    'grapeVariety'?: string;
    /**
     * 
     * @type {string}
     * @memberof WineLabelView
     */
    'gtinNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof WineLabelView
     */
    'harvestYear'?: number;
    /**
     * 
     * @type {Ingredient}
     * @memberof WineLabelView
     */
    'ingredient'?: Ingredient;
    /**
     * 
     * @type {number}
     * @memberof WineLabelView
     */
    'netQuantity'?: number;
    /**
     * 
     * @type {NutritionDeclaration}
     * @memberof WineLabelView
     */
    'nutritionDeclaration'?: NutritionDeclaration;
    /**
     * 
     * @type {PictogramsDto}
     * @memberof WineLabelView
     */
    'pictograms'?: PictogramsDto;
    /**
     * 
     * @type {string}
     * @memberof WineLabelView
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WineLabelView
     */
    'productReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof WineLabelView
     */
    'productionMethod'?: WineLabelViewProductionMethodEnum;
    /**
     * 
     * @type {QrCode}
     * @memberof WineLabelView
     */
    'qrCode'?: QrCode;
    /**
     * 
     * @type {string}
     * @memberof WineLabelView
     */
    'sweetness'?: WineLabelViewSweetnessEnum;
    /**
     * 
     * @type {string}
     * @memberof WineLabelView
     */
    'wineColour'?: WineLabelViewWineColourEnum;
}

export const WineLabelViewProductionMethodEnum = {
    BarrelAged: 'BARREL_AGED',
    BarrelFermented: 'BARREL_FERMENTED',
    BarrelMatured: 'BARREL_MATURED',
    BottleFermented: 'BOTTLE_FERMENTED',
    BottleFermentedTraditionally: 'BOTTLE_FERMENTED_TRADITIONALLY',
    CaskAged: 'CASK_AGED',
    CaskFermented: 'CASK_FERMENTED',
    CaskMatured: 'CASK_MATURED',
    CharmatMethod: 'CHARMAT_METHOD',
    ClassicalMethod: 'CLASSICAL_METHOD',
    ClassicalTraditionalMethod: 'CLASSICAL_TRADITIONAL_METHOD',
    Cremant: 'CREMANT',
    TraditionalMethod: 'TRADITIONAL_METHOD',
    FermentationInVat: 'FERMENTATION_IN_VAT',
    TankFermented: 'TANK_FERMENTED',
    CarboneDioxideInfused: 'CARBONE_DIOXIDE_INFUSED',
    Reductive: 'REDUCTIVE'
} as const;

export type WineLabelViewProductionMethodEnum = typeof WineLabelViewProductionMethodEnum[keyof typeof WineLabelViewProductionMethodEnum];
export const WineLabelViewSweetnessEnum = {
    Dry: 'DRY',
    MediumDry: 'MEDIUM_DRY',
    MediumSweet: 'MEDIUM_SWEET',
    Sweet: 'SWEET',
    BrutNature: 'BRUT_NATURE',
    ExtraBrut: 'EXTRA_BRUT',
    Brut: 'BRUT',
    ExtraDry: 'EXTRA_DRY',
    DryChampagne: 'DRY_CHAMPAGNE',
    HalfDry: 'HALF_DRY',
    SecSweet: 'SEC_SWEET',
    Doux: 'DOUX'
} as const;

export type WineLabelViewSweetnessEnum = typeof WineLabelViewSweetnessEnum[keyof typeof WineLabelViewSweetnessEnum];
export const WineLabelViewWineColourEnum = {
    Red: 'RED',
    Rose: 'ROSE',
    White: 'WHITE',
    Orange: 'ORANGE'
} as const;

export type WineLabelViewWineColourEnum = typeof WineLabelViewWineColourEnum[keyof typeof WineLabelViewWineColourEnum];


/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Change email of a user
         * @param {number} contactId 
         * @param {string} newEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail: async (contactId: number, newEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('changeEmail', 'contactId', contactId)
            // verify required parameter 'newEmail' is not null or undefined
            assertParamExists('changeEmail', 'newEmail', newEmail)
            const localVarPath = `/api/admin/changeEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contactId !== undefined) {
                localVarQueryParameter['contactId'] = contactId;
            }

            if (newEmail !== undefined) {
                localVarQueryParameter['newEmail'] = newEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Undelete soft deleted wine labels
         * @param {Array<number>} wineLabelIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undeleteWineLabels: async (wineLabelIdList: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wineLabelIdList' is not null or undefined
            assertParamExists('undeleteWineLabels', 'wineLabelIdList', wineLabelIdList)
            const localVarPath = `/api/admin/undeleteWineLabels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (wineLabelIdList) {
                localVarQueryParameter['wineLabelIdList'] = wineLabelIdList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Change email of a user
         * @param {number} contactId 
         * @param {string} newEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeEmail(contactId: number, newEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeEmail(contactId, newEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Undelete soft deleted wine labels
         * @param {Array<number>} wineLabelIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async undeleteWineLabels(wineLabelIdList: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.undeleteWineLabels(wineLabelIdList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Change email of a user
         * @param {number} contactId 
         * @param {string} newEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeEmail(contactId: number, newEmail: string, options?: any): AxiosPromise<void> {
            return localVarFp.changeEmail(contactId, newEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * Undelete soft deleted wine labels
         * @param {Array<number>} wineLabelIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        undeleteWineLabels(wineLabelIdList: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.undeleteWineLabels(wineLabelIdList, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Change email of a user
     * @param {number} contactId 
     * @param {string} newEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public changeEmail(contactId: number, newEmail: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).changeEmail(contactId, newEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Undelete soft deleted wine labels
     * @param {Array<number>} wineLabelIdList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public undeleteWineLabels(wineLabelIdList: Array<number>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).undeleteWineLabels(wineLabelIdList, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomImagesApi - axios parameter creator
 * @export
 */
export const CustomImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deleting a custom image
         * @param {number} customImageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomImage: async (customImageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customImageId' is not null or undefined
            assertParamExists('deleteCustomImage', 'customImageId', customImageId)
            const localVarPath = `/api/custom-images/{customImageId}`
                .replace(`{${"customImageId"}}`, encodeURIComponent(String(customImageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploading new custom image
         * @param {'BRAND_LOGO' | 'FOOTER'} imageGroup 
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCustomImage: async (imageGroup: 'BRAND_LOGO' | 'FOOTER', image: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageGroup' is not null or undefined
            assertParamExists('uploadCustomImage', 'imageGroup', imageGroup)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('uploadCustomImage', 'image', image)
            const localVarPath = `/api/custom-images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (imageGroup !== undefined) {
                localVarQueryParameter['imageGroup'] = imageGroup;
            }


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomImagesApi - functional programming interface
 * @export
 */
export const CustomImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Deleting a custom image
         * @param {number} customImageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomImage(customImageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomImage(customImageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Uploading new custom image
         * @param {'BRAND_LOGO' | 'FOOTER'} imageGroup 
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadCustomImage(imageGroup: 'BRAND_LOGO' | 'FOOTER', image: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadCustomImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCustomImage(imageGroup, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomImagesApi - factory interface
 * @export
 */
export const CustomImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomImagesApiFp(configuration)
    return {
        /**
         * Deleting a custom image
         * @param {number} customImageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomImage(customImageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomImage(customImageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploading new custom image
         * @param {'BRAND_LOGO' | 'FOOTER'} imageGroup 
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCustomImage(imageGroup: 'BRAND_LOGO' | 'FOOTER', image: File, options?: any): AxiosPromise<UploadCustomImageResponse> {
            return localVarFp.uploadCustomImage(imageGroup, image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomImagesApi - object-oriented interface
 * @export
 * @class CustomImagesApi
 * @extends {BaseAPI}
 */
export class CustomImagesApi extends BaseAPI {
    /**
     * Deleting a custom image
     * @param {number} customImageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomImagesApi
     */
    public deleteCustomImage(customImageId: number, options?: AxiosRequestConfig) {
        return CustomImagesApiFp(this.configuration).deleteCustomImage(customImageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploading new custom image
     * @param {'BRAND_LOGO' | 'FOOTER'} imageGroup 
     * @param {File} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomImagesApi
     */
    public uploadCustomImage(imageGroup: 'BRAND_LOGO' | 'FOOTER', image: File, options?: AxiosRequestConfig) {
        return CustomImagesApiFp(this.configuration).uploadCustomImage(imageGroup, image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogoutApi - axios parameter creator
 * @export
 */
export const LogoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Logout user by revoking their refresh tokens
         * @param {LogoutRequest} logoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (logoutRequest: LogoutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logoutRequest' is not null or undefined
            assertParamExists('logout', 'logoutRequest', logoutRequest)
            const localVarPath = `/api/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogoutApi - functional programming interface
 * @export
 */
export const LogoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogoutApiAxiosParamCreator(configuration)
    return {
        /**
         * Logout user by revoking their refresh tokens
         * @param {LogoutRequest} logoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(logoutRequest: LogoutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(logoutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogoutApi - factory interface
 * @export
 */
export const LogoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogoutApiFp(configuration)
    return {
        /**
         * Logout user by revoking their refresh tokens
         * @param {LogoutRequest} logoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(logoutRequest: LogoutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.logout(logoutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogoutApi - object-oriented interface
 * @export
 * @class LogoutApi
 * @extends {BaseAPI}
 */
export class LogoutApi extends BaseAPI {
    /**
     * Logout user by revoking their refresh tokens
     * @param {LogoutRequest} logoutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogoutApi
     */
    public logout(logoutRequest: LogoutRequest, options?: AxiosRequestConfig) {
        return LogoutApiFp(this.configuration).logout(logoutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MerchantsApi - axios parameter creator
 * @export
 */
export const MerchantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves merchants for authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMerchants: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/merchants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantsApi - functional programming interface
 * @export
 */
export const MerchantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MerchantsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves merchants for authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveMerchants(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeparatedMerchants>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveMerchants(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MerchantsApi - factory interface
 * @export
 */
export const MerchantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MerchantsApiFp(configuration)
    return {
        /**
         * Retrieves merchants for authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveMerchants(options?: any): AxiosPromise<SeparatedMerchants> {
            return localVarFp.retrieveMerchants(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MerchantsApi - object-oriented interface
 * @export
 * @class MerchantsApi
 * @extends {BaseAPI}
 */
export class MerchantsApi extends BaseAPI {
    /**
     * Retrieves merchants for authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantsApi
     */
    public retrieveMerchants(options?: AxiosRequestConfig) {
        return MerchantsApiFp(this.configuration).retrieveMerchants(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Starting online payment for subscription
         * @param {StartOnlinePaymentRequest} startOnlinePaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSubscriptionPayment: async (startOnlinePaymentRequest: StartOnlinePaymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startOnlinePaymentRequest' is not null or undefined
            assertParamExists('startSubscriptionPayment', 'startOnlinePaymentRequest', startOnlinePaymentRequest)
            const localVarPath = `/api/payments/subscriptions/checkout-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startOnlinePaymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Starting online payment for subscription
         * @param {StartOnlinePaymentRequest} startOnlinePaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startSubscriptionPayment(startOnlinePaymentRequest: StartOnlinePaymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentStarted>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startSubscriptionPayment(startOnlinePaymentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * Starting online payment for subscription
         * @param {StartOnlinePaymentRequest} startOnlinePaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSubscriptionPayment(startOnlinePaymentRequest: StartOnlinePaymentRequest, options?: any): AxiosPromise<PaymentStarted> {
            return localVarFp.startSubscriptionPayment(startOnlinePaymentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * Starting online payment for subscription
     * @param {StartOnlinePaymentRequest} startOnlinePaymentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public startSubscriptionPayment(startOnlinePaymentRequest: StartOnlinePaymentRequest, options?: AxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).startSubscriptionPayment(startOnlinePaymentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PictogramsApi - axios parameter creator
 * @export
 */
export const PictogramsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deleting a custom pictogram with its corresponding image, and all relations to wineLabels
         * @param {number} pictogramId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomPictogram: async (pictogramId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pictogramId' is not null or undefined
            assertParamExists('deleteCustomPictogram', 'pictogramId', pictogramId)
            const localVarPath = `/api/custom-pictograms/{pictogramId}`
                .replace(`{${"pictogramId"}}`, encodeURIComponent(String(pictogramId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploading new custom pictogram
         * @param {'ORIGIN' | 'RESPONSIBLE_CONS' | 'SUSTAINABILITY' | 'ALLERGEN' | 'DETAILED_SUSTAINABILITY'} pictogramGroup 
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCustomPictogram: async (pictogramGroup: 'ORIGIN' | 'RESPONSIBLE_CONS' | 'SUSTAINABILITY' | 'ALLERGEN' | 'DETAILED_SUSTAINABILITY', image: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pictogramGroup' is not null or undefined
            assertParamExists('uploadCustomPictogram', 'pictogramGroup', pictogramGroup)
            // verify required parameter 'image' is not null or undefined
            assertParamExists('uploadCustomPictogram', 'image', image)
            const localVarPath = `/api/custom-pictograms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pictogramGroup !== undefined) {
                localVarQueryParameter['pictogramGroup'] = pictogramGroup;
            }


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PictogramsApi - functional programming interface
 * @export
 */
export const PictogramsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PictogramsApiAxiosParamCreator(configuration)
    return {
        /**
         * Deleting a custom pictogram with its corresponding image, and all relations to wineLabels
         * @param {number} pictogramId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomPictogram(pictogramId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomPictogram(pictogramId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Uploading new custom pictogram
         * @param {'ORIGIN' | 'RESPONSIBLE_CONS' | 'SUSTAINABILITY' | 'ALLERGEN' | 'DETAILED_SUSTAINABILITY'} pictogramGroup 
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadCustomPictogram(pictogramGroup: 'ORIGIN' | 'RESPONSIBLE_CONS' | 'SUSTAINABILITY' | 'ALLERGEN' | 'DETAILED_SUSTAINABILITY', image: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadCustomPictogramResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCustomPictogram(pictogramGroup, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PictogramsApi - factory interface
 * @export
 */
export const PictogramsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PictogramsApiFp(configuration)
    return {
        /**
         * Deleting a custom pictogram with its corresponding image, and all relations to wineLabels
         * @param {number} pictogramId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomPictogram(pictogramId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomPictogram(pictogramId, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploading new custom pictogram
         * @param {'ORIGIN' | 'RESPONSIBLE_CONS' | 'SUSTAINABILITY' | 'ALLERGEN' | 'DETAILED_SUSTAINABILITY'} pictogramGroup 
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCustomPictogram(pictogramGroup: 'ORIGIN' | 'RESPONSIBLE_CONS' | 'SUSTAINABILITY' | 'ALLERGEN' | 'DETAILED_SUSTAINABILITY', image: File, options?: any): AxiosPromise<UploadCustomPictogramResponse> {
            return localVarFp.uploadCustomPictogram(pictogramGroup, image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PictogramsApi - object-oriented interface
 * @export
 * @class PictogramsApi
 * @extends {BaseAPI}
 */
export class PictogramsApi extends BaseAPI {
    /**
     * Deleting a custom pictogram with its corresponding image, and all relations to wineLabels
     * @param {number} pictogramId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PictogramsApi
     */
    public deleteCustomPictogram(pictogramId: number, options?: AxiosRequestConfig) {
        return PictogramsApiFp(this.configuration).deleteCustomPictogram(pictogramId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploading new custom pictogram
     * @param {'ORIGIN' | 'RESPONSIBLE_CONS' | 'SUSTAINABILITY' | 'ALLERGEN' | 'DETAILED_SUSTAINABILITY'} pictogramGroup 
     * @param {File} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PictogramsApi
     */
    public uploadCustomPictogram(pictogramGroup: 'ORIGIN' | 'RESPONSIBLE_CONS' | 'SUSTAINABILITY' | 'ALLERGEN' | 'DETAILED_SUSTAINABILITY', image: File, options?: AxiosRequestConfig) {
        return PictogramsApiFp(this.configuration).uploadCustomPictogram(pictogramGroup, image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PremiumFeaturesApi - axios parameter creator
 * @export
 */
export const PremiumFeaturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves premium features of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumFeatures: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/premium-features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updating premium features
         * @param {PremiumFeature} premiumFeature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePremiumFeatures: async (premiumFeature: PremiumFeature, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'premiumFeature' is not null or undefined
            assertParamExists('updatePremiumFeatures', 'premiumFeature', premiumFeature)
            const localVarPath = `/api/premium-features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(premiumFeature, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PremiumFeaturesApi - functional programming interface
 * @export
 */
export const PremiumFeaturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PremiumFeaturesApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves premium features of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPremiumFeatures(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PremiumFeature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPremiumFeatures(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updating premium features
         * @param {PremiumFeature} premiumFeature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePremiumFeatures(premiumFeature: PremiumFeature, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePremiumFeatures(premiumFeature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PremiumFeaturesApi - factory interface
 * @export
 */
export const PremiumFeaturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PremiumFeaturesApiFp(configuration)
    return {
        /**
         * Retrieves premium features of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumFeatures(options?: any): AxiosPromise<PremiumFeature> {
            return localVarFp.getPremiumFeatures(options).then((request) => request(axios, basePath));
        },
        /**
         * Updating premium features
         * @param {PremiumFeature} premiumFeature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePremiumFeatures(premiumFeature: PremiumFeature, options?: any): AxiosPromise<void> {
            return localVarFp.updatePremiumFeatures(premiumFeature, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PremiumFeaturesApi - object-oriented interface
 * @export
 * @class PremiumFeaturesApi
 * @extends {BaseAPI}
 */
export class PremiumFeaturesApi extends BaseAPI {
    /**
     * Retrieves premium features of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumFeaturesApi
     */
    public getPremiumFeatures(options?: AxiosRequestConfig) {
        return PremiumFeaturesApiFp(this.configuration).getPremiumFeatures(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updating premium features
     * @param {PremiumFeature} premiumFeature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumFeaturesApi
     */
    public updatePremiumFeatures(premiumFeature: PremiumFeature, options?: AxiosRequestConfig) {
        return PremiumFeaturesApiFp(this.configuration).updatePremiumFeatures(premiumFeature, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves products based on the user\'s country
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/product/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves products based on the user\'s country
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * Retrieves products based on the user\'s country
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(options?: any): AxiosPromise<ProductResponse> {
            return localVarFp.getProducts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * Retrieves products based on the user\'s country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProducts(options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProducts(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves profile information of authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updating profile
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (company: Company, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('updateProfile', 'company', company)
            const localVarPath = `/api/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(company, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves profile information of authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updating profile
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(company: Company, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(company, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * Retrieves profile information of authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<Company> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * Updating profile
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(company: Company, options?: any): AxiosPromise<void> {
            return localVarFp.updateProfile(company, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * Retrieves profile information of authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfile(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updating profile
     * @param {Company} company 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateProfile(company: Company, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).updateProfile(company, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QrCodesApi - axios parameter creator
 * @export
 */
export const QrCodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate QR code by url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateQrCode: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('generateQrCode', 'url', url)
            const localVarPath = `/api/qr/generate/{url}`
                .replace(`{${"url"}}`, encodeURIComponent(String(url)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QrCodesApi - functional programming interface
 * @export
 */
export const QrCodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QrCodesApiAxiosParamCreator(configuration)
    return {
        /**
         * Generate QR code by url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateQrCode(url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateQrCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateQrCode(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QrCodesApi - factory interface
 * @export
 */
export const QrCodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QrCodesApiFp(configuration)
    return {
        /**
         * Generate QR code by url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateQrCode(url: string, options?: any): AxiosPromise<GenerateQrCode200Response> {
            return localVarFp.generateQrCode(url, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QrCodesApi - object-oriented interface
 * @export
 * @class QrCodesApi
 * @extends {BaseAPI}
 */
export class QrCodesApi extends BaseAPI {
    /**
     * Generate QR code by url
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QrCodesApi
     */
    public generateQrCode(url: string, options?: AxiosRequestConfig) {
        return QrCodesApiFp(this.configuration).generateQrCode(url, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegistrationApi - axios parameter creator
 * @export
 */
export const RegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Soft delete registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegistration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/registration/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finalizing registration by creating user
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeRegistration: async (company: Company, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'company' is not null or undefined
            assertParamExists('finalizeRegistration', 'company', company)
            const localVarPath = `/api/registration/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(company, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates a registration code
         * @param {ValidateRegistrationCodeRequest} validateRegistrationCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateRegistrationCode: async (validateRegistrationCodeRequest: ValidateRegistrationCodeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateRegistrationCodeRequest' is not null or undefined
            assertParamExists('validateRegistrationCode', 'validateRegistrationCodeRequest', validateRegistrationCodeRequest)
            const localVarPath = `/api/registration/validate-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateRegistrationCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationApi - functional programming interface
 * @export
 */
export const RegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * Soft delete registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRegistration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRegistration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Finalizing registration by creating user
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finalizeRegistration(company: Company, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationFinalizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finalizeRegistration(company, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validates a registration code
         * @param {ValidateRegistrationCodeRequest} validateRegistrationCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateRegistrationCode(validateRegistrationCodeRequest: ValidateRegistrationCodeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateRegistrationCode(validateRegistrationCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistrationApi - factory interface
 * @export
 */
export const RegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistrationApiFp(configuration)
    return {
        /**
         * Soft delete registration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegistration(options?: any): AxiosPromise<void> {
            return localVarFp.deleteRegistration(options).then((request) => request(axios, basePath));
        },
        /**
         * Finalizing registration by creating user
         * @param {Company} company 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeRegistration(company: Company, options?: any): AxiosPromise<RegistrationFinalizationResponse> {
            return localVarFp.finalizeRegistration(company, options).then((request) => request(axios, basePath));
        },
        /**
         * Validates a registration code
         * @param {ValidateRegistrationCodeRequest} validateRegistrationCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateRegistrationCode(validateRegistrationCodeRequest: ValidateRegistrationCodeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.validateRegistrationCode(validateRegistrationCodeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrationApi - object-oriented interface
 * @export
 * @class RegistrationApi
 * @extends {BaseAPI}
 */
export class RegistrationApi extends BaseAPI {
    /**
     * Soft delete registration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public deleteRegistration(options?: AxiosRequestConfig) {
        return RegistrationApiFp(this.configuration).deleteRegistration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Finalizing registration by creating user
     * @param {Company} company 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public finalizeRegistration(company: Company, options?: AxiosRequestConfig) {
        return RegistrationApiFp(this.configuration).finalizeRegistration(company, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates a registration code
     * @param {ValidateRegistrationCodeRequest} validateRegistrationCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public validateRegistrationCode(validateRegistrationCodeRequest: ValidateRegistrationCodeRequest, options?: AxiosRequestConfig) {
        return RegistrationApiFp(this.configuration).validateRegistrationCode(validateRegistrationCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StripeWebhooksApi - axios parameter creator
 * @export
 */
export const StripeWebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutSessionCompleted: async (stripeSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('checkoutSessionCompleted', 'stripeSignature', stripeSignature)
            const localVarPath = `/webhook/checkoutSessionCompleted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature != null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dispute: async (stripeSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('dispute', 'stripeSignature', stripeSignature)
            const localVarPath = `/webhook/dispute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature != null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoice: async (stripeSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('invoice', 'stripeSignature', stripeSignature)
            const localVarPath = `/webhook/invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature != null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntent: async (stripeSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('paymentIntent', 'stripeSignature', stripeSignature)
            const localVarPath = `/webhook/paymentIntent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature != null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscription: async (stripeSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('subscription', 'stripeSignature', stripeSignature)
            const localVarPath = `/webhook/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature != null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxIdCreated: async (stripeSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('taxIdCreated', 'stripeSignature', stripeSignature)
            const localVarPath = `/webhook/taxIdCreated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature != null) {
                localVarHeaderParameter['Stripe-Signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeWebhooksApi - functional programming interface
 * @export
 */
export const StripeWebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeWebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkoutSessionCompleted(stripeSignature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkoutSessionCompleted(stripeSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dispute(stripeSignature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dispute(stripeSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoice(stripeSignature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoice(stripeSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentIntent(stripeSignature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentIntent(stripeSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscription(stripeSignature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscription(stripeSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taxIdCreated(stripeSignature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taxIdCreated(stripeSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StripeWebhooksApi - factory interface
 * @export
 */
export const StripeWebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeWebhooksApiFp(configuration)
    return {
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutSessionCompleted(stripeSignature: string, options?: any): AxiosPromise<void> {
            return localVarFp.checkoutSessionCompleted(stripeSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dispute(stripeSignature: string, options?: any): AxiosPromise<void> {
            return localVarFp.dispute(stripeSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoice(stripeSignature: string, options?: any): AxiosPromise<void> {
            return localVarFp.invoice(stripeSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentIntent(stripeSignature: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentIntent(stripeSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscription(stripeSignature: string, options?: any): AxiosPromise<void> {
            return localVarFp.subscription(stripeSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taxIdCreated(stripeSignature: string, options?: any): AxiosPromise<void> {
            return localVarFp.taxIdCreated(stripeSignature, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StripeWebhooksApi - object-oriented interface
 * @export
 * @class StripeWebhooksApi
 * @extends {BaseAPI}
 */
export class StripeWebhooksApi extends BaseAPI {
    /**
     * 
     * @param {string} stripeSignature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhooksApi
     */
    public checkoutSessionCompleted(stripeSignature: string, options?: AxiosRequestConfig) {
        return StripeWebhooksApiFp(this.configuration).checkoutSessionCompleted(stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stripeSignature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhooksApi
     */
    public dispute(stripeSignature: string, options?: AxiosRequestConfig) {
        return StripeWebhooksApiFp(this.configuration).dispute(stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stripeSignature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhooksApi
     */
    public invoice(stripeSignature: string, options?: AxiosRequestConfig) {
        return StripeWebhooksApiFp(this.configuration).invoice(stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stripeSignature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhooksApi
     */
    public paymentIntent(stripeSignature: string, options?: AxiosRequestConfig) {
        return StripeWebhooksApiFp(this.configuration).paymentIntent(stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stripeSignature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhooksApi
     */
    public subscription(stripeSignature: string, options?: AxiosRequestConfig) {
        return StripeWebhooksApiFp(this.configuration).subscription(stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stripeSignature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeWebhooksApi
     */
    public taxIdCreated(stripeSignature: string, options?: AxiosRequestConfig) {
        return StripeWebhooksApiFp(this.configuration).taxIdCreated(stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WineLabelsApi - axios parameter creator
 * @export
 */
export const WineLabelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a wine label
         * @param {CreateWineLabelRequest} createWineLabelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel: async (createWineLabelRequest: CreateWineLabelRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWineLabelRequest' is not null or undefined
            assertParamExists('createLabel', 'createWineLabelRequest', createWineLabelRequest)
            const localVarPath = `/api/wine-labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWineLabelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a wine label by id list
         * @param {DeleteWineLabelsRequest} deleteWineLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabels: async (deleteWineLabelsRequest: DeleteWineLabelsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteWineLabelsRequest' is not null or undefined
            assertParamExists('deleteLabels', 'deleteWineLabelsRequest', deleteWineLabelsRequest)
            const localVarPath = `/api/wine-labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteWineLabelsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Copy an existing wine label
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateLabel: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateLabel', 'id', id)
            const localVarPath = `/api/wine-labels/{id}/copy`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get wine label information by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWineLabel: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWineLabel', 'id', id)
            const localVarPath = `/api/wine-labels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search labels of the winery by searchText
         * @param {string} [searchText] 
         * @param {number} [pageNumber] 
         * @param {number} [size] 
         * @param {'CREATED' | 'HARVEST_YEAR' | 'PRODUCT_NAME'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLabels: async (searchText?: string, pageNumber?: number, size?: number, sortBy?: 'CREATED' | 'HARVEST_YEAR' | 'PRODUCT_NAME', sortDirection?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/wine-labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a wine label by id
         * @param {number} id 
         * @param {WineLabelView} wineLabelView 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel: async (id: number, wineLabelView: WineLabelView, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLabel', 'id', id)
            // verify required parameter 'wineLabelView' is not null or undefined
            assertParamExists('updateLabel', 'wineLabelView', wineLabelView)
            const localVarPath = `/api/wine-labels/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wineLabelView, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WineLabelsApi - functional programming interface
 * @export
 */
export const WineLabelsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WineLabelsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a wine label
         * @param {CreateWineLabelRequest} createWineLabelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLabel(createWineLabelRequest: CreateWineLabelRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWineLabelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLabel(createWineLabelRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a wine label by id list
         * @param {DeleteWineLabelsRequest} deleteWineLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLabels(deleteWineLabelsRequest: DeleteWineLabelsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLabels(deleteWineLabelsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Copy an existing wine label
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateLabel(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateWineLabelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateLabel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get wine label information by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWineLabel(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WineLabelView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWineLabel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search labels of the winery by searchText
         * @param {string} [searchText] 
         * @param {number} [pageNumber] 
         * @param {number} [size] 
         * @param {'CREATED' | 'HARVEST_YEAR' | 'PRODUCT_NAME'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchLabels(searchText?: string, pageNumber?: number, size?: number, sortBy?: 'CREATED' | 'HARVEST_YEAR' | 'PRODUCT_NAME', sortDirection?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WineLabelListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchLabels(searchText, pageNumber, size, sortBy, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a wine label by id
         * @param {number} id 
         * @param {WineLabelView} wineLabelView 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLabel(id: number, wineLabelView: WineLabelView, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLabel(id, wineLabelView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WineLabelsApi - factory interface
 * @export
 */
export const WineLabelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WineLabelsApiFp(configuration)
    return {
        /**
         * Create a wine label
         * @param {CreateWineLabelRequest} createWineLabelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLabel(createWineLabelRequest: CreateWineLabelRequest, options?: any): AxiosPromise<CreateWineLabelResponse> {
            return localVarFp.createLabel(createWineLabelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a wine label by id list
         * @param {DeleteWineLabelsRequest} deleteWineLabelsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLabels(deleteWineLabelsRequest: DeleteWineLabelsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLabels(deleteWineLabelsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Copy an existing wine label
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateLabel(id: number, options?: any): AxiosPromise<CreateWineLabelResponse> {
            return localVarFp.duplicateLabel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get wine label information by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWineLabel(id: number, options?: any): AxiosPromise<WineLabelView> {
            return localVarFp.getWineLabel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Search labels of the winery by searchText
         * @param {string} [searchText] 
         * @param {number} [pageNumber] 
         * @param {number} [size] 
         * @param {'CREATED' | 'HARVEST_YEAR' | 'PRODUCT_NAME'} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLabels(searchText?: string, pageNumber?: number, size?: number, sortBy?: 'CREATED' | 'HARVEST_YEAR' | 'PRODUCT_NAME', sortDirection?: 'ASC' | 'DESC', options?: any): AxiosPromise<WineLabelListResponse> {
            return localVarFp.searchLabels(searchText, pageNumber, size, sortBy, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a wine label by id
         * @param {number} id 
         * @param {WineLabelView} wineLabelView 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLabel(id: number, wineLabelView: WineLabelView, options?: any): AxiosPromise<void> {
            return localVarFp.updateLabel(id, wineLabelView, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WineLabelsApi - object-oriented interface
 * @export
 * @class WineLabelsApi
 * @extends {BaseAPI}
 */
export class WineLabelsApi extends BaseAPI {
    /**
     * Create a wine label
     * @param {CreateWineLabelRequest} createWineLabelRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WineLabelsApi
     */
    public createLabel(createWineLabelRequest: CreateWineLabelRequest, options?: AxiosRequestConfig) {
        return WineLabelsApiFp(this.configuration).createLabel(createWineLabelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a wine label by id list
     * @param {DeleteWineLabelsRequest} deleteWineLabelsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WineLabelsApi
     */
    public deleteLabels(deleteWineLabelsRequest: DeleteWineLabelsRequest, options?: AxiosRequestConfig) {
        return WineLabelsApiFp(this.configuration).deleteLabels(deleteWineLabelsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Copy an existing wine label
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WineLabelsApi
     */
    public duplicateLabel(id: number, options?: AxiosRequestConfig) {
        return WineLabelsApiFp(this.configuration).duplicateLabel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get wine label information by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WineLabelsApi
     */
    public getWineLabel(id: number, options?: AxiosRequestConfig) {
        return WineLabelsApiFp(this.configuration).getWineLabel(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search labels of the winery by searchText
     * @param {string} [searchText] 
     * @param {number} [pageNumber] 
     * @param {number} [size] 
     * @param {'CREATED' | 'HARVEST_YEAR' | 'PRODUCT_NAME'} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WineLabelsApi
     */
    public searchLabels(searchText?: string, pageNumber?: number, size?: number, sortBy?: 'CREATED' | 'HARVEST_YEAR' | 'PRODUCT_NAME', sortDirection?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return WineLabelsApiFp(this.configuration).searchLabels(searchText, pageNumber, size, sortBy, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a wine label by id
     * @param {number} id 
     * @param {WineLabelView} wineLabelView 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WineLabelsApi
     */
    public updateLabel(id: number, wineLabelView: WineLabelView, options?: AxiosRequestConfig) {
        return WineLabelsApiFp(this.configuration).updateLabel(id, wineLabelView, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WinefoPublicApi - axios parameter creator
 * @export
 */
export const WinefoPublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get public winefo
         * @param {string} wineLabelUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicWinefo: async (wineLabelUrl: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wineLabelUrl' is not null or undefined
            assertParamExists('getPublicWinefo', 'wineLabelUrl', wineLabelUrl)
            const localVarPath = `/winefo/{wineLabelUrl}`
                .replace(`{${"wineLabelUrl"}}`, encodeURIComponent(String(wineLabelUrl)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WinefoPublicApi - functional programming interface
 * @export
 */
export const WinefoPublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WinefoPublicApiAxiosParamCreator(configuration)
    return {
        /**
         * Get public winefo
         * @param {string} wineLabelUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicWinefo(wineLabelUrl: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicWineLabelView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicWinefo(wineLabelUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WinefoPublicApi - factory interface
 * @export
 */
export const WinefoPublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WinefoPublicApiFp(configuration)
    return {
        /**
         * Get public winefo
         * @param {string} wineLabelUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicWinefo(wineLabelUrl: string, options?: any): AxiosPromise<PublicWineLabelView> {
            return localVarFp.getPublicWinefo(wineLabelUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WinefoPublicApi - object-oriented interface
 * @export
 * @class WinefoPublicApi
 * @extends {BaseAPI}
 */
export class WinefoPublicApi extends BaseAPI {
    /**
     * Get public winefo
     * @param {string} wineLabelUrl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WinefoPublicApi
     */
    public getPublicWinefo(wineLabelUrl: string, options?: AxiosRequestConfig) {
        return WinefoPublicApiFp(this.configuration).getPublicWinefo(wineLabelUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


