import AddIcon from '@mui/icons-material/Add';
import { Fab, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '../../utils/modal/use-modal';
import CreateLabelModal from './create-label-modal';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

const CreateLabelButton: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [shouldOpenModal, setShouldOpenModal] = useState(false);

  const { openModal, isModalOpen, closeModal } = useModal();
  const openCreateLabelModal = () => {
    if (!matchPath(ROUTES.LABELS, location.pathname)) {
      navigate(ROUTES.LABELS);
      setShouldOpenModal(true);
    } else {
      openModal();
    }
  };

  useEffect(() => {
    if (shouldOpenModal) {
      openModal();
      setShouldOpenModal(false);
    }
  }, [location.pathname]);

  return (
    <>
      <Fab
        color="primary"
        variant="extended"
        size="small"
        sx={{ mr: 2 }}
        onClick={openCreateLabelModal}
      >
        <Typography variant="button" sx={{ lineHeight: 1 }}>
          {t('header.create-label')}
        </Typography>
        <AddIcon />
      </Fab>
      <CreateLabelModal open={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default CreateLabelButton;
