import LayersIcon from '@mui/icons-material/Layers';
import { IconButton } from '@mui/material';
import React from 'react';
import useDuplicateLabel from '../../data/wine-label/use-duplicate-label';

interface DuplicateLabelButtonProps {
  id: number;
}

const DuplicateLabelButton: React.FC<DuplicateLabelButtonProps> = ({ id }) => {
  const duplicateLabel = useDuplicateLabel();

  return (
    <IconButton
      onClick={() => duplicateLabel.mutate({ id })}
      sx={{ ml: 'auto', mr: 'auto' }}
    >
      <LayersIcon />
    </IconButton>
  );
};

export default DuplicateLabelButton;
