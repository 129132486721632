import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Amplify } from 'aws-amplify';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import awsConfig from './app-config';
import theme from './assets/theme/mui-theme';
import AxiosInterceptor from './components/axios-interceptor/axios-interceptor';
import Router from './router';
import { AuthProvider } from './services/auth-context';
import { SearchProvider } from './services/search-context';
import { SnackbarProvider } from './services/snackbar-context';
import './utils/localization/i18n';
import useFallbackLanguage from './utils/localization/use-fallback-language';

Amplify.configure(awsConfig());

const App: React.FC = () => {
  useFallbackLanguage();

  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <SnackbarProvider>
          <AuthProvider>
            <AxiosInterceptor>
              <SearchProvider>
                <RouterProvider router={Router} />
              </SearchProvider>
            </AxiosInterceptor>
          </AuthProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
