export default [
  {
    value: 'AF',
    label: 'Afganistán',
  },
  {
    value: 'AL',
    label: 'Albania',
  },
  {
    value: 'DZ',
    label: 'Argelia',
  },
  {
    value: 'AS',
    label: 'Samoa Americana',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguila',
  },
  {
    value: 'AG',
    label: 'Antigua y Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentina',
  },
  {
    value: 'AM',
    label: 'Armenia',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Australia',
  },
  {
    value: 'AT',
    label: 'Austria',
  },
  {
    value: 'AZ',
    label: 'Azerbaiyán',
  },
  {
    value: 'BS',
    label: 'Bahamas',
  },
  {
    value: 'BH',
    label: 'Bahréin',
  },
  {
    value: 'BD',
    label: 'Bangladesh',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Bielorrusia',
  },
  {
    value: 'BE',
    label: 'Bélgica',
  },
  {
    value: 'BZ',
    label: 'Belice',
  },
  {
    value: 'BJ',
    label: 'Benín',
  },
  {
    value: 'BM',
    label: 'Bermudas',
  },
  {
    value: 'BT',
    label: 'Bután',
  },
  {
    value: 'BO',
    label: 'Bolivia',
  },
  {
    value: 'BA',
    label: 'Bosnia y Hercegovina',
  },
  {
    value: 'BW',
    label: 'Botsuana',
  },
  {
    value: 'BR',
    label: 'Brasil',
  },
  {
    value: 'IO',
    label: 'Islas Vírgenes Británicas',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bulgaria',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Camboya',
  },
  {
    value: 'CM',
    label: 'Camerún',
  },
  {
    value: 'CA',
    label: 'Canadá',
  },
  {
    value: 'KY',
    label: 'Islas Caimán',
  },
  {
    value: 'CF',
    label: 'República Centroafricana',
  },
  {
    value: 'TD',
    label: 'Chad',
  },
  {
    value: 'CL',
    label: 'Chile',
  },
  {
    value: 'CN',
    label: 'China',
  },
  {
    value: 'CO',
    label: 'Colombia',
  },
  {
    value: 'KM',
    label: 'Comoras',
  },
  {
    value: 'CG',
    label: 'Congo',
  },
  {
    value: 'CK',
    label: 'Islas Cook',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
  },
  {
    value: 'CI',
    label: 'Costa de Marfil',
  },
  {
    value: 'HR',
    label: 'Croacia',
  },
  {
    value: 'CU',
    label: 'Cuba',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Chipre',
  },
  {
    value: 'CZ',
    label: 'República Checa',
  },
  {
    value: 'CZ',
    label: 'Korea del Norte',
  },
  {
    value: 'CD',
    label: 'Congo',
  },
  {
    value: 'DK',
    label: 'Dinamarca',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Yibuti',
  },
  {
    value: 'DM',
    label: 'Dominica',
  },
  {
    value: 'DO',
    label: 'República Dominicana',
  },
  {
    value: 'EC',
    label: 'Ecuador',
  },
  {
    value: 'EG',
    label: 'Egipto',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'GQ',
    label: 'Guinea Ecuatorial',
  },
  {
    value: 'ER',
    label: 'Eritrea',
  },
  {
    value: 'EE',
    label: 'Estonia',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etiopía',
  },
  {
    value: 'FK',
    label: 'Islas Malvinas',
  },
  {
    value: 'FO',
    label: 'Islas Feroe',
  },
  {
    value: 'FJ',
    label: 'Fiyi',
  },
  {
    value: 'FI',
    label: 'Finlandia',
  },
  {
    value: 'FR',
    label: 'Francia',
  },
  {
    value: 'FR',
    label: 'Departamentos y territorios franceses en el Océano Índico',
  },
  {
    value: 'GF',
    label: 'Guayana Francesa',
  },
  {
    value: 'PF',
    label: 'Polinesia Francesa',
  },
  {
    value: 'GA',
    label: 'Gabón',
  },
  {
    value: 'GM',
    label: 'Gambia',
  },
  {
    value: 'GE',
    label: 'Georgia',
  },
  {
    value: 'DE',
    label: 'Alemania',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
  },
  {
    value: 'GR',
    label: 'Grecia',
  },
  {
    value: 'GL',
    label: 'Groenlandia',
  },
  {
    value: 'GD',
    label: 'Granada',
  },
  {
    value: 'GP',
    label: 'Guadalupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  {
    value: 'GG',
    label: 'Guinea',
  },
  {
    value: 'GN',
    label: 'Guinea-Bissau',
  },
  {
    value: 'GY',
    label: 'Guyana',
  },
  {
    value: 'HT',
    label: 'Haití',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hong Kong',
  },
  {
    value: 'HU',
    label: 'Hungría',
  },
  {
    value: 'IS',
    label: 'Islandia',
  },
  {
    value: 'IN',
    label: 'India',
  },
  {
    value: 'ID',
    label: 'Indonesia',
  },
  {
    value: 'IR',
    label: 'Irán',
  },
  {
    value: 'IQ',
    label: 'Iraq',
  },
  {
    value: 'IE',
    label: 'Irlanda',
  },
  {
    value: 'IL',
    label: 'Israel',
  },
  {
    value: 'IT',
    label: 'Italia',
  },
  {
    value: 'JM',
    label: 'Jamaica',
  },
  {
    value: 'JP',
    label: 'Japón',
  },
  {
    value: 'JO',
    label: 'Jordania',
  },
  {
    value: 'KZ',
    label: 'Kazajstán',
  },
  {
    value: 'KE',
    label: 'Kenia',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Corea',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuwait',
  },
  {
    value: 'KG',
    label: 'Kirguistán',
  },
  {
    value: 'LA',
    label: 'R.D.P. Lao',
  },
  {
    value: 'LV',
    label: 'Letonia',
  },
  {
    value: 'LB',
    label: 'Líbano',
  },
  {
    value: 'LS',
    label: 'Lesotho',
  },
  {
    value: 'LR',
    label: 'Liberia',
  },
  {
    value: 'LY',
    label: 'Libia',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
  },
  {
    value: 'LT',
    label: 'Lituania',
  },
  {
    value: 'LU',
    label: 'Luxemburgo',
  },
  {
    value: 'MO',
    label: 'Macao',
  },
  {
    value: 'MG',
    label: 'Madagascar',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Malasia',
  },
  {
    value: 'MV',
    label: 'Maldivas',
  },
  {
    value: 'ML',
    label: 'Malí',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Islas Marshall',
  },
  {
    value: 'MQ',
    label: 'Martinica',
  },
  {
    value: 'MR',
    label: 'Mauritania',
  },
  {
    value: 'MU',
    label: 'Mauricio',
  },
  {
    value: 'MX',
    label: 'México',
  },
  {
    value: 'FM',
    label: 'Micronesia',
  },
  {
    value: 'MD',
    label: 'Moldavia',
  },
  {
    value: 'MC',
    label: 'Mónaco',
  },
  {
    value: 'MN',
    label: 'Mongolia',
  },
  {
    value: 'ME',
    label: 'Montenegro',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Marruecos',
  },
  {
    value: 'MZ',
    label: 'Mozambique',
  },
  {
    value: 'MM',
    label: 'Myanmar',
  },
  {
    value: 'NA',
    label: 'Namibia',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepal',
  },
  {
    value: 'NL',
    label: 'Países Bajos',
  },
  {
    value: 'NC',
    label: 'Nueva Caledonia',
  },
  {
    value: 'NZ',
    label: 'Nueva Zelanda',
  },
  {
    value: 'NI',
    label: 'Nicaragua',
  },
  {
    value: 'NE',
    label: 'Níger',
  },
  {
    value: 'NG',
    label: 'Nigeria',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Isla Norfolk',
  },
  {
    value: 'NF',
    label: 'Macedonia del Norte',
  },
  {
    value: 'MP',
    label: 'Islas Marianas del Norte',
  },
  {
    value: 'NO',
    label: 'Noruega',
  },
  {
    value: 'OM',
    label: 'Omán',
  },
  {
    value: 'PK',
    label: 'Pakistán',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Panamá',
  },
  {
    value: 'PG',
    label: 'Papúa Nueva Guinea',
  },
  {
    value: 'PY',
    label: 'Paraguay',
  },
  {
    value: 'PE',
    label: 'Perú',
  },
  {
    value: 'PH',
    label: 'Filipinas',
  },
  {
    value: 'PL',
    label: 'Polonia',
  },
  {
    value: 'PT',
    label: 'Portugal',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'QA',
    label: 'Qatar',
  },
  {
    value: 'RO',
    label: 'Rumanía',
  },
  {
    value: 'RU',
    label: 'Rusia',
  },
  {
    value: 'RW',
    label: 'Ruanda',
  },
  {
    value: 'SH',
    label: 'Santa Elena, Ascensión y Tristán da Cunha',
  },
  {
    value: 'KN',
    label: 'San Cristóbal y Nieves',
  },
  {
    value: 'LC',
    label: 'Santa Lucía',
  },
  {
    value: 'PM',
    label: 'San Pedro y Miquelón',
  },
  {
    value: 'VC',
    label: 'San Vicente y las Granadinas',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Santo Tomé y Príncipe',
  },
  {
    value: 'SA',
    label: 'Arabia Saudita',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Serbia',
  },
  {
    value: 'SC',
    label: 'Seychelles',
  },
  {
    value: 'SL',
    label: 'Sierra Leona',
  },
  {
    value: 'SG',
    label: 'Singapur',
  },
  {
    value: 'SG',
    label: 'San Martín',
  },
  {
    value: 'SK',
    label: 'Eslovaquia',
  },
  {
    value: 'SI',
    label: 'Eslovenia',
  },
  {
    value: 'SB',
    label: 'Islas Salomón',
  },
  {
    value: 'SO',
    label: 'Somalia',
  },
  {
    value: 'ZA',
    label: 'Sudafrica',
  },
  {
    value: 'SS',
    label: 'Sudán del Sur',
  },
  {
    value: 'ES',
    label: 'España',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
  },
  {
    value: 'SD',
    label: 'Sudán',
  },
  {
    value: 'SR',
    label: 'Surinam',
  },
  {
    value: 'SE',
    label: 'Suecia',
  },
  {
    value: 'CH',
    label: 'Suiza',
  },
  {
    value: 'SY',
    label: 'República Árabe Siria',
  },
  {
    value: 'TW',
    label: 'Taiwán',
  },
  {
    value: 'TJ',
    label: 'Tayikistán',
  },
  {
    value: 'TZ',
    label: 'Tanzania',
  },
  {
    value: 'TH',
    label: 'Tailandia',
  },
  {
    value: 'TL',
    label: 'Timor Oriental',
  },
  {
    value: 'TG',
    label: 'Togo',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinidad y Tobago',
  },
  {
    value: 'TN',
    label: 'Túnez',
  },
  {
    value: 'TR',
    label: 'Türkiye',
  },
  {
    value: 'TM',
    label: 'Turkmenistán',
  },
  {
    value: 'TC',
    label: 'Turkmenistán',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ucrania',
  },
  {
    value: 'AE',
    label: 'Emiratos Árabes Unidos',
  },
  {
    value: 'GB',
    label: 'Reino Unido',
  },
  {
    value: 'US',
    label: 'Estados Unidos',
  },
  {
    value: 'US',
    label: 'Islas Vírgenes de los Estados Unidos',
  },
  {
    value: 'UY',
    label: 'Uruguay',
  },
  {
    value: 'UZ',
    label: 'Uzbekistán',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Vietnam',
  },
  {
    value: 'WF',
    label: 'Wallis y Futuna',
  },
  {
    value: 'YE',
    label: 'Yemen',
  },
  {
    value: 'ZM',
    label: 'Zambia',
  },
  {
    value: 'ZM',
    label: 'Zimbabue',
  },
];
