import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const PreviewSectionIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 31 30">
      <g clipPath="url(#clip0_591_47109)">
        <path
          d="M13.4584 9.81641H22.6251M13.4584 14.9727H22.6251M13.4584 20.0573H22.6251"
          fill="none"
          stroke="currentColor"
          strokeWidth="2.29167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.5 15.1172C29.5 7.52604 23.3411 1.36719 15.75 1.36719C8.15885 1.36719 2 7.52604 2 15.1172C2 22.7083 8.15885 28.8672 15.75 28.8672C23.3411 28.8672 29.5 22.7083 29.5 15.1172Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="2.29167"
          strokeMiterlimit="10"
        />
        <path
          d="M9.44792 10.3919C9.76433 10.3919 10.0208 10.1354 10.0208 9.81901C10.0208 9.5026 9.76433 9.24609 9.44792 9.24609C9.1315 9.24609 8.875 9.5026 8.875 9.81901C8.875 10.1354 9.1315 10.3919 9.44792 10.3919Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="2.29167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.44792 15.6185C9.76433 15.6185 10.0208 15.362 10.0208 15.0456C10.0208 14.7292 9.76433 14.4727 9.44792 14.4727C9.1315 14.4727 8.875 14.7292 8.875 15.0456C8.875 15.362 9.1315 15.6185 9.44792 15.6185Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="2.29167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.44792 20.7044C9.76433 20.7044 10.0208 20.4479 10.0208 20.1315C10.0208 19.8151 9.76433 19.5586 9.44792 19.5586C9.1315 19.5586 8.875 19.8151 8.875 20.1315C8.875 20.4479 9.1315 20.7044 9.44792 20.7044Z"
          fill="none"
          stroke="currentColor"
          strokeWidth="2.29167"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_591_47109">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default PreviewSectionIcon;
