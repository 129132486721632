import { Box, BoxProps, Card, CardProps } from '@mui/material';
import React from 'react';
import FlexBox from '../flexbox/flexbox';
import Background, { BackgroundProps } from './background';

interface TemplateWithCardProps extends BoxProps {
  backgroundProps: BackgroundProps;
  cardContent: React.ReactNode;
  cardProps?: CardProps;
  additionalContent?: React.ReactNode;
}

const TemplateWithCard: React.FC<TemplateWithCardProps> = ({
  backgroundProps,
  cardContent,
  cardProps,
  additionalContent,
}) => {
  const { isCardCentered } = backgroundProps;

  return (
    <Background {...backgroundProps}>
      <FlexBox
        sx={{
          width: { xs: '100%', md: isCardCentered ? '100%' : '50%' },
          justifyContent: 'center',
          overflowX: 'visible',
          mx: { xs: 1, md: isCardCentered ? 0 : 6.25 },
        }}
      >
        <Card
          sx={{
            minWidth: 'min(550px, 100%)',
            maxWidth: 'min(550px, 100%)',
            ...cardProps?.sx,
          }}
        >
          {cardContent}
        </Card>
      </FlexBox>
      <Box display={{ xs: 'none', md: 'contents' }}>{additionalContent}</Box>
    </Background>
  );
};

export default TemplateWithCard;
