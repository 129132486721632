import Typography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';

const TooltipTypography: React.FC<TypographyProps> = ({ children }) => {
  return (
    <Typography fontSize={11} fontWeight={500}>
      {children}
    </Typography>
  );
};

export default TooltipTypography;
