import React from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import axios from '../../data/axios';
import { useSnackbar } from '../../services/snackbar-context';

const useAxiosErrorInterceptor = (): void => {
  const { setSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const interceptor = React.useCallback(
    (error: { response: { status: number } }) => {
      if (error.response?.status === 401) {
        if (![ROUTES.LOGIN, ROUTES.SIGNUP].includes(window.location.pathname)) {
          window.location = ROUTES.LOGIN as string & Location;
        }
      } else if (error.response?.status === 403) {
        //  handled in hooks
      } else if (
        (error.response?.status === 404 &&
          [ROUTES.COSTUMER_WINEFO_POINT, ROUTES.SIGNUP].some((path) =>
            matchPath(path, window.location.pathname)
          )) ||
        (error.response?.status === 400 &&
          matchPath(ROUTES.SIGNUP, window.location.pathname))
      ) {
        //  handled on location
      } else {
        setSnackbar({
          message: t('error.general-network-error'),
        });
      }
      return Promise.reject(error);
    },
    [t, setSnackbar]
  );

  React.useEffect(() => {
    const addedInterceptor = axios.interceptors.response.use(
      (res) => res,
      interceptor
    );
    return () => {
      axios.interceptors.response.eject(addedInterceptor);
    };
  }, [interceptor]);
};

export default useAxiosErrorInterceptor;
