import { Delete } from '@mui/icons-material';
import { Checkbox, FormControlLabel, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FlexBox from '../../../components/flexbox/flexbox';
import IngredientLabel from '../../../components/ingredient-label/ingredient-label';
import ConfirmationModal from '../../../components/modal/confirmation-modal';
import { Ingredient } from '../../../models/ingredient';
import { useIngredientContext } from '../../../services/ingredient-context';
import { useSnackbar } from '../../../services/snackbar-context';
import useModal from '../../../utils/modal/use-modal';
import CustomIngredientTranslationsButton from './custom-ingredient-translations-button';
import CustomIngredientTranslationsModal from './custom-ingredient-translations-modal';

interface CustomIngredientControlsProps {
  ingredient: Ingredient;
}

const CustomIngredientControls: React.FC<CustomIngredientControlsProps> = ({
  ingredient,
}) => {
  const { t } = useTranslation();
  const { removeCustomIngredient, toggleCustomIngredientAllergenStatus } =
    useIngredientContext();
  const {
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
    isModalOpen: isDeleteModalOpen,
  } = useModal();
  const {
    openModal: openTranslationsModal,
    closeModal: closeTranslationsModal,
    isModalOpen: isTranslationsModalOpen,
  } = useModal();
  const { setSnackbar } = useSnackbar();

  const hasTranslations = (ingredient.translations || []).length > 0;
  const handleDeleteClick = () => {
    openDeleteModal();
  };
  const handleDeleteConfirm = () => {
    removeCustomIngredient(ingredient);
    closeDeleteModal();
    setSnackbar({ message: t('common.delete-ingredient-success') });
  };
  const toggleAllergenStatus = () => {
    toggleCustomIngredientAllergenStatus(ingredient);
  };

  return (
    <>
      <FlexBox alignItems="center" width="100%">
        <IngredientLabel ingredient={ingredient} />
        <IconButton sx={{ ml: 3 }} onClick={handleDeleteClick}>
          <Delete color="inherit" />
        </IconButton>
        <FormControlLabel
          sx={{ ml: 'auto' }}
          control={
            <Checkbox
              checked={ingredient.allergen}
              onClick={toggleAllergenStatus}
            />
          }
          label={t('edit-label.mark-allergen')}
        />
      </FlexBox>
      <CustomIngredientTranslationsButton
        version={hasTranslations ? 'edit' : 'add'}
        onClick={openTranslationsModal}
      />
      <CustomIngredientTranslationsModal
        open={isTranslationsModalOpen}
        onClose={closeTranslationsModal}
        ingredient={ingredient}
      />
      <ConfirmationModal
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDeleteConfirm}
        labels={{
          message: t('common.delete-ingredient-message'),
        }}
      />
    </>
  );
};

export default CustomIngredientControls;
