import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultLanguage, LANGUAGES } from '../../constants/languages';

const useFallbackLanguage = (): void => {
  const { i18n } = useTranslation();
  const { isInitialized } = i18n;
  useEffect(() => {
    if (isInitialized) {
      const language = i18n.language.substr(0, 2);
      i18n.changeLanguage(language in LANGUAGES ? language : defaultLanguage);
    }
  }, [isInitialized]);
};

export default useFallbackLanguage;
