import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, ButtonProps } from '@mui/material';
import React from 'react';

const BackButton: React.FC<ButtonProps> = ({ sx, ...rest }) => {
  return (
    <Button
      variant="outlined"
      startIcon={<ChevronLeftIcon />}
      sx={{ borderColor: 'rgba(33, 150, 243, 0.50)', ...sx }}
      {...rest}
    />
  );
};

export default BackButton;
