import { GridProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  WineLabelCustomPictogramViewPictogramGroupEnum as CustomPictogramGroup,
  WineLabelCustomPictogramView,
} from '../../api';
import useDeleteCustomPictogram from '../../data/pictograms/use-delete-custom-pictogram';
import { usePictogramContext } from '../../services/pictogram-context';
import { useSnackbar } from '../../services/snackbar-context';
import splitFileName from '../../utils/file/split-file-name';
import PictogramItem from './pictogram-item';

interface CustomPictogramsProps {
  group: CustomPictogramGroup;
  onPictogramToggle?: () => void;
  gridProps?: GridProps;
}

const CustomPictograms: React.FC<CustomPictogramsProps> = ({
  group,
  onPictogramToggle,
  gridProps,
}) => {
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const { customPictograms, removeCustomPictogram, toggleCustomPictogram } =
    usePictogramContext();
  const deleteCustomPictogram = useDeleteCustomPictogram();

  const handleToggle = (pictogram: WineLabelCustomPictogramView) => {
    toggleCustomPictogram(pictogram);
    onPictogramToggle?.();
  };

  const handleDelete = (pictogramId?: number) => {
    if (typeof pictogramId === 'number') {
      const onSuccess = () => {
        removeCustomPictogram(pictogramId);
        setSnackbar({ message: t('edit-label.pictogram-delete-snackbar') });
      };

      deleteCustomPictogram.mutate({ pictogramId, onSuccess });
    }
  };

  return (
    <>
      {customPictograms
        .filter((it) => it.pictogramGroup === group)
        .map((pictogram) => (
          <PictogramItem
            key={pictogram.id}
            checked={!!pictogram.isSelected}
            value={pictogram.id}
            onChange={() => {
              handleToggle(pictogram);
            }}
            imageSrc={pictogram.downloadUrl || ''}
            label={splitFileName(pictogram.originalFileName || '').baseName}
            onDelete={() => handleDelete(pictogram.id)}
            gridProps={gridProps}
          />
        ))}
    </>
  );
};

export default CustomPictograms;
