/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, CardHeader, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  IngredientGroupOrderIngredientGroupEnum,
  PictogramsDtoAllergenEnum,
  PremiumFeature,
  WineLabelCustomPictogramView,
} from '../../api';
import AllergenIcon from '../../assets/icons/allergen-section-icon';
import HeaderIcon from '../../assets/icons/ingredients-section-icon';
import CustomerContainer from '../../components/customer-container/customer-container';
import FlexBox from '../../components/flexbox/flexbox';
import IngredientList from '../../components/ingredient-list/ingredient-list';
import { primary } from '../../constants/colors';
import { AllergenPictograms } from '../../constants/pictograms';
import { SequencedIngredientOrGroup } from '../../models/ingredient';
import splitFileName from '../../utils/file/split-file-name';
import Pictogram from './pictogram';
import { getColors } from './winefo-point-utils';

interface IngredientsProps {
  ingredientsAndGroups: SequencedIngredientOrGroup[];
  flexibleGroups: IngredientGroupOrderIngredientGroupEnum[];
  defaultAllergen?: PictogramsDtoAllergenEnum;
  customAllergenPictogram?: WineLabelCustomPictogramView;
  premiumFeature?: PremiumFeature;
}

const Ingredients = ({
  ingredientsAndGroups,
  flexibleGroups,
  defaultAllergen,
  customAllergenPictogram,
  premiumFeature,
}: IngredientsProps) => {
  const { t } = useTranslation();
  const hasIngredients = ingredientsAndGroups.length > 0;
  const allergenItem = AllergenPictograms.find(
    (item) => item.id === defaultAllergen
  );
  const hasCustomAllergenPictogram =
    typeof customAllergenPictogram !== 'undefined';
  const colors = getColors(premiumFeature);

  return hasIngredients ? (
    <CustomerContainer
      title={t('common.ingredients')}
      icon={<HeaderIcon />}
      colors={colors}
    >
      <IngredientList
        ingredientsAndGroups={ingredientsAndGroups}
        flexibleGroups={flexibleGroups}
      />
      {(allergenItem || hasCustomAllergenPictogram) && (
        <>
          <CardHeader
            sx={{
              backgroundColor: premiumFeature?.secondaryColour || primary[100],
              color: premiumFeature?.secondaryTextColour || 'inherit',
              '& .MuiCardHeader-title': { fontFamily: 'inherit' },
            }}
            title={
              <FlexBox gap={1} alignItems="center">
                <AllergenIcon />
                <Typography fontFamily="inherit">
                  {t('common.allergens')}
                </Typography>
              </FlexBox>
            }
          />
          <Box sx={{ p: 2, display: 'grid', placeItems: 'center' }}>
            {allergenItem && (
              <>
                <Pictogram src={allergenItem.src} />
                <Typography
                  fontSize={12}
                  color="rgba(0,0,0,0.6)"
                  fontFamily="inherit"
                >
                  {t(allergenItem.name)}
                </Typography>
              </>
            )}
            {hasCustomAllergenPictogram && (
              <>
                <Pictogram src={customAllergenPictogram.downloadUrl} />
                <Typography
                  fontSize={12}
                  color="rgba(0,0,0,0.6)"
                  fontFamily="inherit"
                >
                  {customAllergenPictogram.originalFileName &&
                    splitFileName(customAllergenPictogram.originalFileName)
                      .baseName}
                </Typography>
              </>
            )}
          </Box>
        </>
      )}
    </CustomerContainer>
  ) : (
    <></>
  );
};

export default Ingredients;
