import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const NutritionFilledIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 24" fill="none">
      <path d="M16.2734 4.61719C15.7578 3.4375 15.5 2.14844 15.5 0.75V0H14.75C13.8125 0.0078125 12.9375 0.1875 12.125 0.539062C11.3125 0.890625 10.5977 1.37109 9.98047 1.98047C9.36328 2.58984 8.87891 3.30469 8.52734 4.125C8.17578 4.94531 8 5.82031 8 6.75C8.00781 7.14062 8.03906 7.51172 8.09375 7.86328C8.14844 8.21484 8.23828 8.55469 8.36328 8.88281C8.48828 9.21094 8.63281 9.53125 8.79688 9.84375C8.96094 10.1562 9.15234 10.4727 9.37109 10.793C9.42578 10.8633 9.46484 10.9336 9.48828 11.0039C9.51172 11.0742 9.52344 11.1523 9.52344 11.2383C9.51562 11.4492 9.44141 11.6289 9.30078 11.7773C9.16016 11.9258 8.98438 12 8.77344 12C8.52344 12 8.32031 11.9023 8.16406 11.707C7.97656 11.4648 7.79688 11.2031 7.625 10.9219C7.45312 10.6406 7.30469 10.3438 7.17969 10.0312C7.05469 9.71875 6.94531 9.40625 6.85156 9.09375C6.75781 8.78125 6.68359 8.47656 6.62891 8.17969C6.13672 8.60156 5.69531 9.07422 5.30469 9.59766C4.91406 10.1211 4.58594 10.6719 4.32031 11.25C4.05469 11.8281 3.85156 12.4336 3.71094 13.0664C3.57031 13.6992 3.5 14.3438 3.5 15C3.5 15.8281 3.60938 16.625 3.82812 17.3906C4.04688 18.1562 4.34766 18.875 4.73047 19.5469C5.11328 20.2188 5.58203 20.8242 6.13672 21.3633C6.69141 21.9023 7.30078 22.3711 7.96484 22.7695C8.62891 23.168 9.34375 23.4727 10.1094 23.6836C10.875 23.8945 11.6719 24 12.5 24C13.3281 24 14.125 23.8906 14.8906 23.6719C15.6562 23.4531 16.375 23.1523 17.0469 22.7695C17.7188 22.3867 18.3242 21.918 18.8633 21.3633C19.4023 20.8086 19.8711 20.1992 20.2695 19.5352C20.668 18.8711 20.9727 18.1562 21.1836 17.3906C21.3945 16.625 21.5 15.8281 21.5 15C21.5 13.6016 21.2422 12.3125 20.7266 11.1328C20.2109 9.95312 19.4688 8.86719 18.5 7.875C17.5312 6.88281 16.7891 5.79688 16.2734 4.61719Z" />
    </SvgIcon>
  );
};

export default NutritionFilledIcon;
