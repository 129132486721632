import { yupResolver } from '@hookform/resolvers/yup';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FlexBox from '../../components/flexbox/flexbox';
import { ROUTES } from '../../constants/routes';
import useValidateRegistrationCode from '../../data/registration/use-validate-registration-code';
import { SignupFormValue } from '../../models/signup';
import { useSnackbar } from '../../services/snackbar-context';
import SignupBlocks, { SignupBlockType } from './signup-blocks';
import signupFormSchema from './signup-form-schema';
import { useBlocksStatuses } from './use-blocks-statuses';
import { useRegisterUser } from './use-register-user';

interface SignupFormProps {
  onProgress: () => void;
}

const SignupForm: React.FC<SignupFormProps> = ({ onProgress }) => {
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const register = useRegisterUser();
  const registrationCodeValidation = useValidateRegistrationCode();
  const [expandedBlocks, setExpandedBlocks] = useState<SignupBlockType[]>([
    'company',
  ]);
  const [formerlyExpandedBlocks, setFormerlyExpandedBlocks] = useState<
    SignupBlockType[]
  >(['company']);
  const [isLoading, setIsLoading] = useState(false);
  const handleBlockToggle =
    (block: SignupBlockType) =>
    (event: SyntheticEvent, isExpanded: boolean) => {
      setExpandedBlocks(
        isExpanded ? [block] : [...expandedBlocks.filter((b) => b !== block)]
      );

      if (isExpanded) {
        const blockWasFormerlyExpanded = formerlyExpandedBlocks.includes(block);
        if (!blockWasFormerlyExpanded) {
          onProgress();
        }
        setFormerlyExpandedBlocks([...formerlyExpandedBlocks, block]);
      }
    };
  const {
    handleSubmit,
    control,
    formState,
    trigger,
    setError,
    setValue,
    watch,
  } = useForm<SignupFormValue>({
    mode: 'all',
    resolver: yupResolver(signupFormSchema(t)),
  });
  const { setInitiallyValidated, blocksDirtyStatus, blocksValidationStatus } =
    useBlocksStatuses(formState);
  const companyIdCountry = watch('companyIdCountry');
  const country = watch('country');

  useEffect(() => {
    if (!!companyIdCountry && !country)
      setValue('country', companyIdCountry, { shouldDirty: true });
  }, [companyIdCountry]);

  const signup = async (data: SignupFormValue) => {
    try {
      await register(data);
      onProgress();
      setTimeout(() => {
        navigate(ROUTES.VERIFY_EMAIL);
      }, 500);
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  };
  const handleRegistrationCodeError = (
    error: AxiosError<{ errorCode?: string }>
  ) => {
    const errorStatus = error.response?.status;
    const is404Error = errorStatus === 404;
    const is400Error = errorStatus === 400;
    const errorCode = error.response?.data?.errorCode;
    if (is404Error) {
      if (errorCode === 'REGISTRATION_CODE_NOT_FOUND') {
        setError('registrationCode', {
          type: 'manual',
          message: t('signup.registration-code-error-not-found'),
        });
      } else {
        setSnackbar({
          message: t('error.general-network-error'),
        });
      }
    }
    if (is400Error) {
      if (errorCode === 'REGISTRATION_CODE_ALREADY_USED') {
        setError('registrationCode', {
          type: 'manual',
          message: t('signup.registration-code-error-already-used'),
        });
      } else {
        setSnackbar({
          message: t('error.general-network-error'),
        });
      }
    }
    setIsLoading(false);
  };
  const validateRegistrationCode = (
    registrationCode: string,
    onSuccess: () => void
  ) => {
    registrationCodeValidation.mutate({
      variables: { registrationCode },
      onSuccess: onSuccess,
      onError: handleRegistrationCodeError,
    });
  };

  const onFormSubmitSuccess = async (data: SignupFormValue) => {
    setIsLoading(true);
    if (data.registrationCode) {
      validateRegistrationCode(data.registrationCode, () => signup(data));
    } else {
      signup(data);
    }
  };
  const onFormSubmitError = () => {
    const blocksToExpand: SignupBlockType[] = [];
    if (!blocksValidationStatus.company) {
      blocksToExpand.push('company');
    }
    if (!blocksValidationStatus.location) {
      blocksToExpand.push('location');
    }
    if (!blocksValidationStatus.account) {
      blocksToExpand.push('account');
    }
    setExpandedBlocks(blocksToExpand);
  };

  useEffect(() => {
    trigger();
    setInitiallyValidated(true);
  }, []);

  return (
    <form onSubmit={handleSubmit(onFormSubmitSuccess, onFormSubmitError)}>
      <SignupBlocks
        control={control}
        formState={formState}
        watch={watch}
        trigger={trigger}
        expandedBlocks={expandedBlocks}
        handleBlockToggle={handleBlockToggle}
        blocksValidationStatus={blocksValidationStatus}
        blocksDirtyStatus={blocksDirtyStatus}
      />
      <FlexBox justifyContent="flex-end" mt={2} mb={6}>
        <Button
          variant="contained"
          type="submit"
          endIcon={<ChevronRightIcon />}
          disabled={
            isLoading ||
            !blocksDirtyStatus.company ||
            !blocksDirtyStatus.location
          }
        >
          {t('signup.create-account')}
        </Button>
      </FlexBox>
    </form>
  );
};

export default SignupForm;
