import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  styled,
} from '@mui/material';
import { primary } from '../../constants/colors';

interface SwitchProps extends MuiSwitchProps {
  checkedcolor?: string;
  isCustom?: boolean;
}

const Switch = styled((props: SwitchProps) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme, ...props }) =>
  props.isCustom
    ? {
        width: 48,
        height: 24,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(24px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.grey[400],
              boxShadow: 26,
              opacity: 1,
              border: 0,
            },
            '& .MuiSwitch-thumb': {
              color: props.checkedcolor || primary[100],
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 24,
          height: 24,
        },
        '& .MuiSwitch-track': {
          borderRadius: 24 / 2,
          backgroundColor: theme.palette.grey[400],
          boxShadow: 27,
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }
    : {}
);

export default Switch;
