import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import backgroundImage from '../../assets/images/edit-label-background.png';
import FlexBox from '../../components/flexbox/flexbox';
import NavigationGuard from '../../components/navigation-guard/navigation-guard';
import Background from '../../components/screen-template/background';
import { ROUTES } from '../../constants/routes';
import useUpdateWineLabel from '../../data/wine-label/use-update-wine-label';
import { EditLabelSections, LabelValue } from '../../models/label';
import { useIngredientContext } from '../../services/ingredient-context';
import { usePictogramContext } from '../../services/pictogram-context';
import { useSnackbar } from '../../services/snackbar-context';
import editLabelSchema from './edit-label-schema';
import { useToWineLabelView } from './edit-label-utils';
import GeneralInfoSection from './general-info-section/general-info-section';
import EditLabelHeader from './header-section/edit-label-header';
import IngredientsSection from './ingredients-section/ingredients-section';
import useUploadPictograms from './use-upload-pictograms';

interface EditLabelProps {
  labelValue: LabelValue;
}

const EditLabel: React.FC<EditLabelProps> = ({ labelValue }) => {
  const { t } = useTranslation();
  const { setSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    hasChange: hasIngredientsChange,
    setHasChange: setHasIngredientsChange,
  } = useIngredientContext();
  const {
    customPictograms,
    hasCustomPictogramChange: hasCustomPictogramChangeInContext,
    tempPictograms,
  } = usePictogramContext();
  const { uploadPictograms } = useUploadPictograms();
  const updateWineLabel = useUpdateWineLabel();
  const { toWineLabelView } = useToWineLabelView();

  const [isDeletedLabel, setIsDeletedLabel] = useState(false);
  const [expandedSection, setExpandedSection] = useState<EditLabelSections[]>(
    []
  );

  const navigateToLabels = () => navigate(ROUTES.LABELS);

  const hasCustomPictogramChange = Object.values(
    hasCustomPictogramChangeInContext
  ).some((it) => it);
  const hasTempPictogram = Object.values(tempPictograms).some(
    (it) => it.length > 0
  );

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    setValue,
    reset,
    trigger,
    watch,
  } = useForm<LabelValue>({
    mode: 'all',
    defaultValues: labelValue,
    resolver: yupResolver(editLabelSchema(t)),
  });
  const detailedSustainability = watch('pictograms.detailedSustainability');
  const shouldBlockNavigation =
    !isDeletedLabel &&
    (formState.isDirty ||
      hasCustomPictogramChange ||
      hasTempPictogram ||
      hasIngredientsChange);

  useEffect(() => {
    setValue('pictograms.detailedSustainability', detailedSustainability);
  }, [detailedSustainability]);

  useEffect(() => {
    reset(labelValue);
  }, [labelValue]);

  useEffect(() => {
    if (isDeletedLabel) navigateToLabels();
  }, [isDeletedLabel]);

  const handleBlockToggle =
    (value: EditLabelSections) =>
    (event: SyntheticEvent, isExpanded: boolean) => {
      setExpandedSection(isExpanded ? [value] : []);
    };

  const onFormSubmitSuccess = async (data: LabelValue) => {
    const uploadedPictograms = await uploadPictograms();
    const mutationData = await toWineLabelView(
      data,
      customPictograms,
      uploadedPictograms
    );
    updateWineLabel.mutate({
      id: Number(id),
      data: mutationData,
      onSuccess: () => {
        setSnackbar({ message: t('edit-label.submit-success') });
        setHasIngredientsChange(false);
      },
    });
  };

  const onFormSubmitError = () => {
    setSnackbar({
      message: t('common.submit-error'),
    });
  };

  const submitBeforeNavigation = (
    continueNavigation: () => void,
    closeNavigationGuardModal?: () => void
  ) => {
    handleSubmit(
      (data) => {
        onFormSubmitSuccess(data).then(() => {
          continueNavigation();
        });
      },
      () => {
        closeNavigationGuardModal?.();
        onFormSubmitError();
      }
    )();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmitSuccess, onFormSubmitError)}>
      <NavigationGuard
        shouldBlockNavigation={shouldBlockNavigation}
        beforeNavigation={submitBeforeNavigation}
      />
      <Background imageSource={backgroundImage}>
        <FlexBox
          width="100%"
          height="100%"
          sx={{
            background: {
              xs: '#FFFFFF',
              md: 'linear-gradient(90deg, #FFFFFF 50%, #FFFFFF00 50%)',
            },
            isolation: 'isolate',
          }}
          pl={{ xs: 1, md: 7 }}
          pr={{ xs: 1, md: '50%' }}
          justifyContent="center"
          overflow="auto"
        >
          <Box my={2} width="min(490px, 100%)">
            <EditLabelHeader onDeleteLabel={() => setIsDeletedLabel(true)} />
            <GeneralInfoSection
              control={control}
              formState={formState}
              trigger={trigger}
              getValues={getValues}
              setValue={setValue}
              expandedBlocks={expandedSection}
              handleBlockToggle={handleBlockToggle}
            />
            <IngredientsSection
              control={control}
              formState={formState}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              expandedBlocks={expandedSection}
              handleBlockToggle={handleBlockToggle}
            />
            <FlexBox mt={2} pb={3} justifyContent="center">
              <Button variant="contained" onClick={navigateToLabels}>
                {t('common.done')}
              </Button>
            </FlexBox>
          </Box>
        </FlexBox>
      </Background>
    </form>
  );
};

export default EditLabel;
