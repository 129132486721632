import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const AllergenSectionIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7068 2.29279C12.5193 2.10532 12.265 2 11.9998 2C11.7346 2 11.4803 2.10532 11.2928 2.29279L8.29279 5.29279C8.10532 5.48031 8 5.73462 8 5.99979C8 6.26495 8.10532 6.51926 8.29279 6.70679L11.2928 9.70679C11.4803 9.89426 11.7346 9.99957 11.9998 9.99957C12.265 9.99957 12.5193 9.89426 12.7068 9.70679L15.7068 6.70679C15.8943 6.51926 15.9996 6.26495 15.9996 5.99979C15.9996 5.73462 15.8943 5.48031 15.7068 5.29279L12.7068 2.29279ZM11.2928 14.2928C11.4803 14.1053 11.7346 14 11.9998 14C12.265 14 12.5193 14.1053 12.7068 14.2928L15.7068 17.2928C15.8943 17.4803 15.9996 17.7346 15.9996 17.9998C15.9996 18.2649 15.8943 18.5193 15.7068 18.7068L12.7068 21.7068C12.5193 21.8943 12.265 21.9996 11.9998 21.9996C11.7346 21.9996 11.4803 21.8943 11.2928 21.7068L8.29279 18.7068C8.10532 18.5193 8 18.2649 8 17.9998C8 17.7346 8.10532 17.4803 8.29279 17.2928L11.2928 14.2928ZM17.2928 8.29279C17.4803 8.10532 17.7346 8 17.9998 8C18.2649 8 18.5193 8.10532 18.7068 8.29279L21.7068 11.2928C21.8943 11.4803 21.9996 11.7346 21.9996 11.9998C21.9996 12.265 21.8943 12.5193 21.7068 12.7068L18.7068 15.7068C18.5193 15.8943 18.2649 15.9996 17.9998 15.9996C17.7346 15.9996 17.4803 15.8943 17.2928 15.7068L14.2928 12.7068C14.1053 12.5193 14 12.265 14 11.9998C14 11.7346 14.1053 11.4803 14.2928 11.2928L17.2928 8.29279ZM5.29279 8.29279C5.48031 8.10532 5.73462 8 5.99979 8C6.26495 8 6.51926 8.10532 6.70679 8.29279L9.70679 11.2928C9.89426 11.4803 9.99957 11.7346 9.99957 11.9998C9.99957 12.265 9.89426 12.5193 9.70679 12.7068L6.70679 15.7068C6.51926 15.8943 6.26495 15.9996 5.99979 15.9996C5.73462 15.9996 5.48031 15.8943 5.29279 15.7068L2.29279 12.7068C2.10532 12.5193 2 12.265 2 11.9998C2 11.7346 2.10532 11.4803 2.29279 11.2928L5.29279 8.29279Z"
      />
    </SvgIcon>
  );
};

export default AllergenSectionIcon;
