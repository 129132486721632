import libphonenumber, { PhoneNumberFormat } from 'google-libphonenumber';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const getFormattedPhoneNumber = (
  countryCode: string,
  phoneNumber: string | undefined
): string => {
  try {
    const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(
      phoneNumber || '',
      countryCode
    );
    if (!phoneUtil.isValidNumber(parsedPhoneNumber)) {
      return '';
    }
    return phoneUtil.format(parsedPhoneNumber, PhoneNumberFormat.E164);
  } catch (error) {
    return '';
  }
};

export const isValidPhoneNumber = (
  countryCode: string,
  phoneNumber: string | undefined
): boolean => {
  try {
    const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(
      phoneNumber || '',
      countryCode
    );
    return phoneUtil.isValidNumber(parsedPhoneNumber);
  } catch (e) {
    return false;
  }
};

export const getCountryFromPhoneNumber = (
  phoneNumber: string
): string | undefined => {
  try {
    const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(phoneNumber || '');
    return phoneUtil.getRegionCodeForNumber(parsedPhoneNumber);
  } catch (error) {
    return '';
  }
};

export const getLocalNumberFromPhoneNumber = (
  phoneNumber: string
): string | undefined => {
  try {
    const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(phoneNumber || '');
    return parsedPhoneNumber.getNationalNumber()?.toString();
  } catch (error) {
    return '';
  }
};
