export default [
  {
    value: 'AF',
    label: 'Afganistan',
  },
  {
    value: 'AL',
    label: 'Albania',
  },
  {
    value: 'DZ',
    label: 'Algeria',
  },
  {
    value: 'AS',
    label: 'Amerikkalainen samoa',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguilla',
  },
  {
    value: 'AG',
    label: 'Antigua ja Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentiina',
  },
  {
    value: 'AM',
    label: 'Armenia',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Australia',
  },
  {
    value: 'AT',
    label: 'Itävalta',
  },
  {
    value: 'AZ',
    label: 'Azerbaidžan',
  },
  {
    value: 'BS',
    label: 'Bahama',
  },
  {
    value: 'BH',
    label: 'Bahrain',
  },
  {
    value: 'BD',
    label: 'Bangladesh',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Valkovenäjä',
  },
  {
    value: 'BE',
    label: 'Belgia',
  },
  {
    value: 'BZ',
    label: 'Belize',
  },
  {
    value: 'BJ',
    label: 'Benin',
  },
  {
    value: 'BM',
    label: 'Bermudaa',
  },
  {
    value: 'BT',
    label: 'Bhutan',
  },
  {
    value: 'BO',
    label: 'Bolivia',
  },
  {
    value: 'BA',
    label: 'Bosnia ja Hertsegovina',
  },
  {
    value: 'BW',
    label: 'Botswana',
  },
  {
    value: 'BR',
    label: 'Brasilia',
  },
  {
    value: 'IO',
    label: 'Britannian Neitsytsaaret',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bulgaria',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Kambodža',
  },
  {
    value: 'CM',
    label: 'Kamerun',
  },
  {
    value: 'CA',
    label: 'Kanada',
  },
  {
    value: 'KY',
    label: 'Caymansaaret',
  },
  {
    value: 'CF',
    label: 'Keski-Afrikan tasavalta',
  },
  {
    value: 'TD',
    label: 'Tupla',
  },
  {
    value: 'CL',
    label: 'Chile',
  },
  {
    value: 'CN',
    label: 'Kiina',
  },
  {
    value: 'CO',
    label: 'Kolumbia',
  },
  {
    value: 'KM',
    label: 'Komorit',
  },
  {
    value: 'CG',
    label: 'Kongo',
  },
  {
    value: 'CK',
    label: 'Cook saaret',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
  },
  {
    value: 'CI',
    label: 'Norsunluurannikko',
  },
  {
    value: 'HR',
    label: 'Kroatia',
  },
  {
    value: 'CU',
    label: 'Kuuba',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Kypros',
  },
  {
    value: 'CZ',
    label: 'Tšekin tasavalta',
  },
  {
    value: 'CZ',
    label: 'Korean demokraattinen kansantasavalta',
  },
  {
    value: 'CD',
    label: 'Kongon demokraattinen tasavalta',
  },
  {
    value: 'DK',
    label: 'Tanska',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Djibouti',
  },
  {
    value: 'DM',
    label: 'Domina',
  },
  {
    value: 'DO',
    label: 'Dominikaaninen tasavalta',
  },
  {
    value: 'EC',
    label: 'Ecuador',
  },
  {
    value: 'EG',
    label: 'Egypti',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'GQ',
    label: 'Päiväntasaajan Guinea',
  },
  {
    value: 'ER',
    label: 'Eritrea',
  },
  {
    value: 'EE',
    label: 'Viro',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etiopia',
  },
  {
    value: 'FK',
    label: 'Falkland saaret',
  },
  {
    value: 'FO',
    label: 'Färsaaret',
  },
  {
    value: 'FJ',
    label: 'Fidži',
  },
  {
    value: 'FI',
    label: 'Suomi',
  },
  {
    value: 'FR',
    label: 'Ranska',
  },
  {
    value: 'FR',
    label: 'Ranskan osastot ja alueet Intian valtamerellä',
  },
  {
    value: 'GF',
    label: 'Ranskan Guayana',
  },
  {
    value: 'PF',
    label: 'Ranskan Polynesia',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambia',
  },
  {
    value: 'GE',
    label: 'Georgia',
  },
  {
    value: 'DE',
    label: 'Saksa',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
  },
  {
    value: 'GR',
    label: 'Kreikka',
  },
  {
    value: 'GL',
    label: 'Grönlanti',
  },
  {
    value: 'GD',
    label: 'Grenada',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  {
    value: 'GG',
    label: 'Guinea',
  },
  {
    value: 'GN',
    label: 'Guinea-Bissau',
  },
  {
    value: 'GY',
    label: 'Guyana',
  },
  {
    value: 'HT',
    label: 'Haiti',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hongkong',
  },
  {
    value: 'HU',
    label: 'Unkari',
  },
  {
    value: 'IS',
    label: 'Islanti',
  },
  {
    value: 'IN',
    label: 'Intia',
  },
  {
    value: 'ID',
    label: 'Indonesia',
  },
  {
    value: 'IR',
    label: 'Iran',
  },
  {
    value: 'IQ',
    label: 'Irak',
  },
  {
    value: 'IE',
    label: 'Irlanti',
  },
  {
    value: 'IL',
    label: 'Israel',
  },
  {
    value: 'IT',
    label: 'Italia',
  },
  {
    value: 'JM',
    label: 'Jamaika',
  },
  {
    value: 'JP',
    label: 'Japani',
  },
  {
    value: 'JO',
    label: 'Jordania',
  },
  {
    value: 'KZ',
    label: 'Kazakstan',
  },
  {
    value: 'KE',
    label: 'Kenia',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Korea',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuwait',
  },
  {
    value: 'KG',
    label: 'Kirgisian',
  },
  {
    value: 'LA',
    label: 'Laon Kansan Demokraattinen Tasavalta',
  },
  {
    value: 'LV',
    label: 'Latvia',
  },
  {
    value: 'LB',
    label: 'Libanon',
  },
  {
    value: 'LS',
    label: 'Lesotho',
  },
  {
    value: 'LR',
    label: 'Liberia',
  },
  {
    value: 'LY',
    label: 'Libya',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
  },
  {
    value: 'LT',
    label: 'Liettua',
  },
  {
    value: 'LU',
    label: 'Luxemburg',
  },
  {
    value: 'MO',
    label: 'Macao',
  },
  {
    value: 'MG',
    label: 'Madagaskar',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Malesia',
  },
  {
    value: 'MV',
    label: 'Malediivit',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Marshallsaaret',
  },
  {
    value: 'MQ',
    label: 'Martinique',
  },
  {
    value: 'MR',
    label: 'Mauritania',
  },
  {
    value: 'MU',
    label: 'Mauritius',
  },
  {
    value: 'MX',
    label: 'Meksiko',
  },
  {
    value: 'FM',
    label: 'Mikronesia',
  },
  {
    value: 'MD',
    label: 'Moldova',
  },
  {
    value: 'MC',
    label: 'Monaco',
  },
  {
    value: 'MN',
    label: 'Mongolia',
  },
  {
    value: 'ME',
    label: 'Montenegro',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Marokko',
  },
  {
    value: 'MZ',
    label: 'Mosambik',
  },
  {
    value: 'MM',
    label: 'Myanmar',
  },
  {
    value: 'NA',
    label: 'Namibia',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepal',
  },
  {
    value: 'NL',
    label: 'Alankomaat',
  },
  {
    value: 'NC',
    label: 'Uusi-Caledonia',
  },
  {
    value: 'NZ',
    label: 'Uusi Seelanti',
  },
  {
    value: 'NI',
    label: 'Nicaragua',
  },
  {
    value: 'NE',
    label: 'Niger',
  },
  {
    value: 'NG',
    label: 'Nigeria',
  },
  {
    value: 'NU',
    label: 'Nieen',
  },
  {
    value: 'NF',
    label: 'Norfolkin saari',
  },
  {
    value: 'NF',
    label: 'Pohjois -Makedonia',
  },
  {
    value: 'MP',
    label: 'Pohjois -Mariana -saaret',
  },
  {
    value: 'NO',
    label: 'Norja',
  },
  {
    value: 'OM',
    label: 'Oman',
  },
  {
    value: 'PK',
    label: 'Pakistan',
  },
  {
    value: 'PW',
    label: 'Palu',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papua-Uusi-Guinea',
  },
  {
    value: 'PY',
    label: 'Paraguay',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'PH',
    label: 'Filippiinit',
  },
  {
    value: 'PL',
    label: 'Puola',
  },
  {
    value: 'PT',
    label: 'Portugali',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'QA',
    label: 'Qatari',
  },
  {
    value: 'RO',
    label: 'Romania',
  },
  {
    value: 'RU',
    label: 'Venäjän federaatio',
  },
  {
    value: 'RW',
    label: 'Ruutu',
  },
  {
    value: 'SH',
    label: 'Saint Helena, Ascension ja Tristan Da Cunha',
  },
  {
    value: 'KN',
    label: 'Saint Kitts ja Nevis',
  },
  {
    value: 'LC',
    label: 'Pyhä Lucia',
  },
  {
    value: 'PM',
    label: 'Saint Pierre ja Miquelon',
  },
  {
    value: 'VC',
    label: 'Saint Vincent ja Grenadine',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao Tome ja Principe',
  },
  {
    value: 'SA',
    label: 'Saudi-Arabia',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Serbia',
  },
  {
    value: 'SC',
    label: 'Seychelles',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Singapore',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Slovakia',
  },
  {
    value: 'SI',
    label: 'Slovenia',
  },
  {
    value: 'SB',
    label: 'Solomonsaaret',
  },
  {
    value: 'SO',
    label: 'Somalia',
  },
  {
    value: 'ZA',
    label: 'Etelä-Afrikka',
  },
  {
    value: 'SS',
    label: 'eteläsudan',
  },
  {
    value: 'ES',
    label: 'Espanja',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
  },
  {
    value: 'SD',
    label: 'Sudan',
  },
  {
    value: 'SR',
    label: 'Surinimi',
  },
  {
    value: 'SE',
    label: 'Ruotsi',
  },
  {
    value: 'CH',
    label: 'Sveitsi',
  },
  {
    value: 'SY',
    label: 'Syyria',
  },
  {
    value: 'TW',
    label: 'Taiwan',
  },
  {
    value: 'TJ',
    label: 'Tadžikistan',
  },
  {
    value: 'TZ',
    label: 'Tansania',
  },
  {
    value: 'TH',
    label: 'Thaimaa',
  },
  {
    value: 'TL',
    label: 'Timor-Leste',
  },
  {
    value: 'TG',
    label: 'Togon tasavalta',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinidad ja Tobago',
  },
  {
    value: 'TN',
    label: 'Tunisia',
  },
  {
    value: 'TR',
    label: 'Turkin tasavalta',
  },
  {
    value: 'TM',
    label: 'Turkmenistan',
  },
  {
    value: 'TC',
    label: 'Turkkilaiset ja caicos',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukraina',
  },
  {
    value: 'AE',
    label: 'Yhdistyneet Arabiemiirikunnat',
  },
  {
    value: 'GB',
    label: 'Yhdistynyt kuningaskunta',
  },
  {
    value: 'US',
    label: 'Yhdysvallat',
  },
  {
    value: 'US',
    label: 'Yhdysvaltain Neitsytsaaret',
  },
  {
    value: 'UY',
    label: 'Uruguay',
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Vietnam',
  },
  {
    value: 'WF',
    label: 'Wallis ja Futuna',
  },
  {
    value: 'YE',
    label: 'Jemenit',
  },
  {
    value: 'ZM',
    label: 'Sambia',
  },
  {
    value: 'ZM',
    label: 'Zimbabwe',
  },
];
