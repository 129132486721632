import { UseQueryOptions } from '@tanstack/react-query';
import { Company } from '../../api';
import useGetProfile from './use-get-profile';

const useProfileCountry = (
  options?: UseQueryOptions<Company, unknown, Company, string[]>
) => {
  const { data, isLoading } = useGetProfile({
    staleTime: Infinity,
    ...options,
  });
  const isGermanAccount = data?.country === 'DE';

  return { isLoading, isGermanAccount };
};

export default useProfileCountry;
