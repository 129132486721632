import {
  Ingredient as IngredientDto,
  CustomIngredient,
  IngredientGroupOrderIngredientGroupEnum as IngredientGroupEnum,
  IngredientGroupOrderIngredientGroupEnum,
} from '../api';

export interface IngredientsSequencesFlexibles {
  ingredients: Ingredient[];
  sequencedIngredientsAndGroups: SequencedIngredientOrGroup[];
  flexibleGroups: IngredientGroupOrderIngredientGroupEnum[];
}
export interface Ingredient extends CustomIngredient {
  isCustom?: boolean;
  isSelected?: boolean;
  subIngredient?: IngredientDto;
  subIngredients?: IngredientsSequencesFlexibles;
  tempSubIngredients?: IngredientsSequencesFlexibles;
}

export interface IngredientGroup {
  name?: IngredientGroupEnum;
  ingredients: Ingredient[];
}

export interface SequencedIngredientOrGroup {
  ingredient?: Ingredient;
  group?: IngredientGroup;
  sequenceNumber?: number;
}

export enum AllergenEnum {
  SULPHITE = 'SULPHITE',
  MILK_SULPHITE = 'MILK_SULPHITE',
  EGG_SULPHITE = 'EGG_SULPHITE',
  MILK_EGG_SULPHITE = 'MILK_EGG_SULPHITE',
}

export interface IngredientsAndGroupsListItem {
  text: string;
  isBold?: boolean;
  isGrouped?: boolean;
  isSub?: boolean;
}

export interface IngredientState {
  mainIngredient?: Ingredient;
  ingredients: Ingredient[];
  sequencedIngredientsAndGroups: SequencedIngredientOrGroup[];
  flexibleGroups: IngredientGroupEnum[];
  hasChange: boolean;
  shouldUpdateUpperContext: boolean;
}
