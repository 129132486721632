import React from 'react';
import { Button, DialogContent, DialogProps, useTheme } from '@mui/material';
import CardHeader from '../../../components/card/card-header';
import { Ingredient } from '../../../models/ingredient';
import { useTranslation } from 'react-i18next';
import SubIngredientsSections from './sub-ingredients-sections';
import { useIngredientContext } from '../../../services/ingredient-context';
import SubIngredientsModalHeader from './sub-ingredients-modal-header';
import ModalWithConfirmation from '../../../components/modal/modal-with-confirmation';

interface SubIngredientsModalProps extends DialogProps {
  mainIngredient: Ingredient;
}

const SubIngredientsModal: React.FC<SubIngredientsModalProps> = ({
  mainIngredient,
  ...props
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { hasChange } = useIngredientContext();
  const {
    ingredients,
    sequencedIngredientsAndGroups,
    flexibleGroups,
    setInitialIngredientsSequencesFlexibles,
    setIngredientsSequencesFlexibles,
    setSubIngredients: setSubIngredientsInContext,
    setTempSubIngredients: setTempSubIngredientsInContext,
  } = useIngredientContext();
  const setSubIngredients = () => {
    setSubIngredientsInContext({
      mainIngredient,
      subIngredients: {
        ingredients,
        sequencedIngredientsAndGroups,
        flexibleGroups,
      },
    });
  };
  const resetSubIngredients = () => {
    if (typeof mainIngredient.subIngredients === 'undefined') {
      setInitialIngredientsSequencesFlexibles();
    } else {
      setIngredientsSequencesFlexibles(mainIngredient.subIngredients);
    }
  };
  const setTempSubIngredients = () => {
    setTempSubIngredientsInContext({
      mainIngredient,
      tempSubIngredients: mainIngredient.subIngredients,
    });
  };
  const handleCancelConfirmation = () => {
    setTempSubIngredients();
    resetSubIngredients();
  };
  const closeModal = () => {
    if (props.onClose) props.onClose({}, 'escapeKeyDown');
  };
  const handleConfirmation = () => {
    setSubIngredients();
    closeModal();
  };
  const handleDoneClick = () => {
    setSubIngredients();
    closeModal();
  };

  return (
    <ModalWithConfirmation
      requiresConfirmation={hasChange}
      onConfirmation={handleConfirmation}
      onCancel={handleCancelConfirmation}
      {...props}
    >
      <CardHeader sx={{ color: palette.common.white }}>
        <SubIngredientsModalHeader name={mainIngredient.name} />
      </CardHeader>
      <DialogContent sx={{ p: 4, pb: 6 }}>
        <SubIngredientsSections />
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 4 }}
          onClick={handleDoneClick}
        >
          {t('common.done')}
        </Button>
      </DialogContent>
    </ModalWithConfirmation>
  );
};

export default SubIngredientsModal;
