import { KeyboardArrowRight } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { ICON_MEDIUM2_SIZE } from '../../../constants/dimensions';

interface IngredientGroupAccordionProps {
  title: string;
  onToggle: (expanded: boolean) => void;
  details: ReactElement;
}

const IngredientGroupAccordion: React.FC<IngredientGroupAccordionProps> = ({
  title,
  onToggle,
  details,
}) => {
  const { palette } = useTheme();

  return (
    <Accordion
      sx={{
        boxShadow: 0,
        '&:before': { display: 'none' },
        '&.Mui-expanded': {
          my: 0,
        },
      }}
      onChange={(_, expanded) => onToggle(expanded)}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowRight />}
        sx={{
          p: 0,
          bgcolor: palette.grey[200],
          borderRadius: 3,
          '&.Mui-expanded, & > .Mui-expanded': {
            minHeight: 0,
            m: 0,
          },
          '& > .MuiAccordionSummary-content': {
            alignItems: 'center',
            '&.Mui-expanded': {
              my: 1.5,
            },
          },
          ' & > .MuiAccordionSummary-expandIconWrapper': {
            fontSize: ICON_MEDIUM2_SIZE,
            '& > svg': {
              fontSize: 'inherit',
              color: palette.grey[700],
            },
            '&.Mui-expanded': {
              transform: 'rotate(-90deg)',
            },
          },
        }}
      >
        <Typography ml="10px">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>{details}</AccordionDetails>
    </Accordion>
  );
};

export default IngredientGroupAccordion;
