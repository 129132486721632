import { Button, DialogProps, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import infoIllustration from '../../assets/images/info-2-illustration.svg';
import FlexBoxColumn from '../flexbox/flex-box-column';
import FlexBox from '../flexbox/flexbox';
import Modal from '../modal/modal';

interface InformationModalProps extends DialogProps {
  onAcknowledge?: () => void;
  hideOnChoice?: boolean;
  labels?: {
    message?: string;
    acknowledgement?: string;
  };
}

const InformationModal: React.FC<InformationModalProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { onAcknowledge, labels, hideOnChoice = true, ...modalProps } = props;

  const handleAcknowledgment = () => {
    if (props.onClose && hideOnChoice) props.onClose({}, 'backdropClick');
    if (onAcknowledge) onAcknowledge();
  };

  return (
    <Modal {...modalProps}>
      <FlexBox mx={4} my={6}>
        <img src={infoIllustration} />
        <FlexBoxColumn
          ml={4}
          gap={2}
          justifyContent="space-around"
          alignItems="center"
        >
          <Typography variant="h6" textAlign="center">
            {labels?.message}
          </Typography>
          <Button
            variant="contained"
            onClick={handleAcknowledgment}
            sx={{ backgroundColor: theme.palette.primary.light, minWidth: 140 }}
          >
            {labels?.acknowledgement || t('common.ok')}
          </Button>
        </FlexBoxColumn>
      </FlexBox>
    </Modal>
  );
};

export default InformationModal;
