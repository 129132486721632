import { Card, CardProps } from '@mui/material';
import React from 'react';

const InputWrapper: React.FC<CardProps> = ({ children }) => {
  return (
    <Card
      sx={{
        width: '48px',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        '& > input': {
          border: 'none',
          fontSize: '24px',
          '&:focus-visible': { border: 'none', outline: 'none' },
        },
      }}
    >
      {children}
    </Card>
  );
};

export default InputWrapper;
