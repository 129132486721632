export const FONTS = [
  'Roboto',
  'Arial',
  'Calibri',
  'Inter',
  'Lato',
  'Montserrat',
  'Nunito',
  'Open Sans',
  'Quicksand',
  'Times New Roman',
];

export const DEFAULT_FONT = 'Roboto';
