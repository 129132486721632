import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IngredientGroupOrderIngredientGroupEnum } from '../../../api';
import { useIngredientContext } from '../../../services/ingredient-context';

interface CustomIngredientInputProps {
  group: IngredientGroupOrderIngredientGroupEnum;
}

const CustomIngredientInput: React.FC<CustomIngredientInputProps> = ({
  group,
}) => {
  const { t } = useTranslation();
  const [newIngredient, setNewIngredient] = useState('');
  const { ingredients, addCustomIngredient } = useIngredientContext();
  const ingredientNamesInGroup = ingredients
    .filter((ingredient) => ingredient.ingredientGroup === group)
    .map((it) => it.name);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewIngredient(e.target.value);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!ingredientNamesInGroup.includes(value)) {
        addCustomIngredient(value, group);
        setNewIngredient('');
      }
    }
  };
  const helperText = ingredientNamesInGroup.includes(newIngredient)
    ? t('edit-label.duplicate-ingredient')
    : t('common.add-on-enter');

  return (
    <TextField
      sx={{
        my: 2,
        ml: 3,
        width: '50%',
        '& input': { p: 1 },
        '& .MuiInputLabel-root': { top: '-8px' },
        '& .MuiInputLabel-shrink': { top: '0' },
      }}
      label={t('edit-label.add-ingredient')}
      value={newIngredient}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      helperText={helperText}
    />
  );
};

export default CustomIngredientInput;
