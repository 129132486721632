import { Typography } from '@mui/material';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import HelperTooltip from '../../components/helper/helper-tooltip';
import CopyLinkButton from './copy-link-button';
import DuplicateLabelButton from './duplicate-label-button';
import EditButton from './edit-button';
import PreviewButton from './preview-button';
import ProductName from './product-name';

const useColumns = () => {
  const { t } = useTranslation();
  const actionColumnProps: Partial<GridColDef> = {
    disableColumnMenu: true,
    sortable: false,
    headerAlign: 'center',
    resizable: false,
    width: 140,
  };

  const renderHeader = (params: GridColumnHeaderParams) => (
    <HelperTooltip title={params.colDef.description}>
      <Typography variant="body2" fontWeight={500}>
        {params.colDef.headerName}
      </Typography>
    </HelperTooltip>
  );

  const columns: GridColDef[] = [
    {
      field: 'PRODUCT_NAME',
      headerName: t('labels.winefo-label-names'),
      description: t('labels.winefo-label-names-helper'),
      renderHeader,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
        <ProductName data={params.value} />
      ),
    },
    {
      field: 'HARVEST_YEAR',
      headerName: t('labels.harvest-year'),
      description: t('labels.harvest-year-helper'),
      renderHeader,
      disableColumnMenu: true,
      width: 140,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
        <Typography>{params.value}</Typography>
      ),
    },
    {
      field: 'DUPLICATE',
      headerName: t('labels.duplicate-label'),
      description: t('labels.duplicate-label-helper'),
      renderHeader,
      ...actionColumnProps,
      renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
        <DuplicateLabelButton id={params.id as number} />
      ),
    },
    {
      field: 'COPY',
      headerName: t('labels.copy-label-link'),
      description: t('labels.copy-label-link-helper'),
      renderHeader,
      ...actionColumnProps,
      renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
        <CopyLinkButton link={params.value} />
      ),
    },
    {
      field: 'PREVIEW',
      headerName: t('labels.preview'),
      description: t('labels.preview-helper'),
      renderHeader,
      ...actionColumnProps,
      renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
        <PreviewButton link={params.value} />
      ),
    },
    {
      field: 'EDIT',
      headerName: t('labels.edit-label'),
      description: t('labels.edit-label-helper'),
      renderHeader,
      ...actionColumnProps,
      renderCell: (params: GridRenderCellParams<GridValidRowModel>) => (
        <EditButton id={params.id as number} />
      ),
    },
  ];

  return columns;
};

export default useColumns;
