import { Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FlexBox from '../../components/flexbox/flexbox';
import { ROUTES } from '../../constants/routes';
import SettingsItem from './settings-item';

const ProfileSettingsSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <FlexBox mx={2} flexDirection="column" gap={1}>
        <SettingsItem
          onClick={() => navigate(ROUTES.PROFILE_SETTINGS)}
          sx={{ my: 1.5, fontSize: 16 }}
        >
          {t('settings.profile-settings')}
        </SettingsItem>
        <Typography variant="caption" ml="5px" fontSize={14}>
          {t('settings.company-location-information')}
        </Typography>
        <Typography variant="caption" ml="5px" fontSize={14}>
          {t('settings.marketing-information')}
        </Typography>
      </FlexBox>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

export default ProfileSettingsSection;
