import { Box, BoxProps, Popover, PopoverProps, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { hexColorRegex } from '../../../utils/common/hex-color-regex';

interface ColorPickerPreviewerProps {
  color: string;
  onChange: (color: string) => void;
  onPickerClose?: () => void;
  size?: number;
  previewerProps?: BoxProps;
  popoverProps?: Omit<PopoverProps, 'open'>;
  withOpenPicker?: boolean;
}

const ColorPickerPreviewer: React.FC<ColorPickerPreviewerProps> = ({
  color,
  onChange,
  onPickerClose,
  size,
  previewerProps,
  popoverProps,
  withOpenPicker,
}) => {
  const DEFAULT_SIZE = 56;
  const { sx: previewerSx, ...previewerRest } = previewerProps || {};
  const { sx: popoverSx, ...popoverRest } = popoverProps || {};
  const { palette } = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const openColorPicker = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeColorPicker = () => {
    setAnchorEl(null);
    if (onPickerClose) onPickerClose();
  };
  const isValid = color && hexColorRegex.test(color);

  useEffect(() => {
    if (withOpenPicker) setAnchorEl(ref.current);
  }, [withOpenPicker, ref]);

  return (
    <>
      <Box
        minWidth={size || DEFAULT_SIZE}
        maxWidth={size || DEFAULT_SIZE}
        height={size || DEFAULT_SIZE}
        bgcolor={color}
        borderRadius={1}
        boxShadow={25}
        sx={{ cursor: 'pointer', ...previewerSx }}
        onClick={openColorPicker}
        border={isValid ? 'none' : `1px solid ${palette.error.main}`}
        ref={ref}
        {...previewerRest}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeColorPicker}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 70, horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{
          '.MuiPopover-paper': {
            height: '165px',
            overflow: 'hidden',
          },
          ...popoverSx,
        }}
        {...popoverRest}
      >
        <ChromePicker
          disableAlpha
          color={color}
          onChange={(result) => onChange(result.hex)}
        />
      </Popover>
    </>
  );
};

export default ColorPickerPreviewer;
