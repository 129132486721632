import { Button, ButtonProps, Link } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EMAIL } from '../../constants/emails';

const ContactButton: React.FC<ButtonProps> = ({ sx, ...props }) => {
  const { t } = useTranslation();

  return (
    <Link
      href={`mailto:${EMAIL.CUSTOMER}`}
      target="_blank"
      rel="noreferrer"
      underline="none"
      sx={{ width: '100%', mr: 2, ...sx }}
    >
      <Button fullWidth {...props}>
        {t('common.contact-us')}
      </Button>
    </Link>
  );
};

export default ContactButton;
