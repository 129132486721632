import { Place } from '@mui/icons-material';
import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  WineLabelViewProductionMethodEnum,
  WineLabelViewSweetnessEnum,
  WineLabelViewWineColourEnum,
} from '../../../api';
import PDO from '../../../assets/pictograms/pdo.svg';
import PGI from '../../../assets/pictograms/pgi.svg';
import TitledAccordion from '../../../components/accordion/titled-accordion';
import DatePickerWithController from '../../../components/date-picker/date-picker-with-controller';
import FlexBoxColumn from '../../../components/flexbox/flex-box-column';
import FlexBox from '../../../components/flexbox/flexbox';
import PictogramItem from '../../../components/pictogram/pictogram-item';
import SearchableSelectWithController from '../../../components/select/searchable-select-with-controller';
import { hideArrows } from '../../../components/text-field/number-input-without-arrows';
import TextFieldWithController from '../../../components/text-field/text-field-with-controller';
import { primary } from '../../../constants/colors';
import { EditLabelSplitFormProps } from '../../../models/label';

export const defaultProductSubsectionValues = {
  productName: undefined,
  harvestYear: undefined,
  wineSubcategory: undefined,
  wineColour: undefined,
  sweetness: undefined,
  productionMethod: undefined,
  netQuantity: undefined,
  alcoholByVolume: undefined,
  origin: {},
  productReference: undefined,
  gtinNumber: undefined,
};

const ProductSection: React.FC<EditLabelSplitFormProps> = ({
  control,
  formState,
}) => {
  const { t } = useTranslation();
  const { isSubmitted, errors } = formState;

  return (
    <FlexBoxColumn gap="10px">
      <TextFieldWithController
        control={control}
        name="productName"
        textFieldProps={{
          label: t('edit-label.product-name'),
          error: isSubmitted && !!errors.productName,
          helperText:
            isSubmitted &&
            !!errors.productName &&
            t('common.errors.cant-be-empty'),
          fullWidth: true,
        }}
      />
      <DatePickerWithController
        control={control}
        name="harvestYear"
        datePickerProps={{
          views: ['year'],
          format: 'YYYY',
          label: t('edit-label.harvest-year'),
          slotProps: {
            textField: {
              error: isSubmitted && !!errors.harvestYear,
              fullWidth: true,
              helperText:
                isSubmitted &&
                !!errors.harvestYear &&
                (errors.harvestYear.type === 'matches'
                  ? t('edit-label.harvest-year-format')
                  : t('common.errors.cant-be-empty')),
            },
          },
        }}
      />
      <Typography>{t('edit-label.choose-category')}</Typography>
      <SearchableSelectWithController
        control={control}
        name="wineColour"
        textFieldProps={{
          label: t('edit-label.wine-colour'),
          error: isSubmitted && !!errors.wineColour,
          fullWidth: true,
        }}
        data={Object.values(WineLabelViewWineColourEnum).map((colour) => ({
          value: colour,
          label: t(`wine-colour.${colour}`),
        }))}
      />
      <TextFieldWithController
        control={control}
        name="grapeVariety"
        textFieldProps={{
          label: t('edit-label.variety'),
          error: isSubmitted && !!errors.grapeVariety,
          fullWidth: true,
        }}
      />
      <SearchableSelectWithController
        control={control}
        name="sweetness"
        textFieldProps={{
          label: t('edit-label.sweetness'),
          error: isSubmitted && !!errors.sweetness,
          fullWidth: true,
        }}
        data={Object.values(WineLabelViewSweetnessEnum).map((category) => ({
          value: category,
          label: t(`wine-sweetness.${category}`),
        }))}
        disableSorting
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.value}>
              {option.key}
            </li>
          );
        }}
      />
      <SearchableSelectWithController
        control={control}
        name="productionMethod"
        textFieldProps={{
          label: t('edit-label.production-characteristics'),
          error: isSubmitted && !!errors.productionMethod,
          fullWidth: true,
        }}
        data={Object.values(WineLabelViewProductionMethodEnum).map(
          (category) => ({
            value: category,
            label: t(`production_characteristics.${category}`),
          })
        )}
      />
      <TextFieldWithController
        control={control}
        name="alcoholByVolume"
        undefinedEmpty
        textFieldProps={{
          type: 'number',
          label: t('edit-label.alcoholic-volumen'),
          error: isSubmitted && !!errors.alcoholByVolume,
          helperText:
            isSubmitted &&
            !!errors.alcoholByVolume &&
            errors.alcoholByVolume.message,
          fullWidth: true,
          InputProps: { endAdornment: '%' },
          sx: hideArrows,
        }}
      />
      <FlexBox gap="10px">
        <TextFieldWithController
          control={control}
          name="netQuantity"
          undefinedEmpty
          textFieldProps={{
            label: t('edit-label.quantity'),
            error: isSubmitted && !!errors.netQuantity,
            fullWidth: true,
            type: 'number',
            InputProps: { inputProps: { min: 0 } },
          }}
        />
        <TextField label={t('edit-label.unit')} value="ml" fullWidth />
      </FlexBox>
      <FlexBox color={primary[700]} pt={2} gap="10px" alignItems="center">
        <Place color="inherit" />
        <Typography color="initial">{t('edit-label.origin')}</Typography>
      </FlexBox>
      <TextFieldWithController
        control={control}
        name="pictograms.origin.name"
        textFieldProps={{
          label: t('edit-label.origin-name'),
          fullWidth: true,
        }}
        undefinedEmpty
      />
      <Controller
        name="pictograms.origin.label"
        control={control}
        render={({ field }) => (
          <Grid container justifyContent="center">
            <PictogramItem
              imageSrc={PGI}
              label={t('edit-label.pgi')}
              value="PGI"
              onChange={(value) =>
                field.onChange(field.value === value ? undefined : value)
              }
              checked={field.value === 'PGI'}
            />
            <PictogramItem
              imageSrc={PDO}
              label={t('edit-label.pdo')}
              value="PDO"
              onChange={(value) =>
                field.onChange(field.value === value ? undefined : value)
              }
              checked={field.value === 'PDO'}
            />
          </Grid>
        )}
      />
      <TitledAccordion>
        <TextFieldWithController
          control={control}
          name="productReference"
          undefinedEmpty
          textFieldProps={{
            label: t('edit-label.product-reference'),
            error: isSubmitted && !!errors.productReference,
            fullWidth: true,
            helperText: t('edit-label.product-reference-helper'),
          }}
        />
        <TextFieldWithController
          control={control}
          name="gtinNumber"
          undefinedEmpty
          textFieldProps={{
            label: t('edit-label.gtin-number'),
            error: isSubmitted && !!errors.gtinNumber,
            fullWidth: true,
            helperText: t('edit-label.gtin-number-helper'),
          }}
        />
      </TitledAccordion>
    </FlexBoxColumn>
  );
};

export default ProductSection;
