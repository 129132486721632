import React from 'react';
import Layout from '../layout/layout';
import TagManager from 'react-gtm-module';

const WineryScreensWrapper: React.FC = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_CONTAINER_ID || '',
  };

  TagManager.initialize(tagManagerArgs);

  return <Layout />;
};

export default WineryScreensWrapper;
