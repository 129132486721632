import * as React from 'react';
import useCountriesByCode from '../../utils/countries/use-countries-by-code';
import useSearchableOptions from '../../utils/select/use-searchable-options';
import SearchableSelect, {
  SearchableSelectProps,
} from '../select/searchable-select';

export interface CountrySelectOptions
  extends Omit<SearchableSelectProps, 'optionMap'> {
  onSelectChange: (selected?: string | null) => void;
}

const CountrySelect = React.forwardRef<HTMLInputElement, CountrySelectOptions>(
  (props: CountrySelectOptions, ref) => {
    const data = useCountriesByCode();
    const sortedData = React.useMemo(() => {
      return data.sort((a, b) => a.label.localeCompare(b.label));
    }, [data]);
    const options = useSearchableOptions(sortedData);

    return (
      <SearchableSelect
        optionMap={options}
        freeSolo={false}
        {...props}
        ref={ref}
      />
    );
  }
);

CountrySelect.displayName = 'CountrySelect';

export default CountrySelect;
