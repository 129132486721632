import { Box, BoxProps, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { SequencedIngredientOrGroup } from '../../models/ingredient';
import { getIngredientsAndGroupsListItems } from '../../utils/ingredient/ingredient-utils';
import { IngredientGroupOrderIngredientGroupEnum } from '../../api';

interface IngredientListProps {
  ingredientsAndGroups: SequencedIngredientOrGroup[];
  flexibleGroups: IngredientGroupOrderIngredientGroupEnum[];
  inPreview?: boolean;
  containerProps?: BoxProps;
}

const IngredientList: React.FC<IngredientListProps> = ({
  ingredientsAndGroups,
  flexibleGroups,
  inPreview,
  containerProps,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const ingredientsAndGroupsListItems = getIngredientsAndGroupsListItems(
    ingredientsAndGroups,
    flexibleGroups,
    t,
    language
  );

  return (
    <Box p={2} {...containerProps}>
      <Typography fontFamily="inherit">
        {inPreview && <b>{t('common.ingredients')}: </b>}
        {ingredientsAndGroupsListItems.flat().map((listItem, index) => {
          return (
            <Fragment key={listItem.text + index}>
              {index !== 0 && !listItem.isGrouped && !listItem.isSub && ', '}
              <Typography
                component="span"
                fontFamily="inherit"
                fontWeight={listItem.isBold ? 'bold' : 'normal'}
                key={listItem.text}
              >
                {listItem.text}
              </Typography>
            </Fragment>
          );
        })}
      </Typography>
      {!inPreview && (
        <Typography textAlign="right" variant="body2" fontFamily="inherit">
          *{t('winefo-point.allergen')}
        </Typography>
      )}
    </Box>
  );
};

export default IngredientList;
