import { Color, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FlexBox from '../../components/flexbox/flexbox';
import { ROUTES } from '../../constants/routes';
import useGetProfile from '../../data/profile/use-get-profile';
import SettingsItem from './settings-item';

const PremiumFeaturesSection: React.FC = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { data } = useGetProfile();
  const hasPremiumFeatures = data?.premiumFeature;
  const navigateToPremiumFeatures = () => {
    navigate(ROUTES.PREMIUM_FEATURES);
  };

  return (
    <FlexBox mx={2} mb={2} flexDirection="column">
      <SettingsItem
        onClick={navigateToPremiumFeatures}
        sx={{ my: 1.5, fontSize: 16 }}
      >
        {t('settings.premium-features')}
      </SettingsItem>
      <FlexBox gap={2}>
        {Array.from(Array(3).keys()).map((index) => {
          const colorKey = (300 - index * 100) as keyof Color;
          return (
            <FlexBox
              py={0.5}
              px={1}
              borderRadius={2.5}
              bgcolor={
                hasPremiumFeatures
                  ? palette.primaryVariations[colorKey]
                  : palette.grey[colorKey]
              }
              boxShadow={25}
              key={index}
            >
              <Typography variant="caption">
                {t(`settings.premium-feature-${index + 1}`)}
              </Typography>
            </FlexBox>
          );
        })}
      </FlexBox>
    </FlexBox>
  );
};

export default PremiumFeaturesSection;
