import { Box, Typography } from '@mui/material';
import { CustomImageViewImageGroupEnum, PublicWineLabelView } from '../../api';
import FlexBoxColumn from '../../components/flexbox/flex-box-column';
import FlexBox from '../../components/flexbox/flexbox';
import { primary } from '../../constants/colors';

interface WinefoPointHeaderProps {
  data: PublicWineLabelView;
}

const WinefoPointHeader = ({
  data: { productName, harvestYear, premiumFeature },
}: WinefoPointHeaderProps) => {
  const logo = premiumFeature?.customImages?.find(
    (image) =>
      image.imageGroup === CustomImageViewImageGroupEnum.BrandLogo &&
      typeof image.downloadUrl === 'string'
  );
  const hasLogo = typeof logo !== 'undefined';

  return (
    <FlexBox
      height={80}
      width="100%"
      gap={1}
      alignItems="center"
      bgcolor={premiumFeature?.primaryColour || primary[800]}
      color={premiumFeature?.primaryTextColour || '#FFF'}
      px="20px"
      position="sticky"
      top={0}
      zIndex={1}
      boxShadow={25}
    >
      {hasLogo && (
        <Box
          sx={{
            minWidth: '40px',
            height: '40px',
            borderRadius: '50%',
            background: `center / contain no-repeat url(${logo.downloadUrl})`,
          }}
        />
      )}
      <FlexBoxColumn justifyContent="center">
        <Typography
          variant="h6"
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: 1.2,
            fontFamily: 'inherit',
          }}
        >
          {productName}
        </Typography>
        <Typography variant="body2" fontFamily="inherit">
          {harvestYear}
        </Typography>
      </FlexBoxColumn>
    </FlexBox>
  );
};

export default WinefoPointHeader;
