import { TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import PasswordField from './password-field';

export type PasswordFieldWithControllerProps<T extends FieldValues> = {
  control: Control<T>;
  name: FieldPath<T>;
  textFieldProps: TextFieldProps;
};

const PasswordFieldWithController = <ObjectType extends FieldValues>({
  control,
  name,
  textFieldProps,
  ...otherProps
}: PasswordFieldWithControllerProps<ObjectType>) => {
  return (
    <Controller
      render={({ field: { ...fieldProps } }) => (
        <PasswordField {...textFieldProps} {...fieldProps} {...otherProps} />
      )}
      name={name}
      control={control}
    />
  );
};

export default PasswordFieldWithController;
