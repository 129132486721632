export default [
  {
    value: 'AF',
    label: 'Afganistan',
  },
  {
    value: 'AL',
    label: 'Albaania',
  },
  {
    value: 'DZ',
    label: 'Alžeeria',
  },
  {
    value: 'AS',
    label: 'Ameerika samoa',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguilla',
  },
  {
    value: 'AG',
    label: 'Antigua ja Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentina',
  },
  {
    value: 'AM',
    label: 'Armeenia',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Austraalia',
  },
  {
    value: 'AT',
    label: 'Austria',
  },
  {
    value: 'AZ',
    label: 'Aserbaidžaan',
  },
  {
    value: 'BS',
    label: 'Bahama',
  },
  {
    value: 'BH',
    label: 'Bahrein',
  },
  {
    value: 'BD',
    label: 'Bangladesh',
  },
  {
    value: 'BB',
    label: 'Barbadod',
  },
  {
    value: 'BY',
    label: 'Valgevene',
  },
  {
    value: 'BE',
    label: 'Belgia',
  },
  {
    value: 'BZ',
    label: 'Belize',
  },
  {
    value: 'BJ',
    label: 'Benin',
  },
  {
    value: 'BM',
    label: 'Bermuda',
  },
  {
    value: 'BT',
    label: 'Bhutan',
  },
  {
    value: 'BO',
    label: 'Boliivia',
  },
  {
    value: 'BA',
    label: 'Bosnia ja Hertsegoviina',
  },
  {
    value: 'BW',
    label: 'Botswana',
  },
  {
    value: 'BR',
    label: 'Brasiilia',
  },
  {
    value: 'IO',
    label: 'Briti Neitsisaared',
  },
  {
    value: 'BN',
    label: 'Brunei Daussalam',
  },
  {
    value: 'BG',
    label: 'Bulgaaria',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo verde',
  },
  {
    value: 'KH',
    label: 'Kambodža',
  },
  {
    value: 'CM',
    label: 'Kamerun',
  },
  {
    value: 'CA',
    label: 'Kanada',
  },
  {
    value: 'KY',
    label: 'Kaimani saared',
  },
  {
    value: 'CF',
    label: 'Kesk-Aafrika Vabariik',
  },
  {
    value: 'TD',
    label: 'Tšaad',
  },
  {
    value: 'CL',
    label: 'Tšil',
  },
  {
    value: 'CN',
    label: 'Hiina',
  },
  {
    value: 'CO',
    label: 'Colombia',
  },
  {
    value: 'KM',
    label: 'Comiros',
  },
  {
    value: 'CG',
    label: 'Kongo',
  },
  {
    value: 'CK',
    label: 'Kokasaared',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
  },
  {
    value: 'CI',
    label: "Côte d'Ivoire",
  },
  {
    value: 'HR',
    label: 'Horvaatia',
  },
  {
    value: 'CU',
    label: 'Kuuba',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Küpros',
  },
  {
    value: 'CZ',
    label: 'Tšehhi Vabariik',
  },
  {
    value: 'CZ',
    label: 'Korea demokraatlik rahva vabariik',
  },
  {
    value: 'CD',
    label: 'Kongo demokraatlik vabariik',
  },
  {
    value: 'DK',
    label: 'Taani',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Djibouti',
  },
  {
    value: 'DM',
    label: 'Dominica',
  },
  {
    value: 'DO',
    label: 'Dominikaani Vabariik',
  },
  {
    value: 'EC',
    label: 'Ecuador',
  },
  {
    value: 'EG',
    label: 'Egiptus',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'GQ',
    label: 'Ekvatoriaalne Guinea',
  },
  {
    value: 'ER',
    label: 'Eritrea',
  },
  {
    value: 'EE',
    label: 'Eestia',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etioopia',
  },
  {
    value: 'FK',
    label: 'Falklandi saared',
  },
  {
    value: 'FO',
    label: 'Fääri saared',
  },
  {
    value: 'FJ',
    label: 'Fidži',
  },
  {
    value: 'FI',
    label: 'Soome',
  },
  {
    value: 'FR',
    label: 'Prantsusmaa',
  },
  {
    value: 'FR',
    label: 'Prantsuse osakonnad ja territooriumid India ookeanis',
  },
  {
    value: 'GF',
    label: 'Prantsuse Guajaana',
  },
  {
    value: 'PF',
    label: 'Prantsuse Polüneesia',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambia',
  },
  {
    value: 'GE',
    label: 'Gruusia',
  },
  {
    value: 'DE',
    label: 'Saksamaa',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
  },
  {
    value: 'GR',
    label: 'Kreeka',
  },
  {
    value: 'GL',
    label: 'Gröönimaa',
  },
  {
    value: 'GD',
    label: 'Grenada',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  {
    value: 'GG',
    label: 'Merisea',
  },
  {
    value: 'GN',
    label: 'Merisea-bissau',
  },
  {
    value: 'GY',
    label: 'Guajaana',
  },
  {
    value: 'HT',
    label: 'Haiti',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hongkong',
  },
  {
    value: 'HU',
    label: 'Ungari',
  },
  {
    value: 'IS',
    label: 'Island',
  },
  {
    value: 'IN',
    label: 'India',
  },
  {
    value: 'ID',
    label: 'Indoneesia',
  },
  {
    value: 'IR',
    label: 'Iraan',
  },
  {
    value: 'IQ',
    label: 'Iraak',
  },
  {
    value: 'IE',
    label: 'Iirimaa',
  },
  {
    value: 'IL',
    label: 'Iisrael',
  },
  {
    value: 'IT',
    label: 'Itaalia',
  },
  {
    value: 'JM',
    label: 'Jamaica',
  },
  {
    value: 'JP',
    label: 'Jaapan',
  },
  {
    value: 'JO',
    label: 'Jordaania',
  },
  {
    value: 'KZ',
    label: 'Kasahstan',
  },
  {
    value: 'KE',
    label: 'Keenia',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Korea',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuveit',
  },
  {
    value: 'KG',
    label: 'Kõrgõzstan',
  },
  {
    value: 'LA',
    label: 'Lao rahva demokraatlik vabariik',
  },
  {
    value: 'LV',
    label: 'Läti',
  },
  {
    value: 'LB',
    label: 'Liibanon',
  },
  {
    value: 'LS',
    label: 'Lesotho',
  },
  {
    value: 'LR',
    label: 'Libeeria',
  },
  {
    value: 'LY',
    label: 'Liibüa',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
  },
  {
    value: 'LT',
    label: 'Leedu',
  },
  {
    value: 'LU',
    label: 'Luksemburg',
  },
  {
    value: 'MO',
    label: 'Aokao',
  },
  {
    value: 'MG',
    label: 'Madagaskaar',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Malaisia',
  },
  {
    value: 'MV',
    label: 'Maldiivid',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Marshalli saared',
  },
  {
    value: 'MQ',
    label: 'Martiniik',
  },
  {
    value: 'MR',
    label: 'Mauritaania',
  },
  {
    value: 'MU',
    label: 'Mauritius',
  },
  {
    value: 'MX',
    label: 'Mehhiko',
  },
  {
    value: 'FM',
    label: 'Mikroneesia',
  },
  {
    value: 'MD',
    label: 'Moldova',
  },
  {
    value: 'MC',
    label: 'Monaco',
  },
  {
    value: 'MN',
    label: 'Mongoolia',
  },
  {
    value: 'ME',
    label: 'Montenegro',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Maroko',
  },
  {
    value: 'MZ',
    label: 'Mosambiik',
  },
  {
    value: 'MM',
    label: 'Myanmar',
  },
  {
    value: 'NA',
    label: 'Namiibia',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepal',
  },
  {
    value: 'NL',
    label: 'Holland',
  },
  {
    value: 'NC',
    label: 'Uus-Kaledoonia',
  },
  {
    value: 'NZ',
    label: 'Uus-Meremaa',
  },
  {
    value: 'NI',
    label: 'Nicaragua',
  },
  {
    value: 'NE',
    label: 'Niger',
  },
  {
    value: 'NG',
    label: 'Nigeeria',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Norfolki saar',
  },
  {
    value: 'NF',
    label: 'Põhja -Makedoonia',
  },
  {
    value: 'MP',
    label: 'Põhja -Mariana saared',
  },
  {
    value: 'NO',
    label: 'Norra',
  },
  {
    value: 'OM',
    label: 'Omaaan',
  },
  {
    value: 'PK',
    label: 'Pakistan',
  },
  {
    value: 'PW',
    label: 'Paula',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Paapua Uus-Guinea',
  },
  {
    value: 'PY',
    label: 'Paraguay',
  },
  {
    value: 'PE',
    label: 'Peruu',
  },
  {
    value: 'PH',
    label: 'Filipiinid',
  },
  {
    value: 'PL',
    label: 'Poola',
  },
  {
    value: 'PT',
    label: 'Portugal',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'QA',
    label: 'Katar',
  },
  {
    value: 'RO',
    label: 'Rumeenia',
  },
  {
    value: 'RU',
    label: 'Venemaa Föderatsioon',
  },
  {
    value: 'RW',
    label: 'Rwanda',
  },
  {
    value: 'SH',
    label: 'Püha Helena, Ülestõusmine ja Tristan da cunha',
  },
  {
    value: 'KN',
    label: 'Saint Kitts ja Nevis',
  },
  {
    value: 'LC',
    label: 'Püha Lucia',
  },
  {
    value: 'PM',
    label: 'Püha Pierre ja Miquelon',
  },
  {
    value: 'VC',
    label: 'Püha Vincent ja Grenadiinid',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao Tome ja Principe',
  },
  {
    value: 'SA',
    label: 'Saudi Araabia',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Serbia',
  },
  {
    value: 'SC',
    label: 'Seišellid',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Singapur',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Slovakkia',
  },
  {
    value: 'SI',
    label: 'Sloveenia',
  },
  {
    value: 'SB',
    label: 'Saalomoni saared',
  },
  {
    value: 'SO',
    label: 'Somaalia',
  },
  {
    value: 'ZA',
    label: 'Lõuna-Aafrika',
  },
  {
    value: 'SS',
    label: 'Lõuna -Sudaan',
  },
  {
    value: 'ES',
    label: 'Hispaania',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
  },
  {
    value: 'SD',
    label: 'Sudaan',
  },
  {
    value: 'SR',
    label: 'Surinanimi',
  },
  {
    value: 'SE',
    label: 'Rootsi',
  },
  {
    value: 'CH',
    label: 'Šveits',
  },
  {
    value: 'SY',
    label: 'Süüria Araabia Vabariik',
  },
  {
    value: 'TW',
    label: 'Taiwan',
  },
  {
    value: 'TJ',
    label: 'Tadžikistan',
  },
  {
    value: 'TZ',
    label: 'Tansaania',
  },
  {
    value: 'TH',
    label: 'Tai',
  },
  {
    value: 'TL',
    label: 'Timor Lest',
  },
  {
    value: 'TG',
    label: 'Togo vabariik',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tong',
  },
  {
    value: 'TT',
    label: 'Trinidad ja Tobago',
  },
  {
    value: 'TN',
    label: 'Tuneesia',
  },
  {
    value: 'TR',
    label: 'Türgi Vabariik',
  },
  {
    value: 'TM',
    label: 'Türkmenistan',
  },
  {
    value: 'TC',
    label: 'Türklased ja caicos',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukraina',
  },
  {
    value: 'AE',
    label: 'Araabia Ühendemiraadid',
  },
  {
    value: 'GB',
    label: 'Ühendkuningriik',
  },
  {
    value: 'US',
    label: 'Ühendriigid',
  },
  {
    value: 'US',
    label: 'Ameerika Ühendriikide Neitsisaared',
  },
  {
    value: 'UY',
    label: 'Uruguay',
  },
  {
    value: 'UZ',
    label: 'Usbekistan',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Vietnam',
  },
  {
    value: 'WF',
    label: 'Wallis ja Futuna',
  },
  {
    value: 'YE',
    label: 'Jeemen',
  },
  {
    value: 'ZM',
    label: 'Sambia',
  },
  {
    value: 'ZM',
    label: 'Zimbabwe',
  },
];
