import { BoxProps, useTheme } from '@mui/material';
import React from 'react';
import { HEADER_HEIGHT } from '../../constants/dimensions';
import FlexBox from '../flexbox/flexbox';

interface ContainerProps extends BoxProps {
  isInverse?: boolean;
}

const Container: React.FC<ContainerProps> = ({ isInverse, children }) => {
  const { palette } = useTheme();

  return (
    <FlexBox
      sx={{
        backgroundColor: isInverse
          ? palette.common.white
          : palette.primaryVariations['900'],
        height: HEADER_HEIGHT,
        px: 2.5,
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: 25,
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      {children}
    </FlexBox>
  );
};

export default Container;
