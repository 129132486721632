import * as React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import PrefixSelect, { PrefixSelectOptions } from './prefix-select';

type PrefixSelectWithControllerProps<T extends FieldValues> = Omit<
  PrefixSelectOptions,
  'onSelectChange' | 'ref'
> & {
  control: Control<T>;
  name: FieldPath<T>;
};

const PrefixSelectWithController = <ObjectType extends FieldValues>({
  textFieldProps,
  control,
  name,
  ...otherProps
}: PrefixSelectWithControllerProps<ObjectType>) => {
  return (
    <Controller
      render={({ field: { onChange, ...fieldProps } }) => (
        <PrefixSelect
          onSelectChange={onChange}
          textFieldProps={textFieldProps}
          {...fieldProps}
          {...otherProps}
        />
      )}
      name={name}
      control={control}
    />
  );
};

export default PrefixSelectWithController;
