import ExpandLessIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PictogramsDtoDetailedSustainabilityEnum } from '../../api';
import FlexBoxColumn from '../../components/flexbox/flex-box-column';
import { DetailedSustainabilityPictograms } from '../../constants/pictograms';
import { Pictogram, PictogramId } from '../../models/pictogram';
import WasteCategory from './waste-category';
import { Info } from '@mui/icons-material';
import InfoBox from '../../components/info-box/info-box';

interface DetailedSustainabilityProps {
  pictogramIds: PictogramId[];
}

const DetailedSustainability: React.FC<DetailedSustainabilityProps> = ({
  pictogramIds,
}) => {
  const { t } = useTranslation();
  const pictograms = DetailedSustainabilityPictograms.filter((it) =>
    pictogramIds.includes(it.id as PictogramsDtoDetailedSustainabilityEnum)
  );
  const pictogramsByResinCode = pictograms.reduce(
    (prev, curr) =>
      curr.details?.resinCode
        ? Object.keys(prev).includes(curr.details?.resinCode)
          ? {
              ...prev,
              [curr.details?.resinCode]: [
                ...prev[curr.details.resinCode],
                curr,
              ],
            }
          : {
              ...prev,
              [curr.details?.resinCode]: [curr],
            }
        : prev,
    {} as Record<string, Pictogram[]>
  );

  const hasAnyCategory = Object.keys(pictogramsByResinCode).length > 0;
  const displaySortingMessage = Object.keys(pictogramsByResinCode).length > 1;

  return (
    <>
      {hasAnyCategory ? (
        <Accordion disableGutters sx={{ '&::before': { height: 0 } }}>
          <AccordionSummary
            expandIcon={<ExpandLessIcon />}
            sx={{
              justifyContent: 'flex-start',
              '& > .MuiAccordionSummary-content': { flexGrow: 0 },
            }}
          >
            <Typography fontFamily="inherit">
              {t('winefo-point.recycling-information')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FlexBoxColumn gap={2}>
              {displaySortingMessage && (
                <InfoBox
                  icon={<Info color="inherit" />}
                  label={t('winefo-point.waste-sorting-message')}
                  title={t('winefo-point.waste-sorting-title')}
                />
              )}
              {Object.keys(pictogramsByResinCode).map((it, index) => (
                <WasteCategory
                  key={index}
                  pictograms={pictogramsByResinCode[it]}
                />
              ))}
            </FlexBoxColumn>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </>
  );
};

export default DetailedSustainability;
