import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardFooter from '../../components/card/card-footer';
import DeleteAccountButton from '../../components/delete-account/delete-account-button';
import FlexBox from '../../components/flexbox/flexbox';
import { useAuthContext } from '../../services/auth-context';
import { useSnackbar } from '../../services/snackbar-context';

const Footer: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { sendVerificationEmail } = useAuthContext();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const resendEmail = async () => {
    setLoading(true);
    try {
      await sendVerificationEmail(language);
    } finally {
      setLoading(false);
      setSnackbar({ message: t('email-verification.resend-success-message') });
    }
  };

  return (
    <CardFooter sx={{ justifyContent: 'flex-end' }}>
      <Typography variant="overline" sx={{ my: 1 }}>
        {t('email-verification.resend-message')}
      </Typography>
      <FlexBox sx={{ width: '100%', px: 4, mb: 3.5 }}>
        <DeleteAccountButton fullWidth sx={{ mr: 1 }} />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ ml: 1 }}
          disabled={loading}
          onClick={resendEmail}
        >
          {t('email-verification.resend-button-text')}
        </Button>
      </FlexBox>
    </CardFooter>
  );
};

export default Footer;
