import * as React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import CountrySelect, { CountrySelectOptions } from './country-select';

type CountrySelectWithControllerProps<T extends FieldValues> = Omit<
  CountrySelectOptions,
  'onSelectChange' | 'ref'
> & {
  control: Control<T>;
  name: FieldPath<T>;
};

const CountrySelectWithController = <ObjectType extends FieldValues>({
  textFieldProps,
  control,
  name,
  ...otherProps
}: CountrySelectWithControllerProps<ObjectType>) => {
  return (
    <Controller
      render={({ field: { onChange, ...fieldProps } }) => (
        <CountrySelect
          onSelectChange={(selected) => onChange(selected ? selected : '')}
          textFieldProps={textFieldProps}
          {...fieldProps}
          {...otherProps}
        />
      )}
      name={name}
      control={control}
    />
  );
};

export default CountrySelectWithController;
