import React from 'react';
import FlexBox from '../../components/flexbox/flexbox';
import TabPanel, { TabPanelProps } from '../../components/tab/tab-panel';
import { HEADER_HEIGHT, TABS_HEIGHT } from '../../constants/dimensions';
import { LicenceCardVersion } from '../../models/licence';

interface LicenceCardsWrapperProps extends TabPanelProps {
  isDense?: boolean;
  withTabs?: boolean;
  version?: LicenceCardVersion;
}

const LicenceCardsWrapper: React.FC<LicenceCardsWrapperProps> = ({
  isDense,
  withTabs,
  version,
  children,
  ...rest
}) => {
  const isVersion2 = version === LicenceCardVersion.V2;

  return (
    <TabPanel {...rest}>
      <FlexBox
        sx={{
          height: `calc(100vh - ${HEADER_HEIGHT}px - ${
            withTabs ? TABS_HEIGHT : 0
          }px)`,
          justifyContent: isDense ? 'space-around' : 'center',
          alignItems: 'flex-start',
          gap: isVersion2 ? 2 : isDense ? 0.75 : 7,
          py: isVersion2 ? 3 : 4,
          px: 1,
          flexWrap: 'wrap',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        {children}
      </FlexBox>
    </TabPanel>
  );
};

export default LicenceCardsWrapper;
