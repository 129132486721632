import React, { ReactNode, createContext, useContext } from 'react';
import { AuthService } from '../models/authorization';
import { useAuthService } from './use-auth-service';

const throwMissingFunctionError = () => {
  throw Error('missing function implementation in ingredients context');
};

const initialValue = {
  currentAuthenticatedUser: throwMissingFunctionError,
  removeAccount: throwMissingFunctionError,
  createAccount: throwMissingFunctionError,
  sendVerificationEmail: throwMissingFunctionError,
  verify: throwMissingFunctionError,
  logout: throwMissingFunctionError,
  getToken: throwMissingFunctionError,
  login: throwMissingFunctionError,
  isUserVerified: throwMissingFunctionError,
  currentSession: throwMissingFunctionError,
  forgotPassword: throwMissingFunctionError,
  forgotPasswordSubmit: throwMissingFunctionError,
  changePassword: throwMissingFunctionError,
};

export const AuthContext = createContext<AuthService>({ ...initialValue });

export const AuthProvider: React.FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const authService = useAuthService();
  return (
    <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthService => {
  return useContext(AuthContext);
};
