import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { languageCodes } from '../../../constants/languages';
import { useTranslation } from 'react-i18next';

interface CustomIngredientLanguageSelectorProps {
  value: string | undefined;
  onChange: (languageCode: string | undefined) => void;
  hiddenLanguageCodes?: string[];
  error?: boolean;
}

const CustomIngredientLanguageSelector: React.FC<
  CustomIngredientLanguageSelectorProps
> = ({ value: valueProp, onChange, hiddenLanguageCodes = [], error }) => {
  const { t } = useTranslation();
  const modalNamespace = 'edit-label.ingredients-section.translations-modal';

  return (
    <Autocomplete
      disablePortal
      options={[
        { label: '', languageCode: undefined },
        ...languageCodes
          .map((code) => ({
            label: t(`languages.${code}`),
            languageCode: code,
          }))
          .filter(
            (option) => !hiddenLanguageCodes.includes(option.languageCode)
          ),
      ].sort((a, b) => a.label.localeCompare(b.label))}
      disableClearable={!valueProp}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(`${modalNamespace}.translation-language`)}
          error={error}
          helperText={
            error ? t(`${modalNamespace}.translation-language-error`) : ''
          }
        />
      )}
      renderOption={(props, option) => (
        <React.Fragment key={option.label}>
          {option.label ? <li {...props}>{option.label}</li> : null}
        </React.Fragment>
      )}
      isOptionEqualToValue={(option, value) =>
        !value
          ? option.languageCode === null
          : option.languageCode === value.languageCode
      }
      value={{
        languageCode: valueProp,
        label: !valueProp ? '' : t(`languages.${valueProp}`),
      }}
      onChange={(_, newValue) =>
        onChange(!newValue ? undefined : newValue.languageCode)
      }
      fullWidth
    />
  );
};

export default CustomIngredientLanguageSelector;
