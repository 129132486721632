import { useEffect, useState } from 'react';
import { FormState } from 'react-hook-form';
import {
  accountBlockFields,
  companyBlockFields,
  locationBlockFields,
} from './signup-form-utils';
import { SignupFormValue } from '../../models/signup';

export const useBlocksStatuses = (formState: FormState<SignupFormValue>) => {
  const { errors, dirtyFields } = formState;
  const [initiallyValidated, setInitiallyValidated] = useState(false);
  const [blocksDirtyStatus, setBlocksDirtyStatus] = useState({
    company: false,
    location: false,
    account: false,
  });
  const isBlockValid = (fields: (keyof SignupFormValue)[]) =>
    initiallyValidated &&
    fields.every((field) => !Object.keys(errors).includes(field));
  const isBlockDirty = (fields: (keyof SignupFormValue)[]) =>
    fields.every((field) => Object.keys(dirtyFields).includes(field));
  const blocksValidationStatus = {
    company: initiallyValidated && isBlockValid(companyBlockFields),
    location: initiallyValidated && isBlockValid(locationBlockFields),
    account: initiallyValidated && isBlockValid(accountBlockFields),
  };

  useEffect(() => {
    setBlocksDirtyStatus({
      company:
        blocksDirtyStatus.company ||
        isBlockDirty(companyBlockFields.filter((it) => it !== 'companyUrl')),
      location: blocksDirtyStatus.location || isBlockDirty(locationBlockFields),
      account: blocksDirtyStatus.account || isBlockDirty(accountBlockFields),
    });
  }, [Object.keys(dirtyFields)]);

  return {
    setInitiallyValidated,
    blocksValidationStatus,
    blocksDirtyStatus,
  };
};
