import { PremiumFeature } from '../../api';
import { Colors } from '../../models/premium-features';

export const getColors = (
  premiumFeature?: PremiumFeature
): Partial<Colors> => ({
  primary: premiumFeature?.primaryColour,
  secondary: premiumFeature?.secondaryColour,
  primaryText: premiumFeature?.primaryTextColour,
  secondaryText: premiumFeature?.secondaryTextColour,
});
