import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductProductFamilyEnum } from '../../api';
import backgroundImage from '../../assets/images/licences-background.svg';
import Loading from '../../components/loading/loading';
import Background from '../../components/screen-template/background';
import { GERMAN_CUSTOM_MEMBERS } from '../../constants/licences';
import useGetProducts from '../../data/product/use-get-products';
import useProfileCountry from '../../data/profile/use-profile-country';
import LicenceCard from './licence-card';
import LicenceCardsWrapper from './licence-cards-wrapper';
import LicencesTabs from './licences-tabs';
import { Personalization } from './personalization';
import { LicenceCardVersion } from '../../models/licence';

const Licences: React.FC = () => {
  const { t } = useTranslation();
  const { isLoading, isGermanAccount } = useProfileCountry();
  const { data } = useGetProducts();
  const version = isGermanAccount
    ? LicenceCardVersion.V1
    : LicenceCardVersion.V2;
  const baseProductFamily = isGermanAccount
    ? ProductProductFamilyEnum.GermanBase
    : ProductProductFamilyEnum.Base;
  const products = Array.from(data?.products || []);
  const basicProduct = products.find(
    (it) => it.productFamily === baseProductFamily
  );
  const furtherProducts = products
    .filter((it) => it.productFamily !== baseProductFamily)
    .sort((a, b) => (a.accounts || 0) - (b.accounts || 0));
  const [withPersonalization, setWithPersonalization] = useState(false);
  const [tab, setTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const togglePersonalization = () =>
    setWithPersonalization(!withPersonalization);

  if (isLoading) return <Loading />;

  return (
    <Background imageSource={backgroundImage}>
      <Box width="100%" height="100%">
        {isGermanAccount && (
          <LicencesTabs value={tab} onChange={handleTabChange} />
        )}
        <LicenceCardsWrapper
          value={tab}
          index={0}
          withTabs={isGermanAccount}
          version={version}
        >
          <LicenceCard
            product={basicProduct}
            onTogglePersonalization={
              isGermanAccount ? togglePersonalization : undefined
            }
            version={version}
          />
          {!isGermanAccount && (
            <LicenceCard
              product={basicProduct}
              version={LicenceCardVersion.V2}
              withPersonalization
            />
          )}
          <LicenceCard
            isCustom
            version={
              isGermanAccount ? LicenceCardVersion.V1 : LicenceCardVersion.V2
            }
          />
        </LicenceCardsWrapper>
        {isGermanAccount && (
          <LicenceCardsWrapper value={tab} index={1} withTabs isDense>
            {furtherProducts.map((product) => (
              <LicenceCard
                key={product.productFamily}
                product={product}
                onTogglePersonalization={togglePersonalization}
                isDense
                preHeaderText={`${product.members} ${t('licences.members')}`}
              />
            ))}
            <LicenceCard
              isDense
              isCustom
              preHeaderText={
                <>
                  <Typography component="span">{`${GERMAN_CUSTOM_MEMBERS} ${t(
                    'licences.members'
                  )}`}</Typography>
                  <Typography
                    component="span"
                    sx={{ color: 'rgba(0, 0, 0, 0.12)' }}
                  >
                    {' | '}
                  </Typography>
                  <Typography component="span">
                    {t('licences.wine-cellar')}
                  </Typography>
                </>
              }
            />
          </LicenceCardsWrapper>
        )}
      </Box>
      <Personalization displayed={withPersonalization} />
    </Background>
  );
};

export default Licences;
