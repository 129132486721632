import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { WineLabelView } from '../../api';
import { WINE_LABEL_KEY } from './use-get-wine-label';
import wineLabelApi from './wine-label-api';

interface UpdateWineLabelVariables {
  id: number;
  data: WineLabelView;
  onSuccess: () => void;
}

const useUpdateWineLabel = (): UseMutationResult<
  AxiosResponse,
  unknown,
  UpdateWineLabelVariables,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: UpdateWineLabelVariables) =>
      wineLabelApi.updateLabel(variables.id, variables.data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([WINE_LABEL_KEY, variables.id]);
      variables.onSuccess();
    },
  });
};

export default useUpdateWineLabel;
