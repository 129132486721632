import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  WineLabelCustomPictogramViewPictogramGroupEnum as CustomPictogramGroup,
  UploadCustomPictogramResponse,
} from '../../api';
import pictogramsApi from './pictograms-api';

interface UploadCustomPictogramValue {
  pictogramGroup: CustomPictogramGroup;
  image: File;
}

const useUploadCustomPictogram = (): UseMutationResult<
  AxiosResponse<UploadCustomPictogramResponse>,
  unknown,
  UploadCustomPictogramValue,
  unknown
> => {
  return useMutation({
    mutationFn: (data: UploadCustomPictogramValue) =>
      pictogramsApi.uploadCustomPictogram(data.pictogramGroup, data.image),
  });
};

export default useUploadCustomPictogram;
