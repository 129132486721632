import { Add, Info } from '@mui/icons-material';
import { Box, Button, Card, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FlexBox from '../../../components/flexbox/flexbox';
import HelperTooltip from '../../../components/helper/helper-tooltip';
import InfoBox from '../../../components/info-box/info-box';
import NutritionTable from '../../../components/nutrition-table/nutrition-table';
import { primary } from '../../../constants/colors';
import { KCAL_TO_KJ } from '../../../constants/unit-convertion';
import { EditLabelSplitFormProps } from '../../../models/label';
import { getNumericValue } from '../../../utils/label/nutrition';
import EnergyCalculatorModal from './energy-calculator-modal';
import WineListModal from './wine-list-modal';
import Switch from '../../../components/switch/switch';

export const defaultNutritionSubsectionValues = {
  nutritionDeclaration: {
    energyKcal: undefined,
    fat: undefined,
    saturatedFat: undefined,
    carbohydrate: undefined,
    sugar: undefined,
    protein: undefined,
    salt: undefined,
  },
};

const NutritionSubsection = (props: EditLabelSplitFormProps) => {
  const { control, watch } = props;
  const { t } = useTranslation();

  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
  const [isWineListOpen, setIsWineListOpen] = useState(false);
  const withEnergyCalculation = watch ? watch('autoKcalConversion') : true;

  return (
    <Controller
      name="nutritionDeclaration"
      control={control}
      render={({ field }) => (
        <Box mb={3}>
          <EnergyCalculatorModal
            open={isCalculatorOpen}
            onClose={() => setIsCalculatorOpen(false)}
            onSubmit={(energy, sugar) =>
              field.onChange({
                ...field.value,
                energyKcal: Math.floor(energy),
                energyKj: Math.round(energy * KCAL_TO_KJ),
                sugar: sugar || field.value?.sugar,
              })
            }
            sugar={getNumericValue(field.value?.sugar)}
          />
          <WineListModal
            open={isWineListOpen}
            onClose={() => setIsWineListOpen(false)}
            onSubmit={(energy, nutritions) =>
              field.onChange({
                ...field.value,
                ...nutritions,
                energyKcal: Math.floor(energy),
                energyKj: Math.floor(energy * KCAL_TO_KJ),
              })
            }
            sugar={getNumericValue(field.value?.sugar)}
          />
          <InfoBox label={t('edit-label.nutrition-info')} icon={<Info />} />
          <FlexBox gap={1} mt={2} ml={2.5} alignItems="center">
            <Typography>{t('edit-label.kcal-kj-toggle')}</Typography>
            <Controller
              name="autoKcalConversion"
              control={control}
              render={({ field: autoCalculationField }) => (
                <Switch
                  checked={autoCalculationField.value}
                  onChange={autoCalculationField.onChange}
                />
              )}
            />
          </FlexBox>
          <Card sx={{ my: '20px', py: '20px' }}>
            <NutritionTable
              value={field.value}
              onChange={field.onChange}
              withEnergyCalculation={withEnergyCalculation}
            />
          </Card>
          <FlexBox alignItems="center" justifyContent="space-between" pb={1}>
            <Typography>{t('edit-label.physical-label')}</Typography>
            <HelperTooltip title={t('edit-label.physical-label-helper')} />
          </FlexBox>
          <Card elevation={4} sx={{ mt: '10px', mb: '30px', p: '20px' }}>
            <FlexBox color={primary[700]} justifyContent="space-between">
              <Typography color="black">
                {t('edit-label.energy-per-100-ml', {
                  kcal: Math.round(getNumericValue(field.value?.energyKcal)),
                  kJ: Math.round(getNumericValue(field.value?.energyKj)),
                })}
              </Typography>
              <Tooltip
                title={t('edit-label.energy-per-100-ml-helper')}
                arrow
                placement="top"
              >
                <Info color="inherit" />
              </Tooltip>
            </FlexBox>
          </Card>
          <Typography>{t('edit-label.calculator-description')}</Typography>
          <FlexBox justifyContent="space-evenly">
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setIsWineListOpen(true)}
              sx={{ my: '24px' }}
            >
              {t('edit-label.wine-list')}
            </Button>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setIsCalculatorOpen(true)}
              sx={{ my: '24px' }}
            >
              {t('edit-label.calculator')}
            </Button>
          </FlexBox>
        </Box>
      )}
    />
  );
};

export default NutritionSubsection;
