import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { PremiumFeature } from '../../api';
import premiumFeaturesApi from './premium-features-api';

export const PREMIUM_FEATURES_QUERY_KEY = 'premium-features';

const useGetPremiumFeatures = (
  options?: UseQueryOptions<PremiumFeature, unknown, PremiumFeature, string[]>
): UseQueryResult<PremiumFeature, unknown> => {
  return useQuery({
    queryKey: [PREMIUM_FEATURES_QUERY_KEY],
    queryFn: () =>
      premiumFeaturesApi.getPremiumFeatures().then((result) => result.data),
    ...options,
  });
};

export default useGetPremiumFeatures;
