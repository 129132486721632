import * as yup from 'yup';
import passwordSchema from '../../utils/schemas/password-schema';
import { ResetPasswordFormValue } from './reset-password-form';

const resetPasswordFormSchema = (
  withCurrentPassword?: boolean
): yup.ObjectSchema<ResetPasswordFormValue> => {
  return passwordSchema().shape({
    currentPassword: withCurrentPassword
      ? yup.string().required()
      : yup.string().optional(),
  });
};

export default resetPasswordFormSchema;
