import { Box, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import useSearchLabels from '../../data/wine-label/use-search-labels';
import { SortBy, SortDirection, TableOptions } from '../../models/labels-table';
import { useSearchContext } from '../../services/search-context';
import FirstLabelCard from './first-label-card';
import LabelsTable from './labels-table';

const Labels: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { searchText } = useSearchContext();
  const initialTableOptions = {
    pageNumber: 0,
    sortBy: SortBy.CREATED,
    sortDirection: SortDirection.DESC,
    size: 10,
  };
  const [tableOptions, setTableOptions] =
    useState<TableOptions>(initialTableOptions);
  const { data, isLoading, refetch } = useSearchLabels(
    { searchText, ...tableOptions },
    {
      onError: (e) => {
        const hasNoLicence = (e as AxiosError)?.response?.status === 403;
        if (hasNoLicence) navigate(ROUTES.LICENCES);
      },
    }
  );
  const hasNoLabel = data?.wineLabelPage?.totalElements === 0;

  useEffect(() => {
    refetch();
  }, [tableOptions]);

  return (
    <Box sx={{ px: 2, py: 4 }}>
      <Typography variant="h5">{t('labels.title')}</Typography>
      <Box mt={1}>
        {hasNoLabel ? (
          <FirstLabelCard />
        ) : (
          <LabelsTable
            labels={data?.wineLabelPage?.content || []}
            totalElements={data?.wineLabelPage?.totalSearchElements || 0}
            isLoading={isLoading}
            tableOptions={tableOptions}
            onTableOptionsChange={setTableOptions}
          />
        )}
      </Box>
    </Box>
  );
};

export default Labels;
