import {
  Fab as MuiFab,
  FabProps as MuiFabProps,
  useTheme,
  Typography,
} from '@mui/material';
import React from 'react';

export interface FabProps extends MuiFabProps {
  icon?: React.ReactNode;
  label?: string;
}

const Fab: React.FC<FabProps> = ({ icon, label, ...rest }) => {
  const { palette } = useTheme();

  return (
    <MuiFab
      variant="extended"
      {...rest}
      sx={{
        bgcolor: palette.grey[600],
        height: '24px',
        gap: 0.5,
        px: 1,
        zIndex: 0,
        color: '#FFF',
        '& > svg': { fontSize: '1rem' },
        '&:hover': { bgcolor: palette.grey[500] },
        ...rest.sx,
      }}
    >
      <Typography
        variant="body2"
        color="inherit"
        sx={{
          textTransform: 'none',
          fontSize: '0.75rem',
          lineHeight: '1.245',
        }}
      >
        {label}
      </Typography>
      {icon}
    </MuiFab>
  );
};

export default Fab;
