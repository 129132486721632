import {
  Control,
  FormState,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import {
  Ingredient,
  IngredientGroupOrder,
  PictogramsDto,
  PictogramsDtoDetailedSustainabilityEnum,
  PictogramsDtoResponsibleConsumptionEnum,
  PictogramsDtoSustainabilityEnum,
  WineLabelView,
} from '../api';

export interface PictogramsValue
  extends Omit<
    PictogramsDto,
    'sustainability' | 'responsibleConsumption' | 'detailedSustainability'
  > {
  sustainability?: PictogramsDtoSustainabilityEnum[];
  responsibleConsumption?: PictogramsDtoResponsibleConsumptionEnum[];
  detailedSustainability?: PictogramsDtoDetailedSustainabilityEnum[];
}
export interface IngredientValue
  extends Omit<Ingredient, 'ingredientGroupOrders'> {
  ingredientGroupOrders?: IngredientGroupOrder[];
}
export interface LabelValue
  extends Omit<WineLabelView, 'harvestYear' | 'pictograms' | 'ingredient'> {
  harvestYear?: string;
  pictograms?: PictogramsValue;
  ingredient?: IngredientValue;
}

export enum EditLabelSections {
  COMPANY = 'COMPANY',
  PRODUCT = 'PRODUCT',
  PICTOGRAM = 'PICTOGRAM',
  INGREDIENTS = 'INGREDIENTS',
  PREVIEW = 'PREVIEW',
  NUTRITION = 'NUTRITION',
}

export interface EditLabelSplitFormProps {
  control: Control<LabelValue>;
  formState: FormState<LabelValue>;
  trigger?: UseFormTrigger<LabelValue>;
  getValues?: UseFormGetValues<LabelValue>;
  setValue?: UseFormSetValue<LabelValue>;
  watch?: UseFormWatch<LabelValue>;
}

export enum EditSubIngredientsSection {
  INGREDIENTS = 'INGREDIENTS',
  PREVIEW = 'PREVIEW',
}
