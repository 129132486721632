import jwt_decode from 'jwt-decode';

const isTokenSimple = (token: string): boolean => {
  const header: { discriminator?: string } = jwt_decode(token, {
    header: true,
  });
  return header.discriminator === 'SIMPLE';
};

export default isTokenSimple;
