import * as yup from 'yup';

const passwordSchema = (): yup.ObjectSchema<{ password: string }> => {
  return yup.object().shape({
    password: yup
      .string()
      .required()
      .matches(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z]))((?=.*[A-Z])).*$/),
  });
};

export default passwordSchema;
