import { Card } from '@mui/material';
import React, { useState } from 'react';
import { Product } from '../../api';
import Features from './features';
import LicenceCardAction from './licence-card-action';
import LicenceCardFooter from './licence-card-footer';
import LicenceCardHeader from './licence-card-header';
import LicenceCardPreHeader from './licence-card-pre-header';
import { LicenceCardVersion } from '../../models/licence';

interface LicenceCardProps {
  product?: Product;
  isCustom?: boolean;
  onTogglePersonalization?: () => void;
  isDense?: boolean;
  preHeaderText?: React.ReactNode;
  version?: LicenceCardVersion;
  withPersonalization?: boolean;
}

const LicenceCard: React.FC<LicenceCardProps> = ({
  product,
  isCustom,
  onTogglePersonalization,
  isDense,
  preHeaderText,
  version,
  withPersonalization: withPersonalizationProp,
}) => {
  const [withPersonalization, setWithPersonalization] = useState(
    withPersonalizationProp
  );
  const withPreHeaderText = typeof preHeaderText !== 'undefined';
  const handlePersonalizationToggle = () =>
    setWithPersonalization(!withPersonalization);
  const isVersion2 = version === LicenceCardVersion.V2;

  return (
    <Card
      sx={{
        minWidth: `${isVersion2 ? 304 : isDense ? 250 : 350}px`,
        maxWidth: `${isVersion2 ? 304 : isDense ? 250 : 350}px`,
        ...(isVersion2 ? { borderRadius: 4 } : {}),
      }}
    >
      {withPreHeaderText && <LicenceCardPreHeader text={preHeaderText} />}
      <LicenceCardHeader
        product={product}
        isCustom={isCustom}
        withPersonalization={withPersonalization}
        version={version}
      />
      <LicenceCardAction
        product={product}
        isCustom={isCustom}
        withPersonalization={withPersonalization}
      />
      <Features
        type="licence"
        product={product}
        isCustom={isCustom}
        withPersonalization={withPersonalization}
        version={version}
      />
      {!isCustom && !isVersion2 && (
        <LicenceCardFooter
          product={product}
          checked={withPersonalization}
          onChange={handlePersonalizationToggle}
          onMouseEnter={onTogglePersonalization}
          onMouseLeave={onTogglePersonalization}
          isDense={isDense}
        />
      )}
    </Card>
  );
};

export default LicenceCard;
