import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import registrationApi from '../../data/registration/registration-api';
import { SignupFormValue } from '../../models/signup';
import { useAuthContext } from '../../services/auth-context';
import { useSnackbar } from '../../services/snackbar-context';
import { getPrefixForCountryCode } from '../../utils/countries/country-code-prefix';
import { getStripeTaxType } from '../../utils/tax-id/tax-id-utils';

export const useRegisterUser = (): ((
  formValue: SignupFormValue
) => Promise<void>) => {
  const { createAccount, sendVerificationEmail, removeAccount } =
    useAuthContext();
  const { t, i18n } = useTranslation();
  const { setSnackbar } = useSnackbar();
  return async (formValue: SignupFormValue): Promise<void> => {
    try {
      await createAccount(formValue.email || '', formValue.password || '');
    } catch (e) {
      if ((e as AxiosError).name === 'UserLambdaValidationException') {
        setSnackbar({
          message: t('signup.error-email-already-registered'),
        });
      } else {
        setSnackbar({
          message: t('error.general-network-error'),
        });
      }
      return Promise.reject();
    }
    try {
      await registrationApi.finalizeRegistration({
        address: formValue.address,
        brand: formValue.companyBrand,
        city: formValue.city,
        contact: {
          email: formValue.email,
          name: formValue.contactPerson,
          phoneNumber: formValue.phoneNumber,
          phoneCountryCode: getPrefixForCountryCode(formValue.prefix),
        },
        country: formValue.country,
        legalName: formValue.companyLegalName,
        postalCode: formValue.postalCode,
        state: formValue.state,
        vatNumber: formValue.companyId,
        vatNumberCountry: formValue.companyIdCountry,
        stripeTaxType: getStripeTaxType(
          formValue.companyId,
          formValue.companyIdCountry
        ),
        website: formValue.companyUrl,
        isMarketingMessageAllowed: formValue.marketingConsent,
        registrationCode: formValue.registrationCode,
      });
      return sendVerificationEmail(i18n.language);
    } catch (e) {
      setSnackbar({
        message: t('error.general-network-error'),
      });
      await removeAccount();
    }
    return Promise.reject();
  };
};
