import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import customImagesApi from './custom-images-api';

const useDeleteCustomImage = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  number,
  unknown
> => {
  return useMutation({
    mutationFn: (id: number) => customImagesApi.deleteCustomImage(id),
  });
};

export default useDeleteCustomImage;
