import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { WINE_LABELS_KEY } from './use-search-labels';
import wineLabelApi from './wine-label-api';

interface DuplicateLabelVariables {
  id: number;
}

const useDuplicateLabel = (): UseMutationResult<
  AxiosResponse,
  unknown,
  DuplicateLabelVariables,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: DuplicateLabelVariables) =>
      wineLabelApi.duplicateLabel(variables.id),
    onSuccess: () => {
      queryClient.invalidateQueries([WINE_LABELS_KEY]);
    },
  });
};

export default useDuplicateLabel;
