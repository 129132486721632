export default [
  {
    value: 'AF',
    label: 'Afganistan',
  },
  {
    value: 'AL',
    label: 'Albánsko',
  },
  {
    value: 'DZ',
    label: 'Alžírsko',
  },
  {
    value: 'AS',
    label: 'Americká samoa',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguilla',
  },
  {
    value: 'AG',
    label: 'Antigua a Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentína',
  },
  {
    value: 'AM',
    label: 'Arménsko',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Austrália',
  },
  {
    value: 'AT',
    label: 'Rakúsko',
  },
  {
    value: 'AZ',
    label: 'Azerbajdžan',
  },
  {
    value: 'BS',
    label: 'Bahamy',
  },
  {
    value: 'BH',
    label: 'Bahrajn',
  },
  {
    value: 'BD',
    label: 'Bangladéš',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Bielorusko',
  },
  {
    value: 'BE',
    label: 'Belgicko',
  },
  {
    value: 'BZ',
    label: 'Búda',
  },
  {
    value: 'BJ',
    label: 'Benin',
  },
  {
    value: 'BM',
    label: 'Bermuda',
  },
  {
    value: 'BT',
    label: 'Bhután',
  },
  {
    value: 'BO',
    label: 'Bolívia',
  },
  {
    value: 'BA',
    label: 'Bosna a Hercegovina',
  },
  {
    value: 'BW',
    label: 'Botswana',
  },
  {
    value: 'BR',
    label: 'Brazília',
  },
  {
    value: 'IO',
    label: 'Britské Panenské ostrovy',
  },
  {
    value: 'BN',
    label: 'Brunej Darussalam',
  },
  {
    value: 'BG',
    label: 'Bulharsko',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burz',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Kambodža',
  },
  {
    value: 'CM',
    label: 'Kamerun',
  },
  {
    value: 'CA',
    label: 'Kanada',
  },
  {
    value: 'KY',
    label: 'Kajmanské ostrovy',
  },
  {
    value: 'CF',
    label: 'Stredoafrická republika',
  },
  {
    value: 'TD',
    label: 'Čad',
  },
  {
    value: 'CL',
    label: 'Chile',
  },
  {
    value: 'CN',
    label: 'Čína',
  },
  {
    value: 'CO',
    label: 'Kolumbia',
  },
  {
    value: 'KM',
    label: 'Komory',
  },
  {
    value: 'CG',
    label: 'Kongo',
  },
  {
    value: 'CK',
    label: 'Kuchárové ostrovy',
  },
  {
    value: 'CR',
    label: 'Kostarika',
  },
  {
    value: 'CI',
    label: "Côte d'Ivoire",
  },
  {
    value: 'HR',
    label: 'Chorvátsko',
  },
  {
    value: 'CU',
    label: 'Kub',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Cyper',
  },
  {
    value: 'CZ',
    label: 'Česká republika',
  },
  {
    value: 'CZ',
    label: 'Kórejská demokratická ľudová republika',
  },
  {
    value: 'CD',
    label: 'Konžská demokratická republika',
  },
  {
    value: 'DK',
    label: 'Dánsko',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Džibuti',
  },
  {
    value: 'DM',
    label: 'Dominika',
  },
  {
    value: 'DO',
    label: 'Dominikánska republika',
  },
  {
    value: 'EC',
    label: 'Ekvádor',
  },
  {
    value: 'EG',
    label: 'Egypt',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'GQ',
    label: 'rovníková Guinea',
  },
  {
    value: 'ER',
    label: 'Eritrea',
  },
  {
    value: 'EE',
    label: 'Estónsko',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etiópia',
  },
  {
    value: 'FK',
    label: 'Falklandské ostrovy',
  },
  {
    value: 'FO',
    label: 'Faerské ostrovy',
  },
  {
    value: 'FJ',
    label: 'Fidži',
  },
  {
    value: 'FI',
    label: 'Fínsko',
  },
  {
    value: 'FR',
    label: 'Francúzsko',
  },
  {
    value: 'FR',
    label: 'Francúzske oddelenia a územia v Indickom oceáne',
  },
  {
    value: 'GF',
    label: 'Francúzska Guiana',
  },
  {
    value: 'PF',
    label: 'Francúzska Polynézia',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambie',
  },
  {
    value: 'GE',
    label: 'Gruzínsko',
  },
  {
    value: 'DE',
    label: 'Nemecko',
  },
  {
    value: 'GH',
    label: 'Ghana',
  },
  {
    value: 'GI',
    label: 'Gibraltár',
  },
  {
    value: 'GR',
    label: 'Grécko',
  },
  {
    value: 'GL',
    label: 'Grónsko',
  },
  {
    value: 'GD',
    label: 'Grenada',
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Guatemala',
  },
  {
    value: 'GG',
    label: 'Guinea',
  },
  {
    value: 'GN',
    label: 'Guinejský bissau',
  },
  {
    value: 'GY',
    label: 'Guyana',
  },
  {
    value: 'HT',
    label: 'Haiti',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hongkong',
  },
  {
    value: 'HU',
    label: 'Maďarsko',
  },
  {
    value: 'IS',
    label: 'Island',
  },
  {
    value: 'IN',
    label: 'India',
  },
  {
    value: 'ID',
    label: 'Indonézia',
  },
  {
    value: 'IR',
    label: 'Irán',
  },
  {
    value: 'IQ',
    label: 'Irak',
  },
  {
    value: 'IE',
    label: 'Írsko',
  },
  {
    value: 'IL',
    label: 'Izrael',
  },
  {
    value: 'IT',
    label: 'Taliansko',
  },
  {
    value: 'JM',
    label: 'Jamajka',
  },
  {
    value: 'JP',
    label: 'Japonsko',
  },
  {
    value: 'JO',
    label: 'Jordánsko',
  },
  {
    value: 'KZ',
    label: 'Kazachstan',
  },
  {
    value: 'KE',
    label: 'Keň',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Kórea',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuvajt',
  },
  {
    value: 'KG',
    label: 'Kirgizsko',
  },
  {
    value: 'LA',
    label: 'Demokratická republika Lao',
  },
  {
    value: 'LV',
    label: 'Lotyšsko',
  },
  {
    value: 'LB',
    label: 'Libanon',
  },
  {
    value: 'LS',
    label: 'Lesotho',
  },
  {
    value: 'LR',
    label: 'Libéria',
  },
  {
    value: 'LY',
    label: 'Líbya',
  },
  {
    value: 'LI',
    label: 'Lichtenstein',
  },
  {
    value: 'LT',
    label: 'Litva',
  },
  {
    value: 'LU',
    label: 'Luxembursko',
  },
  {
    value: 'MO',
    label: 'Macao',
  },
  {
    value: 'MG',
    label: 'Madagaskar',
  },
  {
    value: 'MW',
    label: 'Malawi',
  },
  {
    value: 'MY',
    label: 'Malajzia',
  },
  {
    value: 'MV',
    label: 'Zlyhanie',
  },
  {
    value: 'ML',
    label: 'Malica',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Maršalove ostrovy',
  },
  {
    value: 'MQ',
    label: 'Martinik',
  },
  {
    value: 'MR',
    label: 'Mauritánia',
  },
  {
    value: 'MU',
    label: 'Maurícius',
  },
  {
    value: 'MX',
    label: 'Mexiko',
  },
  {
    value: 'FM',
    label: 'Mikronézia',
  },
  {
    value: 'MD',
    label: 'Moldavia',
  },
  {
    value: 'MC',
    label: 'Monako',
  },
  {
    value: 'MN',
    label: 'Mongolsko',
  },
  {
    value: 'ME',
    label: 'Čierna Hora',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Maroku',
  },
  {
    value: 'MZ',
    label: 'Mozambik',
  },
  {
    value: 'MM',
    label: 'Mjanmarsko',
  },
  {
    value: 'NA',
    label: 'Namíbia',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepál',
  },
  {
    value: 'NL',
    label: 'Holandsko',
  },
  {
    value: 'NC',
    label: 'Nová Kaledónia',
  },
  {
    value: 'NZ',
    label: 'Nový Zéland',
  },
  {
    value: 'NI',
    label: 'Nikaragua',
  },
  {
    value: 'NE',
    label: 'Niger',
  },
  {
    value: 'NG',
    label: 'Nigéria',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Ostrov Norfolk',
  },
  {
    value: 'NF',
    label: 'Severný Macedónsko',
  },
  {
    value: 'MP',
    label: 'Ostrovy Severnej Mariany',
  },
  {
    value: 'NO',
    label: 'Nórsko',
  },
  {
    value: 'OM',
    label: 'Omán',
  },
  {
    value: 'PK',
    label: 'Pakistan',
  },
  {
    value: 'PW',
    label: 'Pažerák',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papua-Nová Guinea',
  },
  {
    value: 'PY',
    label: 'Paraguaj',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'PH',
    label: 'Filipíny',
  },
  {
    value: 'PL',
    label: 'Poľsko',
  },
  {
    value: 'PT',
    label: 'Portugalsko',
  },
  {
    value: 'PR',
    label: 'Portoriko',
  },
  {
    value: 'QA',
    label: 'Katar',
  },
  {
    value: 'RO',
    label: 'Rumunsko',
  },
  {
    value: 'RU',
    label: 'Ruská federácia',
  },
  {
    value: 'RW',
    label: 'Rwanda',
  },
  {
    value: 'SH',
    label: 'Svätá Helena, Nanebovstúpenie a Tristan da Cunha',
  },
  {
    value: 'KN',
    label: 'Svätý Kričnia a Nevis',
  },
  {
    value: 'LC',
    label: 'Svätá Lucia',
  },
  {
    value: 'PM',
    label: 'Svätý Pierre a Miquelon',
  },
  {
    value: 'VC',
    label: 'Svätý Vincent a Grenadíny',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao Tome a Principe',
  },
  {
    value: 'SA',
    label: 'Saudská Arábia',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Srbsko',
  },
  {
    value: 'SC',
    label: 'Seychely',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Singapur',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Slovensko',
  },
  {
    value: 'SI',
    label: 'Slovinsko',
  },
  {
    value: 'SB',
    label: 'Šalamúnové ostrovy',
  },
  {
    value: 'SO',
    label: 'Somálsko',
  },
  {
    value: 'ZA',
    label: 'južná Afrika',
  },
  {
    value: 'SS',
    label: 'Južný Sudán',
  },
  {
    value: 'ES',
    label: 'Španielsko',
  },
  {
    value: 'LK',
    label: 'Srí Lanka',
  },
  {
    value: 'SD',
    label: 'Sudán',
  },
  {
    value: 'SR',
    label: 'Suriname',
  },
  {
    value: 'SE',
    label: 'Švédsko',
  },
  {
    value: 'CH',
    label: 'Švajčiarsko',
  },
  {
    value: 'SY',
    label: 'Sýrska arabská republika',
  },
  {
    value: 'TW',
    label: 'Taiwan',
  },
  {
    value: 'TJ',
    label: 'Tadžikistan',
  },
  {
    value: 'TZ',
    label: 'Tanzánia',
  },
  {
    value: 'TH',
    label: 'Thajsko',
  },
  {
    value: 'TL',
    label: 'Timor-lastický',
  },
  {
    value: 'TG',
    label: 'Togo Republic',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinidad a Tobago',
  },
  {
    value: 'TN',
    label: 'Tunisko',
  },
  {
    value: 'TR',
    label: 'Turecká republika',
  },
  {
    value: 'TM',
    label: 'Turkménsko',
  },
  {
    value: 'TC',
    label: 'Turci a Caicos',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukrajina',
  },
  {
    value: 'AE',
    label: 'Spojené Arabské Emiráty',
  },
  {
    value: 'GB',
    label: 'Spojene kralovstvo',
  },
  {
    value: 'US',
    label: 'Spojené štáty',
  },
  {
    value: 'US',
    label: 'Panenské ostrovy Spojených štátov',
  },
  {
    value: 'UY',
    label: 'Uruguaj',
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Vietnesk',
  },
  {
    value: 'WF',
    label: 'Wallis a Futuna',
  },
  {
    value: 'YE',
    label: 'Jemen',
  },
  {
    value: 'ZM',
    label: 'Zambia',
  },
  {
    value: 'ZM',
    label: 'Zimbabwe',
  },
];
