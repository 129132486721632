import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CustomImageViewImageGroupEnum, PremiumFeature } from '../../api';
import WinefoLogo from '../../assets/images/winefo-logo.svg';
import FlexBoxColumn from '../../components/flexbox/flex-box-column';
import LanguageSelector from '../../components/language-selector/language-selector';
import { grey } from '../../constants/colors';
import { Copyright } from '../../constants/mixie';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from '../../constants/urls';

interface WinefoPointFooterProps {
  hideImage?: boolean;
  premiumFeature?: PremiumFeature;
}

const WinefoPointFooter: React.FC<WinefoPointFooterProps> = ({
  hideImage,
  premiumFeature,
}) => {
  const { t } = useTranslation();
  const logo = premiumFeature?.sameFooterLogo
    ? premiumFeature?.customImages?.find(
        (image) =>
          image.imageGroup === CustomImageViewImageGroupEnum.BrandLogo &&
          typeof image.downloadUrl === 'string'
      )
    : premiumFeature?.customImages?.find(
        (image) =>
          image.imageGroup === CustomImageViewImageGroupEnum.Footer &&
          typeof image.downloadUrl === 'string'
      );

  const hasLogo = typeof logo !== 'undefined';

  return (
    <FlexBoxColumn
      bgcolor={grey[800]}
      color="white"
      px="49px"
      py={4}
      gap={3}
      mt="auto"
      width="100%"
    >
      {hasLogo && (
        <img
          src={logo.downloadUrl}
          style={{ maxHeight: '80px', width: 'fit-content', maxWidth: '271px' }}
        />
      )}
      <FlexBoxColumn gap={2}>
        <LanguageSelector variant="light" />
        <Link
          target="_blank"
          to={PRIVACY_POLICY}
          style={{
            all: 'unset',
            cursor: 'pointer',
          }}
        >
          <Typography variant="body2" fontFamily="inherit">
            {t('common.privacy-policy')}
          </Typography>
        </Link>
        <Link
          target="_blank"
          to={TERMS_AND_CONDITIONS}
          style={{ all: 'unset', cursor: 'pointer' }}
        >
          <Typography variant="body2" fontFamily="inherit">
            {t('winefo-point.terms')}
          </Typography>
        </Link>
      </FlexBoxColumn>
      <FlexBoxColumn>
        {!hideImage && (
          <img style={{ width: 'clamp(100px, 60%, 244px)' }} src={WinefoLogo} />
        )}
        <Typography
          sx={{
            fontSize: 8,
            letterSpacing: 1.15,
            color: grey[300],
          }}
        >
          {Copyright}
        </Typography>
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default WinefoPointFooter;
