import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import registrationApi from './registration-api';

interface DeleteRegistrationParams {
  onSuccess: () => void;
}

const useDeleteRegistration = (): UseMutationResult<
  AxiosResponse,
  AxiosError,
  DeleteRegistrationParams,
  unknown
> => {
  return useMutation({
    mutationFn: () => registrationApi.deleteRegistration(),
    onSuccess: (_, params) => {
      params.onSuccess();
    },
  });
};

export default useDeleteRegistration;
