import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ValidateRegistrationCodeRequest } from '../../api';
import registrationApi from './registration-api';

interface ValidateRegistrationCodeParams {
  variables: ValidateRegistrationCodeRequest;
  onSuccess: () => void;
  onError: (error: AxiosError<{ errorCode?: string }>) => void;
}

const useValidateRegistrationCode = (): UseMutationResult<
  AxiosResponse,
  AxiosError<{ errorCode?: string }>,
  ValidateRegistrationCodeParams,
  unknown
> => {
  return useMutation({
    mutationFn: (params: ValidateRegistrationCodeParams) =>
      registrationApi.validateRegistrationCode(params.variables),
    onSuccess: (_, params) => {
      params.onSuccess();
    },
    onError: (error, params) => {
      params.onError(error);
    },
  });
};

export default useValidateRegistrationCode;
