import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { ProductResponse } from '../../api';
import productApi from './product-api';

export const PRODUCTS_KEY = 'products';

const useGetProducts = (
  options?: UseQueryOptions<
    ProductResponse,
    unknown,
    ProductResponse,
    (string | number | undefined)[]
  >
): UseQueryResult<ProductResponse, unknown> => {
  return useQuery({
    queryKey: [PRODUCTS_KEY],
    queryFn: () => productApi.getProducts().then((result) => result.data),
    ...options,
  });
};

export default useGetProducts;
