import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DeleteAccountButton from '../../components/delete-account/delete-account-button';
import FlexBox from '../../components/flexbox/flexbox';
import useGetProfile from '../../data/profile/use-get-profile';
import ChangePassword from './change-password';
import SettingsItem from './settings-item';

const AccountSettingsSection = () => {
  const { t } = useTranslation();
  const { data } = useGetProfile();
  const { email } = data?.contact || {};

  return (
    <>
      <FlexBox mx={2} flexDirection="column" gap={1}>
        <Typography sx={{ my: 1.5, ml: 0.5 }}>
          {t('signup.account-settings')}
        </Typography>
        {email && (
          <SettingsItem endIcon="" disableRipple sx={{ cursor: 'default' }}>
            {t('settings.registered-email')}
            <span style={{ color: '#00000061', marginLeft: 8 }}>{email}</span>
          </SettingsItem>
        )}
        <ChangePassword />
        {process.env.REACT_APP_STRIPE_MANAGEMENT_LINK && (
          <Link
            to={process.env.REACT_APP_STRIPE_MANAGEMENT_LINK}
            target="_blank"
          >
            <SettingsItem>{t('settings.manage-subscription')}</SettingsItem>
          </Link>
        )}
        <DeleteAccountButton
          variant="text"
          endIcon={<ChevronRightIcon />}
          size="small"
          sx={{
            textTransform: 'none',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '166%',
            letterSpacing: '0.4px',
            width: '100%',
            justifyContent: 'start',
          }}
        />
      </FlexBox>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

export default AccountSettingsSection;
