import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SubIngredientsModalHeaderProps {
  name?: string;
}

const SubIngredientsModalHeader: React.FC<SubIngredientsModalHeaderProps> = ({
  name,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 6, ml: 6, mb: 2 }}>
      <Typography variant="h4">
        {t('edit-label.ingredients-section.subsection-modal.title')}
      </Typography>
      <Typography variant="body2">
        {t('edit-label.ingredients-section.subsection-modal.subtitle', {
          ingredient: t(`ingredients.${name}`),
        })}
      </Typography>
    </Box>
  );
};

export default SubIngredientsModalHeader;
