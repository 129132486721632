import { yupResolver } from '@hookform/resolvers/yup';
import { Drafts } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../../../assets/images/settings-background.svg';
import BackButton from '../../../components/button/back-button';
import CheckboxWithController from '../../../components/checkbox/checkbox-with-controller';
import CompanyForm from '../../../components/company-form/company-form';
import FlexBox from '../../../components/flexbox/flexbox';
import LocationForm from '../../../components/location-form/location-form';
import NavigationGuard from '../../../components/navigation-guard/navigation-guard';
import Background from '../../../components/screen-template/background';
import { HEADER_HEIGHT } from '../../../constants/dimensions';
import { ROUTES } from '../../../constants/routes';
import useUpdateProfile from '../../../data/profile/use-update-profile';
import { ProfileFormValue } from '../../../models/profile';
import { useSnackbar } from '../../../services/snackbar-context';
import profileFormSchema from './profile-form-schema';
import ProfileFormSectionHeader from './profile-form-section-header';
import { toCompany } from './profile-utils';

interface ProfileSettingsFormProps {
  formData: ProfileFormValue;
}

const ProfileSettingsForm: React.FC<ProfileSettingsFormProps> = ({
  formData,
}: ProfileSettingsFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();
  const height = `calc(100vh - ${HEADER_HEIGHT}px)`;
  const updateProfile = useUpdateProfile();

  const { handleSubmit, control, formState, reset, watch, trigger } =
    useForm<ProfileFormValue>({
      mode: 'all',
      defaultValues: formData,
      resolver: yupResolver(profileFormSchema(t)),
    });
  const splitFormProps = { control, formState, watch, trigger };

  const handleFormSubmitSuccess = async (formValue: ProfileFormValue) => {
    updateProfile.mutate({
      data: toCompany(formValue),
      onSuccess: () => {
        reset(formValue);
        setSnackbar({ message: t('common.update-account-success-message') });
      },
    });
  };
  const handleFormSubmitError = () => {
    setSnackbar({
      message: t('common.submit-error'),
    });
  };

  const submitBeforeNavigation = (
    continueNavigation: () => void,
    closeNavigationGuardModal?: () => void
  ) => {
    handleSubmit(
      (data) => {
        handleFormSubmitSuccess(data).then(() => {
          continueNavigation();
        });
      },
      () => {
        closeNavigationGuardModal?.();
        handleFormSubmitError();
      }
    )();
  };

  return (
    <Background
      imageSource={BackgroundImage}
      height={height}
      overflow="auto"
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        placeItems: 'start',
        backgroundPosition: '100% 50px',
        backgroundSize: { xs: 0, md: '50% 100%' },
        backgroundAttachment: 'fixed',
      }}
    >
      <NavigationGuard
        shouldBlockNavigation={formState.isDirty}
        beforeNavigation={submitBeforeNavigation}
      />
      <form
        onSubmit={handleSubmit(handleFormSubmitSuccess, handleFormSubmitError)}
      >
        <Box width="100%" maxWidth={800} justifySelf="center">
          <FlexBox justifyContent="space-between" mt={3} ml={2}>
            <BackButton onClick={() => navigate(ROUTES.SETTINGS)}>
              {t('settings.title')}
            </BackButton>
            <Button
              disabled={!formState.isDirty}
              variant="contained"
              type="submit"
            >
              {t('common.save')}
            </Button>
          </FlexBox>
          <Box mt={3} mb={9} mx={4}>
            <Typography variant="h5">
              {t('settings.profile-settings')}
            </Typography>
            <ProfileFormSectionHeader
              title={t('signup.company-information')}
              icon={<WorkIcon fontSize="inherit" />}
            />
            <CompanyForm {...splitFormProps} />
            <ProfileFormSectionHeader
              title={t('signup.company-information')}
              icon={<LocationOnIcon fontSize="inherit" />}
            />
            <LocationForm {...splitFormProps} />
            <ProfileFormSectionHeader
              title={t('settings.marketing-information')}
              icon={<Drafts fontSize="inherit" />}
            />
            <CheckboxWithController
              control={control}
              name="marketingConsent"
              labelProps={{
                label: t('signup.marketing-consent'),
                sx: { mt: '12px' },
              }}
            />
          </Box>
        </Box>
      </form>
    </Background>
  );
};

export default ProfileSettingsForm;
