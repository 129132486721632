import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import WebFont from 'webfontloader';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

WebFont.load({
  google: {
    families: [
      'Inter:400,700',
      'Lato:400,700',
      'Montserrat:400,700',
      'Nunito:400,700',
      'Open Sans:400,700',
      'Quicksand:400,700',
    ],
  },
  custom: {
    families: ['Arial:400,700', 'Calibri:400,700', 'Times New Roman:400,700'],
    urls: ['./assets/fonts/fonts.css'],
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
