export default [
  {
    value: 'AF',
    label: 'Afganistan',
  },
  {
    value: 'AL',
    label: 'Albanija',
  },
  {
    value: 'DZ',
    label: 'Alžirija',
  },
  {
    value: 'AS',
    label: 'Ameriška Samoa',
  },
  {
    value: 'AD',
    label: 'Andorra',
  },
  {
    value: 'AO',
    label: 'Angola',
  },
  {
    value: 'AI',
    label: 'Anguilla',
  },
  {
    value: 'AG',
    label: 'Antigua in Barbuda',
  },
  {
    value: 'AR',
    label: 'Argentina',
  },
  {
    value: 'AM',
    label: 'Armenija',
  },
  {
    value: 'AW',
    label: 'Aruba',
  },
  {
    value: 'AU',
    label: 'Avstralija',
  },
  {
    value: 'AT',
    label: 'Avstrija',
  },
  {
    value: 'AZ',
    label: 'Azerbajdžan',
  },
  {
    value: 'BS',
    label: 'Bahami',
  },
  {
    value: 'BH',
    label: 'Bahrajn',
  },
  {
    value: 'BD',
    label: 'Bangladeš',
  },
  {
    value: 'BB',
    label: 'Barbados',
  },
  {
    value: 'BY',
    label: 'Belorusija',
  },
  {
    value: 'BE',
    label: 'Belgija',
  },
  {
    value: 'BZ',
    label: 'Belize',
  },
  {
    value: 'BJ',
    label: 'Benin',
  },
  {
    value: 'BM',
    label: 'Bermuda',
  },
  {
    value: 'BT',
    label: 'Butan',
  },
  {
    value: 'BO',
    label: 'Bolivija',
  },
  {
    value: 'BA',
    label: 'Bosna in Hercegovina',
  },
  {
    value: 'BW',
    label: 'Bocvana',
  },
  {
    value: 'BR',
    label: 'Brazilija',
  },
  {
    value: 'IO',
    label: 'britanski Deviški otoki',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    label: 'Bolgarija',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
  },
  {
    value: 'BI',
    label: 'Burundi',
  },
  {
    value: 'CV',
    label: 'Cabo Verde',
  },
  {
    value: 'KH',
    label: 'Kambodža',
  },
  {
    value: 'CM',
    label: 'Kamerun',
  },
  {
    value: 'CA',
    label: 'Kanada',
  },
  {
    value: 'KY',
    label: 'Kajmanski otoki',
  },
  {
    value: 'CF',
    label: 'centralna afriška republika',
  },
  {
    value: 'TD',
    label: 'Chad',
  },
  {
    value: 'CL',
    label: 'Čile',
  },
  {
    value: 'CN',
    label: 'Kitajska',
  },
  {
    value: 'CO',
    label: 'Kolumbija',
  },
  {
    value: 'KM',
    label: 'Komoros',
  },
  {
    value: 'CG',
    label: 'Kongo',
  },
  {
    value: 'CK',
    label: 'Kuharski otoki',
  },
  {
    value: 'CR',
    label: 'Kostarika',
  },
  {
    value: 'CI',
    label: 'Slonokoščena obala',
  },
  {
    value: 'HR',
    label: 'Hrvaška',
  },
  {
    value: 'CU',
    label: 'Kuba',
  },
  {
    value: 'CU',
    label: 'Curaçao',
  },
  {
    value: 'CY',
    label: 'Ciper',
  },
  {
    value: 'CZ',
    label: 'Češka',
  },
  {
    value: 'CZ',
    label: 'Demokratična ljudska republika Koreja',
  },
  {
    value: 'CD',
    label: 'Demokratična republika Kongo',
  },
  {
    value: 'DK',
    label: 'Danska',
  },
  {
    value: 'DK',
    label: 'Diego Garcia',
  },
  {
    value: 'DJ',
    label: 'Džibuti',
  },
  {
    value: 'DM',
    label: 'Dominica',
  },
  {
    value: 'DO',
    label: 'Dominikanska republika',
  },
  {
    value: 'EC',
    label: 'Ekvador',
  },
  {
    value: 'EG',
    label: 'Egipt',
  },
  {
    value: 'SV',
    label: 'El Salvador',
  },
  {
    value: 'GQ',
    label: 'Ekvatorialna Gvineja',
  },
  {
    value: 'ER',
    label: 'Eritreja',
  },
  {
    value: 'EE',
    label: 'Estonija',
  },
  {
    value: 'EE',
    label: 'Eswatini',
  },
  {
    value: 'ET',
    label: 'Etiopija',
  },
  {
    value: 'FK',
    label: 'Falklandski otoki',
  },
  {
    value: 'FO',
    label: 'Ferski otoki',
  },
  {
    value: 'FJ',
    label: 'Fidži',
  },
  {
    value: 'FI',
    label: 'Finska',
  },
  {
    value: 'FR',
    label: 'Francija',
  },
  {
    value: 'FR',
    label: 'Francoski oddelki in ozemlja v Indijskem oceanu',
  },
  {
    value: 'GF',
    label: 'Francoska Gvajana',
  },
  {
    value: 'PF',
    label: 'Francoska Polinezija',
  },
  {
    value: 'GA',
    label: 'Gabon',
  },
  {
    value: 'GM',
    label: 'Gambija',
  },
  {
    value: 'GE',
    label: 'Gruzija',
  },
  {
    value: 'DE',
    label: 'Nemčija',
  },
  {
    value: 'GH',
    label: 'Gana',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
  },
  {
    value: 'GR',
    label: 'Grčija',
  },
  {
    value: 'GL',
    label: 'Grenlandija',
  },
  {
    value: 'GD',
    label: 'GRENADA',
  },
  {
    value: 'GP',
    label: 'Guadelaupe',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'GT',
    label: 'Gvatemala',
  },
  {
    value: 'GG',
    label: 'Gvineja',
  },
  {
    value: 'GN',
    label: 'Gvineja-Bissau',
  },
  {
    value: 'GY',
    label: 'Gvajana',
  },
  {
    value: 'HT',
    label: 'Haiti',
  },
  {
    value: 'HN',
    label: 'Honduras',
  },
  {
    value: 'HK',
    label: 'Hong Kong',
  },
  {
    value: 'HU',
    label: 'Madžarska',
  },
  {
    value: 'IS',
    label: 'Islandija',
  },
  {
    value: 'IN',
    label: 'Indija',
  },
  {
    value: 'ID',
    label: 'Indonezija',
  },
  {
    value: 'IR',
    label: 'Iran',
  },
  {
    value: 'IQ',
    label: 'Irak',
  },
  {
    value: 'IE',
    label: 'Irska',
  },
  {
    value: 'IL',
    label: 'Izrael',
  },
  {
    value: 'IT',
    label: 'Italija',
  },
  {
    value: 'JM',
    label: 'Jamajka',
  },
  {
    value: 'JP',
    label: 'Japonska',
  },
  {
    value: 'JO',
    label: 'Jordan',
  },
  {
    value: 'KZ',
    label: 'Kazahstan',
  },
  {
    value: 'KE',
    label: 'Kenija',
  },
  {
    value: 'KI',
    label: 'Kiribati',
  },
  {
    value: 'KR',
    label: 'Koreja',
  },
  {
    value: 'XK',
    label: 'Kosovo',
  },
  {
    value: 'KW',
    label: 'Kuvajt',
  },
  {
    value: 'KG',
    label: 'Kirgizistan',
  },
  {
    value: 'LA',
    label: 'Demokratična republika Laosa',
  },
  {
    value: 'LV',
    label: 'Latvija',
  },
  {
    value: 'LB',
    label: 'Libanon',
  },
  {
    value: 'LS',
    label: 'Lesoto',
  },
  {
    value: 'LR',
    label: 'Liberija',
  },
  {
    value: 'LY',
    label: 'Libija',
  },
  {
    value: 'LI',
    label: 'Lihtenštain',
  },
  {
    value: 'LT',
    label: 'Litva',
  },
  {
    value: 'LU',
    label: 'Luksemburg',
  },
  {
    value: 'MO',
    label: 'Makao',
  },
  {
    value: 'MG',
    label: 'Madagaskar',
  },
  {
    value: 'MW',
    label: 'Malavi',
  },
  {
    value: 'MY',
    label: 'Malezija',
  },
  {
    value: 'MV',
    label: 'Maldivi',
  },
  {
    value: 'ML',
    label: 'Mali',
  },
  {
    value: 'MT',
    label: 'Malta',
  },
  {
    value: 'MH',
    label: 'Marshall otoki',
  },
  {
    value: 'MQ',
    label: 'Martinique',
  },
  {
    value: 'MR',
    label: 'Mavretanija',
  },
  {
    value: 'MU',
    label: 'Mavricij',
  },
  {
    value: 'MX',
    label: 'Mehika',
  },
  {
    value: 'FM',
    label: 'Mikronezija',
  },
  {
    value: 'MD',
    label: 'Moldavija',
  },
  {
    value: 'MC',
    label: 'Monako',
  },
  {
    value: 'MN',
    label: 'Mongolija',
  },
  {
    value: 'ME',
    label: 'Črna gora',
  },
  {
    value: 'MS',
    label: 'Montserrat',
  },
  {
    value: 'MA',
    label: 'Maroko',
  },
  {
    value: 'MZ',
    label: 'Mozambik',
  },
  {
    value: 'MM',
    label: 'Mjanmar',
  },
  {
    value: 'NA',
    label: 'Namibija',
  },
  {
    value: 'NR',
    label: 'Nauru',
  },
  {
    value: 'NP',
    label: 'Nepal',
  },
  {
    value: 'NL',
    label: 'Nizozemska',
  },
  {
    value: 'NC',
    label: 'Nova Kaledonija',
  },
  {
    value: 'NZ',
    label: 'Nova Zelandija',
  },
  {
    value: 'NI',
    label: 'Nikaragva',
  },
  {
    value: 'NE',
    label: 'Niger',
  },
  {
    value: 'NG',
    label: 'Nigerija',
  },
  {
    value: 'NU',
    label: 'Niue',
  },
  {
    value: 'NF',
    label: 'Otok Norfolk',
  },
  {
    value: 'NF',
    label: 'Severna Makedonija',
  },
  {
    value: 'MP',
    label: 'Otoki Severne Mariane',
  },
  {
    value: 'NO',
    label: 'Norveška',
  },
  {
    value: 'OM',
    label: 'Oman',
  },
  {
    value: 'PK',
    label: 'Pakistan',
  },
  {
    value: 'PW',
    label: 'Palau',
  },
  {
    value: 'PA',
    label: 'Panama',
  },
  {
    value: 'PG',
    label: 'Papua Nova Gvineja',
  },
  {
    value: 'PY',
    label: 'Paragvaj',
  },
  {
    value: 'PE',
    label: 'Peru',
  },
  {
    value: 'PH',
    label: 'Filipini',
  },
  {
    value: 'PL',
    label: 'Poljska',
  },
  {
    value: 'PT',
    label: 'Portugalska',
  },
  {
    value: 'PR',
    label: 'Portoriko',
  },
  {
    value: 'QA',
    label: 'Katar',
  },
  {
    value: 'RO',
    label: 'Romunija',
  },
  {
    value: 'RU',
    label: 'Ruska federacija',
  },
  {
    value: 'RW',
    label: 'Ruanda',
  },
  {
    value: 'SH',
    label: 'Saint Helena, Ascension in Tristan da Cunha',
  },
  {
    value: 'KN',
    label: 'Saint Kitts in Nevis',
  },
  {
    value: 'LC',
    label: 'Sveta Lucia',
  },
  {
    value: 'PM',
    label: 'Saint Pierre in Miquelon',
  },
  {
    value: 'VC',
    label: 'Saint Vincent in Grenadine',
  },
  {
    value: 'WS',
    label: 'Samoa',
  },
  {
    value: 'SM',
    label: 'San Marino',
  },
  {
    value: 'ST',
    label: 'Sao tome in principe',
  },
  {
    value: 'SA',
    label: 'Savdska Arabija',
  },
  {
    value: 'SN',
    label: 'Senegal',
  },
  {
    value: 'RS',
    label: 'Srbija',
  },
  {
    value: 'SC',
    label: 'Sejšeli',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
  },
  {
    value: 'SG',
    label: 'Singapur',
  },
  {
    value: 'SG',
    label: 'Sint Maarten',
  },
  {
    value: 'SK',
    label: 'Slovaška',
  },
  {
    value: 'SI',
    label: 'Slovenija',
  },
  {
    value: 'SB',
    label: 'Salomonovi otoki',
  },
  {
    value: 'SO',
    label: 'Somalija',
  },
  {
    value: 'ZA',
    label: 'Južna Afrika',
  },
  {
    value: 'SS',
    label: 'Južni Sudan',
  },
  {
    value: 'ES',
    label: 'Španija',
  },
  {
    value: 'LK',
    label: 'Šrilanka',
  },
  {
    value: 'SD',
    label: 'Sudan',
  },
  {
    value: 'SR',
    label: 'Suriname',
  },
  {
    value: 'SE',
    label: 'Švedska',
  },
  {
    value: 'CH',
    label: 'Švica',
  },
  {
    value: 'SY',
    label: 'Sirska arabska republika',
  },
  {
    value: 'TW',
    label: 'Tajvan',
  },
  {
    value: 'TJ',
    label: 'Tadžikistan',
  },
  {
    value: 'TZ',
    label: 'Tanzanija',
  },
  {
    value: 'TH',
    label: 'Tajska',
  },
  {
    value: 'TL',
    label: 'Timor-leste',
  },
  {
    value: 'TG',
    label: 'Togo republika',
  },
  {
    value: 'TK',
    label: 'Tokelau',
  },
  {
    value: 'TO',
    label: 'Tonga',
  },
  {
    value: 'TT',
    label: 'Trinidad in Tobago',
  },
  {
    value: 'TN',
    label: 'Tunizija',
  },
  {
    value: 'TR',
    label: 'Republika Turčija',
  },
  {
    value: 'TM',
    label: 'Turkmenistan',
  },
  {
    value: 'TC',
    label: 'Turki in Caicos',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
  },
  {
    value: 'UG',
    label: 'Uganda',
  },
  {
    value: 'UA',
    label: 'Ukrajina',
  },
  {
    value: 'AE',
    label: 'Združeni Arabski Emirati',
  },
  {
    value: 'GB',
    label: 'Združeno kraljestvo',
  },
  {
    value: 'US',
    label: 'Združene države',
  },
  {
    value: 'US',
    label: 'Združene države Deviške otoke',
  },
  {
    value: 'UY',
    label: 'Urugvaj',
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
  },
  {
    value: 'VE',
    label: 'Venezuela',
  },
  {
    value: 'VN',
    label: 'Vietnam',
  },
  {
    value: 'WF',
    label: 'Wallis in Futuna',
  },
  {
    value: 'YE',
    label: 'Jemen',
  },
  {
    value: 'ZM',
    label: 'Zambija',
  },
  {
    value: 'ZM',
    label: 'Zimbabve',
  },
];
