import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IngredientsSectionIcon from '../../../assets/icons/ingredients-section-icon';
import PreviewSectionIcon from '../../../assets/icons/preview-section-icon';
import { EditSubIngredientsSection } from '../../../models/label';
import IngredientSubsection from './ingredient-subsection';
import CollapsibleBlock from '../../../components/collapsible-blocks/collapsible-block';
import Preview from './preview';

const SubIngredientsSections: React.FC = () => {
  const { t } = useTranslation();
  const [expandedSection, setExpandedSection] =
    useState<EditSubIngredientsSection>();
  const handleSectionToggle = (section: EditSubIngredientsSection) => {
    setExpandedSection(expandedSection === section ? undefined : section);
  };
  const sections = [
    {
      title: t('edit-label.ingredients-section.subsections.ingredients'),
      icon: <IngredientsSectionIcon fontSize="inherit" />,
      value: EditSubIngredientsSection.INGREDIENTS,
      details: <IngredientSubsection isSubIngredient />,
    },
    {
      title: t('edit-label.ingredients-section.subsections.preview'),
      icon: <PreviewSectionIcon fontSize="inherit" />,
      value: EditSubIngredientsSection.PREVIEW,
      details: <Preview hasMainIngredient />,
    },
  ];

  return (
    <>
      {sections.map((section) => (
        <CollapsibleBlock
          key={section.value}
          {...section}
          expanded={expandedSection === section.value}
          onChange={() => handleSectionToggle(section.value)}
        />
      ))}
    </>
  );
};

export default SubIngredientsSections;
