import { RefObject, useRef } from 'react';
import { useEventListener } from './use-event-listener';

export const useOutsideClickListener = (
  handler: () => void
): {
  ref: RefObject<HTMLDivElement>;
} => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      handler();
    }
  };

  useEventListener('click', handleClickOutside);

  return { ref };
};
