import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Note: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        mt: 2.5,
        mb: 4,
        py: 2.5,
        px: 4,
        border: `1px solid ${theme.palette.warning.main}`,
        borderRadius: '10px',
      }}
    >
      <Typography>{t('stuck.note')}</Typography>
    </Box>
  );
};

export default Note;
